import { useNavigationAccount } from "../../../hooks";

import { AccountContainer } from "../../../../../components/containers";
import { DashboardHeading } from "../../../../../components/headings";
import { NavigationAccount, RedirectLinks } from "../../../components";

export const LegalInformation = () => {
    const navigation = useNavigationAccount("Regulamin i polityka prywatności");

    return (
        <>
            <DashboardHeading title="MOJE KONTO" subtitle="informacje prawne" />
            <AccountContainer
                sidebarElement={<NavigationAccount elements={navigation} />}
            >
                <RedirectLinks
                    links={[
                        {
                            name: "Regulamin",
                            href: "terms-of-use",
                        },
                        {
                            name: "Polityka prywatności",
                            href: "privacy-policy",
                        },
                    ]}
                />
            </AccountContainer>
        </>
    );
};
