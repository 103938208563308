import { QueryClient } from "@tanstack/react-query";

import { getAccountCookie } from "../../../../services";

import {
    orderBannerQuery,
    subscriptionOptionsQuery,
    userSavedCardsQuery,
} from "../../utils";

export const storeLoader = (queryClient: QueryClient) => async () => {
    const { accountType } = getAccountCookie();

    if (accountType !== "student") {
        return undefined;
    }

    const query = subscriptionOptionsQuery();
    const query2 = orderBannerQuery();
    const query3 = userSavedCardsQuery();

    const data = queryClient.getQueryData(query.queryKey);
    const data2 = queryClient.getQueryData(query2.queryKey);
    const data3 = queryClient.getQueryData(query3.queryKey);

    return data && data2 && data3
        ? { data, data2, data3 }
        : (async () => {
              await queryClient.fetchQuery(query);
              await queryClient.fetchQuery(query2);
              await queryClient.fetchQuery(query3);

              return null;
          })();
};
