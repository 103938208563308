import { useState, useEffect } from "react";
import { Portal } from "react-portal";
import { AnimatePresence, motion } from "framer-motion";
import parse from "html-react-parser";

import { getAccountCookie } from "../../../services";

import { useMarkVideoAsWatched } from "../../../modules/dashboard/hooks";

import { CircleLoader } from "../../loaders";

import { VideoModalProps } from "./VideoModal.typings";

import styles from "./VideoModal.module.css";

export const VideoModal = ({ isOpen, setIsOpen }: VideoModalProps) => {
    const { videoUrl } = getAccountCookie();

    const { mutate: markVideoAsWatched } = useMarkVideoAsWatched();

    const [iframe, setIframe] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);

    const handleClose = () => {
        markVideoAsWatched();

        setIsOpen(false);
    };

    useEffect(() => {
        const setIframeVideo = async () => {
            const response = await fetch(videoUrl);
            const html = await response.text();
            const iframe = html.substring(
                html.indexOf("<iframe"),
                html.indexOf("</iframe>") + 9
            );
            const iframeWithTime = iframe.replace(
                "?autoplay=true",
                "?autoplay=true&t=1"
            );

            setIframe(iframeWithTime);
        };

        if (!iframe) {
            setIframeVideo();
        } else if (isLoading) {
            setTimeout(() => {
                setIsLoading(false);
            }, 2000);
        }
    }, [iframe, isLoading, videoUrl]);

    return (
        <Portal node={document && document.getElementById("modal-root")}>
            <AnimatePresence mode="wait" initial={false}>
                {isOpen && (
                    <motion.div
                        className={styles.modal}
                        exit={{ opacity: 0 }}
                        transition={{ type: "tween", duration: 0.2 }}
                    >
                        <div className={styles.wrapper}>
                            <motion.div
                                className={styles.dialog}
                                exit={{ y: -51 }}
                                transition={{ type: "tween", duration: 0.3 }}
                            >
                                <motion.button
                                    id="modalCloseButton"
                                    className={styles.close}
                                    type="button"
                                    whileHover={{ opacity: 0.7 }}
                                    onClick={handleClose}
                                ></motion.button>
                                <div className={styles.video}>
                                    <AnimatePresence
                                        mode="wait"
                                        initial={false}
                                    >
                                        {isLoading && (
                                            <motion.div
                                                className={styles.loader}
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                                transition={{
                                                    type: "tween",
                                                    duration: 0.2,
                                                }}
                                            >
                                                <CircleLoader />
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                    {iframe && parse(iframe)}
                                </div>
                            </motion.div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </Portal>
    );
};
