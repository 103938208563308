import { StoreDataType } from "../../../../typings";


export const getStoreValidation = (storeData: StoreDataType) => {
    let isValid = false;

    Object.entries(storeData).every(([, value]) => {
        if (value.selected) {
            if (value.values) {
                isValid = true;

                return true;
            } else {
                isValid = false;

                return false;
            }
        } else {
            return true;
        }
    });

    return isValid;
};
