import { Form, Formik } from "formik";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Link, Navigate } from "react-router-dom";

import {
    getInvitationCookie,
    getRegisterCookie,
    getRemindPasswordCookie,
    getResetPasswordCookie,
    getSessionExpired,
    setSessionExpired,
} from "../../../../services";

import { useLoadingContext } from "../../../../contexts/LoadingContext";

import { useLogin } from "../../hooks";

import { useMedia, useNotify, useSubmitForm } from "../../../../hooks";

import { DefaultButton } from "../../../../components/buttons";
import { AuthContainer } from "../../../../components/containers";
import { MainHeading } from "../../../../components/headings";
import { DefaultInput } from "../../../../components/inputs";
import { MainLogo } from "../../../../components/logos";
import { ActionText, ErrorText } from "../../../../components/texts";
import { ProtectedRoute } from "../../../../components/utils";

import { getResponseMessage, loginSchema } from "../../utils";

import styles from "./Login.module.css";

import pi from "../../../../assets/images/robots/pi-happy.svg";
import piAndInfinity from "../../../../assets/images/robots/pi-infinity-surprised.svg";

const AnimatedForm = motion(Form);

type Props = {
    mutation: ReturnType<typeof useLogin>;
};

export const Login = ({ mutation }: Props) => {
    const registerCookie = getRegisterCookie();
    const remindPasswordCookie = getRemindPasswordCookie();
    const resetPasswordCookie = getResetPasswordCookie();
    const invitationCookie = getInvitationCookie();

    const sessionExpired = getSessionExpired();

    const submitForm = useSubmitForm();
    const { isDesktop } = useMedia();
    const { setLoadingActive } = useLoadingContext();

    const isFormSubmitted = useRef(false);
    const [errorResponse, setErrorResponse] = useState<[boolean, string?]>([
        false,
        "",
    ]);

    useEffect(() => {
        if (mutation.error && isFormSubmitted.current) {
            isFormSubmitted.current = false;

            setLoadingActive(false, () =>
                setErrorResponse(
                    mutation.error.message === "Network Error" ||
                        !mutation.error.response
                        ? [true, "Przepraszamy, wystąpił błąd z serwerem"]
                        : [
                            true,
                            getResponseMessage(
                                mutation.error.response.data.messages
                            ),
                        ]
                )
            );
        }
    }, [mutation, setLoadingActive]);

    useEffect(() => {
        if (mutation.isSuccess) {
            setLoadingActive(false, () => {
                mutation.reset();
            });
        }
    }, [mutation, setLoadingActive]);

    const notify = useNotify({ type: "error", position: "bottom-center" });

    useEffect(() => {
        if (sessionExpired) {
            notify(
                "Twoja sesja wygasła lub zalogowałeś/aś się na innym urządzeniu."
            );

            setSessionExpired(false);
        }
    }, [sessionExpired, notify]);

    if (registerCookie) {
        return <Navigate to="/register/confirm" replace={true} />;
    }

    if (remindPasswordCookie) {
        return <Navigate to="/remind-password/confirm" replace={true} />;
    }

    if (resetPasswordCookie) {
        return <Navigate to="/reset-password" replace={true} />;
    }

    if (invitationCookie) {
        return (
            <Navigate
                to={`/register/${invitationCookie[1].toLowerCase()}`}
                replace={true}
            />
        );
    }

    if (mutation.isSuccess) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <ProtectedRoute redirectWhenLoggedIn={true} redirectPath="/dashboard">
            <AuthContainer
                image={errorResponse[0] ? piAndInfinity : pi}
                imageSize={
                    isDesktop
                        ? errorResponse[0]
                            ? 406
                            : 285
                        : errorResponse[0]
                            ? 204
                            : 210
                }
                imagePosition="right"
                imageOffsetX={errorResponse[0] ? -51 : 52}
                imageOffsetY={
                    isDesktop ? (errorResponse[0] ? 10 : 15.5556) : undefined
                }
            >
                <MainLogo isCenter={true} />
                <MainHeading wrapper={styles.heading}>LOGOWANIE</MainHeading>
                <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={loginSchema}
                    onSubmit={(data, actions) => {
                        isFormSubmitted.current = true;

                        setLoadingActive(true, () => {
                            submitForm(data);

                            actions.setSubmitting(false);
                        });
                    }}
                >
                    {(formik) => (
                        <AnimatedForm
                            className={styles.form}
                            initial={{
                                paddingTop: "35px",
                            }}
                            animate={{
                                paddingTop: errorResponse[1] ? "40px" : "35px",
                            }}
                            transition={{ type: "tween", duration: 0.3 }}
                        >
                            <ErrorText isError={!!errorResponse[1]}>
                                {errorResponse[1]}
                            </ErrorText>
                            <DefaultInput
                                label="E-mail"
                                name="email"
                                type="email"
                                isError={!!errorResponse[1]}
                                onChange={() =>
                                    setErrorResponse((prevResponse) => [
                                        prevResponse[0],
                                        "",
                                    ])
                                }
                            />
                            <DefaultInput
                                label="Hasło"
                                name="password"
                                type="password"
                                isError={!!errorResponse[1]}
                                onChange={() =>
                                    setErrorResponse((prevResponse) => [
                                        prevResponse[0],
                                        "",
                                    ])
                                }
                            />
                            <DefaultButton
                                wrapper={styles.formButton}
                                initial={{
                                    backgroundColor:
                                        DefaultButton.color.disabled,
                                }}
                                animate={{
                                    backgroundColor:
                                        !!errorResponse[1] ||
                                            !formik.dirty ||
                                            !formik.isValid ||
                                            formik.isSubmitting
                                            ? DefaultButton.color.disabled
                                            : DefaultButton.color.enabled,
                                }}
                                whileHover={{
                                    opacity: formik.isSubmitting ? 1 : 0.7,
                                }}
                                transition={{ type: "tween", duration: 0.3 }}
                                isSubmit={true}
                                isDisabled={
                                    !!errorResponse[1] ||
                                    !formik.dirty ||
                                    !formik.isValid ||
                                    formik.isSubmitting
                                }
                            >
                                ZALOGUJ SIĘ
                            </DefaultButton>
                        </AnimatedForm>
                    )}
                </Formik>
                <>
                    {!errorResponse[0] && (
                        <p className={styles.remind}>
                            <Link
                                className={styles.remindLink}
                                to="/remind-password"
                            >
                                Nie pamiętasz hasła?
                            </Link>
                        </p>
                    )}
                </>
                <ActionText
                    messages={["Nie mam jeszcze konta."]}
                    button="REJESTRACJA"
                    to="/register/student"
                />
                <div className={styles.info}>
                    {errorResponse[0] ? (
                        <p className={styles.remind}>
                            <Link
                                className={styles.remindLink}
                                to="/remind-password"
                            >
                                Nie pamiętasz hasła?
                            </Link>
                        </p>
                    ) : (
                        <p className={styles.rules}>
                            Klikając "Zaloguj się" akceptujesz regulamin
                            <br />
                            <Link to="/terms-of-use">Smart Tutor</Link>.
                        </p>
                    )}
                </div>
            </AuthContainer>
        </ProtectedRoute>
    );
};
