import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

type Mutation = {
    taskId: string;
    content: string;
};

export const useReportError = () => {
    return useMutation<AxiosResponse<any, any>, any, Mutation, unknown>(
        (data) =>
            mainApi.post("report_error", {
                id_exercise: data.taskId,
                content: data.content,
            })
    );
};
