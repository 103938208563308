import { useState, useRef, useEffect } from "react";
import { useNavigation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import { useMedia } from "../../../../hooks";

import { TasksListItem } from "../TasksListItem";

import { TasksResponse } from "../../typings";

import styles from "./TasksList.module.css";

type Props = {
    tasks: TasksResponse["sections"];
    isFiltered?: boolean;
};

export const TasksList = ({ tasks, isFiltered }: Props) => {
    const { isDesktop } = useMedia();

    const { state } = useNavigation();
    const isLoadingState = state === "loading";

    const tasksContainerRef = useRef<HTMLDivElement>(null);
    const isTaskHeightDelay = useRef(true);
    const [tasksHeight, setTasksHeight] = useState<number | undefined>(
        undefined
    );

    useEffect(() => {
        if (!isFiltered) {
            isTaskHeightDelay.current = false;
        } else {
            isTaskHeightDelay.current = true;
        }

        setTimeout(
            () => {
                if (tasksContainerRef.current) {
                    setTasksHeight(tasksContainerRef.current.clientHeight);
                }
            },
            isTaskHeightDelay.current ? 400 : 0
        );
    }, [isFiltered, isDesktop]);

    return (
        <motion.div
            className={styles.tasks}
            animate={{ height: tasksHeight }}
            transition={{
                type: "tween",
                duration: 0.3,
            }}
        >
            <div className={styles.tasksGrid} ref={tasksContainerRef}>
                {tasks.length > 0 ? (
                    <AnimatePresence mode="sync" initial={false}>
                        {tasks.map((task) => {
                            const taskProps = {
                                key: task.id,
                                to: task.id,
                                icon: task.image,
                                progress: task.percentage_done,
                                isDesktop: isDesktop,
                                isLoading: isLoadingState,
                            };

                            return isFiltered ? (
                                task.has_exam_exercise ? (
                                    <TasksListItem {...taskProps}>
                                        {task.name}
                                    </TasksListItem>
                                ) : null
                            ) : (
                                <TasksListItem {...taskProps}>
                                    {task.name}
                                </TasksListItem>
                            );
                        })}
                    </AnimatePresence>
                ) : (
                    <div className={styles.empty}>
                        Brak kategorii do wyświetlenia
                    </div>
                )}
            </div>
        </motion.div>
    );
};
