import clsx from "clsx";

import { useMedia } from "../../../../hooks";

import { NavigationBubbleStatsProps } from "./NavigationBubbleStats.typings";

import styles from "./NavigationBubbleStats.module.css";

export type { StatsProps } from "./NavigationBubbleStats.typings";

export const NavigationBubbleStats = <T extends string | undefined>({
    stats,
    isAvailableSoon,
}: NavigationBubbleStatsProps<T>) => {
    const { isDesktop } = useMedia();

    return (
        <>
            {stats.map((element) => {
                const progressWidth = element.progress
                    ? element.progress.total && !element.progress.isPercentage
                        ? (element.progress.actual * 100) /
                          element.progress.total
                        : element.progress.actual
                    : undefined;

                return (
                    <span
                        key={element.name}
                        className={clsx(
                            styles.container,
                            isAvailableSoon && styles.availableSoon
                        )}
                    >
                        <span className={styles.name}>{element.name}:</span>
                        <span className={styles.progress}>
                            <span
                                className={clsx(
                                    styles.main,
                                    element.studyGoal && styles.full
                                )}
                            >
                                {progressWidth !== undefined && (
                                    <span className={styles.bar}>
                                        <span
                                            className={styles.line}
                                            style={{
                                                width: isAvailableSoon
                                                    ? "50%"
                                                    : progressWidth > 0
                                                    ? `${progressWidth}%`
                                                    : isDesktop
                                                    ? "12px"
                                                    : "6px",
                                            }}
                                        ></span>
                                    </span>
                                )}
                                {element.studyGoal && (
                                    <span className={styles.goal}>
                                        {element.studyGoal}
                                    </span>
                                )}
                            </span>
                            {element.progress && (
                                <span className={styles.counter}>
                                    {isAvailableSoon
                                        ? "?/?"
                                        : element.progress.isPercentage
                                        ? `${element.progress.actual}%`
                                        : `${element.progress.actual}/${element.progress.total}`}
                                </span>
                            )}
                        </span>
                    </span>
                );
            })}
        </>
    );
};
