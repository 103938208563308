import React, { useState, useRef } from "react";
import { useField } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

import { inputVariants, labelVariants } from "./DefaultInput.animations";

import styles from "./DefaultInput.module.css";

type Props = {
    label: string;
    name: string;
    type: string;
    maxLength?: number;
    isNumeric?: boolean;
    isMasked?: boolean;
    isError?: boolean;
    isBlankError?: boolean;
    isDisabled?: boolean;
    isMarginless?: boolean;
    onChange?: () => void;
};

const numberTest = (string: string): boolean =>
    /[a-zA-Z!@#$%^&*)(=_\-|\\}\]{["':;?/>.<,]/g.test(string);

const keyNumberTest = (event: React.KeyboardEvent<HTMLInputElement>) => {
    return (numberTest(event.key) &&
        event.key !== "Backspace" &&
        event.key !== "ArrowLeft" &&
        event.key !== "ArrowRight" &&
        event.key !== "Tab") ||
        event.key === " "
        ? true
        : false;
};

export const DefaultInput = ({
    label,
    maxLength,
    isNumeric,
    isMasked,
    isError,
    isBlankError,
    isDisabled,
    isMarginless,
    onChange,
    ...props
}: Props) => {
    const [field, meta, helpers] = useField(props);

    const inputRef = useRef<HTMLInputElement | null>(null);
    const [isFocused, setIsFocused] = useState(false);

    const isFilled = typeof field.value === "string" && field.value.length > 0;

    isMasked = isMasked && !!maxLength && maxLength > 0;

    const handleOnChange = (event: React.FocusEvent<HTMLInputElement>) => {
        const inputLength = event.target.value.length;

        if (maxLength && maxLength > 0 && maxLength < inputLength) return false;

        if (typeof onChange === "function") {
            onChange();
        }

        field.onChange(event);
    };

    const isEnterKey = useRef(false);
    const isCtrlKey = useRef(false);

    const handleOnKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            isEnterKey.current = true;
        } else if (event.type === "keyup") {
            isEnterKey.current = false;
        }

        if (isEnterKey.current) return false;

        if (event.type === "keydown" && event.key === "Control") {
            isCtrlKey.current = true;
        }

        if (event.type === "keyup" && event.key === "Control") {
            isCtrlKey.current = false;
        }

        if (
            keyNumberTest(event) &&
            !(isCtrlKey.current && event.key === "v") &&
            event.key !== "Enter"
        ) {
            event.preventDefault();

            return false;
        }
    };

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);

        field.onBlur(event);
    };

    const handleOnPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        const text = event.clipboardData.getData("Text").replaceAll(" ", "");

        if (numberTest(text) || (maxLength && maxLength !== text.length)) {
            event.preventDefault();

            return false;
        } else {
            helpers.setValue(text);

            if (typeof onChange === "function") {
                onChange();
            }
        }
    };

    const handleMaskTrigger = () => {
        if (inputRef.current) {
            inputRef.current.focus();

            inputRef.current.selectionStart = maxLength ?? 1000;
        }
    };

    return (
        <div className={clsx(styles.field, isMarginless && styles.marginless)}>
            <div className={styles.fieldRow}>
                <motion.label
                    className={styles.label}
                    initial={
                        isFilled
                            ? isDisabled
                                ? "disabled"
                                : meta.error
                                ? "error"
                                : "success"
                            : isBlankError && meta.error
                            ? "error_static"
                            : "static"
                    }
                    animate={
                        isFilled
                            ? isDisabled
                                ? "disabled"
                                : isError || meta.error
                                ? "error"
                                : "success"
                            : isFocused
                            ? isBlankError && meta.error
                                ? "error"
                                : "active"
                            : isBlankError && meta.error
                            ? "error_static"
                            : "static"
                    }
                    variants={labelVariants}
                    transition={{ type: "tween", duration: 0.3 }}
                >
                    {label}
                </motion.label>
                <motion.input
                    ref={inputRef}
                    className={clsx(styles.input, isMasked && styles.masked)}
                    autoComplete="off"
                    initial={
                        isDisabled
                            ? "disabled"
                            : isFilled
                            ? isError || meta.error
                                ? "error"
                                : "success"
                            : "static"
                    }
                    animate={
                        isDisabled
                            ? "disabled"
                            : isFilled
                            ? isError || meta.error
                                ? "error"
                                : "success"
                            : isBlankError && meta.error
                            ? "error"
                            : "static"
                    }
                    variants={inputVariants}
                    transition={{ type: "tween", duration: 0.3 }}
                    disabled={isDisabled}
                    {...field}
                    {...props}
                    onChange={handleOnChange}
                    onKeyUp={isNumeric ? handleOnKey : undefined}
                    onKeyDown={isNumeric ? handleOnKey : undefined}
                    onFocus={() => setIsFocused(true)}
                    onBlur={handleOnBlur}
                    onPaste={isNumeric ? handleOnPaste : undefined}
                />
                {isMasked ? (
                    <>
                        {!isFocused && (
                            <div
                                className={styles.maskTrigger}
                                onClick={handleMaskTrigger}
                            />
                        )}
                        <AnimatePresence mode="wait" initial={false}>
                            {(isFilled || isFocused) && (
                                <motion.div
                                    className={styles.inputMask}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{
                                        type: "tween",
                                        duration: 0.3,
                                    }}
                                >
                                    {new Array(maxLength)
                                        .fill("_")
                                        .map((mark, index) => {
                                            return (
                                                <div
                                                    className={clsx(
                                                        styles.inputMark,
                                                        isFocused &&
                                                            field.value
                                                                .length ===
                                                                index &&
                                                            styles.current
                                                    )}
                                                    key={`${props.name}-mark_${index}`}
                                                >
                                                    {field.value.charAt(index)
                                                        ? field.value.charAt(
                                                              index
                                                          )
                                                        : mark}
                                                </div>
                                            );
                                        })}
                                    {isFocused &&
                                        maxLength === field.value.length && (
                                            <div
                                                className={styles.pointerMark}
                                            ></div>
                                        )}
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </>
                ) : null}
            </div>
            <AnimatePresence mode="wait">
                {(isFilled || isBlankError) &&
                    meta.error &&
                    meta.error !== " " &&
                    !isError && (
                        <motion.div
                            className={styles.metaError}
                            initial={{ height: isBlankError ? 'auto' : 0 }}
                            animate={{ height: "auto" }}
                            exit={{ height: 0 }}
                            transition={{ type: "tween", duration: 0.3 }}
                        >
                            <p className={styles.metaMessage}>{meta.error}</p>
                        </motion.div>
                    )}
            </AnimatePresence>
        </div>
    );
};
