import { Outlet, useOutlet } from "react-router-dom";
import { romanize } from "romans";

import { useMedia } from "../../../../../hooks";
import { useLearningProgress } from "../../../hooks";

import {
    BackgroundContainer,
    TaskContainer,
} from "../../../../../components/containers";
import { DashboardHeading } from "../../../../../components/headings";
import {
    CounterBox,
    ProgressButton,
    ProgressDefault,
    ShieldAccordion,
} from "../../../components";
import { NavigationBar } from "../../../../tasks/components";

import styles from "./LearningProgress.module.css";

import schoolIcon from "../../../images/learning-progress/school-icon.svg";
import infinity from "../../../../../assets/images/robots/infinity-watching.svg";

export const LearningProgress = () => {
    const outlet = useOutlet();

    const { isSmallerDesktop } = useMedia();

    const { data } = useLearningProgress('student', undefined);

    return (
        <BackgroundContainer
            rightElement={{
                image: infinity,
                size: {
                    width: 223,
                    height: 400,
                },
                position: {
                    x: isSmallerDesktop ? -30 : -140,
                    y: -56,
                },
            }}
        >
            <TaskContainer>
                {outlet ? (
                    <Outlet />
                ) : (
                    <>
                        <DashboardHeading title="POSTĘPY NAUKI" hasBorder />
                        {data && (
                            <div className={styles.info}>
                                <CounterBox
                                    count={data.data.exercise_done_count}
                                    isVisual={false}
                                >
                                    Rozwiązanych
                                    <br /> zadań
                                </CounterBox>
                                <CounterBox
                                    count={data.data.exam_days_left}
                                    isVisual={false}
                                >
                                    Dni do <br /> egzaminu
                                </CounterBox>
                            </div>
                        )}
                        <div className={styles.accordions}>
                            {data &&
                                data.data.progress.map((item) => {
                                    const props = {
                                        key: item.class,
                                        title: item.title,
                                    };

                                    const children =
                                        item.sections.length > 0 ? (
                                            <>
                                                <div
                                                    className={styles.progress}
                                                >
                                                    <ProgressDefault
                                                        progress={
                                                            item.percentage_done
                                                        }
                                                    />
                                                </div>
                                                <div className={styles.buttons}>
                                                    {item.sections.map(
                                                        (section) => (
                                                            <ProgressButton
                                                                key={`${item.class}/${section.category_id}`}
                                                                to={`${item.class}/${section.category_id}`}
                                                                icon={
                                                                    section.category_icon
                                                                }
                                                                level={
                                                                    section.advancement
                                                                }
                                                            >
                                                                {
                                                                    section.category_name
                                                                }
                                                            </ProgressButton>
                                                        )
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <div className={styles.empty}>
                                                Brak zagadnień
                                            </div>
                                        );

                                    return item.class === "8_kl" ||
                                        item.class === "matura_podstawowa" ||
                                        item.class === "matura_rozszerzona" ? (
                                        <ShieldAccordion
                                            {...props}
                                            icon={schoolIcon}
                                        >
                                            {children}
                                        </ShieldAccordion>
                                    ) : (
                                        <ShieldAccordion
                                            {...props}
                                            badge={romanize(
                                                parseInt(item.class)
                                            )}
                                        >
                                            {children}
                                        </ShieldAccordion>
                                    );
                                })}
                        </div>
                        <NavigationBar
                            backward={{
                                children: "WRÓĆ",
                            }}
                        />
                    </>
                )}
            </TaskContainer>
        </BackgroundContainer>
    );
};
