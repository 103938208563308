import { getColor } from "../utils";
import { IconProps } from "./Icons.typings";

export const PeopleIcon = ({ isActive, isImportant }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="43"
        height="30"
        viewBox="0 0 43 30"
    >
        <path
            id="Icon_awesome-user-friends"
            data-name="Icon awesome-user-friends"
            d="M12.9,17.25a7.5,7.5,0,1,0-7.525-7.5A7.509,7.509,0,0,0,12.9,17.25Zm5.16,2.143H17.5a10.42,10.42,0,0,1-9.2,0H7.74A7.729,7.729,0,0,0,0,27.107v1.929A3.22,3.22,0,0,0,3.225,32.25h19.35A3.22,3.22,0,0,0,25.8,29.036V27.107A7.729,7.729,0,0,0,18.06,19.393ZM32.25,17.25a6.429,6.429,0,1,0-6.45-6.429A6.441,6.441,0,0,0,32.25,17.25Zm3.225,2.143H35.22a8.5,8.5,0,0,1-5.939,0h-.255a7.481,7.481,0,0,0-3.742,1.031,9.782,9.782,0,0,1,2.667,6.683v2.571c0,.147-.034.288-.04.429H39.775A3.22,3.22,0,0,0,43,26.893a7.509,7.509,0,0,0-7.525-7.5Z"
            transform="translate(0 -2.25)"
            fill={getColor(
                isActive ? "active" : isImportant ? "important" : "default"
            )}
            opacity="1"
        />
    </svg>
);
