import clsx from "clsx";

import { TaskButton } from "../TaskButton";

import { TaskButtonGroupProps } from "./TaskButtonGroup.typings";

import styles from "./TaskButtonGroup.module.css";

export const TaskButtonGroup = ({
    to,
    progress,
    isTasksActive,
    isTheoryActive,
    isTheoryDisabled,
    isLoading,
    isProgressLoading,
    setIsClicked,
    onClick,
}: TaskButtonGroupProps) => {
    return (
        <div className={styles.container}>
            <div className={clsx(styles.column, styles.tasks)}>
                <TaskButton
                    to={to}
                    progress={progress}
                    isActive={isTasksActive}
                    isLoading={isLoading}
                    isProgressLoading={isProgressLoading}
                    setIsClicked={setIsClicked}
                    onClick={onClick ? onClick[0] : undefined}
                />
            </div>
            <div className={clsx(styles.column, styles.theory)}>
                <TaskButton
                    to={to ? `${to}/theory` : undefined}
                    isActive={isTheoryActive}
                    isLoading={isLoading}
                    isDisabled={isTheoryDisabled}
                    setIsClicked={setIsClicked}
                    onClick={onClick ? onClick[1] : undefined}
                />
            </div>
        </div>
    );
};
