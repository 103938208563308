import { useEffect, useState, useRef } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";

import {
    getAccountCookie,
    getResetPasswordCookie,
    removeResetPasswordCookie,
} from "../../../../services";

import { useLoadingContext } from "../../../../contexts/LoadingContext";
import { useAuthContext } from "../../../../contexts/AuthContext";

import { useResetPassword } from "../../hooks";
import { useMedia, useSubmitForm } from "../../../../hooks";

import { ArrowButton, DefaultButton } from "../../../../components/buttons";
import { AuthContainer } from "../../../../components/containers";
import { MainHeading } from "../../../../components/headings";
import { DefaultInput } from "../../../../components/inputs";
import {
    DisableBack,
    PasswordValidator,
    ProtectedRoute,
} from "../../../../components/utils";
import { AuthText } from "../../../../components/texts";

import { resetPasswordSchema } from "../../utils";

import styles from "./ResetPassword.module.css";

import pi from "../../../../assets/images/robots/pi-loading.svg";
import pi2 from "../../../../assets/images/robots/pi-success.svg";
import pi3 from "../../../../assets/images/robots/pi-thumb.svg";

type Props = {
    mutation: ReturnType<typeof useResetPassword>;
};

type LocationType = {
    state: { isPasswordChanged?: boolean } | null;
};

export const ResetPassword = ({ mutation }: Props) => {
    const resetPasswordCookie = getResetPasswordCookie();
    const { accountType } = getAccountCookie();

    const { state }: LocationType = useLocation();
    const navigate = useNavigate();

    const { setLoadingActive } = useLoadingContext();
    const { setIsUserLogged } = useAuthContext();

    const submitForm = useSubmitForm();
    const { isDesktop } = useMedia();

    const isFormSubmitted = useRef(false);
    const [isChanged, setIsChanged] = useState(
        state?.isPasswordChanged ?? false
    );

    useEffect(() => {
        if (mutation.error && isFormSubmitted.current) {
            isFormSubmitted.current = false;

            setLoadingActive(false, () => {
                navigate("/remind-password", {
                    state: {
                        isExpired: true,
                        message: mutation.error.response.data.messages.error,
                    },
                });
            });
        }
    }, [mutation, setLoadingActive, navigate]);

    useEffect(() => {
        if (mutation.isSuccess && !isChanged) {
            setLoadingActive(false, () => {
                setIsChanged(true);

                mutation.reset();
            });
        }
    }, [mutation, isChanged, setLoadingActive]);

    useEffect(() => {
        if (isChanged && resetPasswordCookie) {
            removeResetPasswordCookie();
        }
    }, [isChanged, resetPasswordCookie]);

    const handleBack = () => {
        removeResetPasswordCookie();

        navigate("/");
    };

    if (!mutation.isSuccess && !resetPasswordCookie && !isChanged) {
        return <Navigate to="/remind-password" replace={true} />;
    }

    return (
        <ProtectedRoute
            redirectWhenLoggedIn={state?.isPasswordChanged ? false : true}
            redirectPath="/dashboard"
        >
            <>
                {!isChanged && (
                    <ArrowButton wrapper={styles.back} onClick={handleBack}>
                        Wstecz
                    </ArrowButton>
                )}
            </>
            <AuthContainer
                position="center"
                image={isChanged ? (state?.isPasswordChanged ? pi3 : pi2) : pi}
                imageSize={isDesktop ? (isChanged ? 284 : 285) : 214}
                imagePosition="right"
                imageOffsetX={isDesktop ? (isChanged ? 41 : 78) : undefined}
                imageOffsetY={isDesktop ? 15.5556 : undefined}
            >
                <MainHeading wrapper={styles.heading} elementType="h2">
                    {isChanged
                        ? "Twoje hasło zostało zmienione!"
                        : "Ustaw nowe hasło"}
                </MainHeading>
                {isChanged ? (
                    <>
                        <AuthText>
                            {state?.isPasswordChanged ? (
                                <p>
                                    Możesz już zalogować się używając swojego
                                    <br />
                                    nowego hasła.
                                </p>
                            ) : (
                                <p>
                                    Od teraz możesz już logować się używając
                                    <br />
                                    swojego nowego hasła.
                                </p>
                            )}
                        </AuthText>
                        <DefaultButton
                            wrapper={styles.forwardButton}
                            onClick={
                                state?.isPasswordChanged
                                    ? () =>
                                          navigate(`/dashboard/${accountType}`)
                                    : () => setIsUserLogged(true)
                            }
                        >
                            PRZEJDŹ DO PANELU
                        </DefaultButton>
                    </>
                ) : (
                    <Formik
                        initialValues={{
                            password: "",
                            password_confirm: "",
                        }}
                        validationSchema={resetPasswordSchema}
                        onSubmit={(data, actions) => {
                            setLoadingActive(true, () => {
                                isFormSubmitted.current = true;

                                submitForm(data, {
                                    token: resetPasswordCookie,
                                });

                                actions.setSubmitting(false);
                            });
                        }}
                    >
                        {(formik) => (
                            <Form className={styles.form}>
                                <DefaultInput
                                    label="Nowe hasło"
                                    name="password"
                                    type="password"
                                />
                                <DefaultInput
                                    label="Powtórz nowe hasło"
                                    name="password_confirm"
                                    type="password"
                                />
                                <PasswordValidator
                                    value={formik.values.password}
                                />
                                <DefaultButton
                                    wrapper={styles.formButton}
                                    initial={{
                                        backgroundColor:
                                            DefaultButton.color.disabled,
                                    }}
                                    animate={{
                                        backgroundColor:
                                            !formik.dirty || !formik.isValid
                                                ? DefaultButton.color.disabled
                                                : DefaultButton.color.enabled,
                                    }}
                                    whileHover={{
                                        opacity: formik.isSubmitting ? 1 : 0.7,
                                    }}
                                    transition={{
                                        type: "tween",
                                        duration: 0.3,
                                    }}
                                    isSubmit={true}
                                    isDisabled={
                                        !formik.dirty || !formik.isValid
                                    }
                                >
                                    USTAW NOWE HASŁO
                                </DefaultButton>
                            </Form>
                        )}
                    </Formik>
                )}
            </AuthContainer>
            <>{isChanged && <DisableBack />}</>
        </ProtectedRoute>
    );
};
