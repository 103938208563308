import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { SolutionOnDemand, SolutionOnDemandList } from "../typings";
import {
    getSolutionOnDemandListQuery,
    getSolutionOnDemandQuery,
} from "../utils/queries";

export const useGetSolutionOnDemandList = () =>
    useQuery<AxiosResponse<SolutionOnDemandList, any>>(
        getSolutionOnDemandListQuery()
    );
export const useGetSolutionOnDemand = (
    id: string,
    onSuccess?: (data: AxiosResponse<SolutionOnDemand>) => void
) =>
    useQuery<AxiosResponse<SolutionOnDemand, any>>(
        getSolutionOnDemandQuery(id, onSuccess)
    );
