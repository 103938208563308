import { getStorePackageKey } from "./getStorePackageKey";

import { StoreDataType, StoreDiscountType } from "../../../../typings";
import { StoreKeys } from "../Store.typings";

export const getStorePrice = (
    data: StoreDataType,
    discount: StoreDiscountType
) => {
    let priceCurrent = 0;
    let priceRaw = 0;

    Object.entries(data).forEach(([key, value]) => {
        if (value.values) {
            let price = parseFloat(value.values.value.price);

            priceRaw += price;

            if (discount) {
                discount.packages.forEach((packageName) => {
                    if (
                        getStorePackageKey(key as StoreKeys, value.selected) ===
                        packageName
                    ) {
                        price = price - price * (discount.discount / 100);
                    }
                });
            }

            priceCurrent += price;
        }
    });

    return {
        current: priceCurrent.toFixed(2),
        raw: priceRaw.toFixed(2),
        discount: discount ? (priceRaw - priceCurrent).toFixed(2) : undefined,
    };
};
