import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

import { useMedia } from "../../../../hooks";

import { StudentListItem } from "../StudentListItem";

import { animationProps } from "./StudentList.animations";

import { StudentListProps } from "./StudentList.typings";

import styles from "./StudentList.module.css";

export const StudentList = ({
    data,
    mutation,
    emptyText,
    isEmpty,
    isPreview,
    isDashboard,
    isLargerOnDesktop,
}: StudentListProps) => {
    const { isDesktop } = useMedia();

    return (
        <div className={clsx(styles.container, isPreview && styles.preview)}>
            <AnimatePresence
                mode={isDesktop ? undefined : "popLayout"}
                initial={false}
            >
                {data.length > 0 ? (
                    data.map((object) => (
                        <motion.div
                            key={object.user_id ?? object.email}
                            layout={!isDesktop}
                            {...(isDesktop ? undefined : animationProps)}
                        >
                            <StudentListItem
                                name={
                                    object.first_name &&
                                    object.last_name &&
                                    `${object.first_name} ${object.last_name}`
                                }
                                id={object.user_id}
                                email={object.email}
                                schoolClass={object.class}
                                mutation={mutation}
                                isPreview={isPreview}
                                isDesktop={isDesktop}
                                isInvited={object.invited_by === object.email}
                                isUnconfirmed={object.status === "Zaproszony"}
                                isDashboard={isDashboard}
                                isLargerOnDesktop={isLargerOnDesktop}
                            />
                        </motion.div>
                    ))
                ) : (
                    <motion.div
                        key="student_empty"
                        className={styles.empty}
                        layout={!isDesktop}
                        {...(isDesktop ? undefined : animationProps)}
                    >
                        {isEmpty
                            ? emptyText.initial
                            : emptyText.search ?? emptyText.initial}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};
