import { SchoolType } from "../../../../typings";
import { getClassList } from "../../../../utils";

export const getTasksFilters = (school: SchoolType, romanize = false) => {
    const classList = getClassList(school, romanize);
    const tasksFilters: { value: string; isNumber: boolean }[] = [];

    // FIXME: dla szkoly sredniej tylko matura podstawowa - wywalic potem
    if (school !== "Podstawowa") {
        tasksFilters.push({ value: "matura_podstawowa", isNumber: false });
        return tasksFilters;
    }

    classList.forEach((schoolClass) => {
        tasksFilters.push({ value: schoolClass, isNumber: true });
    });

    switch (school) {
        case "Podstawowa":
            tasksFilters.push({ value: "8_kl", isNumber: false });
            break;
        default:
            tasksFilters.push(
                { value: "matura_podstawowa", isNumber: false },
                { value: "matura_rozszerzona", isNumber: false }
            );
    }

    return tasksFilters;
};
