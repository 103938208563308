export const getAccountTypeFromUrl = (url: string) => {
    const isStudent = url.indexOf("/dashboard/student") !== -1;
    const isParent = url.indexOf("/dashboard/parent") !== -1;
    const isTeacher = url.indexOf("/dashboard/teacher") !== -1;

    return isStudent
        ? "student"
        : isParent
        ? "parent"
        : isTeacher
        ? "teacher"
        : undefined;
};
