import { Navigate, useLocation } from "react-router-dom";

import { useAuthContext } from "../../../../../contexts/AuthContext";

import { getAccountCookie } from "../../../../../services";

import { DefaultButton } from "../../../../../components/buttons";
import { AuthContainer } from "../../../../../components/containers";
import { MainHeading } from "../../../../../components/headings";
import { DisableBack, ProtectedRoute } from "../../../../../components/utils";

import styles from "./AccountDeleted.module.css";

import pi from "../../../../../assets/images/robots/pi-thumb.svg";

type StateType = {
    state: { isDeleted?: boolean } | null;
};

export const AccountDeleted = () => {
    const { accountType, isParentConfirmationRequired } = getAccountCookie();

    const { state }: StateType = useLocation();
    const { logout } = useAuthContext();

    if (!state?.isDeleted) {
        return <Navigate to={`/dashboard/${accountType}`} replace={true} />;
    }

    return (
        <ProtectedRoute>
            <AuthContainer
                position="center"
                image={pi}
                imageSize={214}
                imagePosition="right"
            >
                <MainHeading wrapper={styles.heading} elementType="h2">
                    {accountType === "student" && isParentConfirmationRequired
                        ? "Prośba o usunięcie konta została wysłana"
                        : "Twoje konto zostało usunięte"}
                </MainHeading>
                <DefaultButton
                    wrapper={styles.forwardButton}
                    whileHover={{ opacity: 0.7 }}
                    onClick={logout}
                >
                    STRONA GŁÓWNA
                </DefaultButton>
            </AuthContainer>
            <DisableBack />
        </ProtectedRoute>
    );
};
