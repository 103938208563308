import React, { useState, useRef, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import parse from "html-react-parser";
import clsx from "clsx";

import { useAnswersContext } from "../../../contexts/AnswersContext";
import { useResultContext } from "../../../contexts/ResultContext";

import { useMedia } from "../../../../../hooks";

import { getDropId, getIsValidArea } from "./utils";
import { replaceOptions } from "../../../utils";

import {
    answerVariants,
    dragTransition,
    fieldVariants,
    rowVariants,
    springTransition,
    transition,
} from "./AnswerFill.animations";
import { AnswerProps, FillProps, ValuesProps } from "./AnswerFill.typings";

import styles from "./AnswerFill.module.css";

export const AnswerFill = ({ id, rows, answers, isLoading }: FillProps) => {
    const { isDesktop } = useMedia();

    const { getProvidedAnswer, provideAnswer, removeProvidedAnswer } =
        useAnswersContext();
    const answer = getProvidedAnswer(id);
    const isAnswerProvided = useRef(false);

    const { getResult, isResultsLoading } = useResultContext();
    const result = getResult(id, "match");
    const answersSelected = result ? result.answers : undefined;

    const [dropId, setDropId] = useState<string | undefined>(undefined);
    const [values, setValues] = useState<ValuesProps>(answer ?? []);

    const [dragIndex, setDragIndex] = useState(10);

    useEffect(() => {
        if (values.length > 0) {
            isAnswerProvided.current = true;

            provideAnswer({
                id,
                type: "match",
                value: values,
            });
        } else if (isAnswerProvided.current) {
            isAnswerProvided.current = false;

            removeProvidedAnswer(id);
        }
    }, [values, id, provideAnswer, removeProvidedAnswer]);

    return (
        <div className={styles.container}>
            {rows.map((row) => {
                const isTriple = row.parts.length === 3;
                const isSuccess = answersSelected
                    ? answersSelected.some(
                          (answer) => answer.id === row.id && answer.correct
                      )
                    : undefined;
                const isError = answersSelected
                    ? answersSelected.some(
                          (answer) => answer.id === row.id && !answer.correct
                      )
                    : undefined;

                return (
                    <React.Fragment key={row.id}>
                        <motion.div
                            className={clsx(
                                styles.row,
                                isTriple && styles.triple
                            )}
                            variants={rowVariants}
                            initial="default"
                            animate={
                                answersSelected && isError
                                    ? "answered"
                                    : "default"
                            }
                            transition={transition}
                        >
                            {row.parts.map((part, partIndex) => {
                                const isFilled = values.find(
                                    (value) => value.id === row.id
                                );

                                return (
                                    <div
                                        key={`${row.id}-${partIndex}`}
                                        className={clsx(
                                            styles.column,
                                            partIndex === 0 && styles.first,
                                            ((partIndex === 1 && !isTriple) ||
                                                (partIndex === 2 &&
                                                    isTriple)) &&
                                                styles.last
                                        )}
                                    >
                                        {part.is_answer ? (
                                            <motion.div
                                                className={clsx(
                                                    styles.fill,
                                                    (!isFilled ||
                                                        isLoading ||
                                                        isSuccess ||
                                                        isError ||
                                                        isResultsLoading) &&
                                                        styles.disabled
                                                )}
                                                data-fillid={row.id}
                                                variants={fieldVariants}
                                                initial={
                                                    isDesktop
                                                        ? "default_desktop"
                                                        : "default"
                                                }
                                                animate={
                                                    isFilled
                                                        ? isSuccess
                                                            ? "success"
                                                            : isError
                                                            ? "error"
                                                            : "fill"
                                                        : dropId === row.id
                                                        ? "highlight"
                                                        : isDesktop
                                                        ? "default_desktop"
                                                        : "default"
                                                }
                                                transition={transition}
                                                onClick={
                                                    isFilled
                                                        ? () =>
                                                              setValues(
                                                                  (values) =>
                                                                      values.filter(
                                                                          (
                                                                              value
                                                                          ) =>
                                                                              value.id !==
                                                                              row.id
                                                                      )
                                                              )
                                                        : undefined
                                                }
                                            >
                                                <AnimatePresence
                                                    initial={false}
                                                >
                                                    {values.map((value) =>
                                                        value.id === row.id ? (
                                                            <motion.div
                                                                key={
                                                                    value.answer
                                                                        .id
                                                                }
                                                                className={
                                                                    styles.fillContent
                                                                }
                                                                initial={{
                                                                    y: -42,
                                                                }}
                                                                animate={{
                                                                    y: 0,
                                                                }}
                                                                exit={{ y: 42 }}
                                                                transition={
                                                                    springTransition
                                                                }
                                                            >
                                                                {parse(
                                                                    value.answer
                                                                        .content,
                                                                    replaceOptions()
                                                                )}
                                                            </motion.div>
                                                        ) : null
                                                    )}
                                                </AnimatePresence>
                                            </motion.div>
                                        ) : (
                                            parse(
                                                part.content,
                                                replaceOptions()
                                            )
                                        )}
                                    </div>
                                );
                            })}
                        </motion.div>
                        <AnimatePresence mode="wait">
                            {answersSelected && isError && (
                                <motion.div
                                    key={`${row.id}-answer`}
                                    className={clsx(
                                        styles.row,
                                        styles.correct,
                                        isTriple && styles.triple
                                    )}
                                    initial={{ height: 0, marginBottom: 0 }}
                                    animate={{
                                        height: "auto",
                                        marginBottom: 10,
                                    }}
                                    exit={{ height: 0, marginBottom: 0 }}
                                    transition={transition}
                                >
                                    {row.parts.map((part, partIndex) =>
                                        (!isTriple &&
                                            partIndex === 0 &&
                                            !part.is_answer) ||
                                        (!isTriple &&
                                            partIndex === 1 &&
                                            part.is_answer) ||
                                        (!isTriple &&
                                            partIndex === 0 &&
                                            part.is_answer) ||
                                        (partIndex !== 2 && isTriple) ? (
                                            <div
                                                key={`${row.id}-${partIndex}-answer`}
                                                className={clsx(
                                                    styles.column,
                                                    partIndex === 0 &&
                                                        styles.first,
                                                    ((partIndex === 1 &&
                                                        !isTriple) ||
                                                        (partIndex === 2 &&
                                                            isTriple)) &&
                                                        styles.last
                                                )}
                                            >
                                                {part.is_answer && (
                                                    <div
                                                        className={
                                                            styles.result
                                                        }
                                                    >
                                                        {parse(
                                                            answersSelected.find(
                                                                (answer) =>
                                                                    answer.id ===
                                                                    row.id
                                                            )?.content || "",
                                                            replaceOptions()
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        ) : null
                                    )}
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </React.Fragment>
                );
            })}
            <div className={styles.answers}>
                <p className={styles.title}>
                    Przenieś odpowiedzi we właściwe miejsca:
                </p>
                <div className={styles.list}>
                    {answers.map((answer) => (
                        <div key={answer.id} className={styles.item}>
                            <AnimatePresence initial={false}>
                                {!values.find(
                                    (value) => value.answer.id === answer.id
                                ) && (
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={transition}
                                    >
                                        <Answer
                                            id={answer.id}
                                            dropId={dropId}
                                            rows={rows}
                                            content={answer.content}
                                            setDropId={setDropId}
                                            setValues={setValues}
                                            dragIndex={dragIndex}
                                            setDragIndex={setDragIndex}
                                            isLoading={isLoading}
                                            isDesktop={isDesktop}
                                            isDisabled={
                                                !!result || isResultsLoading
                                            }
                                        />
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

const Answer = ({
    id,
    dropId,
    rows,
    content,
    setDropId,
    setValues,
    dragIndex,
    setDragIndex,
    isLoading,
    isDesktop,
    isDisabled,
}: AnswerProps) => {
    const currentFieldId = useRef<string | undefined>(undefined);

    const [isDragging, setIsDragging] = useState(false);
    const [dragIndexCurrent, setDragIndexCurrent] = useState(dragIndex);

    const handleOnDragStart = () => {
        setDragIndexCurrent(() => {
            setIsDragging(true);

            return dragIndex + 1;
        });
    };

    const handleOnDrag = (event: MouseEvent | TouchEvent | PointerEvent) => {
        const answerFieldId = getDropId("fillid", event);

        if (getIsValidArea(answerFieldId, rows)) {
            if (currentFieldId.current !== answerFieldId) {
                currentFieldId.current = answerFieldId;

                setDropId(answerFieldId);
            }
        } else {
            if (currentFieldId.current) {
                currentFieldId.current = undefined;
            }

            setDropId(undefined);
        }
    };

    const handleOnDragEnd = () => {
        if (dropId) {
            setValues((values) => {
                setDropId(undefined);

                return [
                    ...values.filter((value) => value.id !== dropId),
                    {
                        id: dropId,
                        answer: {
                            id,
                            content,
                        },
                    },
                ];
            });
        } else {
            setIsDragging(false);
        }

        setDragIndex(dragIndexCurrent);
    };

    return (
        <motion.div
            key={id}
            className={clsx(
                styles.answer,
                isLoading || (isDisabled && styles.disabled)
            )}
            drag={true}
            style={{ zIndex: dragIndexCurrent }}
            onDragStart={handleOnDragStart}
            onDrag={handleOnDrag}
            onDragEnd={handleOnDragEnd}
            variants={answerVariants}
            initial="default"
            animate={
                isDragging
                    ? dropId
                        ? "drop"
                        : "drag"
                    : isDesktop
                    ? "default_desktop"
                    : "default"
            }
            dragSnapToOrigin={dropId ? false : true}
            dragMomentum={false}
            transition={transition}
            dragTransition={dragTransition}
        >
            <div className={styles.answerContent}>
                {parse(content, replaceOptions())}
            </div>
        </motion.div>
    );
};
