type ReturnRaw = "student" | "parent" | "teacher";
type ReturnParsed = "Uczeń" | "Nauczyciel" | "Rodzic" | "Invalid";

export const getAccountType = <B extends boolean>(
    pathname: string,
    isRaw?: B
): B extends true ? ReturnRaw : ReturnParsed => {
    const type = pathname
        .replace("register", "")
        .replaceAll("/", "") as ReturnRaw;

    if (isRaw) {
        return type as any;
    } else {
        return parseAccountType(type) as any;
    }
};

const parseAccountType = (type: string): ReturnParsed => {
    switch (type) {
        case "student":
            return "Uczeń";
        case "teacher":
            return "Nauczyciel";
        case "parent":
            return "Rodzic";
        default:
            return "Invalid";
    }
};
