import { useNavigation } from "react-router-dom";
import clsx from "clsx";

import { NavigationBubble, NavigationBubbleProps } from "../NavigationBubble";

import styles from "./NavigationBubbles.module.css";

type Props<T extends string | undefined> = {
    elements: NavigationBubbleProps<T>[];
};

export const NavigationBubbles = <T extends string | undefined>({ elements }: Props<T>) => {
    const { state } = useNavigation();
    const isLoadingState = state === "loading";

    return (
        <div className={styles.container}>
            {elements.map((element) => {
                const elementProps = {
                    to: element.to,
                    state: element.state,
                    icon: element.icon,
                    isLoading: isLoadingState,
                    isFetching: element.isFetching,
                    isError: element.isError,
                    isAvailableSoon: element.isAvailableSoon,
                    isDisabled: element.isDisabled
                };

                return (
                    <div
                        key={element.icon}
                        className={clsx(
                            styles.bubble,
                            element.type === "stats" && styles.wide
                        )}
                    >
                        {element.type === "stats" ? (
                            <NavigationBubble
                                type="stats"
                                {...elementProps}
                                stats={element.stats}
                            >
                                {element.children}
                            </NavigationBubble>
                        ) : (
                            <NavigationBubble type="normal" {...elementProps}>
                                {element.children}
                            </NavigationBubble>
                        )}
                    </div>
                );
            })}
        </div>
    );
};
