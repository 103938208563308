import { romanize as romanizeFn } from "romans";

import { SchoolType } from "../typings";

export const getClassList = (
    school: SchoolType,
    romanize = false
): string[] => {
    let numberOfClasses = 0;
    const classList = [];

    switch (school) {
        case "Podstawowa":
            numberOfClasses = 8;
            break;
        case "Liceum":
            numberOfClasses = 4;
            break;
        case "Technikum":
            numberOfClasses = 5;
            break;
        case "Branżowa":
            numberOfClasses = 3;
            break;
    }

    while (numberOfClasses > 0) {
        if (romanize) {
            classList.push(romanizeFn(numberOfClasses));
        } else {
            classList.push(numberOfClasses.toString());
        }

        numberOfClasses--;
    }

    return school === 'Podstawowa' ? classList.reverse().splice(3, 8) : classList.reverse();
};
