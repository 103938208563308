const colors = {
    default: "#ffffff",
    success: "#15B745",
    error: "#FE5A5E",
};

const buttonVariants = {
    default: {
        borderColor: colors.default,
    },
    success: {
        borderColor: colors.success,
    },
    error: {
        borderColor: colors.error,
    },
};

const contentVariants = {
    default: {
        paddingLeft: 0,
    },
    finished: {
        paddingLeft: 20,
    },
};

export { buttonVariants, contentVariants };
