import { Placement } from "@popperjs/core";

export const getPosition = (placement: Placement) => {
    if (placement.indexOf("top") !== -1) {
        return "top";
    }

    if (placement.indexOf("right") !== -1) {
        return "right";
    }

    if (placement.indexOf("bottom") !== -1) {
        return "bottom";
    }

    if (placement.indexOf("left") !== -1) {
        return "left";
    }

    return "top";
};