import { useSubmit } from "react-router-dom";

import { getParsedFormData } from "../modules/auth/utils";

type FormData = { [name: string]: any };

export const useSubmitForm = () => {
    const submit = useSubmit();

    const submitForm = (data: FormData, object?: FormData) => {
        submit(
            getParsedFormData({
                ...data,
                ...object,
            }),
            {
                method: "post",
            }
        );
    };

    return submitForm;
};
