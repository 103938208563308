import { useState, useRef, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import clsx from "clsx";

import { useStorageFlag } from "../../hooks";

import { SlimButton } from "../../../../components/buttons";
import { CollapseDesktop } from "../CollapseDesktop";
import { DefaultModal } from "../../../../components/modals";

import { getPreviousPath } from "../../../../utils";

import { ChildrenItemDesktopProps } from "./ChildrenItemDesktop.typings";

import styles from "./ChildrenItemDesktop.module.css";

import checkIcon from "../../images/dashboard/parent/check-icon.svg";
import graphIcon from "../../images/dashboard/parent/graph-icon.svg";
import trashIcon from "../../images/dashboard/parent/trash-icon.svg";

export const ChildrenItemDesktop = ({
    id,
    name,
    email,
    schoolClass,
    mutation,
    elements,
    isInvited,
    isUnconfirmed,
    isDashboard,
}: ChildrenItemDesktopProps) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { state } = useNavigation();

    const isMutated = useRef(mutation.isError ?? false);
    const [isError, setIsError] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    useStorageFlag(["popOpen", "modal"], isModalOpen);

    const [loadingRoute, setLoadingRoute] = useState<"student-progress" | "">(
        ""
    );

    const handleAcceptMutation = () => {
        isMutated.current = true;

        mutation.mutate({
            action: "accept",
            email,
        });
    };

    const handleRemoveMutation = () => {
        isMutated.current = true;

        if (isUnconfirmed) {
            mutation.mutate({
                action: "decline",
                email,
            });
        } else {
            mutation.mutate({
                action: "remove",
                email,
            });
        }

        setIsModalOpen(false);
    };

    useEffect(() => {
        if (mutation.isError && isMutated.current) {
            isMutated.current = false;

            setIsError(true);
        }
    }, [mutation]);

    useEffect(() => {
        if (isError) {
            setTimeout(() => {
                setIsError(false);
            }, 1500);
        }
    }, [isError]);

    return (
        <>
            <div className={styles.container}>
                <div
                    className={clsx(
                        styles.heading,
                        isUnconfirmed && styles.disabled
                    )}
                >
                    <p className={styles.name}>{name}</p>
                    {!schoolClass ? (
                        <p className={styles.class}>(niepotwierdzony)</p>
                    ) : (
                        <p className={styles.class}>
                            {email}
                            <br />
                            (KLASA {schoolClass}){" "}
                            {isUnconfirmed && "- niepotwierdzony"}
                        </p>
                    )}
                    <div className={styles.buttonGroup}>
                        {isUnconfirmed && isInvited && (
                            <SlimButton
                                color="alternative"
                                icon={checkIcon}
                                onClick={handleAcceptMutation}
                            >
                                POTWIERDŹ
                            </SlimButton>
                        )}
                        <SlimButton
                            icon={graphIcon}
                            isDisabled={isUnconfirmed}
                            isLoading={
                                state === "loading" &&
                                loadingRoute === "student-progress"
                            }
                            onClick={() => {
                                setLoadingRoute("student-progress");

                                navigate(
                                    isDashboard
                                        ? `${pathname}/student-progress/${id}`
                                        : `${getPreviousPath(
                                              pathname,
                                              2
                                          )}/student-progress/${id}`
                                );
                            }}
                        >
                            POSTĘPY NAUKI
                        </SlimButton>
                        <SlimButton
                            color="important"
                            icon={trashIcon}
                            onClick={() => setIsModalOpen(true)}
                        />
                    </div>
                </div>
                <div className={styles.main}>
                    <CollapseDesktop
                        elements={elements}
                        isDisabled={isUnconfirmed}
                    />
                </div>
                <AnimatePresence mode="wait">
                    {isError && (
                        <motion.div
                            className={styles.error}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{
                                type: "tween",
                                duration: 0.2,
                            }}
                        >
                            <p>Wystąpił nieoczekiwany błąd.</p>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            <DefaultModal
                title="USUŃ"
                actions={[
                    {
                        name: "TAK, USUŃ",
                        isImportant: true,
                        onClick: handleRemoveMutation,
                    },
                    {
                        name: "NIE, WRÓĆ",
                        onClick: () => setIsModalOpen(false),
                    },
                ]}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            >
                <p>
                    Czy na pewno chcesz przestać śledzić postępy nauki
                    użytkownika:
                </p>
                <p>
                    <strong>
                        {name} (uczeń)
                        <br />
                        {email}
                    </strong>
                </p>
            </DefaultModal>
        </>
    );
};
