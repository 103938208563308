import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

import {
    setAccountCookie,
    setRemindPasswordCookie,
} from "../../../../services";

type Mutation = {
    [name: string]: FormDataEntryValue;
};

export const useRemindPasswordCode = () => {
    return useMutation<AxiosResponse<any, any>, any, Mutation, unknown>(
        (data) => {
            setAccountCookie({
                userEmail: data.email as string,
            });
            
            return mainApi.post("reset_password_code", {
                email: data.email,
            });
        },
        {
            onSuccess: (data) => setRemindPasswordCookie(data.data.email),
        }
    );
};
