import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { useAiSession } from "./useAiSession";

import { mainApi } from "../../../../api";

import { studentGetToKnowDataQuery } from "../../../competencyTest/utils";

type Response = {
    status: number;
};

type Mutation = {
    taskId: string;
    sessionId: string;
    answeredRight: boolean;
    skipped: boolean;
};

export const useAnswerAiSession = () => {
    const queryClient = useQueryClient();

    const { mutate: setAiSession } = useAiSession();

    return useMutation<AxiosResponse<Response, any>, any, Mutation, unknown>(
        (data) =>
            mainApi.post("answer_ai_session_exercise", {
                session_id: data.sessionId,
                exercise_id: data.taskId,
                answered_right: data.skipped ? false : data.answeredRight,
                skipped: data.skipped,
            }),
        {
            onSuccess: (data) => {
                if (data.status === 200) {
                    queryClient.removeQueries(
                        studentGetToKnowDataQuery().queryKey
                    );

                    setAiSession();
                }
            },
        }
    );
};
