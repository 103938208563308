import { useState } from "react";
import {
    Navigate,
    useLocation,
    useNavigate,
    useNavigation,
} from "react-router-dom";

import { useMedia } from "../../../../hooks";
import { useResetCompetencyTest } from "../../../competencyTest/hooks/mutations";

import { ArrowButton, DefaultButton } from "../../../../components/buttons";
import { AuthContainer } from "../../../../components/containers";
import { MainHeading } from "../../../../components/headings";
import { AuthText } from "../../../../components/texts";
import { ButtonLoader } from "../../../../components/utils";

import { CompetencyTestLocation } from "../../../competencyTest/typings";

import styles from "./AiSessionFinished.module.css";

import pi from "../../../../assets/images/robots/pi-happy-2.svg";

export const AiSessionFinished = () => {
    const { isDesktop } = useMedia();

    const navigate = useNavigate();
    const { state }: CompetencyTestLocation = useLocation();
    const { state: navigationState } = useNavigation();

    const {
        mutate: resetCompetencyTest,
        isLoading: isResetCompetencyTestLoading,
    } = useResetCompetencyTest();

    const [loadingRoute, setLoadingRoute] = useState("");
    const isLoadingState = navigationState === "loading";

    if (!state) {
        return <Navigate to="/dashboard/student" replace={true} />;
    }

    return (
        <>
            <ArrowButton
                to="/dashboard/student"
                wrapper={styles.back}
                isDisabled={isLoadingState || isResetCompetencyTestLoading}
            >
                Wstecz
            </ArrowButton>
            <AuthContainer
                position="center"
                image={pi}
                imageSize={isDesktop ? 318 : 240}
                imagePosition="right"
                imageOffsetX={isDesktop ? -27 : undefined}
                imageOffsetY={isDesktop ? 12.4074074 : undefined}
            >
                <div className={styles.container}>
                    <MainHeading wrapper={styles.heading} elementType="h2">
                        Cały dostępny materiał już za Tobą!
                    </MainHeading>
                    <AuthText>
                        <p>
                            Chcesz poprawić swój wynik? Przejdź do bazy zadań i
                            przelicz zadania pominięte/zrobione błędnie.
                            <br />
                            Jeśli chcesz uczyć się dalej, z naszą sztuczną
                            inteligencją, zmień cel nauki na nowy.
                        </p>
                    </AuthText>
                    <div className={styles.buttons}>
                        <DefaultButton
                            whileHover={{ opacity: 0.7 }}
                            isDisabled={
                                isLoadingState || isResetCompetencyTestLoading
                            }
                            onClick={() => {
                                setLoadingRoute("tasks");

                                navigate("/dashboard/student/tasks");
                            }}
                        >
                            <ButtonLoader
                                condition={
                                    loadingRoute === "tasks" && isLoadingState
                                }
                                color="white"
                            >
                                BAZA ZADAŃ
                            </ButtonLoader>
                        </DefaultButton>
                        <DefaultButton
                            className={styles.changeButton}
                            whileHover={{ opacity: 0.7 }}
                            isDisabled={
                                isLoadingState || isResetCompetencyTestLoading
                            }
                            onClick={() => {
                                setLoadingRoute("reset");

                                resetCompetencyTest();
                            }}
                        >
                            <ButtonLoader
                                condition={
                                    isResetCompetencyTestLoading ||
                                    (loadingRoute === "reset" && isLoadingState)
                                }
                                color="white"
                            >
                                ZMIEŃ CEL NAUKI
                            </ButtonLoader>
                        </DefaultButton>
                    </div>
                </div>
            </AuthContainer>
        </>
    );
};
