import { TaskHeading } from "../../../../../components/headings";
import { TaskContent } from "../TaskContent";

import styles from "./TaskSolution.module.css";

type Props = {
    content: string;
};

export const TaskSolution = ({ content }: Props) => {
    return (
        <div className={styles.container}>
            <TaskHeading title="Rozwiązanie" isRoundingDisabled={true} />
            <div className={styles.content}>
                <TaskContent
                    content={
                        content !== ""
                            ? content
                            : "<p><strong>Rozwiązanie nie zostało uzupełnione.</strong></p>"
                    }
                />
            </div>
        </div>
    );
};
