export const parseMathtypeAnswer = (string: string) => {
    const entryParsedString = string
        .replaceAll("<mo>&nbsp;</mo>", "")
        .replaceAll(
            '<math xmlns="http://www.w3.org/1998/Math/MathML"><mrow>',
            '<math xmlns="http://www.w3.org/1998/Math/MathML">'
        )
        .replaceAll("</mrow></math>", "</math>")
        .replaceAll(' columnalign="left"', "")
        .replaceAll(' columnalign="right"', "");

    return parseInvalidBrackets(entryParsedString);
};

const parseInvalidBrackets = (string: string) => {
    const openKey = 'close="" open="';
    const closeKey = '<mfenced close=""';

    let position = 0;
    let newString = "";

    while (position > -1) {
        const foundClosePosition = string.indexOf(closeKey, position);
        const openBracketPosition = string.indexOf(openKey, position);

        if (foundClosePosition === -1) {
            newString += string.substring(position, string.length);
        } else {
            newString +=
                string.substring(position, foundClosePosition) +
                `<mfenced open="${string.charAt(
                    openBracketPosition + openKey.length
                )}" close="">`;
        }

        position = foundClosePosition !== -1 ? foundClosePosition + 27 : -1;
    }

    return newString;
};
