import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useMedia, useRedirectByPop } from "../../../../hooks";
import { useGetLongDescription } from "./hooks";

import { useSubcategoriesPerWeek } from "../../hooks/mutations";
import { useGetExamDates, useStudentGetToKnowData } from "../../hooks/queries";

import { useRedirectContext } from "../../../../contexts/RedirectContext";

import { DefaultButton } from "../../../../components/buttons";
import { BackgroundContainer } from "../../../../components/containers";
import { DashboardHeading } from "../../../../components/headings";
import { CircleLoader } from "../../../../components/loaders";
import { ButtonLoader } from "../../../../components/utils";
import { RedirectLoading, ValuePicker } from "../../components";

import { getPreviousPath } from "../../../../utils";
import { calculatePercentage, getShortDescription } from "./utils";

import { CompetencyTestLocation, ScheduleValue } from "../../typings";

import styles from "./Schedule.module.css";

import infinityImg from "../../../../assets/images/robots/infinity-pleased.svg";

export const Schedule = () => {
    const { isSmallerDesktop } = useMedia();
    const { pathname, state }: CompetencyTestLocation = useLocation();

    const { setRedirect } = useRedirectContext();

    const [picker, setPicker] = useState<ScheduleValue>(1);

    const studentGetToKnowDataQuery = useStudentGetToKnowData();
    const getExamDatesQuery = useGetExamDates();
    const subcategoriesPerWeekMutation = useSubcategoriesPerWeek();

    const longDescription = useGetLongDescription({
        picker,
        weeksLeft: getExamDatesQuery.data?.data.weeks_left ?? 0,
        subjectCount: getExamDatesQuery.data?.data.subject_count ?? 0,
    });

    const backwardPathname = getPreviousPath(pathname, 2);

    useRedirectByPop(backwardPathname);

    useEffect(() => {
        setRedirect(backwardPathname);
    }, [backwardPathname, setRedirect]);

    if (!state) {
        return <RedirectLoading />;
    }

    return (
        <BackgroundContainer
            leftElement={{
                image: infinityImg,
                position: { x: isSmallerDesktop ? 30 : 180, y: 180 },
                size: { height: 458, width: 250 },
            }}
        >
            {(getExamDatesQuery.isLoading ||
                studentGetToKnowDataQuery.isLoading) && (
                <div className={styles.loader}>
                    <CircleLoader />
                </div>
            )}
            {getExamDatesQuery.data && studentGetToKnowDataQuery.data && (
                <div className={styles.schedule}>
                    <DashboardHeading
                        title="HARMONOGRAM"
                        subtitle="ile chcesz się uczyć?"
                        to={backwardPathname}
                        hasBorder={true}
                    />
                    <div className={styles.subtitle}>ile chcesz się uczyć?</div>
                    {studentGetToKnowDataQuery.data.data.data.scope ===
                        "full" && (
                        <div className={styles.info}>
                            Czas jaki pozostał do egzaminu:{" "}
                            <span className={styles.bold}>
                                {getExamDatesQuery.data.data.weeks_left} tygodni
                            </span>
                            .
                        </div>
                    )}

                    <div className={styles.info}>
                        Tematów do opanowania:{" "}
                        <span className={styles.bold}>
                            {getExamDatesQuery.data.data.subject_count}
                        </span>
                        .
                    </div>

                    <div className={styles.title}>
                        Ile zagadnień chcesz opracować tygodniowo?
                    </div>
                    <ValuePicker value={picker} setValue={setPicker} />

                    <div className={styles.card}>
                        {longDescription}
                        {studentGetToKnowDataQuery.data.data.data.scope ===
                            "full" && (
                            <div className={styles.bold}>
                                {getShortDescription(
                                    calculatePercentage({
                                        picker,
                                        weeksLeft:
                                            getExamDatesQuery.data.data
                                                .weeks_left,
                                        subjectCount:
                                            getExamDatesQuery.data.data
                                                .subject_count,
                                    })
                                )}
                            </div>
                        )}
                    </div>

                    <DefaultButton
                        onClick={() =>
                            subcategoriesPerWeekMutation.mutate({
                                subcategoriesPerWeek: picker,
                            })
                        }
                    >
                        <ButtonLoader
                            condition={subcategoriesPerWeekMutation.isLoading}
                            color="white"
                        >
                            PRZEJDŹ DALEJ
                        </ButtonLoader>
                    </DefaultButton>
                </div>
            )}
        </BackgroundContainer>
    );
};
