import { useState, useEffect } from "react";

import { ButtonLoader } from "../../../../components/utils";
import { LogoIcon } from "./icons";

import styles from "./HeaderButton.module.css";

type Props = {
    children: string;
    hasIcon?: boolean;
    isError?: boolean;
    onClick?: () => void;
};

export const HeaderButton = ({
    children,
    hasIcon = true,
    isError,
    onClick,
}: Props) => {
    const [isClicked, setIsClicked] = useState(false);

    const handleOnClick = () => {
        setIsClicked(true);

        if (onClick) {
            onClick();
        }
    };

    useEffect(() => {
        if (isClicked && isError) {
            setIsClicked(false);
        }
    }, [isClicked, isError]);

    return (
        <button className={styles.button} type="button" disabled={isClicked} onClick={handleOnClick}>
            <ButtonLoader condition={isClicked}>{children}</ButtonLoader>
            {hasIcon && (
                <span className={styles.icon}>
                    <LogoIcon />
                </span>
            )}
        </button>
    );
};
