import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

type Response = any;

type Mutation = {
    id: string;
};

export const useReadUserNotification = (queryKeys: (string | number)[][]) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse<Response, any>, any, Mutation, unknown>(
        (data) =>
            mainApi.post("set_notification_as_read", {
                notification_id: data.id,
            }),
        {
            onSuccess: () => {
                queryKeys.forEach((queryKey) => {
                    queryClient.invalidateQueries(queryKey);
                });
            },
        }
    );
};
