import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { learningProgressQuery } from "../../utils";

import { DifficultyLevels } from "../../../tasks/typings";
import { ClassType, LearningProgressAccountType } from "../../typings";

type Response = {
    exercise_done_count: number;
    exam_days_left: number;
    user_plan_status: "0" | "1" | "2";
    progress: {
        title: string;
        class: ClassType;
        percentage_done: number;
        sections: {
            category_name: string;
            category_id: string;
            category_icon: string;
            category_icon2: string;
            advancement: DifficultyLevels["advanced"];
        }[];
    }[];
};

export const useLearningProgress = <T extends LearningProgressAccountType>(
    type: T,
    childrenId: T extends "other" ? string : undefined
) => {
    return useQuery<AxiosResponse<Response, any>>(
        learningProgressQuery(type, childrenId)
    );
};
