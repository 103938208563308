import { createContext, useContext, useState, useMemo } from "react";

type ProviderProps = {
    children: JSX.Element;
};

type ContextType = {
    redirect: string | undefined;
    setRedirect: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const RedirectContext = createContext<ContextType | null>(null);

export const RedirectProvider = ({ children }: ProviderProps) => {
    const [redirect, setRedirect] = useState<string | undefined>(undefined);

    const redirectContextMemoized: ContextType = useMemo(
        () => ({ redirect, setRedirect }),
        [redirect]
    );

    return (
        <RedirectContext.Provider value={redirectContextMemoized}>
            {children}
        </RedirectContext.Provider>
    );
};

export const useRedirectContext = () =>
    useContext(RedirectContext) as ContextType;
