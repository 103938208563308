import { AnimatePresence, motion } from "framer-motion";

import { MainLogo, LoadingLogo } from "../../logos";

import styles from "./AppLoading.module.css";

type Props = {
    duration: {
        container: number;
        element: number;
    };
    isActive: boolean;
};

export const AppLoading = ({ duration, isActive }: Props) => {
    return (
        <AnimatePresence mode="wait">
            {isActive && (
                <motion.div
                    id="appLoading"
                    className={styles.loading}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                        type: "tween",
                        duration: duration.container / 1000,
                    }}
                >
                    <div className={styles.container}>
                        <motion.div
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: 20, opacity: 0 }}
                            transition={{
                                type: "tween",
                                duration: duration.element / 1000,
                            }}
                        >
                            <MainLogo />
                        </motion.div>
                        <motion.div
                            className={styles.logo}
                            initial={{ scale: 0.7 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0.4 }}
                            transition={{
                                type: "tween",
                                duration: duration.element / 1000,
                            }}
                        >
                            <LoadingLogo />
                        </motion.div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
