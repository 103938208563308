const collapseVariants = {
    closed: {
        height: 0,
    },
    open: {
        height: "auto",
    },
};

const indicatorVariants = {
    closed: {
        rotate: 0,
    },
    open: {
        rotate: 180,
    },
};

export { collapseVariants, indicatorVariants };
