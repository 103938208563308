export const removeMathModals = (modals: {
    dialogs: NodeListOf<Element>;
    overlays: NodeListOf<Element>;
}) => {
    const { dialogs, overlays } = modals;

    dialogs.forEach((dialog, index) => {
        dialog.remove();

        overlays[index].remove();
    });
};
