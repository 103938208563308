import { useState } from "react";

import { TaskHeading } from "../../../../components/headings";
import { TaskButtonGroup } from "../TaskButtonGroup";

type Props = {
    title: string;
    to: string;
    progress: React.ComponentProps<typeof TaskButtonGroup>["progress"];
    isTheoryDisabled?: boolean;
    isEqual?: boolean;
    isLoading?: boolean;
};

export const TasksCategoryListItem = ({
    title,
    to,
    progress,
    isTheoryDisabled,
    isEqual,
    isLoading,
}: Props) => {
    const [isClicked, setIsClicked] = useState(false);

    return (
        <>
            <TaskHeading
                title={title.toUpperCase()}
                isEqual={isEqual}
                isLoading={isClicked && isLoading}
            />
            <TaskButtonGroup
                to={to}
                progress={progress}
                isTheoryDisabled={isTheoryDisabled}
                isLoading={isLoading}
                setIsClicked={setIsClicked}
            />
        </>
    );
};
