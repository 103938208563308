import { useEffect, useState } from "react";

import { useNotify } from "../../../../../hooks";
import { useNavigationAccount, useRelationByParent } from "../../../hooks";

import { AccountContainer } from "../../../../../components/containers";
import { DashboardHeading } from "../../../../../components/headings";
import { Childrens, InviteInput, NavigationAccount } from "../../../components";

import { parentChildrensQuery } from "../../../utils";

import styles from "./ParentChildrens.module.css";

export const ParentChildrens = () => {
    const navigation = useNavigationAccount("Dzieci");

    const queryKey = parentChildrensQuery().queryKey;
    const mutation = useRelationByParent(queryKey);

    const notify = useNotify({ type: "error" });

    const [inviteValue, setInviteValue] = useState("");

    useEffect(() => {
        if (
            mutation.isError &&
            mutation.error &&
            mutation.error.response.data
        ) {
            const messages = mutation.error.response.data.messages;
            const messageKey = Object.keys(messages)[0];

            notify(messages[messageKey]);

            mutation.reset();
        }
    }, [mutation, notify]);

    return (
        <>
            <DashboardHeading title="MOJE KONTO" subtitle="moje dzieci" />
            <AccountContainer
                sidebarElement={<NavigationAccount elements={navigation} />}
            >
                <Childrens
                    className={styles.container}
                    isInputDisabled={true}
                />
                <div className={styles.bar}>
                    <InviteInput
                        className={styles.input}
                        title="DODAJ DZIECKO"
                        label="E-mail dziecka"
                        value={inviteValue}
                        mutation={mutation}
                        isBottom={true}
                        onChange={(value) => setInviteValue(value)}
                    />
                </div>
            </AccountContainer>
        </>
    );
};
