import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { setAccountCookie } from "../../../../services";

import { mainApi } from "../../../../api";

export const useMarkVideoAsWatched = () => {
    return useMutation<AxiosResponse<any, any>, any, void, unknown>(
        () => mainApi.post("mark_video_as_watched"),
        {
            onSuccess: () =>
                setAccountCookie({
                    isVideoWatched: "true",
                }),
            onError: () =>
                setAccountCookie({
                    isVideoWatched: "true",
                }),
        }
    );
};
