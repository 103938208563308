import Cookies from "js-cookie";

import { getUserSchool, parseAccountType } from "./utils";

import { GetAccountCookie, SetAccountCookie } from "./accountCookie.typings";

const cookieOptions = {
    sameSite: "strict" as const,
    expires: 30,
};

const key = {
    userId: "userId",
    accountType: "accountType",
    userEmail: "userEmail",
    parentEmail: "parentEmail",
    userSchool: "userSchool",
    videoUrl: "videoUrl",
    hasActiveParents: "hasActiveParents",
    isVideoWatched: "isVideoWatched",
    isSubscriptionPaid: "isSubscriptionPaid",
    isAccountDisabled: "isAccountDisabled",
    isParentConfirmationRequired: "isParentConfirmationRequired",
    isParentApproved: "isParentApproved",
};

const getAccountCookie = (): GetAccountCookie => {
    const userId = Cookies.get(key.userId) as string;
    const accountType = parseAccountType(
        Cookies.get(key.accountType) as Parameters<typeof parseAccountType>[0]
    );
    const userEmail = Cookies.get(key.userEmail)!;
    const parentEmail = Cookies.get(key.parentEmail);
    const userSchool = getUserSchool(Cookies.get(key.userSchool));
    const videoUrl = Cookies.get(key.videoUrl) ?? "";
    const hasActiveParents =
        Cookies.get(key.hasActiveParents) === "true" ? true : false;
    const isVideoWatched =
        Cookies.get(key.isVideoWatched) === "true" ? true : false ?? false;
    const isSubscriptionPaid =
        Cookies.get(key.isSubscriptionPaid) === "true" ? true : false ?? false;
    const isAccountDisabled =
        Cookies.get(key.isAccountDisabled)! === "true" ? true : false;
    const isParentConfirmationRequired =
        Cookies.get(key.isParentConfirmationRequired)! === "true"
            ? true
            : false;
    const isParentApproved =
        Cookies.get(key.isParentApproved)! === "true" ? true : false;

    return {
        userId,
        userEmail,
        accountType,
        parentEmail,
        userSchool,
        videoUrl,
        hasActiveParents,
        isSubscriptionPaid,
        isAccountDisabled,
        isParentConfirmationRequired,
        isParentApproved,
        isVideoWatched,
    };
};

const setAccountCookie = (data: SetAccountCookie) => {
    if (data.userId) {
        Cookies.set(key.userId, data.userId, cookieOptions);
    }

    if (data.accountType) {
        Cookies.set(key.accountType, data.accountType, cookieOptions);
    }

    if (data.userEmail) {
        Cookies.set(key.userEmail, data.userEmail, cookieOptions);
    }

    if (data.parentEmail) {
        if (data.parentEmail === "remove") {
            Cookies.remove(key.parentEmail, {
                sameSite: cookieOptions.sameSite,
            });
        } else {
            Cookies.set(key.parentEmail, data.parentEmail, cookieOptions);
        }
    }

    if (data.userSchool) {
        Cookies.set(
            key.userSchool,
            `${data.userSchool[0]},${data.userSchool[1]}`,
            cookieOptions
        );
    }

    if (data.videoUrl) {
        Cookies.set(key.videoUrl, data.videoUrl, cookieOptions);
    }

    if (data.hasActiveParents !== undefined) {
        Cookies.set(
            key.hasActiveParents,
            data.hasActiveParents.toString(),
            cookieOptions
        );
    }

    if (data.isSubscriptionPaid) {
        Cookies.set(
            key.isSubscriptionPaid,
            data.isSubscriptionPaid,
            cookieOptions
        );
    }

    if (data.isAccountDisabled) {
        Cookies.set(
            key.isAccountDisabled,
            data.isAccountDisabled,
            cookieOptions
        );
    }

    if (data.isParentConfirmationRequired) {
        Cookies.set(
            key.isParentConfirmationRequired,
            data.isParentConfirmationRequired,
            cookieOptions
        );
    }

    if (data.isParentApproved) {
        Cookies.set(key.isParentApproved, data.isParentApproved, cookieOptions);
    }

    if (data.isVideoWatched) {
        Cookies.set(key.isVideoWatched, data.isVideoWatched, cookieOptions);
    }
};

const removeAccountCookie = () => {
    Cookies.remove(key.userId, { sameSite: cookieOptions.sameSite });
    Cookies.remove(key.accountType, { sameSite: cookieOptions.sameSite });
    Cookies.remove(key.userEmail, { sameSite: cookieOptions.sameSite });
    Cookies.remove(key.parentEmail, { sameSite: cookieOptions.sameSite });
    Cookies.remove(key.userSchool, { sameSite: cookieOptions.sameSite });
    Cookies.remove(key.videoUrl, { sameSite: cookieOptions.sameSite });
    Cookies.remove(key.hasActiveParents, { sameSite: cookieOptions.sameSite });
    Cookies.remove(key.isSubscriptionPaid, {
        sameSite: cookieOptions.sameSite,
    });
    Cookies.remove(key.isParentConfirmationRequired, {
        sameSite: cookieOptions.sameSite,
    });
    Cookies.remove(key.isParentApproved, { sameSite: cookieOptions.sameSite });
    Cookies.remove(key.isVideoWatched, { sameSite: cookieOptions.sameSite });
};

export { setAccountCookie, getAccountCookie, removeAccountCookie };
