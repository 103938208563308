import clsx from "clsx";

import styles from "./SelectableCard.module.css";

type Props = {
    children: React.ReactNode;
    onClick: () => void;
    isSelected: boolean;
};

export const SelectableCard = ({ children, onClick, isSelected }: Props) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className={clsx(styles.button, isSelected && styles.selected)}
        >
            {children}
        </button>
    );
};
