import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

import { ListMutation } from "../../typings";

const getMutationUrl = (action: ListMutation["action"]) => {
    switch (action) {
        case "accept":
            return "accept_childs_teacher_invitation";
        case "decline":
            return "decline_childs_teacher_invitation";
        case "remove":
            return "remove_childs_teacher";
        case "invite":
            return "no_api";
    }
};

export const useParentUnderageChildrenActions = (
    queryKey: (string | number)[]
) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse<any, any>, any, ListMutation, unknown>(
        (data) =>
            mainApi.post(getMutationUrl(data.action), {
                email: data.email,
                child_email: data.child_email,
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKey);
            },
        }
    );
};
