import { QueryClient } from "@tanstack/react-query";
import { LoaderFunctionArgs } from "react-router-dom";
import { AxiosResponse } from "axios";

import { getAccountCookie } from "../../../../services";

import {
    loadDiscountCodeQuery,
    orderBannerQuery,
    orderRequestQuery,
    subscriptionOptionsQuery,
} from "../../utils";

type OrderRequestResponse = {
    order_request: {
        discount_code: string;
    };
};

export const storeParentLoader =
    (queryClient: QueryClient) =>
    async ({ params }: LoaderFunctionArgs) => {
        const { accountType } = getAccountCookie();

        const childrenId = params.childrenId;

        if (accountType !== "parent" || !childrenId) {
            return undefined;
        }

        const subscriptionQuery = subscriptionOptionsQuery();
        const orderQuery = orderRequestQuery(childrenId);
        const bannerQuery = orderBannerQuery();

        const subscriptionData = queryClient.getQueryData(
            subscriptionQuery.queryKey
        );
        const orderData = queryClient.getQueryData(orderQuery.queryKey);
        const bannerData = queryClient.getQueryData(bannerQuery.queryKey);

        return subscriptionData && orderData && bannerData
            ? { subscriptionData, orderData, bannerData }
            : (async () => {
                  await queryClient.fetchQuery(subscriptionQuery);
                  await queryClient.fetchQuery(orderQuery);
                  await queryClient.fetchQuery(bannerQuery);

                  const orderRequestData = (await queryClient.getQueryData(
                      orderQuery.queryKey
                  )) as AxiosResponse<OrderRequestResponse, any>;

                  if (
                      orderRequestData?.data.order_request.discount_code !== ""
                  ) {
                      const discountQuery = loadDiscountCodeQuery(
                          childrenId,
                          orderRequestData.data.order_request.discount_code
                      );

                      await queryClient.fetchQuery(discountQuery);
                  }

                  return null;
              })();
    };
