import { useState } from "react";
import { motion } from "framer-motion";

import {
    inputVariants,
    labelVariants,
    transition,
} from "./PaymentInput.animations";
import { PaymentInputProps } from "./PaymentInput.typings";

import styles from "./PaymentInput.module.css";

export const PaymentInput = ({
    value,
    label,
    max,
    response,
    isDisabled,
    isDimmed,
    onChange,
}: PaymentInputProps) => {
    const [isFocused, setIsFocused] = useState(false);
    const isFilled = value.length > 0;
    const isSuccess = response === "success";
    const isError = response === "error";

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;

        if (max && value.length > max) return;

        onChange(value);
    };

    return (
        <div className={styles.container}>
            <div className={styles.field}>
                <motion.div
                    className={styles.label}
                    variants={labelVariants}
                    initial="idle"
                    animate={
                        isFilled
                            ? isSuccess
                                ? "success"
                                : isError
                                ? "error"
                                : "filled"
                            : isFocused
                            ? "focused"
                            : "idle"
                    }
                    transition={transition}
                >
                    {label}
                </motion.div>
                <motion.input
                    className={styles.input}
                    type="text"
                    value={value}
                    disabled={isDisabled}
                    variants={inputVariants}
                    initial="idle"
                    animate={
                        isDimmed
                            ? "disabled"
                            : isSuccess
                            ? "success"
                            : isError
                            ? "error"
                            : "idle"
                    }
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChange={handleOnChange}
                />
            </div>
        </div>
    );
};
