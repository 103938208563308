import { AxiosResponse } from "axios";

import { UserListResponse, ListMutationResult } from "../../typings";

import { UserList } from "../UserList";

import styles from "./Users.module.css";

type Props = {
    data: {
        key: string;
        response?: AxiosResponse<UserListResponse, any>;
        mutation: ListMutationResult;
        heading?: string;
        emptyText?: string;
        modalText?: string;
        maxLength?: {
            title: string;
            count: number;
        };
        input: {
            title: string;
            label: string;
        };
    }[];
    hasPermission?: boolean;
};

export const Users = ({ data, hasPermission }: Props) => {
    return (
        <div className={styles.container}>
            {data.map((user) => (
                <UserList
                    key={user.key}
                    heading={user.heading}
                    values={user.response?.data.students ?? []}
                    mutation={user.mutation}
                    input={user.input}
                    maxLength={user.maxLength}
                    modalText={user.modalText}
                    emptyText={user.emptyText}
                    hasPermission={hasPermission}
                />
            ))}
        </div>
    );
};
