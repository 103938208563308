import clsx from "clsx";
import { useMedia } from "../../../hooks";

import styles from "./AccountContainer.module.css";

type Props = {
    children: JSX.Element | JSX.Element[];
    sidebarElement?: JSX.Element;
};

export const AccountContainer = ({ children, sidebarElement }: Props) => {
    const { isDesktop } = useMedia();

    const isMultiple = Array.isArray(children);

    return (
        <div
            className={clsx(
                styles.container,
                isDesktop && !sidebarElement && styles.standalone,
                isDesktop && sidebarElement && styles.group
            )}
        >
            {isDesktop && sidebarElement && (
                <div className={styles.sidebar}>{sidebarElement}</div>
            )}
            <div className={clsx(styles.main, isMultiple && styles.multiple)}>
                {isMultiple
                    ? children.map((item, index) => (
                          <div key={index} className={styles.column}>
                              {item}
                          </div>
                      ))
                    : children}
            </div>
        </div>
    );
};
