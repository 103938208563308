import { ScheduleValue } from "../../typings";

export const getShortDescription = (percentageValue: number) =>
    `Opanujesz ${percentageValue}% zagadnień`;

export const calculatePercentage = (values: {
    picker: ScheduleValue;
    weeksLeft: number;
    subjectCount: number;
}) =>
    Math.min(
        Math.round(
            ((values.picker * values.weeksLeft) / values.subjectCount) * 100
        ) || 0,
        100
    );
