import { Link } from "react-router-dom";
import { AccountIcon } from "../AccountIcon";
import clsx from "clsx";

import styles from "./AccountType.module.css";

type AccountItemProps = {
    to: string;
    icon: JSX.Element;
    caption?: string;
    isUnfinished?: boolean;
};

export const AccountType = () => {
    return (
        <div className={styles.container}>
            <p className={styles.title}>Zarejestruj się jako:</p>
            <div className={styles.tiles}>
                <div className={styles.tile}>
                    <AccountItem
                        to="student"
                        icon={<AccountIcon type="student" />}
                        caption="UCZEŃ"
                    />
                </div>
                <div className={styles.tile}>
                    <AccountItem
                        to="parent"
                        icon={<AccountIcon type="parent" />}
                        caption="RODZIC"
                    />
                </div>
                <div className={styles.tile}>
                    <AccountItem
                        to="teacher"
                        icon={<AccountIcon type="teacher" />}
                        caption="NAUCZYCIEL"
                    />
                </div>
            </div>
        </div>
    );
};

const AccountItem = ({ to, icon, caption, isUnfinished }: AccountItemProps) => {
    return (
        <Link
            className={clsx(styles.item, isUnfinished && styles.unfinished)}
            to={to}
        >
            <span className={styles.itemIcon}>{icon}</span>
            <span className={styles.itemCaption}>{caption}</span>
        </Link>
    );
};
