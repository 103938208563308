import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { tasksQuery } from "../../utils";

import { TasksResponse } from "../../typings";

export const useTasks = (userClass?: string) => {
    return useQuery<AxiosResponse<TasksResponse, any>>(tasksQuery(userClass));
};
