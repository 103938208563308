import { Outlet } from "react-router-dom";

import { useMedia } from "../../../../hooks";

import { BackgroundContainer } from "../../../../components/containers";

import infinity from "../../../../assets/images/robots/infinity-watching.svg";

export const TasksWrapper = () => {
    const { isSmallerDesktop } = useMedia();

    return (
        <BackgroundContainer
            rightElement={{
                image: infinity,
                size: {
                    width: 223,
                    height: 400,
                },
                position: {
                    x: isSmallerDesktop ? -30 : -140,
                    y: -56,
                },
            }}
        >
            <Outlet />
        </BackgroundContainer>
    );
};
