import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { childrenParentsQuery, setAccountActiveParents } from "../../utils";

import { UserListResponse } from "../../typings";

export const useChildrenParents = (isEnabled?: boolean) =>
    useQuery<AxiosResponse<UserListResponse, any>>({
        ...childrenParentsQuery(isEnabled),
        onSuccess: (data) => setAccountActiveParents(data.data.students),
    });
