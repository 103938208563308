import { useAuthContext } from "../../../contexts/AuthContext";

import { getAccountCookie } from "../../../services";

import { AuthContainer } from "../../../components/containers";
import { MainHeading } from "../../../components/headings";
import { AuthText } from "../../../components/texts";
import { DefaultButton } from "../../../components/buttons";

import styles from "./NotFound.module.css";

import pi from "../../../assets/images/robots/pi-confused.svg";

export const NotFound = () => {
    const { accountType } = getAccountCookie();

    const { isUserLogged } = useAuthContext();

    return (
        <AuthContainer
            position="center"
            image={pi}
            imageSize={156}
            imagePosition="right"
        >
            <MainHeading wrapper={styles.heading} elementType="h2">
                Strony nie znaleziono
            </MainHeading>
            <AuthText>
                <p>
                    Przepraszamy, ale podana strona
                    <br />
                    nie została odnaleziona.
                </p>
            </AuthText>
            <DefaultButton
                wrapper={styles.forwardButton}
                whileHover={{ opacity: 0.7 }}
                to={isUserLogged ? `/dashboard/${accountType}` : "/"}
            >
                {isUserLogged ? "PRZEJDŹ DO PANELU" : "STRONA GŁÓWNA"}
            </DefaultButton>
        </AuthContainer>
    );
};
