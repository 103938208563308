import { QueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { getAccountCookie } from "../../../../services";

import {
    childrenParentsQuery,
    childrenTeachersQuery,
    setAccountActiveParents,
} from "../../utils";

import { UserListResponse } from "../../typings";

export const childrenRelationsLoader =
    (queryClient: QueryClient) => async () => {
        const { accountType } = getAccountCookie();

        if (accountType !== "student") {
            return undefined;
        }

        const parentsQuery = childrenParentsQuery();
        const teachersQuery = childrenTeachersQuery();

        const parentsQueryData = queryClient.getQueryData(
            parentsQuery.queryKey
        );
        const teachersQueryData = queryClient.getQueryData(
            teachersQuery.queryKey
        );

        return parentsQueryData && teachersQueryData
            ? { parentsQueryData, teachersQueryData }
            : (async () => {
                  await queryClient.fetchQuery(parentsQuery);
                  const parentsQueryData = (await queryClient.getQueryData(
                      parentsQuery.queryKey
                  )) as AxiosResponse<UserListResponse, any>;

                  if (parentsQueryData) {
                      setAccountActiveParents(parentsQueryData.data.students);
                  }

                  await queryClient.fetchQuery(teachersQuery);

                  return null;
              })();
    };
