import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import { Vector2d } from "konva/lib/types";
import { useCallback, useRef, useState } from "react";
import { Layer, Line, Stage } from "react-konva";

import { useResizeObserver } from "../../../../../hooks";
import { LineType, Tool } from "../../../typings";
import { ColorButton } from "../ColorButton";
import { EraserButton } from "../EraserButton";
import { getCenter, getDistance, getRelativePointerPosition } from "./utils";

import backspaceIcon from "../../../../../assets/images/components/whiteboard/backspace-icon.svg";
import trashIcon from "../../../../../assets/images/components/whiteboard/trash-icon.svg";
import styles from "./Whiteboard.module.css";

Konva.hitOnDragEnabled = true;

type Props = {
    lines: LineType[];
    setLines: React.Dispatch<React.SetStateAction<LineType[]>>;
};
export const Whiteboard = ({ lines, setLines }: Props) => {
    const [tool, setTool] = useState<Tool>("pen");
    const [color, setColor] = useState<string>("#222692");
    const [stageState, setStageState] = useState({
        scale: 1,
        x: 0,
        y: 0,
    });
    const isDrawing = useRef(false);
    const isResizingOnMobile = useRef(false);

    const removeLastLine = useCallback(
        () =>
            setLines((state) =>
                state.filter((_, index, array) => index + 1 !== array.length)
            ),
        [setLines]
    );

    const onDrawStart = useCallback(
        (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
            isDrawing.current = true;

            const pos = getRelativePointerPosition(e.target.getStage());
            if (pos && typeof color === "string") {
                setLines([
                    ...lines,
                    { tool, color, strokeWidth: 5, points: [pos.x, pos.y] },
                ]);
            }
        },
        [setLines, color, lines, tool]
    );

    const onDrawMove = useCallback(
        (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
            // no drawing - skipping
            if (!isDrawing.current) {
                return;
            }
            const point = getRelativePointerPosition(e.target.getStage());
            let lastLine = lines[lines.length - 1];
            // add point
            if (point) {
                lastLine.points = lastLine.points.concat([point.x, point.y]);
            }

            // replace last
            lines.splice(lines.length - 1, 1, lastLine);
            setLines(lines.concat());
        },
        [lines, setLines]
    );

    const onDrawEnd = useCallback(() => {
        isDrawing.current = false;
        if (isResizingOnMobile.current) {
            removeLastLine();
            removeLastLine();

            isResizingOnMobile.current = false;
        }
    }, [removeLastLine]);

    let lastCenter: Vector2d | null = null;
    let lastDist: number = 0;

    const onTouchMove = useCallback(
        (e: KonvaEventObject<TouchEvent>) => {
            e.evt.preventDefault();

            const touch1 = e.evt.touches[0];
            const touch2 = e.evt.touches[1];
            const stage = e.target.getStage();

            if (!stage) {
                return;
            }
            // if there is no two touches - continue with normal draw only
            if (!(touch1 && touch2)) {
                onDrawMove(e);
                return;
            }

            isResizingOnMobile.current = true;

            // if the stage was under Konva's drag&drop
            // we need to stop it, and implement our own pan logic with two pointers
            if (stage.isDragging()) {
                stage.stopDrag();
            }

            const p1: Vector2d = {
                x: touch1.clientX,
                y: touch1.clientY,
            };
            const p2: Vector2d = {
                x: touch2.clientX,
                y: touch2.clientY,
            };

            if (!lastCenter) {
                lastCenter = getCenter(p1, p2);
                // return;
            }
            const newCenter = getCenter(p1, p2);

            const dist = getDistance(p1, p2);

            if (!lastDist) {
                lastDist = dist;
            }

            // local coordinates of center point
            const pointTo = {
                x: (newCenter.x - stage.x()) / stage.scaleX(),
                y: (newCenter.y - stage.y()) / stage.scaleX(),
            };

            if (
                (stage.scaleX() <= 0.1 && dist / lastDist < 1) ||
                (stage.scaleX() >= 10 && dist / lastDist > 1)
            ) {
                return;
            }

            const scale = stage.scaleX() * (dist / lastDist);

            stage.scaleX(scale);
            stage.scaleY(scale);

            // calculate new position of the stage
            const dx = newCenter.x - lastCenter.x;
            const dy = newCenter.y - lastCenter.y;

            const newPos = {
                x: newCenter.x - pointTo.x * scale + dx,
                y: newCenter.y - pointTo.y * scale + dy,
            };

            stage.position(newPos);

            lastDist = dist;
            lastCenter = newCenter;
        },
        [onDrawMove]
    );

    const onWheel = useCallback(
        (e: KonvaEventObject<WheelEvent>) => {
            e.evt.preventDefault();

            // Zoom constraint
            if (
                (stageState.scale <= 0.1 && e.evt.deltaY > 0) ||
                (stageState.scale >= 10 && e.evt.deltaY < 0)
            ) {
                return;
            }

            const scaleBy = 1.2;
            const stage = e.target.getStage();

            if (!stage) {
                return;
            }

            const oldScale = stage.scaleX();
            const stagePointerPosition = stage.getPointerPosition();

            if (!oldScale || !stagePointerPosition) {
                return;
            }

            const mousePointTo = {
                x: stagePointerPosition.x / oldScale - stage.x() / oldScale,
                y: stagePointerPosition.y / oldScale - stage.y() / oldScale,
            };

            const newScale =
                e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

            setStageState({
                scale: newScale,
                x:
                    (stagePointerPosition.x / newScale - mousePointTo.x) *
                    newScale,
                y:
                    (stagePointerPosition.y / newScale - mousePointTo.y) *
                    newScale,
            });
        },
        [stageState.scale]
    );

    const onResize = useCallback(() => {
        setStageDimensions({
            height: rootRef.current?.clientHeight ?? 0,
            width: rootRef.current?.clientWidth ?? 0,
        });
    }, []);

    const rootRef = useResizeObserver<HTMLDivElement>(onResize);
    const toolboxRef = useRef<HTMLDivElement>(null);

    const [stageDimensions, setStageDimensions] = useState({
        height: 0,
        width: 0,
    });

    return (
        <div onMouseLeave={onDrawEnd} ref={rootRef} className={styles.root}>
            <Stage
                className={styles.stage}
                scaleX={stageState.scale}
                scaleY={stageState.scale}
                x={stageState.x}
                y={stageState.y}
                width={stageDimensions.width}
                height={stageDimensions.height}
                onWheel={onWheel}
                onMouseUp={onDrawEnd}
                onMouseMove={onDrawMove}
                onMouseDown={onDrawStart}
                onTouchStart={onDrawStart}
                onTouchMove={onTouchMove}
                onTouchEnd={onDrawEnd}
            >
                <Layer>
                    {lines.map((line, i) => (
                        <Line
                            key={i}
                            points={line.points}
                            stroke={line.color}
                            strokeWidth={line.strokeWidth}
                            tension={0.5}
                            lineCap="round"
                            lineJoin="round"
                            globalCompositeOperation={
                                line.tool === "eraser"
                                    ? "destination-out"
                                    : "source-over"
                            }
                        />
                    ))}
                </Layer>
            </Stage>

            <div ref={toolboxRef} className={styles.toolbox}>
                <button
                    onClick={() => setLines([])}
                    className={styles.clearButton}
                    type="button"
                >
                    <span>
                        <img src={trashIcon} alt="" /> WYCZYŚĆ
                    </span>
                </button>
                <button
                    onClick={removeLastLine}
                    className={styles.backspaceButton}
                    type="button"
                >
                    <span>
                        <img src={backspaceIcon} alt="" /> COFNIJ
                    </span>
                </button>

                <ColorButton
                    color="#222692"
                    active={tool === "pen" && color === "#222692"}
                    onClick={(value) => {
                        setColor(value);
                        setTool("pen");
                    }}
                />
                <ColorButton
                    color="#3A9B4A"
                    active={tool === "pen" && color === "#3A9B4A"}
                    onClick={(value) => {
                        setColor(value);
                        setTool("pen");
                    }}
                />
                <ColorButton
                    color="#D51B1B"
                    active={tool === "pen" && color === "#D51B1B"}
                    onClick={(value) => {
                        setColor(value);
                        setTool("pen");
                    }}
                />
                <EraserButton
                    active={tool === "eraser"}
                    onClick={() => setTool("eraser")}
                />
            </div>
        </div>
    );
};
