import clsx from "clsx";

import { getColor, getSize } from "./utils";

import { CircleLoaderProps } from "./CircleLoader.typings";

import styles from "./CircleLoader.module.css";

export const CircleLoader = ({ color, size }: CircleLoaderProps) => (
    <span
        className={clsx(
            styles.ldsRing,
            getColor(color, { red: styles.red, white: styles.white }),
            getSize(size, { size18: styles.size18, size24: styles.size24 })
        )}
    >
        <span />
        <span />
        <span />
        <span />
    </span>
);
