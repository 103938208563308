import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

type Response = {
    code: {
        code: string;
        discount: string;
        packages: string;
    };
};

type Mutation = {
    discountCode: string;
};

export type DiscountMutationResult = UseMutationResult<
    AxiosResponse<Response, any>,
    any,
    Mutation,
    unknown
>;

export const useCheckDiscountCode = () => {
    return useMutation<AxiosResponse<Response, any>, any, Mutation, unknown>(
        (data) =>
            mainApi.post("check_discount_code", {
                discount_code: data.discountCode,
            })
    );
};
