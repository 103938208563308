import { useState, useEffect } from "react";
import { useNavigation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import { useReportError } from "../../hooks";

import { DefaultModal } from "../../../../components/modals";

import styles from "./ReportBar.module.css";

type Props = {
    id: string;
};

export const ReportBar = ({ id }: Props) => {
    const { state } = useNavigation();
    const isLoadingState = state === "loading";

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [value, setValue] = useState("");

    const [isError, setIsError] = useState(false);
    const isEmpty = value.length === 0;

    const mutation = useReportError();

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.currentTarget.value;

        if (value.length <= 1000) {
            setValue(value);
        }
    };

    useEffect(() => {
        if (mutation.isSuccess) {
            setIsModalOpen(false);

            mutation.reset();

            setTimeout(() => {
                setValue("");
            }, 300);
        }
    }, [mutation]);

    useEffect(() => {
        if (mutation.isError) {
            mutation.reset();

            setIsError(true);

            setTimeout(() => {
                setIsError(false);
            }, 2000);
        }
    }, [mutation]);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.main}>Smart Tutor</div>
                <div className={styles.aside}>
                    <button
                        className={styles.button}
                        type="button"
                        disabled={isLoadingState}
                        onClick={() => setIsModalOpen(true)}
                    >
                        Zgłoś błąd
                    </button>
                </div>
            </div>
            <DefaultModal
                title="ZGŁOŚ BŁĄD"
                actions={[
                    {
                        name: "WYŚLIJ",
                        isDisabled:
                            isEmpty ||
                            isError ||
                            mutation.isSuccess ||
                            mutation.isLoading,
                        onClick: () =>
                            mutation.mutate({
                                taskId: id,
                                content: value,
                            }),
                    },
                ]}
                hasSmallSpacing={true}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            >
                <div className={styles.field}>
                    <textarea
                        className={styles.textarea}
                        value={value}
                        placeholder="Opisz zauważony błąd w zadaniu"
                        disabled={
                            isError || mutation.isSuccess || mutation.isLoading
                        }
                        onChange={handleOnChange}
                    ></textarea>
                    <div className={styles.counter}>
                        Ilość znaków: <strong>{value.length}/1000</strong>
                    </div>
                    <AnimatePresence mode="wait" initial={false}>
                        {isError && (
                            <motion.div
                                className={styles.error}
                                initial={{ opacity: 0, scale: 0.84 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.84 }}
                                transition={{ type: "tween", duration: 0.3 }}
                            >
                                <div className={styles.errorField}>
                                    <p>
                                        Wystąpił błąd podczas
                                        <br />
                                        wysyłania wiadomości.
                                    </p>
                                    <p>Spróbuj ponownie.</p>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </DefaultModal>
        </>
    );
};
