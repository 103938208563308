const colors = {
    white: "#FFFFFF",
    gray: "#E5E7EA",
    darkGray: "#879195",
    success: "#2EAFBE",
    error: "#FE5A5E",
};

const labelStaticVariant = {
    y: 14,
    fontSize: "16px",
    lineHeight: "18px",
};

const labelActiveVariant = {
    y: -17,
    fontSize: "13px",
    lineHeight: "15px",
};

const labelVariants = {
    static: {
        ...labelStaticVariant,
        color: colors.darkGray,
    },
    active: { ...labelActiveVariant },
};

export { labelVariants };
