import { useLocation } from "react-router-dom";

import { getAccountCookie } from "../../../../../services";

import { useChildrenSubscriptions, useNavigationAccount } from "../../../hooks";

import { AccountContainer } from "../../../../../components/containers";
import { DashboardHeading } from "../../../../../components/headings";
import { Cards, NavigationAccount, Subscriptions } from "../../../components";
import {
    useUserSubscriptions,
} from "../../../hooks/queries";

type LocationType = {
    state: {
        [key: string]: string | number | boolean | undefined;
    } | null;
};

export const Subscription = () => {
    const { accountType, isParentConfirmationRequired } = getAccountCookie();

    const { state }: LocationType = useLocation();
    const navigation = useNavigationAccount("Abonament");

    const { data: userSubscriptions } = useUserSubscriptions(
        accountType === "student"
    );
    const { data: childrenSubscriptions } = useChildrenSubscriptions(
        accountType === "parent"
    );

    return (
        <>
            <DashboardHeading title="MOJE KONTO" subtitle="abonament" />
            <AccountContainer
                sidebarElement={<NavigationAccount elements={navigation} />}
            >
                <Subscriptions
                    subs={
                        accountType === "student" && userSubscriptions?.data
                            ? [
                                  {
                                      id: userSubscriptions.data.user_id,
                                      name: `${userSubscriptions.data.first_name} ${userSubscriptions.data.last_name}`,
                                      schoolClass: userSubscriptions.data.class,
                                      subscriptions:
                                          userSubscriptions.data.subscriptions,
                                  },
                              ]
                            : childrenSubscriptions?.data.children.map(
                                  (child) => ({
                                      id: child.user_id,
                                      name: `${child.first_name} ${child.last_name}`,
                                      schoolClass: child.class,
                                      subscriptions: child.subscriptions,
                                      isOpen:
                                          state?.childrenId === child.user_id,
                                  })
                              )
                    }
                    isStudent={accountType === "student"}
                    isUnderage={isParentConfirmationRequired}
                />
                <Cards/>
            </AccountContainer>
        </>
    );
};
