import { useEffect, useRef } from "react";

import { DottedLoader } from "../../loaders";

import styles from "./ButtonLoader.module.css";

type Props = {
    children: string;
    condition: boolean;
    color?: React.ComponentProps<typeof DottedLoader>["color"];
};

export const ButtonLoader = ({ children, condition, color }: Props) => {
    const childrenRef = useRef<HTMLSpanElement | null>(null);
    const width = useRef<number | undefined>(undefined);

    useEffect(() => {
        if (childrenRef.current) {
            width.current = childrenRef.current.getBoundingClientRect().width;
        }
    }, []);

    return condition ? (
        <span className={styles.block} style={{ width: width.current }}>
            <DottedLoader color={color} />
        </span>
    ) : (
        <span ref={childrenRef} className={styles.block}>
            {children}
        </span>
    );
};
