import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { loadDiscountCodeQuery } from "../../utils";

type Response = {
    code: {
        code: string;
        discount: string;
        packages: string;
    };
};

export const useLoadDiscountCode = (childrenId: string) => {
    return useQuery<AxiosResponse<Response, any>>(
        loadDiscountCodeQuery(childrenId)
    );
};
