import { getAccountCookie } from "../../../../../services";

import {
    useChildrenParents,
    useChildrenTeachers,
    useNavigationAccount,
    useRelationByChildren,
} from "../../../hooks";

import { DashboardHeading } from "../../../../../components/headings";
import { AccountContainer } from "../../../../../components/containers";
import { NavigationAccount, Users } from "../../../components";

import { childrenParentsQuery, childrenTeachersQuery } from "../../../utils";

export const StudentParents = () => {
    const { isParentConfirmationRequired } = getAccountCookie();

    const queryKeyParents = childrenParentsQuery().queryKey;
    const queryKeyTeachers = childrenTeachersQuery().queryKey;
    const queryResultParents = useChildrenParents();
    const queryResultTeachers = useChildrenTeachers();
    const mutationParents = useRelationByChildren(queryKeyParents, "parent");
    const mutationTeachers = useRelationByChildren(queryKeyTeachers, "teacher");

    const navigation = useNavigationAccount("Opiekunowie");

    return (
        <>
            <DashboardHeading title="MOJE KONTO" subtitle="opiekunowie" />
            <AccountContainer
                sidebarElement={
                    <NavigationAccount
                        elements={navigation}
                    />
                }
            >
                <Users
                    data={[
                        {
                            key: "parents",
                            response: queryResultParents.data,
                            mutation: mutationParents,
                            heading: "Rodzice",
                            input: {
                                title: "DODAJ RODZICA",
                                label: "E-mail rodzica",
                            },
                            maxLength: {
                                title: "Możesz posiadać maksymalnie 4 rodziców",
                                count: 4,
                            },
                            modalText: 'Czy na pewno chcesz usunąć użytkownika jako rodzica:',
                            emptyText: "Brak przypisanych rodziców",
                        },
                        {
                            key: "teachers",
                            response: queryResultTeachers.data,
                            mutation: mutationTeachers,
                            heading: "Nauczyciele",
                            input: {
                                title: "DODAJ NAUCZYCIELA",
                                label: "E-mail nauczyciela",
                            },
                            modalText: 'Czy na pewno chcesz usunąć użytkownika jako nauczyciela:',
                            emptyText: "Brak przypisanych nauczycieli",
                        },
                    ]}
                    hasPermission={!isParentConfirmationRequired}
                />
            </AccountContainer>
        </>
    );
};
