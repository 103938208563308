type Props = {
    type: "student" | "parent" | "teacher";
};

export const AccountIcon = ({ type }: Props) => {
    switch (type) {
        case "student":
            return <StudentIcon />;
        case "parent":
            return <ParentIcon />;
        case "teacher":
            return <TeacherIcon />;
        default: 
            return null;
    }
};

const StudentIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="54"
            height="54"
            viewBox="0 0 54 54"
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="0.5"
                    x2="0.5"
                    y2="1"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#2eafbe" />
                    <stop offset="1" stopColor="#2ac3bb" />
                </linearGradient>
            </defs>
            <g
                id="Group_241"
                data-name="Group 241"
                transform="translate(0 -0.093)"
            >
                <g id="Group_235" data-name="Group 235">
                    <circle
                        id="Ellipse_13"
                        data-name="Ellipse 13"
                        cx="27"
                        cy="27"
                        r="27"
                        transform="translate(0 0.093)"
                        fill="url(#linear-gradient)"
                    />
                    <path
                        id="Icon_material-school"
                        data-name="Icon material-school"
                        d="M8.013,21.076v6.513l11.4,6.22,11.4-6.22V21.076l-11.4,6.22ZM19.411,4.5,1.5,14.27l17.911,9.77,14.655-7.995V27.3h3.257V14.27Z"
                        transform="translate(7.727 7.984)"
                        fill="#fff"
                    />
                </g>
            </g>
        </svg>
    );
};

const ParentIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="54"
            height="54"
            viewBox="0 0 54 54"
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="1"
                    x2="0"
                    y2="1"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#2eafbe" />
                    <stop offset="1" stopColor="#2ac3bb" />
                </linearGradient>
            </defs>
            <g
                id="Group_240"
                data-name="Group 240"
                transform="translate(-0.154 -0.093)"
            >
                <g id="Group_236" data-name="Group 236">
                    <circle
                        id="Ellipse_13"
                        data-name="Ellipse 13"
                        cx="27"
                        cy="27"
                        r="27"
                        transform="translate(0.154 0.093)"
                        fill="url(#linear-gradient)"
                    />
                </g>
                <path
                    id="Union_8"
                    data-name="Union 8"
                    d="M11.977,29.077a4.422,4.422,0,0,1,2.06-3.449h0a13.29,13.29,0,0,1,6.7-2.244h.01l.049,0,.059,0V22.186l-.035-.019-.013-.007-.023-.013-.012-.007-.027-.016-.007,0A6.2,6.2,0,0,1,18.16,18.6l-.006-.021,0-.006a6.171,6.171,0,0,1-.258-1.736c0-.713,0-1.4.035-2.042v0a6.285,6.285,0,0,1,.644-2.778,2.919,2.919,0,0,1,1.41-1.255l.01,0,.023-.01.008,0h0a5.9,5.9,0,0,1,2.307-.387c4.4,0,4.438,2.857,4.438,6.4v.042q0,.021,0,.042A6.662,6.662,0,0,1,25.378,20.8h0l0,0-.036.047-.014.018L25.3,20.9l-.013.016-.038.047-.007.009-.036.043-.007.008a5.073,5.073,0,0,1-1.332,1.133l0,0-.051.029v1.187a16.192,16.192,0,0,1,3.953.8q.192.066.379.136h0c2.743,1.036,4.544,2.786,4.544,4.77Zm-.468-3.449H0c0-1.909,1.242-3.752,3.5-5.187a19.263,19.263,0,0,1,8.648-2.617V16.2a7.706,7.706,0,0,1-2.86-2.978A9,9,0,0,1,8.1,8.871c0-2.4,0-4.668.759-6.273C9.695.826,11.384,0,14.17,0s4.472.824,5.311,2.593c.762,1.606.762,3.868.762,6.261a4.825,4.825,0,0,0-3.4,2.673,12.559,12.559,0,0,0-.716,5.305,8.381,8.381,0,0,0,1.924,5.223,14.322,14.322,0,0,0-4.483,1.787,8.546,8.546,0,0,0-2.054,1.784Z"
                    transform="translate(10.794 12.277)"
                    fill="#fff"
                />
            </g>
        </svg>
    );
};

const TeacherIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="54"
            height="54"
            viewBox="0 0 54 54"
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="1"
                    x2="0"
                    y2="1"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#2eafbe" />
                    <stop offset="1" stopColor="#2ac3bb" />
                </linearGradient>
            </defs>
            <g
                id="Group_239"
                data-name="Group 239"
                transform="translate(-0.292 -0.093)"
            >
                <g
                    id="Group_238"
                    data-name="Group 238"
                    transform="translate(0)"
                >
                    <circle
                        id="Ellipse_13"
                        data-name="Ellipse 13"
                        cx="27"
                        cy="27"
                        r="27"
                        transform="translate(0.292 0.093)"
                        fill="url(#linear-gradient)"
                    />
                </g>
                <path
                    id="Icon_metro-users"
                    data-name="Icon metro-users"
                    d="M23.179,25.417v-1.38a7.43,7.43,0,0,0,3.438-6.22c0-4.16,0-7.532-5.156-7.532S16.3,13.658,16.3,17.817a7.43,7.43,0,0,0,3.438,6.22v1.38c-5.83.464-10.313,3.254-10.313,6.626H33.491c0-3.373-4.482-6.162-10.313-6.626Z"
                    transform="translate(2.663 5.974)"
                    fill="#fff"
                />
                <g
                    id="Rectangle_376"
                    data-name="Rectangle 376"
                    transform="translate(9.292 12.093)"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="1"
                >
                    <rect width="36" height="30" rx="2" stroke="none" />
                    <rect
                        x="0.5"
                        y="0.5"
                        width="35"
                        height="29"
                        rx="1.5"
                        fill="none"
                    />
                </g>
                <line
                    id="Line_10"
                    data-name="Line 10"
                    x1="6.031"
                    y2="7.538"
                    transform="translate(35.431 24.877)"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="1"
                />
            </g>
        </svg>
    );
};
