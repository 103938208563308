import { useEffect } from "react";
import {
    Outlet,
    useLocation,
    useNavigate,
    useNavigation,
    useOutlet,
    useParams,
} from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { romanize } from "romans";

import { getAccountCookie } from "../../../../../services";

import { useRedirectContext } from "../../../../../contexts/RedirectContext";

import { useMedia } from "../../../../../hooks";
import {
    useLearningProgress,
    useParentChildrens,
    useTeacherStudents,
} from "../../../hooks";

import {
    BackgroundContainer,
    TaskContainer,
} from "../../../../../components/containers";
import { DashboardHeading } from "../../../../../components/headings";
import {
    CounterBox,
    ProgressButton,
    ProgressDefault,
    ShieldAccordion,
} from "../../../components";
import { NavigationBar } from "../../../../tasks/components";
import { ObjectSelectInput } from "../../../../../components/inputs";

import { getUserPlanStatusDescription } from "./utils";
import { getPreviousPath } from "../../../../../utils";

import styles from "./StudentProgress.module.css";

import schoolIcon from "../../../images/learning-progress/school-icon.svg";
import infinity from "../../../../../assets/images/robots/infinity-watching.svg";

export const StudentProgress = () => {
    const { accountType } = getAccountCookie();
    const { setRedirect } = useRedirectContext();
    const { isSmallerDesktop } = useMedia();

    const outlet = useOutlet();
    const navigate = useNavigate();
    const { state } = useNavigation();
    const { pathname } = useLocation();
    const params = useParams();

    const childrenId = params.childrenId as string;

    const { data: parentChildrensData } = useParentChildrens(
        accountType === "parent"
    );
    const { data: teacherStudentsData } = useTeacherStudents(
        accountType === "teacher"
    );
    const { data: learningProgressData } = useLearningProgress(
        "other",
        childrenId
    );

    const backwardPathname = getPreviousPath(pathname, 2);

    useEffect(() => {
        if (!outlet) {
            setRedirect(backwardPathname);
        }
    }, [outlet, backwardPathname, setRedirect]);

    return (
        <BackgroundContainer
            rightElement={{
                image: infinity,
                size: {
                    width: 223,
                    height: 400,
                },
                position: {
                    x: isSmallerDesktop ? -30 : -140,
                    y: -56,
                },
            }}
        >
            <TaskContainer>
                {outlet ? (
                    <Outlet />
                ) : (
                    <>
                        <DashboardHeading
                            title="POSTĘPY NAUKI"
                            hasBorder={true}
                            to={backwardPathname}
                        />
                        {parentChildrensData && (
                            <div className={styles.select}>
                                <ObjectSelectInput
                                    value={
                                        parentChildrensData.data.children
                                            .filter(
                                                (children) =>
                                                    children.user_id ===
                                                    childrenId
                                            )
                                            .map((children) => ({
                                                label: `${children.first_name} ${children.last_name}` as string,
                                                value: children.user_id as string,
                                            }))[0]
                                    }
                                    selectValues={parentChildrensData.data.children
                                        .filter(
                                            (children) =>
                                                children.status ===
                                                "Zaakceptowany"
                                        )
                                        .map((children) => ({
                                            label: `${children.first_name} ${children.last_name}` as string,
                                            value: children.user_id as string,
                                        }))}
                                    isLabelCenter={true}
                                    isLoading={state === "loading"}
                                    onChange={(value) =>
                                        navigate(
                                            `${getPreviousPath(pathname)}/${
                                                value.value
                                            }`
                                        )
                                    }
                                />
                            </div>
                        )}
                        {teacherStudentsData && (
                            <div className={styles.select}>
                                <ObjectSelectInput
                                    value={
                                        teacherStudentsData.data.students
                                            .filter(
                                                (student) =>
                                                    student.user_id ===
                                                    childrenId
                                            )
                                            .map((student) => ({
                                                label: `${student.first_name} ${student.last_name}` as string,
                                                value: student.user_id as string,
                                            }))[0]
                                    }
                                    selectValues={teacherStudentsData.data.students
                                        .filter(
                                            (student) =>
                                                student.status ===
                                                "Zaakceptowany"
                                        )
                                        .map((student) => ({
                                            label: `${student.first_name} ${student.last_name}` as string,
                                            value: student.user_id as string,
                                        }))}
                                    isLabelCenter={true}
                                    isLoading={state === "loading"}
                                    onChange={(value) =>
                                        navigate(
                                            `${getPreviousPath(pathname)}/${
                                                value.value
                                            }`
                                        )
                                    }
                                />
                            </div>
                        )}
                        <AnimatePresence mode="wait" initial={false}>
                            {learningProgressData?.data && (
                                <motion.div
                                    key={childrenId}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{
                                        type: "tween",
                                        duration: 0.2,
                                    }}
                                >
                                    <div className={styles.info}>
                                        <CounterBox
                                            count={
                                                learningProgressData.data
                                                    .user_plan_status
                                            }
                                            isVisual={true}
                                        >
                                            {getUserPlanStatusDescription(
                                                learningProgressData.data
                                                    .user_plan_status
                                            )}
                                        </CounterBox>
                                        <CounterBox
                                            count={
                                                learningProgressData.data
                                                    .exercise_done_count
                                            }
                                            isVisual={false}
                                        >
                                            Rozwiązanych
                                            <br /> zadań
                                        </CounterBox>
                                        <CounterBox
                                            count={
                                                learningProgressData.data
                                                    .exam_days_left
                                            }
                                            isVisual={false}
                                        >
                                            Dni do <br /> egzaminu
                                        </CounterBox>
                                    </div>
                                    <div className={styles.accordions}>
                                        {learningProgressData.data.progress.map(
                                            (item) => {
                                                const props = {
                                                    key: item.class,
                                                    title: item.title,
                                                };

                                                const children =
                                                    item.sections.length > 0 ? (
                                                        <>
                                                            <div
                                                                className={
                                                                    styles.progress
                                                                }
                                                            >
                                                                <ProgressDefault
                                                                    progress={
                                                                        item.percentage_done
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.buttons
                                                                }
                                                            >
                                                                {item.sections.map(
                                                                    (
                                                                        section
                                                                    ) => (
                                                                        <ProgressButton
                                                                            key={`${item.class}/${section.category_id}`}
                                                                            to={`${item.class}/${section.category_id}`}
                                                                            icon={
                                                                                section.category_icon
                                                                            }
                                                                            level={
                                                                                section.advancement
                                                                            }
                                                                        >
                                                                            {
                                                                                section.category_name
                                                                            }
                                                                        </ProgressButton>
                                                                    )
                                                                )}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div
                                                            className={
                                                                styles.empty
                                                            }
                                                        >
                                                            Brak zagadnień
                                                        </div>
                                                    );

                                                return item.class === "8_kl" ||
                                                    item.class ===
                                                        "matura_podstawowa" ||
                                                    item.class ===
                                                        "matura_rozszerzona" ? (
                                                    <ShieldAccordion
                                                        {...props}
                                                        icon={schoolIcon}
                                                    >
                                                        {children}
                                                    </ShieldAccordion>
                                                ) : (
                                                    <ShieldAccordion
                                                        {...props}
                                                        badge={romanize(
                                                            parseInt(item.class)
                                                        )}
                                                    >
                                                        {children}
                                                    </ShieldAccordion>
                                                );
                                            }
                                        )}
                                    </div>
                                </motion.div>
                            )}
                        </AnimatePresence>
                        <NavigationBar
                            backward={{
                                children: "WRÓĆ",
                                to: backwardPathname,
                            }}
                        />
                    </>
                )}
            </TaskContainer>
        </BackgroundContainer>
    );
};
