import { useState } from "react";

import { TaskCollapse } from "../TaskCollapse";

import { checkActiveCollapse } from "./utils";

import { TaskAccordionProps } from "./TaskAccordion.typings";

import styles from "./TaskAccordion.module.css";

export const TaskAccordion = ({ content }: TaskAccordionProps) => {
    const [elementIds, setElementIds] = useState<string[] | undefined>(
        undefined
    );

    const handleCollapse = (id: string) => {
        setElementIds((elementIds) =>
            elementIds
                ? checkActiveCollapse(elementIds, id)
                    ? elementIds.filter((elementId) => elementId !== id)
                    : [...elementIds, id]
                : [id]
        );
    };

    return (
        <div className={styles.container}>
            {content.length > 0 ? (
                content.map((item) => (
                    <TaskCollapse
                        key={item.id}
                        id={item.id}
                        title={item.name}
                        content={item.content}
                        isActive={checkActiveCollapse(elementIds, item.id)}
                        onClick={handleCollapse}
                    />
                ))
            ) : (
                <div className={styles.empty}>Brak teorii do wyświetlenia</div>
            )}
        </div>
    );
};
