import { Navigate, useLocation } from "react-router-dom";

import { AccountDeleteContent } from "../../../components";
import { DefaultButton } from "../../../../../components/buttons";

import { getPreviousPath } from "../../../../../utils";

import styles from "./AccountDeleteCodeResend.module.css";

type LocationType = {
    pathname: string;
    state: { isResend?: boolean } | null;
};

export const AccountDeleteCodeResend = () => {
    const { pathname, state }: LocationType = useLocation();

    if (!state?.isResend) {
        return <Navigate to={`${getPreviousPath(pathname)}`} replace={true} />;
    }

    return (
        <>
            <AccountDeleteContent>
                <p>
                    Kod potwierdzający do usunięcia konta
                    <br />
                    został wysłany.
                </p>
            </AccountDeleteContent>
            <DefaultButton
                wrapper={styles.forwardButton}
                to={`${getPreviousPath(pathname)}/confirm`}
            >
                DALEJ
            </DefaultButton>
        </>
    );
};
