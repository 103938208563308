import { ReactComponent as PenComponent } from "../../../../../assets/images/components/whiteboard/pen-icon.svg";
import styles from "./ColorButton.module.css";

type Props = {
    color: string;
    active: boolean;
    onClick: (value: string) => void;
};
export const ColorButton = ({ color, active, onClick }: Props) => (
    <button
        className={styles.colorButton}
        type="button"
        onClick={() => onClick(color)}
    >
        <span
            style={{
                borderColor: color,
                backgroundColor: active ? "white" : color,
            }}
        >
            <PenComponent fill={active ? color : "white"} />
        </span>
    </button>
);
