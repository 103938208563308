import { mainApi } from "../../../../api";

export const loadDiscountCodeQuery = (
    childrenId: string,
    discountCode?: string
) => ({
    queryKey: ["loadDiscountCode", childrenId],
    queryFn: async () =>
        mainApi.post("check_discount_code", {
            discount_code: discountCode,
        }),
    cacheTime: 1000,
    staleTime: 1000,
    enabled: false,
});
