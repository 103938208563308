import { useEffect, useState } from "react";

import { useValidateAuthToken } from "../../hooks";

import { SuspenseWithFlag } from "../utils/SuspenseWithFlag";

type Props = {
    children: JSX.Element;
    delay?: number;
};

export const PreloadContentWrapper = ({ children, delay = 1000 }: Props) => {
    const validateAuthToken = useValidateAuthToken();
    const isFetching = validateAuthToken.isFetching;
    const isFetched = validateAuthToken.isFetched;

    const [isSuspended, setIsSuspended] = useState(isFetched ? false : true);

    useEffect(() => {
        if (isFetching && isSuspended) {
            setTimeout(() => {
                setIsSuspended(false);
            }, delay);
        }
    }, [isFetching, isSuspended, delay]);

    return (
        <SuspenseWithFlag isSuspended={isSuspended}>
            {children}
        </SuspenseWithFlag>
    );
};
