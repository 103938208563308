import clsx from "clsx";

import styles from "./ColumnContainer.module.css";

type Props = {
    className?: string;
    children: JSX.Element | JSX.Element[];
};

export const ColumnContainer = ({ className, children }: Props) => {
    return (
        <div className={clsx(styles.container, className)}>
            {Array.isArray(children) ? (
                children.map((object, index) => {
                    return (
                        <div key={index} className={styles.column}>
                            {object}
                        </div>
                    );
                })
            ) : (
                <div className={styles.column}>{children}</div>
            )}
        </div>
    );
};
