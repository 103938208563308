import { getAccountCookie } from "../../../../services";
import { GetToKnow } from "../../../competencyTest/typings";

import { getActiveSubscription } from "../../utils/functions";

import { UserSubscriptionsResponse } from "../queries/useUserSubscriptions";

export const useIsSubscriptionPaid = (
    subscriptions: UserSubscriptionsResponse["subscriptions"] | undefined,
    studentData: GetToKnow | undefined
) => {
    const { isSubscriptionPaid } = getAccountCookie();

    if (isSubscriptionPaid && subscriptions && studentData) {
        if (
            studentData.data.school === "Podstawowa" &&
            getActiveSubscription(subscriptions, "Egzamin 8-klasisty")
        ) {
            return true;
        } else if (
            studentData.data.school === "Liceum" ||
            studentData.data.school === "Branżowa" ||
            studentData.data.school === "Technikum"
        ) {
            if (
                getActiveSubscription(subscriptions, "Matura podstawowa") ||
                getActiveSubscription(subscriptions, "Matura rozszerzona")
            ) {
                if (
                    studentData.data.class !== "matura_podstawowa" &&
                    studentData.data.class !== "matura_rozszerzona"
                ) {
                    return true;
                } else {
                    if (
                        (getActiveSubscription(
                            subscriptions,
                            "Matura podstawowa"
                        ) &&
                            studentData.data.class === "matura_podstawowa") ||
                        (getActiveSubscription(
                            subscriptions,
                            "Matura rozszerzona"
                        ) &&
                            studentData.data.class === "matura_rozszerzona")
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
};
