type Props = {
    isSmall?: boolean;
};

export const XMarkIcon = ({ isSmall }: Props) =>
    isSmall ? (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10.903"
            height="10.903"
            style={{ width: 10, height: 10 }}
            viewBox="0 0 10.903 10.903"
        >
            <g
                id="Group_11901"
                data-name="Group 11901"
                transform="translate(8657.571 -2327.429)"
            >
                <line
                    id="Line_29"
                    data-name="Line 29"
                    x2="11.418"
                    transform="translate(-8656.157 2336.917) rotate(-45)"
                    fill="none"
                    stroke="#fe5a5e"
                    strokeLinecap="round"
                    strokeWidth="2"
                />
                <line
                    id="Line_30"
                    data-name="Line 30"
                    x2="11.418"
                    transform="translate(-8656.157 2328.843) rotate(45)"
                    fill="none"
                    stroke="#fe5a5e"
                    strokeLinecap="round"
                    strokeWidth="2"
                />
            </g>
        </svg>
    ) : (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.243"
            height="16.243"
            style={{ width: 16, height: 16 }}
            viewBox="0 0 16.243 16.243"
        >
            <g
                id="Group_11901"
                data-name="Group 11901"
                transform="translate(8658.278 -2326.722)"
            >
                <line
                    id="Line_29"
                    data-name="Line 29"
                    x2="16.97"
                    transform="translate(-8656.157 2340.843) rotate(-45)"
                    fill="none"
                    stroke="#fe5a5e"
                    strokeLinecap="round"
                    strokeWidth="3"
                />
                <line
                    id="Line_30"
                    data-name="Line 30"
                    x2="16.97"
                    transform="translate(-8656.157 2328.843) rotate(45)"
                    fill="none"
                    stroke="#fe5a5e"
                    strokeLinecap="round"
                    strokeWidth="3"
                />
            </g>
        </svg>
    );
