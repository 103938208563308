import { Variants } from "framer-motion";

export const animationVariants: Variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: { type: "tween", duration: 0.25, ease: "easeInOut" },
  },
  exit: {
    opacity: 0,
    transition: { type: "tween", duration: 0.2, ease: "easeInOut" },
  },
};
