import { useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

const setNewQuery = (
    currentQuery: AxiosResponse<any, any>,
    email: string,
    key: string,
    isRemove?: boolean
) => {
    return {
        ...currentQuery,
        data: {
            ...currentQuery.data,
            [key]: isRemove
                ? currentQuery.data[key].filter(
                      (element: any) => element.email !== email
                  )
                : currentQuery.data[key].map((element: any) =>
                      element.email === email
                          ? { ...element, status: "Zaakceptowany" }
                          : element
                  ),
        },
    };
};

export const useOptimisticRelations = (queryKey: (string | number)[]) => {
    const queryClient = useQueryClient();

    const updateOptimisticRelations = (
        email: string,
        key: string,
        isRemove?: boolean
    ) => {
        const currentQuery =
            queryClient.getQueryData<AxiosResponse<any, any>>(queryKey);

        if (currentQuery) {
            const newQuery = setNewQuery(currentQuery, email, key, isRemove);

            queryClient.setQueryData(queryKey, newQuery);
        }
    };

    const revertOptimisticRelations = (data: unknown) =>
        queryClient.setQueryData(queryKey, data);

    return {
        updateOptimisticRelations,
        revertOptimisticRelations,
    };
};
