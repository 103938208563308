import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { userNotificationsQuery } from "../../utils";

import { NotificationType } from "../../typings";

type Response = {
    status: number;
    notifications: {
        id: string;
        title: string;
        body: string;
        type: NotificationType;
        url: string | undefined;
        created_at: string;
    }[];
};

export const useUserNotifications = () => {
    return useQuery<AxiosResponse<Response, any>>(userNotificationsQuery());
};
