import { getAccountCookie } from "../../../../../../services";

import { NavigationAccountItemProps } from "../../../../components/NavigationAccountItem/NavigationAccountItem.typings";

const student: (id?: string) => NavigationAccountItemProps[] = (id) =>
    [
        {
            children: "Edycja danych",
            icon: "person",
            to: "edit",
        },
        {
            children: "Opiekunowie",
            icon: "people",
            to: "parents",
            // onClick: removeParentsQuery,
        },
        id !== "2304" && {
            children: "Abonament",
            icon: "coin",
            to: "subscription",
        },
        {
            children: "Regulamin i polityka prywatności",
            icon: "libra",
            to: "legal",
        },
        {
            children: "Zmiana hasła",
            icon: "key",
            to: "change-password",
        },
        {
            children: "Usunięcie konta",
            icon: "trash",
            to: "delete",
            isImportant: true,
        },
    ].filter((item) => item) as NavigationAccountItemProps[];

const parent: (id?: string) => NavigationAccountItemProps[] = (id) =>
    [
        {
            children: "Edycja danych",
            icon: "person",
            to: "edit",
        },
        {
            children: "Dzieci",
            icon: "people",
            to: "childrens",
        },
        id !== "2304" && {
            children: "Abonament",
            icon: "coin",
            to: "subscription",
        },
        {
            children: "Regulamin i polityka prywatności",
            icon: "libra",
            to: "legal",
        },
        {
            children: "Zmiana hasła",
            icon: "key",
            to: "change-password",
        },
        {
            children: "Usunięcie konta",
            icon: "trash",
            to: "delete",
            isImportant: true,
            // onClick: removeChildrensQuery,
        },
    ].filter((item) => item) as NavigationAccountItemProps[];

const teacher: (id?: string) => NavigationAccountItemProps[] = () => [
    {
        children: "Edycja danych",
        icon: "person",
        to: "edit",
    },
    {
        children: "Uczniowie",
        icon: "people",
        to: "students",
        // onClick: removeStudentsQuery,
    },
    {
        children: "Regulamin i polityka prywatności",
        icon: "libra",
        to: "legal",
    },
    {
        children: "Zmiana hasła",
        icon: "key",
        to: "change-password",
    },
    {
        children: "Usunięcie konta",
        icon: "trash",
        to: "delete",
        isImportant: true,
    },
];

export const getNavigation = (
    type: ReturnType<typeof getAccountCookie>["accountType"],
    id?: string
) => {
    switch (type) {
        case "student":
            return student(id);
        case "parent":
            return parent(id);
        case "teacher":
            return teacher(id);
        default:
            return [];
    }
};
