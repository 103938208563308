import { useEffect, useRef } from "react";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";

export const useRedirectByPop = (to: string) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const navigationType = useNavigationType();

    const isActive = useRef(false);

    useEffect(() => {
        if (navigationType === "PUSH") {
            isActive.current = true;
        }

        if (navigationType === "POP" && isActive.current) {
            navigate(to, { replace: true });
        }
    }, [navigationType, navigate, to, pathname]);
};
