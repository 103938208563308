import userIcon from "../../../../../assets/images/navbar/user-icon.svg";
import logoutIcon from "../../../../../assets/images/navbar/logout-icon.svg";

import { NavbarButtonProps } from "../NavbarButton.typings";

export const getIcon = (icon: NavbarButtonProps["icon"]) => {
    switch (icon) {
        case "user":
            return userIcon;
        case "logout":
            return logoutIcon;
    }
};