import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

import { getIsMobile } from "../../../../utils";

import {
    getButtonAnimations,
    getIconAnimations,
    getMarkAnimations,
    getSymbolAnimations,
} from "./StoreChecker.animations";
import { StoreCheckerProps } from "./StoreChecker.typings";

import styles from "./StoreChecker.module.css";

import { ReactComponent as Check } from "./images/check-icon.svg";

const MotionCheck = motion(Check);

const isMobile = getIsMobile();

export const StoreChecker = ({
    label,
    labelAddon,
    isChecked,
    isRadio,
    isAvailableSoon,
    onClick,
}: StoreCheckerProps) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <motion.button
            className={styles.button}
            type="button"
            disabled={isAvailableSoon}
            onClick={!isAvailableSoon ? onClick : undefined}
            onMouseEnter={!isMobile ? () => setIsHovered(true) : undefined}
            onMouseLeave={!isMobile ? () => setIsHovered(false) : undefined}
            {...getButtonAnimations(isChecked, isAvailableSoon)}
        >
            <motion.span
                className={clsx(styles.icon, isRadio && styles.rounded)}
                {...getIconAnimations(isChecked, isAvailableSoon)}
            >
                <AnimatePresence mode="wait" initial={false}>
                    {(isChecked || isHovered) && (
                        <motion.span
                            className={styles.mark}
                            {...getMarkAnimations(isChecked, isHovered)}
                        >
                            {isRadio ? (
                                <motion.span
                                    className={styles.radio}
                                    {...getSymbolAnimations(
                                        isChecked,
                                        isHovered
                                    )}
                                ></motion.span>
                            ) : (
                                <MotionCheck
                                    {...getSymbolAnimations(
                                        isChecked,
                                        isHovered
                                    )}
                                />
                            )}
                        </motion.span>
                    )}
                </AnimatePresence>
            </motion.span>
            <span className={styles.label}>
                {label}
                {labelAddon && (
                    <span className={styles.labelAddon}>{labelAddon}</span>
                )}
            </span>
        </motion.button>
    );
};
