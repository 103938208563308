import { useQueryClient } from "@tanstack/react-query";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import isEqual from "lodash.isequal";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";

import { useRedirectContext } from "../../../../contexts/RedirectContext";

import { useMedia, useRedirectByPop } from "../../../../hooks";
import { useAiSession } from "../../../tasks/hooks";
import { useStudySchedule } from "../../hooks/mutations";
import { useStudentGetToKnowData } from "../../hooks/queries";
import { useIsSubscriptionPaid } from "../../../dashboard/hooks/utils";
import { useUserSubscriptions } from "../../../dashboard/hooks/queries";

import { DefaultButton } from "../../../../components/buttons";
import { BackgroundContainer } from "../../../../components/containers";
import { DashboardHeading } from "../../../../components/headings";
import { ButtonLoader } from "../../../../components/utils";
import { NavigationBar } from "../../../tasks/components";
import {
    Notification,
    NotificationsModal,
    RedirectLoading,
} from "../../components";
import { DefaultModal } from "../../../../components/modals";

import { getPreviousPath } from "../../../../utils";
import { studySettingsQuery } from "../../utils";
import { getNewNotification } from "./utils";

import { NotificationType } from "../../typings";

import styles from "./Notifications.module.css";

import infinityImg from "../../../../assets/images/robots/infinity-pleased.svg";
import piImg from "../../../../assets/images/robots/pi-confused-2.svg";
import iconPlus from "../../images/plus-icon.svg";

export const Notifications = () => {
    const { isSmallerDesktop } = useMedia();

    const queryClient = useQueryClient();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { state } = useNavigation();

    const { setRedirect } = useRedirectContext();

    const studyScheduleMutation = useStudySchedule();
    const { data: userSubscriptionsData } = useUserSubscriptions();
    const { data: studentData } = useStudentGetToKnowData();
    const { mutate: setAiSession, isLoading: isAiSessionLoading } =
        useAiSession();

    const isSubscriptionPaid = useIsSubscriptionPaid(
        userSubscriptionsData?.data.subscriptions,
        studentData?.data
    );

    const [notifications, setNotifications] = useState<NotificationType[]>(
        studentData?.data.data.schedule.map((notification) =>
            getNewNotification(notification)
        ) ?? []
    );
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    const isNotificationsValid = notifications.length > 0;
    const [loadingRoute, setLoadingRoute] = useState("");

    const onSubmit = () => {
        studyScheduleMutation.mutate(
            {
                times: notifications,
            },
            {
                onSuccess: () => {
                    queryClient.removeQueries(studySettingsQuery().queryKey);

                    if (studentData?.data.data.ai_ever_started === "0") {
                        if (isSubscriptionPaid) {
                            setAiSession({
                                invalidateStudentData: true,
                            });
                        } else {
                            navigate(
                                `${getPreviousPath(pathname, 2)}/restriction`,
                                {
                                    state: true,
                                }
                            );
                        }
                    } else {
                        navigate(
                            `${getPreviousPath(pathname, 1)}/study-settings`
                        );
                    }
                },
                onError: () => setLoadingRoute(""),
            }
        );
    };

    const backwardPathname =
        studentData?.data.data.ai_ever_started === "0"
            ? getPreviousPath(pathname, 2)
            : `${getPreviousPath(pathname)}/study-settings`;

    useRedirectByPop(backwardPathname);

    useEffect(() => {
        setRedirect(backwardPathname);
    }, [backwardPathname, setRedirect]);

    if (!state) {
        return <RedirectLoading />;
    }

    return (
        <BackgroundContainer
            leftElement={{
                image: infinityImg,
                position: { x: isSmallerDesktop ? 30 : 180, y: 180 },
                size: { height: 458, width: 250 },
            }}
            rightElement={{
                image: piImg,
                position: { x: isSmallerDesktop ? -30 : -180, y: -50 },
                size: { height: 214, width: 140 },
            }}
        >
            <DefaultModal
                isOpen={isErrorModalOpen}
                setIsOpen={setIsErrorModalOpen}
                actions={[
                    {
                        name: "Zamknij",
                        onClick: () => {
                            setIsErrorModalOpen(false);
                        },
                    },
                ]}
                title="Wystąpił błąd"
            >
                Posiadasz już powiadomienie o takiej samej godzinie oraz takich
                samych dniach tygodnia
            </DefaultModal>

            <div className={styles.root}>
                <DashboardHeading
                    title="HARMONOGRAM"
                    subtitle="ustaw powiadomienia"
                    to={backwardPathname}
                    hasBorder
                    hasSmallSpacing
                />
                <div className={styles.subtitle}>
                    kiedy mamy Ci przypominać?
                </div>
                <div className={styles.description}>
                    Dodaj dowolną ilość powiadomień.
                </div>
                <NotificationsModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    onSubmit={(values, { resetForm }) => {
                        if (
                            notifications.some(
                                (notification) =>
                                    notification.time === values.time &&
                                    isEqual(
                                        notification.daysOfWeek,
                                        values.daysOfWeek
                                    )
                            )
                        ) {
                            setIsErrorModalOpen(true);
                            return;
                        }
                        setNotifications((state) => [
                            ...state,
                            getNewNotification(values),
                        ]);
                        setIsModalOpen(false);
                        resetForm();
                    }}
                />
                <LayoutGroup>
                    <div className={styles.notifications}>
                        <AnimatePresence mode="sync" initial={false}>
                            {notifications.map((notification) => (
                                <Notification
                                    key={notification.id}
                                    notification={notification}
                                    setNotifications={setNotifications}
                                />
                            ))}
                        </AnimatePresence>
                        <motion.div layout className={styles.buttonAdd}>
                            <button
                                onClick={() => {
                                    setIsModalOpen(true);
                                }}
                                type="button"
                            >
                                <img src={iconPlus} alt="" />
                            </button>
                        </motion.div>
                    </div>

                    <motion.div layout="position" className={styles.buttons}>
                        <DefaultButton
                            initial={{
                                backgroundColor:
                                    notifications.length > 0
                                        ? DefaultButton.color.enabled
                                        : DefaultButton.color.disabled,
                            }}
                            animate={{
                                backgroundColor:
                                    notifications.length > 0
                                        ? DefaultButton.color.enabled
                                        : DefaultButton.color.disabled,
                            }}
                            isDisabled={
                                !isNotificationsValid ||
                                loadingRoute !== "" ||
                                isAiSessionLoading
                            }
                            onClick={() => {
                                setLoadingRoute("accept");

                                onSubmit();
                            }}
                        >
                            <ButtonLoader
                                condition={
                                    (studyScheduleMutation.isLoading &&
                                        loadingRoute === "accept") ||
                                    (isAiSessionLoading &&
                                        loadingRoute === "accept") ||
                                    (state === "loading" &&
                                        loadingRoute === "accept")
                                }
                                color="white"
                            >
                                POTWIERDŹ
                            </ButtonLoader>
                        </DefaultButton>
                        {!isNotificationsValid && (
                            <DefaultButton
                                className={styles.buttonDecline}
                                isDisabled={
                                    loadingRoute !== "" || isAiSessionLoading
                                }
                                onClick={() => {
                                    setLoadingRoute("skip");

                                    onSubmit();
                                }}
                            >
                                <ButtonLoader
                                    condition={
                                        (studyScheduleMutation.isLoading &&
                                            loadingRoute === "skip") ||
                                        (isAiSessionLoading &&
                                            loadingRoute === "skip") ||
                                        (state === "loading" &&
                                            loadingRoute === "skip")
                                    }
                                >
                                    NIE CHCĘ POWIADOMIEŃ
                                </ButtonLoader>
                            </DefaultButton>
                        )}
                    </motion.div>
                </LayoutGroup>
                <img className={styles.piMobile} src={piImg} alt="" />
                <NavigationBar
                    backward={{
                        children: "WRÓĆ",
                        to: backwardPathname,
                    }}
                />
            </div>
        </BackgroundContainer>
    );
};
