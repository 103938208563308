import { AccountIcon } from "../../../modules/auth/components";

import styles from "./IconHeading.module.css";

type Props = {
    iconType: React.ComponentProps<typeof AccountIcon>["type"];
    elementType?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    children: JSX.Element | string;
};

export const IconHeading = ({
    iconType,
    elementType: ElementType = "h3",
    children,
}: Props) => {
    return (
        <div className={styles.heading}>
            <div className={styles.icon}>
                <AccountIcon type={iconType} />
            </div>
            <ElementType className={styles.title}>{children}</ElementType>
        </div>
    );
};
