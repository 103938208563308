export const getResponseMessage = <B extends boolean = false>(
    messages: object,
    hasTarget?: B
): B extends true ? string[] : string => {
    let message = "";
    let input = "";

    for (const [key, value] of Object.entries(messages)) {
        if (!message) {
            message = value as string;

            if (hasTarget) {
                input = key as string;
            }

            break;
        }
    }

    return hasTarget ? ([message, input] as any) : message;
};
