import { v4 as uuid } from "uuid";
import * as yup from "yup";

import { NotificationType } from "../../typings";

export const getStringifiedDaysOfWeek = (
    daysOfWeek: NotificationType["daysOfWeek"]
) => {
    if (Object.values(daysOfWeek).every((value) => value)) {
        return "codziennie";
    }

    return Object.entries(daysOfWeek)
        .flatMap(([key, value]) => {
            if (!value) {
                return [];
            }

            switch (key) {
                case "monday":
                    return "pon.";
                case "tuesday":
                    return "wt.";
                case "wednesday":
                    return "śr.";
                case "thursday":
                    return "czw.";
                case "friday":
                    return "pt.";
                case "saturday":
                    return "sob.";
                case "sunday":
                    return "niedz.";
                default:
                    return [];
            }
        })
        .join(", ");
};

export const validationSchema = yup.object({
    id: yup.string(),
    time: yup.string().required(),
    daysOfWeek: yup
        .object({
            monday: yup.boolean(),
            tuesday: yup.boolean(),
            wednesday: yup.boolean(),
            thursday: yup.boolean(),
            friday: yup.boolean(),
            saturday: yup.boolean(),
            sunday: yup.boolean(),
        })
        .test({ test: (value) => Object.values(value).some((v) => v) }),
});

export const getNewNotification = ({
    time,
    daysOfWeek,
}: Omit<NotificationType, "id">): NotificationType => ({
    id: uuid(),
    time,
    daysOfWeek,
});
