import React from 'react';
import { Link } from "react-router-dom";
import clsx from 'clsx';

import styles from "./ActionText.module.css";

type Props = {
    className?: string;
    messages: string[];
    button: string;
    callback?: () => void;
    to?: string;
    isButtonLast?: boolean;
};

type AuthButtonProps = {
    callback?: () => void;
    to?: string;
    children: string;
};

const AuthButton = ({ callback, to, children }: AuthButtonProps) => {
    return callback ? (
        <button className={styles.button} type="button" onClick={callback}>
            {children}
        </button>
    ) : to ? (
        <Link className={styles.button} to={to}>{children}</Link>
    ) : null;
};

export const ActionText = ({
    className,
    messages,
    button,
    callback,
    to,
    isButtonLast,
}: Props) => {
    return (
        <div className={clsx(styles.messages, className)}>
            {messages.map((message, index) => {
                const isMessageLast = messages.length === index + 1;

                if (isMessageLast && isButtonLast)
                    return (
                        <React.Fragment key={message}>
                            <p>{message}</p>
                            <p>
                                <AuthButton callback={callback} to={to}>
                                    {button}
                                </AuthButton>
                            </p>
                        </React.Fragment>
                    );

                return (
                    <p key={message}>
                        {message}
                        {isMessageLast ? (
                            <>
                                {" "}
                                <AuthButton callback={callback} to={to}>
                                    {button}
                                </AuthButton>
                            </>
                        ) : null}
                    </p>
                );
            })}
        </div>
    );
};
