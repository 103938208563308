import { Outlet, useOutlet } from "react-router-dom";

import { useMedia } from "../../../../../hooks";
import { useNavigationAccount } from "../../../hooks";

import { useDeleteAccountContext } from "../../Dashboard";

import { DashboardHeading } from "../../../../../components/headings";
import {
    AccountContainer,
    BackgroundContainer,
} from "../../../../../components/containers";
import { NavigationAccount } from "../../../components";

import infinity from "../../../images/account/robot-infinity.svg";
import pi from "../../../images/account/robot-pi.svg";

export const StudentAccount = () => {
    const { isSmallerDesktop } = useMedia();

    const outlet = useOutlet();
    const deleteAccountContext = useDeleteAccountContext();
    const navigation = useNavigationAccount();

    return (
        <BackgroundContainer
            leftElement={{
                image: infinity,
                size: {
                    width: 251,
                    height: 458,
                },
                position: {
                    x: isSmallerDesktop ? 30 : 180,
                    y: 164,
                },
            }}
            rightElement={{
                image: pi,
                size: {
                    width: 149,
                    height: 249,
                },
                position: {
                    x: isSmallerDesktop ? -48 : -230,
                    y: -74,
                },
            }}
        >
            {outlet ? (
                <Outlet context={deleteAccountContext} />
            ) : (
                <>
                    <DashboardHeading title="MOJE KONTO" />
                    <AccountContainer>
                        <NavigationAccount
                            elements={navigation}
                            isFooterVisible={true}
                        />
                    </AccountContainer>
                </>
            )}
        </BackgroundContainer>
    );
};
