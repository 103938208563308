import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import {
    GetCompetencyTest,
    GetExamDates,
    GetToKnow,
    StudySettings,
} from "../typings";

import {
    getCompetencyTestQuery,
    getExamDatesQuery,
    setCompetencyTestAsDoneQuery,
    studentGetToKnowDataQuery,
    studySettingsQuery,
} from "../utils/queries";

export const useStudentGetToKnowData = () => {
    return useQuery<AxiosResponse<GetToKnow>>(studentGetToKnowDataQuery());
};

export const useGetCompetencyTest = () =>
    useQuery<AxiosResponse<GetCompetencyTest>>(
        getCompetencyTestQuery()
    );

export const useSetCompetencyTestAsDone = (isEnabled: boolean) =>
    useQuery(setCompetencyTestAsDoneQuery(isEnabled));

export const useGetExamDates = () =>
    useQuery<AxiosResponse<GetExamDates>>(getExamDatesQuery());

export const useStudySettings = () => {
    return useQuery<AxiosResponse<StudySettings, any>>(studySettingsQuery());
};
