import { useLocation, useNavigation, useParams } from "react-router-dom";

import { setTaskParams } from "../../../tasks/services";

import { useExamTaskDetails } from "../../hooks";

import { DashboardHeading } from "../../../../components/headings";
import { NavigationBar, TaskItem } from "../../../tasks/components";

import { createGridRepeat, getPreviousPath } from "../../../../utils";
import { taskSlug } from "../../../tasks/utils";

import styles from "./ExamTaskDetails.module.css";

import robotImg from "../../../../assets/images/robots/infinity-student.svg";

export const ExamTaskDetails = () => {
    const { pathname } = useLocation();
    const params = useParams();
    const { state } = useNavigation();

    const examId = params.examId as string;
    const isLoadingState = state === "loading";

    const examTaskDetailsQuery = useExamTaskDetails(examId);

    return (
        <div className={styles.examTaskDetails}>
            <img className={styles.infinity} src={robotImg} alt="" />
            {examTaskDetailsQuery.data && (
                <>
                    <DashboardHeading
                        title={examTaskDetailsQuery.data?.data.exam_title}
                        hasBorder
                    />

                    <div
                        style={{
                            gridTemplateRows: createGridRepeat(
                                examTaskDetailsQuery.data.data.exercises.length
                            ),
                        }}
                        className={styles.tasks}
                    >
                        {examTaskDetailsQuery.data?.data.exercises.map(
                            (exercise, index) => {
                                const isSuccess =
                                    exercise.already_tried &&
                                    exercise.done_correctly;
                                const isError =
                                    exercise.already_tried &&
                                    !exercise.done_correctly;

                                return (
                                    <TaskItem
                                        key={exercise.id}
                                        to={`${getPreviousPath(
                                            pathname,
                                            2
                                        )}/task/${taskSlug.exercise}/${
                                            exercise.id
                                        }`}
                                        level={exercise.difficulty}
                                        isExam
                                        isSuccess={isSuccess}
                                        isError={isError}
                                        isLoading={isLoadingState}
                                        onClick={() =>
                                            setTaskParams({
                                                type: taskSlug.exercise,
                                                taskId: exercise.id.toString(),
                                                additionalId: examId,
                                            })
                                        }
                                    >
                                        {`Zadanie ${index + 1}`}
                                    </TaskItem>
                                );
                            }
                        )}
                    </div>
                </>
            )}

            <NavigationBar
                backward={{
                    children: "WRÓĆ",
                }}
            />
        </div>
    );
};
