import clsx from "clsx";
import parse from "html-react-parser";

import { replaceOptions } from "../../../utils";

import styles from "./TaskContent.module.css";

type Props = {
    content: string | undefined;
    isInline?: boolean;
};

export const TaskContent = ({ content, isInline }: Props) => {
    const options = replaceOptions(styles);

    return content ? (
        <div className={clsx(styles.container, isInline && styles.inline)}>
            {/* style=\"font-family:Arial;\" breaks parse() */}
            {parse(
                content.replaceAll('style="font-family:Arial;"', ""),
                options
            )}
        </div>
    ) : null;
};
