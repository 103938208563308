import Cookies from "js-cookie";

export const getTokenCookie = (): string | undefined => Cookies.get("token");

export const setTokenCookie = (token: string): string | undefined =>
    Cookies.set("token", token, {
        sameSite: "strict",
        expires: 30,
    });

export const removeTokenCookie = (): void =>
    Cookies.remove("token", { sameSite: "strict" });
