import { useMediaQuery } from "react-responsive";

export const useMedia = () => {
    const isDesktop = useMediaQuery({
        minWidth: 1200,
    });

    const isSmallerDesktop = useMediaQuery({
        minWidth: 1200,
        maxWidth: 1599,
    });

    return { isDesktop, isSmallerDesktop };
};
