import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { learningProgressCategoryQuery } from "../../utils";

import { DifficultyLevels } from "../../../tasks/typings";
import { LearningProgressAccountType } from "../../typings";

type Response = {
    category_id: string;
    category_name: string;
    category_image: string;
    category_image_2: string;
    progress: number;
    subcategories: {
        id: string;
        name: string;
        ZwZ: DifficultyLevels["advanced"];
    }[];
};

export const useLearningProgressCategory = <
    T extends LearningProgressAccountType
>(
    type: T,
    classType: string,
    categoryId: string,
    childrenId: T extends "other" ? string : undefined
) => {
    return useQuery<AxiosResponse<Response, any>>(
        learningProgressCategoryQuery(type, classType, categoryId, childrenId)
    );
};
