type TextProps = {
    children: string;
    isDesktop?: boolean;
};

const Text = ({ children, isDesktop }: TextProps) => {
    return (
        <span
            style={{
                display: "block",
                fontWeight: 300,
                ...(isDesktop
                    ? {
                          fontSize: "30px",
                          lineHeight: "34px",
                          marginTop: "10px",
                      }
                    : {
                          fontSize: "18px",
                          lineHeight: "21px",
                          marginTop: "7px",
                      }),
            }}
        >
            {children}
        </span>
    );
};

export const getAiSessionDescription = (score: number, isDesktop?: boolean) => {
    if (score < 40) {
        return (
            <>
                Trening czyni mistrza! <strong>{score.toFixed(2)}%</strong>{" "}
                poprawnie rozwiązanych zadań!{" "}
                <Text isDesktop={isDesktop}>
                    Jeśli chcesz poprawić swój wynik przejdź do bazy zadań i
                    rozwiąż dodatkowe zadania.
                </Text>
            </>
        );
    } else if (score < 59.99999999) {
        return (
            <>
                Robisz postępy! <strong>{score.toFixed(2)}%</strong> poprawnie
                rozwiązanych zadań!
                <Text isDesktop={isDesktop}>
                    Jeśli chcesz poprawić swój wynik przejdź do bazy zadań i
                    rozwiąż dodatkowe zadania.
                </Text>
            </>
        );
    } else if (score < 79.99999999) {
        return (
            <>
                Dobra robota! <strong>{score.toFixed(2)}%</strong> poprawnie
                rozwiązanych zadań!{" "}
                <Text isDesktop={isDesktop}>
                    Jeśli chcesz poprawić swój wynik przejdź do bazy zadań i
                    rozwiąż dodatkowe zadania.
                </Text>
            </>
        );
    } else {
        return (
            <>
                Idzie Ci świetnie! <strong>{score.toFixed(2)}%</strong>{" "}
                poprawnie rozwiązanych zadań!{" "}
                {score !== 100 && (
                    <Text isDesktop={isDesktop}>
                        Jeśli chcesz poprawić swój wynik przejdź do bazy zadań i
                        rozwiąż dodatkowe zadania.
                    </Text>
                )}
            </>
        );
    }
};
