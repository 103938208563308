import { AnimatePresence, motion } from "framer-motion";

import { NotificationsDropdown } from "../NotificationsDropdown";
import { NotificationsToggle } from "../NotificationsToggle";

import { dropdownAnimations } from "../NavbarDesktop/NavbarDesktop.typings";

import styles from "./NotificationsDesktop.module.css";

const AnimatedDropdown = motion(NotificationsDropdown);

type Props = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NotificationsDesktop = ({ isOpen, setIsOpen }: Props) => {
    return (
        <>
            <div className={styles.container}>
                <NotificationsToggle
                    onClick={() => setIsOpen((open) => !open)}
                />
                <AnimatePresence initial={false}>
                    {isOpen && (
                        <AnimatedDropdown
                            {...dropdownAnimations}
                            transition={{ type: "tween", duration: 0.2 }}
                            setIsOpen={setIsOpen}
                        />
                    )}
                </AnimatePresence>
            </div>
            {isOpen && (
                <div
                    className={styles.mask}
                    onClick={() => setIsOpen(false)}
                ></div>
            )}
        </>
    );
};
