import { useState, useEffect } from "react";
import { motion } from "framer-motion";

import { useAnswersContext } from "../../../contexts/AnswersContext";
import { useResultContext } from "../../../contexts/ResultContext";

import {
    TruthProps,
    ButtonProps,
    HighlightProps,
    SelectionProps,
} from "./AnswerTruth.typings";
import {
    buttonVariants,
    highlightVariants,
    radioVariants,
    transition,
} from "./AnswerTruth.animations";

import styles from "./AnswerTruth.module.css";

export const AnswerTruth = ({ id, isLoading }: TruthProps) => {
    const { getProvidedAnswer, provideAnswer } = useAnswersContext();
    const answer = getProvidedAnswer(id);

    const { getResult, isResultsLoading } = useResultContext();
    const result = getResult(id, "true_or_false");

    const [isInit, setIsInit] = useState(answer !== undefined ? true : false);
    const [isSelected, setIsSelected] = useState<SelectionProps>(
        answer !== undefined ? answer : undefined
    );

    useEffect(() => {
        if (isSelected !== undefined && !isInit) {
            setIsInit(true);
        }
    }, [isSelected, isInit]);

    useEffect(() => {
        if (isSelected === true || isSelected === false) {
            provideAnswer({
                id,
                type: "true_or_false",
                value: isSelected,
            });
        }
    }, [isSelected, provideAnswer, id]);

    const isCorrect = result ? result.correct : undefined;
    const isSuccess = isCorrect === true;
    const isError = isCorrect === false;

    return (
        <div className={styles.container}>
            <div className={styles.field}>
                <motion.div
                    className={styles.radio}
                    variants={radioVariants}
                    initial={
                        isSelected !== undefined
                            ? isSuccess
                                ? "success"
                                : isError
                                ? "error"
                                : "active"
                            : "inactive"
                    }
                    animate={
                        isSelected !== undefined
                            ? isSuccess
                                ? "success"
                                : isError
                                ? "error"
                                : "active"
                            : "inactive"
                    }
                    transition={transition}
                >
                    <Button
                        id={id}
                        isSelected={isSelected === true}
                        isSuccess={isSuccess}
                        isError={isError}
                        isDirty={isInit}
                        isLoading={isLoading}
                        isDisabled={isResultsLoading}
                        onClick={() => setIsSelected(true)}
                    >
                        Prawda
                    </Button>
                    <Button
                        id={id}
                        isSelected={isSelected === false}
                        isSuccess={isSuccess}
                        isError={isError}
                        isDirty={isInit}
                        isLoading={isLoading}
                        isDisabled={isResultsLoading}
                        onClick={() => setIsSelected(false)}
                    >
                        Fałsz
                    </Button>
                </motion.div>
            </div>
        </div>
    );
};

const Button = ({
    id,
    children,
    isSelected,
    isSuccess,
    isError,
    isDirty,
    isLoading,
    isDisabled,
    onClick,
}: ButtonProps) => (
    <motion.button
        className={styles.button}
        variants={buttonVariants}
        initial={isSelected ? "active" : "inactive"}
        animate={isSelected ? "active" : "inactive"}
        transition={transition}
        type="button"
        disabled={isSelected || isSuccess || isError || isLoading || isDisabled}
        onClick={onClick}
    >
        {children}
        {isSelected ? (
            <Highlight
                id={id}
                isSuccess={isSuccess}
                isError={isError}
                isDirty={isDirty}
            />
        ) : null}
    </motion.button>
);

const Highlight = ({ id, isSuccess, isError, isDirty }: HighlightProps) => (
    <motion.span
        className={styles.highlight}
        layoutId={`truth-highlight_${id}`}
        variants={highlightVariants}
        initial={
            isSuccess
                ? "success"
                : isError
                ? "error"
                : isDirty
                ? "active"
                : "inactive"
        }
        animate={
            isSuccess
                ? "success"
                : isError
                ? "error"
                : isDirty
                ? "active"
                : "inactive"
        }
        transition={transition}
    ></motion.span>
);
