import { Options } from "../../../components/inputs";
import { Source } from "../typings";

export const sourceOptions: Options<string, Source> = [
    { label: "Zadanie z książki", value: "book" },
    {
        label: "Zadanie z innego źródła",
        value: "other",
    },
];
