import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { subscriptionOptionsQuery } from "../../utils";

import { SubscriptionKeys } from "../../typings";

export type StoreOptionsResponse = {
    subscriptions: {
        id: string;
        name: SubscriptionKeys;
        packages: {
            id: string;
            price: string;
            value: string;
        }[];
    }[];
};

export const useSubscriptionOptions = () => {
    return useQuery<AxiosResponse<StoreOptionsResponse, any>>(
        subscriptionOptionsQuery()
    );
};
