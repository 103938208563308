import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { romanize } from "romans";

import { useRedirectContext } from "../../../../../contexts/RedirectContext";

import { useLearningProgressSubcategory } from "../../../hooks";

import {
    DashboardHeading,
    TaskHeading,
} from "../../../../../components/headings";
import {
    NavigationBar,
    TasksLearningProgress,
} from "../../../../tasks/components";

import { getPreviousPath } from "../../../../../utils";

import schoolIcon from "../../../images/learning-progress/school-icon.svg";

export const LearningProgressSubcategory = () => {
    const { pathname } = useLocation();
    const params = useParams();

    const { setRedirect } = useRedirectContext();

    const classType = params.classType as string;
    const categoryId = params.categoryId as string;
    const subcategoryId = params.subcategoryId as string;

    const { data } = useLearningProgressSubcategory(
        "student",
        classType,
        subcategoryId,
        undefined
    );

    const backwardPathname = getPreviousPath(pathname, 1);

    useEffect(() => {
        setRedirect(backwardPathname);
    }, [backwardPathname, setRedirect]);

    return (
        <>
            {data && (
                <>
                    <DashboardHeading
                        title={data.data.category_name}
                        icon={data.data.category_image_2}
                        userClass={
                            data.data.class === "matura_podstawowa" ||
                            data.data.class === "matura_rozszerzona" ||
                            data.data.class === "8_kl" ? (
                                <img src={schoolIcon} alt="" />
                            ) : (
                                romanize(parseInt(data.data.class))
                            )
                        }
                        hasSmallSpacing={true}
                    />
                    <TaskHeading
                        title={data.data.subcategory_name.toUpperCase()}
                        tooltip="Teoria"
                    />
                    <TasksLearningProgress
                        progress={{
                            good: data.data.progress.done_right,
                            bad: data.data.progress.done_wrong,
                            total: data.data.progress.total,
                        }}
                        tasks={data.data.exercises}
                        params={{
                            categoryId,
                            subcategoryId,
                            classType,
                        }}
                    />
                    <NavigationBar
                        backward={{
                            children: "WRÓĆ DO LISTY TEMATÓW",
                        }}
                    />
                </>
            )}
        </>
    );
};
