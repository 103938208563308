const colors = {
    white: "#FFFFFF",
    gray: "#E5E7EA",
    darkGray: "#879195",
    darkGray2: "#374247",
    success: "#2EAFBE",
    error: "#FE5A5E",
};

const labelStatic = {
    y: 14,
    fontSize: "16px",
    lineHeight: "18px",
    opacity: 1,
};

const labelActive = {
    y: -17,
    fontSize: "13px",
    lineHeight: "15px",
    opacity: 1,
};

const collapseVariants = {
    closed: {
        height: 35,
    },
    open: {
        height: 45,
    },
    success: {
        height: "auto",
    },
};

const labelVariants = {
    static: {
        ...labelStatic,
        color: colors.darkGray,
    },
    active: { ...labelActive },
    filled: { ...labelActive, color: colors.success },
    success: { ...labelActive, color: colors.darkGray, opacity: 0 },
    error: { ...labelActive, color: colors.error },
    disabled: { ...labelActive, color: colors.darkGray },
};

const inputVariants = {
    static: {
        backgroundColor: colors.white,
        borderColor: colors.white,
        color: colors.darkGray2,
    },
    filled: {
        backgroundColor: colors.white,
        borderColor: colors.success,
        color: colors.darkGray2,
    },
    success: {
        backgroundColor: colors.white,
        borderColor: colors.white,
        color: colors.white,
    },
    error: {
        backgroundColor: colors.white,
        borderColor: colors.error,
        color: colors.darkGray2,
    },
    disabled: {
        backgroundColor: colors.gray,
        borderColor: colors.gray,
        color: colors.darkGray2,
    },
};

const buttonVariants = {
    success: { background: colors.success },
    error: { background: colors.error },
};

const iconVariants = {
    success: {
        rotate: 0,
    },
    error: {
        rotate: 45,
    },
};

export {
    collapseVariants,
    labelVariants,
    inputVariants,
    buttonVariants,
    iconVariants,
};
