import { useMedia } from "../../../../hooks";

import { AccountSocial } from "../AccountSocial";
import { AppDownload } from "../AppDownload";

import styles from "./HomeWrapper.module.css";

type Props = {
    children: JSX.Element | JSX.Element[];
};

export const HomeWrapper = ({ children }: Props) => {
    const { isDesktop } = useMedia();

    return isDesktop ? (
        <div className={styles.container}>
            {children}
            <div className={styles.footer}>
                <AppDownload />
                <div className={styles.social}>
                    <AccountSocial />
                </div>
            </div>
        </div>
    ) : (
        <>{children}</>
    );
};
