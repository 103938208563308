import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { parentChildrensQuery } from "../../utils";
import { SubscriptionType } from "../../typings";

type Response = {
    children: {
        email: string;
        status: "Zaproszony" | "Zaakceptowany";
        user_id?: string;
        first_name?: string;
        last_name?: string;
        class?: string;
        teachers: {
            email: string;
            status: "Zaproszony" | "Zaakceptowany";
            user_id: string | null;
            first_name: string | null;
            last_name: string | null;
            class: string | null;
            invited_by: string;
        }[];
        subscriptions: SubscriptionType[];
        invited_by: string;
    }[];
};

export const useParentChildrens = (
    isEnabled?: boolean,
    isFasterRefetch?: boolean
) => {
    return useQuery<AxiosResponse<Response, any>>(
        parentChildrensQuery(isEnabled, isFasterRefetch)
    );
};
