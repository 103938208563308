import { useEffect, useState, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import clsx from "clsx";

import { useAuthContext } from "../../../../../contexts/AuthContext";

import { useCheckAccountStatus, useNewChildCodeResend } from "../../../hooks";
import { useMedia } from "../../../../../hooks";

import { getAccountCookie, setAccountCookie } from "../../../../../services";

import { AuthContainer } from "../../../../../components/containers";
import { MainHeading } from "../../../../../components/headings";
import { AuthText } from "../../../../../components/texts";
import { DefaultButton } from "../../../../../components/buttons";
import { DefaultModal } from "../../../../../components/modals";

import styles from "./DashboardLocked.module.css";

import pi from "../../../../../assets/images/robots/pi-tears.svg";

const refreshTime = 10;

export const DashboardLocked = () => {
    const {
        parentEmail,
        isAccountDisabled,
        isParentConfirmationRequired,
        isParentApproved,
    } = getAccountCookie();

    const { logout } = useAuthContext();
    const navigate = useNavigate();

    const checkAccountStatus = useCheckAccountStatus();
    const newChildCodeResend = useNewChildCodeResend();
    const { isDesktop } = useMedia();

    const [isCodeResent, setIsCodeResent] = useState(false);
    const [isRefreshed, setIsRefreshed] = useState<[boolean, number]>([
        false,
        0,
    ]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const refreshTimeout = useRef<NodeJS.Timeout | null>(null);
    const refreshInterval = useRef<NodeJS.Timer | null>(null);

    const handleRefresh = () => {
        if (!isRefreshed[0]) {
            checkAccountStatus.mutate();
        }
    };

    const handleConfirmationResend = () => {
        setIsCodeResent(true);

        newChildCodeResend.mutate();
    };

    useEffect(() => {
        if (!isRefreshed[0] && checkAccountStatus.data) {
            const status = checkAccountStatus.data.data.status;

            if (status === 401) {
                setIsRefreshed([true, refreshTime]);
            }

            if (status === 200) {
                setAccountCookie({
                    isParentApproved: "true",
                });

                navigate("/dashboard/student", {
                    replace: true,
                });
            }

            checkAccountStatus.reset();
        }
    }, [isRefreshed, checkAccountStatus, navigate]);

    useEffect(() => {
        if (isRefreshed[0] && isRefreshed[1] === refreshTime) {
            refreshTimeout.current = setTimeout(() => {
                setIsRefreshed([false, 0]);
            }, isRefreshed[1] * 1000);

            refreshInterval.current = setInterval(() => {
                setIsRefreshed((prevState) => {
                    if (prevState[1] - 1 <= 1) {
                        if (refreshInterval.current) {
                            clearInterval(refreshInterval.current);
                        }
                    }

                    return [prevState[0], prevState[1] - 1];
                });
            }, 1000);
        }
    }, [isRefreshed]);

    useEffect(
        () => () => {
            if (refreshTimeout.current) {
                clearTimeout(refreshTimeout.current);
            }

            if (refreshInterval.current) {
                clearInterval(refreshInterval.current);
            }
        },
        []
    );

    if (isAccountDisabled) {
        return <Navigate to={`/dashboard/disabled`} replace={true} />;
    }

    if (
        !isParentConfirmationRequired ||
        (isParentConfirmationRequired && isParentApproved)
    ) {
        return <Navigate to="/dashboard/student" replace={true} />;
    }

    return (
        <>
            <AuthContainer
                position="center"
                image={pi}
                imageSize={isDesktop ? 285 : 214}
                imagePosition="right"
                imageOffsetX={isDesktop ? 78 : undefined}
                imageOffsetY={isDesktop ? 15.5556 : undefined}
            >
                <MainHeading wrapper={styles.heading} elementType="h2">
                    {isCodeResent
                        ? "Wysłano mail Twojemu rodzicowi"
                        : "Twoje konto czeka na akceptację rodzica"}
                </MainHeading>
                <AuthText>
                    {isCodeResent ? (
                        <p>
                            Wysłaliśmy mail z linkiem potwierdzającym
                            <br />
                            na email Twojego rodzica:{" "}
                            <strong>{parentEmail}</strong>
                        </p>
                    ) : (
                        <>
                            <p>
                                Daj mu znać, że link potwierdzający
                                <br />
                                czeka na jego mailu{" "}
                                <strong>{parentEmail}</strong>.
                            </p>
                            <p>
                                <button
                                    type="button"
                                    onClick={handleConfirmationResend}
                                >
                                    WYŚLIJ PONOWNIE
                                </button>
                            </p>
                        </>
                    )}
                </AuthText>
                {isCodeResent ? (
                    <DefaultButton
                        className={clsx(styles.button, styles.forwardButton)}
                        whileHover={{ opacity: 0.7 }}
                        onClick={() => setIsCodeResent(false)}
                    >
                        DALEJ
                    </DefaultButton>
                ) : (
                    <>
                        <DefaultButton
                            className={clsx(
                                styles.button,
                                styles.refreshButton
                            )}
                            initial={{
                                backgroundColor:
                                    checkAccountStatus.isLoading ||
                                    isRefreshed[0]
                                        ? DefaultButton.color.disabled
                                        : DefaultButton.color.enabled,
                            }}
                            animate={{
                                backgroundColor:
                                    checkAccountStatus.isLoading ||
                                    isRefreshed[0]
                                        ? DefaultButton.color.disabled
                                        : DefaultButton.color.enabled,
                            }}
                            whileHover={{
                                opacity:
                                    checkAccountStatus.isLoading ||
                                    isRefreshed[0]
                                        ? 1
                                        : 0.7,
                            }}
                            transition={{ type: "tween", duration: 0.3 }}
                            isDisabled={
                                checkAccountStatus.isLoading || isRefreshed[0]
                            }
                            onClick={handleRefresh}
                        >
                            ODŚWIEŻ {isRefreshed[0] && `(${isRefreshed[1]})`}
                        </DefaultButton>
                        <DefaultButton
                            className={clsx(styles.button, styles.logoutButton)}
                            whileHover={{ opacity: 0.7 }}
                            onClick={() => setIsModalOpen(true)}
                        >
                            WYLOGUJ
                        </DefaultButton>
                    </>
                )}
            </AuthContainer>
            <DefaultModal
                title="WYLOGUJ"
                actions={[
                    {
                        name: "TAK, WYLOGUJ",
                        isImportant: true,
                        onClick: logout,
                    },
                    {
                        name: "NIE, WRÓĆ",
                        onClick: () => setIsModalOpen(false),
                    },
                ]}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            >
                <p>
                    Czy na pewno chcesz się
                    <br />
                    wylogować?
                </p>
            </DefaultModal>
        </>
    );
};
