import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { useRedirectContext } from "../../../../contexts/RedirectContext";

import { useMedia, useRedirectByPop } from "../../../../hooks";
import { useStudentGetToKnowData } from "../../hooks/queries";

import { BackgroundContainer } from "../../../../components/containers";
import { RedirectLoading } from "../../components";

import { getPreviousPath } from "../../../../utils";

import {
    CompetencyTestContextType,
    CompetencyTestLocation,
} from "../../typings";

import styles from "./CompetencyTestForm.module.css";

import infinityImg from "../../../../assets/images/robots/infinity.svg";
import piMobileImg from "../../../../assets/images/robots/pi-confused-2.svg";
import piImg from "../../../../assets/images/robots/pi.svg";

export const CompetencyTestForm = () => {
    const { isSmallerDesktop } = useMedia();
    const { pathname, state }: CompetencyTestLocation = useLocation();

    const { setRedirect } = useRedirectContext();

    const [form, setForm] = useState<CompetencyTestContextType["form"]>({
        class: undefined,
        grade: undefined,
        opinion: undefined,
        scope: undefined,
        exam: { label: "Matura podstawowa", value: "matura_podstawowa" },
    });

    const studentGetToKnowDataQuery = useStudentGetToKnowData();

    useEffect(() => {
        if (studentGetToKnowDataQuery.data) {
            const data = studentGetToKnowDataQuery.data.data.data;

            setForm((state) => ({
                ...state,
                grade: data.grade
                    ? { label: data.grade, value: data.grade }
                    : undefined,
                opinion: data.what_is_math
                    ? { label: data.what_is_math, value: data.what_is_math }
                    : undefined,
                scope: data.scope ?? undefined,
            }));
        }
    }, [studentGetToKnowDataQuery.data]);

    const backwardPathname = getPreviousPath(pathname, 3);

    useEffect(() => {
        setRedirect(backwardPathname);
    }, [backwardPathname, setRedirect]);

    useRedirectByPop('/dashboard/student');

    if (!state) {
        return <RedirectLoading />;
    }

    return (
        <BackgroundContainer
            leftElement={{
                image: infinityImg,
                position: { x: isSmallerDesktop ? 30 : 180, y: 180 },
                size: { height: 458, width: 250 },
            }}
            rightElement={{
                image: piImg,
                position: { x: isSmallerDesktop ? -30 : -180, y: -50 },
                size: { height: 214, width: 140 },
            }}
        >
            <div className={styles.competencyTest}>
                <img className={styles.piMobile} src={piMobileImg} alt="" />
                <Outlet context={{ form, setForm, backwardPathname }} />
            </div>
        </BackgroundContainer>
    );
};
