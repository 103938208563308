import { LoaderFunctionArgs } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

import { getAccountCookie } from "../../../../services";

import { learningProgressSubcategoryQuery } from "../../utils";

export const studentProgressSubcategoryLoader =
    (queryClient: QueryClient) =>
    async ({ params }: LoaderFunctionArgs) => {
        const { accountType } = getAccountCookie();

        const childrenId = params.childrenId;
        const classType = params.classType;
        const subcategoryId = params.subcategoryId;

        if (
            accountType === "student" ||
            !childrenId ||
            !classType ||
            !subcategoryId
        ) {
            return undefined;
        }

        const query = learningProgressSubcategoryQuery(
            "other",
            classType,
            subcategoryId,
            childrenId
        );

        return (
            queryClient.getQueryData(query.queryKey) ??
            queryClient.fetchQuery(query)
        );
    };
