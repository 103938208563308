const colors = {
    default: "#FFFFFF",
    primary: "#2DB3BE",
    success: "#15B745",
    error: "#FE5A5E",
};

const radioVariants = {
    inactive: {
        borderColor: colors.default,
    },
    active: {
        borderColor: colors.primary,
    },
    success: {
        borderColor: colors.success,
    },
    error: {
        borderColor: colors.error,
    },
};

const buttonVariants = {
    inactive: {
        color: colors.primary,
    },
    active: {
        color: colors.default,
    },
};

const highlightVariants = {
    inactive: {
        background: colors.primary,
        opacity: 0,
    },
    active: {
        background: colors.primary,
        opacity: 1,
    },
    success: {
        background: colors.success,
        opacity: 1,
    },
    error: {
        background: colors.error,
        opacity: 1,
    },
};

const transition = {
    type: "tween",
    duration: 0.3,
};

export { radioVariants, buttonVariants, highlightVariants, transition };
