import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useNavigation } from "react-router-dom";

import { useExamTasks } from "../../hooks";
import { useGetRandomTask } from "../../../tasks/hooks";

import { ButtonWithIcon } from "../../../../components/buttons";
import { DashboardHeading } from "../../../../components/headings";
import { SelectInput } from "../../../../components/inputs";
import { NavigationBar } from "../../../tasks/components";
import { ExamTask } from "../../components";
import { ButtonLoader } from "../../../../components/utils";

import { createGridRepeat } from "../../../../utils";

import { Exam } from "../../typings";

import styles from "./ExamTasks.module.css";

import robotImg from "../../../../assets/images/robots/infinity-student.svg";

export const ExamTasks = () => {
    const { state } = useNavigation();

    const examTasksQuery = useExamTasks();

    const [isRandomTaskLoading, setIsRandomTaskLoading] = useState(false);
    const { mutate: getRandomTask } = useGetRandomTask();

    const [select, setSelect] = useState<string>();

    const options = Array.from(
        new Set(
            examTasksQuery.data?.data.exams.map((exam) => String(exam.year))
        )
    );

    const examFilter = (exam: Exam) => {
        if (select) {
            if (select === "WSZYSTKIE") {
                return true;
            }

            if (select === String(exam.year)) {
                return true;
            }

            return false;
        }
        return true;
    };

    return (
        <>
        <div className={styles.examTasks}>
            <img className={styles.infinity} src={robotImg} alt="" />

            <DashboardHeading title="Zadania egzaminacyjne" hasBorder />
            <div className={styles.heading}>
                <img src={robotImg} alt="" />
                <div className={styles.title}>arkusze z ubiegłych lat</div>
            </div>
            <div className={styles.titleDesktop}>arkusze z ubiegłych lat</div>
            <div className={styles.main}>
                <div className={styles.controls}>
                    <SelectInput
                        className={styles.select}
                        label="ROK"
                        selectValues={["WSZYSTKIE", ...options]}
                        value={select}
                        onChange={(value) => setSelect(value)}
                    />
                    <ButtonWithIcon
                        className={styles.button}
                        icon="draw"
                        onClick={() => {
                            setIsRandomTaskLoading(true);

                            getRandomTask(undefined, {
                                onError: () => setIsRandomTaskLoading(false),
                            });
                        }}
                    >
                        <ButtonLoader
                            condition={isRandomTaskLoading}
                            color="white"
                        >
                            Losuj Zadanie
                        </ButtonLoader>
                    </ButtonWithIcon>
                </div>
                {examTasksQuery.data && (
                    <div
                        style={{
                            gridTemplateRows: createGridRepeat(
                                examTasksQuery.data.data.exams.length
                            ),
                        }}
                        className={styles.exams}
                    >
                        <AnimatePresence mode="popLayout" initial={false}>
                            {examTasksQuery.data.data.exams
                                .filter(examFilter)
                                .map((exam) => (
                                    <motion.div
                                        key={exam.id}
                                        layout
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{
                                            type: "tween",
                                            duration: 0.3,
                                        }}
                                    >
                                        <ExamTask
                                            to={exam.id.toString()}
                                            isLoading={state === "loading"}
                                            tasks_done={exam.tasks_done}
                                            tasks_all={exam.tasks_all}
                                        >
                                            {exam.name}
                                        </ExamTask>
                                    </motion.div>
                                ))}
                        </AnimatePresence>
                    </div>
                )}
            </div>
            <NavigationBar
                backward={{
                    children: "WRÓĆ",
                }}
            />
        </div>
        {
            isRandomTaskLoading && <div className={styles.loadingMask}></div>
        }
        </>
        
    );
};
