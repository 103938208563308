import styles from "./TaskNote.module.css";

type Props = {
    children: JSX.Element;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const TaskNote = ({ children, setIsOpen }: Props) => {
    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.title}>NOTES</div>
                <button
                    className={styles.close}
                    type="button"
                    onClick={() => setIsOpen(false)}
                ></button>
                <div className={styles.body}>{children}</div>
            </div>
        </div>
    );
};
