import clsx from "clsx";
import { motion } from "framer-motion";
import { useState } from "react";
import { Link, useNavigation } from "react-router-dom";

import { DottedLoader } from "../../../../components/loaders";
import { Answer as AnswerType, AnswerState } from "../../typings";

import styles from "./Answer.module.css";

type Props = { data: AnswerType; state: AnswerState };
export const Answer = ({ data, state }: Props) => {
    const navigation = useNavigation();
    const [isClicked, setIsClicked] = useState(false);

    return (
        <motion.div
            key={data.id}
            layout
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >
            <Link
                onClick={() => setIsClicked(true)}
                to={`/dashboard/student/answer-on-demand/${data.id}`}
                className={styles.answerLink}
            >
                {navigation.state === "loading" && isClicked ? (
                    <div className={styles.loader}>
                        <DottedLoader />
                    </div>
                ) : (
                    <>
                        <div
                            className={clsx(
                                styles.answerTitle,
                                data.read && styles.read
                            )}
                        >
                            {state === "sent" ? "Do rozwiązania" : "Rozwiązane"}
                        </div>
                        <div>{data.date}</div>
                    </>
                )}
            </Link>
        </motion.div>
    );
};
