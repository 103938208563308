import { RedirectLinksProps } from "./RedirectLinks.typings";

import { Link } from "react-router-dom";
import styles from "./RedirectLinks.module.css";

export const RedirectLinks = ({ links }: RedirectLinksProps) => {
    return (
        <div className={styles.container}>
            {links.map((element) => (
                <div key={element.name} className={styles.item}>
                    <Link className={styles.link} to={element.href}>
                        {element.name}
                    </Link>
                </div>
            ))}
        </div>
    );
};
