const collapseVariants = {
    closed: {
        height: 35,
    },
    open: {
        height: 45,
    },
};

const colors = {
    white: "#FFFFFF",
    gray: "#E5E7EA",
    darkGray: "#879195",
    success: "#2EAFBE",
    error: "#FE5A5E",
};

const labelStaticVariant = {
    y: 14,
    fontSize: "16px",
    lineHeight: "18px",
};

const labelActiveVariant = {
    y: -17,
    fontSize: "13px",
    lineHeight: "15px",
};

const labelVariants = {
    static: {
        ...labelStaticVariant,
        color: colors.darkGray,
    },
    active: { ...labelActiveVariant },
    error: { ...labelActiveVariant, color: colors.error },
    success: { ...labelActiveVariant, color: colors.success },
    disabled: { ...labelActiveVariant, color: colors.darkGray },
    submitted: { ...labelActiveVariant, color: colors.darkGray, opacity: 0 },
};

const inputVariants = {
    static: { backgroundColor: colors.white, borderColor: colors.white },
    error: { backgroundColor: colors.white, borderColor: colors.error },
    success: { backgroundColor: colors.white, borderColor: colors.success },
    disabled: { backgroundColor: colors.gray, borderColor: colors.gray },
    submitted: {
        backgroundColor: colors.white,
        borderColor: colors.white,
        color: colors.white,
    },
};

const buttonVariants = {
    success: { background: colors.success },
    error: { background: colors.error },
};

const iconVariants = {
    success: {
        rotate: 0,
    },
    error: {
        rotate: 45,
    },
};

export {
    collapseVariants,
    labelVariants,
    inputVariants,
    buttonVariants,
    iconVariants,
};
