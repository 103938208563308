import { OrderRequestResponse } from "../../hooks/queries/useOrderRequest";
import { StoreDataType } from "../../typings";

export const getLoadedOrderRequest = (
    orderRequest: OrderRequestResponse["order_request"] | undefined
): StoreDataType => {
    if (orderRequest && orderRequest.id !== "") {
        return {
            primarySchool:
                orderRequest.sub_8_kl_id !== ""
                    ? {
                          selected: true,
                          values: {
                              label: orderRequest.sub_8_kl_id.label,
                              value: {
                                  id: orderRequest.sub_8_kl_id.id,
                                  price: orderRequest.sub_8_kl_id.price,
                              },
                          },
                      }
                    : {
                          selected: false,
                          values: undefined,
                      },
            highSchool:
                orderRequest.sub_matura_podstawowa_id !== ""
                    ? {
                          selected: "basic",
                          values: {
                              label: orderRequest.sub_matura_podstawowa_id
                                  .label,
                              value: {
                                  id: orderRequest.sub_matura_podstawowa_id.id,
                                  price: orderRequest.sub_matura_podstawowa_id
                                      .price,
                              },
                          },
                      }
                    : orderRequest.sub_matura_rozszerzona_id !== ""
                    ? {
                          selected: "extended",
                          values: {
                              label: orderRequest.sub_matura_rozszerzona_id
                                  .label,
                              value: {
                                  id: orderRequest.sub_matura_rozszerzona_id.id,
                                  price: orderRequest.sub_matura_rozszerzona_id
                                      .price,
                              },
                          },
                      }
                    : {
                          selected: undefined,
                          values: undefined,
                      },
            solutionOnDemand:
                orderRequest.sub_rozw_na_zawolanie_id !== ""
                    ? {
                          selected: true,
                          values: {
                              label: orderRequest.sub_rozw_na_zawolanie_id
                                  .label,
                              value: {
                                  id: orderRequest.sub_rozw_na_zawolanie_id.id,
                                  price: orderRequest.sub_rozw_na_zawolanie_id
                                      .price,
                              },
                          },
                      }
                    : {
                          selected: false,
                          values: undefined,
                      },
        };
    } else {
        return {
            primarySchool: {
                selected: false,
                values: undefined,
            },
            highSchool: {
                selected: undefined,
                values: undefined,
            },
            solutionOnDemand: {
                selected: false,
                values: undefined,
            },
        };
    }
};
