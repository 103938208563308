import { colors } from "../../utils";

import { ReactComponent as Arrows } from "../../../../assets/images/dashboard/competency-test/arrows.svg";
import { ReactComponent as Checkmark } from "../../../../assets/images/dashboard/competency-test/checkmark.svg";
import { ReactComponent as Questionmark } from "../../../../assets/images/dashboard/competency-test/questionmark.svg";
import { ReactComponent as Target } from "../../../../assets/images/dashboard/competency-test/target.svg";
import styles from "./StepperWithTitle.module.css";

type Values<O> = O[keyof O];

type Props = {
    steps: [
        Values<typeof colors>,
        Values<typeof colors>,
        Values<typeof colors>
    ];
};
export const StepperWithTitle = ({ steps }: Props) => {
    return (
        <div className={styles.stepperWithTitle}>
            <div className={styles.title}>
                Dzięki Twoim odpowiedziom nasza Sztuczna Inteligencja opracuje
                optymalny tok nauczania, żeby Twoja nauka była
                najskuteczniejsza.
            </div>
            <div className={styles.stepper}>
                <div className={styles.step}>
                    <Target stroke={steps[0]} />
                    <div style={{ color: steps[0] }}>Cel nauki</div>
                </div>
                <Arrows fill={steps[0]} />
                <div className={styles.step}>
                    <Questionmark fill={steps[1]} />
                    <div style={{ color: steps[1] }}>
                        Ocena
                        <br />z matematyki
                    </div>
                </div>
                <Arrows fill={steps[1]} />
                <div className={styles.step}>
                    <Checkmark fill={steps[2]} />
                    <div style={{ color: steps[2] }}>Podsumowanie</div>
                </div>
            </div>
        </div>
    );
};
