import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { mainApi } from "../../../../api";

type Response = {
    email: "string";
    status: number;
    type: "Student" | "Parent" | "Teacher";
};

export const useGetInvitationToken = (token: string) => {
    return useQuery<AxiosResponse<Response, any>>(
        [token],
        async () =>
            mainApi.post("get_invitation_token_credentials", {
                token,
            }),
        {
            staleTime: Infinity,
            refetchOnMount: false,
        }
    );
};
