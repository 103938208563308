import { Link, Navigate, Outlet, useOutlet } from "react-router-dom";

import { useGetShortDescription } from "../../hooks/useGetShortDescription";
import { useUserHasSubscription } from "../../hooks/useUserHasSubscrption";

import { DashboardHeading } from "../../../../components/headings";
import { NotificationSubscription } from "../../../dashboard/components";
import { NavigationBar } from "../../../tasks/components";
import { AnswerRequests } from "../../components";
import { useGetSchoolHasBegun } from "../../hooks/useGetSchoolHasBegun";

import plusImg from "../../../../assets/images/dashboard/answerOnDemand/plus-in-circle.svg";
import styles from "./AnswerOnDemand.module.css";

export const AnswerOnDemand = () => {
    const outlet = useOutlet();

    const userHasSubscription = useUserHasSubscription();
    const shortDescription = useGetShortDescription();
    const schoolHasBegun = useGetSchoolHasBegun();

    return <Navigate to="/" />;

    return outlet ? (
        <Outlet />
    ) : (
        <div className={styles.answerOnDemand}>
            <DashboardHeading
                title="Rozwiązanie na zawołanie"
                hasBorder
                hasSmallSpacing
                subtitle="wyślij nam swoje zadanie do rozwiązania"
            />
            <div className={styles.answerOnDemandSubscription}>
                {shortDescription}
            </div>
            {schoolHasBegun ? (
                userHasSubscription ? (
                    <div className={styles.addButton}>
                        <Link to="form">
                            <img src={plusImg} alt="Dodaj zadanie" />
                        </Link>
                        <div>DODAJ ZADANIE</div>
                    </div>
                ) : (
                    <div className={styles.alert}>
                        <NotificationSubscription>
                            Nie posiadasz wykupionego abonamentu{" "}
                            <strong>ROZWIĄZANIA NA ZAWOŁANIE</strong>, bądź
                            wszystkie prośby o rozwiązanie zadania zostały
                            wykorzystane.
                        </NotificationSubscription>
                    </div>
                )
            ) : (
                <div className={styles.alert}>
                    <NotificationSubscription isSecondLineDisabled={true}>
                        Rok szkolny jeszcze się nie rozpoczął, prośby o
                        rozwiązanie zadań zostały wyłączone.
                    </NotificationSubscription>
                </div>
            )}
            <AnswerRequests />
            <NavigationBar
                backward={{
                    children: "WRÓĆ",
                }}
            />
        </div>
    );
};
