export const getUserAdvancement = (string?: string) => {
    const isPercentage = string?.indexOf("%") !== -1;
    const data = string
        ? isPercentage
            ? string.replace("%", "")
            : string.split("/")
        : ["0", "0"];

    return {
        actual:
            isPercentage && !Array.isArray(data)
                ? parseInt(data)
                : parseInt(data[0]),
        total: isPercentage ? undefined : parseInt(data[1]),
        isPercentage,
    };
};
