import { useUserData } from "../../../dashboard/hooks";
import { useStudentGetToKnowData } from "../../hooks/queries";
import { ScheduleValue } from "../../typings";
import { calculatePercentage } from "./utils";

const weeksString = (weeks: number) => {
    if (weeks === 1) {
        return `${weeks} tydzień`;
    }
    if (weeks >= 2 && weeks <= 4) {
        return `${weeks} tygodnie`;
    }
    return `${weeks} tygodni`;
};

type Params = {
    picker: ScheduleValue;
    weeksLeft: number;
    subjectCount: number;
};
export const useGetLongDescription = (data: Params) => {
    const userDataQuery = useUserData();
    const studentGetToKnowDataQuery = useStudentGetToKnowData();

    const percentageValue = calculatePercentage(data);
    const weeks = Math.ceil(data.subjectCount / data.picker);

    if (!studentGetToKnowDataQuery.data || !userDataQuery.data) {
        return "";
    }

    switch (studentGetToKnowDataQuery.data.data.data.scope) {
        case "class": {
            if (data.picker === 1) {
                return `Cieszę się, że w Twoim harmonogramie znalazła się chwila na matematykę, cały materiał opanujesz w ${weeksString(
                    weeks
                )}`;
            }
            if (data.picker === 2) {
                return `Świetnie, że znalazłeś czas na naukę, w tym tempie opanujesz materiał w ${weeksString(
                    weeks
                )}`;
            }
            if (data.picker === 3) {
                return `Dobry plan, dzięki niemu nauczysz się całego materiału w ${weeksString(
                    weeks
                )}`;
            }
            if (data.picker === 4) {
                return `Super, dajesz z siebie wszystko żeby opanować materiał w ${weeksString(
                    weeks
                )}`;
            }
            if (data.picker === 5) {
                return `Taka ilość zadań to doskonały plan by opanować cały materiał w ${weeksString(
                    weeks
                )}`;
            }
            if (data.picker === 6) {
                return `Chcesz opanować więcej niż połowę materiału w ${weeksString(
                    weeks
                )}, rewelacja!`;
            }
            if (data.picker === 7) {
                return `Masz niezłą motywację do nauki, z tym planem opanujesz materiał w ${weeksString(
                    weeks
                )}`;
            }
            if (data.picker === 8) {
                return `Fantastycznie, widać, że zależy Ci na dobrym tempie, dzięki temu nauczysz się całego materiału w ${weeksString(
                    weeks
                )}`;
            }
            if (data.picker === 9) {
                return `Perfekcyjnie planujesz naukę, opanujesz materiał w ${weeksString(
                    weeks
                )}, fenomenalnie!`;
            }

            return `Lepiej być nie może, ten plan pozwoli Ci osiągnąć cel w ${weeksString(
                weeks
            )}, jestem pod wrażeniem!`;
        }

        case "full": {
            if (userDataQuery.data.data.data.school === "Podstawowa") {
                if (percentageValue >= 0 && percentageValue <= 10) {
                    return "Cieszę się, że w Twoim harmonogramie znalazła się chwila na przygotowania do E8 z matematyki";
                }
                if (percentageValue > 10 && percentageValue <= 20) {
                    return "Masz mało czasu, ale świetnie, że chcesz go poświęcić na naukę, dzięki temu masz szansę na podwyższenie wyniku";
                }
                if (percentageValue > 20 && percentageValue <= 30) {
                    return "Niezły plan, żeby zrobić postępy w przygotowaniu do E8 z matematyki";
                }
                if (percentageValue > 30 && percentageValue <= 40) {
                    return "Super, dajesz z siebie wszystko, żeby zdać egzamin jak najlepiej";
                }
                if (percentageValue > 40 && percentageValue <= 50) {
                    return "Taka ilość zagadnień to rewelacyjny plan by podnieść swój wynik na egzaminie";
                }
                if (percentageValue > 50 && percentageValue <= 60) {
                    return "Chcesz opanować więcej niż połowę materiału do egzaminu, to świetnie!";
                }
                if (percentageValue > 60 && percentageValue <= 70) {
                    return "Fantastycznie, widać, że naprawdę zależy Ci na dobrym wyniku E8";
                }
                if (percentageValue > 70 && percentageValue <= 80) {
                    return "Masz ponadprzeciętna motywację do nauki, wysoki wynik na egzaminie jest w Twoim zasięgu";
                }
                if (percentageValue > 80 && percentageValue <= 90) {
                    return "Doskonale planujesz naukę, masz szansę osiągnąć 90% na egzaminie";
                }
                return "Wszystko albo nic! Masz rację, jeśli się uczyć to na 100%, jestem pod wrażeniem!";
            }

            // other schools
            if (percentageValue >= 0 && percentageValue <= 10) {
                return "Cieszę się, że w Twoim harmonogramie znalazła się chwila na przygotowania do matury z matematyki";
            }
            if (percentageValue > 10 && percentageValue <= 20) {
                return "Masz mało czasu, ale świetnie, że chcesz go poświęcić na naukę, dzięki temu masz szansę na podwyższenie swojego wyniku";
            }
            if (percentageValue > 20 && percentageValue <= 30) {
                return "Niezły plan, żeby zrobić postępy w przygotowaniu do matury.";
            }
            if (percentageValue > 30 && percentageValue <= 40) {
                return "Super, dajesz z siebie wszystko, żeby zdać maturę jak najlepiej";
            }
            if (percentageValue > 40 && percentageValue <= 50) {
                return "Taka ilość zagadnień to rewelacyjny plan by podnieść swój wynik na maturze";
            }
            if (percentageValue > 50 && percentageValue <= 60) {
                return "Chcesz opanować więcej niż połowę materiału do matury, to świetnie!";
            }
            if (percentageValue > 60 && percentageValue <= 70) {
                return "Fantastycznie, widać, że naprawdę zależy Ci na dobrym wyniku na maturze!";
            }
            if (percentageValue > 70 && percentageValue <= 80) {
                return "Masz ponadprzeciętną motywację do nauki, wysoki wynik na maturze jest w Twoim zasięgu";
            }
            if (percentageValue > 80 && percentageValue <= 90) {
                return "Doskonale planujesz naukę, masz szansę osiągnąć wysoki wynik na maturze!";
            }
            return "Wszystko albo nic! Masz rację, jeśli się uczyć to na 100%, jestem pod wrażeniem!";
        }

        default:
            return "";
    }
};
