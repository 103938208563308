import { Navigate } from "react-router-dom";

import { useAuthContext } from "../../../contexts/AuthContext";

type Props = {
    children: JSX.Element | JSX.Element[];
    redirectWhenLoggedIn?: boolean;
    redirectPath?: string;
};

export const ProtectedRoute = ({
    children,
    redirectWhenLoggedIn = false,
    redirectPath = "/",
}: Props) => {
    const { isUserLogged } = useAuthContext();

    if (redirectWhenLoggedIn && isUserLogged) {
        return <Navigate to={redirectPath} replace={true} />;
    }

    if (!redirectWhenLoggedIn && !isUserLogged) {
        return <Navigate to={redirectPath} replace={true} />;
    }

    return Array.isArray(children) ? <>{children}</> : children;
};
