import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

import { useIsStudentProgressPage, useIsTaskPage } from "../../../hooks";

import styles from "./DashboardContainer.module.css";

type Props = {
    outsideElement?: JSX.Element;
    children?: JSX.Element | JSX.Element[] | string;
};

export const DashboardContainer = ({ outsideElement, children }: Props) => {
    const { pathname } = useLocation();

    const isTaskPage = useIsTaskPage();
    const isStudentProgressPage = useIsStudentProgressPage();

    const containerRef = useRef<HTMLDivElement>(null);
    const isTaskPageVisited = useRef(false);
    const isStudentProgressPageVisited = useRef(false);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: 0,
                behavior:
                    (isTaskPage && isTaskPageVisited.current) ||
                    (isStudentProgressPage &&
                        isStudentProgressPageVisited.current)
                        ? "smooth"
                        : "auto",
            });

            if (isTaskPage) {
                isTaskPageVisited.current = true;
            } else if (isTaskPageVisited.current) {
                isTaskPageVisited.current = false;
            }

            if (isStudentProgressPage) {
                isStudentProgressPageVisited.current = true;
            } else {
                isStudentProgressPageVisited.current = false;
            }
        }
    }, [pathname, isTaskPage, isStudentProgressPage]);

    return (
        <div
            id="dashboardContainer"
            className={clsx(styles.container, isTaskPage && styles.task)}
            ref={containerRef}
        >
            {outsideElement}
            <div className={styles.wrapper}>{children}</div>
        </div>
    );
};
