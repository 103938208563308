type Props = {
    isAlt?: boolean;
};

export const EnvelopeIcon = ({ isAlt }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={isAlt ? "12" : "29"}
        height={isAlt ? "10" : "25"}
        viewBox="0 0 29 25"
    >
        <path
            id="Icon_ionic-md-mail-open"
            data-name="Icon ionic-md-mail-open"
            d="M31.25,13.463A2.712,2.712,0,0,0,29.938,11.1L16.75,4.5,3.562,11.1A2.869,2.869,0,0,0,2.25,13.463v13.26A2.778,2.778,0,0,0,5.012,29.5H28.488a2.778,2.778,0,0,0,2.762-2.778Zm-14.5,6.662L5.657,12.833,16.75,7.278l11.093,5.556Z"
            transform="translate(-2.25 -4.5)"
            fill={isAlt ? "#2eafbe" : "#fff"}
        />
    </svg>
);
