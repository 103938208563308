import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

import { useMedia } from "../../../../hooks";

import { NavigationBubbleStats } from "../NavigationBubbleStats";
import { CircleLoader } from "../../../../components/loaders";

import { getIcon } from "./utils";

import { NavigationBubbleProps } from "./NavigationBubble.typings";

import styles from "./NavigationBubble.module.css";

import { ReactComponent as AvailableSoonBadge } from "./images/availableSoon-icon.svg";

export type { NavigationBubbleProps };

export const NavigationBubble = <T extends string | undefined>({
    type = "normal",
    children,
    to,
    state,
    icon,
    stats,
    isLoading,
    isFetching,
    isError,
    isAvailableSoon,
    isDisabled,
}: NavigationBubbleProps<T>) => {
    const { isDesktop } = useMedia();

    const [isClicked, setIsClicked] = useState(false);
    const [isCustomFetching, setIsCustomFetching] = useState(isFetching);

    useEffect(() => {
        if (!isFetching && isCustomFetching) {
            setTimeout(() => {
                setIsCustomFetching(false);
            }, 750);
        }
    }, [isFetching, isCustomFetching]);

    return (
        <Link
            className={clsx(
                styles.button,
                stats && styles.stats,
                isLoading && isClicked && styles.active,
                (isLoading || isAvailableSoon) && styles.disabled,
                isAvailableSoon && styles.gray,
                isDisabled && `${styles.disabled} ${styles.gray}`
            )}
            to={isAvailableSoon ? "/dashboard/student" : to}
            state={state}
            onClick={() => (isAvailableSoon ? undefined : setIsClicked(true))}
        >
            <span className={styles.icon}>
                {isLoading && isClicked ? (
                    <CircleLoader size={isDesktop ? undefined : "24"} />
                ) : (
                    getIcon(icon)
                )}
            </span>
            {type === "stats" && stats ? (
                <>
                    <AnimatePresence mode="wait" initial={false}>
                        {isCustomFetching && !isAvailableSoon && (
                            <motion.span
                                className={styles.fetching}
                                exit={{ opacity: 0 }}
                                transition={{ type: "tween", duration: 0.3 }}
                            >
                                <CircleLoader />
                            </motion.span>
                        )}
                    </AnimatePresence>
                    {isError && (
                        <div className={styles.error}>
                            Wystąpił błąd
                            <br />
                            podczas wczytywania danych
                        </div>
                    )}
                    <span className={styles.wrapper}>
                        <span className={styles.name}>{children}</span>
                        <NavigationBubbleStats
                            stats={stats}
                            isAvailableSoon={isAvailableSoon}
                        />
                    </span>
                </>
            ) : (
                children
            )}

            {isAvailableSoon && (
                <span className={styles.availableSoonBadge}>
                    <AvailableSoonBadge />
                </span>
            )}
        </Link>
    );
};
