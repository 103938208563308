export const variants = {
    default: {
        borderColor: "#eef0f3",
    },
    success: {
        borderColor: "#15B745",
    },
    error: {
        borderColor: "#FE5A5E",
    },
};