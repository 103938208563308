import Cookies from "js-cookie";

const setResetPasswordCookie = (token: string) =>
    Cookies.set("reset_password_token", token, {
        sameSite: "strict",
        expires: 1,
    });

const getResetPasswordCookie = () => Cookies.get("reset_password_token");

const removeResetPasswordCookie = () =>
    Cookies.remove("reset_password_token", { sameSite: "strict" });

export {
    setResetPasswordCookie,
    getResetPasswordCookie,
    removeResetPasswordCookie,
};
