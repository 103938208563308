import { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

import { ResultProvider } from "../../contexts/ResultContext";
import { AnswersProvider } from "../../contexts/AnswersContext";

import { getTaskParams } from "../../services";

import { useIsTaskPage } from "../../../../hooks";
import { useRedirectToValidTask, useTask } from "../../hooks";

import { TaskBody } from "../../components";

import { taskSlug } from "../../utils";

import { TaskType } from "../../typings";
import { ClassType } from "../../../dashboard/typings";

export const Task = () => {
    const params = useParams();

    const taskType = params.taskType as TaskType;
    const taskId = params.taskId as string;

    const taskParams = getTaskParams();

    const isTaskExamPage = useIsTaskPage(taskSlug.exercise);
    const isTaskCompetencyTestPage = useIsTaskPage(taskSlug.competency_test);
    const isTaskAiPage = useIsTaskPage(taskSlug.ai);
    const isTaskLearningProgressPage = useIsTaskPage(
        taskSlug.learning_progress
    );

    const { data } = useTask({
        taskId,
        examId:
            isTaskExamPage &&
            taskParams &&
            taskParams.type === taskSlug.exercise
                ? taskParams.additionalId
                : undefined,
    });

    const [isBrowserChanged, setIsBrowserChanged] = useState(false);
    useRedirectToValidTask(
        {
            type: taskType,
            id: taskId,
        },
        taskParams
            ? {
                  type: taskParams.type,
                  id: taskParams.taskId,
              }
            : undefined,
        isBrowserChanged
    );

    useEffect(() => {
        const getDocumentHidden = () => {
            const isHidden = document.hidden;

            if (isHidden) {
                setIsBrowserChanged(true);
            }
        };

        const getChangeBrowser = () => {
            setIsBrowserChanged(true);
        };

        document.addEventListener("visibilitychange", getDocumentHidden);
        window.addEventListener("blur", getChangeBrowser);
        window.addEventListener("focus", getChangeBrowser);

        return () => {
            setTimeout(() => {
                document.removeEventListener(
                    "visibilitychange",
                    getDocumentHidden
                );
                window.removeEventListener("blur", getChangeBrowser);
                window.removeEventListener("focus", getChangeBrowser);
            }, 0);
        };
    }, [taskParams]);

    useEffect(() => {
        if (isBrowserChanged) {
            setTimeout(() => {
                setIsBrowserChanged(false);
            }, 250);
        }
    }, [isBrowserChanged]);

    if (!taskParams) {
        if (taskType === taskSlug.default) {
            return <Navigate to="/dashboard/student/tasks" replace={true} />;
        } else if (taskType === taskSlug.exercise) {
            return (
                <Navigate to="/dashboard/student/exam-tasks" replace={true} />
            );
        } else if (taskType === taskSlug.learning_progress) {
            return (
                <Navigate
                    to="/dashboard/student/learning-progress"
                    replace={true}
                />
            );
        } else {
            return <Navigate to="/dashboard/student" replace={true} />;
        }
    }

    return (
        <ResultProvider>
            <AnswersProvider answers={data ? data.data.exercise.answers : []}>
                {data && (
                    <TaskBody
                        id={data.data.exercise.id}
                        type={taskType}
                        exam={
                            isTaskExamPage &&
                            taskParams &&
                            taskParams.type !== taskSlug.default &&
                            taskParams.type !== taskSlug.random
                                ? {
                                      id: taskParams.additionalId,
                                      taskId: taskParams.taskId,
                                  }
                                : undefined
                        }
                        test={
                            isTaskCompetencyTestPage &&
                            taskParams &&
                            taskParams.type !== taskSlug.default &&
                            taskParams.type !== taskSlug.random
                                ? {
                                      id: taskParams.additionalId,
                                      taskId: taskParams.taskId,
                                  }
                                : undefined
                        }
                        ai={
                            isTaskAiPage &&
                            taskParams &&
                            taskParams.type !== taskSlug.default &&
                            taskParams.type !== taskSlug.random
                                ? {
                                      id: taskParams.additionalId,
                                      taskId: taskParams.taskId,
                                  }
                                : undefined
                        }
                        learningProgress={
                            isTaskLearningProgressPage &&
                            taskParams &&
                            taskParams.type !== taskSlug.default &&
                            taskParams.type !== taskSlug.random
                                ? {
                                      id: {
                                          category:
                                              taskParams.additionalId.split(
                                                  "/"
                                              )[0],
                                          subcategory:
                                              taskParams.additionalId.split(
                                                  "/"
                                              )[1],
                                      },
                                      classType:
                                          taskParams.additionalId.split("/")[2] as ClassType,
                                      taskId: taskParams.taskId,
                                  }
                                : undefined
                        }
                        content={data.data.exercise.content}
                        answers={data.data.exercise.answers}
                        hint={data.data.exercise.hint}
                        sheets={data.data.exercise.exams}
                        difficulty={data.data.exercise.difficulty}
                        count={{
                            current: data.data.exercise.exam_index_current,
                            max: data.data.exercise.exam_index_max,
                        }}
                        icon={data.data.exercise.image}
                        category={data.data.exercise.categories[0]}
                        subcategory={
                            data.data.exercise.categories[0].subcategories[0]
                        }
                        hasTheory={data.data.exercise.has_theory}
                        isExamFilter={
                            data.data.exercise.exercise_type === "Egzaminacyjne"
                        }
                    />
                )}
            </AnswersProvider>
        </ResultProvider>
    );
};
