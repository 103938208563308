import { useState, useEffect } from "react";
import {
    Navigate,
    useLocation,
    useNavigate,
    useRouteError,
} from "react-router-dom";
import { motion } from "framer-motion";

import { getAccountCookie } from "../../../services";

import { useAuthContext } from "../../../contexts/AuthContext";
import { useLoadingContext } from "../../../contexts/LoadingContext";

import { useIsHomeDashboard, useMedia } from "../../../hooks";

import { AuthContainer } from "../../containers";
import { MainLogo } from "../../logos";
import { MainHeading } from "../../headings";
import { AuthText } from "../../texts";
import { DefaultButton } from "../../buttons";
import { ErrorModal } from "../../modals";

import styles from "./ErrorBoundary.module.css";

import iconDebug from "../../../assets/images/dashboard/icon-debug.svg";
import pi from "../../../assets/images/robots/pi-error.svg";

export const ErrorBoundary = () => {
    const { accountType } = getAccountCookie();
    const { isDesktop } = useMedia();

    const error = useRouteError();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { setLoadingActive } = useLoadingContext();
    const { logout } = useAuthContext();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [refreshCount, setRefreshCount] = useState(0);

    const isHome = useIsHomeDashboard();
    const isCodeError =
        error instanceof ReferenceError ||
        error instanceof TypeError ||
        error instanceof SyntaxError ||
        error instanceof EvalError;

    useEffect(() => {
        setLoadingActive(false);
    }, [setLoadingActive]);

    if (error && pathname.indexOf(`/tasks`) > 0) {
        return <Navigate to="/dashboard/student" replace={true} />;
    }

    return (
        <>
            <AuthContainer
                position="center"
                image={pi}
                imageSize={isDesktop ? 285 : 214}
                imagePosition="right"
                imageOffsetX={isDesktop ? 78 : undefined}
                imageOffsetY={isDesktop ? 15.5556 : undefined}
            >
                <MainLogo isCenter={true} />
                <MainHeading wrapper={styles.heading}>
                    WYSTĄPIŁ BŁĄD
                </MainHeading>
                <AuthText>
                    <p>
                        Przepraszamy, ale coś poszło nie tak.
                        <br />
                        Spróbuj ponownie.
                    </p>
                </AuthText>
                <div className={styles.buttonGroup}>
                    <div className={styles.groupMain}>
                        <DefaultButton
                            wrapper={styles.reloadButton}
                            whileHover={{ opacity: 0.7 }}
                            onClick={
                                isCodeError
                                    ? isHome
                                        ? logout
                                        : () =>
                                              navigate(
                                                  `/dashboard/${accountType}`,
                                                  {
                                                      replace: true,
                                                  }
                                              )
                                    : refreshCount < 5
                                    ? () => {
                                          setRefreshCount(
                                              (prevCount) => prevCount + 1
                                          );

                                          navigate(pathname, {
                                              replace: true,
                                          });
                                      }
                                    : logout
                            }
                        >
                            {isCodeError
                                ? isHome
                                    ? "WYLOGUJ"
                                    : "POWRÓT"
                                : refreshCount >= 5
                                ? "WYLOGUJ"
                                : "ODŚWIEŻ"}
                        </DefaultButton>
                    </div>
                    <div className={styles.groupAside}>
                        <motion.button
                            whileHover={{ opacity: 0.7 }}
                            className={styles.debugButton}
                            type="button"
                            onClick={() => setIsModalOpen(true)}
                        >
                            <img src={iconDebug} alt="" />
                        </motion.button>
                    </div>
                </div>
            </AuthContainer>
            <ErrorModal
                error={error}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            />
        </>
    );
};
