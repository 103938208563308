import { mainApi } from "../../../../api";

const key = "taskCategory";

export const taskCategoryQuery = (categoryId?: string, userClass?: string) => ({
    queryKey: categoryId ? [key, categoryId] : [key],
    queryFn: async () =>
        mainApi.post(`get_exercise_section_data/${categoryId}`, {
            user_class: userClass,
        }),
});
