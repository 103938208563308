import { AxiosResponse } from "axios";
import { mainApi } from "../../../api";
import { SolutionOnDemand } from "../typings";

const time = 1 * 60 * 1000;

export const getSolutionOnDemandListQuery = () => ({
    queryKey: ["getSolutionOnDemandList"],
    queryFn: async () => mainApi.get("get_solution_on_demand_list"),
    staleTime: time,
});

export const getSolutionOnDemandQuery = (
    id: string,
    onSuccess?: (data: AxiosResponse<SolutionOnDemand>) => void
) => ({
    queryKey: ["getSolutionOnDemand", id],
    queryFn: async () => mainApi.get(`get_solution_on_demand/${id}`),
    staleTime: 0,
    onSuccess,
});
