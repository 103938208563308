import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

import { useOptimisticRelations } from "../optimistic-updates";

import { resolveTimeout } from "../../../../utils";

import { ListMutation } from "../../typings";

type RelationType = "teacher" | "parent";

const getMutationUrl = (action: ListMutation["action"], type: RelationType) => {
    switch (action) {
        case "accept":
            return `accept_${type}_invitation_by_child`;
        case "decline":
            return `decline_${type}_invite_by_student`;
        case "remove":
            return `remove_${type}_by_child`;
        case "invite":
            return `invite_${type}_by_child`;
    }
};

export const useRelationByChildren = (
    queryKey: (string | number)[],
    type: RelationType
) => {
    const queryClient = useQueryClient();
    const previousQueryData = queryClient.getQueryData(queryKey);

    const { updateOptimisticRelations, revertOptimisticRelations } =
        useOptimisticRelations(queryKey);

    return useMutation<AxiosResponse<any, any>, any, ListMutation, unknown>(
        async (data) => {
            const isAccept = data.action === "accept";
            const isRemove =
                data.action === "decline" || data.action === "remove";

            if (isAccept || isRemove) {
                updateOptimisticRelations(
                    data.email as string,
                    "students",
                    isRemove
                );

                await resolveTimeout(500);
            }

            return mainApi.post(getMutationUrl(data.action, type), {
                email: data.email,
            });
        },
        {
            onSuccess: () => queryClient.invalidateQueries(queryKey),
            onError: () => revertOptimisticRelations(previousQueryData),
        }
    );
};
