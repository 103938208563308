import { useState, useEffect } from "react";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import { useResetCompetencyTest } from "../../hooks/mutations";
import { useAiSession } from "../../../tasks/hooks";
import { useAddNotify } from "../../../dashboard/hooks/mutations/useAddNotify";

import { DashboardHeading } from "../../../../components/headings";
import { DefaultButton } from "../../../../components/buttons";
import { DottedLoader } from "../../../../components/loaders";
import { ButtonLoader } from "../../../../components/utils";

import { getPreviousPath } from "../../../../utils";

import styles from "./Sandbox.module.css";

export const Sandbox = () => {
    const navigate = useNavigate();
    const { state } = useNavigation();
    const { pathname } = useLocation();

    const { mutate, isLoading, isSuccess, isError } = useResetCompetencyTest();
    const {
        mutate: setAiSession,
        isLoading: isAiSessionLoading,
        isSuccess: isAiSessionSuccess,
        isError: isAiSessionError,
    } = useAiSession();
    const { mutate: addNotify } = useAddNotify();

    const [clickedButton, setClickedButton] = useState("");
    const isLoadingState = state === "loading";

    const [isNotificationLoading, setIsNotificationLoading] = useState(false);
    const [NoN, setNoN] = useState<string | number>("");
    const [error, setError] = useState(false);
    const [jp, setJp] = useState(false);
    const [url, setUrl] = useState("");

    const handleAddNotify = () => {
        if (typeof NoN === "number") {
            if (NoN === 2137) {
                setJp(true);
            }

            if (NoN - 1 > 30 && NoN !== 2137) {
                setError(true);
            } else {
                setIsNotificationLoading(true);

                for (let i = 0; i < (NoN > 30 ? 1 : NoN); i++) {
                    addNotify(url);
                }
            }
        }
    };

    useEffect(() => {
        if (
            (clickedButton === "aiSession" && isAiSessionSuccess) ||
            (clickedButton === "aiSession" && isAiSessionError)
        ) {
            setClickedButton("");
        }
    }, [clickedButton, isAiSessionSuccess, isAiSessionError]);

    useEffect(() => {
        if (isNotificationLoading) {
            setTimeout(() => {
                setIsNotificationLoading(false);
            }, 2500);
        }
    }, [isNotificationLoading]);

    useEffect(() => {
        if (jp) {
            setTimeout(() => {
                setJp(false);
            }, 2800);
        }
    }, [jp]);

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(false);
            }, 1200);
        }
    }, [error]);

    return (
        <>
            <DashboardHeading title="Sandbox" hasBorder hasSmallSpacing />
            <div className={styles.grid}>
                <div className={styles.column}>
                    <p className={styles.title}>RESET TESTU KOMPETENCJI</p>
                    <DefaultButton isDisabled={isLoadingState} onClick={mutate}>
                        RESET
                    </DefaultButton>
                    {(isLoading || isSuccess || isError) && (
                        <div style={{ marginTop: 15 }}>
                            {isLoading && <DottedLoader />}
                            {isSuccess && <>&#x2713;</>}
                            {isError && <>&#x274C;</>}
                        </div>
                    )}
                </div>
                <div className={styles.column}>
                    <p className={styles.title}>PRZEJDŹ DO TESTU KOMPETENCJI</p>
                    <DefaultButton
                        onClick={() => {
                            setClickedButton("competencyTest");

                            navigate("/dashboard/student/competency-test");
                        }}
                    >
                        {isLoadingState &&
                        clickedButton === "competencyTest" ? (
                            <DottedLoader color="white" />
                        ) : (
                            "PRZEJDŹ"
                        )}
                    </DefaultButton>
                </div>
                <div className={styles.column}>
                    <p className={styles.title}>URUCHOM SESJE AI</p>
                    <DefaultButton
                        isDisabled={isAiSessionLoading}
                        onClick={() => {
                            setClickedButton("aiSession");

                            setAiSession();
                        }}
                    >
                        {isAiSessionLoading && clickedButton === "aiSession" ? (
                            <DottedLoader color="white" />
                        ) : (
                            "URUCHOM"
                        )}
                    </DefaultButton>
                </div>
                <div className={styles.column}>
                    <p className={styles.title}>
                        PODGLĄD RESTRYKCJI DARMOWEJ WERSJI
                    </p>
                    <DefaultButton
                        isDisabled={isAiSessionLoading}
                        onClick={() =>
                            navigate(`${getPreviousPath(pathname)}/restriction`)
                        }
                    >
                        PRZEJDŹ
                    </DefaultButton>
                </div>
                <div className={styles.column}>
                    <p className={styles.title}>PRZEJDŹ DO POSTĘPÓW NAUKI</p>
                    <DefaultButton
                        onClick={() =>
                            navigate(
                                `${getPreviousPath(pathname)}/learning-progress`
                            )
                        }
                    >
                        PRZEJDŹ
                    </DefaultButton>
                </div>
                <div className={styles.column}>
                    <p className={styles.title}>DODAJ POWIADOMIENIA</p>
                    <input
                        type="text"
                        placeholder="Ilość powiadomień"
                        value={NoN}
                        style={{
                            display: "block",
                            width: "100%",
                            maxWidth: "300px",
                            border: "2px solid #2fafbe",
                            fontSize: "16px",
                            textAlign: "center",
                            lineHeight: "1",
                            borderRadius: "30px",
                            padding: "11px 15px 12px",
                            outline: "0",
                            margin: "0 auto 10px",
                            pointerEvents: isNotificationLoading
                                ? "none"
                                : "auto",
                        }}
                        disabled={isNotificationLoading}
                        onChange={(event) => {
                            const value = event.currentTarget.value;

                            setNoN(
                                !isNaN(parseInt(value)) ? parseInt(value) : ""
                            );
                        }}
                    />
                    <input
                        type="text"
                        placeholder="Url powiadomień"
                        value={url}
                        style={{
                            display: "block",
                            width: "100%",
                            maxWidth: "300px",
                            border: "2px solid #2fafbe",
                            fontSize: "16px",
                            textAlign: "center",
                            lineHeight: "1",
                            borderRadius: "30px",
                            padding: "11px 15px 12px",
                            outline: "0",
                            margin: "0 auto 10px",
                            pointerEvents: isNotificationLoading
                                ? "none"
                                : "auto",
                        }}
                        disabled={isNotificationLoading}
                        onChange={(event) => setUrl(event.currentTarget.value)}
                    />
                    <DefaultButton
                        isDisabled={isNotificationLoading}
                        onClick={() => handleAddNotify()}
                    >
                        <ButtonLoader
                            condition={isNotificationLoading}
                            color="white"
                        >
                            DODAJ
                        </ButtonLoader>
                    </DefaultButton>
                    <AnimatePresence mode="wait">
                        {jp && (
                            <motion.div
                                className={styles.pope}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ type: "tween", duration: 0.2 }}
                            >
                                <div
                                    className={styles.popeImg}
                                    style={{
                                        backgroundImage:
                                            "url(https://media.tenor.com/CzW0P5EOQwoAAAAM/jp2gmd-pope.gif)",
                                    }}
                                ></div>
                            </motion.div>
                        )}
                        {error && (
                            <motion.div
                                className={styles.error}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ type: "tween", duration: 0.2 }}
                            >
                                NIE PRZESADZAJ
                                <br />Z POWIADOMIENIAMI
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
        </>
    );
};
