import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect } from "react";
import { Portal } from "react-portal";

import { DefaultButton } from "../../buttons";
import { CircleLoader } from "../../loaders";

import { DefaultModalProps } from "./DefaultModal.typings";

import styles from "./DefaultModal.module.css";

export const DefaultModal = ({
    title,
    titleColor = "error",
    borderColor = "error",
    children,
    actions,
    hasSmallSpacing,
    isOpen,
    setIsOpen,
    isLoading,
    dialogClassname,
}: DefaultModalProps) => {
    const handleEsc = useCallback(
        (event: KeyboardEvent) => {
            if (isOpen && event.key === "Escape" && !isLoading) {
                setIsOpen(false);
            }
        },
        [isOpen, setIsOpen, isLoading]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleEsc);

        return () => {
            document.removeEventListener("keydown", handleEsc);
        };
    }, [handleEsc]);

    return (
        <Portal node={document && document.getElementById("modal-root")}>
            <AnimatePresence mode="wait">
                {isOpen && (
                    <motion.div
                        className={clsx(
                            styles.modal,
                            hasSmallSpacing && styles.smallSpacing
                        )}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ type: "tween", duration: 0.2 }}
                    >
                        <div className={styles.wrapper}>
                            <motion.div
                                className={clsx(
                                    styles.dialog,
                                    borderColor === "primary" &&
                                        styles.colorPrimary,
                                    dialogClassname
                                )}
                                initial={{ y: -40 }}
                                animate={{ y: 0 }}
                                exit={{ y: 60 }}
                                transition={{ type: "tween", duration: 0.3 }}
                            >
                                <motion.button
                                    id="modalCloseButton"
                                    className={styles.close}
                                    type="button"
                                    disabled={isLoading}
                                    whileHover={{ opacity: 0.7 }}
                                    onClick={() => setIsOpen(false)}
                                ></motion.button>
                                <h3
                                    className={clsx(
                                        styles.title,
                                        titleColor === "primary" &&
                                            styles.colorPrimary
                                    )}
                                >
                                    {title}
                                </h3>
                                <div className={styles.body}>{children}</div>
                                <div className={styles.footer}>
                                    {actions.map((action, index) => (
                                        <DefaultButton
                                            key={index}
                                            className={clsx(
                                                styles.button,
                                                action.white && styles.white
                                            )}
                                            initial={{
                                                backgroundColor:
                                                    action.isDisabled
                                                        ? DefaultButton.color
                                                              .disabled
                                                        : action.isImportant
                                                        ? DefaultButton.color
                                                              .error
                                                        : action.white
                                                        ? DefaultButton.color
                                                              .white
                                                        : DefaultButton.color
                                                              .enabled,
                                                color: action.white
                                                    ? DefaultButton.color
                                                          .enabled
                                                    : DefaultButton.color.white,
                                            }}
                                            animate={{
                                                backgroundColor:
                                                    action.isDisabled
                                                        ? DefaultButton.color
                                                              .disabled
                                                        : action.isImportant
                                                        ? DefaultButton.color
                                                              .error
                                                        : action.white
                                                        ? DefaultButton.color
                                                              .white
                                                        : DefaultButton.color
                                                              .enabled,
                                                color: action.white
                                                    ? DefaultButton.color
                                                          .enabled
                                                    : DefaultButton.color.white,
                                            }}
                                            whileHover={{
                                                opacity: action.isDisabled
                                                    ? 1
                                                    : 0.7,
                                            }}
                                            transition={{
                                                type: "tween",
                                                duration: 0.3,
                                            }}
                                            isDisabled={
                                                action.isDisabled || isLoading
                                            }
                                            onClick={action.onClick}
                                        >
                                            {action.name}
                                        </DefaultButton>
                                    ))}
                                </div>
                                <AnimatePresence mode="wait">
                                    {isLoading && (
                                        <motion.div
                                            className={styles.loading}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{
                                                type: "tween",
                                                duration: 0.2,
                                            }}
                                        >
                                            <CircleLoader />
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </motion.div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </Portal>
    );
};
