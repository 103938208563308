import { mainApi } from "../../../../api";

import { LearningProgressAccountType } from "../../typings";

const key = "learningProgress";
const url = "user_learning_progress";

export const learningProgressQuery = (
    type: LearningProgressAccountType,
    childrenId?: string
) => ({
    queryKey:
        type === "student" ? [key] : childrenId ? [key, childrenId] : [key],
    queryFn: async () =>
        mainApi.get(type === "student" ? url : `${url}/${childrenId}`),
});
