const type = "tween";

const modalAnimations = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { type, duration: 0.2 },
};

const dialogAnimations = {
    initial: { scale: 0.8 },
    animate: { scale: 1 },
    exit: { scale: 0.8 },
    transition: { type, duration: 0.3 },
};

export { modalAnimations, dialogAnimations };
