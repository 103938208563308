import { QueryClient } from "@tanstack/react-query";

import { getAccountCookie } from "../../../../services";

import { learningProgressQuery } from "../../utils";

export const learningProgressLoader =
    (queryClient: QueryClient) => async () => {
        const { accountType } = getAccountCookie();

        if (accountType !== "student") {
            return undefined;
        }

        const query = learningProgressQuery('student');

        return (
            queryClient.getQueryData(query.queryKey) ??
            queryClient.fetchQuery(query)
        );
    };
