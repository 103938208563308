import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";

import { useMedia } from "../../../hooks";

import { ArrowButton } from "../../buttons";
import { MainHeading } from "../MainHeading";
import { ShieldIcon } from "./icons";

import { taskChangeAnimations } from "../../../modules/tasks/utils";
import { getPreviousPath } from "../../../utils";

import { DashboardHeadingProps } from "./DashboardHeading.typings";

import styles from "./DashboardHeading.module.css";

export const DashboardHeading = ({
    title,
    subtitle,
    icon,
    to,
    userClass,
    hasBorder,
    hasSmallSpacing,
    isAnimated,
    onGoBack,
}: DashboardHeadingProps) => {
    const { isDesktop } = useMedia();

    const { pathname } = useLocation();
    const { state } = useNavigation();
    const navigate = useNavigate();

    const isGoBackAccesible = typeof onGoBack === "function";
    const isLoadingState = state === "loading";

    const handleGoBack = () => {
        navigate(to ?? (getPreviousPath(pathname) || "/"));

        if (isGoBackAccesible) {
            onGoBack();
        }
    };

    return (
        <div
            className={clsx(
                styles.container,
                hasSmallSpacing && !hasBorder && styles.smallSpacing,
                hasSmallSpacing && hasBorder && styles.mediumSpacing,
                isDesktop && icon && styles.hasIcon,
                isDesktop && userClass && styles.hasShield
            )}
        >
            {!isDesktop && (
                <ArrowButton
                    wrapper={clsx(styles.arrow, icon && styles.center)}
                    to={
                        isGoBackAccesible
                            ? undefined
                            : to ?? getPreviousPath(pathname)
                    }
                    isDisabled={isLoadingState}
                    onClick={handleGoBack}
                />
            )}
            <MainHeading
                className={clsx(
                    styles.title,
                    hasBorder && !subtitle && !isDesktop && styles.border
                )}
                elementType="h3"
            >
                {isAnimated ? (
                    <AnimatePresence mode="wait" initial={false}>
                        <motion.span
                            key={title.replaceAll(" ", "_")}
                            style={{ display: "block" }}
                            {...taskChangeAnimations}
                        >
                            {icon && (
                                <span className={styles.icon}>
                                    <img src={icon} alt="" />
                                </span>
                            )}
                            {title}
                        </motion.span>
                    </AnimatePresence>
                ) : (
                    <>
                        {icon && (
                            <span className={styles.icon}>
                                <img src={icon} alt="" />
                            </span>
                        )}
                        {title}
                    </>
                )}
            </MainHeading>
            {subtitle && !isDesktop && (
                <MainHeading className={styles.subtitle} elementType="h4">
                    {subtitle}
                </MainHeading>
            )}
            {userClass && (
                <div className={clsx(styles.class, icon && styles.center)}>
                    {userClass}
                    <ShieldIcon />
                </div>
            )}
        </div>
    );
};
