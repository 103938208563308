import { useState } from "react";

import { useUnlinkCard } from "../../hooks";

import { DefaultModal } from "../../../../components/modals";

import styles from "./CardItem.module.css";

type Props = {
    id: string;
    digits: string;
};

export const CardItem = ({ id, digits }: Props) => {
    const { mutate } = useUnlinkCard();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMutating, setIsMutating] = useState(false);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.number}>{digits}</div>
                <button
                    className={styles.button}
                    type="button"
                    onClick={() => setIsModalOpen(true)}
                >
                    odepnij
                </button>
            </div>
            <DefaultModal
                title="ODEPNIJ KARTĘ"
                actions={[
                    {
                        name: "TAK, ODEPNIJ",
                        isImportant: true,
                        onClick: () => {
                            setIsMutating(true);

                            mutate({
                                cardId: id,
                            },
                            {
                                onSuccess: () => {
                                    setIsModalOpen(false);
                                },
                                onError: () => {
                                    setTimeout(()=> {
                                        setIsMutating(false);
                                    }, 600);
                                    
                                }
                            });
                        },
                    },
                    {
                        name: "NIE, WRÓĆ",
                        onClick: () => setIsModalOpen(false),
                    },
                ]}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                isLoading={isMutating}
            >
                <p>
                    Czy na pewno chcesz odpiąć
                    <br />
                    kartę od konta?
                </p>
                <p>
                    <strong>
                        Opłacane za jej pomocą abonamenty nie przedłużą się
                        automatycznie.
                    </strong>
                </p>
            </DefaultModal>
        </>
    );
};
