const colors = {
    white: "#FFFFFF",
    gray: "#E5E7EA",
    darkGray: "#879195",
    success: "#2EAFBE",
    error: "#FE5A5E",
};

const labelStaticVariant = {
    y: 14,
    fontSize: "16px",
    lineHeight: "18px",
};

const labelActiveVariant = {
    y: -17,
    fontSize: "13px",
    lineHeight: "15px",
};

const labelVariants = {
    static: {
        ...labelStaticVariant,
        color: colors.darkGray,
    },
    active: { ...labelActiveVariant },
    error: { ...labelActiveVariant, color: colors.error },
    error_static: { ...labelStaticVariant, color: colors.error },
    success: { ...labelActiveVariant, color: colors.success },
    disabled: { ...labelActiveVariant, color: colors.darkGray },
};

const inputVariants = {
    static: { backgroundColor: colors.white, borderColor: colors.white },
    error: { backgroundColor: colors.white, borderColor: colors.error },
    success: { backgroundColor: colors.white, borderColor: colors.success },
    disabled: { backgroundColor: colors.gray, borderColor: colors.gray },
};

export { labelVariants, inputVariants };
