import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

import { setTaskParams } from "../../services";

import { getPreviousPath } from "../../../../utils";
import { taskSlug } from "../../utils";

type Response = {
    exercise: {
        id: string;
    };
};

export const useGetNextRandomTask = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    return useMutation<AxiosResponse<Response, any>, any, void, unknown>(
        () => mainApi.get("get_random_exam_exercise"),
        {
            onSuccess: (data) => {
                const taskId = data.data.exercise.id;

                if (taskId) {
                    setTaskParams({
                        type: taskSlug.random,
                        taskId,
                    });

                    navigate(
                        `${getPreviousPath(pathname, 3)}/task/${
                            taskSlug.random
                        }/${taskId}`
                    );
                } else {
                    navigate(`/dashboard/student`);
                }
            },
        }
    );
};
