import { useNavigation } from "react-router-dom";

import { AccountSocialItem } from "../AccountSocialItem";

import styles from "./AccountSocial.module.css";

import iconFacebook from "./icons/icon-facebook.svg";
import iconInstagram from "./icons/icon-instagram.svg";
import iconYoutube from "./icons/icon-youtube.svg";
import iconTiktok from "./icons/icon-tiktok.svg";

const socialItems = [
    {
        key: "facebook",
        icon: iconFacebook,
        url: "https://www.facebook.com/smarttutorpl",
    },
    {
        key: "instagram",
        icon: iconInstagram,
        url: "https://www.instagram.com/smarttutorpl",
    },
    {
        key: "youtube",
        icon: iconYoutube,
        url: "https://www.youtube.com/@smarttutorpl",
    },
    {
        key: "tiktok",
        icon: iconTiktok,
        url: "https://www.tiktok.com/@smarttutorpl",
    },
];

export const AccountSocial = () => {
    const { state } = useNavigation();
    const isLoadingState = state === "loading";

    return (
        <div className={styles.container}>
            {socialItems.map((item) => {
                return (
                    <div className={styles.column} key={item.key}>
                        <AccountSocialItem
                            icon={item.icon}
                            url={item.url}
                            isDisabled={isLoadingState}
                        />
                    </div>
                );
            })}
        </div>
    );
};
