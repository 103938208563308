import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { userSavedCardsQuery } from "../../utils";

export type SavedCardsResponse = {
    id: string;
    card_vendor: string;
    card_last_digits: string;
    exp_date: string;
};

export const useUserSavedCards = () => {
    return useQuery<AxiosResponse<SavedCardsResponse[], any>>(
        userSavedCardsQuery()
    );
};
