import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import { useAnswersContext } from "../../../contexts/AnswersContext";
import { useResultContext } from "../../../contexts/ResultContext";

import { useCheckTaskAnswers } from "../../../hooks";

import { DefaultButton } from "../../../../../components/buttons";
import { DottedLoader } from "../../../../../components/loaders";
import { Popper } from "../../../../../components/utils";

import styles from "./AnswerSubmit.module.css";
import { TaskType } from "../../../typings";

type Props = {
    taskId: string;
    children: React.ReactNode;
    isLoading?: boolean;
};

const buttonVariants = {
    disabled: {
        background: DefaultButton.color.disabled,
    },
    enabled: {
        background: DefaultButton.color.enabled,
    },
};

export const AnswerSubmit = ({ taskId, children, isLoading }: Props) => {
    const params = useParams();
    const taskType = params.taskType as TaskType;

    const { getProvidedAnswers, isAnswersValid } = useAnswersContext();
    const { isResultsLoading, setIsResultsLoading } = useResultContext();

    const { mutate } = useCheckTaskAnswers(taskType);

    const [isSubmitInvalid, setIsSubmitInvalid] = useState(false);
    const [submitButtonRef, setSubmitButtonRef] = useState<Element | null>(
        null
    );

    const handleOnClick = () => {
        if (isAnswersValid) {
            setIsResultsLoading(true);
            
            mutate({
                id: taskId,
                answers: getProvidedAnswers({
                    parseMatch: true,
                }),
            });
        } else {
            setIsSubmitInvalid(true);
        }
    };

    useEffect(() => {
        if (isSubmitInvalid) {
            setTimeout(() => {
                setIsSubmitInvalid(false);
            }, 2000);
        }
    }, [isSubmitInvalid]);

    return (
        <div className={styles.container}>
            <DefaultButton
                ref={setSubmitButtonRef}
                className={styles.button}
                variants={buttonVariants}
                initial="disabled"
                animate={isAnswersValid ? "enabled" : "disabled"}
                transition={{
                    type: "tween",
                    duration: 0.3,
                }}
                isDisabled={isLoading || isResultsLoading}
                onClick={handleOnClick}
            >
                <AnimatePresence mode="wait" initial={false}>
                    <motion.div
                        key={`submitting_${isResultsLoading}`}
                        initial={{ y: -10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: 10, opacity: 0 }}
                        transition={{
                            type: "tween",
                            duration: 0.15,
                        }}
                    >
                        {isResultsLoading ? (
                            <DottedLoader color="white" />
                        ) : (
                            <>
                                <span className={styles.icon}>
                                    <Icon />
                                </span>
                                {children}
                            </>
                        )}
                    </motion.div>
                </AnimatePresence>
            </DefaultButton>
            <Popper
                referenceElement={submitButtonRef}
                placement="top"
                popStyle="error"
                isActive={isSubmitInvalid}
            >
                Udziel wszystkich możliwych odpowiedzi
            </Popper>
        </div>
    );
};

const Icon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="9"
        viewBox="0 0 12 9"
    >
        <path
            id="Icon_awesome-check"
            data-name="Icon awesome-check"
            d="M4.076,13.4.176,9.478a.606.606,0,0,1,0-.853l.849-.853a.6.6,0,0,1,.849,0L4.5,10.414l5.627-5.66a.6.6,0,0,1,.849,0l.849.853a.606.606,0,0,1,0,.853l-6.9,6.94A.6.6,0,0,1,4.076,13.4Z"
            transform="translate(0 -4.577)"
            fill="#FFFFFF"
        />
    </svg>
);
