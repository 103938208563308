import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import clsx from "clsx";

import { useAuthContext } from "../../../../contexts/AuthContext";
import { useRedirectContext } from "../../../../contexts/RedirectContext";

import { getAccountCookie } from "../../../../services";

import {
    useIsHomeDashboard,
    useIsTaskPage,
    useResetRedirect,
} from "../../../../hooks";

import { ArrowButton } from "../../../../components/buttons";
import { NavbarBrand } from "../NavbarBrand";
import { NavbarButton } from "../NavbarButton";
import { DefaultModal } from "../../../../components/modals";

import { getPreviousPath } from "../../../../utils";

import styles from "./NavbarDesktop.module.css";

type Props = {
    children?: JSX.Element;
};

export const NavbarDesktop = ({ children }: Props) => {
    const { accountType } = getAccountCookie();

    const { pathname } = useLocation();
    const params = useParams();

    const { redirect } = useRedirectContext();
    const { logout } = useAuthContext();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const isHome = useIsHomeDashboard();
    const isTheory = pathname.indexOf("theory") !== -1;
    const isTask = useIsTaskPage();
    const isStore = pathname.indexOf("store") !== -1;
    const isCompetencyTest =
        !isTask && pathname.indexOf("competency-test") !== -1;
    const isAiSessionResult = pathname.indexOf("ai-session") !== -1;
    const isLearningProgressCategories =
        pathname.indexOf(
            `learning-progress/${params.classType}/${params.categoryId}`
        ) !== -1;
    const isStudentProgress =
        pathname.indexOf(`student-progress/${params.childrenId}`) !== -1 &&
        pathname.indexOf(
            `student-progress/${params.childrenId}/${params.classType}/${params.categoryId}/${params.subcategoryId}`
        ) === -1;

    useResetRedirect([
        isTask,
        isStore,
        isCompetencyTest,
        isAiSessionResult,
        isLearningProgressCategories,
        isStudentProgress,
    ]);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <div className={clsx(styles.column, styles.left)}>
                        {!isHome && (
                            <ArrowButton
                                to={
                                    redirect ??
                                    getPreviousPath(
                                        pathname,
                                        isTheory || isTask ? 2 : 1
                                    )
                                }
                                isBorderless={true}
                            >
                                Wstecz
                            </ArrowButton>
                        )}
                    </div>
                    <div className={clsx(styles.column, styles.center)}>
                        <NavbarBrand
                            accountType={accountType}
                            isDisabled={isHome}
                        />
                    </div>
                    <div className={clsx(styles.column, styles.right)}>
                        <ul className={styles.menu}>
                            {children && (
                                <li className={styles.notifications}>
                                    {children}
                                </li>
                            )}
                            <li>
                                <NavbarButton
                                    icon="user"
                                    to="account"
                                    isReversed={true}
                                >
                                    MOJE KONTO
                                </NavbarButton>
                            </li>
                            <li>
                                <NavbarButton
                                    icon="logout"
                                    isReversed={true}
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    WYLOGUJ
                                </NavbarButton>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <DefaultModal
                title="WYLOGUJ"
                actions={[
                    {
                        name: "TAK, WYLOGUJ",
                        isImportant: true,
                        onClick: logout,
                    },
                    {
                        name: "NIE, WRÓĆ",
                        onClick: () => setIsModalOpen(false),
                    },
                ]}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            >
                <p>
                    Czy na pewno chcesz się
                    <br />
                    wylogować?
                </p>
            </DefaultModal>
        </>
    );
};
