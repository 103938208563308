type Props = {
    isSmall?: boolean;
    isSuccess?: boolean;
};

export const CheckmarkIcon = ({ isSmall, isSuccess }: Props) =>
    isSmall ? (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="9"
            viewBox="0 0 12 9"
        >
            <path
                id="Icon_awesome-check"
                data-name="Icon awesome-check"
                d="M4.076,13.4.176,9.478a.606.606,0,0,1,0-.853l.849-.853a.6.6,0,0,1,.849,0L4.5,10.414l5.627-5.66a.6.6,0,0,1,.849,0l.849.853a.606.606,0,0,1,0,.853l-6.9,6.94A.6.6,0,0,1,4.076,13.4Z"
                transform="translate(0 -4.577)"
                fill={isSuccess ? "#15B745" : "#374247"}
            />
        </svg>
    ) : (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18.242"
            height="13.621"
            viewBox="0 0 18.242 13.621"
        >
            <g
                id="Group_11881"
                data-name="Group 11881"
                transform="translate(-313.379 -699.879)"
            >
                <path
                    id="Icon_feather-check"
                    data-name="Icon feather-check"
                    d="M20,9,10.375,19,6,14.455"
                    transform="translate(309.5 693)"
                    fill="none"
                    stroke={isSuccess ? "#15B745" : "#374247"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                />
            </g>
        </svg>
    );
