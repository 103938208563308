import { QueryClient } from "@tanstack/react-query";
import { LoaderFunctionArgs } from "react-router-dom";

import { getAccountCookie } from "../../../../services";
import { getTaskParams } from "../../services";

import { taskQuery } from "../queries";
import { taskSlug } from "../variables";

export const taskLoader =
    (queryClient: QueryClient) =>
    async ({ params }: LoaderFunctionArgs) => {
        const { accountType } = getAccountCookie();

        const taskParams = getTaskParams();

        const isExam = params.taskType === taskSlug.exercise;

        if (accountType !== "student" || !taskParams) {
            return undefined;
        }

        const query = taskQuery(
            isExam && taskParams.type === taskSlug.exercise
                ? { taskId: taskParams.taskId, examId: taskParams.additionalId }
                : { taskId: taskParams.taskId }
        );

        return (
            queryClient.getQueryData(query.queryKey) ??
            queryClient.fetchQuery(query)
        );
    };
