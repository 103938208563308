import Cookies from "js-cookie";

const setRegisterCookie = (email: string) => {
    Cookies.set("register_success", email, {
        sameSite: "strict",
        expires: 1,
    });
};

const getRegisterCookie = () => Cookies.get("register_success");

const removeRegisterCookie = () =>
    Cookies.remove("register_success", { sameSite: "strict" });

export { setRegisterCookie, getRegisterCookie, removeRegisterCookie };
