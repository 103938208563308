import {
    Navigate,
    Outlet,
    useLocation,
    useOutlet,
    useParams,
} from "react-router-dom";
import { romanize } from "romans";

import { useTaskCategory } from "../../hooks";
import { useTasksOptions } from "../Tasks";

import { DashboardHeading } from "../../../../components/headings";
import { NotificationTask } from "../../../dashboard/components/NotificationTask";
import { NavigationBar, TasksCategoryList } from "../../components";

import { getAccountCookie } from "../../../../services";
import { getPreviousPath } from "../../../../utils";
import { getIsTaskFilterExam, getShieldExamName } from "../../utils";

import { TaskFilterExamType } from "../../typings";

export const TaskCategory = () => {
    const { isSubscriptionPaid } = getAccountCookie();
    const outlet = useOutlet();
    const { pathname } = useLocation();
    const params = useParams();
    const { userClassState, tasksFilterState } = useTasksOptions();

    const [isTasksFiltered] = tasksFilterState;
    const [userClass] = userClassState;

    const { data } = useTaskCategory(params.categoryId!, userClass.value);

    if (!outlet && data && data.data.subsections.length === 0) {
        return <Navigate to={getPreviousPath(pathname)} />;
    }

    return outlet ? (
        <Outlet context={{ userClassState, tasksFilterState }} />
    ) : data ? (
        <>
            <DashboardHeading
                title={data!.data.name}
                icon={data!.data.image}
                userClass={
                    userClass
                        ? getIsTaskFilterExam(userClass.value)
                            ? getShieldExamName(
                                  userClass.value as TaskFilterExamType
                              )
                            : romanize(parseInt(userClass.value))
                        : undefined
                }
                hasSmallSpacing={true}
            />
            <TasksCategoryList
                tasks={data!.data.subsections}
                isFiltered={isTasksFiltered}
            />
            {!isSubscriptionPaid && <NotificationTask />}
            <NavigationBar
                backward={{
                    children: "WRÓĆ DO BAZY ZADAŃ",
                }}
            />
        </>
    ) : null;
};
