import { useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";

import { useMedia } from "../../../../../hooks";

import { getAccountCookie } from "../../../../../services";

import { AuthContainer } from "../../../../../components/containers";
import { MainHeading } from "../../../../../components/headings";
import { AuthText } from "../../../../../components/texts";
import { ArrowButton, DefaultButton } from "../../../../../components/buttons";
import { ButtonLoader } from "../../../../../components/utils";

import styles from "./PaymentError.module.css";

import pi from "../../../../../assets/images/robots/pi-error.svg";

export const PaymentError = () => {
    const { isDesktop } = useMedia();
    const { accountType } = getAccountCookie();

    const navigate = useNavigate();
    const { state } = useNavigation();

    const [isForwardClicked, setIsForwardClicked] = useState(false);
    const isLoadingState = state === "loading";

    const forwardPathname = `/dashboard/${accountType}/account/subscription`;

    return (
        <>
            <ArrowButton to={forwardPathname} wrapper={styles.back}>
                Wstecz
            </ArrowButton>
            <AuthContainer
                position="center"
                image={pi}
                imageSize={isDesktop ? 285 : 214}
                imagePosition="right"
                imageOffsetX={isDesktop ? 78 : undefined}
                imageOffsetY={isDesktop ? 15.5556 : undefined}
            >
                <MainHeading wrapper={styles.heading} elementType="h2">
                    Płatność nie powiodła się
                </MainHeading>
                <AuthText>
                    <p className={styles.text}>
                        Wystąpił problem z płatnością.
                    </p>
                </AuthText>
                <DefaultButton
                    wrapper={styles.forwardButton}
                    whileHover={{ opacity: 0.7 }}
                    isDisabled={isLoadingState}
                    onClick={() => {
                        setIsForwardClicked(true);

                        navigate(forwardPathname);
                    }}
                >
                    <ButtonLoader condition={isLoadingState && isForwardClicked} color="white">
                        SPRÓBUJ PONOWNIE
                    </ButtonLoader>
                </DefaultButton>
            </AuthContainer>
        </>
    );
};
