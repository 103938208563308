import { DefaultButton } from "../DefaultButton";

import styles from "./ButtonWithIcon.module.css";

import { ReactComponent as Draw } from "../../../assets/images/components/button/draw.svg";
import { ReactComponent as Checkmark } from "../../../assets/images/components/button/checkmark.svg";

type Props = React.ComponentProps<typeof DefaultButton> & {
    icon: "draw" | "checkmark";
};

export const ButtonWithIcon = ({
    children,
    icon = "checkmark",
    ...rest
}: Props) => (
    <div className={styles.container}>
        <DefaultButton {...rest}>
            <span className={styles.icon}>
                {icon === "draw" && <Draw />}
                {icon === "checkmark" && <Checkmark />}
            </span>
            <span className={styles.text}> {children}</span>
        </DefaultButton>
    </div>
);
