import React, { useEffect, useState, useRef } from "react";
import { useField } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import clsx from "clsx";

import styles from "./DefaultCheckbox.module.css";

type Props = {
    label?: JSX.Element | string;
    name: string;
    type: string;
    isBlankError?: boolean;
};

export const DefaultCheckbox = ({ label, isBlankError, ...props }: Props) => {
    const [field, meta] = useField(props);
    const [isFocused, setIsFocused] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const isFilledOnMount = useRef(field.value);
    const isTabKey = useRef(false);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (isFilledOnMount.current) {
            isFilledOnMount.current = false;
        }

        if (!isTouched) {
            setIsTouched(true);
        }

        field.onChange(event);
    };

    useEffect(() => {
        const checkTabKey = (event: KeyboardEvent) => {
            const key = event.code || event.key;

            if (key === "Tab") {
                isTabKey.current = true;
            } else {
                isTabKey.current = false;
            }
        };

        const disableTabKey = () => {
            if (isTabKey.current) {
                isTabKey.current = false;
            }
        };

        document.addEventListener("keydown", checkTabKey);
        document.addEventListener("click", disableTabKey);

        return () => {
            document.removeEventListener("keydown", checkTabKey);
            document.removeEventListener("click", disableTabKey);
        };
    }, []);

    return (
        <div className={styles.field}>
            <label className={styles.label}>
                <span>
                    <span
                        className={clsx(
                            styles.checkbox,
                            isFocused && isTabKey.current && styles.focused,
                            ((isTouched && meta.error) ||
                                (isBlankError && meta.error)) &&
                                styles.required
                        )}
                    >
                        <AnimatePresence mode="wait">
                            {field.value && (
                                <motion.span
                                    className={styles.icon}
                                    initial={{
                                        y: isFilledOnMount.current ? 0 : -20,
                                        scale: isFilledOnMount.current
                                            ? 1
                                            : 0.4,
                                    }}
                                    animate={{ y: 0, scale: 1 }}
                                    exit={{ y: 20, scale: 0.4 }}
                                    transition={{
                                        type: "spring",
                                        stiffness: 360,
                                        damping: 10,
                                    }}
                                >
                                    <CheckboxIcon />
                                </motion.span>
                            )}
                        </AnimatePresence>
                    </span>
                </span>
                <input
                    className={styles.input}
                    {...field}
                    {...props}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChange={handleOnChange}
                />
                {label && <span className={styles.text}>{label}</span>}
            </label>
            <AnimatePresence mode="wait">
                {isTouched && meta.error && meta.error !== " " ? (
                    <motion.div
                        className={styles.metaError}
                        initial={{ height: 0 }}
                        animate={{ height: "auto" }}
                        exit={{ height: 0 }}
                        transition={{ type: "tween", duration: 0.3 }}
                    >
                        <p className={styles.metaMessage}>{meta.error}</p>
                    </motion.div>
                ) : null}
            </AnimatePresence>
        </div>
    );
};

const CheckboxIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="9"
            viewBox="0 0 12 9"
        >
            <path
                id="Icon_awesome-check"
                data-name="Icon awesome-check"
                d="M4.076,13.4.176,9.478a.606.606,0,0,1,0-.853l.849-.853a.6.6,0,0,1,.849,0L4.5,10.414l5.627-5.66a.6.6,0,0,1,.849,0l.849.853a.606.606,0,0,1,0,.853l-6.9,6.94A.6.6,0,0,1,4.076,13.4Z"
                transform="translate(0 -4.577)"
                fill="#374247"
            />
        </svg>
    );
};
