import { useRef, useEffect } from "react";

export const useStorageFlag = (item: [string, string], isActive: boolean) => {
    const isSet = useRef(false);

    useEffect(() => {
        if (isActive) {
            isSet.current = true;

            window.sessionStorage.setItem(item[0], item[1]);
        } else if (isSet.current) {
            isSet.current = false;

            window.sessionStorage.removeItem(item[0]);
        }
    }, [isActive, item]);
};
