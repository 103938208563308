const collapseVariants = {
    closed: {
        height: 0,
    },
    open: {
        height: "auto",
    },
};

const arrowVariants = {
    closed: {
        rotate: 0,
    },
    open: {
        rotate: -180,
    },
};

const transition = {
    type: "tween",
    duration: 0.3,
};

export { collapseVariants, arrowVariants, transition };
