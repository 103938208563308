export const parseAccountType = (type: "Uczeń" | "Rodzic" | "Nauczyciel" | "undefined") => {
    switch (type) {
        case "Uczeń":
            return "student";
        case "Rodzic":
            return "parent";
        case "Nauczyciel":
            return "teacher";
        default:
            return "undefined";
    }
};
