const transition = {
    type: "tween",
    duration: 0.2,
};

const getButtonAnimations = (isChecked: boolean, isAvailableSoon?: boolean) => {
    const animation = {
        borderColor: isAvailableSoon
            ? "#879195"
            : isChecked
            ? "#2eafbe"
            : "#FFFFFF",
    };

    return {
        initial: animation,
        animate: animation,
        transition: transition,
    };
};

const getIconAnimations = (isChecked: boolean, isAvailableSoon?: boolean) => {
    const animation = {
        borderColor: isAvailableSoon
            ? "#879195"
            : isChecked
            ? "#2eafbe"
            : "#FFFFFF",
    };

    return {
        initial: animation,
        animate: animation,
        transition: transition,
    };
};

const getMarkAnimations = (isChecked: boolean, isHovered: boolean) => {
    const filter = !isChecked && isHovered ? "grayscale(1)" : "grayscale(0)";

    return {
        initial: {
            opacity: 0,
            filter,
        },
        animate: {
            opacity: 1,
            filter,
        },
        exit: {
            opacity: 0,
            filter,
        },
        transition: transition,
    };
};

const getSymbolAnimations = (isChecked: boolean, isHovered: boolean) => {
    const animation = !isChecked && isHovered ? 0.3 : 1;

    return {
        initial: {
            opacity: animation,
        },
        animate: {
            opacity: animation,
        },
        transition: transition,
    };
};

export {
    getButtonAnimations,
    getIconAnimations,
    getMarkAnimations,
    getSymbolAnimations,
};
