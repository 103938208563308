import Cookies from "js-cookie";

const setRemindPasswordCookie = (email: string) =>
    Cookies.set("remind_password_success", email, {
        sameSite: "strict",
        expires: 1,
    });

const getRemindPasswordCookie = () => Cookies.get("remind_password_success");

const removeRemindPasswordCookie = () =>
    Cookies.remove("remind_password_success", { sameSite: "strict" });

export {
    setRemindPasswordCookie,
    getRemindPasswordCookie,
    removeRemindPasswordCookie,
};
