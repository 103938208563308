const transition = { type: "tween", duration: 0.3 };

const getArrowAnimation = (isOpen: boolean) => ({
    animate: isOpen ? { rotate: 180 } : { rotate: 0 },
    transition,
});

const collapseAnimation = {
    initial: { height: 0 },
    animate: { height: "auto" },
    exit: { height: 0 },
    transition: { type: "tween", duration: 0.3 },
};

export { getArrowAnimation, collapseAnimation };
