import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

import { useResultContext } from "../../contexts/ResultContext";

import { useBanner } from "../../../dashboard/hooks";

import {
    tasksQuery,
    taskCategoryQuery,
    taskSubcategoryQuery,
    taskSlug,
} from "../../utils";
import { examTaskDetailsQuery, examTasksQuery } from "../../../examTasks/utils";
import {
    learningProgressCategoryQuery,
    learningProgressQuery,
    learningProgressSubcategoryQuery,
    userAdvancementDataQuery,
} from "../../../dashboard/utils";

import { AnswerCheckType, TaskType } from "../../typings";

type Mutation = {
    id: string;
    answers: any[];
};

export const useCheckTaskAnswers = (taskType: TaskType) => {
    const queryClient = useQueryClient();

    const { setResults, setIsResultsLoading } = useResultContext();

    const { refetch: bannerRefetch } = useBanner(true);

    return useMutation<
        AxiosResponse<AnswerCheckType, any>,
        any,
        Mutation,
        unknown
    >(
        (data) =>
            mainApi.post("check_exercise_answers", {
                id: data.id,
                answers: data.answers,
            }),
        {
            onSuccess: (data) => {
                if (taskType === taskSlug.default) {
                    queryClient.removeQueries(tasksQuery().queryKey);
                    queryClient.removeQueries(taskCategoryQuery().queryKey);
                    queryClient.removeQueries(taskSubcategoryQuery().queryKey);
                }

                if (taskType === taskSlug.exercise) {
                    queryClient.removeQueries(examTasksQuery().queryKey);
                    queryClient.removeQueries(examTaskDetailsQuery().queryKey);
                }

                queryClient.removeQueries(learningProgressQuery('student').queryKey);
                queryClient.removeQueries(
                    learningProgressCategoryQuery('student').queryKey
                );
                queryClient.removeQueries(
                    learningProgressSubcategoryQuery('student').queryKey
                );

                queryClient.invalidateQueries(
                    userAdvancementDataQuery().queryKey
                );

                bannerRefetch();

                setTimeout(() => {
                    setResults(data.data);
                    setIsResultsLoading(false);
                }, 1300);
            },
        }
    );
};
