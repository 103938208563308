import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { mainApi } from "../../../../api";

import { setTaskParams } from "../../services";

import { useNotify } from "../../../../hooks";

import { taskSlug } from "../../utils";
import { studentGetToKnowDataQuery } from "../../../competencyTest/utils";

type Response = {
    status: number;
    session_id: string;
    exercise_id: string | 0;
    exercise_count: number;
    theory_id: string | 0;
    end_of_ai: boolean;
};

type Mutation = {
    invalidateStudentData?: boolean;
} | void;

export const useAiSession = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const params = useParams();

    const notify = useNotify({ type: "error", position: "bottom-center" });

    const mutation = useMutation<
        AxiosResponse<Response, any>,
        any,
        Mutation,
        unknown
    >(() => mainApi.get("ai_session"), {
        onSuccess: (data, variables) => {
            if (variables?.invalidateStudentData) {
                queryClient.invalidateQueries(
                    studentGetToKnowDataQuery().queryKey
                );
            }

            const taskId = data.data.exercise_id;
            const sessionId = data.data.session_id;
            const theoryId = data.data.theory_id;

            if (params.taskId === taskId) {
                notify(
                    "Wystąpił błąd. Znalezione zadanie jest identyczne. Aktualna sesja została zakończona."
                );

                setTimeout(() => {
                    navigate("/dashboard/student");
                }, 1000);
            }

            if (theoryId !== 0) {
                navigate(`/dashboard/student/ai-session/theory/${sessionId}`, {
                    state: true,
                });
            } else {
                if (data.data.end_of_ai) {
                    navigate("/dashboard/student/ai-session/finished", {
                        state: true,
                    });
                } else {
                    if (taskId !== 0) {
                        setTaskParams({
                            type: taskSlug.ai,
                            taskId,
                            additionalId: sessionId,
                        });

                        navigate(
                            `/dashboard/student/task/${taskSlug.ai}/${taskId}`
                        );
                    } else if (data.data.exercise_count === 0) {
                        mutation.mutate();
                    } else {
                        navigate(
                            `/dashboard/student/ai-session/result/${sessionId}`,
                            {
                                state: true,
                            }
                        );
                    }
                }
            }
        },
    });

    return mutation;
};
