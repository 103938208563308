import { useState } from "react";
import { Navigate, Outlet, useLocation, useOutlet } from "react-router-dom";

import { getAccountCookie } from "../../../../../services";

import { useIsHomeDashboard, useMedia } from "../../../../../hooks";
import { useDeleteAccountContext } from "../../Dashboard";

import { useStorageFlag, useUserImportantNotification } from "../../../hooks";

import { DashboardContainer } from "../../../../../components/containers";
import { WideHeading } from "../../../../../components/headings";
import {
    Brand,
    Childrens,
    ChildrensDesktop,
    HomeWrapper,
    Navbar,
    NavbarDesktop,
    NotificationAlert,
    NotificationDemo,
    Notifications,
    NotificationsBackdrop,
    NotificationsDesktop,
} from "../../../components";
import { VideoModal } from "../../../../../components/modals";

import { getClearPath } from "../../../../../utils";

import styles from "./DashboardParent.module.css";

export const DashboardParent = () => {
    const { accountType, isAccountDisabled, isVideoWatched } =
        getAccountCookie();
    const { isDesktop } = useMedia();

    const outlet = useOutlet();
    const deleteAccountContext = useDeleteAccountContext();
    const userImportantNotification = useUserImportantNotification();

    const { pathname } = useLocation();
    const isDashboardInView = getClearPath(pathname) === "/dashboard/parent";
    const isHome = useIsHomeDashboard();

    const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
    useStorageFlag(["popOpen", "notifications"], isNotificationsOpen);

    const [isVideoModalOpen, setIsVideoModalOpen] = useState(!isVideoWatched);

    if (isAccountDisabled) {
        return <Navigate to={`/dashboard/disabled`} replace={true} />;
    }

    if (accountType !== "parent") {
        return <Navigate to={`/dashboard/${accountType}`} replace={true} />;
    }

    return (
        <>
            <DashboardContainer
                outsideElement={
                    isDesktop ? (
                        <>
                            <NavbarDesktop>
                                <NotificationsDesktop
                                    isOpen={isNotificationsOpen}
                                    setIsOpen={setIsNotificationsOpen}
                                />
                            </NavbarDesktop>
                            {isHome && (
                                <WideHeading className={styles.heading}>
                                    PANEL RODZICA
                                </WideHeading>
                            )}
                        </>
                    ) : (
                        <Navbar
                            isCollapsed={isNotificationsOpen}
                            setIsCollapsed={setIsNotificationsOpen}
                        >
                            <Notifications
                                isOpen={isNotificationsOpen}
                                setIsOpen={setIsNotificationsOpen}
                            />
                        </Navbar>
                    )
                }
            >
                {outlet ? (
                    <Outlet context={deleteAccountContext} />
                ) : (
                    <>
                        {!isDesktop && (
                            <Brand
                                onClick={() => setIsNotificationsOpen(true)}
                            />
                        )}
                        <NotificationAlert result={userImportantNotification} />
                        <HomeWrapper>
                            {isDesktop ? (
                                <ChildrensDesktop
                                    isEnabled={isDashboardInView}
                                />
                            ) : (
                                <Childrens
                                    title="MOJE DZIECI"
                                    isEnabled={isDashboardInView}
                                />
                            )}
                        </HomeWrapper>
                    </>
                )}
            </DashboardContainer>
            {!isDesktop && (
                <NotificationsBackdrop isOpen={isNotificationsOpen} />
            )}
            <VideoModal
                isOpen={isVideoModalOpen}
                setIsOpen={setIsVideoModalOpen}
            />
        </>
    );
};
