import { useEffect } from "react";
import { Formik, Form } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { useLoadingContext } from "../../../../../contexts/LoadingContext";

import {
    useChangePassword,
    useNavigationAccount,
    useUserData,
} from "../../../hooks";
import { useSubmitForm } from "../../../../../hooks";

import { DashboardHeading } from "../../../../../components/headings";
import { AccountContainer } from "../../../../../components/containers";
import { NavigationAccount } from "../../../components";
import { DefaultInput } from "../../../../../components/inputs";
import { PasswordValidator } from "../../../../../components/utils";
import { DefaultButton } from "../../../../../components/buttons";

import { changePasswordSchema } from "../../../utils";
import { getPreviousPath } from "../../../../../utils";

import styles from "./ChangePassword.module.css";

type Props = {
    mutation: ReturnType<typeof useChangePassword>;
};

export const ChangePassword = ({ mutation }: Props) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { setLoadingActive } = useLoadingContext();

    const navigation = useNavigationAccount("Zmiana hasła");

    const userData = useUserData();
    const submitForm = useSubmitForm();

    useEffect(() => {
        if (mutation.isSuccess) {
            setLoadingActive(false, () => {
                mutation.reset();

                navigate("/reset-password", {
                    state: { isPasswordChanged: true },
                });
            });
        }
    }, [mutation, setLoadingActive, navigate]);

    useEffect(()=>{
        if(mutation.isError) {
            setLoadingActive(false);
        }
    }, [mutation, setLoadingActive])

    return (
        <>
            <DashboardHeading title="MOJE KONTO" subtitle="zmiana hasła" />
            <AccountContainer
                sidebarElement={<NavigationAccount elements={navigation} />}
            >
                <div className={styles.container}>
                    <Formik
                        initialValues={{
                            email: userData.data?.data.data.email,
                            password: "",
                            password_confirm: "",
                        }}
                        validationSchema={changePasswordSchema}
                        onSubmit={(data, actions) => {
                            setLoadingActive(true, () => {
                                submitForm(data);
                                actions.setSubmitting(false);
                            });
                        }}
                    >
                        {(formik) => (
                            <Form className={styles.form}>
                                <DefaultInput
                                    label="E-mail"
                                    name="email"
                                    type="email"
                                    isDisabled={true}
                                />
                                <DefaultInput
                                    label="Nowe hasło"
                                    name="password"
                                    type="password"
                                />
                                <DefaultInput
                                    label="Powtórz nowe hasło"
                                    name="password_confirm"
                                    type="password"
                                />
                                <PasswordValidator
                                    value={formik.values.password}
                                />
                                <DefaultButton
                                    wrapper={styles.formButton}
                                    initial={{
                                        backgroundColor:
                                            DefaultButton.color.disabled,
                                    }}
                                    animate={{
                                        backgroundColor:
                                            !formik.dirty || !formik.isValid
                                                ? DefaultButton.color.disabled
                                                : DefaultButton.color.enabled,
                                    }}
                                    whileHover={{
                                        opacity: formik.isSubmitting ? 1 : 0.7,
                                    }}
                                    transition={{
                                        type: "tween",
                                        duration: 0.3,
                                    }}
                                    isSubmit={true}
                                    isDisabled={
                                        !formik.dirty || !formik.isValid
                                    }
                                >
                                    ZAPISZ
                                </DefaultButton>
                                <DefaultButton
                                    wrapper={styles.cancelButton}
                                    whileHover={{
                                        opacity: formik.isSubmitting ? 1 : 0.7,
                                    }}
                                    transition={{
                                        type: "tween",
                                        duration: 0.3,
                                    }}
                                    to={getPreviousPath(pathname)}
                                >
                                    ANULUJ ZMIANY
                                </DefaultButton>
                            </Form>
                        )}
                    </Formik>
                </div>
            </AccountContainer>
        </>
    );
};
