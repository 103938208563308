import { DashboardLogo } from "../../../../components/logos";
import { NotificationsToggle } from "../../components";

import styles from "./Brand.module.css";

type Props = {
    onClick?: () => void;
};

export const Brand = ({ onClick }: Props) => {
    const handleToggle = () => {
        const container = document.querySelector("#dashboardContainer")!;
        const scrollTop = container.scrollTop;

        if (onClick) {
            if (scrollTop > 0) {
                container.scroll({
                    behavior: "smooth",
                    top: 0,
                });

                setTimeout(() => {
                    onClick();
                }, 500);
            } else {
                onClick();
            }
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.brand}>
                <DashboardLogo />
            </div>
            {typeof onClick === "function" && (
                <div className={styles.notification}>
                    <NotificationsToggle onClick={handleToggle} />
                </div>
            )}
        </div>
    );
};
