import { mainApi } from "../../../api";

const studentGetToKnowDataQuery = () => ({
    queryKey: ["studentGetToKnowData"],
    queryFn: async () => mainApi.get("student_get_to_know_data"),
    staleTime: Infinity,
});

const getCompetencyTestQuery = () => ({
    queryKey: ["getCompetencyTest"],
    queryFn: async () => mainApi.get("get_competency_test"),
    staleTime: 0,
});

const setCompetencyTestAsDoneQuery = (isEnabled: boolean) => ({
    queryKey: ["setCompetencyTestAsDone"],
    queryFn: async () => mainApi.post("set_competency_test_as_done"),
    cacheTime: 0,
    enabled: isEnabled,
});

const getExamDatesQuery = () => ({
    queryKey: ["getExamDates"],
    queryFn: async () => mainApi.get("get_exam_dates"),
    cacheTime: 1000,
    staleTime: 1000,
});

const studySettingsQuery = () => ({
    queryKey: ["studySettings"],
    queryFn: async () => mainApi.get("study_planning"),
});

export {
    studentGetToKnowDataQuery,
    getCompetencyTestQuery,
    setCompetencyTestAsDoneQuery,
    getExamDatesQuery,
    studySettingsQuery,
};
