import { AnimatePresence, motion } from "framer-motion";

import { TaskContent } from "../build-components";

import {
    arrowVariants,
    collapseVariants,
    transition,
} from "./TaskCollapse.animations";

import { TaskCollapseProps } from "./TaskCollapse.typings";

import styles from "./TaskCollapse.module.css";

import { ReactComponent as Arrow } from "./images/arrow-icon.svg";

export const TaskCollapse = ({
    id,
    title,
    content,
    isActive,
    onClick,
}: TaskCollapseProps) => {
    return (
        <div className={styles.container}>
            <button
                className={styles.button}
                type="button"
                onClick={() => onClick(id)}
            >
                {title}
                <motion.span
                    className={styles.arrow}
                    initial={isActive ? "open" : "closed"}
                    animate={isActive ? "open" : "closed"}
                    variants={arrowVariants}
                    transition={transition}
                >
                    <Arrow />
                </motion.span>
            </button>
            <AnimatePresence mode="wait" initial={false}>
                {isActive && (
                    <motion.div
                        className={styles.collapse}
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={collapseVariants}
                        transition={transition}
                    >
                        <div className={styles.content}>
                            <TaskContent content={content} />
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};
