export const TheoryIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.5"
        height="15"
        viewBox="0 0 17.5 15"
    >
        <path
            id="Icon_ionic-md-book"
            data-name="Icon ionic-md-book"
            d="M19.285,5.643l-7.16,1.364L4.965,5.643a1.493,1.493,0,0,0-1.59,1.6v10.42c0,.882.673,1.389,1.59,1.6l7.16,1.359,7.16-1.364c.917-.213,1.59-.72,1.59-1.6V7.245A1.493,1.493,0,0,0,19.285,5.643Zm0,12.018-6.361,1.364V8.6l6.361-1.364Zm-7.959,1.364L4.965,17.661V7.245l6.361,1.364Z"
            transform="translate(-3.375 -5.627)"
            fill="#2eafbe"
        />
    </svg>
);
