import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { DottedLoader } from "../../../../components/loaders";
import { TasksIcon, TheoryIcon } from "./icons";

import { getProgressStatus } from "../../utils";

import { ButtonProps, ButtonWrapperProps } from "./TaskButton.typings";

import styles from "./TaskButton.module.css";

const AnimatedLink = motion(Link);

export const TaskButton = ({
    to,
    progress,
    isActive,
    isLoading,
    isDisabled,
    isProgressLoading,
    setIsClicked,
    onClick,
}: ButtonProps) => {
    const progressPercentage = progress
        ? progress[1] === 0
            ? undefined
            : (progress[0] * 100) / progress[1]
        : 0;
    const progressStatus = getProgressStatus(progressPercentage);

    return (
        <ButtonWrapper
            to={to}
            isSpaced={progress ? true : false}
            isActive={isActive}
            isLoading={isLoading}
            isDisabled={isDisabled}
            setIsClicked={setIsClicked}
            onClick={onClick}
        >
            <span className={styles.content}>
                <span className={styles.icon}>
                    {progress ? <TasksIcon /> : <TheoryIcon />}
                </span>
                <span className={styles.name}>
                    {progress ? "ZADANIA" : "TEORIA"}
                </span>
            </span>
            <>
                {progress && (
                    <span
                        className={clsx(
                            styles.progress,
                            progressStatus === "low" && styles.low,
                            progressStatus === "medium" && styles.medium,
                            progressStatus === "high" && styles.high
                        )}
                    >
                        {isProgressLoading ? (
                            <DottedLoader />
                        ) : (
                            `${progress[0]}/${progress[1]}`
                        )}
                    </span>
                )}
            </>
        </ButtonWrapper>
    );
};

const ButtonWrapper = ({
    children,
    to,
    isSpaced,
    isActive,
    isLoading,
    isDisabled,
    setIsClicked,
    onClick,
}: ButtonWrapperProps) => {
    const props = {
        className: clsx(
            styles.button,
            isSpaced && styles.spaceBetween,
            (isActive || isLoading || isDisabled) && styles.disabled
        ),
        variants: {
            inactive: {
                borderColor: "#ffffff",
                opacity: 1,
            },
            active: {
                borderColor: "#2EAFBE",
                opacity: 1,
            },
            disabled: {
                borderColor: "#ffffff",
                opacity: 0.5,
            },
        },
        initial: isDisabled ? "disabled" : isActive ? "active" : "inactive",
        animate: isDisabled ? "disabled" : isActive ? "active" : "inactive",
        transition: {
            type: "tween",
            duration: 0.3,
        },
    };

    const handleButtonClick = () => {
        if (setIsClicked) {
            setIsClicked(true);
        }

        if (onClick) {
            onClick();
        }
    };

    return to ? (
        <div className={styles.container}>
            <AnimatedLink
                to={to}
                {...props}
                onClick={setIsClicked ? () => setIsClicked(true) : undefined}
            >
                {children}
            </AnimatedLink>
        </div>
    ) : (
        <div className={styles.container}>
            <motion.button
                type="button"
                {...props}
                disabled={isActive || isLoading || isDisabled}
                onClick={handleButtonClick}
            >
                {children}
            </motion.button>
        </div>
    );
};
