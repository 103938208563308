import clsx from "clsx";
import { ReactComponent as EraserComponent } from "../../../../../assets/images/components/whiteboard/eraser-icon.svg";
import styles from "./EraserButton.module.css";

type Props = { active: boolean; onClick: () => void };
export const EraserButton = ({ active, onClick }: Props) => (
    <button
        className={clsx(styles.button, active && styles.active)}
        type="button"
        onClick={onClick}
    >
        <span>
            <EraserComponent fill={active ? "white" : "#2eafbe"} />
        </span>
    </button>
);
