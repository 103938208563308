import { useState, useRef, useEffect } from "react";
import { Navigate, Outlet, useLocation, useOutlet } from "react-router-dom";
import { Formik, Form } from "formik";
import clsx from "clsx";

import {
    getInvitationCookie,
    getRemindPasswordCookie,
    getResetPasswordCookie,
} from "../../../../services";

import { useLoadingContext } from "../../../../contexts/LoadingContext";

import { useRemindPasswordCode } from "../../hooks";
import { useMedia, useSubmitForm } from "../../../../hooks";

import { DisableBack, ProtectedRoute } from "../../../../components/utils";
import { AuthContainer } from "../../../../components/containers";
import { ArrowButton, DefaultButton } from "../../../../components/buttons";
import { MainHeading } from "../../../../components/headings";
import { AuthText, ErrorText } from "../../../../components/texts";
import { DefaultInput } from "../../../../components/inputs";

import { remindPasswordSchema } from "../../utils";

import styles from "./RemindPassword.module.css";

import pi from "../../../../assets/images/robots/pi-loading.svg";

type Props = {
    mutation: ReturnType<typeof useRemindPasswordCode>;
};

type StateConfirmed = {
    state: {
        isConfirmed?: boolean;
    } | null;
};

type StateExpired = {
    state: {
        isExpired?: boolean;
        message?: string;
    } | null;
};

export const RemindPassword = ({ mutation }: Props) => {
    const outlet = useOutlet();

    return outlet ? <Outlet /> : <RemindPasswordForm mutation={mutation} />;
};

const RemindPasswordForm = ({ mutation }: Props) => {
    const remindPasswordCookie = getRemindPasswordCookie();
    const resetPasswordCookie = getResetPasswordCookie();
    const invitationCookie = getInvitationCookie();

    const { state }: StateConfirmed & StateExpired = useLocation();
    const submitForm = useSubmitForm();
    const { isDesktop } = useMedia();
    const { setLoadingActive } = useLoadingContext();

    const isRedirectAvailable = useRef(false);
    const [isSent, setIsSent] = useState(state?.isConfirmed ?? false);

    useEffect(() => {
        if (mutation.isSuccess && !isSent) {
            setLoadingActive(false, () => {
                setIsSent(true);

                mutation.reset();
            });
        }
    }, [mutation, isSent, setLoadingActive]);

    if (
        remindPasswordCookie &&
        !isRedirectAvailable.current &&
        !state?.isConfirmed
    ) {
        isRedirectAvailable.current = true;

        return <Navigate to="confirm" replace={true} />;
    }

    if (resetPasswordCookie) {
        return <Navigate to="/reset-password" replace={true} />;
    }

    if (invitationCookie) {
        return (
            <Navigate
                to={`/register/${invitationCookie[1].toLowerCase()}`}
                replace={true}
            />
        );
    }

    return (
        <ProtectedRoute redirectWhenLoggedIn={true} redirectPath="/dashboard">
            <>
                {!isSent && (
                    <ArrowButton to="/" wrapper={styles.back}>
                        Wstecz
                    </ArrowButton>
                )}
            </>
            <AuthContainer
                position="center"
                image={pi}
                imageSize={isDesktop ? 285 : 214}
                imagePosition="right"
                imageOffsetX={isDesktop ? 78 : undefined}
                imageOffsetY={isDesktop ? 15.5556 : undefined}
            >
                <MainHeading wrapper={styles.heading} elementType="h2">
                    {isSent ? "Kod został wysłany" : "Przypomnij hasło"}
                </MainHeading>
                <AuthText>
                    {isSent ? (
                        <p>
                            Kod do zresetowania hasła został
                            <br />
                            wysłany na adres:
                            <br />
                            <strong>{remindPasswordCookie}</strong>
                        </p>
                    ) : (
                        <p>
                            Wpisz poniżej swój adres e-mail, wyślemy na niego
                            link do resetowania hasła.
                        </p>
                    )}
                </AuthText>
                {isSent ? (
                    <DefaultButton wrapper={styles.forwardButton} to="confirm">
                        DALEJ
                    </DefaultButton>
                ) : (
                    <Formik
                        initialValues={{ email: "" }}
                        validationSchema={remindPasswordSchema}
                        onSubmit={(data, actions) => {
                            setLoadingActive(true, () => {
                                submitForm(data);

                                actions.setSubmitting(false);
                            });
                        }}
                    >
                        {(formik) => (
                            <Form
                                className={clsx(
                                    styles.form,
                                    state?.isExpired && styles.error
                                )}
                            >
                                <ErrorText isError={state?.isExpired}>
                                    {state?.message}
                                </ErrorText>
                                <DefaultInput
                                    label="E-mail"
                                    name="email"
                                    type="email"
                                />
                                <DefaultButton
                                    wrapper={styles.formButton}
                                    initial={{
                                        backgroundColor:
                                            DefaultButton.color.disabled,
                                    }}
                                    animate={{
                                        backgroundColor:
                                            !formik.dirty || !formik.isValid
                                                ? DefaultButton.color.disabled
                                                : DefaultButton.color.enabled,
                                    }}
                                    whileHover={{
                                        opacity: formik.isSubmitting ? 1 : 0.7,
                                    }}
                                    transition={{
                                        type: "tween",
                                        duration: 0.3,
                                    }}
                                    isSubmit={true}
                                    isDisabled={!formik.isValid}
                                >
                                    WYŚLIJ
                                </DefaultButton>
                            </Form>
                        )}
                    </Formik>
                )}
            </AuthContainer>
            <>{isSent && <DisableBack />}</>
        </ProtectedRoute>
    );
};
