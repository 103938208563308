import { AnimatePresence, motion } from "framer-motion";

import { CircleLoader } from "../../../../components/loaders";

import { StorePaymentMethodsProps } from "../../typings";

import styles from "./StorePaymentMethods.module.css";

type Props = {
    methods: (StorePaymentMethodsProps | boolean)[];
};

export const StorePaymentMethods = ({ methods }: Props) => {
    const methodsFiltered = methods.filter(
        (method) => Object.keys(method).length !== 0
    ) as StorePaymentMethodsProps[];

    return (
        <div className={styles.container}>
            {methodsFiltered.map((method) => (
                <div key={method.label} className={styles.item}>
                    <button
                        className={styles.button}
                        type="button"
                        disabled={method.isDisabled}
                        onClick={method.onClick}
                    >
                        <span className={styles.box}>
                            <span className={styles.icon}>
                                <img src={method.icon} alt="" />
                            </span>
                            {method.icon2 && (
                                <span className={styles.icon2}>
                                    <img src={method.icon2} alt="" />
                                </span>
                            )}
                            <AnimatePresence mode="wait">
                                {method.isLoading && (
                                    <motion.span
                                        className={styles.loading}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{
                                            type: "tween",
                                            duration: 0.1,
                                        }}
                                    >
                                        <CircleLoader color="white" />
                                    </motion.span>
                                )}
                            </AnimatePresence>
                        </span>
                        <span className={styles.label}>{method.label}</span>
                    </button>
                </div>
            ))}
        </div>
    );
};
