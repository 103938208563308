import { ScheduleValue } from "../../typings";

import arrowImg from "../../../../assets/images/dashboard/competency-test/long-arrow-alt-left-solid.svg";
import styles from "./ValuePicker.module.css";

import piBrain1 from "../../../../assets/images/dashboard/competency-test/pi-brain-1.svg";
import piBrain10 from "../../../../assets/images/dashboard/competency-test/pi-brain-10.svg";
import piBrain2 from "../../../../assets/images/dashboard/competency-test/pi-brain-2.svg";
import piBrain3 from "../../../../assets/images/dashboard/competency-test/pi-brain-3.svg";
import piBrain4 from "../../../../assets/images/dashboard/competency-test/pi-brain-4.svg";
import piBrain5 from "../../../../assets/images/dashboard/competency-test/pi-brain-5.svg";
import piBrain6 from "../../../../assets/images/dashboard/competency-test/pi-brain-6.svg";
import piBrain7 from "../../../../assets/images/dashboard/competency-test/pi-brain-7.svg";
import piBrain8 from "../../../../assets/images/dashboard/competency-test/pi-brain-8.svg";
import piBrain9 from "../../../../assets/images/dashboard/competency-test/pi-brain-9.svg";

type Props = {
    value: ScheduleValue;
    setValue: React.Dispatch<React.SetStateAction<ScheduleValue>>;
};
export const ValuePicker = ({ value, setValue }: Props) => {
    const getRobotImg = () => {
        switch (value) {
            case 1:
                return piBrain1;
            case 2:
                return piBrain2;
            case 3:
                return piBrain3;
            case 4:
                return piBrain4;
            case 5:
                return piBrain5;
            case 6:
                return piBrain6;
            case 7:
                return piBrain7;
            case 8:
                return piBrain8;
            case 9:
                return piBrain9;
            case 10:
                return piBrain10;
        }
    };

    return (
        <div className={styles.picker}>
            <div className={styles.pickerInner}>
                <div className={styles.progressBar}>
                    <div
                        className={styles.fill}
                        style={{ height: `${value * 10}%` }}
                    />
                </div>
                <div>
                    <button
                        disabled={value === 10}
                        type="button"
                        className={styles.pickerButton}
                        onClick={() =>
                            setValue((state) => (state + 1) as ScheduleValue)
                        }
                    >
                        <img
                            className={styles.arrowUp}
                            src={arrowImg}
                            alt="Zwiększ ilość zagadnień"
                        />
                    </button>
                    <div className={styles.pickerValue}>{value}</div>
                    <button
                        disabled={value === 1}
                        type="button"
                        className={styles.pickerButton}
                        onClick={() =>
                            setValue((state) => (state - 1) as ScheduleValue)
                        }
                    >
                        <img
                            className={styles.arrowDown}
                            src={arrowImg}
                            alt="Zmniejsz ilość zagadnień"
                        />
                    </button>
                </div>
            </div>
            <img className={styles.robotImg} src={getRobotImg()} alt="" />
        </div>
    );
};
