import * as yup from "yup";
import YupPassword from "yup-password";

import { passwordCompareMessage } from "../../../auth/utils";

YupPassword(yup);

export const changePasswordSchema = yup.object({
    email: yup
        .string()
        .email("* nieprawidłowy adres e-mail")
        .required("* pole wymagane"),
    password: yup
        .string()
        .min(8, " ")
        .minLowercase(1, " ")
        .minUppercase(1, " ")
        .minNumbers(1, " ")
        // .minSymbols(1, " ")
        .required(" "),
    password_confirm: yup
        .string()
        .min(8, " ")
        .minLowercase(1, passwordCompareMessage)
        .minUppercase(1, passwordCompareMessage)
        .minNumbers(1, passwordCompareMessage)
        // .minSymbols(1, passwordCompareMessage)
        .oneOf([yup.ref("password")], passwordCompareMessage)
        .required(" "),
});
