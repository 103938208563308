import { QueryClient } from "@tanstack/react-query";

import { userDataQuery } from "../../utils";

export const userDataLoader = (queryClient: QueryClient) => async () => {
    const query = userDataQuery();

    return (
        queryClient.getQueryData(query.queryKey) ??
        queryClient.fetchQuery(query)
    );
};
