import React from "react";
import { renderToString } from "react-dom/server";
import { MathComponent } from "mathjax-react";
import { DOMNode, domToReact, Element } from "html-react-parser";

const formatStringToCamelCase = (string: string) => {
    const splitted = string.split("-");

    if (splitted.length === 1) return splitted[0];

    return (
        splitted[0] +
        splitted
            .slice(1)
            .map((word) => word[0].toUpperCase() + word.slice(1))
            .join("")
    );
};

const getStyleObjectFromString = (string: string) => {
    const style = {} as { [key: string]: string };

    string.split(";").forEach((element) => {
        const [property, value] = element.split(":");

        if (!property) return;

        const formattedProperty = formatStringToCamelCase(property.trim());
        style[formattedProperty] = value.trim();
    });

    return style;
};

export const replaceOptions = (styles?: {
    readonly [key: string]: string;
}) => ({
    replace: (domNode: DOMNode) => {
        if (domNode instanceof Element) {
            const { name, attribs, parent, children } = domNode;

            if (
                name === "p" &&
                children[0].type === "text" &&
                children[0].data.trim().length === 0
            ) {
                return React.createElement("p", {
                    ...attribs,
                    ...(styles && { className: styles.blank }),
                });
            }

            if (name === "figure" && attribs.class === "table") {
                return React.createElement(
                    "div",
                    {
                        ...(styles && { className: styles.table }),
                    },
                    domToReact(children, replaceOptions(styles))
                );
            }

            if (name === "figure" && attribs.class === "image image_resized") {
                const nodeStyle = getStyleObjectFromString(attribs.style);

                return React.createElement(
                    "figure",
                    {
                        ...(styles && { className: styles.image }),
                        ...(nodeStyle
                            ? {
                                  style: {
                                      ...nodeStyle,
                                      marginRight: "auto",
                                      marginLeft: "auto",
                                  },
                              }
                            : {}),
                    },
                    domToReact(children, replaceOptions(styles))
                );
            }

            if (name === "figure" && attribs.class === "media") {
                return React.createElement(
                    "figure",
                    {
                        ...(styles && { className: styles.media }),
                    },
                    domToReact(children, replaceOptions())
                );
            }

            if (
                name === "colgroup" &&
                parent instanceof Element &&
                parent.attribs.class === "ck-table-resized"
            ) {
                return <></>;
            }

            if (name === "math") {
                return (
                    <MathComponent
                        mathml={renderToString(
                            React.createElement(
                                name,
                                attribs,
                                domToReact(children, replaceOptions(styles))
                            )
                        )}
                        display={false}
                    />
                );
            }

            if (name === "mfenced") {
                return React.createElement(
                    name,
                    { OPEN: attribs.open, close: attribs.close },
                    domToReact(children, replaceOptions(styles))
                );
            }
        }
    },
});
