import { Navigate, useLocation } from "react-router-dom";
import { DefaultButton } from "../../../../components/buttons";
import { DashboardHeading } from "../../../../components/headings";
import { ObjectSelectInput, Options } from "../../../../components/inputs";
import { useMedia } from "../../../../hooks";
import { getPreviousPath } from "../../../../utils";
import { StepperWithTitle } from "../../components";
import { useCompetencyTestContext } from "../../hooks";
import { colors } from "../../utils";

import styles from "./Step3.module.css";

export const Step3 = () => {
    const { isDesktop } = useMedia();

    const { pathname } = useLocation();

    const { form, setForm, backwardPathname } = useCompetencyTestContext();

    const isFilled = form.class && form.scope && form.grade && form.opinion;

    const gradesOptions: Options = [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
    ];
    const opinionOptions: Options = [
        {
            label: "najlepszy przedmiot w szkole",
            value: "najlepszy przedmiot w szkole",
        },
        {
            label: "lubię matematykę, ale sprawia mi trudność",
            value: "lubię matematykę, ale sprawia mi trudność",
        },
        {
            label: "nie lubię matematyki, ale muszę się nauczyć",
            value: "nie lubię matematyki, ale muszę się nauczyć",
        },
        {
            label: "największy koszmar w szkole",
            value: "największy koszmar w szkole",
        },
    ];

    if (!form.class || !form.scope) {
        return <Navigate to={getPreviousPath(pathname, 1)} replace />;
    }

    return (
        <div className={styles.step3}>
            <DashboardHeading
                title="Daj się poznać"
                subtitle="ocena z matematyki"
                to={backwardPathname}
                hasBorder={true}
            />
            {isDesktop && (
                <StepperWithTitle
                    steps={[
                        colors.filled,
                        isFilled ? colors.filled : colors.active,
                        colors.inactive,
                    ]}
                />
            )}

            <div className={styles.form}>
                <div className={styles.label}>
                    Wybierz ocenę otrzymaną z matematyki na ubiegłorocznym
                    świadectwie:
                </div>
                <ObjectSelectInput
                    value={form.grade}
                    className={styles.select}
                    onChange={(value) =>
                        setForm((state) => ({ ...state, grade: value }))
                    }
                    selectValues={gradesOptions}
                    isLabelCenter
                    label="Ocena z ostatniego świadectwa"
                />
                <div className={styles.label}>Matematyka to dla Ciebie:</div>
                <ObjectSelectInput
                    value={form.opinion}
                    className={styles.select}
                    onChange={(value) =>
                        setForm((state) => ({ ...state, opinion: value }))
                    }
                    selectValues={opinionOptions}
                    isLabelCenter
                    label="Matematyka to dla mnie"
                />
            </div>

            <DefaultButton
                initial={false}
                animate={{
                    backgroundColor: isFilled
                        ? DefaultButton.color.enabled
                        : DefaultButton.color.disabled,
                }}
                wrapper={styles.nextButton}
                to={`${getPreviousPath(pathname, 1)}/finish`}
                state={true}
                isDisabled={!isFilled}
            >
                PRZEJDŹ DALEJ
            </DefaultButton>
        </div>
    );
};
