const colors = {
    default: "#FFFFFF",
    gray: "#E5E7EA",
    grayDark: "#879195",
    primary: "#2DB3BE",
    success: "#15B745",
    error: "#FE5A5E",
};

const defaultShadow = "0 3px 6px rgba(0, 0, 0, .2)";

const shadow = {
    visible: defaultShadow + ", inset 0 0 0 1px rgba(45, 179, 190, 1)",
    hidden: defaultShadow + ", inset 0 0 0 1px rgba(45, 179, 190, 0)",
};

const optionVariants = {
    default: {
        background: colors.default,
        borderColor: colors.default,
        boxShadow: shadow.hidden,
    },
    default_desktop: {
        background: colors.default,
        borderColor: colors.grayDark,
        boxShadow: shadow.hidden,
    },
    selected: {
        background: colors.default,
        borderColor: colors.primary,
        boxShadow: shadow.hidden,
    },
    selected_desktop: {
        background: colors.default,
        borderColor: colors.primary,
        boxShadow: shadow.visible,
    },
    success: {
        background: colors.default,
        borderColor: colors.success,
        boxShadow: shadow.hidden,
    },
    success_answered: {
        background: colors.gray,
        borderColor: colors.success,
        boxShadow: shadow.hidden,
    },
    error: {
        background: colors.default,
        borderColor: colors.error,
        boxShadow: shadow.hidden,
    },
    error_answered: {
        background: colors.gray,
        borderColor: colors.error,
        boxShadow: shadow.hidden,
    },
};

const checkboxVariants = {
    default: {
        borderColor: colors.default,
    },
    success: {
        borderColor: colors.success,
    },
    success_answered: {
        borderColor: colors.success,
    },
    error: {
        borderColor: colors.error,
    },
    error_answered: { borderColor: colors.error },
};

const transition = { type: "tween", duration: 0.3 };

export { optionVariants, checkboxVariants, transition };
