import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

import { setAccountCookie, setRegisterCookie } from "../../../../services";

type Response = {
    email: string;
};

type Error = {
    response: {
        data?: {
            error: number;
            messages: {
                email: string;
            };
        };
    };
};

type Mutation = {
    [name: string]: FormDataEntryValue;
};

export const useRegister = () => {
    return useMutation<AxiosResponse<Response, any>, Error, Mutation, unknown>(
        (data) => {
            setAccountCookie({
                userEmail: data.email as string,
            });

            return mainApi.post("register", {
                email: data.email,
                password: data.password,
                password_confirm: data.password_confirm,
                first_name: data.first_name,
                last_name: data.last_name,
                city: data.cityId,
                type: data.type,
                ...(data.is_children === "true" && {
                    school: data.school,
                    class: data.class,
                    birthday: data.birthday,
                    parent_email: data.parent_email,
                }),
            });
        },

        {
            onSuccess: (data) => setRegisterCookie(data.data.email),
        }
    );
};
