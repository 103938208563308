import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import { useSetCompetencyTestAsDone } from "../../../competencyTest/hooks/mutations";

import { mainApi } from "../../../../api";

import { setTaskParams } from "../../services";

import { getPreviousPath } from "../../../../utils";
import { taskSlug } from "../../utils";
import { getCompetencyTestQuery } from "../../../competencyTest/utils";

type Response = {
    next_exercise_id: string | 0;
    test_id: string;
};

type Mutation = {
    taskId: string;
    testId: string;
    skipped: boolean;
};

export const useGetNextCompetencyTestTask = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { mutate: setCompetencyTestAsDone } = useSetCompetencyTestAsDone();

    return useMutation<AxiosResponse<Response, any>, any, Mutation, unknown>(
        (data) =>
            mainApi.post("get_competency_test_next_exercise", {
                id_exercise: data.taskId,
                id_test: data.testId,
                skipped: data.skipped,
            }),

        {
            onSuccess: (data) => {
                const taskId = data.data.next_exercise_id;
                const testId = data.data.test_id;

                if (taskId !== 0) {
                    setTaskParams({
                        type: taskSlug.competency_test,
                        taskId,
                        additionalId: testId,
                    });

                    navigate(`${getPreviousPath(pathname)}/${taskId}`);
                } else {
                    queryClient.removeQueries(
                        getCompetencyTestQuery().queryKey
                    );

                    setCompetencyTestAsDone();
                }
            },
        }
    );
};
