import clsx from "clsx";

import { DifficultyProps } from "./DifficultyStatus.typings";

import styles from "./DifficultyStatus.module.css";

export const DifficultyStatus = <B extends boolean = false>({
    level,
    isAdvanced,
    isHorizontal,
    isLargerOnDesktop,
}: DifficultyProps<B>) => {
    const isLv1 = level === "Łatwe" || level === "1";
    const isLv2 = level === "Średnie" || level === "2";
    const isLv3 = level === "Trudne" || level === "3";
    const isLv4 = level === "4";
    const isLv5 = level === "5";

    return (
        <span
            className={clsx(
                styles.container,
                isLargerOnDesktop && styles.large
            )}
        >
            <span className={styles.bars}>
                <span
                    className={clsx(
                        styles.bar,
                        isLv1 && !isAdvanced && styles.easy,
                        isLv2 && !isAdvanced && styles.medium,
                        isLv3 && !isAdvanced && styles.hard,
                        isAdvanced && styles.advanced,
                        (isLv1 || isLv2 || isLv3 || isLv4 || isLv5) &&
                            isAdvanced &&
                            styles.fill
                    )}
                ></span>
                <span
                    className={clsx(
                        styles.bar,
                        isLv2 && !isAdvanced && styles.medium,
                        isLv3 && !isAdvanced && styles.hard,
                        isAdvanced && styles.advanced,
                        (isLv2 || isLv3 || isLv4 || isLv5) &&
                            isAdvanced &&
                            styles.fill
                    )}
                ></span>
                <span
                    className={clsx(
                        styles.bar,
                        isLv3 && !isAdvanced && styles.hard,
                        isAdvanced && styles.advanced,
                        (isLv3 || isLv4 || isLv5) && isAdvanced && styles.fill
                    )}
                ></span>
                {isAdvanced && (
                    <>
                        <span
                            className={clsx(
                                styles.bar,

                                isAdvanced && styles.advanced,
                                (isLv4 || isLv5) && isAdvanced && styles.fill
                            )}
                        ></span>
                        <span
                            className={clsx(
                                styles.bar,
                                isAdvanced && styles.advanced,
                                isLv5 && isAdvanced && styles.fill
                            )}
                        ></span>
                    </>
                )}
            </span>
            <span
                className={clsx(
                    styles.text,
                    isLv1 && !isAdvanced && styles.easy,
                    isLv2 && !isAdvanced && styles.medium,
                    isLv3 && !isAdvanced && styles.hard,
                    isHorizontal && styles.horizontal
                )}
            >
                {isAdvanced ? "zaawansowanie" : level.toLowerCase()}
            </span>
        </span>
    );
};
