import { QueryClient } from "@tanstack/react-query";

import { getAccountCookie } from "../../../services";

import {
    getCompetencyTestQuery,
    getExamDatesQuery,
    studentGetToKnowDataQuery,
    studySettingsQuery,
} from "./queries";
import { userDataQuery } from "../../dashboard/utils";

const studentGetToKnowDataLoader = (queryClient: QueryClient) => async () => {
    const { accountType } = getAccountCookie();

    if (accountType !== "student") {
        return undefined;
    }

    const query = studentGetToKnowDataQuery();
    const query2 = userDataQuery();

    const data = queryClient.getQueryData(query.queryKey);
    const data2 = queryClient.getQueryData(query2.queryKey);

    return data && data2
        ? { data, data2 }
        : (async () => {
              await queryClient.fetchQuery(query);
              await queryClient.fetchQuery(query2);

              return null;
          })();
};

const getCompetencyTestLoader = (queryClient: QueryClient) => async () => {
    const { accountType } = getAccountCookie();

    if (accountType !== "student") {
        return undefined;
    }

    const query = getCompetencyTestQuery();

    return (
        queryClient.getQueryData(query.queryKey) ??
        queryClient.fetchQuery(query)
    );
};

const studySettingsLoader = (queryClient: QueryClient) => async () => {
    const { accountType } = getAccountCookie();

    if (accountType !== "student") {
        return undefined;
    }

    const studentGetToKnowQuery = studentGetToKnowDataQuery();
    const settingsQuery = studySettingsQuery();

    const studentGetToKnowData = queryClient.getQueryData(
        studentGetToKnowQuery.queryKey
    );
    const settingsData = queryClient.getQueryData(settingsQuery.queryKey);

    return studentGetToKnowData && settingsData
        ? { studentGetToKnowData, settingsData }
        : (async () => {
              await queryClient.fetchQuery(studentGetToKnowQuery);
              await queryClient.fetchQuery(settingsQuery);

              return null;
          })();
};

const scheduleLoader = (queryClient: QueryClient) => async () => {
    const { accountType } = getAccountCookie();

    if (accountType !== "student") {
        return undefined;
    }

    const examDatesQuery = getExamDatesQuery();
    const studentGetToKnowQuery = studentGetToKnowDataQuery();

    const examDatesData = queryClient.getQueryData(examDatesQuery.queryKey);
    const studentGetToKnowData = queryClient.getQueryData(
        studentGetToKnowQuery.queryKey
    );

    return examDatesData && studentGetToKnowData
        ? { examDatesData, studentGetToKnowData }
        : (async () => {
              await queryClient.fetchQuery(examDatesQuery);
              await queryClient.fetchQuery(studentGetToKnowQuery);

              return null;
          })();
};

export {
    studentGetToKnowDataLoader,
    getCompetencyTestLoader,
    studySettingsLoader,
    scheduleLoader,
};
