import { UseMutateFunction } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

type Mutation = {
    [name: string]: FormDataEntryValue;
};

export const handleRouteAction = async (
    request: Request,
    callback: UseMutateFunction<
        AxiosResponse<any, any>,
        unknown,
        Mutation,
        unknown
    >
) => {
    const data = Object.fromEntries(await request.formData());

    return callback(data);
};
