import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

import { useOptimisticRelations } from "../optimistic-updates";

import { resolveTimeout } from "../../../../utils";
import { childrenSubscriptionsQuery } from "../../utils";

import { ListMutation } from "../../typings";

const getMutationUrl = (action: ListMutation["action"]) => {
    switch (action) {
        case "accept":
            return "accept_child_invitation_by_parent";
        case "decline":
            return "decline_child_invite_by_parent";
        case "remove":
            return "remove_child_by_parent";
        case "invite":
            return "invite_child_by_parent";
    }
};

export const useRelationByParent = (queryKey: (string | number)[]) => {
    const queryClient = useQueryClient();
    const previousQueryData = queryClient.getQueryData(queryKey);

    const { updateOptimisticRelations, revertOptimisticRelations } =
        useOptimisticRelations(queryKey);

    return useMutation<AxiosResponse<any, any>, any, ListMutation, unknown>(
        async (data) => {
            const isAccept = data.action === "accept";
            const isRemove =
                data.action === "decline" || data.action === "remove";

            if (isAccept || isRemove) {
                updateOptimisticRelations(
                    data.email as string,
                    "children",
                    isRemove
                );

                await resolveTimeout(500);
            }

            return mainApi.post(getMutationUrl(data.action), {
                email: data.email,
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(queryKey);
                queryClient.removeQueries(childrenSubscriptionsQuery().queryKey)
            },
            onError: () => revertOptimisticRelations(previousQueryData),
        }
    );
};
