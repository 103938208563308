import { motion, AnimatePresence } from "framer-motion";

import styles from "./ErrorText.module.css";

type Props = {
    children?: string;
    isError?: boolean;
};

const Icon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="4.552"
            height="17.001"
            viewBox="0 0 4.552 17.001"
        >
            <path
                id="Path_98"
                data-name="Path 98"
                d="M.637-15.54H-2.686l-.523-11.476H1.16Zm-3.968,3.575a1.75,1.75,0,0,1,.651-1.4,2.437,2.437,0,0,1,1.625-.548,2.437,2.437,0,0,1,1.625.548,1.75,1.75,0,0,1,.651,1.4,1.75,1.75,0,0,1-.651,1.4,2.437,2.437,0,0,1-1.625.548,2.437,2.437,0,0,1-1.625-.548A1.75,1.75,0,0,1-3.331-11.964Z"
                transform="translate(3.331 27.016)"
                fill="#fe5a5e"
            />
        </svg>
    );
};

export const ErrorText = ({ children, isError }: Props) => {
    return (
        <AnimatePresence mode="wait">
            {isError && (
                <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ type: "tween", duration: 0.3 }}
                >
                    <div className={styles.message}>
                        <div className={styles.icon}>
                            <Icon />
                        </div>
                        {children}
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
