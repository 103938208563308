import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { getAccountCookie } from "../../../services";

import { useRedirectContext } from "../../../contexts/RedirectContext";

import { useMedia } from "../../../hooks";
import { useOrderRequest } from "../../dashboard/hooks";

import { DashboardHeading } from "../../../components/headings";
import { Header, HeaderDesktop } from "../../dashboard/components";
import { DefaultButton } from "../../../components/buttons";
import { CircleLoader } from "../../../components/loaders";

import { getPreviousPath } from "../../../utils";

import styles from "./StoreError.module.css";

import mobileThumbnail from "../../dashboard/images/store/header-mobile.png";
import desktopThumbnail from "../../dashboard/images/store/header-desktop.png";

export const StoreError = () => {
    const { accountType } = getAccountCookie();
    const { isDesktop } = useMedia();

    const { setRedirect } = useRedirectContext();

    const { pathname } = useLocation();
    const params = useParams();

    const childrenId = params.childrenId as string;
    const isParent = accountType === "parent";

    const { error } = useOrderRequest(childrenId, isParent);

    const headerData =
        isParent && error?.response?.statusText === "Not Found"
            ? {
                  title: "POWIĄZANIE Z DZIECKIEM NIE ZOSTAŁO ODNALEZIONE",
                  subtitle: "Wystąpił błąd",
                  text: "Powiązanie z dzieckiem nie zostało odnalezione. Upewnij się czy jesteś rodzicem dziecka. Prosimy o cofnięcie się do zakładki z abonamentami i spróbowanie ponownie.",
              }
            : {
                  title: "WYSTĄPIŁ NIEOCZEKIWANY BŁĄD",
                  subtitle: "Wystąpił błąd",
                  text: "Wystąpił błąd z serwerem. Prosimy o cierpliwość, staramy się jak najszybciej rozwiązać zaistniały problem.",
              };

    const backwardPathname = `${getPreviousPath(
        pathname,
        isParent ? 2 : 1
    )}/account/subscription`;

    useEffect(() => {
        setRedirect(backwardPathname);
    }, [backwardPathname, setRedirect]);

    return (
        <div className={styles.container}>
            <DashboardHeading
                title="SUBSKRYPCJA - BŁĄD"
                to={backwardPathname}
            />
            {!isParent || error ? (
                <>
                    {isDesktop ? (
                        <HeaderDesktop
                            data={{ ...headerData, image: desktopThumbnail }}
                        />
                    ) : (
                        <Header
                            data={{ ...headerData, image: mobileThumbnail }}
                        />
                    )}
                </>
            ) : (
                <div className={styles.loading}>
                    <CircleLoader />
                </div>
            )}
            <DefaultButton wrapper={styles.button} to={backwardPathname}>
                WRÓĆ
            </DefaultButton>
        </div>
    );
};
