import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

import { setAccountCookie } from "../../../../services";

import { userSavedCardsQuery, userSubscriptionsQuery } from "../../utils";

import { StorePaymentData } from "../../typings";

type Response = {
    message: string;
    order_placed: boolean;
    payment_success: boolean;
    tpay_url: string;
};

export const usePlaceOrder = () => {
    const queryClient = useQueryClient();

    const query = userSubscriptionsQuery();
    const query2 = userSavedCardsQuery();

    const navigate = useNavigate();

    return useMutation<
        AxiosResponse<Response, any>,
        any,
        StorePaymentData,
        unknown
    >(
        (data) =>
            mainApi.post("place_order", {
                discount_code: data.discountCode,
                sub_8_kl_id: data.primarySchoolId,
                sub_matura_podstawowa_id: data.highSchoolBasicId,
                sub_matura_rozszerzona_id: data.highSchoolExtendedId,
                sub_rozw_na_zawolanie_id: data.solutionOnDemandId,
                final_amount: data.price,
                ...(data.gpayToken && { g_pay_token: data.gpayToken }),
                ...(data.card &&
                    !data.cardSavedId && {
                        card_data: [
                            data.card.hash,
                            data.card.vendor,
                            data.card.shortcode,
                        ],
                    }),
                ...(data.cardSavedId && {
                    card_saved_id: data.cardSavedId,
                }),
            }),
        {
            onSuccess: (data, variables) => {
                if (data.data.tpay_url) {
                    window.location.href = data.data.tpay_url;
                } else {
                    if (variables.card) {
                        queryClient.removeQueries(query2.queryKey);
                    }

                    if (data.data.payment_success) {
                        setAccountCookie({ isSubscriptionPaid: "true" });

                        queryClient.removeQueries(query.queryKey);

                        navigate("/dashboard/payment-success", { state: true });
                    }
                }
            },
            onError: () => {
                navigate("/dashboard/payment-error");
            },
        }
    );
};
