import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import {
    DashboardHeading,
    StoreHeading,
} from "../../../../../components/headings";
import {
    DiscountInput,
    StoreChecker,
    StoreGrid,
    StorePayment,
    StorePrice,
} from "../../../components";

import { getAccountCookie } from "../../../../../services";

import { useAuthContext } from "../../../../../contexts/AuthContext";
import { useRedirectContext } from "../../../../../contexts/RedirectContext";

import { useMedia, useNotify } from "../../../../../hooks";
import {
    useCheckDiscountCode,
    useLoadDiscountCode,
    useOrderBanner,
    useOrderRequest,
    usePlaceChildOrder,
    usePlaceOrder,
    usePlaceOrderRequest,
    useSubscriptionOptions,
} from "../../../hooks";

import { DefaultButton } from "../../../../../components/buttons";
import { ObjectSelectInput } from "../../../../../components/inputs";
import { StoreModal } from "../../../../../components/modals";
import { ButtonLoader } from "../../../../../components/utils";

import { getOperatingSystem, getPreviousPath } from "../../../../../utils";
import { getLoadedDiscountCode, getLoadedOrderRequest } from "../../../utils";
import { getStoreOptions, getStorePrice, getStoreValidation } from "./utils";

import { StoreDataType, StoreDiscountState } from "../../../typings";
import { selectAnimations } from "./Store.animations";

import styles from "./Store.module.css";

import coins from "../../../../../assets/images/store/ikona-monety.svg";
import question from "../../../../../assets/images/store/ikona-znak-zapytania.svg";
import infinity from "../../../../../assets/images/robots/infinity-watching.svg";

export const Store = () => {
    const { accountType, hasActiveParents, isParentConfirmationRequired } =
        getAccountCookie();
    const { isDesktop } = useMedia();

    const { setRedirect } = useRedirectContext();

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const childrenId = params.childrenId;
    const isParent = accountType === "parent";
    const isStudent = accountType === "student";

    const notify = useNotify({ type: "error", position: "bottom-center" });

    const { data: orderBannerData } = useOrderBanner();
    const { data: optionsData } = useSubscriptionOptions();
    const { data: orderRequestData } = useOrderRequest(
        childrenId as string,
        isParent
    );
    const checkDiscountCode = useCheckDiscountCode();
    const { data: loadDiscountCodeData } = useLoadDiscountCode(
        childrenId as string
    );
    const { mutate: placeOrderRequest } = usePlaceOrderRequest();

    const [storeData, setStoreData] = useState<StoreDataType>(
        getLoadedOrderRequest(orderRequestData?.data.order_request)
    );
    const [discount, setDiscount] = useState<StoreDiscountState>(
        getLoadedDiscountCode(loadDiscountCodeData?.data.code)
    );
    const storePrice = getStorePrice(storeData, discount.response);

    const [isOrderRequested, setIsOrderRequested] = useState(false);

    const [isAskModalOpen, setIsAskModalOpen] = useState(false);
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    const [isCardPaymentMethod, setIsCardPaymentMethod] = useState(false);

    const { setIsStorePageDisplyed } = useAuthContext();

    const isValid = getStoreValidation(storeData);
    const isUnderage = isStudent && isParentConfirmationRequired;

    const handleOrderRequest = () => {
        setIsOrderRequested(true);

        placeOrderRequest(
            {
                discountCode: discount.response ? discount.value : undefined,
                primarySchoolId: storeData.primarySchool.values
                    ? storeData.primarySchool.values.value.id
                    : undefined,
                highSchoolBasicId:
                    storeData.highSchool.values &&
                    storeData.highSchool.selected === "basic"
                        ? storeData.highSchool.values.value.id
                        : undefined,
                highSchoolExtendedId:
                    storeData.highSchool.values &&
                    storeData.highSchool.selected === "extended"
                        ? storeData.highSchool.values.value.id
                        : undefined,
                solutionOnDemandId: storeData.solutionOnDemand.values
                    ? storeData.solutionOnDemand.values.value.id
                    : undefined,
                price: storePrice.current,
            },
            {
                onSuccess: () => {
                    setIsAskModalOpen(true);
                },
                onError: (data) => {
                    setIsOrderRequested(false);

                    notify(
                        data.response.data.messages.error ??
                            "Wystąpił błąd przy wysyłaniu prośby o płatność do rodzica."
                    );
                },
            }
        );
    };

    const backwardPathname = `${getPreviousPath(
        pathname,
        isParent ? 2 : 1
    )}/account/subscription`;

    useEffect(() => {
        setRedirect(backwardPathname);
    }, [backwardPathname, setRedirect]);

    useEffect(() => {
        return setIsStorePageDisplyed(true);
    }, []);

    const handleClick = () => {
        navigate("/dashboard");
    };

    const { mutate: placeOrder } = usePlaceOrder();
    const { mutate: placeChildOrder } = usePlaceChildOrder();

    const handleOrder = () => {
        const operatingSystem = getOperatingSystem();

        const gpayToken =
            operatingSystem === "android"
                ? (
                      document.querySelector(
                          "#gpayInput"
                      ) as HTMLInputElement | null
                  )?.value
                : null;

        const data = {
            discountCode: discount.response ? discount.value : undefined,
            primarySchoolId: storeData.primarySchool.values
                ? storeData.primarySchool.values.value.id
                : undefined,
            highSchoolBasicId:
                storeData.highSchool.values &&
                storeData.highSchool.selected === "basic"
                    ? storeData.highSchool.values.value.id
                    : undefined,
            highSchoolExtendedId:
                storeData.highSchool.values &&
                storeData.highSchool.selected === "extended"
                    ? storeData.highSchool.values.value.id
                    : undefined,
            solutionOnDemandId: storeData.solutionOnDemand.values
                ? storeData.solutionOnDemand.values.value.id
                : undefined,
            price: storePrice.current,
            userId: childrenId,
        };

        if (childrenId) {
            placeChildOrder(
                {
                    ...data,
                    childrenId: childrenId,
                    ...(gpayToken && {
                        gpayToken,
                    }),
                },
                {
                    onError: () => {
                        notify("Składanie zamówienia nie powiodło się.");
                    },
                }
            );
        } else {
            placeOrder(
                {
                    ...data,
                    ...(gpayToken && {
                        gpayToken,
                    }),
                },
                {
                    onError: () => {
                        notify("Składanie zamówienia nie powiodło się.");
                    },
                }
            );
        }
    };

    return (
        <>
            <div className={styles.container}>
                <DashboardHeading title="SUBSKRYPCJA" to={backwardPathname} />
                <div className={styles.frameContainer}>
                    <div className={styles.frame}>
                        <div
                            className={styles.frameIcon}
                            style={{ backgroundImage: `url(${question})` }}
                        ></div>
                        <h3 className={styles.frameTitle}>DLACZEGO WARTO</h3>
                        <h3 className={styles.frameTitle}>
                            <span>BYĆ PREMIUM?</span>
                        </h3>
                        <ul className={styles.list}>
                            <li>
                                Uczysz się <span>bez stresu</span>
                            </li>
                            <li>
                                Uczysz się <span>szybko i efektywnie</span>-
                                poziom zadań<br></br>dostosowany dla Ciebie!
                            </li>
                            <li>
                                Osiągniesz swój<span>wymarzony wynik</span>
                            </li>
                            <li>
                                Dajemy Ci <span>gwarancję zdania</span> egzaminu
                            </li>
                        </ul>
                    </div>

                    <div className={styles.frame}>
                        <div
                            className={styles.frameIcon}
                            style={{ backgroundImage: `url(${coins})` }}
                        ></div>
                        <h3 className={styles.frameTitle}>
                            BĄDŹ <span>SMART</span>
                        </h3>
                        <h5 className={styles.frameSubTitle}>
                            SPRÓBUJ JUŻ DZIŚ, JAK BĘDZIESZ
                        </h5>
                        <h5 className={styles.frameSubTitle}>
                            NIEZADOWOLONY ZWRÓCIMY CI KASĘ
                        </h5>
                        <p className={styles.info}>
                            Masz 30 dni na darmowy zwrot! Wystarczy, że
                            napiszesz do nas maila, a w przeciągu 7 dni dokonamy
                            zwrotu płatności.
                        </p>
                    </div>
                </div>
                {/* {orderBannerData &&
                    (isDesktop ? (
                        <HeaderDesktop
                            data={{
                                title: orderBannerData.data.title1,
                                subtitle: orderBannerData.data.title2,
                                text: orderBannerData.data.desc,
                                image: orderBannerData.data.img,
                            }}
                        />
                    ) : (
                        <Header
                            data={{
                                title: orderBannerData.data.title1,
                                subtitle: orderBannerData.data.title2,
                                text: orderBannerData.data.desc,
                                image: orderBannerData.data.img_mobile,
                            }}
                        />
                    ))} */}
                <div className={styles.main}>
                    {optionsData?.data && (
                        <>
                            <div className={styles.block}>
                                {isDesktop ? (
                                    <div className={styles.line}> </div>
                                ) : null}
                                <StoreHeading title="SZKOŁA PODSTAWOWA" />
                                <div className={styles.listContainer}>
                                    <ul className={styles.list}>
                                        <li>Dostęp do 100% materiału</li>
                                        <li>
                                            Nauka z wykorzystaniem{" "}
                                            <span>
                                                systemu personalizacji nauki,
                                            </span>{" "}
                                            ucz się tego, czego potrzebujesz
                                        </li>
                                        <li>
                                            Poziom zadań{" "}
                                            <span>
                                                dostosowany do Twojego poziomu
                                                wiedzy
                                            </span>
                                        </li>
                                        <li>
                                            Arkusze CKE i próbne z
                                            videorozwiązaniami
                                        </li>
                                    </ul>
                                    <ul className={styles.list}>
                                        <li>
                                            <span>
                                                Ponad 600 filmów z
                                                videorozwiązaniem
                                            </span>
                                        </li>
                                        <li>
                                            Teoria w w postaci videotutoriali
                                        </li>
                                        <li>
                                            Uczysz się tego czego potrzebujesz
                                        </li>
                                    </ul>
                                </div>

                                <StoreGrid>
                                    <StoreChecker
                                        label={
                                            <>
                                                KLASY 4-8
                                                <br />I EGZAMIN 8-KLASISTY
                                            </>
                                        }
                                        isChecked={
                                            storeData.primarySchool.selected
                                        }
                                        onClick={() =>
                                            setStoreData((data) => ({
                                                ...data,
                                                primarySchool: {
                                                    selected:
                                                        !data.primarySchool
                                                            .selected,
                                                    values: data.primarySchool
                                                        .selected
                                                        ? undefined
                                                        : data.primarySchool
                                                              .values,
                                                },
                                            }))
                                        }
                                    />
                                </StoreGrid>
                                <AnimatePresence mode="wait" initial={false}>
                                    {storeData.primarySchool.selected && (
                                        <motion.div {...selectAnimations}>
                                            <StoreGrid>
                                                <div className={styles.select}>
                                                    <ObjectSelectInput
                                                        label="Długość subskrypcji"
                                                        value={
                                                            storeData
                                                                .primarySchool
                                                                .values
                                                        }
                                                        selectValues={getStoreOptions(
                                                            optionsData.data
                                                                .subscriptions,
                                                            "Egzamin 8-klasisty"
                                                        )}
                                                        isLabelCenter={true}
                                                        onChange={(value) =>
                                                            setStoreData(
                                                                (data) => ({
                                                                    ...data,
                                                                    primarySchool:
                                                                        {
                                                                            selected:
                                                                                data
                                                                                    .primarySchool
                                                                                    .selected,
                                                                            values: value,
                                                                        },
                                                                })
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </StoreGrid>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                            <div className={styles.block}>
                                <div className={styles.line}> </div>
                                <StoreHeading title="SZKOŁA ŚREDNIA" />
                                <div className={styles.listContainer}>
                                    <ul className={styles.list}>
                                        <li>Dostęp do 100% materiału</li>
                                        <li>
                                            Nauka z wykorzystaniem{" "}
                                            <span>
                                                systemu personalizacji nauki,
                                            </span>{" "}
                                            ucz się tego, czego potrzebujesz
                                        </li>
                                        <li>
                                            Poziom zadań{" "}
                                            <span>
                                                dostosowany do Twojego poziomu
                                                wiedzy
                                            </span>
                                        </li>
                                        <li>
                                            Arkusze CKE i próbne z
                                            videorozwiązaniami
                                        </li>
                                    </ul>
                                    <ul className={styles.list}>
                                        <li>
                                            <span>
                                                Ponad 500 filmów z
                                                videorozwiązaniem
                                            </span>
                                        </li>
                                        <li>
                                            Teoria w w postaci videotutoriali
                                        </li>
                                        <li>
                                            Uczysz się tego czego potrzebujesz
                                        </li>
                                    </ul>
                                </div>
                                <StoreGrid>
                                    <StoreChecker
                                        label={
                                            <>
                                                SZKOŁA ŚREDNIA
                                                <br />I MATURA PODSTAWOWA
                                            </>
                                        }
                                        isChecked={
                                            storeData.highSchool.selected ===
                                            "basic"
                                        }
                                        isRadio={true}
                                        onClick={() =>
                                            setStoreData((data) => ({
                                                ...data,
                                                highSchool: {
                                                    selected:
                                                        data.highSchool
                                                            .selected ===
                                                        "basic"
                                                            ? undefined
                                                            : data.highSchool
                                                                  .selected ===
                                                              "extended"
                                                            ? "basic"
                                                            : "basic",
                                                    values: undefined,
                                                },
                                            }))
                                        }
                                    />
                                    <StoreChecker
                                        label={
                                            <>
                                                SZKOŁA ŚREDNIA
                                                <br />I MATURA ROZSZERZONA
                                            </>
                                        }
                                        isChecked={
                                            storeData.highSchool.selected ===
                                            "extended"
                                        }
                                        isRadio={true}
                                        isAvailableSoon={true}
                                        onClick={() =>
                                            setStoreData((data) => ({
                                                ...data,
                                                highSchool: {
                                                    selected:
                                                        data.highSchool
                                                            .selected ===
                                                        "extended"
                                                            ? undefined
                                                            : data.highSchool
                                                                  .selected ===
                                                              "basic"
                                                            ? "extended"
                                                            : "extended",
                                                    values: undefined,
                                                },
                                            }))
                                        }
                                    />
                                </StoreGrid>
                                <AnimatePresence mode="wait" initial={false}>
                                    {storeData.highSchool.selected && (
                                        <motion.div {...selectAnimations}>
                                            <StoreGrid>
                                                <div className={styles.select}>
                                                    <ObjectSelectInput
                                                        label="Długość subskrypcji"
                                                        value={
                                                            storeData.highSchool
                                                                .values
                                                        }
                                                        selectValues={getStoreOptions(
                                                            optionsData.data
                                                                .subscriptions,
                                                            storeData.highSchool
                                                                .selected ===
                                                                "extended"
                                                                ? "Matura rozszerzona"
                                                                : "Matura podstawowa"
                                                        )}
                                                        isLabelCenter={true}
                                                        onChange={(value) =>
                                                            setStoreData(
                                                                (data) => ({
                                                                    ...data,
                                                                    highSchool:
                                                                        {
                                                                            selected:
                                                                                data
                                                                                    .highSchool
                                                                                    .selected,
                                                                            values: value,
                                                                        },
                                                                })
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </StoreGrid>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                            {/* Temporarily removed */}
                            {/* <div className={styles.block}>
                                <div className={styles.line}> </div>
                                <StoreHeading
                                    title="ROZWIĄZANIE NA ZAWOŁANIE"
                                    subtitle="dostępne w czasie roku szkolnego (01.09-30.06)"
                                    popover={{
                                        title: "Rozwiązanie na zawołanie",
                                        slides: [
                                            <>
                                                <p>
                                                    Nie wiesz jak rozwiązać
                                                    zadanie lub nie rozumiesz
                                                    treści w książce od
                                                    matematyki?
                                                </p>
                                                <p>
                                                    Wyślij nam zdjęcie zadania,
                                                    w my odeślemy Ci video
                                                    tutorial z szczegółowym
                                                    rozwiązaniem i wyjaśnieniem.
                                                </p>
                                            </>,
                                            <p>
                                                Wyślij zadanie do 18.00 (nd, pn,
                                                wt, śr, czw), a rozwiązanie
                                                otrzymasz tego samego dnia do
                                                godziny 21.00. Odpowiedź na
                                                zadanie wysłane w piątek lub
                                                sobotę, wysyłamy najpóźniej w
                                                niedzielę do 18.00.
                                            </p>,
                                            <p>
                                                W wakacje i niektóre dni
                                                świąteczne odpoczywamy, sprawdź
                                                link:{" "}
                                                <a
                                                    href="https://smarttutor.pl/rnz-terminy/"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    https://smarttutor.pl/rnz-terminy/
                                                </a>
                                            </p>,
                                        ],
                                    }}
                                />
                                <div className={styles.listContainer}>
                                    <ul className={styles.list}>
                                        <li>
                                            <span>
                                                Masz problem z zadaniem, nie
                                                rozumiesz skąd się wziął wynik?
                                            </span>
                                        </li>
                                        <li>
                                            Zrób zdjęcie zadania i wyślij do nas
                                            za pomocą aplikacji Smart Tutor
                                            -nauka matematyki
                                        </li>
                                    </ul>
                                    <ul className={styles.list}>
                                        <li>
                                            Wyślij do 18.00, a rozwiązanie video
                                            otrzymasz tego samego dnia do 21.00
                                        </li>
                                        <li>
                                            <span>
                                                Ciesz się rozwiązanym zadaniem
                                                wraz z wytłumaczeniem
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                                <StoreGrid>
                                    <StoreChecker
                                        label="ROZWIĄZANIE NA ZAWOŁANIE"
                                        labelAddon="WAŻNE 3 MIESIĄCE"
                                        isChecked={
                                            storeData.solutionOnDemand.selected
                                        }
                                        onClick={() =>
                                            setStoreData((data) => ({
                                                ...data,
                                                solutionOnDemand: {
                                                    selected:
                                                        !data.solutionOnDemand
                                                            .selected,
                                                    values: data
                                                        .solutionOnDemand
                                                        .selected
                                                        ? undefined
                                                        : data.solutionOnDemand
                                                              .values,
                                                },
                                            }))
                                        }
                                    />
                                </StoreGrid>
                                <AnimatePresence mode="wait" initial={false}>
                                    {storeData.solutionOnDemand.selected && (
                                        <motion.div {...selectAnimations}>
                                            <StoreGrid>
                                                <div className={styles.select}>
                                                    <ObjectSelectInput
                                                        label="Ilość zadań"
                                                        value={
                                                            storeData
                                                                .solutionOnDemand
                                                                .values
                                                        }
                                                        selectValues={getStoreOptions(
                                                            optionsData.data
                                                                .subscriptions,
                                                            "Rozwiązanie na zawołanie"
                                                        )}
                                                        isLabelCenter={true}
                                                        onChange={(value) =>
                                                            setStoreData(
                                                                (data) => ({
                                                                    ...data,
                                                                    solutionOnDemand:
                                                                        {
                                                                            selected:
                                                                                data
                                                                                    .solutionOnDemand
                                                                                    .selected,
                                                                            values: value,
                                                                        },
                                                                })
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </StoreGrid>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div> */}
                        </>
                    )}
                    <div className={styles.line}> </div>
                    <StoreHeading
                        title="PODSUMOWANIE"
                        subtitle={
                            orderRequestData?.data ? (
                                <>
                                    Abonament dla:{" "}
                                    <strong>
                                        {orderRequestData.data.order_request
                                            .first_name &&
                                        orderRequestData.data.order_request
                                            .last_name
                                            ? `${orderRequestData.data.order_request.first_name} ${orderRequestData.data.order_request.last_name}`
                                            : "Brak danych"}
                                    </strong>
                                </>
                            ) : undefined
                        }
                    />
                    <StoreGrid>
                        <DiscountInput
                            title="KOD RABATOWY"
                            label="Kod rabatowy"
                            data={discount}
                            mutation={checkDiscountCode}
                            isLoaded={!!loadDiscountCodeData}
                            onChange={(value) =>
                                setDiscount((discount) => ({
                                    ...discount,
                                    value,
                                }))
                            }
                            onSuccess={(value) =>
                                setDiscount((discount) => ({
                                    ...discount,
                                    response: value,
                                }))
                            }
                        />
                    </StoreGrid>
                </div>
                <StorePrice
                    current={storePrice.current}
                    discount={storePrice.discount}
                />
                <div className={styles.buttons}>
                    {isStudent && hasActiveParents && (
                        <DefaultButton
                            wrapper={styles.button}
                            initial={{
                                backgroundColor: DefaultButton.color.white,
                                color: !isValid
                                    ? DefaultButton.color.disabled
                                    : DefaultButton.color.enabled,
                                border: !isValid
                                    ? `1px solid ${DefaultButton.color.disabled}`
                                    : `1px solid ${DefaultButton.color.enabled}`,
                            }}
                            animate={{
                                backgroundColor: DefaultButton.color.white,
                                color: !isValid
                                    ? DefaultButton.color.disabled
                                    : DefaultButton.color.enabled,
                                border: !isValid
                                    ? `1px solid ${DefaultButton.color.disabled}`
                                    : `1px solid ${DefaultButton.color.enabled}`,
                            }}
                            whileHover={{
                                opacity: 0.7,
                            }}
                            transition={{
                                type: "tween",
                                duration: 0.3,
                            }}
                            isDisabled={!isValid || isOrderRequested}
                            onClick={handleOrderRequest}
                        >
                            <ButtonLoader condition={isOrderRequested}>
                                WYŚLIJ DO RODZICA PROŚBĘ O PŁATNOŚĆ
                            </ButtonLoader>
                        </DefaultButton>
                    )}
                    {!isUnderage && (
                        <>
                            <DefaultButton
                                wrapper={styles.button}
                                initial={{
                                    backgroundColor: !isValid
                                        ? DefaultButton.color.disabled
                                        : DefaultButton.color.enabled,
                                }}
                                animate={{
                                    backgroundColor: !isValid
                                        ? DefaultButton.color.disabled
                                        : DefaultButton.color.enabled,
                                }}
                                whileHover={{
                                    opacity: 0.7,
                                }}
                                transition={{
                                    type: "tween",
                                    duration: 0.3,
                                }}
                                isDisabled={!isValid || isOrderRequested}
                                onClick={() => {
                                    if (storePrice.current === "0.00") {
                                        handleOrder();
                                    } else {
                                        setIsPaymentModalOpen(true);
                                    }
                                }}
                            >
                                KUP
                            </DefaultButton>
                        </>
                    )}
                </div>
                <div className={styles.buttonContainer}>
                    {!isDesktop ? (
                        <DefaultButton onClick={handleClick}>
                            PRZEJDŹ DO WERSJI BEZPŁATNEJ
                        </DefaultButton>
                    ) : (
                        <Link
                            className={styles.link}
                            to="/dashboard"
                        >{`PRZEJDŹ DO WERSJI BEZPŁATNEJ ->`}</Link>
                    )}
                </div>
                {!isDesktop && (
                    <div className={styles.robot}>
                        <img src={infinity} alt="" />
                    </div>
                )}
            </div>
            <StoreModal
                isCloseHidden={true}
                isOpen={isAskModalOpen}
                setIsOpen={setIsAskModalOpen}
            >
                <h3 className={styles.askTitle}>
                    Prośba o rozszerzenie
                    <br />
                    abonamentu została
                    <br />
                    przesłana do Twojego
                    <br />
                    rodzica(ów).
                </h3>
                <DefaultButton
                    wrapper={styles.askButton}
                    onClick={() => navigate(getPreviousPath(pathname))}
                >
                    WRÓĆ
                </DefaultButton>
            </StoreModal>
            {!isUnderage && (
                <StoreModal
                    isCloseHidden={isCardPaymentMethod}
                    isOpen={isPaymentModalOpen}
                    setIsOpen={setIsPaymentModalOpen}
                >
                    <StorePayment
                        accountType={accountType}
                        data={{
                            discountCode: discount.response
                                ? discount.value
                                : undefined,
                            primarySchoolId: storeData.primarySchool.values
                                ? storeData.primarySchool.values.value.id
                                : undefined,
                            highSchoolBasicId:
                                storeData.highSchool.values &&
                                storeData.highSchool.selected === "basic"
                                    ? storeData.highSchool.values.value.id
                                    : undefined,
                            highSchoolExtendedId:
                                storeData.highSchool.values &&
                                storeData.highSchool.selected === "extended"
                                    ? storeData.highSchool.values.value.id
                                    : undefined,
                            solutionOnDemandId: storeData.solutionOnDemand
                                .values
                                ? storeData.solutionOnDemand.values.value.id
                                : undefined,
                            price: storePrice.current,
                            userId: childrenId,
                        }}
                        cardPaymentMethodState={[
                            isCardPaymentMethod,
                            setIsCardPaymentMethod,
                        ]}
                    />
                </StoreModal>
            )}
        </>
    );
};
