import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

import { useUserNotifications } from "../../hooks";

import styles from "./NotificationsToggle.module.css";

type Props = {
    id?: string;
    onClick?: () => void;
};

export const NotificationsToggle = ({ id, onClick }: Props) => {
    const userNotifications = useUserNotifications();

    const notificationsCount: number = userNotifications.data
        ? userNotifications.data.data.notifications.length
        : 0;

    const isNotificationsLoading =
        !userNotifications.isFetched && userNotifications.isFetching;

    return (
        <button
            id={id}
            className={styles.button}
            type="button"
            disabled={isNotificationsLoading}
            onClick={onClick}
        >
            <span className={styles.icon}>
                <span className={clsx(styles.bell, styles.default)}></span>
                <span className={clsx(styles.bell, styles.important)}></span>
            </span>
            <AnimatePresence mode="wait" initial={false}>
                {notificationsCount !== 0 && (
                    <motion.span
                        className={styles.badge}
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.5 }}
                        transition={{ type: "tween", duration: 0.3 }}
                    >
                        {notificationsCount > 99 ? "99+" : notificationsCount}
                    </motion.span>
                )}
            </AnimatePresence>
        </button>
    );
};
