import { useState } from "react";
import { useNavigation } from "react-router-dom";
import clsx from "clsx";

import { ButtonLoader } from "../../../../components/utils";

import styles from "./MidgetButton.module.css";

import { ReactComponent as CoinIcon } from "./images/coin-icon.svg";

type Props = {
    children: string;
    onClick?: () => void;
};

export const MidgetButton = ({ children, onClick }: Props) => {
    const { state } = useNavigation();

    const [isClicked, setIsClicked] = useState(false);

    const handleOnClick = () => {
        setIsClicked(true);

        if (onClick) {
            onClick();
        }
    };

    const isLoading = isClicked && state === "loading";

    return (
        <button
            className={clsx(styles.button, isLoading && styles.loading)}
            type="button"
            disabled={isLoading}
            onClick={handleOnClick}
        >
            <span className={styles.text}>
                <ButtonLoader condition={isLoading} color="white">
                    {children}
                </ButtonLoader>
            </span>
            <span className={styles.icon}>
                <CoinIcon />
            </span>
        </button>
    );
};
