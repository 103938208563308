import { getAccountCookie } from "../../../../../../services";

import { NavigationAccountItemProps } from "../../../../components/NavigationAccountItem/NavigationAccountItem.typings";

type FunctionsType = [string, () => void][];

const parseFunctions = (key: string, functions?: FunctionsType) => {
    if (functions) {
        const filteredFunctions = functions.filter((fn) => key === fn[0])[0];
        const foundFunction = filteredFunctions
            ? filteredFunctions[1]
            : undefined;

        return foundFunction;
    } else {
        return undefined;
    }
};

export const getParsedNavigation = (
    navigation: NavigationAccountItemProps[],
    parse?: {
        active?: string;
        functions?: FunctionsType;
    }
) => {
    const { accountType } = getAccountCookie();

    return parse
        ? navigation.map((item) => {
              const fn = parseFunctions(item.children, parse.functions);
              const pathname = `/dashboard/${accountType}/account/${item.to}`;

              if (item.children === parse.active) {
                  return {
                      ...item,
                      to: pathname,
                      isActive: true,
                      ...(fn && { onClick: fn }),
                  };
              }

              return {
                  ...item,
                  to: pathname,
                  ...(fn && { onClick: fn }),
              };
          })
        : navigation;
};
