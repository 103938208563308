import clsx from "clsx";

import { validateString } from "../../../utils";

import styles from "./PasswordValidator.module.css";

type Props = {
    className?: string;
    value: string;
};

export const PasswordValidator = ({ className, value }: Props) => {
    return (
        <div className={clsx(styles.validation, className)}>
            Hasło powinno zawierać{" "}
            <span
                className={clsx(
                    styles.validator,
                    validateString("length", value) && styles.active
                )}
            >
                min. 8 znaków
            </span>
            ,<br />
            <span
                className={clsx(
                    styles.validator,
                    validateString("uppercase", value) && styles.active
                )}
            >
                WIELKĄ
            </span>{" "}
            oraz{" "}
            <span
                className={clsx(
                    styles.validator,
                    validateString("lowercase", value) && styles.active
                )}
            >
                małą literę
            </span>
            ,{" "}
            <span
                className={clsx(
                    styles.validator,
                    validateString("number", value) && styles.active
                )}
            >
                cyfrę
            </span>
            {/*{" "} i{" "}
            <span
                className={clsx(
                    styles.validator,
                    validateString("special", value) && styles.active
                )}
            >
                znak specjalny
            </span> */}
        </div>
    );
};
