import clsx from "clsx";

import styles from "./DottedLoader.module.css";

type Props = {
    color?: "white";
};

export const DottedLoader = ({ color }: Props) => {
    return (
        <span
            className={clsx(
                styles.ldsEllipsis,
                color === "white" && styles.white
            )}
        >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </span>
    );
};
