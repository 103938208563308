import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-mathtype";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import parse from "html-react-parser";
import { useEffect, useState } from "react";

import { useAnswersContext } from "../../../contexts/AnswersContext";
import { useResultContext } from "../../../contexts/ResultContext";

import { replaceOptions } from "../../../utils";
import { getMathModals, removeMathModals } from "./utils";

import styles from "./AnswerOpen.module.css";
import "./CKEditor.styles.css";

type Props = {
    id: string;
    isLoading?: boolean;
};

export const AnswerOpen = ({ id, isLoading }: Props) => {
    const { getProvidedAnswer, provideAnswer, removeProvidedAnswer } =
        useAnswersContext();
    const answer = getProvidedAnswer(id);

    const { getResult, isResultsLoading } = useResultContext();
    const result = getResult(id, "open");

    const removeModals = () => removeMathModals(getMathModals());

    useEffect(
        () => () => {
            removeModals();
        },
        []
    );

    useEffect(() => {
        if (
            (isLoading && getMathModals().dialogs.length > 0) ||
            (result && getMathModals().dialogs.length > 0)
        ) {
            removeModals();
        }
    }, [isLoading, result]);

    const isCorrect = result ? result.correct : undefined;
    const isSuccess = isCorrect === true;
    const isError = isCorrect === false;

    const [isDataSet, setIsDataSet] = useState(false);

    return (
        <div className={styles.container}>
            <p className={styles.title}>
                Wpisz poprawną odpowiedź/rozwiązanie:
            </p>
            <div
                className={clsx(
                    styles.editor,
                    isSuccess && styles.success,
                    isError && styles.error,
                    (isLoading || isCorrect || isResultsLoading || result) &&
                        styles.disabled
                )}
            >
                <div
                    className={clsx(
                        "custom-editor",
                        !!result && "is-answered",
                        isDataSet && "is-set"
                    )}
                >
                    <CKEditor
                        editor={ClassicEditor}
                        config={{
                            toolbar: {
                                items: ["MathType"],
                            },
                        }}
                        data={answer ?? ""}
                        onReady={(editor) => {
                            editor.sourceElement.nextSibling
                                .querySelector(".ck-content")
                                .addEventListener("click", () =>
                                    editor.sourceElement.nextSibling
                                        .querySelector(".ck-button")
                                        .click()
                                );

                            editor.editing.view.document.on(
                                "drop",
                                (evt: any, data: any) => {
                                    evt.stop();
                                    evt.preventDefault();

                                    data.preventDefault();
                                },
                                { priority: "high" }
                            );

                            editor.editing.view.document.on(
                                "dragover",
                                (evt: any, data: any) => {
                                    evt.stop();
                                    evt.preventDefault();

                                    data.preventDefault();
                                },
                                { priority: "high" }
                            );
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();

                            setTimeout(() => {
                                const widget =
                                    editor.sourceElement.nextSibling.querySelector(
                                        ".ck-math-widget"
                                    );
                                const formula =
                                    editor.sourceElement.nextSibling.querySelector(
                                        ".Wirisformula"
                                    );

                                if (widget && formula) {
                                    const widgetWidth = widget.clientWidth;
                                    const widgetHeight = widget.clientHeight;

                                    const formulaWidth = formula.clientWidth;
                                    const formulaHeight = formula.clientHeight;

                                    const paddingX = widgetWidth - formulaWidth;
                                    const paddingY =
                                        widgetHeight - formulaHeight;

                                    formula.style.padding = `${
                                        paddingY / 2
                                    }px ${paddingX / 2}px`;
                                }
                            }, 0);

                            if (data.length > 0) {
                                setIsDataSet(true);

                                provideAnswer({
                                    id,
                                    type: "open",
                                    value: data,
                                });
                            } else {
                                setIsDataSet(false);

                                removeProvidedAnswer(id);
                            }
                        }}
                    />
                </div>
            </div>
            <AnimatePresence initial={false}>
                {result && result.answers && (
                    <motion.div
                        className={styles.answers}
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        transition={{ type: "tween", duration: 0.3 }}
                    >
                        <div className={styles.body}>
                            <p className={styles.title}>
                                Dopuszczalne poprawne odpowiedzi:
                            </p>
                            <div className={styles.grid}>
                                {result.answers.map((answer) => (
                                    <div key={answer} className={styles.column}>
                                        <div className={styles.answer}>
                                            {parse(answer, replaceOptions())}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};
