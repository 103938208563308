import clsx from "clsx";

import { SubscriptionPackageProps } from "./SubscriptionPackage.typings";

import styles from "./SubscriptionPackage.module.css";

export const SubscriptionPackage = ({
    children,
    active,
    isBorderless
}: SubscriptionPackageProps) => (
    <div className={clsx(styles.container, isBorderless && styles.borderless)}>
        <div className={styles.main}>{children}</div>
        <div className={styles.aside}>
            <p className={clsx(styles.status, !active && styles.inactive)}>
                {active ?? "nieaktywny"}
            </p>
        </div>
    </div>
);
