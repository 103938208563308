export const setDropPosition = (
    event: MouseEvent,
    bounds: DOMRect,
    fieldPosition: { x: number; y: number }
) => {
    return {
        x: +(event.clientX - bounds.x - fieldPosition.x).toFixed(4),
        y: +(event.clientY - bounds.y - fieldPosition.y).toFixed(4),
    };
};
