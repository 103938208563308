import { forwardRef } from "react";

import styles from "./UtilsButton.module.css";

type Props = {
    children: string;
    icon: string;
    isDisabled?: boolean;
    onClick?: () => void;
};

export const UtilsButton = forwardRef(
    (props: Props, ref: React.ForwardedRef<HTMLButtonElement>) => {
        const { children, icon, isDisabled, onClick } = props;

        return (
            <button
                ref={ref}
                className={styles.button}
                type="button"
                disabled={isDisabled}
                onClick={onClick}
            >
                {children}
                <span className={styles.icon}>
                    <img src={icon} alt="" />
                </span>
            </button>
        );
    }
);
