import clsx from "clsx";

import styles from "./DefaultTextarea.module.css";

type Props = {
    className?: string;
    placeholder?: string;
    value?: string;
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
    isDisabled?: boolean;
};
export const DefaultTextarea = ({
    className,
    placeholder,
    value,
    onChange,
    isDisabled,
}: Props) => (
    <textarea
        className={clsx(styles.defaultTextarea, className)}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={isDisabled}
    />
);
