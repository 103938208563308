import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { aiSessionResultQuery } from "../../utils";

import { DifficultyLevels } from "../../typings";

type Response = {
    percentage_result: number;
    exercises: {
        id_exercise: string;
        answered_right: "0" | "1";
        difficulty: DifficultyLevels["standard"];
    }[];
};

export const useAiSessionResult = (sessionId: string) => {
    return useQuery<AxiosResponse<Response, any>>(
        aiSessionResultQuery(sessionId)
    );
};
