import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { orderRequestQuery } from "../../utils";

type RequestPackage =
    | {
          id: string;
          price: string;
          label: string;
      }
    | "";

export type OrderRequestResponse = {
    order_request: {
        id: string;
        discount_code: string;
        final_amount: string;
        first_name: string;
        last_name: string;
        sub_8_kl_id: RequestPackage;
        sub_matura_podstawowa_id: RequestPackage;
        sub_matura_rozszerzona_id: RequestPackage;
        sub_rozw_na_zawolanie_id: RequestPackage;
    };
};

export const useOrderRequest = (childrenId: string, isEnabled?: boolean) => {
    return useQuery<AxiosResponse<OrderRequestResponse, any>, any>(
        orderRequestQuery(childrenId, isEnabled)
    );
};
