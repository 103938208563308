import { QueryClient } from "@tanstack/react-query";
import { LoaderFunctionArgs } from "react-router-dom";

import { getAccountCookie } from "../../../services";

import { examTaskDetailsQuery, examTasksQuery } from "./queries";

export const examTasksLoader = (queryClient: QueryClient) => async () => {
    const { accountType } = getAccountCookie();

    if (accountType !== "student") {
        return undefined;
    }

    const query = examTasksQuery();

    return (
        queryClient.getQueryData(query.queryKey) ??
        queryClient.fetchQuery(query)
    );
};

export const examTasksDetailsLoader =
    (queryClient: QueryClient) =>
    async ({ params }: LoaderFunctionArgs) => {
        const { accountType } = getAccountCookie();

        if (accountType !== "student") {
            return undefined;
        }

        const query = examTaskDetailsQuery(params.examId!);

        return (
            queryClient.getQueryData(query.queryKey) ??
            queryClient.fetchQuery(query)
        );
    };
