import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

import { useUserNotifications } from "../../hooks";

import { NotificationsToggle } from "../NotificationsToggle";
import { NotificationSlide } from "../NotificationSlide";

import { animationProps } from "./Notifications.animations";

import styles from "./Notifications.module.css";
import {
    userImportantNotificationQuery,
    userNotificationsQuery,
} from "../../utils";
import { getOperatingSystem } from "../../../../utils";

type Props = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Notifications = ({ isOpen, setIsOpen }: Props) => {
    const { pathname } = useLocation();
    const prevPathname = useRef(pathname);

    const { state } = useNavigation();
    const isLoadingState = state === "loading";

    const userNotifications = useUserNotifications();
    const notifications = userNotifications.data
        ? userNotifications.data.data.notifications
        : [];

    const userNotificationsQueryKey = userNotificationsQuery().queryKey;
    const userImportantNotificationQueryKey =
        userImportantNotificationQuery().queryKey;

    const [isRedirecting, setIsRedirecting] = useState(false);

    const operatingSystem = getOperatingSystem();

    useEffect(() => {
        const closeRedirectTime = 500;

        if (prevPathname.current !== pathname) {
            setTimeout(() => {
                setIsOpen(false);
            }, closeRedirectTime);

            setTimeout(() => {
                setIsRedirecting(false);
            }, closeRedirectTime * 2);
        }

        prevPathname.current = pathname;
    }, [pathname, setIsOpen]);

    return (
        <div className={clsx(styles.body, operatingSystem && styles.fallback)}>
            <div className={styles.heading}>
                <p className={styles.title}>POWIADOMIENIA</p>
                <div className={styles.toggle}>
                    <NotificationsToggle
                        id={isOpen ? "notificationsToggle" : undefined}
                        onClick={() => setIsOpen(false)}
                    />
                </div>
            </div>
            <hr className={clsx(styles.border, styles.top)} />
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <AnimatePresence mode="wait" initial={false}>
                        {isOpen && (
                            <motion.div
                                className={styles.list}
                                {...animationProps}
                            >
                                <AnimatePresence mode="sync" initial={false}>
                                    {notifications.length > 0 ? (
                                        notifications.map((notification) => (
                                            <motion.div
                                                key={notification.id}
                                                {...animationProps}
                                            >
                                                <NotificationSlide
                                                    id={notification.id}
                                                    type={notification.type}
                                                    body={notification.title}
                                                    url={notification.url}
                                                    createdAt={notification.created_at}
                                                    queryKeys={[
                                                        userNotificationsQueryKey,
                                                        userImportantNotificationQueryKey,
                                                    ]}
                                                    isLoading={isLoadingState}
                                                    isRedirecting={
                                                        isRedirecting
                                                    }
                                                    setIsOpen={setIsOpen}
                                                    setIsRedirecting={
                                                        setIsRedirecting
                                                    }
                                                />
                                            </motion.div>
                                        ))
                                    ) : (
                                        <motion.div
                                            key="notifications_empty"
                                            className={styles.empty}
                                            {...animationProps}
                                        >
                                            Brak powiadomień
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </div>
            <hr className={clsx(styles.border, styles.bottom)} />
        </div>
    );
};
