import { LoaderFunctionArgs } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

import { getAccountCookie } from "../../../../services";

import { learningProgressSubcategoryQuery } from "../../utils";

export const learningProgressSubcategoryLoader =
    (queryClient: QueryClient) =>
    async ({ params }: LoaderFunctionArgs) => {
        const { accountType } = getAccountCookie();

        const classType = params.classType;
        const subcategoryId = params.subcategoryId;

        if (accountType !== "student" || !classType || !subcategoryId) {
            return undefined;
        }

        const query = learningProgressSubcategoryQuery(
            "student",
            classType,
            subcategoryId
        );

        return (
            queryClient.getQueryData(query.queryKey) ??
            queryClient.fetchQuery(query)
        );
    };
