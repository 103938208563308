import { ReactQueryWrapper } from "./ReactQueryWrapper";
import { LoadingProvider } from "../../contexts/LoadingContext";
import { AuthProvider } from "../../contexts/AuthContext";
import { PreloadContentWrapper } from "./PreloadContentWrapper";
import { RedirectProvider } from "../../contexts/RedirectContext";

type Props = {
    children: JSX.Element;
};

export const MainWrapper = ({ children }: Props) => (
    <ReactQueryWrapper>
        <LoadingProvider>
            <AuthProvider>
                <RedirectProvider>
                    <PreloadContentWrapper>{children}</PreloadContentWrapper>
                </RedirectProvider>
            </AuthProvider>
        </LoadingProvider>
    </ReactQueryWrapper>
);
