import { useParams } from "react-router-dom";
import { romanize } from "romans";

import { useLearningProgressSubcategory } from "../../../hooks";

import {
    DashboardHeading,
    TaskHeading,
} from "../../../../../components/headings";
import {
    NavigationBar,
    TasksLearningProgress,
} from "../../../../tasks/components";

import schoolIcon from "../../../images/learning-progress/school-icon.svg";

export const StudentProgressSubcategory = () => {
    const params = useParams();

    const childrenId = params.childrenId as string;
    const classType = params.classType as string;
    const subcategoryId = params.subcategoryId as string;

    const { data } = useLearningProgressSubcategory(
        "other",
        classType,
        subcategoryId,
        childrenId
    );
    return (
        <>
            {data && (
                <>
                    <DashboardHeading
                        title={data.data.category_name}
                        icon={data.data.category_image_2}
                        userClass={
                            data.data.class === "matura_podstawowa" ||
                            data.data.class === "matura_rozszerzona" ||
                            data.data.class === "8_kl" ? (
                                <img src={schoolIcon} alt="" />
                            ) : (
                                romanize(parseInt(data.data.class))
                            )
                        }
                        hasSmallSpacing={true}
                    />
                    <TaskHeading
                        title={data.data.subcategory_name.toUpperCase()}
                    />
                    <TasksLearningProgress
                        progress={{
                            good: data.data.progress.done_right,
                            bad: data.data.progress.done_wrong,
                            total: data.data.progress.total,
                        }}
                        tasks={data.data.exercises}
                    />
                    <NavigationBar
                        backward={{
                            children: "WRÓĆ DO LISTY TEMATÓW",
                        }}
                    />
                </>
            )}
        </>
    );
};
