import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Navigate, Outlet, useLocation, useOutlet } from "react-router-dom";

import { getAccountCookie } from "../../../../../services";

import { useIsHomeDashboard, useMedia } from "../../../../../hooks";
import { useDeleteAccountContext } from "../../Dashboard";

import {
    useTeacherStudents,
    useRelationByTeacher,
    useStorageFlag,
    useUserImportantNotification,
} from "../../../hooks";

import { DashboardContainer } from "../../../../../components/containers";
import { WideHeading } from "../../../../../components/headings";
import {
    Brand,
    Students,
    Navbar,
    InviteInput,
    Notifications,
    NotificationsDesktop,
    NavbarDesktop,
    HomeWrapper,
    NotificationsBackdrop,
    NotificationAlert,
    NotificationDemo,
} from "../../../components";
import { CircleLoader } from "../../../../../components/loaders";
import { BackgroundContainer } from "../../../../../components/containers";
import { VideoModal } from "../../../../../components/modals";

import { teacherStudentsQuery } from "../../../utils";
import { getClearPath } from "../../../../../utils";

import styles from "./DashboardTeacher.module.css";

import infinity from "../../../images/dashboard/robot-infinity.svg";

export const DashboardTeacher = () => {
    const { accountType, isAccountDisabled, isVideoWatched } =
        getAccountCookie();
    const { isDesktop, isSmallerDesktop } = useMedia();

    const outlet = useOutlet();
    const deleteAccountContext = useDeleteAccountContext();
    const userImportantNotification = useUserImportantNotification();

    const { pathname } = useLocation();
    const isDashboardInView = getClearPath(pathname) === "/dashboard/teacher";
    const isHome = useIsHomeDashboard();

    const queryKey = teacherStudentsQuery().queryKey;
    const queryResult = useTeacherStudents(
        isDashboardInView,
        isDashboardInView
    );
    const mutation = useRelationByTeacher(queryKey);

    const [isQueryLoaded, setIsQueryLoaded] = useState(
        queryResult.data ? true : false
    );
    const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
    useStorageFlag(["popOpen", "notifications"], isNotificationsOpen);
    const [inviteValue, setInviteValue] = useState("");

    useEffect(() => {
        if (queryResult.data && !isQueryLoaded) {
            setTimeout(() => {
                setIsQueryLoaded(true);
            }, 1000);
        }
    }, [queryResult.data, isQueryLoaded]);

    const [isVideoModalOpen, setIsVideoModalOpen] = useState(!isVideoWatched);

    if (isAccountDisabled) {
        return <Navigate to={`/dashboard/disabled`} replace={true} />;
    }

    if (accountType !== "teacher") {
        return <Navigate to={`/dashboard/${accountType}`} replace={true} />;
    }

    return (
        <>
            <DashboardContainer
                outsideElement={
                    isDesktop ? (
                        <>
                            <NavbarDesktop>
                                <NotificationsDesktop
                                    isOpen={isNotificationsOpen}
                                    setIsOpen={setIsNotificationsOpen}
                                />
                            </NavbarDesktop>
                            {isHome && (
                                <WideHeading className={styles.heading}>
                                    PANEL NAUCZYCIELA
                                </WideHeading>
                            )}
                        </>
                    ) : (
                        <Navbar
                            isCollapsed={isNotificationsOpen}
                            setIsCollapsed={setIsNotificationsOpen}
                        >
                            <Notifications
                                isOpen={isNotificationsOpen}
                                setIsOpen={setIsNotificationsOpen}
                            />
                        </Navbar>
                    )
                }
            >
                {outlet ? (
                    <Outlet context={deleteAccountContext} />
                ) : (
                    <BackgroundContainer
                        leftElement={{
                            image: infinity,
                            position: {
                                x: isSmallerDesktop ? 90 : 180,
                                y: -44,
                            },
                            size: { height: 458, width: 222 },
                            isBottom: true,
                        }}
                    >
                        <HomeWrapper>
                            <div className={styles.container}>
                                {!isDesktop && (
                                    <Brand
                                        onClick={() =>
                                            setIsNotificationsOpen(true)
                                        }
                                    />
                                )}
                                <NotificationAlert
                                    result={userImportantNotification}
                                />
                                <InviteInput
                                    className={styles.input}
                                    title="DODAJ UCZNIA"
                                    label="E-mail ucznia"
                                    value={inviteValue}
                                    mutation={mutation}
                                    onChange={(value) => setInviteValue(value)}
                                />
                                <AnimatePresence mode="wait" initial={false}>
                                    {isQueryLoaded ? (
                                        <motion.div
                                            key="students"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{
                                                type: "tween",
                                                duration: 0.3,
                                            }}
                                        >
                                            <Students
                                                data={{
                                                    response: queryResult.data,
                                                    key: "students",
                                                }}
                                                emptyText={{
                                                    initial:
                                                        "Brak uczniów do wyświetlenia",
                                                    search: "Nie znaleziono wyszukiwanego ucznia",
                                                }}
                                                mutation={mutation}
                                                isDashboard={true}
                                                isLargerOnDesktop={true}
                                            />
                                        </motion.div>
                                    ) : (
                                        <motion.div
                                            key="students_loading"
                                            className={styles.loading}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{
                                                type: "tween",
                                                duration: 0.3,
                                            }}
                                        >
                                            <p>Trwa wczytywanie modułu</p>
                                            <CircleLoader />
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        </HomeWrapper>
                    </BackgroundContainer>
                )}
            </DashboardContainer>
            {!isDesktop && (
                <NotificationsBackdrop isOpen={isNotificationsOpen} />
            )}
            <VideoModal
                isOpen={isVideoModalOpen}
                setIsOpen={setIsVideoModalOpen}
            />
        </>
    );
};
