import { useQueryClient } from "@tanstack/react-query";

import { getAccountCookie } from "../../../../../services";

import {
    useChildrenParents,
    useChildrenTeachers,
    useParentChildrens,
    useTeacherStudents,
} from "../../queries";

import {
    childrenParentsQuery,
    childrenTeachersQuery,
    parentChildrensQuery,
    teacherStudentsQuery,
} from "../../../utils";
import { getNavigation, getParsedNavigation } from "./utils";

export const useNavigationAccount = (activeElement?: string) => {
    const { userId, accountType } = getAccountCookie();

    const queryClient = useQueryClient();

    const childrenParents = useChildrenParents(false);
    const childrenTeachers = useChildrenTeachers(false);
    const parentsChildren = useParentChildrens(false);
    const teacherStudents = useTeacherStudents(false);

    const removeParentsQuery = () => {
        if (childrenParents.isStale || childrenTeachers.isStale) {
            queryClient.removeQueries(childrenParentsQuery().queryKey);
            queryClient.removeQueries(childrenTeachersQuery().queryKey);
        }
    };

    const removeChildrensQuery = () => {
        if (parentsChildren.isStale) {
            queryClient.removeQueries(parentChildrensQuery().queryKey);
        }
    };

    const removeStudentsQuery = () => {
        if (teacherStudents.isStale) {
            queryClient.removeQueries(teacherStudentsQuery().queryKey);
        }
    };

    return getParsedNavigation(getNavigation(accountType, userId), {
        active: activeElement,
        functions:
            accountType === "student"
                ? [["Opiekunowie", removeParentsQuery]]
                : accountType === "parent"
                ? [
                      ["Dzieci", removeChildrensQuery],
                      ["Usunięcie konta", removeChildrensQuery],
                  ]
                : accountType === "teacher"
                ? [["Uczniowie", removeStudentsQuery]]
                : undefined,
    });
};
