import clsx from "clsx";

import styles from "./WideHeading.module.css";

type Props = {
    children: string;
    className?: string;
};

export const WideHeading = ({ children, className }: Props) => {
    return (
        <div className={clsx(styles.container, className)}>
            <div className={styles.wrapper}>
                <h2 className={styles.title}>{children}</h2>
            </div>
        </div>
    );
};
