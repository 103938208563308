const userClassLabel = "Zadanie dla klasy";

/*
    [object key]: [object value (route name)] - description

    default: "base" - podstawowe zadania z bazy zadań
    exercise: "exam" - zadania egzaminacyjne
    random: "random" - losowe zadania wygenerowane przez backend na podstawie zadan egzaminacyjnych
    competency_test: "competency-test" - zadania, które uczeń musi zrobić, żeby rozpocząć planowanie nauki
    ai: "ai" - zadania wygenerowane przez sztuczną inteligencję
    exercise_ai: "ai-exam" - egzaminy wygenerowany przez sztuczną inteligencję
*/

const taskSlug = {
    default: "base" as const,
    exercise: "exam" as const,
    random: "random" as const,
    competency_test: "competency-test" as const,
    ai: "ai" as const,
    learning_progress: "learning-progress" as const,
};

const taskChangeAnimations = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { type: "tween", duration: 0.2 },
};

export { userClassLabel, taskSlug, taskChangeAnimations };
