import { Form, Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getInvitationCookie, getRegisterCookie } from "../../../../services";

import { useLoadingContext } from "../../../../contexts/LoadingContext";

import { useMedia, useSubmitForm } from "../../../../hooks";
import { useRegister } from "../../hooks";

import { ArrowButton, DefaultButton } from "../../../../components/buttons";
import {
    AuthContainer,
    ColumnContainer,
} from "../../../../components/containers";
import { IconHeading } from "../../../../components/headings";
import {
    DateInput,
    DefaultCheckbox,
    DefaultInput,
    SearchInput,
    SelectInput,
} from "../../../../components/inputs";
import { ErrorText } from "../../../../components/texts";
import { PasswordValidator, Popper } from "../../../../components/utils";

import { getClassList } from "../../../../utils";
import {
    getAccountType,
    getResponseMessage,
    registerStudentSchema,
} from "../../utils";

import { SchoolType } from "../../../../typings";

import styles from "./RegisterStudent.module.css";

import { Link } from "react-router-dom";
import pi from "../../../../assets/images/robots/pi-blush.svg";

const AnimatedForm = motion(Form);

type Props = {
    mutation: ReturnType<typeof useRegister>;
};

export const RegisterStudent = ({ mutation }: Props) => {
    const registerCookie = getRegisterCookie();
    const invitationCookie = getInvitationCookie();

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isDesktop } = useMedia();

    const submitForm = useSubmitForm();
    const { setLoadingActive } = useLoadingContext();

    const isFormSubmitted = useRef(false);
    const [errorResponse, setErrorResponse] = useState(["", ""]);
    const [isClassTouched, setIsClassTouched] = useState(false);

    const [submitButtonRef, setSubmitButtonRef] = useState<Element | null>(
        null
    );
    const [isValidationError, setIsValidationError] = useState([false, false]);

    useEffect(() => {
        if (isValidationError[1]) {
            setTimeout(() => {
                setIsValidationError([true, false]);
            }, 2400);
        }
    }, [isValidationError]);

    useEffect(() => {
        if (mutation.error && isFormSubmitted.current) {
            isFormSubmitted.current = false;

            setLoadingActive(false, () => {
                setErrorResponse(
                    mutation.error.response.data
                        ? getResponseMessage(
                            mutation.error.response.data.messages,
                            true
                        )
                        : ["Przepraszamy, wystąpił błąd z serwerem", "api"]
                );

                document
                    .querySelector("#mainAppContainer")!
                    .scrollTo({ top: 0, behavior: "smooth" });
            });
        }
    }, [mutation, setLoadingActive]);

    useEffect(() => {
        if (errorResponse[1] === "api") {
            setTimeout(() => {
                setErrorResponse(["", ""]);
            }, 6300);
        }
    }, [errorResponse]);

    useEffect(() => {
        if (mutation.isSuccess && registerCookie) {
            setLoadingActive(false, () => {
                navigate("/register/confirm");

                mutation.reset();
            });
        }
    }, [mutation, registerCookie, setLoadingActive, navigate]);

    return (
        <>
            <ArrowButton to="/" wrapper={styles.back}>
                Wstecz
            </ArrowButton>
            <AuthContainer
                position="top"
                image={isDesktop ? pi : undefined}
                imagePosition="right"
                imageSize={isDesktop ? 285 : undefined}
                imageOffsetX={isDesktop ? 78 : undefined}
                imageOffsetY={isDesktop ? 15.5556 : undefined}
            >
                <IconHeading iconType="student">REJESTRACJA UCZNIA</IconHeading>
                <Formik
                    initialValues={{
                        email: invitationCookie ? invitationCookie[0] : "",
                        password: "",
                        password_confirm: "",
                        birthday: "",
                        school: undefined as SchoolType | undefined,
                        class: "",
                        city: { id: "", name: "" },
                        first_name: "",
                        last_name: "",
                        parent_email: "",
                        agreement: false,
                    }}
                    validationSchema={registerStudentSchema}
                    onSubmit={(data, actions) => {
                        isFormSubmitted.current = true;

                        setLoadingActive(true, () => {
                            submitForm(data, {
                                type: getAccountType(pathname),
                                is_children: "true",
                            });

                            actions.setSubmitting(false);
                        });
                    }}
                >
                    {(formik) => (
                        <AnimatedForm
                            className={styles.form}
                            initial={{
                                paddingTop: "5px",
                            }}
                            animate={{
                                paddingTop: errorResponse[0] ? "30px" : "5px",
                            }}
                            transition={{ type: "tween", duration: 0.3 }}
                        >
                            <ErrorText isError={!!errorResponse[0]}>
                                {errorResponse[0]}
                            </ErrorText>
                            <DefaultInput
                                label="E-mail"
                                name="email"
                                type="email"
                                isError={errorResponse[1] === "email"}
                                isBlankError={isValidationError[0]}
                                isDisabled={invitationCookie ? true : false}
                                onChange={() =>
                                    errorResponse[1] === "email"
                                        ? setErrorResponse(["", ""])
                                        : undefined
                                }
                            />
                            <DefaultInput
                                label="Hasło"
                                name="password"
                                type="password"
                                isError={errorResponse[1] === "password"}
                                isBlankError={isValidationError[0]}
                                onChange={() =>
                                    errorResponse[1] === "password"
                                        ? setErrorResponse(["", ""])
                                        : undefined
                                }
                            />
                            <DefaultInput
                                label="Powtórz hasło"
                                name="password_confirm"
                                type="password"
                                isError={errorResponse[1] === "password"}
                                isBlankError={isValidationError[0]}
                                onChange={() =>
                                    errorResponse[1] === "password"
                                        ? setErrorResponse(["", ""])
                                        : undefined
                                }
                            />
                            <PasswordValidator
                                className={styles.validator}
                                value={formik.values.password}
                            />
                            <DateInput
                                name="birthday"
                                isBlankError={isValidationError[0]}
                            />
                            <ColumnContainer className={styles.inputGroup}>
                                <SelectInput
                                    value={formik.values.school}
                                    selectValues={[
                                        "Podstawowa",
                                        "Liceum",
                                        "Technikum",
                                        "Branżowa",
                                    ]}
                                    label="Szkoła"
                                    isBlankError={isValidationError[0]}
                                    onChange={(value) =>
                                        formik.setValues(
                                            {
                                                ...formik.values,
                                                class: "",
                                                school: value,
                                            },
                                            true
                                        )
                                    }
                                />
                                <SelectInput
                                    value={formik.values.class}
                                    selectValues={
                                        formik.values.school
                                            ? getClassList(formik.values.school)
                                            : undefined
                                    }
                                    label="Klasa"
                                    isError={
                                        isClassTouched && !formik.values.class
                                    }
                                    isBlankError={isValidationError[0]}
                                    isDisabled={!formik.values.school}
                                    onChange={(value) => {
                                        if (!isClassTouched) {
                                            setIsClassTouched(true);
                                        }

                                        formik.setFieldValue(
                                            "class",
                                            value,
                                            true
                                        );
                                    }}
                                />
                            </ColumnContainer>
                            <SearchInput
                                label="Miejscowość"
                                name="city"
                                query={{
                                    key: ["city_search"],
                                    url: "/getSelectCities",
                                    name: "NAZWA",
                                    nameFn: (value) =>
                                        value.NAZWA + " (" + value.GMI + ")",
                                }}
                                isBlankError={isValidationError[0]}
                                onChange={(value) =>
                                    formik.setFieldValue("city", value, true)
                                }
                            />
                            <DefaultInput
                                label="Imię"
                                name="first_name"
                                type="text"
                                isError={errorResponse[1] === "first_name"}
                                isBlankError={isValidationError[0]}
                                onChange={() =>
                                    errorResponse[1] === "first_name"
                                        ? setErrorResponse(["", ""])
                                        : undefined
                                }
                            />
                            <DefaultInput
                                label="Nazwisko"
                                name="last_name"
                                type="text"
                                isError={errorResponse[1] === "last_name"}
                                isBlankError={isValidationError[0]}
                                onChange={() =>
                                    errorResponse[1] === "last_name"
                                        ? setErrorResponse(["", ""])
                                        : undefined
                                }
                            />
                            <AnimatePresence mode="wait">
                                {(formik.errors.parent_email ||
                                    (!formik.errors.parent_email &&
                                        formik.getFieldMeta("parent_email")
                                            .value !== "")) && (
                                        <motion.div
                                            initial={{
                                                height: 0,
                                                opacity: 0,
                                                marginTop: 0,
                                            }}
                                            animate={{
                                                height: "auto",
                                                opacity: 1,
                                                marginTop: "25px",
                                            }}
                                            exit={{
                                                height: 0,
                                                opacity: 0,
                                                marginTop: 0,
                                            }}
                                            transition={{
                                                type: "tween",
                                                duration: 0.3,
                                            }}
                                        >
                                            <DefaultInput
                                                label="E-mail rodzica"
                                                name="parent_email"
                                                type="email"
                                                isError={
                                                    errorResponse[1] ===
                                                    "parent_email"
                                                }
                                                isBlankError={!isValidationError[0]}
                                                isMarginless={true}
                                                onChange={() =>
                                                    errorResponse[1] ===
                                                        "parent_email"
                                                        ? setErrorResponse(["", ""])
                                                        : undefined
                                                }
                                            />
                                        </motion.div>
                                    )}
                            </AnimatePresence>
                            <DefaultCheckbox
                                label={
                                    <>
                                        Akceptuję{" "}
                                        <Link to="terms-of-use">
                                            regulamin
                                        </Link>{" "}
                                        oraz{" "}
                                        <Link to="privacy-policy">
                                            politykę prywatności
                                        </Link>
                                    </>
                                }
                                name="agreement"
                                type="checkbox"
                                isBlankError={isValidationError[0]}
                            />
                            <DefaultButton
                                ref={setSubmitButtonRef}
                                wrapper={styles.formButton}
                                initial={{
                                    backgroundColor:
                                        DefaultButton.color.disabled,
                                }}
                                animate={{
                                    backgroundColor:
                                        !!errorResponse[0] ||
                                            !formik.dirty ||
                                            !formik.isValid ||
                                            formik.isSubmitting
                                            ? DefaultButton.color.disabled
                                            : DefaultButton.color.enabled,
                                }}
                                whileHover={{
                                    opacity:
                                        !formik.dirty ||
                                            !formik.isValid ||
                                            formik.isSubmitting
                                            ? 1
                                            : 0.7,
                                }}
                                transition={{ type: "tween", duration: 0.3 }}
                                isSubmit={
                                    formik.dirty || formik.isValid
                                        ? true
                                        : false
                                }
                                onClick={
                                    !formik.dirty || !formik.isValid
                                        ? () =>
                                            setIsValidationError([true, true])
                                        : undefined
                                }
                                isDisabled={
                                    !!errorResponse[0] ||
                                    formik.isSubmitting ||
                                    isValidationError[1]
                                }
                            >
                                ZAREJESTRUJ
                            </DefaultButton>
                        </AnimatedForm>
                    )}
                </Formik>
            </AuthContainer>
            <Popper
                referenceElement={submitButtonRef}
                placement="top"
                popStyle="error"
                isActive={isValidationError[1]}
            >
                Wypełnij wszystkie wymagane pola
            </Popper>
        </>
    );
};
