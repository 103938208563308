import { Navigate } from "react-router-dom";

import { useMedia } from "../../../../hooks";

import { DefaultButton } from "../../../../components/buttons";
import { MainHeading } from "../../../../components/headings";

import styles from "./Step1.module.css";

export const Step1 = () => {
    const { isDesktop } = useMedia();

    if (isDesktop) {
        return <Navigate to="target" replace={true} state={true} />;
    }

    return (
        <div className={styles.step1}>
            <MainHeading elementType="h2" className={styles.heading}>
                Daj się poznać
            </MainHeading>
            <div className={styles.subHeading}>
                Dzięki Twoim odpowiedziom nasza Sztuczna Inteligencja opracuje
                optymalny tok nauczania, żeby Twoja nauka była
                najskuteczniejsza.
            </div>
            <DefaultButton wrapper={styles.nextButton} to="target" state={true} >
                START
            </DefaultButton>
        </div>
    );
};
