import { useState, useRef, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

import { useStorageFlag } from "../../hooks";

import { IconButton } from "../../../../components/buttons";
import { DefaultModal } from "../../../../components/modals";

import { ListMutationResult } from "../../typings";

import styles from "./UserListItem.module.css";

type Props = {
    children: JSX.Element | string;
    email: string;
    childrenEmail?: string;
    mutation: ListMutationResult;
    modalText?: string;
    isInvited?: boolean;
    isUnconfirmed?: boolean;
    isRemoveDisabled?: boolean;
};

export const UserListItem = ({
    children,
    email,
    childrenEmail,
    mutation,
    modalText,
    isInvited,
    isUnconfirmed,
    isRemoveDisabled,
}: Props) => {
    const isMutated = useRef(mutation.isError ?? false);
    const [isError, setIsError] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    useStorageFlag(["popOpen", "modal"], isModalOpen);

    const handleAcceptMutation = () => {
        isMutated.current = true;

        mutation.mutate({
            action: "accept",
            email,
            ...(childrenEmail && { child_email: childrenEmail }),
        });
    };

    const handleRemoveMutation = () => {
        isMutated.current = true;

        if (isUnconfirmed) {
            mutation.mutate({
                action: "decline",
                email,
                ...(childrenEmail && { child_email: childrenEmail }),
            });
        } else {
            mutation.mutate({
                action: "remove",
                email,
                ...(childrenEmail && { child_email: childrenEmail }),
            });
        }

        setIsModalOpen(false);
    };

    useEffect(() => {
        if (mutation.isError && isMutated.current) {
            isMutated.current = false;

            if (mutation.isError) {
                setIsError(true);
            }
        }
    }, [mutation]);

    useEffect(() => {
        if (isError) {
            setTimeout(() => {
                setIsError(false);
            }, 1500);
        }
    }, [isError, mutation]);

    return (
        <>
            <div
                className={clsx(
                    styles.container,
                    isUnconfirmed && styles.unconfirmed
                )}
            >
                <div className={styles.content}>
                    <p className={styles.name}>
                        {children}
                        {isUnconfirmed && (
                            <>
                                <br />
                                (niepotwierdzony)
                            </>
                        )}
                    </p>
                </div>
                <div className={styles.buttonGroup}>
                    {isUnconfirmed && isInvited && (
                        <div className={styles.button}>
                            <IconButton
                                type="confirm"
                                isDisabled={isError || mutation.isLoading}
                                onClick={handleAcceptMutation}
                            />
                        </div>
                    )}
                    {((isUnconfirmed && isRemoveDisabled) ||
                        !isRemoveDisabled) && (
                        <div className={styles.button}>
                            <IconButton
                                type="delete"
                                isDisabled={isError || mutation.isLoading}
                                onClick={() => setIsModalOpen(true)}
                            />
                        </div>
                    )}
                </div>
                <AnimatePresence mode="wait">
                    {isError && (
                        <motion.div
                            className={styles.error}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{
                                type: "tween",
                                duration: 0.2,
                            }}
                        >
                            <p>Wystąpił nieoczekiwany błąd.</p>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            <DefaultModal
                title="USUŃ"
                actions={[
                    {
                        name: "TAK, USUŃ",
                        isImportant: true,
                        onClick: handleRemoveMutation,
                    },
                    {
                        name: "NIE, WRÓĆ",
                        onClick: () => setIsModalOpen(false),
                    },
                ]}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            >
                {modalText && <p>{modalText}</p>}
                <p>
                    <strong>{email}</strong>
                </p>
            </DefaultModal>
        </>
    );
};
