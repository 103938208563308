import * as yup from "yup";

export const loginSchema = yup.object({
    email: yup
        .string()
        .email("* nieprawidłowy adres e-mail")
        .required("* pole wymagane"),
    password: yup
        .string()
        .min(8, "* hasło musi składać się minimum z 8 znaków")
        .required("* pole wymagane"),
});
