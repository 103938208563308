import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

type Mutation = string;

export const useCodeResend = (isRegister?: boolean) => {
    return useMutation<AxiosResponse<any, any>, unknown, Mutation, unknown>(
        (email) =>
            mainApi.post(
                isRegister
                    ? "reset_register_activation_code"
                    : "reset_password_code",
                {
                    email,
                }
            )
    );
};
