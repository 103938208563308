export const LettersIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.463"
        height="14.378"
        viewBox="0 0 18.463 14.378"
    >
        <g
            id="Group_12514"
            data-name="Group 12514"
            transform="translate(-1818.18 -1176.313)"
        >
            <path
                id="Path_13009"
                data-name="Path 13009"
                d="M1831.818,1237.6l-6.5-3.251-6.5,3.251a1.414,1.414,0,0,0-.647,1.163v6.532a1.369,1.369,0,0,0,1.361,1.369h11.564a1.369,1.369,0,0,0,1.361-1.369v-6.532A1.339,1.339,0,0,0,1831.818,1237.6Zm-6.5,4.445-5.466-3.592,5.466-2.736,5.466,2.736Z"
                transform="translate(0 -55.973)"
                fill="#26a2b1"
            />
            <path
                id="Path_13010"
                data-name="Path 13010"
                d="M2059.784,1206.792l-6.37-3.189a.28.28,0,0,0-.25,0l-.816.409a.232.232,0,0,0,0,.415l6.33,3.169a1.337,1.337,0,0,1,.647,1.163v6.532a1.365,1.365,0,0,1-.119.558,1.369,1.369,0,0,0,1.225-1.361v-6.532A1.339,1.339,0,0,0,2059.784,1206.792Z"
                transform="translate(-225.72 -26.291)"
                fill="#26a2b1"
            />
            <path
                id="Path_13011"
                data-name="Path 13011"
                d="M2114.727,1180.7a1.339,1.339,0,0,0-.647-1.163l-6.37-3.189a.278.278,0,0,0-.249,0l-.817.409a.232.232,0,0,0,0,.415l6.33,3.169a1.338,1.338,0,0,1,.647,1.163v6.532a1.364,1.364,0,0,1-.119.558,1.369,1.369,0,0,0,1.225-1.361Z"
                transform="translate(-278.085)"
                fill="#26a2b1"
            />
        </g>
    </svg>
);
