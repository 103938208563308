import clsx from "clsx";

import { useMedia } from "../../../../hooks";

import { NavigationAccountItem } from "../NavigationAccountItem";
import { AccountSocial } from "../AccountSocial";

import { NavigationAccountProps } from "./NavigationAccount.typings";

import styles from "./NavigationAccount.module.css";

import infinity from "../../../../assets/images/robots/infinity-account.svg";

export const NavigationAccount = ({
    elements,
    isFooterVisible,
}: NavigationAccountProps) => {
    const { isDesktop } = useMedia();

    return (
        <div
            className={clsx(
                styles.container,
                !isFooterVisible && isDesktop && styles.footerHidden
            )}
        >
            {elements.map((element) => (
                <div className={styles.item} key={element.children}>
                    <NavigationAccountItem
                        icon={element.icon}
                        to={element.to}
                        isActive={element.isActive}
                        isImportant={element.isImportant}
                        onClick={element.onClick}
                    >
                        {element.children}
                    </NavigationAccountItem>
                </div>
            ))}
            {isFooterVisible && (
                <div className={styles.footer}>
                    {isDesktop ? (
                        <AccountSocial />
                    ) : (
                        <>
                            <div className={styles.robot}>
                                <img src={infinity} alt="" />
                            </div>
                            <div className={styles.social}>
                                <AccountSocial />
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
