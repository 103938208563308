import { useLocation, useParams } from "react-router-dom";

export const useIsStudentProgressPage = () => {
    const { pathname } = useLocation();
    const params = useParams();

    const childrenId = params.childrenId;
    const classType = params.classType;

    return pathname.indexOf(`/student-progress/${childrenId}`) !== -1 &&
        pathname.indexOf(`/student-progress/${childrenId}/${classType}`) === -1
        ? true
        : false;
};
