const taskNoteAnimations = {
    initial: {
        minWidth: "0%",
        maxWidth: "0%",
        width: "0%",
        opacity: 0,
    },
    animate: {
        minWidth: "44.27480917%",
        maxWidth: "44.27480917%",
        width: "44.27480917%",
        opacity: 1,
    },
    exit: {
        minWidth: "0%",
        maxWidth: "0%",
        width: "0%",
        opacity: 0,
    },
    transition: {
        minWidth: {
            type: "tween",
            duration: 0.4,
        },
        maxWidth: {
            type: "tween",
            duration: 0.4,
        },
        width: {
            type: "tween",
            duration: 0.4,
        },
        opacity: {
            type: "tween",
            duration: 0.2,
        },
    },
};

export { taskNoteAnimations };
