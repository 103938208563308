import { useState, forwardRef } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import clsx from "clsx";

import { CircleLoader } from "../../../../components/loaders";

import { getProgressStatus } from "../../utils";

import styles from "./TasksListItem.module.css";

type Props = {
    children: string;
    to: string;
    icon: string;
    progress: number;
    isDesktop?: boolean;
    isLoading?: boolean;
};

export const TasksListItem = forwardRef(
    (forwardedProps: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
        const { children, to, icon, progress, isDesktop, isLoading } =
            forwardedProps;

        const [isClicked, setIsClicked] = useState(false);
        const progressStatus = getProgressStatus(progress);

        return (
            <motion.div
                ref={ref}
                className={styles.container}
                layout={true}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                    type: "tween",
                    duration: 0.3,
                }}
            >
                <Link
                    className={clsx(
                        styles.button,
                        isLoading && styles.disabled
                    )}
                    to={to}
                    onClick={() => setIsClicked(true)}
                >
                    <span className={styles.icon}>
                        {isLoading && isClicked ? (
                            <CircleLoader
                                color="white"
                                size={!isDesktop ? "24" : undefined}
                            />
                        ) : (
                            <img src={icon} alt="" />
                        )}
                    </span>
                    {children}
                    <span
                        className={clsx(
                            styles.progress,
                            progressStatus === "low" && styles.low,
                            progressStatus === "medium" && styles.medium,
                            progressStatus === "high" && styles.high
                        )}
                    >
                        {progress}%
                    </span>
                </Link>
            </motion.div>
        );
    }
);
