import { Stage } from "konva/lib/Stage";
import { Vector2d } from "konva/lib/types";

export const getRelativePointerPosition = (node: Stage | null) => {
  if (node) {
    // the function will return pointer position relative to the passed node
    const transform = node.getAbsoluteTransform().copy();
    // to detect relative position we need to invert transform
    transform.invert();

    // get pointer (say mouse or touch) position
    const pos = node.getStage().getPointerPosition();

    if (pos) {
      return transform.point(pos);
    }
    // now we find a relative point
  }
};

export const getDistance = (p1: Vector2d, p2: Vector2d) =>
  Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));

export const getCenter = (p1: Vector2d, p2: Vector2d): Vector2d => ({
  x: (p1.x + p2.x) / 2,
  y: (p1.y + p2.y) / 2,
});
