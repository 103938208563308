import { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useField } from "formik";
import clsx from "clsx";

import { SelectInput } from "../SelectInput";

import { parseMonthString } from "../../../utils";

import styles from "./DateInput.module.css";

type Props = {
    name: string;
    isBlankError?: boolean;
    isDisabled?: boolean;
};

const months = [
    "styczeń",
    "luty",
    "marzec",
    "kwiecień",
    "maj",
    "czerwiec",
    "lipiec",
    "sierpień",
    "wrzesień",
    "październik",
    "listopad",
    "grudzień",
];

const getYears = (minYear: number): string[] => {
    const years = [];

    let currentYear = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
    ).getFullYear();

    if (currentYear > minYear) {
        while (currentYear >= minYear) {
            years.push(currentYear.toString());

            currentYear--;
        }
    } else {
        years.push(currentYear.toString());
    }

    return years;
};

const getDays = (year: string, month: string): string[] | undefined => {
    if (year && month) {
        const daysInMonth = new Date(
            parseInt(year),
            parseInt(month),
            0
        ).getDate();
        const days = [];

        for (let i = 0; i < daysInMonth; i++) {
            days.push((i + 1).toString());
        }

        return days;
    } else {
        return undefined;
    }
};

const formatNumber = (number: string | number): string => {
    number = typeof number === "string" ? parseInt(number) : number;

    return number > 9 ? number.toString() : "0" + number.toString();
};

type DateObjectProps = { day: string; month: string; year: string };

const getDateObject = (date: string): DateObjectProps => {
    if (date === "") {
        return { day: "", month: "", year: "" };
    } else {
        const dateArray = date.split(".");

        return { day: dateArray[0], month: dateArray[1], year: dateArray[2] };
    }
};

export const DateInput = ({ name, isBlankError, isDisabled }: Props) => {
    const useFieldFlag = useRef(false);
    const [field, meta, helpers] = useField({ name, type: "text" });

    const [date, setDate] = useState(getDateObject(field.value));

    const [isErrorActive, setIsErrorActive] = useState(false);
    const isSuccess = field.value !== "";
    const isError = (isErrorActive && !!meta.error) || (isBlankError && !!meta.error);

    const years = getYears(1900);
    const days = getDays(date.year, date.month);

    useEffect(() => {
        if (!useFieldFlag.current) {
            useFieldFlag.current = true;

            if (date.day && date.month && date.year) {
                helpers.setValue(
                    `${formatNumber(date.day)}.${date.month}.${date.year}`
                );
            } else if (field.value !== "") {
                helpers.setValue("");
            }
        }
    }, [date, field, helpers]);

    return (
        <div className={styles.field}>
            <p
                className={clsx(
                    styles.title,
                    isSuccess && styles.success,
                    isError && styles.error,
                    isDisabled && styles.disabled
                )}
            >
                Data urodzenia
            </p>
            <div className={styles.fieldRow}>
                <div className={styles.fieldColumn}>
                    <SelectInput
                        value={date.year}
                        selectValues={years}
                        label="ROK"
                        isLabelCenter={true}
                        isError={isError}
                        isDisabled={isDisabled}
                        onChange={(value) => {
                            useFieldFlag.current = false;

                            setDate({
                                day: "",
                                month: "",
                                year: value,
                            });
                        }}
                    />
                </div>
                <div className={clsx(styles.fieldColumn, styles.large)}>
                    <SelectInput
                        value={parseMonthString(date.month, true).toUpperCase()}
                        selectValues={months.map((month) =>
                            month.toUpperCase()
                        )}
                        label="MIESIĄC"
                        isLabelCenter={true}
                        isDisabled={isDisabled || !date.year}
                        isError={isError}
                        onChange={(value) => {
                            useFieldFlag.current = false;

                            setDate((prevDate) => ({
                                ...prevDate,
                                day: "",
                                month: parseMonthString(value),
                            }));
                        }}
                    />
                </div>
                <div className={styles.fieldColumn}>
                    <SelectInput
                        value={date.day}
                        selectValues={days}
                        label="DZIEŃ"
                        isLabelCenter={true}
                        isDisabled={isDisabled || !date.year || !date.month}
                        isError={isError}
                        onChange={(value) => {
                            useFieldFlag.current = false;

                            setDate((prevDate) => ({
                                ...prevDate,
                                day: value,
                            }));

                            setIsErrorActive(true);
                        }}
                    />
                </div>
            </div>
            <AnimatePresence mode="wait">
                {isErrorActive && meta.error && meta.error !== " " && (
                    <motion.div
                        className={styles.metaError}
                        initial={{ height: 0 }}
                        animate={{ height: "auto" }}
                        exit={{ height: 0 }}
                        transition={{ type: "tween", duration: 0.3 }}
                    >
                        <p className={styles.metaMessage}>{meta.error}</p>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};
