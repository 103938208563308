import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { ExamTaskDetailsResponse, ExamTasksResponse } from "../typings";
import { examTaskDetailsQuery, examTasksQuery } from "../utils";

const useExamTasks = () =>
    useQuery<AxiosResponse<ExamTasksResponse, any>>(examTasksQuery());

const useExamTaskDetails = (examId: string) => {
    return useQuery<AxiosResponse<ExamTaskDetailsResponse, any>>(
        examTaskDetailsQuery(examId)
    );
};

export { useExamTasks, useExamTaskDetails };
