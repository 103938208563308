import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import clsx from "clsx";
import { useField } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

import { mainApi } from "../../../api";
import { useDebounce } from "../../../hooks/useDebounce";

import {
    dropdownVariants,
    inputVariants,
    labelVariants,
} from "./SearchInput.animations";

import styles from "./SearchInput.module.css";

type ReturnedValue = {
    id: string;
    name: string;
};

type Props = {
    className?: string;
    label: string;
    name: string;
    query: {
        key: string[] | number[] | object[];
        url: string;
        name: string;
        nameFn?: (value: any) => string;
    };
    isBlankError?: boolean;
    onChange: (value: ReturnedValue) => void;
    isDisabled?: boolean;
    emptyErrorMessage?: string;
    minCharsForQueryToLaunch?: number;
};

const transition = {
    type: "tween",
    duration: 0.3,
};

export const SearchInput = ({
    className,
    label,
    name,
    query,
    isBlankError,
    onChange,
    isDisabled,
    emptyErrorMessage = "Nie znaleziono wyszukiwanej frazy.",
    minCharsForQueryToLaunch = 3,
}: Props) => {
    const [field] = useField<ReturnedValue>({ name, type: "text" });

    const [isOpen, setIsOpen] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const isSearchQueried = useRef(false);
    const isSearchFinished = useRef(false);

    const [inputValue, setInputValue] = useState("");
    const debouncedInputValue = useDebounce(
        inputValue,
        inputValue.length >= minCharsForQueryToLaunch ? 1000 : 0
    );

    const inputElement = useRef<HTMLInputElement>(null);
    const dropdownElement = useRef<HTMLDivElement>(null);

    const isFilled = inputValue.length > 0;
    const isSelected = !!field.value.name;
    const isSelectedOnMount = useRef(isSelected);

    const handleOnChange = (event: React.FocusEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const length = value.length;

        if (length > 20) return false;

        setInputValue(value);
    };

    const handleOnFocus = () => {
        if (isSelectedOnMount.current) {
            isSelectedOnMount.current = false;
        }

        setIsOpen(true);
    };

    const handleOnBlur = () => {
        if (!isSelected && !!inputValue) {
            setIsEmpty(true);
        } else if (inputValue.length > 0) {
            setIsEmpty(false);
        }
    };

    const handleSearchFinish = () => {
        isSearchFinished.current = true;

        setIsSearching(false);
    };

    const {
        data,
        isFetched,
        isLoading,
        isRefetching,
        isError,
        refetch,
        remove,
    } = useQuery<AxiosResponse<any, any>>(
        query.key,
        () => mainApi.post(query.url, { value: debouncedInputValue }),
        {
            enabled: false,
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
            onSuccess: handleSearchFinish,
            onError: handleSearchFinish,
        }
    );

    const isFocused =
        (isOpen &&
            isFetched &&
            inputValue.length >= minCharsForQueryToLaunch) ||
        (isOpen && ((isLoading && isFetched) || isRefetching));

    const dataResponse = data?.data.response;

    useEffect(() => {
        if (
            typeof debouncedInputValue === "string" &&
            debouncedInputValue.length >= minCharsForQueryToLaunch
        ) {
            isSearchQueried.current = true;

            refetch();
        }
    }, [minCharsForQueryToLaunch, debouncedInputValue, refetch]);

    useEffect(() => {
        if (
            inputValue.length < minCharsForQueryToLaunch &&
            isSearchQueried.current
        ) {
            isSearchQueried.current = false;

            remove();
        }

        if (!isSearchFinished.current) {
            if (inputValue.length >= minCharsForQueryToLaunch && !isSearching) {
                setIsSearching(true);
            } else if (
                inputValue.length < minCharsForQueryToLaunch &&
                isSearching
            ) {
                setIsSearching(false);
            }
        } else {
            isSearchFinished.current = false;
        }
    }, [minCharsForQueryToLaunch, inputValue, isSearching, remove]);

    useEffect(() => {
        return () => {
            remove();
        };
    }, [remove]);

    const handleMenuClick = (value: ReturnedValue) => {
        setIsOpen(false);

        setInputValue("");

        onChange(value);
    };

    const handleSearchClose = (event: MouseEvent) => {
        const target = event.target as Node;

        if (
            inputElement.current &&
            !inputElement.current.contains(target) &&
            (!dropdownElement.current ||
                (dropdownElement.current &&
                    !dropdownElement.current.contains(target)))
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleSearchClose);

        return () => {
            document.removeEventListener("mousedown", handleSearchClose);
        };
    }, []);

    return (
        <div className={clsx(styles.field, className)}>
            <div className={styles.fieldRow}>
                <motion.label
                    className={styles.label}
                    initial={isSelectedOnMount.current ? "success" : "closed"}
                    animate={
                        isDisabled && !isFilled
                            ? "closed_disabled"
                            : isDisabled && isFilled
                            ? "disabled"
                            : isSelected
                            ? "success"
                            : isOpen || isFilled
                            ? isEmpty
                                ? "error"
                                : isBlankError
                                ? "error"
                                : "open"
                            : isBlankError || isEmpty
                            ? "closed_error"
                            : "closed"
                    }
                    variants={labelVariants}
                    transition={{ type: "tween", duration: 0.3 }}
                >
                    {label}
                </motion.label>
                <motion.input
                    ref={inputElement}
                    className={styles.input}
                    type="text"
                    value={inputValue}
                    initial={isSelectedOnMount.current ? "success" : "closed"}
                    animate={
                        isFocused
                            ? "open"
                            : isSelected
                            ? "success"
                            : isDisabled
                            ? "disabled"
                            : isBlankError || isEmpty
                            ? "error"
                            : "closed"
                    }
                    variants={inputVariants}
                    transition={transition}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    onChange={handleOnChange}
                    disabled={isDisabled}
                />
                <div className={styles.placeholder}>
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={`placeholder_${(
                                isSelected && !isOpen
                            ).toString()}`}
                            initial={{ y: isSelectedOnMount.current ? 0 : 30 }}
                            animate={{ y: 0 }}
                            exit={{ y: -30 }}
                            transition={{ type: "tween", duration: 0.23 }}
                        >
                            {isSelected && !isOpen ? (
                                <p className={styles.placeholderValue}>
                                    {field.value.name}
                                </p>
                            ) : (
                                <p className={styles.placeholderValue}>
                                    {inputValue}
                                </p>
                            )}
                        </motion.div>
                    </AnimatePresence>
                </div>
                <AnimatePresence mode="wait">
                    {isSearching && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ type: "tween", duration: 0.2 }}
                            className={styles.loader}
                        >
                            <div className={styles.dots}></div>
                        </motion.div>
                    )}
                </AnimatePresence>
                <AnimatePresence mode="wait">
                    {isFocused && (
                        <motion.div
                            ref={dropdownElement}
                            className={clsx(
                                styles.dropdown,
                                dataResponse &&
                                    dataResponse.length === 0 &&
                                    styles.hidden
                            )}
                            variants={dropdownVariants}
                            initial="closed"
                            animate="open"
                            exit="closed"
                            transition={transition}
                        >
                            <ul className={styles.menu}>
                                {dataResponse && dataResponse.length > 0 ? (
                                    dataResponse.map((object: any) => {
                                        const name =
                                            typeof query.nameFn === "function"
                                                ? query.nameFn(object)
                                                : object[query.name];

                                        return (
                                            <li key={object.id}>
                                                <button
                                                    className={clsx(
                                                        styles.menuButton,
                                                        object.id ===
                                                            field.value.id &&
                                                            styles.active
                                                    )}
                                                    type="button"
                                                    disabled={
                                                        object.id ===
                                                        field.value.id
                                                    }
                                                    onClick={() =>
                                                        handleMenuClick({
                                                            id: object.id,
                                                            name: name,
                                                        })
                                                    }
                                                >
                                                    {name}
                                                </button>
                                            </li>
                                        );
                                    })
                                ) : (
                                    <li>
                                        <span
                                            className={clsx(
                                                styles.menuMessage,
                                                isError && styles.error
                                            )}
                                        >
                                            {isError ? (
                                                <>
                                                    Wystąpił błąd podczas
                                                    wyszukiwania.
                                                    <br />
                                                    Prosimy spróbować za chwilę.
                                                </>
                                            ) : (
                                                emptyErrorMessage
                                            )}
                                        </span>
                                    </li>
                                )}
                            </ul>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        </div>
    );
};
