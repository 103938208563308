import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { setTaskParams } from "../../../tasks/services";

import { useRedirectByPop } from "../../../../hooks";

import { useGetCompetencyTest } from "../../hooks/queries";

import { RedirectLoading } from "../../components";

import { taskSlug } from "../../../tasks/utils";
import { getPreviousPath } from "../../../../utils";

import { CompetencyTestLocation } from "../../typings";

import styles from "./CompetencyTestLoading.module.css";

export const CompetencyTestLoading = () => {
    const { pathname, state }: CompetencyTestLocation = useLocation();

    const { data, refetch, isFetching, isLoading } = useGetCompetencyTest();

    const testId = data?.data.test_id;
    const firstExercise = data?.data.exercises[0];

    useRedirectByPop("/dashboard/student");

    useEffect(() => {
        if (!firstExercise && taskSlug.competency_test) {
            refetch();
        }
    }, [firstExercise, refetch]);

    if (!state) {
        return <RedirectLoading />;
    }

    if (state && testId && firstExercise) {
        setTaskParams({
            type: taskSlug.competency_test,
            taskId: firstExercise.id,
            additionalId: testId,
        });

        return (
            <Navigate
                to={`${getPreviousPath(pathname, 2)}/task/${
                    taskSlug.competency_test
                }/${firstExercise.id}`}
                replace
            />
        );
    }

    return isFetching ? (
        <RedirectLoading />
    ) : !(testId && firstExercise) && !isLoading ? (
        <div className={styles.container}>Brak egzaminu</div>
    ) : (
        <RedirectLoading />
    );
};
