import { mainApi } from "../../../../api";

const time = 1 * 60 * 1000;

export const teacherStudentsQuery = (
    isEnabled: boolean = true,
    isFasterRefetch: boolean = false
) => ({
    queryKey: ["teacherStudents"],
    queryFn: async () => mainApi.get("get_teacher_students"),
    enabled: isEnabled,
    refetchInterval: isFasterRefetch ? time / 4 : time,
    staleTime: time / 4,
});
