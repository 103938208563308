import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useOutlet } from "react-router-dom";
import { romanize } from "romans";

import { getAccountCookie } from "../../../../services";

import { useRedirectContext } from "../../../../contexts/RedirectContext";
import { useAnswersContext } from "../../contexts/AnswersContext";
import { useResultContext } from "../../contexts/ResultContext";

import { useMedia, useRedirectByPop } from "../../../../hooks";
import {
    useAnswerAiSession,
    useGetNextCompetencyTestTask,
    useGetNextExamTask,
    useGetNextLearningProgressTask,
    useGetNextRandomTask,
    useGetNextTask,
} from "../../hooks";

import { TaskContainer } from "../../../../components/containers";
import { DashboardHeading, TaskHeading } from "../../../../components/headings";
import {
    NavigationBar,
    ReportBar,
    TaskBuild,
    TaskNote,
    TaskNoteModal,
} from "../../components";
import { Whiteboard } from "../whiteboard";

import { getPreviousPath } from "../../../../utils";
import {
    getExamFilter,
    getIsTaskFilterExam,
    getShieldExamName,
    setExamFilter,
    taskChangeAnimations,
    taskSlug,
} from "../../utils";

import { LineType, TaskFilterExamType } from "../../typings";

import { taskNoteAnimations } from "./TaskBody.animations";

import { TaskBodyProps } from "./TaskBody.typings";

import styles from "./TaskBody.module.css";

import schoolIcon from "../../../dashboard/images/learning-progress/school-icon.svg";

export const TaskBody = ({
    id,
    type,
    exam,
    test,
    ai,
    learningProgress,
    content,
    answers,
    hint,
    sheets,
    difficulty,
    count,
    icon,
    category,
    subcategory,
    hasTheory,
    isExamFilter,
}: TaskBodyProps) => {
    const { userSchool } = getAccountCookie();
    const { isDesktop } = useMedia();

    const { setRedirect } = useRedirectContext();
    const { removeProvidedAnswers } = useAnswersContext();
    const { results, removeResults, isResultsLoading } = useResultContext();

    const { pathname } = useLocation();

    const outlet = useOutlet();
    const navigate = useNavigate();

    const { mutate: getNextTask, isLoading: isNextTaskLoading } =
        useGetNextTask();
    const { mutate: getNextExamTask, isLoading: isNextExamTaskLoading } =
        useGetNextExamTask();
    const { mutate: getNextRandomTask, isLoading: isNextRandomTaskLoading } =
        useGetNextRandomTask();
    const {
        mutate: getNextCompetencyTestTask,
        isLoading: isNextCompetencyTestTaskLoading,
    } = useGetNextCompetencyTestTask();
    const {
        mutate: getNextLearningProgressTask,
        isLoading: isNextLearningProgressTask,
    } = useGetNextLearningProgressTask();
    const { mutate: answerAiSession, isLoading: isAnswerAiSessionLoading } =
        useAnswerAiSession();

    const backwardPathname = outlet
        ? getPreviousPath(pathname)
        : `${getPreviousPath(pathname, 3)}/${
              type === taskSlug.exercise || type === taskSlug.random
                  ? exam
                      ? `exam-tasks/${exam.id}`
                      : "exam-tasks"
                  : type === taskSlug.competency_test || type === taskSlug.ai
                  ? ""
                  : learningProgress
                  ? `learning-progress/${learningProgress.classType}/${learningProgress.id.category}/${learningProgress.id.subcategory}`
                  : `tasks/${category.id}/${subcategory.id}`
          }`;

    const [isNoteOpen, setIsNoteOpen] = useState(false);
    const [isTaskChanging, setIsTaskChanging] = useState(false);
    const isFiltered = getExamFilter();

    useRedirectByPop(backwardPathname);

    useEffect(() => {
        if (!isExamFilter && isFiltered) {
            setExamFilter(false);
        }
    }, [isExamFilter, isFiltered]);

    useEffect(() => {
        setRedirect(backwardPathname);
    }, [backwardPathname, setRedirect]);

    const [lines, setLines] = useState<LineType[]>([]);

    const onForward = () => {
        setIsTaskChanging(true);
        setLines([]);

        if (exam && type === taskSlug.exercise) {
            getNextExamTask({
                taskId: exam.taskId,
                examId: exam.id,
            });
        } else if (test && type === taskSlug.competency_test) {
            getNextCompetencyTestTask({
                taskId: test.taskId,
                testId: test.id,
                skipped: !results,
            });
        } else if (ai && type === taskSlug.ai) {
            answerAiSession({
                taskId: ai.taskId,
                sessionId: ai.id,
                answeredRight: results ? results.correct : false,
                skipped: results ? false : true,
            });
        } else if (type === taskSlug.random) {
            getNextRandomTask();
        } else if (learningProgress && type === taskSlug.learning_progress) {
            getNextLearningProgressTask({
                taskId: learningProgress.taskId,
                categoryId: learningProgress.id.category,
                subcategoryId: learningProgress.id.subcategory,
                classType: learningProgress.classType,
            });
        } else {
            getNextTask({
                taskId: id,
                userClass: userSchool.class,
                categoryId: category.id,
                subcategoryId: subcategory.id,
                isFiltered,
            });
        }
    };

    return (
        <TaskContainer footerElement={<ReportBar id={id} />}>
            <DashboardHeading
                title={
                    type === taskSlug.competency_test
                        ? "TEST KOMPETENCJI"
                        : category.name
                }
                icon={type === taskSlug.competency_test ? undefined : icon}
                to={backwardPathname}
                userClass={
                    type !== taskSlug.competency_test &&
                    type !== taskSlug.random ? (
                        type === taskSlug.exercise ? (
                            <img src={schoolIcon} alt="" />
                        ) : learningProgress ? (
                            learningProgress.classType ===
                                "matura_podstawowa" ||
                            learningProgress.classType ===
                                "matura_rozszerzona" ||
                            learningProgress.classType === "8_kl" ? (
                                <img src={schoolIcon} alt="" />
                            ) : (
                                romanize(parseInt(learningProgress.classType))
                            )
                        ) : getIsTaskFilterExam(userSchool.class) ? (
                            getShieldExamName(
                                userSchool.class as TaskFilterExamType
                            )
                        ) : (
                            romanize(parseInt(userSchool.class))
                        )
                    ) : undefined
                }
                hasSmallSpacing={true}
                isAnimated={type === taskSlug.competency_test ? false : true}
            />
            <TaskHeading
                title={subcategory.name.toUpperCase()}
                tooltip="Teoria"
                onClick={
                    !outlet && hasTheory ? () => navigate("theory") : undefined
                }
            />
            {outlet ? (
                <Outlet />
            ) : (
                <AnimatePresence mode="wait" initial={false}>
                    <motion.div
                        key={id}
                        {...taskChangeAnimations}
                        onAnimationComplete={(definition: {
                            opacity: number;
                        }) => {
                            if (definition.opacity === 0) {
                                removeProvidedAnswers();
                                removeResults();
                            } else {
                                setTimeout(() => {
                                    setIsTaskChanging(false);
                                }, taskChangeAnimations.transition.duration);
                            }
                        }}
                    >
                        <div className={styles.grid}>
                            <div className={styles.main}>
                                <div className={styles.item}>
                                    <TaskBuild
                                        taskId={id}
                                        content={content}
                                        answers={answers}
                                        hint={hint}
                                        sheets={sheets}
                                        level={difficulty}
                                        count={count}
                                        isExamFilter={isExamFilter}
                                        setIsNoteOpen={setIsNoteOpen}
                                    />
                                </div>
                            </div>
                            <AnimatePresence mode="wait" initial={false}>
                                {isDesktop && isNoteOpen && (
                                    <motion.div
                                        className={styles.aside}
                                        {...taskNoteAnimations}
                                    >
                                        <div className={styles.item}>
                                            <TaskNote setIsOpen={setIsNoteOpen}>
                                                <Whiteboard
                                                    lines={lines}
                                                    setLines={setLines}
                                                />
                                            </TaskNote>
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    </motion.div>
                </AnimatePresence>
            )}
            <NavigationBar
                backward={{
                    children: outlet ? "WRÓĆ DO ZADANIA" : "WRÓĆ",
                    to: outlet ? getPreviousPath(pathname) : backwardPathname,
                }}
                forward={
                    !outlet
                        ? {
                              children: results
                                  ? count.max !== 0 &&
                                    count.current === count.max
                                      ? "ZAKOŃCZ"
                                      : "NASTĘPNE"
                                  : "POMIŃ",
                              onClick: onForward,
                          }
                        : undefined
                }
                isResultsLoading={isResultsLoading}
                isNextTaskLoading={
                    isNextTaskLoading ||
                    isNextExamTaskLoading ||
                    isNextRandomTaskLoading ||
                    isNextCompetencyTestTaskLoading ||
                    isNextLearningProgressTask ||
                    isAnswerAiSessionLoading ||
                    isTaskChanging
                }
                isNarrowerOnDesktop={true}
            />
            <TaskNoteModal
                isOpen={!isDesktop && isNoteOpen}
                setIsOpen={setIsNoteOpen}
            >
                <Whiteboard lines={lines} setLines={setLines} />
            </TaskNoteModal>
        </TaskContainer>
    );
};
