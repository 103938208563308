type Props = {
    wrapper?: string;
    wrapperType?: "div" | "span";
    children: JSX.Element | string;
};

export const ConditionalWrapper = ({
    wrapper,
    wrapperType: WrapperType = "div",
    children,
}: Props) => {
    return wrapper ? (
        <WrapperType className={wrapper}>{children}</WrapperType>
    ) : typeof children === "string" ? (
        <>{children}</>
    ) : (
        children
    );
};
