import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { usePopper } from "react-popper";
import clsx from "clsx"

import { getPosition } from "./utils";

import { PopperType } from "./Popper.typings";

import styles from "./Popper.module.css";

export const Popper = ({
    referenceElement,
    placement,
    children,
    popStyle = "default",
    isActive,
}: PopperType) => {
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(
        null
    );
    const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

    const position = getPosition(placement);

    const { styles: popperStyles, attributes } = usePopper(
        referenceElement,
        popperElement,
        {
            modifiers: [
                {
                    name: "offset",
                    options: {
                        offset: [0, 8],
                    },
                },
                { name: "arrow", options: { element: arrowElement } },
            ],
            placement: placement,
        }
    );

    return (
        <>
            <AnimatePresence mode="wait">
                {isActive && (
                    <motion.div
                        ref={setPopperElement}
                        className={clsx(
                            styles.container,
                            popStyle === "error" && styles.error
                        )}
                        style={popperStyles.popper}
                        {...attributes.popper}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                            type: "tween",
                            duration: 0.2,
                        }}
                    >
                        {children}
                        <div
                            ref={setArrowElement}
                            className={clsx(
                                styles.arrow,
                                position === "top" && styles.top,
                                position === "right" && styles.right,
                                position === "bottom" && styles.bottom,
                                position === "left" && styles.left
                            )}
                            style={popperStyles.arrow}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};
