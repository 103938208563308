import { useNavigate } from "react-router-dom";

import { useIsSubscriptionPaid } from "../../hooks/utils";
import { useAiSession } from "../../../tasks/hooks";

import { HeaderButton } from "../HeaderButton";

import { HeaderDesktopProps } from "./HeaderDestkop.typings";

import styles from "./HeaderDesktop.module.css";

export const HeaderDesktop = ({
    data,
    subscriptions,
    studentData,
    isSetupComplete,
}: HeaderDesktopProps) => {
    const navigate = useNavigate();

    const {
        mutate: setAiSession,
        data: aiSessionData,
        isError: isAiSessionError,
    } = useAiSession();

    const isSubscriptionPaid = useIsSubscriptionPaid(subscriptions, studentData);

    return (
        <div className={styles.container}>
            <div className={styles.main}>
                <div className={styles.heading}>
                    <h3>{data.subtitle}</h3>
                    <h2>{data.title}</h2>
                </div>
                <div className={styles.content}>
                    <p>{data.text}</p>
                </div>
                {data.button && (
                    <HeaderButton
                        hasIcon={true}
                        isError={
                            isAiSessionError || aiSessionData?.data.end_of_ai
                        }
                        onClick={
                            data.button.type === "start"
                                ? isSubscriptionPaid
                                    ? isSetupComplete
                                        ? () => setAiSession()
                                        : () => navigate("competency-test")
                                    : () =>
                                          navigate("restriction", {
                                              state: true,
                                          })
                                : undefined
                        }
                    >
                        {data.button.text.toUpperCase()}
                    </HeaderButton>
                )}
            </div>
            <div className={styles.aside}>
                <div
                    className={styles.thumbnail}
                    style={{ backgroundImage: `url(${data.image})` }}
                ></div>
            </div>
        </div>
    );
};
