import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

import { setAccountCookie, setTokenCookie } from "../../../../services";

import { SchoolType } from "../../../../typings";

type Response = {
    auth_token: string;
    user_id: number;
    account_type: "Uczeń" | "Rodzic" | "Nauczyciel";
    parent_confirmation_required: boolean;
    parent_email: string;
    school: SchoolType;
    class: string;
    front_app_video_url: string;
};

type Mutation = {
    [name: string]: FormDataEntryValue;
};

type Error = {
    response: {
        data: {
            messages: {
                error: string;
            };
        };
    };
};

export const useRegisterConfirm = () => {
    return useMutation<AxiosResponse<Response, any>, Error, Mutation, unknown>(
        (data) =>
            mainApi.post("register_activation", {
                email: data.email,
                code: data.code,
            }),
        {
            onSuccess: (data) => {
                const userId = data.data.user_id.toString();
                const accountType = data.data.account_type;
                const parentEmail = data.data.parent_email;
                const userSchool: [SchoolType, string] | undefined =
                    data.data.school && data.data.class
                        ? [data.data.school, data.data.class]
                        : undefined;
                const videoUrl = data.data.front_app_video_url;
                const isParentConfirmationRequired =
                    data.data.parent_confirmation_required;

                setAccountCookie({
                    userId,
                    accountType,
                    parentEmail,
                    userSchool,
                    videoUrl,
                    isParentConfirmationRequired:
                        isParentConfirmationRequired.toString(),
                    isParentApproved: isParentConfirmationRequired
                        ? "false"
                        : "true",
                    isVideoWatched: "false",
                });

                setTokenCookie(data.data.auth_token);
            },
        }
    );
};
