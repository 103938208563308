import Cookies from "js-cookie";

const setInvitationCookie = (email: string, type: string) =>
    Cookies.set("user_invitation", `${email},${type}`, {
        sameSite: "strict",
        expires: 30,
    });

const getInvitationCookie = () => {
    const invitationCookie = Cookies.get("user_invitation");

    if (invitationCookie) {
        return invitationCookie.split(",");
    } else {
        return undefined;
    }
};

const removeInvitationCookie = () =>
    Cookies.remove("user_invitation", { sameSite: "strict" });

export { setInvitationCookie, getInvitationCookie, removeInvitationCookie };
