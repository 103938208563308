import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { useMedia } from "../../../../hooks";

import { useBanner } from "../../hooks";
import { useStudentGetToKnowData } from "../../../competencyTest/hooks/queries";
import { useUserSubscriptions } from "../../hooks/queries";

import { Header } from "../Header";
import { HeaderDesktop } from "../HeaderDesktop";
import { CircleLoader } from "../../../../components/loaders";

import styles from "./HeaderWrapper.module.css";

export const HeaderWrapper = () => {
    const { isDesktop } = useMedia();

    const banner = useBanner();
    const studentData = useStudentGetToKnowData();
    const userSubscriptions = useUserSubscriptions();

    const [isBannerLoaded, setIsBannerLoaded] = useState(
        banner.data ? true : false
    );
    const isSetupComplete =
        studentData.data?.data.data.competency_test_done === "1" &&
        studentData.data.data.data.subjects_per_week !== "0" &&
        studentData.data.data.data.schedule_visited === "1";
    const isDataLoaded =
        banner.data && studentData.data && userSubscriptions.data;

    useEffect(() => {
        if (isDataLoaded && !isBannerLoaded) {
            setTimeout(() => {
                setIsBannerLoaded(true);
            }, 1000);
        }
    }, [isDataLoaded, isBannerLoaded]);

    return (
        <div className={styles.container}>
            <AnimatePresence mode="wait" initial={false}>
                {isDataLoaded && isBannerLoaded ? (
                    <motion.div
                        key="banner"
                        initial={{ opacity: 0, height: 86 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 86 }}
                        transition={{
                            type: "tween",
                            duration: 0.3,
                        }}
                    >
                        {isDesktop ? (
                            <HeaderDesktop
                                data={banner.data.data.baner}
                                subscriptions={
                                    userSubscriptions.data.data.subscriptions
                                }
                                studentData={studentData.data.data}
                                isSetupComplete={isSetupComplete}
                                isHome={true}
                            />
                        ) : (
                            <Header
                                data={{
                                    ...banner.data.data.baner,
                                    image: banner.data.data.baner.image_mobile,
                                }}
                                subscriptions={
                                    userSubscriptions.data.data.subscriptions
                                }
                                studentData={studentData.data.data}
                                isSetupComplete={isSetupComplete}
                                isHome={true}
                            />
                        )}
                    </motion.div>
                ) : (
                    <motion.div
                        key="banner_loading"
                        className={styles.loading}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                            type: "tween",
                            duration: 0.3,
                        }}
                    >
                        <p>Trwa wczytywanie modułu</p>
                        <CircleLoader />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};
