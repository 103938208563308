import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

import { setResetPasswordCookie } from "../../../../services";

type Response = {
    reset_password_token: string;
};

type Mutation = {
    [name: string]: FormDataEntryValue;
};

type Error = {
    response: {
        data: {
            messages: {
                error: string;
            };
        };
    };
};

export const useRemindPasswordConfirm = () => {
    return useMutation<AxiosResponse<Response, any>, Error, Mutation, unknown>(
        (data) =>
            mainApi.post("reset_password_credentials", {
                email: data.email,
                code: data.code,
            }),
        {
            onSuccess: (data) =>
                setResetPasswordCookie(data.data.reset_password_token),
        }
    );
};
