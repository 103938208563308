export const getUserPlanStatusDescription = (status: "0" | "1" | "2") => {
    switch (status) {
        case "0":
            return (
                <>
                    Możliwe
                    <br />
                    opóźnienie
                </>
            );
        case "1":
            return (
                <>
                    Zgodnie
                    <br />z planem
                </>
            );
        case "2":
            return (
                <>
                    Brak planu
                    <br />
                    nauki
                </>
            );
    }
};
