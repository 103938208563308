import { LoaderFunctionArgs } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";

import { getAccountCookie } from "../../../../services";

import { learningProgressCategoryQuery } from "../../utils";

export const studentProgressCategoryLoader =
    (queryClient: QueryClient) =>
    async ({ params }: LoaderFunctionArgs) => {
        const { accountType } = getAccountCookie();

        const childrenId = params.childrenId;
        const classType = params.classType;
        const categoryId = params.categoryId;

        if (
            accountType === "student" ||
            !childrenId ||
            !classType ||
            !categoryId
        ) {
            return undefined;
        }

        const query = learningProgressCategoryQuery(
            "other",
            classType,
            categoryId,
            childrenId
        );

        return (
            queryClient.getQueryData(query.queryKey) ??
            queryClient.fetchQuery(query)
        );
    };
