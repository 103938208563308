import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

import { setAccountCookie, setTokenCookie } from "../../../../services";

type Response = {
    token: string;
    account_type: string;
    parent_confirmation_required: boolean;
    is_parent_approved: boolean;
    user_id: number;
    parent_email: string;
};

type Error = {
    response: {
        data: {
            messages: {
                error: string;
            };
        };
    };
};

type Mutation = {
    [name: string]: FormDataEntryValue;
};

export const useResetPassword = () => {
    return useMutation<AxiosResponse<Response, any>, Error, Mutation, unknown>(
        (data) =>
            mainApi.post("reset_password", {
                token: data.token,
                password: data.password,
                password_confirm: data.password_confirm,
            }),
        {
            onSuccess: (data) => {
                const userId = data.data.user_id.toString();
                const accountType = data.data.account_type;
                const parentEmail = data.data.parent_email;
                const isParentConfirmationRequired =
                    data.data.parent_confirmation_required;

                setAccountCookie({
                    userId,
                    accountType,
                    parentEmail,
                    isParentConfirmationRequired:
                        isParentConfirmationRequired.toString(),
                    isParentApproved: isParentConfirmationRequired
                        ? "false"
                        : "true",
                });
                setTokenCookie(data.data.token);
            },
        }
    );
};
