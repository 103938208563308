import { Navigate } from "react-router-dom";

import { useAuthContext } from "../../../../../contexts/AuthContext";

import { getAccountCookie } from "../../../../../services";

import { AuthContainer } from "../../../../../components/containers";
import { MainHeading } from "../../../../../components/headings";
import { AuthText } from "../../../../../components/texts";
import { DefaultButton } from "../../../../../components/buttons";

import styles from "./DashboardError.module.css";

import pi from "../../../../../assets/images/robots/pi-tears.svg";

export const DashboardError = () => {
    const { accountType } = getAccountCookie();

    const { logout } = useAuthContext();

    if (accountType !== "undefined") {
        return <Navigate to={`/dashboard/${accountType}`} replace={true} />;
    }

    return (
        <AuthContainer
            position="center"
            image={pi}
            imageSize={214}
            imagePosition="right"
        >
            <MainHeading wrapper={styles.heading} elementType="h2">
                <>
                    Niezidentyfikowany
                    <br />
                    typ konta
                </>
            </MainHeading>
            <AuthText>
                <p>
                    Przepraszamy, ale wystąpił błąd.
                    <br />
                    Prosimy o ponowne zalogowanie.
                </p>
            </AuthText>
            <DefaultButton
                wrapper={styles.forwardButton}
                whileHover={{ opacity: 0.7 }}
                onClick={logout}
            >
                WYLOGUJ
            </DefaultButton>
        </AuthContainer>
    );
};
