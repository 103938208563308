import { mainApi } from "../../../../api";

const time = 0.25 * 60 * 1000;

export const childrenTeachersQuery = (isEnabled: boolean = true) => ({
    queryKey: ["childrenTeachers"],
    queryFn: async () => mainApi.get("get_child_teachers"),
    enabled: isEnabled,
    refetchInterval: time,
    staleTime: time,
});
