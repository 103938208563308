import { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { getTaskParams, removeTaskParams } from "../../services";

import { useIsTaskPage } from "../../../../hooks";

export const useClearTaskParams = () => {
    const { pathname } = useLocation();

    const params = getTaskParams();
    const isParamsAvailable = useRef(!!params);
    const isValidPage = useIsTaskPage("generic");

    useEffect(() => {
        if (isValidPage) {
            if (!isParamsAvailable.current) {
                isParamsAvailable.current = true;
            }
        } else if (isParamsAvailable.current) {
            isParamsAvailable.current = false;

            removeTaskParams();
        }
    }, [pathname, isValidPage]);

    useEffect(() => {
        if (!isValidPage && params && !isParamsAvailable.current) {
            isParamsAvailable.current = true;
        }
    }, [isValidPage, params, pathname]);
};
