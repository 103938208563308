import { motion } from "framer-motion";
import clsx from "clsx";

import { ExamIcon } from "../icon-components";

import { variants } from "./ExamBadge.animations";

import { ExamBadgeProps } from "./ExamBadge.typings";

import styles from "./ExamBadge.module.css";

export const ExamBadge = ({
    status,
    hasBorder,
    isLargerOnDesktop,
}: ExamBadgeProps) => {
    const isSuccess = status === "success";
    const isError = status === "error";

    return hasBorder ? (
        <motion.span
            className={clsx(
                styles.container,
                styles.bordered,
                isLargerOnDesktop && styles.large
            )}
            variants={variants}
            initial={isSuccess ? "success" : isError ? "error" : "default"}
            animate={isSuccess ? "success" : isError ? "error" : "default"}
            transition={{ type: "tween", duration: 0.3 }}
        >
            <ExamIcon />
        </motion.span>
    ) : (
        <span
            className={clsx(
                styles.container,
                isLargerOnDesktop && styles.large
            )}
        >
            <ExamIcon />
        </span>
    );
};
