import { Form, Formik } from "formik";
import { useParams } from "react-router-dom";

import { DashboardHeading, WideHeading } from "../../../../components/headings";
import {
    DefaultInput,
    DefaultTextarea,
    FileInput,
    ObjectSelectInput,
} from "../../../../components/inputs";
import { useMedia } from "../../../../hooks";
import { NavigationBar, TaskContent } from "../../../tasks/components";
import { useSetSolutionOnDemandAsRead } from "../../hooks/mutations";
import { useGetSolutionOnDemand } from "../../hooks/queries";
import { FormFieldsPreview } from "../../typings";
import { sourceOptions } from "../../utils/selectOptions";

import styles from "./AnswerOnDemandDetails.module.css";

export const AnswerOnDemandDetails = () => {
    const { isDesktop } = useMedia();
    const params = useParams();
    const { mutate } = useSetSolutionOnDemandAsRead();
    const { data } = useGetSolutionOnDemand(params.id!, () =>
        mutate({ id: params.id! })
    );

    return (
        <>
            <DashboardHeading
                title="Rozwiązanie na zawołanie"
                hasBorder
                hasSmallSpacing
            />
            <div className={styles.answerOnDemandDetails}>
                {data?.data.solution_on_demand.answer && (
                    <>
                        {!isDesktop && (
                            <WideHeading className={styles.heading}>
                                ROZWIĄZANE ZADANIE
                            </WideHeading>
                        )}
                        <div className={styles.content}>
                            <div className={styles.date}>
                                Zadanie:{" "}
                                {data.data.solution_on_demand.created_at}
                            </div>
                            <TaskContent
                                content={data.data.solution_on_demand.answer}
                            />
                        </div>
                    </>
                )}

                <WideHeading className={styles.heading}>
                    Treść zadania
                </WideHeading>

                <Formik<FormFieldsPreview>
                    enableReinitialize
                    initialValues={{
                        source: sourceOptions.find(
                            (value) =>
                                value.value ===
                                data?.data.solution_on_demand.source
                        ),
                        title: data?.data.solution_on_demand.title,
                        pageNumber: data?.data.solution_on_demand.page_number,
                        taskNumber: data?.data.solution_on_demand.task_number,
                        file1: data?.data.solution_on_demand.file_path_1,
                        file2: data?.data.solution_on_demand.file_path_2,
                        notes: data?.data.solution_on_demand.notes,
                    }}
                    onSubmit={() => {}}
                >
                    {({ values }) => (
                        <Form className={styles.form}>
                            <div className={styles.textFields}>
                                <ObjectSelectInput
                                    selectValues={[]}
                                    value={values.source}
                                    label="Zadanie z..."
                                    isDisabled
                                />
                                <DefaultInput
                                    isMarginless
                                    type="text"
                                    name="title"
                                    label="Tytuł"
                                    isDisabled
                                />
                                <div className={styles.selectFields}>
                                    <DefaultInput
                                        isMarginless
                                        type="text"
                                        name="pageNumber"
                                        label="Nr strony"
                                        isDisabled
                                    />
                                    <DefaultInput
                                        isMarginless
                                        type="text"
                                        name="taskNumber"
                                        label="Nr zadania"
                                        isDisabled
                                    />
                                </div>
                            </div>

                            <div className={styles.fileFields}>
                                <div className={styles.fileFieldsInner}>
                                    <FileInput
                                        preview={values.file1}
                                        isDisabled
                                    />
                                    <FileInput
                                        preview={values.file2}
                                        isDisabled
                                    />
                                </div>
                                <DefaultTextarea
                                    placeholder="Dodatkowe notatki, np. numer zadania."
                                    value={values.notes}
                                    isDisabled
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
                <NavigationBar
                    backward={{
                        children: "WRÓĆ",
                    }}
                />
            </div>
        </>
    );
};
