import clsx from "clsx";
import { DashboardHeading } from "../../../../../components/headings";

import styles from "./DashboardPrivacyPolicy.module.css";

type Props = { containerPadding?: boolean };
export const DashboardPrivacyPolicy = ({ containerPadding = true }: Props) => {
    return (
        <div className={styles.root}>
            <div
                className={clsx(
                    styles.container,
                    containerPadding && styles.padding
                )}
            >
                <DashboardHeading title="Polityka prywatności" />

                <h3>Informacje ogólne</h3>

                <p>
                    Niniejsza polityka dotyczy Serwisu www, funkcjonującego pod
                    adresem: smarttutor.pl oraz aplikacji Smart Tutor- nauka
                    matematyki dostępnej w sklepie Google Play, AppStore-
                    zwanych dalej Serwisem/aplikacją.&nbsp;
                </p>

                <p>
                    Operatorem serwisu oraz Administratorem danych osobowych
                    jest: SMART TUTOR sp. z.o.o., ul. Zagrodnicza 8a, 61-654
                    Poznań, NIP: 9721342304, KRS:&nbsp;0001051164,
                    REGON:&nbsp;526070720
                </p>

                <p>
                    Adres kontaktowy poczty elektronicznej operatora:
                    kontakt@smarttutor.pl i inne adresy w domenie @smarttutor.pl
                </p>

                <p>
                    Operator jest Administratorem Twoich danych osobowych w
                    odniesieniu do danych podanych dobrowolnie w Serwisie.
                </p>

                <p>Serwis wykorzystuje dane osobowe w następujących celach:</p>
                <ul>
                    <li>
                        realizacja dostępu do serwisu smarttutor.pl oraz
                        aplikacji Smart Tutor- Nauka Matematyki,
                    </li>

                    <li>realizacja płatności w serwisie/aplikacji,</li>

                    <li>
                        obsługa zapytań składanych przez formularze w
                        serwisie/aplikacji,
                    </li>
                    <li>prowadzenie newslettera, wysyłanie powiadomień.</li>
                </ul>

                <p>
                    Serwis realizuje funkcje pozyskiwania informacji o
                    użytkownikach i ich zachowaniu w następujący sposób:
                </p>

                <ul>
                    <li>
                        Poprzez dobrowolnie wprowadzone w formularzach dane,
                        które zostają wprowadzone do systemów Operatora.
                    </li>

                    <li>
                        Poprzez zapisywanie w urządzeniach końcowych plików
                        cookie (tzw. „ciasteczka”).
                    </li>
                </ul>

                <h3>
                    2. Wybrane metody ochrony danych stosowane przez Operatora
                </h3>

                <p>
                    Miejsca logowania i wprowadzania danych osobowych są
                    chronione w warstwie transmisji (certyfikat SSL). Dzięki
                    temu dane osobowe i dane logowania, wprowadzone na stronie,
                    zostają zaszyfrowane w komputerze użytkownika i mogą być
                    odczytane jedynie na docelowym serwerze.
                </p>

                <p>
                    Hasła użytkowników są przechowywane w postaci hashowanej.
                    Funkcja hashująca działa jednokierunkowo – nie jest możliwe
                    odwrócenie jej działania, co stanowi obecnie standard w
                    zakresie przechowywania haseł użytkowników.
                </p>

                <p>Operator okresowo zmienia swoje hasła administracyjne.</p>

                <p>
                    Istotnym elementem ochrony danych jest regularna
                    aktualizacja wszelkiego oprogramowania, wykorzystywanego
                    przez Operatora do przetwarzania danych osobowych, co w
                    szczególności oznacza regularne aktualizacje komponentów
                    programistycznych.
                </p>

                <h3>3. Hosting</h3>

                <p>
                    Serwis jest hostowany (technicznie utrzymywany) na serwerach
                    operatora: LH.pl Sp. z o.o. ul. ks. Jakuba Wujka 7/26,
                    61-581 Poznań
                </p>

                <h3>
                    4. Twoje prawa i dodatkowe informacje o sposobie
                    wykorzystania danych
                </h3>

                <p>
                    W niektórych sytuacjach Administrator ma prawo przekazywać
                    Twoje dane osobowe innym odbiorcom, jeśli będzie to
                    niezbędne do wykonania zawartej z Tobą umowy lub do
                    zrealizowania obowiązków ciążących na Administratorze.
                    Dotyczy to takich grup odbiorców:
                </p>

                <ul>
                    <li>firma hostingowa na zasadzie powierzenia,</li>

                    <li>operatorzy płatności,</li>

                    <li>
                        upoważnieni pracownicy i współpracownicy, którzy
                        korzystają z danych w celu realizacji celu działania
                        strony, aplikacji,
                    </li>

                    <li>realizacji celu działania strony, aplikacji.</li>
                </ul>

                <p>
                    Twoje dane osobowe przetwarzane są przez Administratora nie
                    dłużej, niż jest to konieczne do wykonania związanych z nimi
                    czynności określonych osobnymi przepisami (np. o prowadzeniu
                    rachunkowości). W odniesieniu do danych marketingowych dane
                    nie będą przetwarzane dłużej niż przez 3 lata.
                </p>

                <p>Przysługuje Ci prawo żądania od Administratora:</p>

                <ul>
                    <li>dostępu do danych osobowych Ciebie dotyczących,</li>

                    <li>ich sprostowania,</li>

                    <li>usunięcia,</li>

                    <li>ograniczenia przetwarzania,</li>

                    <li>oraz przenoszenia danych.</li>
                </ul>

                <p>
                    Przysługuje Ci prawo do złożenia sprzeciwu w zakresie
                    przetwarzania wobec przetwarzania danych osobowych w celu
                    wykonania prawnie uzasadnionych interesów realizowanych
                    przez Administratora, w tym profilowania, przy czym prawo
                    sprzeciwu nie będzie mogło być wykonane w przypadku
                    istnienia ważnych prawnie uzasadnionych podstaw do
                    przetwarzania, nadrzędnych wobec Ciebie interesów, praw i
                    wolności, w szczególności ustalenia, dochodzenia lub obrony
                    roszczeń.
                </p>

                <p>
                    Na działania Administratora przysługuje skarga do Prezesa
                    Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193
                    Warszawa.
                </p>

                <p>
                    Podanie danych osobowych jest dobrowolne, lecz niezbędne do
                    obsługi Serwisu/aplikacji.
                </p>

                <p>
                    W stosunku do Ciebie mogą być podejmowane czynności
                    polegające na zautomatyzowanym podejmowaniu decyzji, w tym
                    profilowaniu w celu świadczenia usług w ramach zawartej
                    umowy oraz w celu prowadzenia przez Administratora
                    marketingu bezpośredniego.
                </p>

                <p>
                    Dane osobowe nie są przekazywane od krajów trzecich w
                    rozumieniu przepisów o ochronie danych osobowych. Oznacza
                    to, że nie przesyłamy ich poza teren Unii Europejskiej.
                </p>

                <h3>5. Informacje w gromadzanych i przetwarzanych danych</h3>

                <p>
                    Gromadzimy i przetwarzamy informacje podane przez
                    użytkownika celem realizacji usługi dostępu do serwisu i
                    aplikacji, w tym dane osobowe, o ile zostaną one podane,
                    m.in.
                </p>

                <ul>
                    <li>
                        login, hasło, adres email, imię, nazwisko, datę
                        urodzenia,&nbsp; miejscowość, rodzaj szkoły, klasę,
                        email podany przy rejestracji,
                    </li>

                    <li>
                        informację o dacie rejestracji, ustawieniach konta,
                        postępach nauki, zrealizowanych zadaniach,
                    </li>

                    <li>
                        informację o zakupionych abonamentach, dokonany
                        płatnościach (data, godzina, kwota, sposób płatności).
                    </li>
                </ul>

                <p>
                    Serwis może zapisać informacje o parametrach połączenia
                    (oznaczenie czasu, adres IP).
                </p>

                <p>
                    Dane podane w formularzu są przetwarzane w celu wynikającym
                    z funkcji konkretnego formularza, np. w celu dokonania
                    procesu obsługi zgłoszenia serwisowego lub kontaktu
                    handlowego, rejestracji usług itp. Każdorazowo kontekst i
                    opis formularza w czytelny sposób informuje, do czego on
                    służy.
                </p>

                <h3>6. Logi Administratora</h3>

                <p>
                    Informacje zachowaniu użytkowników w serwisie mogą podlegać
                    logowaniu. Dane te są wykorzystywane w celu administrowania
                    serwisem.
                </p>

                <h3>7. Istotne techniki marketingowe</h3>

                <p>
                    Operator stosuje analizę statystyczną ruchu na stronie,
                    poprzez Google Analytics (Google Inc. z siedzibą w USA).
                    Operator nie przekazuje do operatora tej usługi danych
                    osobowych, a jedynie zanonimizowane informacje. Usługa
                    bazuje na wykorzystaniu ciasteczek w urządzeniu końcowym
                    użytkownika. W zakresie informacji o preferencjach
                    użytkownika gromadzonych przez sieć reklamową Google
                    użytkownik może przeglądać i edytować informacje wynikające
                    z plików cookies przy pomocy narzędzia:
                    https://www.google.com/ads/preferences/
                </p>

                <p>
                    Operator stosuje techniki remarketingowe, pozwalające na
                    dopasowanie przekazów reklamowych do zachowania użytkownika
                    na stronie, co może dawać złudzenie, że dane osobowe
                    użytkownika są wykorzystywane do jego śledzenia, jednak w
                    praktyce nie dochodzi do przekazania żadnych danych
                    osobowych od Operatora do operatorom reklam. Technologicznym
                    warunkiem takich działań jest włączona obsługa plików
                    cookie.
                </p>

                <p>
                    Operator stosuje korzysta z piksela Facebooka. Ta
                    technologia powoduje, że serwis Facebook (Facebook Inc. z
                    siedzibą w USA) wie, że dana osoba w nim zarejestrowana
                    korzysta z Serwisu. Bazuje w tym wypadku na danych, wobec
                    których sam jest administratorem, Operator nie przekazuje od
                    siebie żadnych dodatkowych danych osobowych serwisowi
                    Facebook. Usługa bazuje na wykorzystaniu ciasteczek w
                    urządzeniu końcowym użytkownika.
                </p>

                <p>
                    Operator stosuje rozwiązanie automatyzujące działanie
                    Serwisu w odniesieniu do użytkowników, np. mogące przesłać
                    maila do użytkownika po odwiedzeniu konkretnej podstrony, o
                    ile wyraził on zgodę na otrzymywanie korespondencji
                    handlowej od Operatora.
                </p>

                <h3>8. Informacja o plikach cookies</h3>

                <p>Serwis korzysta z plików cookies.</p>

                <p>
                    Pliki cookies (tzw. „ciasteczka”) stanowią dane
                    informatyczne, w szczególności pliki tekstowe, które
                    przechowywane są w urządzeniu końcowym Użytkownika Serwisu i
                    przeznaczone są do korzystania ze stron internetowych
                    Serwisu. Cookies zazwyczaj zawierają nazwę strony
                    internetowej, z której pochodzą, czas przechowywania ich na
                    urządzeniu końcowym oraz unikalny numer.
                </p>

                <p>
                    Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika
                    Serwisu pliki cookies oraz uzyskującym do nich dostęp jest
                    operator Serwisu.
                </p>

                <p>Pliki cookies wykorzystywane są w następujących celach:</p>

                <ul>
                    <li>
                        utrzymanie sesji użytkownika Serwisu/aplikacji (po
                        zalogowaniu), dzięki której użytkownik nie musi na
                        każdej podstronie Serwisu ponownie wpisywać loginu i
                        hasła;
                    </li>

                    <li>
                        realizacji celów określonych powyżej w części „Istotne
                        techniki marketingowe”;
                    </li>
                </ul>

                <p>
                    Oprogramowanie do przeglądania stron internetowych
                    (przeglądarka internetowa) zazwyczaj domyślnie dopuszcza
                    przechowywanie plików cookies w urządzeniu końcowym
                    Użytkownika. Użytkownicy Serwisu mogą dokonać zmiany
                    ustawień w tym zakresie. Przeglądarka internetowa umożliwia
                    usunięcie plików cookies. Możliwe jest także automatyczne
                    blokowanie plików cookies Szczegółowe informacje na ten
                    temat zawiera pomoc lub dokumentacja przeglądarki
                    internetowej.
                </p>

                <p>
                    Ograniczenia stosowania plików cookies mogą wpłynąć na
                    niektóre funkcjonalności dostępne na stronach internetowych
                    Serwisu/aplikacji.
                </p>

                <p>
                    Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika
                    Serwisu wykorzystywane mogą być również przez współpracujące
                    z operatorem Serwisu podmioty, w szczególności dotyczy to
                    firm: Google (Google Inc. z siedzibą w USA), Apple (Apple
                    Inc. w USA), Facebook, Instagram (Meta Inc. z siedzibą w
                    USA).
                </p>
            </div>
        </div>
    );
};
