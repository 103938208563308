import { mainApi } from "../../../../api";

const key = "taskSubcategory";

export const taskSubcategoryQuery = (subcategoryId?: string, userClass?: string) => ({
    queryKey: subcategoryId ? [key, subcategoryId] : [key],
    queryFn: async () => mainApi.post(`get_subsection_exercises/${subcategoryId}`, {
        user_class: userClass
    }),
});
