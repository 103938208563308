import { useState, useEffect } from "react";
import {
    Navigate,
    Outlet,
    useLocation,
    useNavigate,
    useOutlet,
    useOutletContext,
} from "react-router-dom";

import { getAccountCookie, setAccountCookie } from "../../../../../services";

import { mainApi } from "../../../../../api";

import { ProtectedRoute } from "../../../../../components/utils";

type OutletContextType = [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>
];

export const DashboardMain = () => {
    const { accountType, isAccountDisabled, isParentApproved } =
        getAccountCookie();

    const outlet = useOutlet();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [isDeleteAccountRequested, setIsDeleteAccountRequested] =
        useState(false);

    useEffect(() => {
        mainApi.interceptors.response.use(
            (config) => config,
            (error) => {
                if (error.response.statusText === "PERMISSION_DENIED") {
                    navigate("/dashboard/session-expired", {
                        replace: true,
                        state: {
                            sessionExpired: true,
                        },
                    });
                }

                if (error.response.statusText === "INACTIVE_ACCOUNT") {
                    setAccountCookie({
                        isAccountDisabled: "true",
                    });

                    navigate("/dashboard/disabled", {
                        replace: true,
                        state: {
                            sessionExpired: true,
                        },
                    });
                }

                return Promise.reject(error);
            }
        );
    }, [navigate]);

    return outlet ? (
        <ProtectedRoute>
            <Outlet
                context={[
                    isDeleteAccountRequested,
                    setIsDeleteAccountRequested,
                ]}
            />
        </ProtectedRoute>
    ) : (
        <ProtectedRoute>
            {isAccountDisabled ? (
                <Navigate to="disabled" replace={true} />
            ) : !isParentApproved && pathname !== "/dashboard/locked" ? (
                <Navigate to="locked" replace={true} />
            ) : (
                <Navigate to={accountType} replace={true} />
            )}
        </ProtectedRoute>
    );
};

export const useDeleteAccountContext = () => {
    return useOutletContext<OutletContextType>();
};
