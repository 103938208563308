import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import {
    useLocation,
    useNavigate,
    useNavigation,
    useOutlet,
    useParams,
} from "react-router-dom";
import { deromanize, romanize } from "romans";

import { getAccountCookie, setAccountCookie } from "../../../../services";

import { useMedia } from "../../../../hooks";
import { useTaskSubcategory } from "../../hooks";
import { useTasksOptions } from "../Tasks";

import { DashboardHeading, TaskHeading } from "../../../../components/headings";
import { ObjectSelectInput, SelectValue } from "../../../../components/inputs";
import { CircleLoader } from "../../../../components/loaders";
import { NotificationTask } from "../../../dashboard/components/NotificationTask";
import {
    Checkbox,
    NavigationBar,
    TaskAccordion,
    TaskButtonGroup,
    TasksFilter,
} from "../../components";
import { ExamIcon } from "../../components/icon-components";

import { getPreviousPath } from "../../../../utils";
import {
    getIsTaskFilterExam,
    getShieldExamName,
    getTaskFilterExamName,
    getTasksFilters,
    setExamFilter,
    taskCategoryQuery,
    tasksQuery,
    userClassLabel,
} from "../../utils";

import { TaskFilterExamType } from "../../typings";

import styles from "./TaskSubcategory.module.css";

export const TaskSubcategory = () => {
    const { userSchool, isSubscriptionPaid } = getAccountCookie();
    const { isDesktop } = useMedia();

    const queryClient = useQueryClient();
    const outlet = useOutlet();
    const params = useParams();
    const { userClassState, tasksFilterState } = useTasksOptions();

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { state } = useNavigation();

    const userClassPrevious = useRef(userSchool.class);
    const [userClass, setUserClass] = userClassState;

    const [isTasksLoading, setIsTasksLoading] = useState(false);
    const { data, refetch } = useTaskSubcategory(
        params.subcategoryId!,
        userClass.value
    );

    const [isTasksFiltered, setIsTasksFiltered] = tasksFilterState;
    const [gridHeight, setGridHeight] = useState<number | undefined>(undefined);
    const isDataEmpty = !data || data.data.exercises.length === 0;
    const isLoadingState = state === "loading";
    const isTheory = pathname.indexOf("theory") !== -1;

    const handleUserClassChange = (value: SelectValue) => {
        setUserClass(value);
        setIsTasksLoading(true);

        queryClient.removeQueries(tasksQuery().queryKey);
        queryClient.removeQueries(taskCategoryQuery().queryKey);

        setAccountCookie({
            userSchool: [userSchool.name, value.value],
        });
    };

    useEffect(() => {
        if (userClass && userClassPrevious.current !== userClass.value) {
            refetch();

            userClassPrevious.current = userClass.value;
        }
    }, [userClass, refetch]);

    useEffect(() => {
        if (isTasksLoading) {
            setTimeout(() => {
                setIsTasksLoading(false);
            }, 1000);
        }
    }, [isTasksLoading]);

    return (
        <>
            <DashboardHeading
                title={data!.data.main_section_name}
                icon={data!.data.image}
                to={outlet ? getPreviousPath(pathname, 2) : undefined}
                userClass={
                    userClass
                        ? getIsTaskFilterExam(userClass.value)
                            ? getShieldExamName(
                                  userClass.value as TaskFilterExamType
                              )
                            : romanize(parseInt(userClass.value))
                        : undefined
                }
                hasSmallSpacing={true}
            />
            <div className={styles.heading}>
                <TaskHeading title={data!.data.subsection_name.toUpperCase()} />
                <div className={styles.buttonGroup}>
                    <TaskButtonGroup
                        progress={[
                            data!.data.premium_exercises_done,
                            data!.data.premium_exercises_total,
                        ]}
                        isTasksActive={!isTheory}
                        isTheoryActive={isTheory}
                        isTheoryDisabled={
                            (!isTasksFiltered &&
                                data!.data.subsection_theory.length === 0) ||
                            (isTasksFiltered &&
                                data!.data.subsection_theory_2.length === 0)
                        }
                        isLoading={isLoadingState || isTasksLoading}
                        isProgressLoading={isTasksLoading}
                        onClick={[
                            () => navigate(getPreviousPath(pathname)),
                            () => navigate("theory"),
                        ]}
                    />
                </div>
            </div>
            <AnimatePresence mode="wait" initial={false}>
                <motion.div
                    key={isTheory ? "theory" : "filters"}
                    className={clsx(
                        styles.content,
                        isDesktop && isTheory && styles.theory
                    )}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ type: "tween", duration: 0.15 }}
                >
                    {isTheory ? (
                        <TaskAccordion
                            content={
                                isTasksFiltered
                                    ? data!.data.subsection_theory_2
                                    : data!.data.subsection_theory
                            }
                        />
                    ) : (
                        <>
                            <div className={styles.utils}>
                                <div className={styles.select}>
                                    <ObjectSelectInput
                                        value={userClass}
                                        selectValues={getTasksFilters(
                                            userSchool.name,
                                            true
                                        ).map((taskFilter) => ({
                                            label: taskFilter.isNumber
                                                ? `${userClassLabel} ${taskFilter.value}`
                                                : getTaskFilterExamName(
                                                      taskFilter.value as TaskFilterExamType
                                                  ),
                                            value: taskFilter.isNumber
                                                ? deromanize(
                                                      taskFilter.value
                                                  ).toString()
                                                : taskFilter.value,
                                        }))}
                                        isLabelCenter={true}
                                        isDisabled={isTasksFiltered}
                                        isLoading={
                                            isLoadingState || isTasksLoading
                                        }
                                        onChange={handleUserClassChange}
                                    />
                                </div>
                                <div className={styles.filter}>
                                    <Checkbox
                                        icon={<ExamIcon />}
                                        isChecked={isTasksFiltered}
                                        isDisabled={
                                            data
                                                ? data.data.exercises.length > 0
                                                    ? data.data.exercises.filter(
                                                          (exercise) =>
                                                              exercise.exercise_type ===
                                                              "Egzaminacyjne"
                                                      ).length > 0
                                                        ? false
                                                        : true
                                                    : true
                                                : true
                                        }
                                        isLoading={
                                            isDataEmpty ||
                                            isLoadingState ||
                                            isTasksLoading
                                        }
                                        onClick={() =>
                                            setIsTasksFiltered((isFiltered) => {
                                                if (isFiltered) {
                                                    setExamFilter(false);

                                                    return false;
                                                } else {
                                                    setExamFilter(true);

                                                    return true;
                                                }
                                            })
                                        }
                                    >
                                        Egzaminacyjne
                                    </Checkbox>
                                </div>
                            </div>
                            <AnimatePresence mode="wait" initial={false}>
                                {data && !isTasksLoading ? (
                                    <motion.div
                                        key="tasksGrid"
                                        initial={{ opacity: 1 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{
                                            type: "tween",
                                            duration: 0.2,
                                        }}
                                    >
                                        <TasksFilter
                                            tasks={data?.data.exercises!}
                                            gridHeightState={[
                                                gridHeight,
                                                setGridHeight,
                                            ]}
                                            isFiltered={isTasksFiltered}
                                            isLoading={isLoadingState}
                                        />
                                    </motion.div>
                                ) : (
                                    <motion.div
                                        key="tasksGridLoading"
                                        className={styles.loading}
                                        initial={{
                                            height: gridHeight,
                                            opacity: 0,
                                        }}
                                        animate={{
                                            height: isDesktop ? 180 : 100,
                                            opacity: 1,
                                        }}
                                        exit={{
                                            height: isDesktop ? 180 : 100,
                                            opacity: 0,
                                        }}
                                        transition={{
                                            type: "tween",
                                            duration: 0.2,
                                        }}
                                        onAnimationComplete={(definition: {
                                            height: number;
                                            opacity: number;
                                        }) => {
                                            if (
                                                (definition.height === 180 ||
                                                    definition.height ===
                                                        100) &&
                                                definition.opacity === 1
                                            ) {
                                                setGridHeight(
                                                    isDesktop ? 180 : 100
                                                );
                                            }
                                        }}
                                    >
                                        <div className={styles.loader}>
                                            <CircleLoader />
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </>
                    )}
                </motion.div>
            </AnimatePresence>

            {!isSubscriptionPaid && (
                <NotificationTask amount={data?.data.premium_exercises_total} />
            )}

            <NavigationBar
                backward={{
                    children: `WRÓĆ DO LISTY ${isTheory ? "ZADAŃ" : "TEMATÓW"}`,
                }}
            />
        </>
    );
};
