import { Toaster } from "react-hot-toast";

import { ShapesContainer } from "../ShapesContainer";
import { AppVersion } from "../../utils";

import styles from "./MainAppContainer.module.css";

type Props = { children: JSX.Element | JSX.Element[] };

export const MainAppContainer = ({ children }: Props) => (
    <>
        <div className={styles.container}>
            {children}
            <Toaster />
            <ShapesContainer />
            <AppVersion version="1.5.29 - BETA" />
        </div>
    </>
);
