import { QueryClient } from "@tanstack/react-query";

import { getAccountCookie } from "../../../../services";

import { teacherStudentsQuery } from "../../utils";

export const teacherStudentsLoader = (queryClient: QueryClient) => async () => {
    const { accountType } = getAccountCookie();

    if (accountType !== "teacher") {
        return undefined;
    }

    const query = teacherStudentsQuery();

    return (
        queryClient.getQueryData(query.queryKey) ??
        queryClient.fetchQuery(query)
    );
};
