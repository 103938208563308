import { mainApi } from "../../../../api";

const time = 0.25 * 60 * 1000;

export const userImportantNotificationQuery = () => ({
    queryKey: ["userImportantNotification"],
    queryFn: async () => mainApi.get("get_user_last_important_notification"),
    staleTime: time,
    refetchInterval: time,
});
