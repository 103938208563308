import { useEffect } from "react";
import { Outlet, useLocation, useOutlet, useParams } from "react-router-dom";
import { romanize } from "romans";

import { useRedirectContext } from "../../../../../contexts/RedirectContext";

import { useLearningProgressCategory } from "../../../hooks";

import { DashboardHeading } from "../../../../../components/headings";
import { NavigationBar } from "../../../../tasks/components";
import { ProgressCategoryButton, ProgressDefault } from "../../../components";

import { getPreviousPath } from "../../../../../utils";

import styles from "./LearningProgressCategory.module.css";

import schoolIcon from "../../../images/learning-progress/school-icon.svg";

export const LearningProgressCategory = () => {
    const outlet = useOutlet();
    const params = useParams();
    const { pathname } = useLocation();

    const { setRedirect } = useRedirectContext();

    const classType = params.classType as string;
    const categoryId = params.categoryId as string;

    const { data } = useLearningProgressCategory(
        "student",
        classType,
        categoryId,
        undefined
    );

    const backwardPathname = getPreviousPath(pathname, 2);

    useEffect(() => {
        if (!outlet) {
            setRedirect(backwardPathname);
        }
    }, [backwardPathname, setRedirect, outlet]);

    return outlet ? (
        <Outlet />
    ) : (
        <>
            {data && (
                <>
                    <DashboardHeading
                        title={data.data.category_name}
                        icon={data.data.category_image_2}
                        to={backwardPathname}
                        userClass={
                            classType === "8_kl" ||
                            classType === "matura_podstawowa" ||
                            classType === "matura_rozszerzona" ? (
                                <img src={schoolIcon} alt="" />
                            ) : (
                                romanize(parseInt(classType))
                            )
                        }
                        hasSmallSpacing={true}
                    />
                    <div className={styles.progress}>
                        <ProgressDefault
                            progress={data.data.progress}
                            title="Ogólny postęp nauki"
                        />
                    </div>
                    <div className={styles.list}>
                        {data.data.subcategories.map((subcategory) => (
                            <ProgressCategoryButton
                                key={subcategory.id}
                                to={subcategory.id}
                                level={subcategory.ZwZ}
                            >
                                {subcategory.name.toUpperCase()}
                            </ProgressCategoryButton>
                        ))}
                    </div>
                </>
            )}
            <NavigationBar
                backward={{
                    children: "WRÓĆ",
                    to: backwardPathname,
                }}
            />
        </>
    );
};
