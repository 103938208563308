import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

type Error = any;

type Mutation = {
    [name: string]: FormDataEntryValue;
};

export const useChangePassword = () => {
    return useMutation<AxiosResponse<any, any>, Error, Mutation, unknown>(
        (data) =>
            mainApi.post("change_password", {
                email: data.email,
                password: data.password,
                password_confirm: data.password_confirm,
            })
    );
};
