import { useRef } from "react";
import { Navigate, Outlet, useLocation, useOutlet } from "react-router-dom";

import { useMedia } from "../../../../hooks";
import { useStudentGetToKnowData } from "../../hooks/queries";

import { RedirectLoading } from "../../components";

import { CompetencyTestLocation } from "../../typings";

export const CompetencyTest = () => {
    const { isDesktop } = useMedia();
    const outlet = useOutlet();
    const { state }: CompetencyTestLocation = useLocation();

    const { data } = useStudentGetToKnowData();
    const studentData = data?.data.data;

    const isRedirected = useRef(state ?? false);

    if (studentData && !isRedirected.current) {
        isRedirected.current = true;

        if (
            !(
                studentData.grade &&
                studentData.scope &&
                studentData.what_is_math
            )
        ) {
            if (isDesktop) {
                return <Redirect to="form/target" />;
            } else {
                return <Redirect to="form" />;
            }
        } else {
            if (studentData.competency_test_done === "0") {
                return <Redirect to="loading" />;
            } else {
                if (studentData.subjects_per_week === "0") {
                    return <Redirect to="schedule" />;
                } else {
                    if (studentData.schedule_visited === "0") {
                        return <Redirect to="notifications" />;
                    } else {
                        return <Redirect to="study-settings" />;
                    }
                }
            }
        }
    }

    return outlet ? <Outlet /> : <RedirectLoading />;
};

const Redirect = ({ to }: { to: string }) => (
    <Navigate to={to} replace={true} state={true} />
);
