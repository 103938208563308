import clsx from "clsx";

import { StorePopover } from "../../../modules/dashboard/components";

import styles from "./StoreHeading.module.css";

type Props = {
    title: string;
    subtitle?: JSX.Element | string;
    popover?: {
        title: string;
        slides: JSX.Element[];
    };
    isAvailableSoon?: boolean;
};

export const StoreHeading = ({
    title,
    subtitle,
    popover,
    isAvailableSoon,
}: Props) => (
    <div
        className={clsx(
            styles.container,
            isAvailableSoon && styles.availableSoon
        )}
    >
        <h4 className={clsx(styles.title, popover && styles.hasPopover)}>
            {title}
            {popover && (
                <div className={styles.popover}>
                    <StorePopover
                        title={popover.title}
                        slides={popover.slides}
                    />
                </div>
            )}
        </h4>
        {(subtitle  || isAvailableSoon) && <h5 className={styles.subtitle}>{isAvailableSoon ? 'Dostępne wkrótce' : subtitle}</h5>}
    </div>
);
