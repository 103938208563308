import { QueryClient } from "@tanstack/react-query";

import { getAccountCookie } from "../../../../services";

import { userSavedCardsQuery, userSubscriptionsQuery } from "../../utils";

export const userSubscriptionsLoader =
    (queryClient: QueryClient) => async () => {
        const { accountType } = getAccountCookie();

        if (accountType !== "student") {
            return undefined;
        }

        const query = userSubscriptionsQuery();
        const query2 = userSavedCardsQuery();

        const data = queryClient.getQueryData(query.queryKey);
        const data2 = queryClient.getQueryData(query2.queryKey);

        return data && data2
            ? { data, data2 }
            : (async () => {
                  await queryClient.fetchQuery(query);
                  await queryClient.fetchQuery(query2);

                  return null;
              })();
    };
