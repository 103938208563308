import { useState } from "react";
import { Link, useNavigation } from "react-router-dom";
import clsx from "clsx";

import { CircleLoader } from "../../../../components/loaders";

import { getIcon } from "./utils";

import { NavigationAccountItemProps } from "./NavigationAccountItem.typings";

import styles from "./NavigationAccountItem.module.css";

export const NavigationAccountItem = ({
    children,
    icon,
    to,
    isActive,
    isImportant,
    onClick,
}: NavigationAccountItemProps) => {
    const { state } = useNavigation();

    const [isClicked, setIsClicked] = useState(false);
    const isLoadingState = state === "loading";

    const onClickHandler = () => {
        setIsClicked(true);

        if (typeof onClick === "function") {
            onClick();
        }
    };

    return (
        <Link
            className={clsx(
                styles.button,
                isActive && styles.active,
                !isActive && isImportant && styles.important,
                (isActive || isLoadingState) && styles.disabled
            )}
            to={to}
            onClick={onClickHandler}
        >
            {children}
            {isLoadingState && isClicked ? (
                <span className={styles.loader}>
                    <CircleLoader color={isImportant ? "red" : null} />
                </span>
            ) : (
                icon && (
                    <span className={styles.icon}>
                        {getIcon(icon, isActive, isImportant)}
                    </span>
                )
            )}
        </Link>
    );
};
