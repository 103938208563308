import { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigation } from "react-router-dom";
import clsx from "clsx";

import { DottedLoader } from "../../../../components/loaders";

import { getPreviousPath } from "../../../../utils";

import {
    NavigationProps,
    ButtonProps,
    ArrowProps,
    ButtonLinkProps,
} from "./NavigationBar.typings";

import styles from "./NavigationBar.module.css";

export const NavigationBar = ({
    backward,
    forward,
    isResultsLoading,
    isNextTaskLoading,
    isNarrowerOnDesktop,
}: NavigationProps) => {
    const { state } = useNavigation();

    const isLoadingState = state === "loading";

    const [browserScrollWidth, setBroswerScrollWidth] = useState(0);

    useEffect(() => {
        const setScrollWidth = () => {
            const dashboardContainer = document.querySelector(
                "#dashboardContainer"
            ) as HTMLDivElement | null;

            if (dashboardContainer) {
                const scrollWidth =
                    dashboardContainer.offsetWidth -
                    dashboardContainer.clientWidth;

                if (scrollWidth !== browserScrollWidth) {
                    setBroswerScrollWidth(scrollWidth);
                }
            }
        };

        setScrollWidth();

        window.addEventListener("resize", setScrollWidth);

        return () => {
            window.removeEventListener("resize", setScrollWidth);
        };
    }, [browserScrollWidth]);

    return (
        <>
            <div
                className={styles.container}
                style={{ paddingRight: browserScrollWidth }}
            >
                <div
                    className={clsx(
                        styles.wrapper,
                        isNarrowerOnDesktop && styles.narrow
                    )}
                >
                    {backward.onClick ? (
                        <Button
                            onClick={backward.onClick}
                            isDisabled={isNextTaskLoading || isResultsLoading}
                            isLoading={isLoadingState}
                        >
                            {backward.children}
                        </Button>
                    ) : (
                        <ButtonLink
                            to={backward.to}
                            isDisabled={isResultsLoading}
                            isLoading={isNextTaskLoading || isLoadingState}
                        >
                            {backward.children}
                        </ButtonLink>
                    )}
                    {forward && (
                        <Button
                            onClick={forward.onClick}
                            isRotated={true}
                            isLoading={
                                isNextTaskLoading ||
                                isResultsLoading ||
                                isLoadingState
                            }
                        >
                            {forward.children}
                        </Button>
                    )}
                </div>
            </div>
            {isNextTaskLoading && <div className={styles.loadingMask}></div>}
        </>
    );
};

const Button = ({
    children,
    isRotated,
    isDisabled,
    isLoading,
    onClick,
}: ButtonProps) => {
    const { pathname } = useLocation();
    const prevPathname = useRef(pathname);

    const [isClicked, setIsClicked] = useState(false);

    const handleClick = () => {
        setIsClicked(true);

        if (onClick) {
            onClick();
        }
    };

    useEffect(() => {
        if (!isLoading) {
            if (prevPathname.current !== pathname) {
                setIsClicked(false);
            }

            prevPathname.current = pathname;
        }
    }, [pathname, isLoading]);

    return (
        <button
            className={clsx(
                styles.button,
                isRotated ? styles.forward : styles.backward,
                isLoading && isClicked && styles.loading
            )}
            type="button"
            disabled={isDisabled || isLoading}
            onClick={handleClick}
        >
            {isLoading && isClicked ? (
                <DottedLoader />
            ) : isRotated ? (
                <>
                    <span className={styles.name}>{children}</span>
                    <span className={styles.arrow}>
                        <Arrow isRotated={true} />
                    </span>
                </>
            ) : (
                <>
                    <span className={styles.arrow}>
                        <Arrow />
                    </span>
                    <span className={styles.name}>{children}</span>
                </>
            )}
        </button>
    );
};

const ButtonLink = ({
    children,
    to,
    isDisabled,
    isLoading,
}: ButtonLinkProps) => {
    const { pathname } = useLocation();
    const prevPathname = useRef(pathname);

    const [isClicked, setIsClicked] = useState(false);

    useEffect(() => {
        if (!isLoading) {
            if (prevPathname.current !== pathname) {
                setIsClicked(false);
            }

            prevPathname.current = pathname;
        }
    }, [pathname, isLoading]);

    return (
        <Link
            className={clsx(
                styles.button,
                styles.backward,
                isLoading && isClicked && styles.loading,
                (isDisabled || isLoading) && styles.disabled
            )}
            to={to ?? getPreviousPath(pathname)}
            onClick={() => setIsClicked(true)}
        >
            {isLoading && isClicked ? (
                <DottedLoader />
            ) : (
                <>
                    <span className={styles.arrow}>
                        <Arrow />
                    </span>
                    <span className={styles.name}>{children}</span>
                </>
            )}
        </Link>
    );
};

const Arrow = ({ isRotated }: ArrowProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="6"
            viewBox="0 0 15 6"
        >
            <path
                id="Icon_awesome-long-arrow-alt-left"
                data-name="Icon awesome-long-arrow-alt-left"
                d="M4.489,14.348H14.6a.37.37,0,0,0,.4-.327V12.5a.37.37,0,0,0-.4-.327H4.489V10.913c0-.583-.866-.874-1.372-.462L.235,12.8a.572.572,0,0,0,0,.925l2.881,2.345c.506.412,1.372.12,1.372-.462Z"
                transform={
                    isRotated
                        ? "translate(15 16.258) rotate(180)"
                        : "translate(0 -10.258)"
                }
                fill="#2eafbe"
            />
        </svg>
    );
};
