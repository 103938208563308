import { motion } from "framer-motion";
import clsx from "clsx";

import { useMedia } from "../../../hooks";

import { transition } from "./BackgroundContainer.animations";

import { BackgroundContainerProps } from "./BackgroundContainer.typings";

import styles from "./BackgroundContainer.module.css";

export const BackgroundContainer = ({
    children,
    leftElement,
    rightElement,
}: BackgroundContainerProps) => {
    const { isDesktop } = useMedia();

    return (
        <div className={styles.container}>
            {children}
            {isDesktop && leftElement && (
                <motion.div
                    className={clsx(
                        styles.element,
                        styles.left,
                        leftElement.isBottom && styles.bottom
                    )}
                    initial={{
                        x: leftElement.position.x,
                        y: leftElement.position.y,
                    }}
                    animate={{
                        x: leftElement.position.x,
                        y: leftElement.position.y,
                    }}
                    transition={transition}
                >
                    <img
                        src={leftElement.image}
                        alt=""
                        style={{
                            width: leftElement.size.width,
                            height: leftElement.size.height,
                        }}
                    />
                </motion.div>
            )}
            {isDesktop && rightElement && (
                <motion.div
                    className={clsx(styles.element, styles.right)}
                    initial={{
                        x: `${(rightElement.position.x * 100) / 1920}vw`,
                        y: rightElement.position.y,
                    }}
                    animate={{
                        x: `${(rightElement.position.x * 100) / 1920}vw`,
                        y: rightElement.position.y,
                    }}
                    transition={transition}
                >
                    <img
                        src={rightElement.image}
                        alt=""
                        style={{
                            width: rightElement.size.width,
                            height: rightElement.size.height,
                        }}
                    />
                </motion.div>
            )}
        </div>
    );
};
