import clsx from "clsx";

import styles from "./MainLogo.module.css";

import logo from "../../../assets/images/logos/smart-tutor_logo.svg";

type Props = {
    isCenter?: boolean;
    isSmall?: boolean;
};

export const MainLogo = ({ isCenter, isSmall }: Props) => {
    return (
        <div
            className={clsx(
                styles.logoHolder,
                isSmall && styles.isSmall,
                isCenter && styles.isCenter
            )}
        >
            <img className={styles.logoImage} src={logo} alt="Smart Tutor" />
        </div>
    );
};
