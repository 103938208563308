import { useState, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { useNotify } from "../../../../hooks";
import { usePlaceChildOrder, usePlaceOrder } from "../../hooks";

import { StorePaymentMethods } from "../StorePaymentMethods";
import { StorePaymentForm } from "../StorePaymentForm";

import { getOperatingSystem } from "../../../../utils";
import { concatenateCreditCardData, encodeCreditCardData } from "../../utils";
import { getCardNumberShortcode, getCardValidation } from "./utils";
import { getCardType } from "../StorePaymentForm/utils";

import {
    changeAnimations,
    getWrapperAnimations,
} from "./StorePayment.animations";

import { CardFormData } from "../../typings";
import { StorePaymentProps } from "./StorePayment.typings";

import styles from "./StorePayment.module.css";

import cardIcon from "./images/card-icon.svg";
import tpayIcon from "./images/tpay-icon.png";
import blikIcon from "./images/blik-icon.png";

export const StorePayment = ({
    data,
    cardPaymentMethodState,
}: StorePaymentProps) => {
    const operatingSystem = getOperatingSystem();
    const [isCardPaymentMethod, setIsCardPaymentMethod] =
        cardPaymentMethodState;

    const notify = useNotify({ type: "error", position: "bottom-center" });

    const [containerHeight, setContainerHeight] = useState<number | undefined>(
        undefined
    );
    const selectMethodRef = useRef<HTMLDivElement | null>(null);
    const cardMethodRef = useRef<HTMLDivElement | null>(null);

    const { mutate: placeOrder } = usePlaceOrder();
    const { mutate: placeChildOrder } = usePlaceChildOrder();

    const [cardSavedId, setCardSavedId] = useState<string | undefined>(
        undefined
    );
    const [cardData, setCardData] = useState<CardFormData>({
        number: {
            value: "",
            response: "",
        },
        owner: {
            value: "",
            response: "",
        },
        validThru: {
            value: "",
            response: "",
        },
        cvc: {
            value: "",
            response: "",
        },
    });
    const isCardValid = getCardValidation(cardData);
    const [order, setOrder] = useState<
        [boolean, "card" | "online" | "googlepay" | "applepay" | ""]
    >([false, ""]);

    const handleOrder = () => {
        const gpayToken =
            operatingSystem === "android"
                ? (
                      document.querySelector(
                          "#gpayInput"
                      ) as HTMLInputElement | null
                  )?.value
                : null;

        if (isCardPaymentMethod) {
            setOrder([true, "card"]);
        } else if (gpayToken) {
            setOrder([true, "googlepay"]);
        } else {
            setOrder([true, "online"]);
        }

        if (data.userId) {
            placeChildOrder(
                {
                    ...data,
                    childrenId: data.userId,
                    ...(gpayToken && {
                        gpayToken,
                    }),
                    ...(isCardValid &&
                        (cardSavedId
                            ? {
                                  cardSavedId,
                              }
                            : {
                                  card: {
                                      hash: encodeCreditCardData(
                                          concatenateCreditCardData(
                                              cardData.number.value,
                                              cardData.validThru.value,
                                              cardData.cvc.value
                                          )
                                      ),
                                      vendor: getCardType(
                                          cardData.number.value
                                      )!.type,
                                      shortcode: getCardNumberShortcode(
                                          cardData.number.value
                                      ),
                                  },
                              })),
                },
                {
                    onError: () => {
                        if (isCardPaymentMethod) {
                            setOrder([false, ""]);
                        }

                        notify("Składanie zamówienia nie powiodło się.");
                    },
                }
            );
        } else {
            placeOrder(
                {
                    ...data,
                    ...(gpayToken && {
                        gpayToken,
                    }),
                    ...(isCardValid &&
                        (cardSavedId
                            ? {
                                  cardSavedId,
                              }
                            : {
                                  card: {
                                      hash: encodeCreditCardData(
                                          concatenateCreditCardData(
                                              cardData.number.value,
                                              cardData.validThru.value,
                                              cardData.cvc.value
                                          )
                                      ),
                                      vendor: getCardType(
                                          cardData.number.value
                                      )!.type,
                                      shortcode: getCardNumberShortcode(
                                          cardData.number.value
                                      ),
                                  },
                              })),
                },
                {
                    onError: () => {
                        if (isCardPaymentMethod) {
                            setOrder([false, ""]);
                        }

                        notify("Składanie zamówienia nie powiodło się.");
                    },
                }
            );
        }
    };

    // const handleAndroidOrder = () => {
    //     if (operatingSystem === "android") {
    //         (window as any).ReactNativeWebView.postMessage(data.price);
    //     }
    // };

    return (
        <>
            <div className={styles.container}>
                <motion.div
                    className={styles.wrapper}
                    {...getWrapperAnimations(containerHeight)}
                >
                    <AnimatePresence mode="wait" initial={false}>
                        {isCardPaymentMethod ? (
                            <motion.div
                                key="cardMethod"
                                ref={cardMethodRef}
                                {...changeAnimations}
                                onAnimationStart={(definition: {
                                    opacity: 0 | 1;
                                }) => {
                                    if (cardMethodRef.current) {
                                        if (definition.opacity === 1) {
                                            setContainerHeight(
                                                cardMethodRef.current
                                                    .clientHeight
                                            );
                                        }
                                    }
                                }}
                            >
                                <h3 className={styles.title}>DODAJ KARTĘ</h3>
                                <p className={styles.subtitle}>
                                    Używając karty, subskrypcja zostanie
                                    odnowiona automatycznie.
                                    <br />
                                    Subskrypcję możesz anulować z panelu
                                    Abonamentu przez odpięcie karty.
                                </p>
                                <StorePaymentForm
                                    data={cardData}
                                    setData={setCardData}
                                    savedId={cardSavedId}
                                    setSavedId={setCardSavedId}
                                    isValid={isCardValid}
                                    isLoading={order[0]}
                                    isSubmitting={order[1] === "card"}
                                    onSubmit={handleOrder}
                                    onCancel={() =>
                                        setIsCardPaymentMethod(false)
                                    }
                                />
                            </motion.div>
                        ) : (
                            <motion.div
                                key="selectMethod"
                                ref={selectMethodRef}
                                {...changeAnimations}
                                onAnimationStart={(definition: {
                                    opacity: 0 | 1;
                                }) => {
                                    if (
                                        selectMethodRef.current &&
                                        (!containerHeight ||
                                            definition.opacity === 1)
                                    ) {
                                        setContainerHeight(
                                            selectMethodRef.current.clientHeight
                                        );
                                    }
                                }}
                            >
                                <h3 className={styles.title}>
                                    WYBIERZ FORMĘ PŁATNOŚCI
                                </h3>
                                <StorePaymentMethods
                                    methods={[
                                        {
                                            label: "karta",
                                            icon: cardIcon,
                                            isDisabled: order[0],
                                            onClick: () =>
                                                setIsCardPaymentMethod(true),
                                        },
                                        {
                                            label: "płatność online",
                                            icon: tpayIcon,
                                            icon2: blikIcon,
                                            isDisabled: order[0],
                                            isLoading: order[1] === "online",
                                            onClick: handleOrder,
                                        },
                                        // operatingSystem === "android" && {
                                        //     label: "google pay",
                                        //     icon: gpayIcon,
                                        //     isDisabled: order[0],
                                        //     isLoading: order[1] === "googlepay",
                                        //     onClick: handleAndroidOrder,
                                        // },
                                    ]}
                                />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </motion.div>
            </div>
            {operatingSystem === "android" && (
                <div className={styles.androidOrder}>
                    <input id="gpayInput" type="text" />
                    <button
                        id="gpayPaymentHandler"
                        type="button"
                        onClick={handleOrder}
                    />
                </div>
            )}
        </>
    );
};
