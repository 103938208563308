import { getColor } from "../utils";
import { IconProps } from "./Icons.typings";

export const PersonIcon = ({ isActive, isImportant }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
    >
        <path
            id="Icon_material-person"
            data-name="Icon material-person"
            d="M21,21a7.5,7.5,0,1,0-7.5-7.5A7.5,7.5,0,0,0,21,21Zm0,3.75c-5.006,0-15,2.512-15,7.5V36H36V32.25C36,27.262,26.006,24.75,21,24.75Z"
            transform="translate(-6 -6)"
            fill={getColor(
                isActive ? "active" : isImportant ? "important" : "default"
            )}
            opacity="1"
        />
    </svg>
);
