import { getOperatingSystem } from "../../../../utils";

export const getCollapseVariants = (
    operatingSystem: ReturnType<typeof getOperatingSystem>
) => ({
    open: {
        height: "5px",
    },
    closed: {
        height: `calc(100${operatingSystem ? "vh" : "dvh"} - 63px)`,
    },
});
