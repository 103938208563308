import { DefaultButton } from "../../../../components/buttons";

import { ReactComponent as ExclamationIcon } from "./images/exclamation-icon.svg";
import styles from "./NotificationTask.module.css";

type Props = {
    amount?: number;
};

export const NotificationTask = ({ amount }: Props) => {
    return (
        <div className={styles.container}>
            <div className={styles.icon}>
                <ExclamationIcon />
            </div>
            <div className={styles.content}>
                <p className={styles.red}>Korzystasz z darmowej wersji</p>

                {amount && (
                    <p className={styles.primary}>
                        W wersji PREMIUM w tym zagadnieniu
                        <b> dostępne {amount} zadań</b> z rozwiązaniami video
                    </p>
                )}

                <DefaultButton
                    className={styles.button}
                    to="/dashboard/student/account/subscription"
                >
                    Przejdź na PREMIUM
                </DefaultButton>
            </div>
        </div>
    );
};
