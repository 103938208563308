const styles = {
    color: {
        default: "#FFFFFF",
        success: "#2EAFBE",
        error: "#FE5A5E",
        disabled: "#E5E7EA",
        disabled2: "#879195",
    },
    radius: {
        closed: "23px 23px 23px 23px",
        open: "23px 23px 0px 0px",
    },
};

const labelClosed = {
    y: 0,
    fontSize: "16px",
    lineHeight: "18px",
};

const labelOpen = {
    y: -31,
    fontSize: "13px",
    lineHeight: "15px",
};

const labelVariants = {
    closed: {
        ...labelClosed,
        color: styles.color.disabled2,
    },
    closed_error: {
        ...labelClosed,
        color: styles.color.error,
    },
    open: { ...labelOpen },
    error: { ...labelOpen, color: styles.color.error },
    success: { ...labelOpen, color: styles.color.success },
    disabled: { ...labelOpen, color: styles.color.disabled2 },
    closed_disabled: { ...labelClosed, color: styles.color.disabled2 },
};

const inputVariants = {
    closed: {
        background: styles.color.default,
        borderColor: styles.color.default,
        borderRadius: styles.radius.closed,
    },
    open: {
        background: styles.color.default,
        borderColor: styles.color.success,
        borderRadius: styles.radius.open,
    },
    success: {
        background: styles.color.default,
        borderColor: styles.color.success,
        borderRadius: styles.radius.closed,
    },
    error: {
        background: styles.color.default,
        borderColor: styles.color.error,
        borderRadius: styles.radius.closed,
    },
    disabled: {
        background: styles.color.disabled,
        borderColor: styles.color.disabled,
        borderRadius: styles.radius.closed,
    },
    disabled_error: {
        background: styles.color.disabled,
        borderColor: styles.color.error,
        borderRadius: styles.radius.closed,
    },
};

const dropdownVariants = {
    closed: {
        height: 0,
        borderColor: styles.color.default,
        opacity: 0,
        scale: 0,
        originY: 0,
    },
    open: {
        height: "auto",
        borderColor: styles.color.success,
        opacity: 1,
        scale: 1,
        originY: 0,
    },
};

export { labelVariants, inputVariants, dropdownVariants };
