import { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigation } from "react-router-dom";
import clsx from "clsx";

import { ConditionalWrapper } from "../../wrappers";
import { CircleLoader } from "../../loaders";

import { ArrowButtonProps, ButtonProps } from "./ArrowButton.typings";

import styles from "./ArrowButton.module.css";

export const ArrowButton = ({
    to,
    wrapper,
    wrapperType = "div",
    children,
    isReversed,
    isBorderless,
    isDisabled,
    onClick,
}: ArrowButtonProps) => {
    const { state } = useNavigation();
    const { pathname } = useLocation();
    const prevPathname = useRef(pathname);

    const [isClicked, setIsClicked] = useState(false);
    const isLoadingState = state === "loading";

    const handleClick = () => {
        if (onClick) {
            onClick();
        }

        setIsClicked(true);
    };

    useEffect(() => {
        if (prevPathname.current !== pathname) {
            setIsClicked(false);
        }

        prevPathname.current = pathname;
    }, [pathname]);

    return (
        <ConditionalWrapper wrapper={wrapper} wrapperType={wrapperType}>
            <Button
                id="goBackButton"
                className={clsx(
                    styles.button,
                    isReversed && styles.reversed,
                    isBorderless && children && styles.borderless,
                    (isDisabled || isLoadingState) && styles.disabled,
                    !children && styles.standalone
                )}
                to={to}
                isLink={
                    typeof onClick === "function" ? false : to ? true : false
                }
                onClick={handleClick}
            >
                {children ? (
                    isReversed ? (
                        <>
                            {children}
                            {isLoadingState && isClicked ? (
                                <Loader />
                            ) : (
                                <Arrow />
                            )}
                        </>
                    ) : (
                        <>
                            {isLoadingState && isClicked ? (
                                <Loader />
                            ) : (
                                <Arrow />
                            )}
                            {children}
                        </>
                    )
                ) : isLoadingState && isClicked ? (
                    <Loader />
                ) : (
                    <Arrow />
                )}
            </Button>
        </ConditionalWrapper>
    );
};

const Button = ({
    id,
    className,
    to,
    children,
    isLink,
    onClick,
}: ButtonProps) => {
    if (isLink && to) {
        return (
            <Link id={id} className={className} to={to} onClick={onClick}>
                {children}
            </Link>
        );
    } else {
        return (
            <button
                id={id}
                className={className}
                type="button"
                onClick={onClick}
            >
                {children}
            </button>
        );
    }
};

const Arrow = () => {
    return (
        <svg
            className={styles.arrow}
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
        >
            <path
                id="Icon_awesome-arrow-circle-left"
                data-name="Icon awesome-arrow-circle-left"
                d="M15.563,30.563a15,15,0,1,1,15-15A15,15,0,0,1,15.563,30.563Zm1.748-8.685L12.744,17.5H23.788a1.448,1.448,0,0,0,1.452-1.452v-.968a1.448,1.448,0,0,0-1.452-1.452H12.744L17.31,9.248a1.453,1.453,0,0,0,.024-2.075l-.665-.659a1.446,1.446,0,0,0-2.05,0l-8.026,8.02a1.446,1.446,0,0,0,0,2.05l8.026,8.026a1.446,1.446,0,0,0,2.05,0l.665-.659A1.453,1.453,0,0,0,17.31,21.877Z"
                transform="translate(-0.563 -0.563)"
                fill="#2eafbe"
            />
        </svg>
    );
};

const Loader = () => {
    return (
        <span className={styles.loader}>
            <CircleLoader color="white" size="18" />
        </span>
    );
};
