import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { taskCategoryQuery } from "../../utils";

import { TaskCategoryResponse } from "../../typings";

export const useTaskCategory = (categoryId: string, userClass: string) => {
    return useQuery<AxiosResponse<TaskCategoryResponse, any>>(
        taskCategoryQuery(categoryId, userClass)
    );
};
