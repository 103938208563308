const setFormData = (
    formData: FormData,
    data: any,
    parentKey?: string
): void => {
    if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
    ) {
        Object.keys(data).forEach((key) => {
            setFormData(
                formData,
                data[key],
                parentKey ? `${parentKey}[${key}]` : key
            );
        });
    } else {
        const value = data == null ? "" : data;

        formData.append(parentKey ?? "", value);
    }
};

export const buildFormData = (data: any) => {
    const formData = new FormData();

    setFormData(formData, data);

    return formData;
};