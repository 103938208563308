import { useLocation, useNavigation } from "react-router-dom";

import { setTaskParams } from "../../services";

import { ProgressStats } from "../../../dashboard/components";
import { TaskItem } from "../TaskItem";

import { createGridRepeat, getPreviousPath } from "../../../../utils";
import { taskSlug } from "../../utils";

import { TaskLearningProgressProps } from "./TaskLearningProgress.typings";

import styles from "./TasksLearningProgress.module.css";

export const TasksLearningProgress = ({
    progress,
    tasks,
    params,
}: TaskLearningProgressProps) => {
    const { pathname } = useLocation();
    const { state } = useNavigation();

    return (
        <div className={styles.container}>
            <div className={styles.progress}>
                <ProgressStats
                    progress={{
                        good: progress.good,
                        bad: progress.bad,
                        total: progress.total,
                    }}
                />
            </div>
            <div
                className={styles.tasks}
                style={{
                    gridTemplateRows: createGridRepeat(tasks.length),
                }}
            >
                {tasks.length > 0 ? (
                    tasks.map((task, index) => (
                        <div className={styles.task} key={index}>
                            <TaskItem
                                to={
                                    params
                                        ? `${getPreviousPath(
                                              pathname,
                                              4
                                          )}/task/${
                                              taskSlug.learning_progress
                                          }/${task.id}`
                                        : ""
                                }
                                level={task.difficulty}
                                isExam={task.exercise_type === "Egzaminacyjne"}
                                isSuccess={
                                    task.times_tried !== "0" &&
                                    task.done_correctly === "1"
                                }
                                isError={
                                    task.times_tried !== "0" &&
                                    task.done_correctly === "0"
                                }
                                isLoading={state === "loading"}
                                onClick={
                                    params
                                        ? () =>
                                              setTaskParams({
                                                  type: taskSlug.learning_progress,
                                                  taskId: task.id,
                                                  additionalId: `${params.categoryId}/${params.subcategoryId}/${params.classType}`,
                                              })
                                        : undefined
                                }
                            >
                                {`Zadanie ${index + 1}`}
                            </TaskItem>
                        </div>
                    ))
                ) : (
                    <div className={styles.task}>
                        <div className={styles.empty}>
                            Brak zadań do wyświetlenia
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
