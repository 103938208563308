export const LogoIcon = () => (
    <svg
        id="Group_251"
        data-name="Group 251"
        xmlns="http://www.w3.org/2000/svg"
        width="17.185"
        height="21.528"
        viewBox="0 0 17.185 21.528"
    >
        <g
            id="Path_950"
            data-name="Path 950"
            transform="translate(16.933 0) rotate(90)"
            fill="none"
        >
            <path
                d="M7.21,1.368a2.841,2.841,0,0,1,4.859,0L18.877,12.7a2.8,2.8,0,0,1-2.43,4.234H2.832A2.8,2.8,0,0,1,.4,12.7Z"
                stroke="none"
            />
            <path
                d="M 9.639828681945801 1.5 C 9.41166877746582 1.5 8.84346866607666 1.562459945678711 8.495868682861328 2.140990257263184 L 1.687957763671875 13.47169017791748 C 1.340438842773438 14.05008983612061 1.560129165649414 14.56715965270996 1.670719146728516 14.762619972229 C 1.784788131713867 14.96423053741455 2.126289367675781 15.43317031860352 2.831918716430664 15.43317031860352 L 16.44773864746094 15.43317031860352 C 17.15336799621582 15.43317031860352 17.49486923217773 14.96423053741455 17.60893821716309 14.762619972229 C 17.71952819824219 14.56715965270996 17.93921852111816 14.05008983612061 17.59169769287109 13.47169017791748 L 10.78378868103027 2.140990257263184 C 10.43617820739746 1.562459945678711 9.867988586425781 1.5 9.639828681945801 1.5 M 9.639824867248535 0 C 10.58061408996582 0 11.52140426635742 0.4561500549316406 12.06954860687256 1.368450164794922 L 18.8774585723877 12.69915008544922 C 19.99936866760254 14.56639003753662 18.64117813110352 16.93317031860352 16.44773864746094 16.93317031860352 L 2.831918716430664 16.93317031860352 C 0.6384792327880859 16.93317031860352 -0.7197113037109375 14.56639003753662 0.4021987915039062 12.69915008544922 L 7.210108757019043 1.368450164794922 C 7.758248329162598 0.4561500549316406 8.699036598205566 0 9.639824867248535 0 Z"
                stroke="none"
                fill="#2eafbe"
            />
        </g>
        <line
            id="Line_11"
            data-name="Line 11"
            y1="7.239"
            x2="11.952"
            transform="translate(4.203 13.259)"
            fill="none"
            stroke="#2eafbe"
            strokeLinecap="round"
            strokeWidth="1.5"
        />
    </svg>
);
