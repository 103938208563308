import { useLocation, useParams } from "react-router-dom";

import { taskSlug } from "../modules/tasks/utils";
import { TaskType } from "../modules/tasks/typings";

export const useIsTaskPage = (taskType?: TaskType | "generic") => {
    const { pathname } = useLocation();
    const params = useParams();

    const taskId = params.taskId;

    if (taskId) {
        let isTaskPage = false;

        if (taskType) {
            if (taskType === "generic") {
                if (pathname.indexOf("/task/") > 0) {
                    isTaskPage = true;
                }
            } else {
                if (pathname.indexOf(`task/${taskType}/${params.taskId}`) > 0) {
                    isTaskPage = true;
                }
            }
        } else {
            Object.values(taskSlug).forEach((type) => {
                if (pathname.indexOf(`task/${type}/${params.taskId}`) > 0) {
                    isTaskPage = true;
                }
            });
        }

        return isTaskPage;
    } else {
        return false;
    }
};
