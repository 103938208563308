export const getProgressStatus = (int?: number) => {
    if(int) {
        if (int < 33.3333) {
            return "low";
        } else if (int > 33.3334 && int < 66.6666) {
            return "medium";
        } else {
            return "high";
        }
    } else {
        return undefined;
    }
};
