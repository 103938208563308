import clsx from "clsx";

import styles from "./TaskContainer.module.css";

type Props = {
    children: JSX.Element | JSX.Element[];
    footerElement?: JSX.Element;
};

export const TaskContainer = ({ children, footerElement }: Props) => (
    <div className={clsx(styles.container, footerElement && styles.flex)}>
        <div className={styles.body}>{children}</div>
        {footerElement && <div className={styles.footer}>{footerElement}</div>}
    </div>
);
