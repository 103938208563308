import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

import { setAccountCookie } from "../../../../services";

import {
    setExamFilter,
    taskCategoryQuery,
    tasksQuery,
    taskSubcategoryQuery,
} from "../../../tasks/utils";

import { useOptimisticUserData } from "../optimistic-updates";

import { SchoolType } from "../../../../typings";
import {
    learningProgressCategoryQuery,
    learningProgressQuery,
    learningProgressSubcategoryQuery,
    userAdvancementDataQuery,
} from "../../utils";

type Response = {
    token: string;
    is_active: boolean;
    is_parent_approved: boolean;
    email: string;
    type: string;
};

type Error = {
    response: {
        data: {
            messages: {
                email?: string;
                error?: string;
            };
        };
        status: number;
    };
};

type Mutation = {
    [name: string]: FormDataEntryValue;
};

export const useUpdateUserData = () => {
    const queryClient = useQueryClient();

    const tasksQueryKey = tasksQuery().queryKey;
    const taskCategoryQueryKey = taskCategoryQuery().queryKey;
    const taskSubcategoryQueryKey = taskSubcategoryQuery().queryKey;

    const learningProgressQueryKey = learningProgressQuery('student').queryKey;
    const learningProgressCategoryQueryKey =
        learningProgressCategoryQuery('student').queryKey;
    const learningProgressSubcategoryQueryKey =
        learningProgressSubcategoryQuery('student').queryKey;

    const userAdvancementDataQueryKey = userAdvancementDataQuery().queryKey;

    const updateOptimisticUserData = useOptimisticUserData();

    return useMutation<AxiosResponse<Response, any>, Error, Mutation, unknown>(
        (data) => {
            updateOptimisticUserData(data);

            if (data.is_children === "true") {
                queryClient.removeQueries(tasksQueryKey);
                queryClient.removeQueries(taskCategoryQueryKey);
                queryClient.removeQueries(taskSubcategoryQueryKey);

                queryClient.removeQueries(learningProgressQueryKey);
                queryClient.removeQueries(learningProgressCategoryQueryKey);
                queryClient.removeQueries(learningProgressSubcategoryQueryKey);

                setExamFilter(false);

                setAccountCookie({
                    userSchool: [
                        data.school as SchoolType,
                        data.class as string,
                    ],
                });
            }

            return mainApi.post("update_user_data", {
                city: data.cityId,
                first_name: data.first_name,
                last_name: data.last_name,
                ...(data.is_children === "true" && {
                    school: data.school,
                    class: data.class,
                    birthday: data.birthday,
                    parent_email: data.parent_email,
                }),
            });
        },
        {
            onSuccess: (_, variables) => {
                if (variables.is_children === "true") {
                    queryClient.invalidateQueries(userAdvancementDataQueryKey);
                }
            },
        }
    );
};
