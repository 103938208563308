import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { childrenTeachersQuery } from "../../utils";

import { UserListResponse } from "../../typings";

export const useChildrenTeachers = (isEnabled?: boolean) => {
    return useQuery<AxiosResponse<UserListResponse, any>>(
        childrenTeachersQuery(isEnabled)
    );
};
