import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { getAccountCookie } from "../../../../services";

import { useMedia } from "../../../../hooks";

import styles from "./NavbarBrand.module.css";

import mobileLogo from "./images/logo-mobile.svg";
import desktopLogo from "./images/logo-desktop.svg";

import {
    BrandLinkProps,
    NavbarBrandProps,
    SusbcriptionBadgeProps,
} from "./NavbarBrand.typings";

export const NavbarBrand = ({
    accountType,
    isAction,
    isDisabled,
    onClick,
}: NavbarBrandProps) => {
    const { isSubscriptionPaid } = getAccountCookie();
    const { isDesktop } = useMedia();

    return (
        <div className={styles.container}>
            {isDesktop ? (
                <BrandLink
                    accountType={accountType}
                    isSubscriptionPaid={isSubscriptionPaid}
                    isDisabled={isDisabled}
                    isDesktop={true}
                />
            ) : (
                <>
                    <AnimatePresence mode="wait" initial={false}>
                        {isAction && (
                            <motion.div
                                className={styles.text}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{
                                    type: "tween",
                                    duration: 0.5,
                                }}
                            >
                                Zwiń
                            </motion.div>
                        )}
                    </AnimatePresence>
                    {typeof onClick === "function" ? (
                        <button
                            className={styles.button}
                            type="button"
                            disabled={isDisabled}
                            onClick={onClick}
                        >
                            <img src={mobileLogo} alt="" />
                            {accountType === "student" && (
                                <SubscriptionBadge
                                    isSubscriptionPaid={isSubscriptionPaid}
                                />
                            )}
                        </button>
                    ) : (
                        <BrandLink
                            accountType={accountType}
                            isSubscriptionPaid={isSubscriptionPaid}
                            isDisabled={isDisabled}
                        />
                    )}
                </>
            )}
        </div>
    );
};

const BrandLink = ({
    accountType,
    isSubscriptionPaid,
    isDisabled,
    isDesktop,
}: BrandLinkProps) => (
    <Link
        className={clsx(styles.button, isDisabled && styles.disabled)}
        to={`/dashboard/${accountType}`}
    >
        <img src={isDesktop ? desktopLogo : mobileLogo} alt="" />
        {accountType === "student" && (
            <SubscriptionBadge isSubscriptionPaid={isSubscriptionPaid} />
        )}
    </Link>
);

const SubscriptionBadge = ({ isSubscriptionPaid }: SusbcriptionBadgeProps) => (
    <span className={clsx(styles.badge, isSubscriptionPaid && styles.premium)}>
        {isSubscriptionPaid ? "premium" : "free"}
    </span>
);
