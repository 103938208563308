import { StoreDiscountState } from "../../typings";

export const getLoadedDiscountCode = (
    discount:
        | {
              code: string;
              discount: string;
              packages: string;
          }
        | undefined
): StoreDiscountState =>
    discount
        ? {
              value: discount.code,
              response: {
                  code: discount.code,
                  discount: parseInt(discount.discount),
                  packages: discount.packages.split(","),
              },
          }
        : {
              value: "",
              response: undefined,
          };
