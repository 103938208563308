import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

import { validateEmail } from "./utils";

import {
    collapseVariants,
    labelVariants,
    inputVariants,
    buttonVariants,
    iconVariants,
} from "./InviteInput.animations";
import { InviteInputProps } from "./InviteInput.typings";

import styles from "./InviteInput.module.css";

import plusIcon from "./images/plus-icon.svg";
import checkmarkIcon from "./images/checkmark-icon.svg";

export const InviteInput = ({
    className,
    title,
    label,
    value,
    mutation,
    isBottom,
    onChange,
}: InviteInputProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const isFilled = value.length > 0;

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;

        if (!validateEmail(value) && value.length > 0) {
            setIsError(true);
        } else if (isError) {
            setIsError(false);
        }

        onChange(value);
    };

    const handleOnKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const isEnter = event.charCode === 13 || event.code === "Enter";

        if (
            isEnter &&
            isFilled &&
            !isError &&
            !isSubmitted &&
            !mutation.isLoading
        ) {
            handleSubmit();

            event.currentTarget.blur();
        }
    };

    const handleSubmit = () => {
        if (!isSubmitted) {
            if (isFilled && !isError && !mutation.isLoading) {
                setIsSubmitted(true);

                mutation.mutate({ action: "invite", email: value });
            } else {
                onChange("");

                if (isError) {
                    setIsError(false);
                }

                setIsOpen(false);
            }
        }
    };

    useEffect(() => {
        if (isSubmitted) {
            setTimeout(() => {
                setIsOpen(false);
            }, 2000);
        }
    }, [isSubmitted]);

    useEffect(() => {
        if (!isOpen && isSubmitted) {
            onChange("");

            setIsSubmitted(false);
        }
    }, [isOpen, isSubmitted, onChange]);

    return (
        <motion.div
            className={clsx(className, isBottom && styles.bottom)}
            initial="closed"
            animate={isOpen ? "open" : "closed"}
            variants={collapseVariants}
            transition={{ type: "tween", duration: 0.3 }}
        >
            <AnimatePresence mode="wait" initial={false}>
                <motion.div
                    key={`isOpen_${isOpen}`}
                    initial={{
                        opacity: 0,
                        x: -5,
                    }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 5 }}
                    transition={{ type: "tween", duration: 0.2 }}
                >
                    {isOpen ? (
                        <div className={clsx(styles.field)}>
                            <motion.label
                                className={styles.label}
                                animate={
                                    isSubmitted
                                        ? "submitted"
                                        : isFilled
                                        ? isError
                                            ? "error"
                                            : "success"
                                        : isFocused
                                        ? "active"
                                        : "static"
                                }
                                initial={
                                    isFilled
                                        ? isError
                                            ? "error"
                                            : "success"
                                        : "static"
                                }
                                variants={labelVariants}
                                transition={{
                                    type: "tween",
                                    duration: 0.3,
                                }}
                            >
                                {label}
                            </motion.label>
                            <motion.input
                                className={clsx(styles.input)}
                                type="email"
                                value={value}
                                autoComplete="off"
                                initial={
                                    isFilled
                                        ? isError
                                            ? "error"
                                            : "success"
                                        : "static"
                                }
                                animate={
                                    isSubmitted
                                        ? "submitted"
                                        : isFilled
                                        ? isError
                                            ? "error"
                                            : "success"
                                        : "static"
                                }
                                variants={inputVariants}
                                transition={{
                                    type: "tween",
                                    duration: 0.3,
                                }}
                                disabled={isSubmitted || mutation.isLoading}
                                onKeyPress={handleOnKeyPress}
                                onFocus={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                                onChange={handleOnChange}
                            />
                            {isSubmitted && (
                                <motion.div
                                    className={styles.meta}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{
                                        type: "tween",
                                        duration: 0.3,
                                    }}
                                >
                                    Zaproszenie zostało wysłane
                                </motion.div>
                            )}
                            <motion.button
                                className={clsx(
                                    styles.submitButton,
                                    isSubmitted && styles.disabled
                                )}
                                type="submit"
                                initial="error"
                                animate={
                                    isFilled
                                        ? isError
                                            ? "error"
                                            : "success"
                                        : "error"
                                }
                                variants={buttonVariants}
                                transition={{
                                    type: "tween",
                                    duration: 0.3,
                                }}
                                disabled={isSubmitted || mutation.isLoading}
                                onClick={handleSubmit}
                            >
                                <AnimatePresence mode="wait">
                                    <motion.div
                                        key={`isSubmitted_${isSubmitted}`}
                                        initial={{
                                            y: isSubmitted ? -20 : 0,
                                            opacity: isSubmitted ? 0 : 1,
                                        }}
                                        animate={{ y: 0, opacity: 1 }}
                                        exit={{ y: 20, opacity: 0 }}
                                        transition={{
                                            type: "tween",
                                            duration: 0.2,
                                        }}
                                    >
                                        {isSubmitted ? (
                                            <img
                                                className={styles.submitIcon}
                                                src={checkmarkIcon}
                                                alt=""
                                            />
                                        ) : (
                                            <motion.img
                                                className={styles.submitIcon}
                                                src={plusIcon}
                                                alt=""
                                                initial="error"
                                                animate={
                                                    isFilled
                                                        ? isError
                                                            ? "error"
                                                            : "success"
                                                        : "error"
                                                }
                                                variants={iconVariants}
                                            />
                                        )}
                                    </motion.div>
                                </AnimatePresence>
                            </motion.button>
                        </div>
                    ) : (
                        <button
                            className={styles.openButton}
                            type="button"
                            disabled={mutation.isLoading}
                            onClick={handleOpen}
                        >
                            {title}
                        </button>
                    )}
                </motion.div>
            </AnimatePresence>
        </motion.div>
    );
};
