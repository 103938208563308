export const getParsedFormData = (data: {
    [name: string]: any;
}): { [name: string]: string } => {
    const object: { [name: string]: string } = {};

    Object.entries(data).forEach(([key, value]: [string, any]) => {
        if (key !== "city") {
            if (typeof value === "boolean") {
                object[key] = value.toString();
            } else {
                object[key] = value;
            }
        } else {
            Object.entries(value as { [name: string]: string }).forEach(
                ([key, value]) => {
                    if (key === "id") {
                        object["cityId"] = value;
                    }

                    if (key === "name") {
                        object["cityName"] = value;
                    }
                }
            );
        }
    });

    return object;
};
