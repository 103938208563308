import { useState, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link, useNavigation } from "react-router-dom";
import clsx from "clsx";

import { useStorageFlag } from "../../hooks";

import { IconButton } from "../../../../components/buttons";
import { Collapse } from "../Collapse";
import { DefaultModal } from "../../../../components/modals";
import { ButtonLoader } from "../../../../components/utils";

import { ChildrenItemProps } from "./ChildrenItem.typings";

import styles from "./ChildrenItem.module.css";


export const ChildrenItem = ({
    name,
    email,
    schoolClass,
    elements,
    mutation,
    isInvited,
    isUnconfirmed,
}: ChildrenItemProps) => {
    const { state } = useNavigation();
    const isLoadingState = state === "loading";

    const isMutated = useRef(mutation.isError ?? false);
    const [isError, setIsError] = useState(false);

    const [collapseKey, setCollapseKey] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    useStorageFlag(["popOpen", "modal"], isModalOpen);

    const [isClicked, setIsClicked] = useState<string | undefined>(undefined);

    const handleAcceptMutation = () => {
        isMutated.current = true;

        mutation.mutate({
            action: "accept",
            email,
        });
    };

    const handleRemoveMutation = () => {
        isMutated.current = true;

        if (isUnconfirmed) {
            mutation.mutate({
                action: "decline",
                email,
            });
        } else {
            mutation.mutate({
                action: "remove",
                email,
            });
        }

        setIsModalOpen(false);
    };

    useEffect(() => {
        if (mutation.isError && isMutated.current) {
            isMutated.current = false;

            setIsError(true);
        }
    }, [mutation]);

    useEffect(() => {
        if (isError) {
            setTimeout(() => {
                setIsError(false);
            }, 1500);
        }
    }, [isError]);

    return (
        <>
            <div className={styles.container}>
                <div className={styles.heading}>
                    <div
                        className={clsx(
                            styles.content,
                            isUnconfirmed && styles.unconfirmed
                        )}
                    >
                        <p className={styles.name}>{name}</p>
                        {!schoolClass ? (
                            <p className={styles.class}>(niepotwierdzony)</p>
                        ) : (
                            <p className={styles.class}>
                                {email}
                                <br />
                                (KLASA {schoolClass}){" "}
                                {isUnconfirmed && "- niepotwierdzony"}
                            </p>
                        )}
                    </div>
                    <div className={styles.buttonGroup}>
                        {isUnconfirmed && isInvited && (
                            <div className={styles.button}>
                                <IconButton
                                    type="confirm"
                                    isDisabled={isError || mutation.isLoading}
                                    onClick={handleAcceptMutation}
                                />
                            </div>
                        )}
                        <div className={styles.button}>
                            <IconButton
                                type="delete"
                                isDisabled={isError || mutation.isLoading}
                                onClick={() => setIsModalOpen(true)}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.accordion}>
                    {elements.map((element) =>
                        element.children ? (
                            <Collapse
                                key={element.key}
                                title={element.title}
                                icon={element.icon}
                                isActive={element.key === collapseKey}
                                isDisabled={isUnconfirmed}
                                onClick={() =>
                                    setCollapseKey(
                                        collapseKey &&
                                            collapseKey === element.key
                                            ? ""
                                            : element.key
                                    )
                                }
                            >
                                {element.children}
                            </Collapse>
                        ) : element.location ? (
                            <div key={element.key} className={styles.wrapper}>
                                <Link
                                    className={clsx(
                                        styles.link,
                                        (isUnconfirmed || element.isDisabled) &&
                                            styles.disabled
                                    )}
                                    to={element.location.to}
                                    state={element.location.state}
                                    onClick={() => setIsClicked(element.title)}
                                >
                                    <span className={styles.linkIcon}>
                                        <img src={element.icon} alt="" />
                                    </span>
                                    <span className={styles.linkTitle}>
                                        <ButtonLoader
                                            condition={
                                                isLoadingState &&
                                                isClicked === element.title
                                            }
                                            color="white"
                                        >
                                            {element.title}
                                        </ButtonLoader>
                                    </span>
                                </Link>
                            </div>
                        ) : null
                    )}
                </div>
                <AnimatePresence mode="wait">
                    {isError && (
                        <motion.div
                            className={styles.error}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{
                                type: "tween",
                                duration: 0.2,
                            }}
                        >
                            <p>Wystąpił nieoczekiwany błąd.</p>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            <DefaultModal
                title="USUŃ"
                actions={[
                    {
                        name: "TAK, USUŃ",
                        isImportant: true,
                        onClick: handleRemoveMutation,
                    },
                    {
                        name: "NIE, WRÓĆ",
                        onClick: () => setIsModalOpen(false),
                    },
                ]}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            >
                <p>
                    Czy na pewno chcesz przestać śledzić postępy nauki
                    użytkownika:
                </p>
                <p>
                    <strong>
                        {name} (uczeń)
                        <br />
                        {email}
                    </strong>
                </p>
            </DefaultModal>
        </>
    );
};
