import { UserSubscriptionsResponse } from "../../hooks/queries/useUserSubscriptions";
import { SubscriptionKeys } from "../../typings";

export const getActiveSubscription = (
    subscriptions: UserSubscriptionsResponse["subscriptions"],
    key: SubscriptionKeys
) => {
    let isActive = false;

    subscriptions.forEach((subscription) => {
        if (subscription.subscription === key) {
            isActive = subscription.active_to !== "nieaktywny";
        }
    });

    return isActive;
};
