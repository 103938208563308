import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { useAuthContext } from "../contexts/AuthContext";

import {
    getTokenCookie,
    removeAccountCookie,
    removeTokenCookie,
    setAccountCookie,
    setSessionExpired,
} from "../services";

import { mainApi } from "../api";

type Response = {
    status: number;
    subscription_paid: boolean;
};

export const useValidateAuthToken = () => {
    const queryClient = useQueryClient();

    const { setIsUserLogged } = useAuthContext();

    return useQuery<AxiosResponse<Response, any>, AxiosError<any, any>>(
        ["authTokenValidate"],
        () => mainApi.post("validateAuthToken"),
        {
            onSuccess: (data) => {
                if (data.data.status !== 401) {
                    setAccountCookie({
                        isSubscriptionPaid:
                            data.data.subscription_paid === true
                                ? "true"
                                : "false",
                    });
                    setIsUserLogged(true);
                } else if (getTokenCookie()) {
                    removeTokenCookie();
                }
            },
            onError: (error) => {
                if (
                    getTokenCookie() &&
                    error.response?.statusText === "Forbidden"
                ) {
                    removeAccountCookie();
                    removeTokenCookie();

                    queryClient.removeQueries();

                    setSessionExpired(true);
                }

                setIsUserLogged(false);
            },
        }
    );
};
