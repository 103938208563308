import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect } from "react";
import { Portal } from "react-portal";

import { DisableBack } from "../../utils";

import { dialogAnimations, modalAnimations } from "./StoreModal.animations";
import { StoreModalProps } from "./StoreModal.typings";

import styles from "./StoreModal.module.css";

export const StoreModal = ({
    children,
    isCloseHidden,
    isOpen,
    setIsOpen,
}: StoreModalProps) => {
    const handleEsc = useCallback(
        (event: KeyboardEvent) => {
            if (isOpen && event.key === "Escape" && !isCloseHidden) {
                setIsOpen(false);
            }
        },
        [isOpen, setIsOpen, isCloseHidden]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleEsc);

        return () => {
            document.removeEventListener("keydown", handleEsc);
        };
    }, [handleEsc]);

    return (
        <Portal node={document && document.getElementById("modal-root")}>
            <AnimatePresence mode="wait">
                {isOpen && (
                    <motion.div className={styles.modal} {...modalAnimations}>
                        <div className={styles.wrapper}>
                            <motion.div
                                className={styles.dialog}
                                {...dialogAnimations}
                            >
                                <div className={styles.body}>
                                    <AnimatePresence
                                        mode="wait"
                                        initial={false}
                                    >
                                        {!isCloseHidden && (
                                            <motion.button
                                                id="modalCloseButton"
                                                className={styles.close}
                                                type="button"
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                                whileHover={{ opacity: 0.7 }}
                                                transition={{
                                                    type: "tween",
                                                    duration: 0.2,
                                                }}
                                                onClick={() => setIsOpen(false)}
                                            ></motion.button>
                                        )}
                                    </AnimatePresence>
                                    {isCloseHidden && <DisableBack />}
                                    <div className={styles.content}>
                                        {children}
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </Portal>
    );
};
