import { CardFormData } from "../../../typings";

const getCardValidation = (data: CardFormData) => {
    let isValid = true;

    Object.entries(data).every(([, value]) => {
        if (value.response === "success" || value.response === "saved") {
            return true;
        } else {
            isValid = false;

            return false;
        }
    });

    return isValid;
};

const getCardNumberShortcode = (value: string) => {
    const clearValue = value.replaceAll(" ", "");

    return clearValue.substring(clearValue.length - 4, clearValue.length);
};

export { getCardValidation, getCardNumberShortcode };
