import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

import {
    dropdownVariants,
    iconVariants,
    inputVariants,
    labelCenterVariants,
    labelVariants,
    menuVariants,
    selectVariants,
} from "./SelectInput.animations";

import styles from "./SelectInput.module.css";

const transition = {
    type: "tween",
    duration: 0.3,
};

type Props<T extends string> = {
    className?: string;
    value?: T;
    selectValues: T[] | undefined;
    label?: string;
    isLabelCenter?: boolean;
    isError?: boolean;
    isBlankError?: boolean;
    isDisabled?: boolean;
    isLoading?: boolean;
    onChange: (value: T) => void;
};

export const SelectInput = <T extends string>({
    className,
    value,
    selectValues,
    label,
    isLabelCenter,
    isError,
    isBlankError,
    isDisabled,
    isLoading,
    onChange,
}: Props<T>) => {
    const [isOpen, setIsOpen] = useState(false);
    const isFilled = value && value.length > 0;

    const buttonElement = useRef<HTMLButtonElement>(null);
    const dropdownElement = useRef<HTMLDivElement>(null);

    const handleMenuClick = (value: T) => {
        setIsOpen(false);

        onChange(value);
    };

    const handleSelectClose = (event: MouseEvent) => {
        const target = event.target as Node;

        if (
            buttonElement.current &&
            !buttonElement.current.contains(target) &&
            dropdownElement.current &&
            !dropdownElement.current.contains(target)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleSelectClose);

        return () => {
            document.removeEventListener("mousedown", handleSelectClose);
        };
    }, []);

    return (
        <div className={clsx(styles.field, className)}>
            {isLabelCenter ? (
                !value && (
                    <motion.div
                        className={clsx(styles.label, styles.center)}
                        variants={labelCenterVariants}
                        initial={
                            isOpen || isFilled
                                ? "default"
                                : isError || isBlankError
                                ? "error"
                                : "default"
                        }
                        animate={
                            isOpen || isFilled
                                ? "default"
                                : isError || isBlankError
                                ? "error"
                                : "default"
                        }
                        transition={transition}
                    >
                        {label ?? "Zaznacz opcję"}
                    </motion.div>
                )
            ) : (
                <motion.div
                    className={styles.label}
                    initial={
                        isFilled
                            ? isError
                                ? "error"
                                : "success"
                            : isOpen
                            ? "open"
                            : "closed"
                    }
                    animate={
                        isFilled
                            ? isError
                                ? "error"
                                : "success"
                            : isOpen
                            ? isBlankError
                                ? "error"
                                : "open"
                            : isError || isBlankError
                            ? "error_closed"
                            : "closed"
                    }
                    variants={labelVariants}
                    transition={transition}
                >
                    {label ?? "Zaznacz opcję"}
                </motion.div>
            )}
            <motion.button
                ref={buttonElement}
                className={clsx(styles.input, isLabelCenter && styles.center)}
                type="button"
                variants={inputVariants}
                initial={
                    selectValues && !isDisabled
                        ? isFilled
                            ? "success"
                            : "closed"
                        : "disabled"
                }
                animate={
                    selectValues && !isDisabled
                        ? isOpen
                            ? "open"
                            : isFilled
                            ? isError
                                ? "error"
                                : "success"
                            : isError
                            ? "error"
                            : isBlankError
                            ? "error"
                            : "closed"
                        : isError || isBlankError
                        ? "error_disabled"
                        : "disabled"
                }
                transition={transition}
                disabled={!selectValues || isDisabled || isLoading}
                onClick={() => setIsOpen((open) => !open)}
            >
                {value}
                <motion.span
                    className={styles.icon}
                    variants={iconVariants}
                    transition={transition}
                >
                    <SelectIcon />
                </motion.span>
            </motion.button>
            {selectValues && (
                <AnimatePresence mode="wait">
                    {isOpen && !isDisabled && (
                        <motion.div
                            ref={dropdownElement}
                            className={styles.dropdown}
                            variants={dropdownVariants}
                            initial="closed"
                            animate="open"
                            exit="closed"
                            transition={transition}
                        >
                            <motion.ul
                                className={styles.menu}
                                variants={menuVariants}
                            >
                                {selectValues.map((item) => {
                                    return (
                                        <motion.li
                                            key={item}
                                            variants={selectVariants}
                                        >
                                            <button
                                                className={clsx(
                                                    styles.menuButton,
                                                    value === item &&
                                                        styles.active
                                                )}
                                                type="button"
                                                disabled={value === item}
                                                onClick={() =>
                                                    handleMenuClick(item)
                                                }
                                            >
                                                {item}
                                            </button>
                                        </motion.li>
                                    );
                                })}
                            </motion.ul>
                        </motion.div>
                    )}
                </AnimatePresence>
            )}
        </div>
    );
};

const SelectIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8.121"
            height="5.121"
            viewBox="0 0 8.121 5.121"
        >
            <g
                id="Group_326"
                data-name="Group 326"
                transform="translate(3526.021 6771.914)"
            >
                <line
                    id="Line_12"
                    data-name="Line 12"
                    x2="4.243"
                    transform="translate(-3518.96 -6770.854) rotate(135)"
                    fill="none"
                    stroke="#879195"
                    strokeLinecap="round"
                    strokeWidth="1.5"
                />
                <line
                    id="Line_13"
                    data-name="Line 13"
                    x2="4.243"
                    transform="translate(-3521.96 -6767.854) rotate(-135)"
                    fill="none"
                    stroke="#879195"
                    strokeLinecap="round"
                    strokeWidth="1.5"
                />
            </g>
        </svg>
    );
};
