import { QueryClient } from "@tanstack/react-query";

import { getAccountCookie } from "../../../../services";

import { parentChildrensQuery } from "../../utils";

export const parentChildrensLoader = (queryClient: QueryClient) => async () => {
    const { accountType } = getAccountCookie();

    if (accountType !== "parent") {
        return undefined;
    }

    const query = parentChildrensQuery();

    return (
        queryClient.getQueryData(query.queryKey) ??
        queryClient.fetchQuery(query)
    );
};
