import { useState, useEffect } from "react";
import clsx from "clsx";

import { useNotify } from "../../../../hooks";

import { StudentSearch } from "../StudentSearch";
import { StudentList } from "../StudentList";

import { getFilteredStudents } from "../../utils";

import { StudentsProps } from "./Students.typings";

import styles from "./Students.module.css";

export const Students = ({
    className,
    data,
    emptyText,
    isPreview,
    mutation,
    isDashboard,
    isLargerOnDesktop,
}: StudentsProps) => {
    const notify = useNotify({ type: "error" });

    const dataList = data.response ? data.response.data[data.key] : [];

    const [value, setValue] = useState("");

    useEffect(() => {
        if (
            mutation.isError &&
            mutation.error &&
            mutation.error.response.data
        ) {
            const messages = mutation.error.response.data.messages;
            const messageKey = Object.keys(messages)[0];

            notify(messages[messageKey]);

            mutation.reset();
        }
    }, [mutation, notify]);

    return (
        <div
            className={clsx(
                styles.container,
                className,
                isPreview && styles.preview
            )}
        >
            {!isPreview && <StudentSearch value={value} setValue={setValue} />}
            <StudentList
                data={
                    isPreview ? dataList : getFilteredStudents(dataList, value)
                }
                mutation={mutation}
                emptyText={emptyText}
                isEmpty={dataList.length === 0}
                isPreview={isPreview}
                isDashboard={isDashboard}
                isLargerOnDesktop={isLargerOnDesktop}
            />
        </div>
    );
};
