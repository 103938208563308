import { useEffect } from "react";

import { useRedirectContext } from "../contexts/RedirectContext";

const getIsExcludedPage = (pages: boolean[]) => {
    let isExcluded = false;

    pages.forEach((page) => {
        if (page) {
            isExcluded = true;
        }
    });

    return isExcluded;
};

export const useResetRedirect = (excludedPages: boolean[]) => {
    const { redirect, setRedirect } = useRedirectContext();

    const isExcluded = getIsExcludedPage(excludedPages);

    useEffect(() => {
        if (!isExcluded && redirect) {
            setRedirect(undefined);
        }
    }, [isExcluded, redirect, setRedirect]);
};
