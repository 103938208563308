import { getColor } from "../utils";
import { IconProps } from "./Icons.typings";

export const CoinIcon = ({ isActive, isImportant }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
    >
        <path
            id="Icon_awesome-coins"
            data-name="Icon awesome-coins"
            d="M0,23.748v2.5C0,28.318,5.039,30,11.25,30S22.5,28.318,22.5,26.25v-2.5c-2.42,1.705-6.844,2.5-11.25,2.5S2.42,25.453,0,23.748ZM18.75,7.5C24.961,7.5,30,5.818,30,3.75S24.961,0,18.75,0,7.5,1.682,7.5,3.75,12.539,7.5,18.75,7.5ZM0,17.6v3.023c0,2.068,5.039,3.75,11.25,3.75s11.25-1.682,11.25-3.75V17.6c-2.42,1.992-6.85,3.023-11.25,3.023S2.42,19.594,0,17.6Zm24.375.645C27.732,17.6,30,16.389,30,15V12.5a14.389,14.389,0,0,1-5.625,2.021ZM11.25,9.375C5.039,9.375,0,11.473,0,14.063S5.039,18.75,11.25,18.75s11.25-2.1,11.25-4.687S17.461,9.375,11.25,9.375Zm12.85,3.3c3.516-.633,5.9-1.875,5.9-3.3v-2.5c-2.08,1.471-5.654,2.262-9.416,2.449A6.562,6.562,0,0,1,24.1,12.674Z"
            fill={getColor(
                isActive ? "active" : isImportant ? "important" : "default"
            )}
            opacity="1"
        />
    </svg>
);
