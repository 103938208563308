import React from "react";
import ReactDOM from "react-dom/client";

import { MainWrapper } from "./components/wrappers";
import { App } from "./App";

import "./assets/css/normalize.css";
import "./assets/css/index.css";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <MainWrapper>
            <App />
        </MainWrapper>
    </React.StrictMode>
);
