import { AnimatePresence, motion } from "framer-motion";

import styles from "./NotificationsBackdrop.module.css";

type Props = {
    isOpen: boolean;
};

export const NotificationsBackdrop = ({ isOpen }: Props) => {
    return (
        <AnimatePresence mode="wait" initial={false}>
            {isOpen && (
                <motion.div
                    className={styles.container}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                        type: "tween",
                        duration: 0.5,
                    }}
                ></motion.div>
            )}
        </AnimatePresence>
    );
};
