export const concatenateCreditCardData = (
    cardNumber: string,
    validThru: string,
    cvc: string
) => {
    return (
        cardNumber.replaceAll(" ", "") +
        "|" +
        validThru +
        "|" +
        cvc +
        "|" +
        document.location.origin
    );
};
