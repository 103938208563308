import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { userSubscriptionsQuery } from "../../utils";

import { SubscriptionType } from "../../typings";

export type UserSubscriptionsResponse = {
    user_id: string;
    first_name: string;
    last_name: string;
    class: string;
    subscriptions: SubscriptionType[];
};

export const useUserSubscriptions = (isEnabled?: boolean) => {
    return useQuery<AxiosResponse<UserSubscriptionsResponse, any>>(
        userSubscriptionsQuery(isEnabled)
    );
};
