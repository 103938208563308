export const PlusIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
    >
        <g
            id="Icon_ionic-ios-add-circle-outline"
            data-name="Icon ionic-ios-add-circle-outline"
            transform="translate(-12.375 -12.375)"
        >
            <path
                id="Path_980"
                data-name="Path 980"
                d="M24.385,16.885H19.138V11.638a1.127,1.127,0,0,0-2.254,0v5.246H11.638a1.079,1.079,0,0,0-1.127,1.127,1.091,1.091,0,0,0,1.127,1.127h5.246v5.246a1.091,1.091,0,0,0,1.127,1.127,1.121,1.121,0,0,0,1.127-1.127V19.138h5.246a1.127,1.127,0,1,0,0-2.254Z"
                transform="translate(1.863 1.863)"
                fill="#fff"
            />
        </g>
    </svg>
);
