import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

import { setTaskParams } from "../../services";

import { getPreviousPath } from "../../../../utils";
import { taskSlug } from "../../utils";

type Response = {
    next_exercise_id: string | false;
    exercises: { id: string }[];
    id_category: string;
    id_subcategory: string;
};

type Mutation = {
    taskId: string;
    userClass: string;
    categoryId: string;
    subcategoryId: string;
    isFiltered: boolean;
};

export const useGetNextTask = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return useMutation<AxiosResponse<Response, any>, any, Mutation, unknown>(
        (data) =>
            mainApi.post("get_next_exercise_id", {
                current_exercise_id: data.taskId,
                exam_filter: data.isFiltered,
                class: data.userClass,
                category_id: data.categoryId,
                subcategory_id: data.subcategoryId,
            }),

        {
            onSuccess: (data) => {
                const taskId = data.data.next_exercise_id;
                const categoryId = data.data.id_category;
                const subcategoryId = data.data.id_subcategory;

                if (taskId) {
                    setTaskParams({ type: taskSlug.default, taskId });

                    navigate(`${getPreviousPath(pathname)}/${taskId}`);
                } else {
                    navigate(
                        `${getPreviousPath(
                            pathname,
                            3
                        )}/tasks/${categoryId}/${subcategoryId}`
                    );
                }
            },
        }
    );
};
