import { Form, Formik } from "formik";
import { useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useLoadingContext } from "../../../../../contexts/LoadingContext";

import { useSubmitForm } from "../../../../../hooks";
import {
    useNavigationAccount,
    useUpdateUserData,
    useUserData,
} from "../../../hooks";

import { DefaultButton } from "../../../../../components/buttons";
import {
    AccountContainer,
    ColumnContainer,
} from "../../../../../components/containers";
import { DashboardHeading } from "../../../../../components/headings";
import {
    DateInput,
    DefaultCheckbox,
    DefaultInput,
    SearchInput,
    SelectInput,
} from "../../../../../components/inputs";
import { NavigationAccount } from "../../../components";

import { getAccountCookie } from "../../../../../services";

import { getClassList, getPreviousPath } from "../../../../../utils";
import {
    editOthersAccountSchema,
    editStudentAccountSchema,
} from "../../../utils";

import styles from "./AccountEdit.module.css";

type AccountEditProps = {
    mutation: ReturnType<typeof useUpdateUserData>;
};

export const AccountEdit = ({ mutation }: AccountEditProps) => {
    const { accountType } = getAccountCookie();
    const isStudent = accountType === "student" ? true : false;

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { data: userData } = useUserData();

    const navigation = useNavigationAccount("Edycja danych");

    const submitForm = useSubmitForm();
    const { setLoadingActive } = useLoadingContext();

    const isFormSuccess = useRef(false);

    useEffect(() => {
        if (mutation.isSuccess && !isFormSuccess.current) {
            isFormSuccess.current = true;

            setLoadingActive(false, () => {
                navigate(getPreviousPath(pathname));

                mutation.reset();
            });
        }
    }, [mutation, setLoadingActive, navigate, pathname]);

    return (
        <>
            <DashboardHeading title="MOJE KONTO" subtitle="edycja danych" />
            <AccountContainer
                sidebarElement={<NavigationAccount elements={navigation} />}
            >
                <div className={styles.container}>
                    <Formik
                        initialValues={{
                            email: userData?.data.data.email,
                            ...(isStudent && {
                                birthday:
                                    userData?.data.data.birthday.replaceAll(
                                        "-",
                                        "."
                                    ),
                                school: userData?.data.data.school,
                                class: userData?.data.data.class,
                            }),
                            city: {
                                id: userData?.data.data.city.id,
                                name: `${userData?.data.data.city.NAZWA} (${userData?.data.data.city.GMI})`,
                            },
                            first_name: userData?.data.data.first_name,
                            last_name: userData?.data.data.last_name,
                            agreement: true,
                        }}
                        validationSchema={
                            isStudent
                                ? editStudentAccountSchema
                                : editOthersAccountSchema
                        }
                        validateOnMount={true}
                        onSubmit={(data, actions) => {
                            setLoadingActive(true, () => {
                                submitForm(data, {
                                    is_children: isStudent ? "true" : "false",
                                });

                                actions.setSubmitting(false);
                            });
                        }}
                    >
                        {(formik) => (
                            <Form className={styles.form}>
                                <DefaultInput
                                    label="E-mail"
                                    name="email"
                                    type="email"
                                    isDisabled={true}
                                />
                                {isStudent && (
                                    <>
                                        <DateInput
                                            name="birthday"
                                            isDisabled={true}
                                        />
                                        <ColumnContainer
                                            className={styles.inputGroup}
                                        >
                                            <SelectInput
                                                value={formik.values.school}
                                                selectValues={[
                                                    "Podstawowa",
                                                    "Liceum",
                                                    "Technikum",
                                                    "Branżowa",
                                                ]}
                                                label="Szkoła"
                                                onChange={(value) =>
                                                    formik.setValues(
                                                        {
                                                            ...formik.values,
                                                            class: "",
                                                            school: value,
                                                        },
                                                        true
                                                    )
                                                }
                                            />
                                            <SelectInput
                                                value={formik.values.class}
                                                selectValues={getClassList(
                                                    formik.values.school!
                                                )}
                                                label="Klasa"
                                                isError={!formik.values.class}
                                                isDisabled={
                                                    !formik.values.school
                                                }
                                                onChange={(value) => {
                                                    formik.setFieldValue(
                                                        "class",
                                                        value,
                                                        true
                                                    );
                                                }}
                                            />
                                        </ColumnContainer>
                                    </>
                                )}
                                <SearchInput
                                    label="Miejscowość"
                                    name="city"
                                    query={{
                                        key: ["city_search"],
                                        url: "/getSelectCities",
                                        name: "NAZWA",
                                        nameFn: (value) =>
                                            value.NAZWA +
                                            " (" +
                                            value.GMI +
                                            ")",
                                    }}
                                    onChange={(value) =>
                                        formik.setFieldValue(
                                            "city",
                                            value,
                                            true
                                        )
                                    }
                                />
                                <DefaultInput
                                    label="Imię"
                                    name="first_name"
                                    type="text"
                                />
                                <DefaultInput
                                    label="Nazwisko"
                                    name="last_name"
                                    type="text"
                                />
                                <DefaultCheckbox
                                    label={
                                        <>
                                            Akceptuję{" "}
                                            <Link to="terms-of-use">
                                                regulamin
                                            </Link>{" "}
                                            oraz{" "}
                                            <Link to="privacy-policy">
                                                politykę prywatności
                                            </Link>
                                        </>
                                    }
                                    name="agreement"
                                    type="checkbox"
                                />
                                <DefaultButton
                                    wrapper={styles.formButton}
                                    initial={{
                                        backgroundColor:
                                            !formik.isValid || !formik.dirty
                                                ? DefaultButton.color.disabled
                                                : DefaultButton.color.enabled,
                                    }}
                                    animate={{
                                        backgroundColor:
                                            !formik.isValid ||
                                            !formik.dirty ||
                                            formik.isSubmitting
                                                ? DefaultButton.color.disabled
                                                : DefaultButton.color.enabled,
                                    }}
                                    whileHover={{
                                        opacity: formik.isSubmitting ? 1 : 0.7,
                                    }}
                                    transition={{
                                        type: "tween",
                                        duration: 0.3,
                                    }}
                                    isSubmit={true}
                                    isDisabled={
                                        !formik.isValid ||
                                        !formik.dirty ||
                                        formik.isSubmitting
                                    }
                                >
                                    ZAPISZ
                                </DefaultButton>
                                <DefaultButton
                                    wrapper={styles.cancelButton}
                                    whileHover={{
                                        opacity: formik.isSubmitting ? 1 : 0.7,
                                    }}
                                    transition={{
                                        type: "tween",
                                        duration: 0.3,
                                    }}
                                    to={getPreviousPath(pathname)}
                                >
                                    ANULUJ ZMIANY
                                </DefaultButton>
                            </Form>
                        )}
                    </Formik>
                </div>
            </AccountContainer>
        </>
    );
};
