import { TaskFilterExamType } from "../../typings";

export const getTaskFilterExamName = (filter: TaskFilterExamType) => {
    switch (filter) {
        case "8_kl":
            return "Egzamin 8-klasisty";
        case "matura_podstawowa":
            return "Matura podstawowa";
        case "matura_rozszerzona":
            return "Matura rozszerzona";
    }
};
