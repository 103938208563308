import { useState, useRef, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import parse from "html-react-parser";
import clsx from "clsx";

import { useMedia } from "../../../../../hooks";
import { useAnswersContext } from "../../../contexts/AnswersContext";
import { useResultContext } from "../../../contexts/ResultContext";

import { CheckmarkIcon, XMarkIcon } from "../../icon-components";

import { getIsOptionSelected, getListLetter } from "./utils";
import { replaceOptions } from "../../../utils";

import {
    checkboxVariants,
    optionVariants,
    transition,
} from "./AnswerChoice.animations";

import { ChoiceProps } from "./AnswerChoice.typings";

import styles from "./AnswerChoice.module.css";

export const AnswerChoice = ({
    id,
    options,
    isMultiple,
    isLoading,
}: ChoiceProps) => {
    const { isDesktop } = useMedia();

    const { getProvidedAnswer, provideAnswer, removeProvidedAnswer } = useAnswersContext();
    const answer = getProvidedAnswer(id);
    const isAnswerProvided = useRef(false);

    const { getResult, isResultsLoading } = useResultContext();
    const result = getResult(id, "radiobutton");
    const answersApproved = result ? result.answers : undefined;

    const [selectedOptions, setSelectedOptions] = useState<
        string[] | undefined
    >(answer);

    const handleSelect = (id: string) => {
        if (isMultiple) {
            setSelectedOptions((options) =>
                options
                    ? getIsOptionSelected(id, selectedOptions)
                        ? options.length > 1
                            ? options!.filter((option) => option !== id)
                            : undefined
                        : [...options, id]
                    : [id]
            );
        } else {
            if (getIsOptionSelected(id, selectedOptions)) {
                setSelectedOptions(undefined);
            } else {
                setSelectedOptions([id]);
            }
        }
    };

    useEffect(() => {
        if (selectedOptions) {
            isAnswerProvided.current = true;

            provideAnswer({
                id,
                type: "radiobutton",
                value: selectedOptions,
            });
        } else if (isAnswerProvided.current) {
            isAnswerProvided.current = false;

            removeProvidedAnswer(id);
        }
    }, [selectedOptions, provideAnswer, removeProvidedAnswer, id]);

    return (
        <div className={styles.container}>
            {options.map((option, index) => {
                const content = parse(
                    `<span className=${styles.index}>${getListLetter(
                        index
                    )}.</span><span className=${styles.text}>${
                        option.content
                    }</span>`,
                    replaceOptions()
                );

                const isAnswered = answersApproved ? true : false;
                const isSelected = answersApproved
                    ? answersApproved.some(
                          (answer) =>
                              answer.id === option.id && answer.checked_by_user
                      )
                    : getIsOptionSelected(option.id, selectedOptions);
                const isCorrect = answersApproved
                    ? answersApproved.some(
                          (answer) => answer.id === option.id && answer.correct
                      )
                    : undefined;
                const isSuccess = answersApproved
                    ? answersApproved.some(
                          (answer) =>
                              answer.id === option.id &&
                              answer.correct &&
                              answer.checked_by_user
                      )
                    : undefined;
                const isError = answersApproved
                    ? answersApproved.some(
                          (answer) =>
                              answer.id === option.id &&
                              !answer.correct &&
                              answer.checked_by_user
                      )
                    : undefined;

                return (
                    <motion.button
                        key={option.id}
                        className={clsx(
                            styles.option,
                            isMultiple && styles.multiple
                        )}
                        type="button"
                        variants={optionVariants}
                        initial={isDesktop ? "default_desktop" : "default"}
                        animate={
                            isAnswered
                                ? isSuccess
                                    ? "success_answered"
                                    : isError
                                    ? "error_answered"
                                    : !isSelected && isCorrect
                                    ? "error"
                                    : "default"
                                : isSelected
                                ? isDesktop
                                    ? "selected_desktop"
                                    : "selected"
                                : isDesktop
                                ? "default_desktop"
                                : "default"
                        }
                        disabled={isLoading || !!result || isResultsLoading}
                        transition={transition}
                        onClick={() => handleSelect(option.id)}
                    >
                        {isMultiple && (
                            <motion.span
                                className={styles.checkbox}
                                variants={checkboxVariants}
                                transition={transition}
                            >
                                <AnimatePresence initial={false}>
                                    {isSelected && (
                                        <motion.span
                                            key={
                                                isSuccess
                                                    ? "is-success"
                                                    : isError
                                                    ? "is-error"
                                                    : isSelected
                                                    ? "is-selected"
                                                    : "no-state"
                                            }
                                            className={styles.checkboxIcon}
                                            initial={{
                                                y: -20,
                                                scale: 0.4,
                                            }}
                                            animate={{ y: 0, scale: 1 }}
                                            exit={{ y: 20, scale: 0.4 }}
                                            transition={transition}
                                        >
                                            {isSelected && (
                                                <CheckmarkIcon isSmall={true} />
                                            )}
                                        </motion.span>
                                    )}
                                </AnimatePresence>
                            </motion.span>
                        )}
                        <span className={styles.content}>{content}</span>
                        <AnimatePresence initial={false}>
                            {(isCorrect === true || isCorrect === false) && (
                                <motion.span
                                    key={
                                        isCorrect === true
                                            ? "is-correct"
                                            : isCorrect === false
                                            ? "is-incorrect"
                                            : "no-state"
                                    }
                                    className={styles.icon}
                                    initial={{
                                        opacity: 0,
                                        scale: 0.5,
                                    }}
                                    animate={{
                                        opacity: 1,
                                        scale: 1,
                                    }}
                                    exit={{
                                        opacity: 0,
                                        scale: 0.5,
                                    }}
                                    transition={transition}
                                >
                                    {isCorrect === true ? (
                                        <CheckmarkIcon isSuccess={true} />
                                    ) : (
                                        isCorrect === false && <XMarkIcon />
                                    )}
                                </motion.span>
                            )}
                        </AnimatePresence>
                    </motion.button>
                );
            })}
        </div>
    );
};
