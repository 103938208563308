import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect } from "react";
import { Portal } from "react-portal";

import { TaskNoteModalProps } from "./TaskNoteModal.typings";

import styles from "./TaskNoteModal.module.css";

export const TaskNoteModal = ({
    children,
    isOpen,
    setIsOpen,
}: TaskNoteModalProps) => {
    const handleEsc = useCallback(
        (event: KeyboardEvent) => {
            if (isOpen && event.key === "Escape") {
                setIsOpen(false);
            }
        },
        [isOpen, setIsOpen]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleEsc);

        return () => {
            document.removeEventListener("keydown", handleEsc);
        };
    }, [handleEsc]);

    return (
        <Portal node={document && document.getElementById("modal-root")}>
            <AnimatePresence mode="wait">
                {isOpen && (
                    <motion.div
                        className={styles.modal}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ type: "tween", duration: 0.2 }}
                    >
                        <div className={styles.dialog}>
                            <motion.button
                                id="modalCloseButton"
                                className={styles.close}
                                type="button"
                                whileHover={{ opacity: 0.7 }}
                                onClick={() => setIsOpen(false)}
                            ></motion.button>
                            <h3 className={styles.title}>NOTES</h3>
                            <div className={styles.body}>
                                <div className={styles.whiteboardBg} />
                                {children}
                            </div>
                            <div className={styles.footer}></div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </Portal>
    );
};
