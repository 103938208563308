import robot from "../../../assets/images/logos/loading-robot_logo.svg";
import circle from "../../../assets/images/logos/loading-circle_logo.svg";

import styles from "./LoadingLogo.module.css";

export const LoadingLogo = () => {
    return (
        <div className={styles.logo}>
            <img className={styles.robot} src={robot} alt="" />
            <img className={styles.circle} src={circle} alt="" />
        </div>
    );
};
