const iconVariants = {
    active: {
        rotate: 180,
    },
    inactive: {
        rotate: 0,
    },
};

const transition = { type: "tween", duration: 0.3 };

const getIconAnimations = (isActive: boolean) => ({
    variants: iconVariants,
    initial: isActive ? "active" : "inactive",
    animate: isActive ? "active" : "inactive",
    transition,
});

const collapseAnimations = {
    initial: { height: 0 },
    animate: { height: "auto" },
    exit: { height: 0 },
    transition,
};

export { getIconAnimations, collapseAnimations };
