import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { teacherStudentsQuery } from "../../utils";

type Response = {
    students: {
        email: string;
        status: "Zaproszony" | "Zaakceptowany";
        user_id: string | null;
        first_name: string | null;
        last_name: string | null;
        class: string | null;
        invited_by: string;
    }[];
};

export const useTeacherStudents = (
    isEnabled?: boolean,
    isFasterRefetch?: boolean
) => {
    return useQuery<AxiosResponse<Response, any>>(
        teacherStudentsQuery(isEnabled, isFasterRefetch)
    );
};
