import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { useNotify } from "../../../../hooks";
import {
    useParentChildrens,
    useParentUnderageChildrenActions,
    useRelationByParent,
} from "../../hooks";

import { DefaultButton } from "../../../../components/buttons";
import { CircleLoader } from "../../../../components/loaders";
import { ButtonLoader } from "../../../../components/utils";
import { ChildrenItem } from "../ChildrenItem";
import { InviteInput } from "../InviteInput";
import { SubscriptionPackages } from "../SubscriptionPackages";
import { UserList } from "../UserList";

import { getPreviousPath } from "../../../../utils";
import { parentChildrensQuery } from "../../utils";

import styles from "./Childrens.module.css";

import coinIcon from "../../images/dashboard/parent/coin-icon.svg";
import graphIcon from "../../images/dashboard/parent/graph-icon.svg";
import personIcon from "../../images/dashboard/parent/person-icon.svg";

type Props = {
    className?: string;
    title?: string;
    isEnabled?: boolean;
    isInputDisabled?: boolean;
};

export const Childrens = ({
    className,
    title,
    isEnabled,
    isInputDisabled = false,
}: Props) => {
    const { pathname } = useLocation();
    const { state } = useNavigation();
    const navigate = useNavigate();

    const parentChildrensQueryKey = parentChildrensQuery().queryKey;
    const parentChildrens = useParentChildrens(isEnabled, isEnabled);
    const mutation = useRelationByParent(parentChildrensQueryKey);
    const mutationTeacher = useParentUnderageChildrenActions(
        parentChildrensQueryKey
    );

    const [isQueryLoaded, setIsQueryLoaded] = useState(
        parentChildrens.data ? true : false
    );

    const notify = useNotify({ type: "error" });
    const [inviteValue, setInviteValue] = useState("");
    const isValueLast = useRef(false);
    const valuesLengthLast = useRef(
        parentChildrens.data ? parentChildrens.data.data.children.length : 0
    );

    const [loadingRoute, setLoadingRoute] = useState("");

    useEffect(() => {
        if (parentChildrens.data && !isQueryLoaded) {
            setTimeout(() => {
                setIsQueryLoaded(true);
            }, 1000);
        }
    }, [parentChildrens.data, isQueryLoaded]);

    useEffect(() => {
        if (
            !parentChildrens.data ||
            parentChildrens.data.data.children.length <= 1 ||
            (parentChildrens.data.data.children.length <= 1 &&
                parentChildrens.data.data.children.length <
                valuesLengthLast.current)
        ) {
            isValueLast.current = true;
        } else {
            isValueLast.current = false;
        }

        valuesLengthLast.current = parentChildrens.data
            ? parentChildrens.data.data.children.length
            : 0;
    }, [parentChildrens.data]);

    useEffect(() => {
        if (
            !isInputDisabled &&
            mutation.isError &&
            mutation.error &&
            mutation.error.response.data
        ) {
            const messages = mutation.error.response.data.messages;
            const messageKey = Object.keys(messages)[0];

            notify(messages[messageKey]);

            mutation.reset();
        }
    }, [isInputDisabled, mutation, notify]);

    return (
        <AnimatePresence mode="wait" initial={false}>
            {isQueryLoaded ? (
                <motion.div
                    key="childrens"
                    className={clsx(styles.container, className)}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ type: "tween", duration: 0.3 }}
                >
                    {title && <h3 className={styles.title}>{title}</h3>}
                    <div className={styles.list}>
                        <AnimatePresence
                            mode={isValueLast.current ? "wait" : "sync"}
                            initial={false}
                        >
                            {parentChildrens.data &&
                                parentChildrens.data.data.children.length > 0 ? (
                                parentChildrens.data.data.children.map(
                                    (children) => (
                                        <motion.div
                                            key={
                                                children.user_id ??
                                                children.email
                                            }
                                            initial={{ opacity: 0, scale: 0.7 }}
                                            animate={{ opacity: 1, scale: 1 }}
                                            exit={{ opacity: 0, scale: 0.7 }}
                                            transition={{
                                                type: "tween",
                                                duration: 0.2,
                                            }}
                                        >
                                            <ChildrenItem
                                                name={
                                                    children.first_name &&
                                                        children.last_name
                                                        ? `${children.first_name} ${children.last_name}`
                                                        : children.email
                                                }
                                                schoolClass={children.class}
                                                email={children.email}
                                                mutation={mutation}
                                                elements={[
                                                    {
                                                        key: `subscription_${children.user_id}`,
                                                        title: "ABONAMENT",
                                                        icon: coinIcon,
                                                        children: (
                                                            <>
                                                                <SubscriptionPackages
                                                                    subscriptions={
                                                                        children.subscriptions
                                                                    }
                                                                />
                                                                <DefaultButton
                                                                    wrapper={
                                                                        styles.subscriptionButton
                                                                    }
                                                                    state={
                                                                        children.user_id
                                                                            ? {
                                                                                childrenId:
                                                                                    children.user_id,
                                                                            }
                                                                            : undefined
                                                                    }
                                                                    whileHover={{
                                                                        opacity: 0.7,
                                                                    }}
                                                                    isDisabled={
                                                                        loadingRoute !==
                                                                        ""
                                                                    }
                                                                    onClick={() => {
                                                                        setLoadingRoute(
                                                                            children.user_id as string
                                                                        );

                                                                        navigate(
                                                                            `${pathname}/store/${children.user_id}`
                                                                        );
                                                                    }}
                                                                >
                                                                    <ButtonLoader
                                                                        condition={
                                                                            state ===
                                                                            "loading" &&
                                                                            loadingRoute ===
                                                                            children.user_id
                                                                        }
                                                                        color="white"
                                                                    >
                                                                        WYKUP
                                                                        ABONAMENT
                                                                    </ButtonLoader>
                                                                </DefaultButton>
                                                            </>
                                                        ),
                                                    },
                                                    {
                                                        key: `progress_${children.user_id}`,
                                                        title: "POSTĘPY NAUKI",
                                                        icon: graphIcon,
                                                        location: {
                                                            to: isEnabled
                                                                ? `${pathname}/student-progress/${children.user_id}`
                                                                : `${getPreviousPath(
                                                                    pathname,
                                                                    2
                                                                )}/student-progress/${children.user_id
                                                                }`,
                                                        },
                                                    },
                                                    {
                                                        key: `teachers_${children.user_id}`,
                                                        title: "NAUCZYCIELE",
                                                        icon: personIcon,
                                                        children: (
                                                            <UserList
                                                                values={
                                                                    children.teachers
                                                                }
                                                                childrenEmail={
                                                                    children.email
                                                                }
                                                                mutation={
                                                                    mutationTeacher
                                                                }
                                                                modalText="Czy na pewno chcesz usunąć użytkownika jako nauczyciela:"
                                                                emptyText="Brak przypisanych nauczycieli"
                                                                hasPermission={
                                                                    true
                                                                }
                                                            />
                                                        ),
                                                    },
                                                ]}
                                                isInvited={
                                                    children.email ===
                                                    children.invited_by
                                                }
                                                isUnconfirmed={
                                                    children.status ===
                                                    "Zaproszony"
                                                }
                                            />
                                        </motion.div>
                                    )
                                )
                            ) : (
                                <motion.div
                                    key="children_empty"
                                    initial={{ y: -10, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    exit={{ y: 10, opacity: 0 }}
                                    transition={{
                                        type: "tween",
                                        duration: 0.2,
                                    }}
                                    className={styles.empty}
                                >
                                    Brak dzieci do wyświetlenia
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                    {!isInputDisabled && (
                        <InviteInput
                            className={styles.input}
                            title="DODAJ DZIECKO"
                            label="E-mail dziecka"
                            value={inviteValue}
                            mutation={mutation}
                            onChange={(value) => setInviteValue(value)}
                        />
                    )}
                    <div className={styles.addChildDescription}>
                        Dodaj dzieci, aby śledzić ich postępy w zdobywaniu
                        wiedzy.
                        <br /> <br /> W panelu rodzica widzisz postęp nauki
                        Twojego dziecka i możesz opłacić abonament dziecka.
                        ROZWIĄZYWANIE ZADAŃ DOSTĘPNE JEST TYLKO NA PROFILU
                        UCZNIA - konieczna rejestracja ucznia.
                    </div>
                </motion.div>
            ) : (
                <motion.div
                    key="childrens_loading"
                    className={styles.loading}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ type: "tween", duration: 0.3 }}
                >
                    <p>Trwa wczytywanie modułu</p>
                    <CircleLoader />
                </motion.div>
            )}
        </AnimatePresence>
    );
};
