import { mainApi } from "../../../api";

const time = 1 * 60 * 1000;

export const examTasksQuery = () => ({
    queryKey: ["examTasks"],
    queryFn: async () => mainApi.get("get_exams"),
    refetchInterval: time,
    staleTime: time / 4,
});

export const examTaskDetailsQuery = (examId?: string) => ({
    queryKey: examId ? ["examTaskDetails", examId] : ["examTaskDetails"],
    queryFn: async () => mainApi.get(`get_exam_exercise_list/${examId}`),
    refetchInterval: time,
    staleTime: time / 4,
});
