import { mainApi } from "../../../../api";

const time = 0.25 * 60 * 1000;

export const userNotificationsQuery = () => ({
    queryKey: ["userNotifications"],
    queryFn: async () => mainApi.get("get_user_unread_notifications"),
    staleTime: time,
    refetchInterval: time,
});
