import { useLocation } from "react-router-dom";

import { getAccountCookie } from "../services";

export const useIsHomeDashboard = () => {
    const { accountType } = getAccountCookie();

    const { pathname } = useLocation();

    return pathname === `/dashboard/${accountType}` ? true : false;
};
