const getTransition = (duration: number) => ({
    transition: {
        type: "tween",
        duration,
    },
});

const getWrapperAnimations = (height: number | undefined) => ({
    initial: { height },
    animate: { height },
    ...getTransition(0.2),
});

const changeAnimations = {
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
    ...getTransition(0.2),
};

export { getWrapperAnimations, changeAnimations };
