import { ReactComponent as ThumbdownIcon } from "../images/thumbdown-icon.svg";
import { ReactComponent as ThumbupIcon } from "../images/thumbup-icon.svg";
import { ReactComponent as QuestionmarkIcon } from "../images/questionmark-icon.svg";

export const getVisualIcon = (type: "0" | "1" | "2") => {
    switch (type) {
        case "0":
            return <ThumbdownIcon />;
        case "1":
            return <ThumbupIcon />;
        case "2":
            return <QuestionmarkIcon />;
    }
};
