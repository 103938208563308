import clsx from "clsx";

import styles from "./AccountSocialItem.module.css";

type Props = {
    icon: string;
    url: string;
    isDisabled?: boolean;
};

export const AccountSocialItem = ({ icon, url, isDisabled }: Props) => {
    return (
        <a
            className={clsx(styles.button, isDisabled && styles.disabled)}
            href={url}
            target="_blank"
            rel="noreferrer"
        >
            <img className={styles.icon} src={icon} alt="" />
        </a>
    );
};
