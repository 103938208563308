import Cookies from "js-cookie";

const key = "sessionExpired";

const setSessionExpired = (isSet: boolean) => {
    if (isSet) {
        Cookies.set(key, "true", {
            sameSite: "strict",
            expires: 30,
        });
    } else {
        removeSessionExpired();
    }
};

const getSessionExpired = () => Cookies.get(key);

const removeSessionExpired = () => Cookies.remove(key, { sameSite: "strict" });

export { setSessionExpired, getSessionExpired };
