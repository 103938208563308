import { setAccountCookie } from "../../../../services";

import { UserListResponse } from "../../typings";

export const setAccountActiveParents = (
    students: UserListResponse["students"]
) => {
    if (students.length > 0) {
        if (students.some((student) => student.status === "Zaakceptowany")) {
            setAccountCookie({
                hasActiveParents: true,
            });
        } else {
            setAccountCookie({
                parentEmail: students[0].email,
                hasActiveParents: false,
            });
        }
    } else {
        setAccountCookie({
            parentEmail: "remove",
            hasActiveParents: false,
        });
    }
};
