import clsx from "clsx";

import { useMedia } from "../../../../hooks";

import { ProgressStatsProps, StatsBoxProps } from "./ProgressStats.typings";

import styles from "./ProgressStats.module.css";

export const ProgressStats = ({ progress }: ProgressStatsProps) => {
    const { isDesktop } = useMedia();

    return (
        <div className={styles.container}>
            {isDesktop && (
                <div className={styles.title}>Ogólny postęp nauki</div>
            )}
            <div className={styles.progress}>
                <div
                    className={clsx(styles.bar, styles.good)}
                    style={{
                        width: `${(progress.good * 100) / progress.total}%`,
                    }}
                ></div>
                <div
                    className={clsx(styles.bar, styles.bad)}
                    style={{
                        width: `${(progress.bad * 100) / progress.total}%`,
                    }}
                ></div>
            </div>
            <div className={styles.row}>
                <StatsBox progress={progress} status="good" />
                <StatsBox progress={progress} />
                <StatsBox progress={progress} status="bad" />
            </div>
        </div>
    );
};

const StatsBox = ({ progress, status }: StatsBoxProps) => {
    return (
        <div
            className={clsx(
                styles.block,
                status === "good" && styles.good,
                status === "bad" && styles.bad
            )}
        >
            <div className={styles.legend}></div>
            {status === "good" && `dobrze ${progress.good}`}
            {status === "bad" && `źle ${progress.bad}`}
            {!status &&
                `pozostało ${progress.total - progress.good - progress.bad}`}
        </div>
    );
};
