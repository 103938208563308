import {
    StoreOptionsResponse,
} from "../../../../hooks/queries/useSubscriptionOptions";
import { SubscriptionKeys } from "../../../../typings";

type Options = {
    label: string;
    value: {
        id: string;
        price: string;
    };
}[];

export const getStoreOptions = (
    options: StoreOptionsResponse["subscriptions"],
    key: SubscriptionKeys
) => {
    const option = options.filter((option) => option.name === key);

    if (option) {
        const packages = option[0].packages;
        const newOptions = [] as Options;

        packages.forEach((packageItem) => {
            newOptions.push({
                label: packageItem.value,
                value: {
                    id: packageItem.id,
                    price: packageItem.price,
                },
            });
        });

        return newOptions;
    } else {
        return undefined;
    }
};
