import { useQueryClient } from "@tanstack/react-query";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import {
    useLogin,
    useRegister,
    useRegisterConfirm,
    useRemindPasswordCode,
    useRemindPasswordConfirm,
    useResetPassword,
} from "./modules/auth/hooks";
import {
    useChangePassword,
    useDeleteAccount,
    useUpdateUserData,
} from "./modules/dashboard/hooks";

import {
    getCompetencyTestLoader,
    scheduleLoader,
    studentGetToKnowDataLoader,
    studySettingsLoader,
} from "./modules/competencyTest/utils";
import {
    childrenRelationsLoader,
    childrenSubscriptionsLoader,
    learningProgressCategoryLoader,
    learningProgressLoader,
    learningProgressSubcategoryLoader,
    parentChildrensLoader,
    storeLoader,
    storeParentLoader,
    studentProgressCategoryLoader,
    teacherStudentsLoader,
    userDataLoader,
    userSubscriptionsLoader,
} from "./modules/dashboard/utils";
import {
    examTasksDetailsLoader,
    examTasksLoader,
} from "./modules/examTasks/utils";
import {
    aiSessionResultLoader,
    aiSessionTheoryLoader,
    taskCategoryLoader,
    taskLoader,
    tasksLoader,
    taskSubcategoryLoader,
    taskTheoryLoader,
} from "./modules/tasks/utils";
import { handleRouteAction } from "./utils";

import { MainAppContainer } from "./components/containers";
import { ErrorBoundary } from "./components/utils";
import {
    AnswerOnDemand,
    AnswerOnDemandDetails,
    AnswerOnDemandForm,
} from "./modules/answerOnDemand/pages";
import { getSolutionOnDemandLoader } from "./modules/answerOnDemand/utils/loaders";
import {
    Login,
    Register,
    RegisterConfirmation,
    RegisterConfirmed,
    RegisterOthers,
    RegisterStudent,
    RemindPassword,
    RemindPasswordConfirmation,
    ResetPassword,
} from "./modules/auth/pages";
import {
    CompetencyTest,
    CompetencyTestFinished,
    CompetencyTestForm,
    CompetencyTestLoading,
    Notifications,
    Sandbox,
    Schedule,
    Step1,
    Step2,
    Step3,
    Step4,
    StudySettings,
} from "./modules/competencyTest/pages";
import {
    AccountDelete,
    AccountDeleteCodeResend,
    AccountDeleteConfirmation,
    AccountDeleted,
    AccountEdit,
    ChangePassword,
    DashboardDisabled,
    DashboardError,
    DashboardLocked,
    DashboardMain,
    DashboardParent,
    DashboardStudent,
    DashboardTeacher,
    LearningProgress,
    LearningProgressCategory,
    LearningProgressSubcategory,
    LegalInformation,
    ParentAccount,
    ParentChildrens,
    PaymentError,
    PaymentSuccess,
    Store,
    StudentAccount,
    StudentParents,
    StudentProgress,
    StudentProgressCategory,
    StudentProgressSubcategory,
    Subscription,
    TeacherAccount,
    TeacherStudents,
} from "./modules/dashboard/pages";
import { DashboardPrivacyPolicy } from "./modules/dashboard/pages/Dashboard/DashboardPrivacyPolicy";
import { DashboardTermsOfUse } from "./modules/dashboard/pages/Dashboard/DashboardTermsOfUse";
import {
    studentProgressParentLoader,
    studentProgressSubcategoryLoader,
    studentProgressTeacherLoader,
} from "./modules/dashboard/utils/loaders";
import {
    FreeRestriction,
    NotFound,
    SessionExpired,
    StoreError,
} from "./modules/error";
import { ExamTaskDetails, ExamTasks } from "./modules/examTasks/pages";
import {
    AiSessionFinished,
    AiSessionResult,
    AiSessionTheory,
    Task,
    TaskCategory,
    Tasks,
    TaskSubcategory,
    TasksWrapper,
    TaskTheory,
} from "./modules/tasks/pages";

export const App = () => {
    const queryClient = useQueryClient();

    const login = useLogin();
    const remindPasswordCode = useRemindPasswordCode();
    const remindPasswordConfirm = useRemindPasswordConfirm();
    const resetPassword = useResetPassword();
    const register = useRegister();
    const registerConfirm = useRegisterConfirm();
    const updateUserData = useUpdateUserData();
    const changePassword = useChangePassword();
    const deleteAccount = useDeleteAccount();

    const router = createBrowserRouter([
        {
            path: "*",
            element: <NotFound />,
        },
        {
            path: "/",
            element: <Login mutation={login} />,
            errorElement: <ErrorBoundary />,
            action: ({ request }) => handleRouteAction(request, login.mutate),
        },
        {
            path: "/remind-password",
            element: <RemindPassword mutation={remindPasswordCode} />,
            errorElement: <ErrorBoundary />,
            action: ({ request }) =>
                handleRouteAction(request, remindPasswordCode.mutate),
            children: [
                {
                    path: "confirm",
                    element: (
                        <RemindPasswordConfirmation
                            mutation={remindPasswordConfirm}
                        />
                    ),
                    action: ({ request }) =>
                        handleRouteAction(
                            request,
                            remindPasswordConfirm.mutate
                        ),
                },
            ],
        },
        {
            path: "/reset-password",
            element: <ResetPassword mutation={resetPassword} />,
            errorElement: <ErrorBoundary />,
            action: ({ request }) =>
                handleRouteAction(request, resetPassword.mutate),
        },
        {
            path: "/register",
            element: <Register mutation={register} />,
            errorElement: <ErrorBoundary />,
            children: [
                {
                    path: "student",
                    children: [
                        {
                            index: true,
                            element: <RegisterStudent mutation={register} />,
                            action: ({ request }) =>
                                handleRouteAction(request, register.mutate),
                        },
                        {
                            path: "terms-of-use",
                            element: <DashboardTermsOfUse />,
                        },
                        {
                            path: "privacy-policy",
                            element: <DashboardPrivacyPolicy />,
                        },
                    ],
                },
                {
                    path: "parent",
                    children: [
                        {
                            index: true,
                            element: <RegisterOthers mutation={register} />,
                            action: ({ request }) =>
                                handleRouteAction(request, register.mutate),
                        },
                        {
                            path: "terms-of-use",
                            element: <DashboardTermsOfUse />,
                        },
                        {
                            path: "privacy-policy",
                            element: <DashboardPrivacyPolicy />,
                        },
                    ],
                },
                {
                    path: "teacher",
                    children: [
                        {
                            index: true,
                            element: <RegisterOthers mutation={register} />,
                            action: ({ request }) =>
                                handleRouteAction(request, register.mutate),
                        },
                        {
                            path: "terms-of-use",
                            element: <DashboardTermsOfUse />,
                        },
                        {
                            path: "privacy-policy",
                            element: <DashboardPrivacyPolicy />,
                        },
                    ],
                },
                {
                    path: "confirm",
                    element: (
                        <RegisterConfirmation mutation={registerConfirm} />
                    ),
                    action: ({ request }) =>
                        handleRouteAction(request, registerConfirm.mutate),
                },
                {
                    path: "confirmed",
                    element: <RegisterConfirmed />,
                },
            ],
        },
        {
            path: "/dashboard",
            element: <DashboardMain />,
            errorElement: <ErrorBoundary />,
            children: [
                {
                    path: "locked",
                    element: <DashboardLocked />,
                },
                {
                    path: "disabled",
                    element: <DashboardDisabled />,
                },
                {
                    path: "student",
                    element: <DashboardStudent />,
                    children: [
                        {
                            path: "account",
                            element: <StudentAccount />,
                            children: [
                                {
                                    path: "edit",
                                    children: [
                                        {
                                            index: true,
                                            element: (
                                                <AccountEdit
                                                    mutation={updateUserData}
                                                />
                                            ),
                                            loader: userDataLoader(queryClient),
                                            action: ({ request }) =>
                                                handleRouteAction(
                                                    request,
                                                    updateUserData.mutate
                                                ),
                                        },
                                        {
                                            path: "terms-of-use",
                                            element: (
                                                <DashboardTermsOfUse
                                                    containerPadding={false}
                                                />
                                            ),
                                        },
                                        {
                                            path: "privacy-policy",
                                            element: (
                                                <DashboardPrivacyPolicy
                                                    containerPadding={false}
                                                />
                                            ),
                                        },
                                    ],
                                },
                                {
                                    path: "parents",
                                    element: <StudentParents />,
                                    loader: childrenRelationsLoader(
                                        queryClient
                                    ),
                                },
                                {
                                    path: "subscription",
                                    element: <Subscription />,
                                    loader: userSubscriptionsLoader(
                                        queryClient
                                    ),
                                },
                                {
                                    path: "legal",
                                    children: [
                                        {
                                            index: true,
                                            element: <LegalInformation />,
                                        },
                                        {
                                            path: "terms-of-use",
                                            element: (
                                                <DashboardTermsOfUse
                                                    containerPadding={false}
                                                />
                                            ),
                                        },
                                        {
                                            path: "privacy-policy",
                                            element: (
                                                <DashboardPrivacyPolicy
                                                    containerPadding={false}
                                                />
                                            ),
                                        },
                                    ],
                                },
                                {
                                    path: "change-password",
                                    element: (
                                        <ChangePassword
                                            mutation={changePassword}
                                        />
                                    ),
                                    loader: userDataLoader(queryClient),
                                    action: ({ request }) =>
                                        handleRouteAction(
                                            request,
                                            changePassword.mutate
                                        ),
                                },
                                {
                                    path: "delete",
                                    element: <AccountDelete />,
                                    children: [
                                        {
                                            path: "resend",
                                            element: (
                                                <AccountDeleteCodeResend />
                                            ),
                                        },
                                        {
                                            path: "confirm",
                                            element: (
                                                <AccountDeleteConfirmation
                                                    mutation={deleteAccount}
                                                />
                                            ),
                                            action: ({ request }) =>
                                                handleRouteAction(
                                                    request,
                                                    deleteAccount.mutate
                                                ),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: "store",
                            element: <Store />,
                            loader: storeLoader(queryClient),
                            errorElement: <StoreError />,
                        },
                        {
                            element: <TasksWrapper />,
                            children: [
                                {
                                    path: "tasks",
                                    element: <Tasks />,
                                    loader: tasksLoader(queryClient),
                                    children: [
                                        {
                                            path: ":categoryId",
                                            element: <TaskCategory />,
                                            // loader: taskCategoryLoader(
                                            //     queryClient
                                            // ),
                                            children: [
                                                {
                                                    path: ":subcategoryId",
                                                    element: (
                                                        <TaskSubcategory />
                                                    ),
                                                    loader: taskSubcategoryLoader(
                                                        queryClient
                                                    ),
                                                    children: [
                                                        {
                                                            path: "theory",
                                                            element: <></>,
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    path: "task/:taskType/:taskId",
                                    element: <Task />,
                                    loader: taskLoader(queryClient),
                                    children: [
                                        {
                                            path: "theory",
                                            element: <TaskTheory />,
                                            loader: taskTheoryLoader(
                                                queryClient
                                            ),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: "ai-session/theory/:sessionId",
                            element: <AiSessionTheory />,
                            loader: aiSessionTheoryLoader(queryClient),
                        },
                        {
                            path: "ai-session/result/:sessionId",
                            element: <AiSessionResult />,
                            loader: aiSessionResultLoader(queryClient),
                        },
                        {
                            path: "ai-session/finished",
                            element: <AiSessionFinished />,
                        },
                        {
                            path: "exam-tasks",
                            element: <ExamTasks />,
                            loader: examTasksLoader(queryClient),
                        },
                        {
                            path: "exam-tasks/:examId",
                            element: <ExamTaskDetails />,
                            loader: examTasksDetailsLoader(queryClient),
                        },
                        {
                            path: "competency-test",
                            element: <CompetencyTest />,
                            loader: studentGetToKnowDataLoader(queryClient),
                            children: [
                                {
                                    path: "*",
                                    element: <></>,
                                },
                                {
                                    path: "form",
                                    element: <CompetencyTestForm />,
                                    children: [
                                        { index: true, element: <Step1 /> },
                                        { path: "target", element: <Step2 /> },
                                        { path: "grade", element: <Step3 /> },
                                        { path: "finish", element: <Step4 /> },
                                    ],
                                },
                                {
                                    path: "loading",
                                    element: <CompetencyTestLoading />,
                                },
                                {
                                    path: "finished",
                                    element: <CompetencyTestFinished />,
                                    loader: getCompetencyTestLoader(
                                        queryClient
                                    ),
                                },
                                {
                                    path: "schedule",
                                    element: <Schedule />,
                                    loader: scheduleLoader(queryClient),
                                },
                                {
                                    path: "notifications",
                                    element: <Notifications />,
                                },
                                {
                                    path: "study-settings",
                                    element: <StudySettings />,
                                    loader: studySettingsLoader(queryClient),
                                },
                            ],
                        },
                        {
                            path: "answer-on-demand",
                            element: <AnswerOnDemand />,
                            loader: userSubscriptionsLoader(queryClient),
                            children: [
                                {
                                    path: "form",
                                    element: <AnswerOnDemandForm />,
                                },
                                {
                                    path: ":id",
                                    element: <AnswerOnDemandDetails />,
                                    loader: getSolutionOnDemandLoader(
                                        queryClient
                                    ),
                                },
                            ],
                        },
                        {
                            path: "learning-progress",
                            element: <LearningProgress />,
                            loader: learningProgressLoader(queryClient),
                            children: [
                                {
                                    path: ":classType/:categoryId/",
                                    element: <LearningProgressCategory />,
                                    loader: learningProgressCategoryLoader(
                                        queryClient
                                    ),
                                    children: [
                                        {
                                            path: ":subcategoryId",
                                            element: (
                                                <LearningProgressSubcategory />
                                            ),
                                            loader: learningProgressSubcategoryLoader(
                                                queryClient
                                            ),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: "sbx",
                            element: <Sandbox />,
                        },
                        {
                            path: "restriction",
                            element: <FreeRestriction />,
                        },
                    ],
                },
                {
                    path: "parent",
                    element: <DashboardParent />,
                    children: [
                        {
                            path: "account",
                            element: <ParentAccount />,
                            children: [
                                {
                                    path: "edit",
                                    children: [
                                        {
                                            index: true,
                                            element: (
                                                <AccountEdit
                                                    mutation={updateUserData}
                                                />
                                            ),
                                            loader: userDataLoader(queryClient),
                                            action: ({ request }) =>
                                                handleRouteAction(
                                                    request,
                                                    updateUserData.mutate
                                                ),
                                        },
                                        {
                                            path: "terms-of-use",
                                            element: (
                                                <DashboardTermsOfUse
                                                    containerPadding={false}
                                                />
                                            ),
                                        },
                                        {
                                            path: "privacy-policy",
                                            element: (
                                                <DashboardPrivacyPolicy
                                                    containerPadding={false}
                                                />
                                            ),
                                        },
                                    ],
                                },
                                {
                                    path: "childrens",
                                    element: <ParentChildrens />,
                                    loader: parentChildrensLoader(queryClient),
                                },
                                {
                                    path: "subscription",
                                    element: <Subscription />,
                                    loader: childrenSubscriptionsLoader(
                                        queryClient
                                    ),
                                },
                                {
                                    path: "legal",
                                    children: [
                                        {
                                            index: true,
                                            element: <LegalInformation />,
                                        },
                                        {
                                            path: "terms-of-use",
                                            element: (
                                                <DashboardTermsOfUse
                                                    containerPadding={false}
                                                />
                                            ),
                                        },
                                        {
                                            path: "privacy-policy",
                                            element: (
                                                <DashboardPrivacyPolicy
                                                    containerPadding={false}
                                                />
                                            ),
                                        },
                                    ],
                                },
                                {
                                    path: "change-password",
                                    element: (
                                        <ChangePassword
                                            mutation={changePassword}
                                        />
                                    ),
                                    loader: userDataLoader(queryClient),
                                    action: ({ request }) =>
                                        handleRouteAction(
                                            request,
                                            changePassword.mutate
                                        ),
                                },
                                {
                                    path: "delete",
                                    element: <AccountDelete />,
                                    loader: parentChildrensLoader(queryClient),
                                    children: [
                                        {
                                            path: "resend",
                                            element: (
                                                <AccountDeleteCodeResend />
                                            ),
                                        },
                                        {
                                            path: "confirm",
                                            element: (
                                                <AccountDeleteConfirmation
                                                    mutation={deleteAccount}
                                                />
                                            ),
                                            action: ({ request }) =>
                                                handleRouteAction(
                                                    request,
                                                    deleteAccount.mutate
                                                ),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: "student-progress/:childrenId",
                            element: <StudentProgress />,
                            loader: studentProgressParentLoader(queryClient),
                            children: [
                                {
                                    path: ":classType/:categoryId/",
                                    element: <StudentProgressCategory />,
                                    loader: studentProgressCategoryLoader(
                                        queryClient
                                    ),
                                    children: [
                                        {
                                            path: ":subcategoryId",
                                            element: (
                                                <StudentProgressSubcategory />
                                            ),
                                            loader: studentProgressSubcategoryLoader(
                                                queryClient
                                            ),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: "store/:childrenId",
                            element: <Store />,
                            loader: storeParentLoader(queryClient),
                            errorElement: <StoreError />,
                        },
                    ],
                },
                {
                    path: "teacher",
                    element: <DashboardTeacher />,
                    children: [
                        {
                            path: "account",
                            element: <TeacherAccount />,
                            children: [
                                {
                                    path: "edit",
                                    children: [
                                        {
                                            index: true,
                                            element: (
                                                <AccountEdit
                                                    mutation={updateUserData}
                                                />
                                            ),
                                            loader: userDataLoader(queryClient),
                                            action: ({ request }) =>
                                                handleRouteAction(
                                                    request,
                                                    updateUserData.mutate
                                                ),
                                        },
                                        {
                                            path: "terms-of-use",
                                            element: (
                                                <DashboardTermsOfUse
                                                    containerPadding={false}
                                                />
                                            ),
                                        },
                                        {
                                            path: "privacy-policy",
                                            element: (
                                                <DashboardPrivacyPolicy
                                                    containerPadding={false}
                                                />
                                            ),
                                        },
                                    ],
                                },
                                {
                                    path: "students",
                                    element: <TeacherStudents />,
                                    loader: teacherStudentsLoader(queryClient),
                                },
                                {
                                    path: "legal",
                                    children: [
                                        {
                                            index: true,
                                            element: <LegalInformation />,
                                        },
                                        {
                                            path: "terms-of-use",
                                            element: (
                                                <DashboardTermsOfUse
                                                    containerPadding={false}
                                                />
                                            ),
                                        },
                                        {
                                            path: "privacy-policy",
                                            element: (
                                                <DashboardPrivacyPolicy
                                                    containerPadding={false}
                                                />
                                            ),
                                        },
                                    ],
                                },
                                {
                                    path: "change-password",
                                    element: (
                                        <ChangePassword
                                            mutation={changePassword}
                                        />
                                    ),
                                    loader: userDataLoader(queryClient),
                                    action: ({ request }) =>
                                        handleRouteAction(
                                            request,
                                            changePassword.mutate
                                        ),
                                },
                                {
                                    path: "delete",
                                    element: <AccountDelete />,
                                    children: [
                                        {
                                            path: "resend",
                                            element: (
                                                <AccountDeleteCodeResend />
                                            ),
                                        },
                                        {
                                            path: "confirm",
                                            element: (
                                                <AccountDeleteConfirmation
                                                    mutation={deleteAccount}
                                                />
                                            ),
                                            action: ({ request }) =>
                                                handleRouteAction(
                                                    request,
                                                    deleteAccount.mutate
                                                ),
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: "student-progress/:childrenId",
                            element: <StudentProgress />,
                            loader: studentProgressTeacherLoader(queryClient),
                            children: [
                                {
                                    path: ":classType/:categoryId/",
                                    element: <StudentProgressCategory />,
                                    loader: studentProgressCategoryLoader(
                                        queryClient
                                    ),
                                    children: [
                                        {
                                            path: ":subcategoryId",
                                            element: (
                                                <StudentProgressSubcategory />
                                            ),
                                            loader: studentProgressSubcategoryLoader(
                                                queryClient
                                            ),
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    path: "undefined",
                    element: <DashboardError />,
                },
                {
                    path: "session-expired",
                    element: <SessionExpired />,
                },
                {
                    path: "payment-success",
                    element: <PaymentSuccess />,
                },
                {
                    path: "payment-error",
                    element: <PaymentError />,
                },
            ],
        },
        {
            path: "account-deleted",
            element: <AccountDeleted />,
        },
        {
            path: "terms-of-use",
            element: <DashboardTermsOfUse />,
        },
        {
            path: "privacy-policy",
            element: <DashboardPrivacyPolicy />,
        },
    ]);

    return (
        <MainAppContainer>
            <RouterProvider router={router} />
        </MainAppContainer>
    );
};
