import { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { Formik, Form } from "formik";
import { Navigate, useNavigate } from "react-router-dom";

import { getRegisterCookie, removeRegisterCookie } from "../../../../services";

import { useAuthContext } from "../../../../contexts/AuthContext";
import { useLoadingContext } from "../../../../contexts/LoadingContext";

import { useCodeResend, useRegisterConfirm } from "../../hooks";
import { useMedia, useSubmitForm } from "../../../../hooks";

import { ArrowButton, DefaultButton } from "../../../../components/buttons";
import { AuthContainer } from "../../../../components/containers";
import { MainHeading } from "../../../../components/headings";
import { ActionText, AuthText, ErrorText } from "../../../../components/texts";
import { DefaultInput } from "../../../../components/inputs";
import { DefaultModal } from "../../../../components/modals";
import { DisableBack } from "../../../../components/utils";

import { confirmationSchema } from "../../utils";

import styles from "./RegisterConfirmation.module.css";

import pi from "../../../../assets/images/robots/pi-loading.svg";
import pi2 from "../../../../assets/images/robots/pi-error.svg";

const AnimatedForm = motion(Form);

type Props = {
    mutation: ReturnType<typeof useRegisterConfirm>;
};

export const RegisterConfirmation = ({ mutation }: Props) => {
    const registerCookie = getRegisterCookie();

    const submitForm = useSubmitForm();
    const navigate = useNavigate();
    const { setLoadingActive } = useLoadingContext();
    const { isDesktop } = useMedia();
    const codeResend = useCodeResend(true);

    const { setIsUserLogged } = useAuthContext();

    const isFormSubmitted = useRef(false);
    const [errorResponse, setErrorResponse] = useState<[boolean, string]>([
        false,
        "",
    ]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCodeResent, setIsCodeResent] = useState(false);

    useEffect(() => {
        if (mutation.error && isFormSubmitted.current) {
            isFormSubmitted.current = false;

            setErrorResponse([
                true,
                mutation.error.response.data.messages.error,
            ]);

            setLoadingActive(false);
        }
    }, [mutation.error, setLoadingActive]);

    useEffect(() => {
        if (mutation.data && mutation.isSuccess && registerCookie) {
            setLoadingActive(false, () => {
                removeRegisterCookie();

                if (mutation.data.data.parent_confirmation_required) {
                    setIsUserLogged(true);
                } else {
                    navigate("/register/confirmed", {
                        state: {
                            isConfirmed: true,
                        },
                    });
                }

                mutation.reset();
            });
        }
    }, [mutation, registerCookie, setLoadingActive, navigate, setIsUserLogged]);

    const handleLogout = () => {
        removeRegisterCookie();

        navigate("/");
    };

    const handleCodeResend = () => {
        codeResend.mutate(registerCookie!);

        setIsCodeResent(true);
    };

    if (!mutation.isSuccess && !registerCookie) {
        return <Navigate to="/register" replace={true} />;
    }

    return (
        <>
            {!isCodeResent && (
                <ArrowButton
                    wrapper={styles.back}
                    onClick={() => setIsModalOpen(true)}
                >
                    Wyloguj
                </ArrowButton>
            )}
            <AuthContainer
                position="center"
                image={isCodeResent ? pi : errorResponse[0] ? pi2 : pi}
                imageSize={isDesktop ? 285 : 214}
                imagePosition="right"
                imageOffsetX={isDesktop ? 78 : undefined}
                imageOffsetY={isDesktop ? 15.5556 : undefined}
            >
                <MainHeading wrapper={styles.heading} elementType="h2">
                    {isCodeResent
                        ? "Kod aktywacyjny został wysłany ponownie"
                        : "Potwierdzenie rejestracji"}
                </MainHeading>
                <AuthText>
                    {isCodeResent ? (
                        <p>
                            Kod aktywacyjny został wysłany
                            <br />
                            na adres:
                            <br />
                            <strong>{registerCookie}</strong>
                        </p>
                    ) : (
                        <p>
                            Na podany przez Ciebie adres e-mail wysłaliśmy
                            <br />
                            <strong>KOD POTWIERDZAJĄCY</strong>. Wpisz go
                            poniżej.
                        </p>
                    )}
                </AuthText>
                {isCodeResent ? (
                    <DefaultButton
                        wrapper={styles.forwardButton}
                        whileHover={{ opacity: 0.7 }}
                        onClick={() => {
                            setErrorResponse([false, ""]);

                            setIsCodeResent(false);
                        }}
                    >
                        DALEJ
                    </DefaultButton>
                ) : (
                    <>
                        <Formik
                            initialValues={{
                                email: registerCookie,
                                code: "",
                            }}
                            validationSchema={confirmationSchema}
                            onSubmit={(data, actions) => {
                                isFormSubmitted.current = true;

                                setLoadingActive(true, () => {
                                    submitForm(data);

                                    actions.setSubmitting(false);
                                });
                            }}
                        >
                            {(formik) => (
                                <AnimatedForm
                                    initial={{
                                        paddingTop: "15px",
                                    }}
                                    animate={{
                                        paddingTop: errorResponse[1]
                                            ? "30px"
                                            : "15px",
                                    }}
                                    transition={{
                                        type: "tween",
                                        duration: 0.3,
                                    }}
                                >
                                    <ErrorText isError={!!errorResponse[1]}>
                                        {errorResponse[1]}
                                    </ErrorText>
                                    <DefaultInput
                                        label="E-mail"
                                        name="email"
                                        type="email"
                                        isDisabled={true}
                                    />
                                    <DefaultInput
                                        label="Kod potwierdzający"
                                        name="code"
                                        type="text"
                                        maxLength={6}
                                        isNumeric={true}
                                        isMasked={true}
                                        isError={!!errorResponse[1]}
                                        onChange={() =>
                                            setErrorResponse((prevResponse) => [
                                                prevResponse[0],
                                                "",
                                            ])
                                        }
                                    />
                                    <DefaultButton
                                        wrapper={styles.formButton}
                                        initial={{
                                            backgroundColor:
                                                DefaultButton.color.disabled,
                                        }}
                                        animate={{
                                            backgroundColor:
                                                !!errorResponse[1] ||
                                                !formik.dirty ||
                                                !formik.isValid ||
                                                formik.isSubmitting
                                                    ? DefaultButton.color
                                                          .disabled
                                                    : DefaultButton.color
                                                          .enabled,
                                        }}
                                        whileHover={{
                                            opacity: formik.isSubmitting
                                                ? 1
                                                : 0.7,
                                        }}
                                        transition={{
                                            type: "tween",
                                            duration: 0.3,
                                        }}
                                        isSubmit={true}
                                        isDisabled={
                                            !!errorResponse[1] ||
                                            !formik.dirty ||
                                            !formik.isValid ||
                                            formik.isSubmitting
                                        }
                                    >
                                        WYŚLIJ
                                    </DefaultButton>
                                </AnimatedForm>
                            )}
                        </Formik>
                        <ActionText
                            className={styles.infoText}
                            messages={
                                errorResponse[0]
                                    ? [
                                          "Sprawdź dokładnie kod i wpisz go ponownie.",
                                          "Twój kod mógł też stracić ważność.",
                                      ]
                                    : ["Nie mam żadnego kodu."]
                            }
                            button="WYŚLIJ PONOWNIE"
                            callback={handleCodeResend}
                            isButtonLast={errorResponse[0] ? true : false}
                        />
                    </>
                )}
            </AuthContainer>
            <DefaultModal
                title="WYLOGUJ"
                actions={[
                    {
                        name: "TAK, WYLOGUJ",
                        isImportant: true,
                        onClick: handleLogout,
                    },
                    {
                        name: "NIE, WRÓĆ",
                        onClick: () => setIsModalOpen(false),
                    },
                ]}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            >
                <p>
                    Czy na pewno chcesz się wylogować
                    <br />z adresu:
                </p>
                <p>
                    <strong className={styles.break}>{registerCookie}</strong>
                </p>
            </DefaultModal>
            <>{isCodeResent && <DisableBack />}</>
        </>
    );
};
