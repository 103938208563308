import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

type Mutation = string | null | undefined;

export const useLogoutUser = () => {
    return useMutation<AxiosResponse<any, any>, unknown, Mutation, unknown>(
        (data) => mainApi.get(data ? `logout?device_token=${data}` : "logout")
    );
};
