import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { taskSubcategoryQuery } from "../../utils";

import { TaskSubcategoryResponse } from "../../typings";

export const useTaskSubcategory = (
    subcategoryId: string,
    userClass: string
) => {
    return useQuery<AxiosResponse<TaskSubcategoryResponse, any>>(
        taskSubcategoryQuery(subcategoryId, userClass)
    );
};
