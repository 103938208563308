import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";

import { useNotify } from "../../../../hooks";
import {
    useParentChildrens,
    useParentUnderageChildrenActions,
    useRelationByParent,
} from "../../hooks";

import { DefaultButton } from "../../../../components/buttons";
import { CircleLoader } from "../../../../components/loaders";
import { ButtonLoader } from "../../../../components/utils";
import { ChildrenItemDesktop } from "../ChildrenItemDesktop";
import { InviteInput } from "../InviteInput";
import { SubscriptionPackages } from "../SubscriptionPackages";
import { UserList } from "../UserList";
import { PlusIcon } from "./icons";

import { parentChildrensQuery } from "../../utils";

import styles from "./ChildrensDesktop.module.css";

import coinIcon from "../../images/dashboard/parent/coin-icon.svg";
import personIcon from "../../images/dashboard/parent/person-icon.svg";

type Props = {
    isEnabled?: boolean;
};

export const ChildrensDesktop = ({ isEnabled }: Props) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { state } = useNavigation();

    const queryKey = parentChildrensQuery().queryKey;
    const queryResult = useParentChildrens(isEnabled, isEnabled);
    const mutation = useRelationByParent(queryKey);
    const mutationTeacher = useParentUnderageChildrenActions(queryKey);

    const [isQueryLoaded, setIsQueryLoaded] = useState(
        queryResult.data ? true : false
    );

    const notify = useNotify({ type: "error" });
    const inviteRef = useRef<HTMLDivElement>(null);
    const [inviteValue, setInviteValue] = useState("");

    const [loadingRoute, setLoadingRoute] = useState("");

    const handleScroll = () => {
        const container = document.querySelector("#dashboardContainer");

        if (container && inviteRef.current) {
            container.scroll({
                behavior: "smooth",
                top: inviteRef.current.offsetTop,
            });
        }
    };

    useEffect(() => {
        if (queryResult.data && !isQueryLoaded) {
            setTimeout(() => {
                setIsQueryLoaded(true);
            }, 1000);
        }
    }, [queryResult.data, isQueryLoaded]);

    useEffect(() => {
        if (
            mutation.isError &&
            mutation.error &&
            mutation.error.response.data
        ) {
            const messages = mutation.error.response.data.messages;
            const messageKey = Object.keys(messages)[0];

            notify(messages[messageKey]);

            mutation.reset();
        }
    }, [mutation, notify]);

    return (
        <>
            <AnimatePresence mode="wait" initial={false}>
                {isQueryLoaded ? (
                    <motion.div
                        key="childrens"
                        className={styles.container}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ type: "tween", duration: 0.3 }}
                    >
                        <h3 className={styles.title}>
                            MOJE DZIECI
                            <button
                                className={styles.scrollToButton}
                                type="button"
                                onClick={handleScroll}
                            >
                                <PlusIcon />
                            </button>
                        </h3>
                        <AnimatePresence mode="sync" initial={false}>
                            {queryResult.data &&
                            queryResult.data.data.children.length > 0 ? (
                                queryResult.data.data.children.map(
                                    (children) => (
                                        <motion.div
                                            key={
                                                children.user_id ??
                                                children.email
                                            }
                                            initial={{ opacity: 0, scale: 0.7 }}
                                            animate={{ opacity: 1, scale: 1 }}
                                            exit={{ opacity: 0, scale: 0.7 }}
                                            transition={{
                                                type: "tween",
                                                duration: 0.2,
                                            }}
                                        >
                                            <ChildrenItemDesktop
                                                id={children.user_id}
                                                name={
                                                    children.first_name &&
                                                    children.last_name
                                                        ? `${children.first_name} ${children.last_name}`
                                                        : children.email
                                                }
                                                schoolClass={children.class}
                                                email={children.email}
                                                mutation={mutation}
                                                elements={[
                                                    {
                                                        key: `subscription_${children.user_id}`,
                                                        title: "ABONAMENT",
                                                        icon: coinIcon,
                                                        children: (
                                                            <>
                                                                <SubscriptionPackages
                                                                    subscriptions={
                                                                        children.subscriptions
                                                                    }
                                                                />
                                                                <DefaultButton
                                                                    wrapper={
                                                                        styles.subscriptionButton
                                                                    }
                                                                    state={
                                                                        children.user_id
                                                                            ? {
                                                                                  childrenId:
                                                                                      children.user_id,
                                                                              }
                                                                            : undefined
                                                                    }
                                                                    whileHover={{
                                                                        opacity: 0.7,
                                                                    }}
                                                                    isDisabled={
                                                                        loadingRoute !==
                                                                        ""
                                                                    }
                                                                    onClick={() => {
                                                                        setLoadingRoute(
                                                                            children.user_id as string
                                                                        );

                                                                        navigate(
                                                                            `${pathname}/store/${children.user_id}`
                                                                        );
                                                                    }}
                                                                >
                                                                    <ButtonLoader
                                                                        condition={
                                                                            state ===
                                                                                "loading" &&
                                                                            loadingRoute ===
                                                                                children.user_id
                                                                        }
                                                                        color="white"
                                                                    >
                                                                        WYKUP
                                                                        ABONAMENT
                                                                    </ButtonLoader>
                                                                </DefaultButton>
                                                            </>
                                                        ),
                                                    },
                                                    {
                                                        key: `teachers_${children.user_id}`,
                                                        title: "NAUCZYCIELE",
                                                        icon: personIcon,
                                                        children: (
                                                            <UserList
                                                                values={
                                                                    children.teachers
                                                                }
                                                                childrenEmail={
                                                                    children.email
                                                                }
                                                                mutation={
                                                                    mutationTeacher
                                                                }
                                                                modalText="Czy na pewno chcesz usunąć użytkownika jako nauczyciela:"
                                                                emptyText="Brak przypisanych nauczycieli"
                                                                hasPermission={
                                                                    true
                                                                }
                                                            />
                                                        ),
                                                    },
                                                ]}
                                                isInvited={
                                                    children.email ===
                                                    children.invited_by
                                                }
                                                isUnconfirmed={
                                                    children.status ===
                                                    "Zaproszony"
                                                }
                                                isDashboard={isEnabled}
                                            />
                                        </motion.div>
                                    )
                                )
                            ) : (
                                <motion.div
                                    key="children_empty"
                                    initial={{ y: -10, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    exit={{ y: 10, opacity: 0 }}
                                    transition={{
                                        type: "tween",
                                        duration: 0.2,
                                    }}
                                    className={styles.empty}
                                >
                                    Brak dzieci do wyświetlenia
                                </motion.div>
                            )}
                        </AnimatePresence>
                        <div className={styles.invite} ref={inviteRef}>
                            <InviteInput
                                className={styles.input}
                                title="DODAJ DZIECKO"
                                label="E-mail dziecka"
                                value={inviteValue}
                                mutation={mutation}
                                onChange={(value) => setInviteValue(value)}
                            />
                            <div className={styles.addChildDescription}>
                                Dodaj dzieci, aby śledzić ich postępy w
                                zdobywaniu wiedzy.
                                <br /> <br /> W panelu rodzica widzisz postęp
                                nauki Twojego dziecka i możesz opłacić abonament
                                dziecka. ROZWIĄZYWANIE ZADAŃ DOSTĘPNE JEST TYLKO
                                NA PROFILU UCZNIA - konieczna rejestracja
                                ucznia.
                            </div>
                        </div>
                    </motion.div>
                ) : (
                    <motion.div
                        key="childrens_loading"
                        className={styles.loading}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ type: "tween", duration: 0.3 }}
                    >
                        <p>Trwa wczytywanie modułu</p>
                        <CircleLoader />
                    </motion.div>
                )}
            </AnimatePresence>
            {loadingRoute && <div className={styles.loadingMask}></div>}
        </>
    );
};
