import { StoreKeys } from "../Store.typings";

export const getStorePackageKey = <K extends StoreKeys>(
    key: K,
    type: K extends "highSchool" ? "basic" | "extended" : unknown
) => {
    if (key === "primarySchool") {
        return "Egzamin 8-klasisty";
    } else if (key === "highSchool" && type === "basic") {
        return "Matura podstawowa";
    } else if (key === "highSchool" && type === "extended") {
        return "Matura rozszerzona";
    } else if (key === "solutionOnDemand") {
        return "Rozwiązanie na zawołanie";
    }
};
