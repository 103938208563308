import { AnimatePresence, motion } from "framer-motion";

import { CheckmarkIcon } from "../icon-components";

import { CheckboxProps } from "./Checkbox.typings";

import styles from "./Checkbox.module.css";

const buttonVariants = {
    default: {
        opacity: 1,
    },
    disabled: {
        opacity: 0.5,
    },
};

export const Checkbox = ({
    children,
    icon,
    isChecked,
    isDisabled,
    isLoading,
    onClick,
}: CheckboxProps) => {
    return (
        <motion.button
            className={styles.button}
            type="button"
            variants={buttonVariants}
            initial={isDisabled ? "disabled" : "default"}
            animate={isDisabled ? "disabled" : "default"}
            transition={{ type: "tween", duration: 0.3 }}
            disabled={isDisabled || isLoading}
            onClick={onClick}
        >
            <span className={styles.checkbox}>
                <AnimatePresence mode="wait" initial={false}>
                    {isChecked && (
                        <motion.span
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: 20, opacity: 0 }}
                            transition={{ type: "tween", duration: 0.3 }}
                        >
                            <CheckmarkIcon isSmall={true} />
                        </motion.span>
                    )}
                </AnimatePresence>
            </span>
            <span className={styles.content}>
                {icon && (
                    <span className={styles.icon}>
                        {typeof icon === "string" ? (
                            <img src={icon} alt="" />
                        ) : (
                            icon
                        )}
                    </span>
                )}
                <span className={styles.name}>{children}</span>
            </span>
        </motion.button>
    );
};
