import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import { useReadUserNotification } from "../../hooks";
import { getPathFromUrl } from "../../utils";
import {
    EnvelopeIcon,
    ExclamationIcon,
    GoIcon,
    QuestionIcon,
} from "../icon-components";
import { NotificationButton } from "../NotificationButton";
import { NotificationItemProps } from "./NotificationItem.typings";

import styles from "./NotificationItem.module.css";

export const NotificationItem = ({
    id,
    body,
    type,
    url,
    createdAt,
    queryKeys,
    isDisabled,
    isLoading,
    isRedirecting,
    setIsOpen,
    setIsRedirecting,
}: NotificationItemProps) => {
    const navigate = useNavigate();
    const readUserNotification = useReadUserNotification(queryKeys);

    const isRead = isLoading || readUserNotification.isSuccess;
    const isCritical = type === "Krytyczne" ? true : false;

    const handleGoTo = () => {
        if (url) {
            const pathFromUrl = getPathFromUrl(url);

            if (pathFromUrl.isInsideApp) {
                navigate(pathFromUrl.url);

                setIsRedirecting(true);
            } else {
                window.open(pathFromUrl.url, "_blank");

                setIsOpen(false);
            }
        }

        readUserNotification.mutate({ id });
    };

    return (
        <div
            className={clsx(
                styles.container,
                isCritical && styles.critical,
                (isDisabled || isRead) && styles.disabled
            )}
        >
            <div className={styles.wrapper}>
                <div className={styles.icon}>
                    {isCritical ? <ExclamationIcon /> : <QuestionIcon />}
                </div>
                <div className={styles.body}>
                    <p className={styles.text}>
                        <span>{body}</span> <b>{createdAt}</b>
                    </p>
                    <ul className={styles.actions}>
                        <li>
                            <NotificationButton
                                icon={<GoIcon isAlt={true} />}
                                size={121}
                                isDisabled={isDisabled || isRead}
                                isLoading={isLoading}
                                isRedirecting={isRedirecting}
                                onClick={handleGoTo}
                            >
                                PRZEJDŹ DO
                            </NotificationButton>
                        </li>
                        <li>
                            <NotificationButton
                                icon={<EnvelopeIcon isAlt={true} />}
                                isDisabled={isDisabled || isRead}
                                isRedirecting={isRedirecting}
                                onClick={() =>
                                    readUserNotification.mutate({ id })
                                }
                            >
                                OZNACZ JAKO PRZECZYTANE
                            </NotificationButton>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
