export const dropdownAnimations = {
    initial: {
        y: -40,
        opacity: 0,
        scale: 0.9,
    },
    animate: {
        y: 0,
        opacity: 1,
        scale: 1,
    },
    exit: {
        y: 10,
        opacity: 0,
        scale: 1
    }
};