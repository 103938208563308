import React, { useRef, useEffect, forwardRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";

import { setTaskParams } from "../../services";

import { useMedia } from "../../../../hooks";

import { TaskItem } from "../TaskItem";

import { createGridRepeat, getPreviousPath } from "../../../../utils";
import { taskSlug } from "../../utils";

import { FilterProps } from "./TasksFilter.typings";

import styles from "./TasksFilter.module.css";

export const TasksFilter = ({
    tasks,
    gridHeightState,
    isFiltered,
    isLoading,
}: FilterProps) => {
    const { isDesktop } = useMedia();

    const { pathname } = useLocation();

    const tasksContainerRef = useRef<HTMLDivElement>(null);
    const isTaskHeightDelay = useRef(true);
    const [gridHeight, setGridHeight] = gridHeightState;

    useEffect(() => {
        if (!isFiltered) {
            isTaskHeightDelay.current = false;
        } else {
            isTaskHeightDelay.current = true;
        }

        setTimeout(
            () => {
                if (tasksContainerRef.current) {
                    setGridHeight(tasksContainerRef.current.clientHeight);
                }
            },
            isTaskHeightDelay.current ? 400 : 0
        );
    }, [isFiltered, isDesktop, setGridHeight]);

    return (
        <motion.div
            className={styles.tasks}
            initial={{ height: gridHeight, opacity: 0 }}
            animate={{ height: gridHeight, opacity: 1 }}
            transition={{
                height: {
                    type: "tween",
                    duration: 0.3,
                },
                opacity: {
                    type: "tween",
                    duration: 0.3,
                    delay: 0.3,
                },
            }}
        >
            <div
                ref={tasksContainerRef}
                className={styles.tasksGrid}
                style={{
                    gridTemplateRows: createGridRepeat(
                        tasks.length > 0 ? tasks.length : 1
                    ),
                }}
            >
                {tasks.length > 0 ? (
                    <AnimatePresence mode="sync" initial={false}>
                        {tasks.map((task, index) => {
                            const taskContent = `Zadanie ${index + 1}`;
                            const taskProps = {
                                key: task.id,
                                id: task.id,
                                to: `${getPreviousPath(pathname, 3)}/task/${
                                    taskSlug.default
                                }/${task.id}`,
                                level: task.difficulty,
                                isLoading,
                                isExam: task.exercise_type === "Egzaminacyjne",
                                isSuccess:
                                    task.already_tried && task.done_correctly,
                                isError:
                                    task.already_tried && !task.done_correctly,
                            };

                            return isFiltered ? (
                                task.exercise_type === "Egzaminacyjne" ? (
                                    <Task {...taskProps}>{taskContent}</Task>
                                ) : null
                            ) : (
                                <Task {...taskProps}>{taskContent}</Task>
                            );
                        })}
                    </AnimatePresence>
                ) : (
                    <div>
                        <div className={styles.empty}>
                            Brak zadań do wyświetlenia
                        </div>
                    </div>
                )}
            </div>
        </motion.div>
    );
};

const Task = forwardRef(
    (
        forwardedProps: React.ComponentProps<typeof TaskItem> & { id: string },
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        const {
            children,
            id,
            to,
            level,
            isExam,
            isSuccess,
            isError,
            isLoading,
        } = forwardedProps;

        return (
            <motion.div
                ref={ref}
                layout={true}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                    type: "tween",
                    duration: 0.3,
                }}
            >
                <TaskItem
                    to={to}
                    level={level}
                    isExam={isExam}
                    isSuccess={isSuccess}
                    isError={isError}
                    isLoading={isLoading}
                    onClick={() =>
                        setTaskParams({ type: taskSlug.default, taskId: id })
                    }
                >
                    {children}
                </TaskItem>
            </motion.div>
        );
    }
);
