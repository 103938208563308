import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

type Error = any;

// type Mutation = {
//     [name: string]: FormDataEntryValue;
// };

type Mutation = any;

export const useDeleteAccountSendCode = () => {
    return useMutation<AxiosResponse<any, any>, Error, Mutation, unknown>(() =>
        mainApi.post("delete_account_send_code")
    );
};
