import React, { useState, useRef, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

import {
    collapseVariants,
    labelVariants,
    inputVariants,
    buttonVariants,
    iconVariants,
} from "./DiscountInput.animations";
import { DiscountInputProps } from "./DiscountInput.typings";

import styles from "./DiscountInput.module.css";

import plusIcon from "./images/plus-icon.svg";
import checkmarkIcon from "./images/checkmark-icon.svg";

export const DiscountInput = ({
    className,
    title,
    label,
    data,
    mutation,
    isLoaded,
    onChange,
    onSuccess,
}: DiscountInputProps) => {
    const [isOpen, setIsOpen] = useState(isLoaded ?? false);
    const [isFocused, setIsFocused] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const isSuccess = !!data.response;
    const [error, setError] = useState("");
    const isFilled = data.value.length > 0;

    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleOpen = () => setIsOpen((open) => !open);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;

        if (error) {
            setError("");
        }

        onChange(value);
    };

    const handleOnKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const isEnter = event.charCode === 13 || event.code === "Enter";

        if (isEnter && isFilled && !isSubmitted && !mutation.isLoading) {
            handleSubmit();

            event.currentTarget.blur();
        }
    };

    const handleSubmit = () => {
        if (isFilled) {
            if (error) {
                setError("");

                onChange("");
            } else {
                if (!isSubmitted && !mutation.isLoading) {
                    setIsSubmitted(true);

                    mutation.mutate({ discountCode: data.value });
                }
            }
        } else {
            handleOpen();
        }
    };

    const handleRemoveDiscount = () => {
        onChange("");

        onSuccess(undefined);
    };

    useEffect(() => {
        if (isSubmitted && mutation.error) {
            const data = mutation.error.response.data;

            if (data) {
                setError(data.messages.error);
            }

            setIsSubmitted(false);

            mutation.reset();
        }
    }, [isSubmitted, mutation]);

    useEffect(() => {
        if (isSubmitted && mutation.data && mutation.isSuccess) {
            const data = mutation.data.data.code;

            onSuccess({
                code: data.code,
                discount: parseInt(data.discount),
                packages: data.packages.split(","),
            });

            setIsSubmitted(false);

            mutation.reset();
        }
    }, [isSubmitted, mutation, onSuccess]);

    return (
        <motion.div
            className={className}
            initial="closed"
            animate={isSuccess ? "success" : isOpen ? "open" : "closed"}
            variants={collapseVariants}
            transition={{ type: "tween", duration: 0.3 }}
        >
            <AnimatePresence mode="wait" initial={false}>
                <motion.div
                    key={`isOpen_${isOpen}`}
                    initial={{
                        opacity: 0,
                        x: -5,
                    }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 5 }}
                    transition={{ type: "tween", duration: 0.2 }}
                    onAnimationStart={(definition: {
                        opacity: 0 | 1;
                        x: 0 | 5;
                    }) => {
                        const input = inputRef.current;

                        if (
                            definition.opacity === 1 &&
                            definition.x === 0 &&
                            input
                        ) {
                            input.focus();
                        }
                    }}
                >
                    {isOpen ? (
                        <div className={clsx(styles.field)}>
                            <motion.label
                                className={styles.label}
                                initial={
                                    isSuccess
                                        ? "success"
                                        : isFilled
                                        ? "filled"
                                        : "active"
                                }
                                animate={
                                    isSuccess
                                        ? "success"
                                        : error
                                        ? "error"
                                        : isFilled
                                        ? "filled"
                                        : isFocused
                                        ? "active"
                                        : "static"
                                }
                                variants={labelVariants}
                                transition={{
                                    type: "tween",
                                    duration: 0.3,
                                }}
                            >
                                {label}
                            </motion.label>
                            <motion.input
                                ref={inputRef}
                                className={clsx(styles.input)}
                                type="email"
                                value={data.value}
                                autoComplete="off"
                                variants={inputVariants}
                                initial={
                                    isSuccess
                                        ? "success"
                                        : isFilled
                                        ? "filled"
                                        : "static"
                                }
                                animate={
                                    isSuccess
                                        ? "success"
                                        : error
                                        ? "error"
                                        : isFilled
                                        ? "filled"
                                        : "static"
                                }
                                transition={{
                                    type: "tween",
                                    duration: 0.3,
                                }}
                                disabled={mutation.isLoading || isSuccess}
                                onKeyPress={handleOnKeyPress}
                                onFocus={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                                onChange={handleOnChange}
                            />
                            <AnimatePresence mode="wait">
                                {isSuccess && (
                                    <motion.div
                                        className={styles.meta}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{
                                            type: "tween",
                                            duration: 0.3,
                                        }}
                                    >
                                        Aktywowano kod rabatowy
                                    </motion.div>
                                )}
                            </AnimatePresence>
                            <motion.button
                                className={styles.submitButton}
                                type="submit"
                                initial="error"
                                animate={
                                    isFilled && !error ? "success" : "error"
                                }
                                variants={buttonVariants}
                                transition={{
                                    type: "tween",
                                    duration: 0.3,
                                }}
                                disabled={mutation.isLoading || isSuccess}
                                onClick={handleSubmit}
                            >
                                <AnimatePresence mode="wait">
                                    <motion.div
                                        key={isSuccess ? "isSuccess" : "isIdle"}
                                        initial={{
                                            y: isSuccess ? -20 : 0,
                                            opacity: isSuccess ? 0 : 1,
                                        }}
                                        animate={{ y: 0, opacity: 1 }}
                                        exit={{ y: 20, opacity: 0 }}
                                        transition={{
                                            type: "tween",
                                            duration: 0.2,
                                        }}
                                    >
                                        {isSuccess ? (
                                            <img
                                                className={styles.submitIcon}
                                                src={checkmarkIcon}
                                                alt=""
                                            />
                                        ) : (
                                            <motion.img
                                                className={styles.submitIcon}
                                                src={plusIcon}
                                                alt=""
                                                initial="error"
                                                animate={
                                                    isFilled && !error
                                                        ? "success"
                                                        : "error"
                                                }
                                                variants={iconVariants}
                                            />
                                        )}
                                    </motion.div>
                                </AnimatePresence>
                            </motion.button>
                            <AnimatePresence mode="wait">
                                {!error && !isSuccess && (
                                    <motion.div
                                        className={styles.helper}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{
                                            type: "tween",
                                            duration: 0.2,
                                        }}
                                    >
                                        Wciśnij + aby dodać kod
                                    </motion.div>
                                )}
                            </AnimatePresence>
                            <AnimatePresence mode="wait">
                                {error && (
                                    <motion.div
                                        className={styles.error}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{
                                            type: "tween",
                                            duration: 0.2,
                                        }}
                                    >
                                        * {error}
                                    </motion.div>
                                )}
                            </AnimatePresence>
                            <AnimatePresence mode="wait">
                                {isSuccess && data.response && (
                                    <motion.div
                                        className={styles.success}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{
                                            type: "tween",
                                            duration: 0.2,
                                        }}
                                    >
                                        <p>
                                            Przydzielono rabat{" "}
                                            {data.response.discount.toString()}%{" "}
                                            {data.response.packages && (
                                                <>
                                                    na abonament{" "}
                                                    {data.response.packages.map(
                                                        (
                                                            packageName,
                                                            index
                                                        ) => (
                                                            <React.Fragment
                                                                key={
                                                                    packageName
                                                                }
                                                            >
                                                                {index !== 0 &&
                                                                    " | "}
                                                                {packageName.toUpperCase()}
                                                            </React.Fragment>
                                                        )
                                                    )}
                                                </>
                                            )}
                                        </p>
                                        <button
                                            className={styles.removeDiscount}
                                            type="button"
                                            onClick={handleRemoveDiscount}
                                        >
                                            anuluj kod
                                        </button>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    ) : (
                        <button
                            className={styles.openButton}
                            type="button"
                            disabled={mutation.isLoading}
                            onClick={handleOpen}
                        >
                            {title}
                        </button>
                    )}
                </motion.div>
            </AnimatePresence>
        </motion.div>
    );
};
