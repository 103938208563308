import { AxiosResponse } from "axios";

import { getRandomInt } from "./getRandomInt";

export const fetchDelay = async (
    callback: Promise<AxiosResponse<any, any>>,
    timeout: {
        min: number;
        max: number;
    }
) => {
    await new Promise((resolve) =>
        setTimeout(resolve, getRandomInt(timeout.min, timeout.max))
    );

    return callback;
};
