import JSEncrypt from "jsencrypt";

const getPublicKey = () =>
    `-----BEGIN PUBLIC KEY-----${window.atob(
        "LS0tLS1CRUdJTiBQVUJMSUMgS0VZLS0tLS0NCk1JR2ZNQTBHQ1NxR1NJYjNEUUVCQVFVQUE0R05BRENCaVFLQmdRQ1BBR3BWVEJkbEpnRlVScmM3RkVpSW9qKzkNCmJqc0lzZ3RhSVhySCs2UnpmTzNCMmVsaHkvSEJnT2ZBVW5tTW50U2JqK0poYW5GbVFwZytyc3U0UVdrUGJxUUwNCm9GZ1M0OWN0Y3Z3VHZyM1J5OHRkQncvR25rZjhqUjhOa0JENUlWV05vRGUwZXhKcUcva01pdUxwbzhyTlVNRTcNClNOUmErT0ViajUyNDVMbzVvd0lEQVFBQg0KLS0tLS1FTkQgUFVCTElDIEtFWS0tLS0t"
    )}-----END PUBLIC KEY-----`;

export const encodeCreditCardData = (data: string) => {
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setKey(getPublicKey());

    return jsEncrypt.encrypt(data);
};
