import {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";
import { useQueryClient } from "@tanstack/react-query";

import { useLogoutUser } from "../modules/auth/hooks";

import { removeAccountCookie, removeTokenCookie } from "../services";

type ProviderProps = { children: JSX.Element };

type ContextType = {
    isUserLogged: boolean;
    setIsUserLogged: React.Dispatch<React.SetStateAction<boolean>>;
    logout: (isMutationDisabled?: boolean) => void;
    isStorePageDisplayed: boolean;
    setIsStorePageDisplyed: Function
};

const AuthContext = createContext<ContextType | null>(null);

export const AuthProvider = ({ children }: ProviderProps) => {
    const queryClient = useQueryClient();

    const logoutUser = useLogoutUser();
    const [isUserLogged, setIsUserLogged] = useState(false);
    const [isStorePageDisplayed, setIsStorePageDisplyed] = useState(true)

    const expoPushToken = window.sessionStorage.getItem("expoPushToken");

    const logout = useCallback(
        (isMutationDisabled?: boolean) => {
            setIsUserLogged(false);

            setTimeout(() => {
                removeAccountCookie();
                removeTokenCookie();

                queryClient.removeQueries();

                if (!isMutationDisabled) {
                    logoutUser.mutate(expoPushToken);
                }
            }, 0);
        },
        [logoutUser, queryClient, expoPushToken]
    );

    const authContextMemoized: ContextType = useMemo(
        () => ({ isUserLogged, setIsUserLogged, logout, isStorePageDisplayed, setIsStorePageDisplyed }),
        [isUserLogged, logout, isStorePageDisplayed]
    );

    return (
        <AuthContext.Provider value={authContextMemoized}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => useContext(AuthContext) as ContextType;
