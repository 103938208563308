import { useEffect, useState, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { UserListItem } from "../UserListItem";
import { InviteInput } from "../InviteInput";

import { UserListProps } from "./UserList.typings";

import styles from "./UserList.module.css";

export const UserList = ({
    heading,
    values,
    childrenEmail,
    mutation,
    input,
    maxLength,
    modalText,
    emptyText,
    hasPermission,
}: UserListProps) => {
    const [inviteValue, setInviteValue] = useState("");
    const [error, setError] = useState("");

    const isValueLast = useRef(false);
    const valuesLengthLast = useRef(values.length);

    useEffect(() => {
        if (
            values.length <= 1 ||
            (values.length <= 1 && values.length < valuesLengthLast.current)
        ) {
            isValueLast.current = true;
        } else {
            isValueLast.current = false;
        }

        valuesLengthLast.current = values.length;
    }, [values]);

    useEffect(() => {
        if (
            mutation.isError &&
            mutation.error &&
            mutation.error.response.data &&
            !error
        ) {
            const messages = mutation.error.response.data.messages;
            const messageKey = Object.keys(messages)[0];

            setError(messages[messageKey]);

            mutation.reset();
        }
    }, [mutation, error]);

    useEffect(() => {
        if (maxLength && maxLength.count <= values.length) {
            setInviteValue("");
        }
    }, [maxLength, values, inviteValue]);

    return (
        <div className={styles.container}>
            {heading && <h4 className={styles.title}>{heading}</h4>}
            <div className={styles.items}>
                <AnimatePresence
                    mode={isValueLast.current ? "wait" : "sync"}
                    initial={false}
                >
                    {values.length > 0 ? (
                        values.map((value) => (
                            <motion.div
                                key={value.user_id ?? value.email}
                                initial={{ opacity: 0, scale: 0.9 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.9 }}
                                transition={{ type: "tween", duration: 0.2 }}
                            >
                                <UserListItem
                                    email={value.email}
                                    childrenEmail={childrenEmail}
                                    mutation={mutation}
                                    modalText={modalText}
                                    isInvited={value.invited_by === value.email}
                                    isUnconfirmed={
                                        value.status === "Zaproszony"
                                    }
                                    isRemoveDisabled={!hasPermission}
                                >
                                    {value.first_name && value.last_name ? (
                                        <>
                                            {value.first_name} {value.last_name}
                                            <br />
                                            {value.email}
                                        </>
                                    ) : (
                                        value.email
                                    )}
                                </UserListItem>
                            </motion.div>
                        ))
                    ) : (
                        <motion.div
                            key="user_empty"
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.9 }}
                            transition={{ type: "tween", duration: 0.2 }}
                            className={styles.empty}
                        >
                            <span>{emptyText ?? "Brak listy"}</span>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            <AnimatePresence mode="wait">
                {!!error && (
                    <motion.div
                        className={styles.error}
                        initial={{ height: 0, opacity: 0, marginTop: 0 }}
                        animate={{ height: "auto", opacity: 1, marginTop: 15 }}
                        exit={{ height: 0, opacity: 0, marginTop: 0 }}
                        onAnimationComplete={(definition: {
                            height: string | number;
                        }) => {
                            if (definition.height === "auto") {
                                setTimeout(() => {
                                    setError("");
                                }, 2000);
                            }
                        }}
                        transition={{ type: "tween", duration: 0.3 }}
                    >
                        <div className={styles.errorBody}>
                            <span>{error}</span>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
            {input &&
                (maxLength ? (
                    maxLength.count > values.length ? (
                        <InviteInput
                            className={styles.input}
                            {...input}
                            value={inviteValue}
                            mutation={mutation}
                            onChange={(value) => setInviteValue(value)}
                        />
                    ) : (
                        <div className={styles.max}>
                            <div className={styles.maxTitle}>
                                {maxLength.title}
                            </div>
                        </div>
                    )
                ) : (
                    <InviteInput
                        className={styles.input}
                        {...input}
                        value={inviteValue}
                        mutation={mutation}
                        onChange={(value) => setInviteValue(value)}
                    />
                ))}
        </div>
    );
};
