import { mainApi } from "../../../../api";

import { LearningProgressAccountType } from "../../typings";

const key = "learningProgressSubategory";
const url = "user_subcategory_learning_progress";

export const learningProgressSubcategoryQuery = (
    type: LearningProgressAccountType,
    classType?: string,
    subcategoryId?: string,
    childrenId?: string
) => ({
    queryKey:
        type === "student"
            ? classType && subcategoryId
                ? [key, classType, subcategoryId]
                : [key]
            : classType && subcategoryId && childrenId
            ? [key, classType, subcategoryId, childrenId]
            : [key],
    queryFn: async () =>
        mainApi.post(type === "student" ? url : `${url}/${childrenId}`, {
            class: classType,
            subcategory_id: subcategoryId,
        }),
});
