import { DottedLoader } from "../../../../components/loaders";

import styles from "./RedirectLoading.module.css";

export const RedirectLoading = () => (
    <div className={styles.container}>
        <div>Trwa wczytywanie planowania nauki...</div>
        <DottedLoader />
    </div>
);
