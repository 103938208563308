import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { taskTheoryQuery } from "../../utils";

import { TaskTheoryResponse } from "../../typings";

export const useTaskTheory = (id: string, userClass: string) => {
    return useQuery<AxiosResponse<TaskTheoryResponse, any>>(
        taskTheoryQuery(id, userClass)
    );
};
