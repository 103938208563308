import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

import { ShieldIcon } from "../../../../components/headings/DashboardHeading/icons";

import {
    collapseAnimation,
    getArrowAnimation,
} from "./ShieldAccordion.animations";
import { ShieldAccordionProps } from "./ShieldAccordion.typings";

import styles from "./ShieldAccordion.module.css";

import { ReactComponent as ArrowIcon } from "./images/arrow-icon.svg";

export const ShieldAccordion = <T extends string | undefined>({
    title,
    badge,
    icon,
    children,
}: ShieldAccordionProps<T>) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={clsx(styles.container, isOpen && styles.open)}>
            <button
                className={styles.button}
                type="button"
                onClick={() => setIsOpen((open) => !open)}
            >
                <span className={styles.icon}>
                    <ShieldIcon />
                    {(icon || badge) && (
                        <span className={styles.badge}>
                            {icon ? <img src={icon} alt="" /> : badge}
                        </span>
                    )}
                </span>
                {title}
                <motion.span
                    className={styles.arrow}
                    {...getArrowAnimation(isOpen)}
                >
                    <ArrowIcon />
                </motion.span>
            </button>
            <AnimatePresence mode="wait">
                {isOpen && (
                    <motion.div
                        className={styles.collapse}
                        {...collapseAnimation}
                    >
                        <div className={styles.body}>{children}</div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};
