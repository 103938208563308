import { Navigate } from "react-router-dom";
import clsx from "clsx";

import { useAuthContext } from "../../../../../contexts/AuthContext";

import { getAccountCookie } from "../../../../../services";

import { useMedia } from "../../../../../hooks";

import { AuthContainer } from "../../../../../components/containers";
import { MainHeading } from "../../../../../components/headings";
import { AuthText } from "../../../../../components/texts";
import { DefaultButton } from "../../../../../components/buttons";

import styles from "./DashboardDisabled.module.css";

import pi from "../../../../../assets/images/robots/pi-tears.svg";

export const DashboardDisabled = () => {
    const { accountType, isAccountDisabled } = getAccountCookie();

    const { logout } = useAuthContext();

    const { isDesktop } = useMedia();

    if (!isAccountDisabled) {
        return <Navigate to={`/dashboard/${accountType}`} replace={true} />;
    }

    return (
        <>
            <AuthContainer
                position="center"
                image={pi}
                imageSize={isDesktop ? 285 : 214}
                imagePosition="right"
                imageOffsetX={isDesktop ? 78 : undefined}
                imageOffsetY={isDesktop ? 15.5556 : undefined}
            >
                <MainHeading wrapper={styles.heading} elementType="h2">
                    Konto zostało dezaktywowane
                </MainHeading>
                <AuthText>
                    <p>
                        Twoje konto zostało dezaktywowane
                        <br />
                        przez administratora.
                        <br />
                    </p>
                    <p>
                        Skontaktuj się z administratorem w celu odblokowania
                        konta -{" "}
                        <strong>
                            <a href="mailto:pomoc@smarttutor.pl">
                                pomoc@smarttutor.pl
                            </a>
                        </strong>
                    </p>
                </AuthText>

                <DefaultButton
                    wrapper={clsx(styles.button)}
                    whileHover={{ opacity: 0.7 }}
                    onClick={() => logout()}
                >
                    WYLOGUJ
                </DefaultButton>
            </AuthContainer>
        </>
    );
};
