import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import {
    Navigate,
    Outlet,
    useNavigation,
    useOutlet,
    useOutletContext,
} from "react-router-dom";
import { deromanize, romanize } from "romans";

import { getAccountCookie, setAccountCookie } from "../../../../services";

import { useMedia } from "../../../../hooks";
import { useTasks } from "../../hooks";

import { TaskContainer } from "../../../../components/containers";
import { DashboardHeading } from "../../../../components/headings";
import { ObjectSelectInput, SelectValue } from "../../../../components/inputs";
import { CircleLoader } from "../../../../components/loaders";
import { Checkbox, NavigationBar, TasksList } from "../../components";
import { ExamIcon } from "../../components/icon-components";

import {
    getExamFilter,
    getIsTaskFilterExam,
    getShieldExamName,
    getTaskFilterExamName,
    getTasksFilters,
    setExamFilter,
    taskCategoryQuery,
    taskSubcategoryQuery,
    userClassLabel,
} from "../../utils";

import { TaskFilterExamType } from "../../typings";
import { OutletContextType } from "./Task.typings";

import styles from "./Tasks.module.css";

import infinity from "../../../../assets/images/robots/infinity-watching.svg";

export const Tasks = () => {
    const { userSchool, isSubscriptionPaid } = getAccountCookie();
    const { isDesktop } = useMedia();

    const queryClient = useQueryClient();
    const outlet = useOutlet();
    const { state } = useNavigation();

    const [isExamFilter, setIsExamFilter] = useState(getExamFilter());

    const userClassPrevious = useRef(userSchool.class);
    const [userClass, setUserClass] = useState<SelectValue>({
        // label: getIsTaskFilterExam(userSchool.class)
        //     ? getTaskFilterExamName(userSchool.class as TaskFilterExamType)
        //     : `${userClassLabel} ${romanize(parseInt(userSchool.class))}`,
        // value: userSchool.class,
        label:
            userSchool.name !== "Podstawowa"
                ? "Matura podstawowa"
                : getIsTaskFilterExam(userSchool.class)
                ? getTaskFilterExamName(userSchool.class as TaskFilterExamType)
                : `${userClassLabel} ${romanize(parseInt(userSchool.class))}`,
        value:
            userSchool.name !== "Podstawowa"
                ? "matura_podstawowa"
                : userSchool.class,
    });

    const [isTasksLoading, setIsTasksLoading] = useState(false);
    const { data, refetch, isRefetching, isFetched } = useTasks(
        userClass.value
    );

    const isDataEmpty = !data || data.data.sections.length === 0;
    const isLoadingState = state === "loading";

    const handleUserClassChange = (value: SelectValue) => {
        setUserClass(value);

        queryClient.removeQueries(taskCategoryQuery().queryKey);
        queryClient.removeQueries(taskSubcategoryQuery().queryKey);

        setAccountCookie({
            userSchool: [userSchool.name, value.value],
        });
    };

    useEffect(() => {
        if (userClass && userClassPrevious.current !== userClass.value) {
            refetch();

            setIsTasksLoading(true);

            userClassPrevious.current = userClass.value;
        }
    }, [userClass, refetch]);

    useEffect(() => {
        if (isTasksLoading && !isRefetching) {
            setTimeout(() => {
                setIsTasksLoading(false);
            }, 1000);
        }
    }, [isTasksLoading, isRefetching]);

    if (
        data &&
        data.data.sections.length === 0 &&
        isFetched &&
        !isSubscriptionPaid
    ) {
        return (
            <Navigate
                to="/dashboard/student/restriction"
                state={true}
                replace={true}
            />
        );
    }

    return (
        <TaskContainer
            footerElement={
                !isDesktop ? (
                    <img className={styles.robot} src={infinity} alt="" />
                ) : undefined
            }
        >
            {outlet ? (
                <Outlet
                    context={{
                        userClassState: [userClass, setUserClass],
                        tasksFilterState: [isExamFilter, setIsExamFilter],
                    }}
                />
            ) : (
                <>
                    <DashboardHeading
                        title="BAZA ZADAŃ"
                        userClass={
                            userClass
                                ? getIsTaskFilterExam(userClass.value)
                                    ? getShieldExamName(
                                          userClass.value as TaskFilterExamType
                                      )
                                    : romanize(parseInt(userClass.value))
                                : undefined
                        }
                        hasBorder={true}
                    />
                    <div className={styles.utils}>
                        <div className={styles.select}>
                            <ObjectSelectInput
                                value={userClass}
                                selectValues={getTasksFilters(
                                    userSchool.name,
                                    true
                                ).map((taskFilter) => ({
                                    label: taskFilter.isNumber
                                        ? `${userClassLabel} ${taskFilter.value}`
                                        : getTaskFilterExamName(
                                              taskFilter.value as TaskFilterExamType
                                          ),
                                    value: taskFilter.isNumber
                                        ? deromanize(
                                              taskFilter.value
                                          ).toString()
                                        : taskFilter.value,
                                }))}
                                isLabelCenter={true}
                                isDisabled={isExamFilter}
                                isLoading={isTasksLoading}
                                onChange={handleUserClassChange}
                            />
                        </div>
                        <div className={styles.filter}>
                            <Checkbox
                                icon={<ExamIcon />}
                                isChecked={isExamFilter}
                                isDisabled={!data!.data.has_exam_exercises}
                                isLoading={
                                    isDataEmpty ||
                                    isLoadingState ||
                                    isTasksLoading
                                }
                                onClick={() =>
                                    setIsExamFilter((isFiltered) => {
                                        if (isFiltered) {
                                            setExamFilter(false);

                                            return false;
                                        } else {
                                            setExamFilter(true);

                                            return true;
                                        }
                                    })
                                }
                            >
                                Egzaminacyjne
                            </Checkbox>
                        </div>
                    </div>
                    {data && !isTasksLoading ? (
                        <TasksList
                            tasks={data.data.sections}
                            isFiltered={isExamFilter}
                        />
                    ) : (
                        <div className={styles.loading}>
                            <div className={styles.loader}>
                                <CircleLoader />
                            </div>
                        </div>
                    )}
                    <NavigationBar
                        backward={{
                            children: "WRÓĆ",
                        }}
                    />
                </>
            )}
        </TaskContainer>
    );
};

export const useTasksOptions = () => useOutletContext<OutletContextType>();
