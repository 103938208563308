import clsx from "clsx";

import { DifficultyStatus } from "../DifficultyStatus";
import { ExamBadge } from "../ExamBadge";

import { StatusBarProps } from "./StatusBar.typings";

import styles from "./StatusBar.module.css";

export const StatusBar = <B extends boolean = false>({
    sheets,
    level,
    count,
    isExamFilter,
    isAdvanced,
    isLargerOnDesktop,
}: StatusBarProps<B>) => (
    <div className={clsx(styles.container, isLargerOnDesktop && styles.large)}>
        <div className={styles.wrapper}>
            {isExamFilter && (
                <div className={styles.info}>
                    <ExamBadge isLargerOnDesktop={isLargerOnDesktop} />
                    <div className={styles.text}>Zadanie z egzaminu</div>
                </div>
            )}
            <div className={styles.status}>
                <DifficultyStatus
                    level={level}
                    isAdvanced={isAdvanced}
                    isLargerOnDesktop={isLargerOnDesktop}
                />
            </div>
        </div>
        {sheets.length > 0 && (
            <div
                className={clsx(
                    styles.sheets,
                    isLargerOnDesktop && styles.large
                )}
            >
                Pochodzi z arkusza:{" "}
                {sheets.map((sheet, index) => (
                    <span key={sheet.id}>
                        {sheet.name}
                        {sheets.length > 1 && index !== sheets.length - 1
                            ? ", "
                            : null}
                    </span>
                ))}
                {count.max !== 0 && (
                    <span style={{ marginLeft: 5 }}>
                        ({count.current}/{count.max})
                    </span>
                )}
            </div>
        )}
    </div>
);
