import styles from './AccountDeleteContent.module.css';

type Props ={ 
    children: JSX.Element | JSX.Element[] | null;
}

export const AccountDeleteContent = ({children}:Props) => {
    return <div className={styles.container}>
        {children}
    </div>
}