import { Toast, ToastPosition, toast } from "react-hot-toast";

import styles from "./useNotify.module.css";

const Button = ({ children, t }: { children: string; t: Toast }) => (
    <>
        <button
            className={styles.button}
            type="button"
            onClick={() => toast.dismiss(t.id)}
        ></button>
        {children}
    </>
);

export const useNotify = (options?: {
    type?: "success" | "error";
    position?: ToastPosition;
}) => {
    const props = {
        className: styles.body,
        position: options?.position ?? "bottom-right",
        duration: 5 * 1000,
        style: {
            border: 0,
            padding: options?.type ? "8px 5px 8px 15px" : "8px 5px",
            background:
                options?.type === "success"
                    ? "#15b745"
                    : options?.type === "error"
                    ? "#fe5a5e"
                    : "#2eafbe",
            color: "#ffffff",
            fontSize: "14px",
            lineHeight: "16px",
        },
        iconTheme: {
            primary: "#ffffff",
            secondary:
                options?.type === "success"
                    ? "#15b745"
                    : options?.type === "error"
                    ? "#fe5a5e"
                    : "#2eafbe",
        },
    };

    const notify = (text: string) =>
        options?.type === "success"
            ? toast.success((t) => <Button t={t}>{text}</Button>, props)
            : options?.type === "error"
            ? toast.error((t) => <Button t={t}>{text}</Button>, props)
            : toast((t) => <Button t={t}>{text}</Button>, props);
    return notify;
};
