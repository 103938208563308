import * as yup from "yup";

export const editStudentAccountSchema = yup.object({
    email: yup
        .string()
        .email("* nieprawidłowy adres e-mail")
        .required("* pole wymagane"),
    birthday: yup.string().required("* data urodzenia jest wymagana"),
    school: yup.string().required(" "),
    class: yup.string().required(" "),
    city: yup.object({
        id: yup.string().required(" "),
        name: yup.string().required(" "),
    }),
    first_name: yup.string().min(2, " ").required(" "),
    last_name: yup.string().min(2, " ").required(" "),
    agreement: yup
        .boolean()
        .oneOf([true], "* wyrażenie zgody jest niezbędne")
        .required(" "),
});
