import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { userImportantNotificationQuery } from "../../utils";

import { ImportantNotificationResponse } from "../../typings";

export const useUserImportantNotification = () => {
    return useQuery<AxiosResponse<ImportantNotificationResponse, any>>(
        userImportantNotificationQuery()
    );
};
