import Cookies from "js-cookie";

import { taskSlug } from "../utils";

import { TaskType } from "../typings";

type BaseReturn = {
    type: typeof taskSlug.default;
    taskId: string;
};

type RandomReturn = {
    type: typeof taskSlug.random;
    taskId: string;
};
type OthersReturn = {
    type: Exclude<TaskType, typeof taskSlug.default | typeof taskSlug.random>;
    taskId: string;
    additionalId: string;
};

type TaskParamsReturn = BaseReturn | RandomReturn | OthersReturn | undefined;

const key = "taskParams";

const setTaskParams = <T extends TaskType>(
    params: T extends typeof taskSlug.default | typeof taskSlug.random
        ? {
              type: typeof taskSlug.default | typeof taskSlug.random;
              taskId: string;
              additionalId?: never;
          }
        : { type: T; taskId: string; additionalId: string }
) => {
    const type = params.type;
    const taskId = params.taskId;

    if (type === taskSlug.default || type === taskSlug.random) {
        Cookies.set(key, `${type},${taskId}`, {
            sameSite: "strict",
            expires: 30,
        });
    } else {
        const additionalId = params.additionalId;

        Cookies.set(key, `${type},${taskId},${additionalId}`, {
            sameSite: "strict",
            expires: 30,
        });
    }
};

const getTaskParams = (): TaskParamsReturn => {
    const params = Cookies.get(key);

    if (params) {
        const type = params.split(",")[0] as TaskType;
        const taskId = params.split(",")[1];

        if (type === taskSlug.default || type === taskSlug.random) {
            return {
                type,
                taskId,
            };
        } else {
            const additionalId = params.split(",")[2];

            return {
                type,
                taskId,
                additionalId,
            };
        }
    } else {
        return undefined;
    }
};

const removeTaskParams = () => Cookies.remove(key, { sameSite: "strict" });

export { setTaskParams, getTaskParams, removeTaskParams };
