import styles from "./ProgressDefault.module.css";

type Props = {
    title?: string;
    progress: number;
};

export const ProgressDefault = ({ title, progress }: Props) => {
    return (
        <div className={styles.container}>
            <div className={styles.counter}>
                {title && <span className={styles.title}>{title}: </span>}
                {progress.toString()}%
            </div>
            <div className={styles.progress}>
                <div
                    className={styles.bar}
                    style={{ width: `${progress}%` }}
                ></div>
            </div>
        </div>
    );
};
