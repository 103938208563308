import { useLocation, useNavigate } from "react-router-dom";

import { useMedia } from "../../../../../hooks";

import { getAccountCookie } from "../../../../../services";

import { AuthContainer } from "../../../../../components/containers";
import { MainHeading } from "../../../../../components/headings";
import { AuthText } from "../../../../../components/texts";
import { ArrowButton, DefaultButton } from "../../../../../components/buttons";

import styles from "./PaymentSuccess.module.css";

import pi from "../../../../../assets/images/robots/pi-success.svg";

type LocationType = {
    state: boolean | null;
};

export const PaymentSuccess = () => {
    const { isDesktop } = useMedia();
    const { accountType } = getAccountCookie();

    const navigate = useNavigate();
    const { state }: LocationType = useLocation();

    const forwardPathname = `/dashboard/${accountType}`;

    return (
        <>
            <ArrowButton to={forwardPathname} wrapper={styles.back}>
                Wstecz
            </ArrowButton>
            <AuthContainer
                position="center"
                image={pi}
                imageSize={isDesktop ? 284 : 214}
                imagePosition="right"
                imageOffsetX={isDesktop ? 41 : undefined}
                imageOffsetY={isDesktop ? 15.5556 : undefined}
            >
                <MainHeading wrapper={styles.heading} elementType="h2">
                    {state
                        ? "Subskrypcja aktywowana"
                        : "Przetwarzanie płatności"}
                </MainHeading>
                <AuthText>
                    {state ? (
                        <p>
                            Masz już dostęp do wybranych
                            <br />
                            przez Ciebie funkcji <strong>Smart Tutor</strong>!
                        </p>
                    ) : (
                        <>
                            <p>Trwa przetwarzanie płatności.</p>
                            <p>
                                Za chwilę Twoja subskrypcja zostanie aktywowana.
                            </p>
                        </>
                    )}
                </AuthText>
                <DefaultButton
                    wrapper={styles.forwardButton}
                    whileHover={{ opacity: 0.7 }}
                    onClick={() => navigate(forwardPathname)}
                >
                    STRONA GŁÓWNA
                </DefaultButton>
            </AuthContainer>
        </>
    );
};
