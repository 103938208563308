import { useState, useRef, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

import { labelVariants } from "./StudentSearch.animations";

import styles from "./StudentSearch.module.css";

type Props = {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
};

export const StudentSearch = ({ value, setValue }: Props) => {
    const isInit = useRef(false);
    const [isFocused, setIsFocused] = useState(false);
    const isFilled = value.length > 0 ? true : false;

    useEffect(() => {
        isInit.current = true;
    }, []);

    return (
        <div className={styles.field}>
            <div className={styles.fieldRow}>
                <motion.label
                    className={styles.label}
                    animate={isFocused || isFilled ? "active" : "static"}
                    initial="static"
                    variants={labelVariants}
                    transition={{ type: "tween", duration: 0.3 }}
                >
                    Wyszukaj ucznia
                </motion.label>
                <input
                    className={styles.input}
                    name="childrenSearch"
                    value={value}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChange={(event) => setValue(event.currentTarget.value)}
                />
                <motion.button
                    className={styles.button}
                    variants={{
                        active: { background: "#fe5a5e" },
                        inactive: { background: "#2eafbe" },
                    }}
                    initial="inactive"
                    animate={isFilled ? "active" : "inactive"}
                    type="button"
                    disabled={isFilled ? false : true}
                    onClick={() => setValue("")}
                >
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={`filled_${isFilled}`}
                            initial={
                                isInit.current
                                    ? { y: -16, opacity: 0 }
                                    : { y: 0, opacity: 1 }
                            }
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: 16, opacity: 0 }}
                            transition={{
                                type: "tween",
                                duration: 0.2,
                            }}
                        >
                            {isFilled ? <ClearIcon /> : <SearchIcon />}
                        </motion.div>
                    </AnimatePresence>
                </motion.button>
            </div>
        </div>
    );
};

const SearchIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20.996"
            height="21"
            viewBox="0 0 20.996 21"
        >
            <path
                id="Icon_awesome-search"
                data-name="Icon awesome-search"
                d="M20.711,18.156l-4.089-4.089a.984.984,0,0,0-.7-.287h-.668a8.527,8.527,0,1,0-1.476,1.476v.668a.984.984,0,0,0,.287.7l4.089,4.089a.98.98,0,0,0,1.39,0l1.161-1.161a.989.989,0,0,0,0-1.394ZM8.53,13.78A5.249,5.249,0,1,1,13.78,8.53,5.246,5.246,0,0,1,8.53,13.78Z"
                fill="#fff"
            />
        </svg>
    );
};

const ClearIcon = () => {
    return (
        <span className={styles.icon}>
            <span className={styles.bar}></span>
            <span className={styles.bar}></span>
        </span>
    );
};
