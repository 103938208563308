import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { userDataQuery } from "../../utils";

import { SchoolType } from "../../../../typings";

type Response = {
    status: number;
    data: {
        birthday: "string";
        city: {
            id: string;
            NAZWA: string;
            GMI: string;
        };
        class: string;
        email: "string";
        first_name: string;
        last_name: string;
        school: SchoolType;
    };
};

export const useUserData = () => {
    return useQuery<AxiosResponse<Response, any>>(userDataQuery());
};
