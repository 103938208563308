import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { getIcon } from "./utils";

import { buttonVariants } from "./NavbarButton.animations";
import { ButtonInteriorProps, NavbarButtonProps } from "./NavbarButton.typings";

import styles from "./NavbarButton.module.css";

export const NavbarButton = ({
    children,
    icon,
    to,
    isReversed,
    isHidden,
    isDisabled,
    onClick,
}: NavbarButtonProps) => {
    return (
        <motion.div
            variants={buttonVariants}
            initial={isHidden ? "hidden" : "visible"}
            animate={isHidden ? "hidden" : "visible"}
            transition={{
                type: "tween",
                duration: 0.25,
            }}
        >
            {to ? (
                <Link
                    className={clsx(
                        styles.button,
                        isReversed && styles.reversed,
                        isDisabled && styles.disabled
                    )}
                    to={to}
                >
                    <ButtonInterior icon={icon} text={children} />
                </Link>
            ) : (
                <button
                    className={clsx(
                        styles.button,
                        isReversed && styles.reversed,
                        isDisabled && styles.disabled
                    )}
                    type="button"
                    onClick={onClick}
                >
                    <ButtonInterior icon={icon} text={children} />
                </button>
            )}
        </motion.div>
    );
};

const ButtonInterior = ({ icon, text }: ButtonInteriorProps) => {
    return (
        <span className={styles.interior}>
            <span className={styles.aside}>
                <span className={styles.icon}>
                    <img src={getIcon(icon)} alt={text} />
                </span>
            </span>
            <span className={styles.text}>{text}</span>
        </span>
    );
};
