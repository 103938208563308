import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import { SlimButtonProps } from "./SlimButton.typings";

import styles from "./SlimButton.module.css";
import { ButtonLoader } from "../../utils";

export const SlimButton = <B extends boolean = false>({
    children,
    color,
    icon,
    to,
    isLink,
    isDisabled,
    isLoading,
    onClick,
}: SlimButtonProps<B>) => {
    const navigate = useNavigate();

    const handleOnClick = () => {
        if (isLink && to) {
            navigate(to);
        } else if (onClick) {
            onClick();
        }
    };

    return (
        <button
            className={clsx(
                styles.button,
                isLink && styles.link,
                color === "alternative" && styles.alternative,
                color === "important" && styles.important
            )}
            type="button"
            disabled={isDisabled}
            onClick={handleOnClick}
        >
            {icon && (
                <span className={styles.icon}>
                    {typeof icon === "string" ? (
                        <img src={icon} alt="" />
                    ) : (
                        icon
                    )}
                </span>
            )}
            {children && (
                <span className={styles.text}>
                    <ButtonLoader condition={isLoading ?? false} color="white">
                        {children}
                    </ButtonLoader>
                </span>
            )}
        </button>
    );
};
