const styles = {
    color: {
        default: "#FFFFFF",
        success: "#2EAFBE",
        error: "#FE5A5E",
        disabled: "#E5E7EA",
        gray: "#879195",
    },
    radius: {
        closed: "23px 23px 23px 23px",
        open: "23px 23px 0px 0px",
    },
};

const labelClosed = {
    y: 0,
    fontSize: "16px",
    lineHeight: "18px",
};

const labelOpen = {
    y: -31,
    fontSize: "13px",
    lineHeight: "15px",
};

const labelVariants = {
    closed: {
        ...labelClosed,
        color: styles.color.gray,
    },
    open: { ...labelOpen },
    error: { ...labelOpen, color: styles.color.error },
    error_closed: { ...labelClosed, color: styles.color.error },
    success: { ...labelOpen, color: styles.color.success },
    disabled: { ...labelOpen, color: styles.color.gray },
};

const labelCenterVariants = {
    default: {
        color: styles.color.gray
    },
    error: {
        color: styles.color.error
    }

}

const inputVariants = {
    closed: {
        background: styles.color.default,
        borderColor: styles.color.default,
        borderRadius: styles.radius.closed,
    },
    open: {
        background: styles.color.default,
        borderColor: styles.color.success,
        borderRadius: styles.radius.open,
    },
    success: {
        background: styles.color.default,
        borderColor: styles.color.success,
        borderRadius: styles.radius.closed,
    },
    error: {
        background: styles.color.default,
        borderColor: styles.color.error,
        borderRadius: styles.radius.closed,
    },
    error_disabled: {
        background: styles.color.disabled,
        borderColor: styles.color.error,
        borderRadius: styles.radius.closed,
    },
    disabled: {
        background: styles.color.disabled,
        borderColor: styles.color.disabled,
        borderRadius: styles.radius.closed,
    },
};

const iconVariants = {
    closed: {
        rotate: 0,
    },
    open: {
        rotate: 180,
    },
};

const dropdownVariants = {
    closed: {
        height: 0,
        borderColor: styles.color.default,
        opacity: 0,
        scale: 0,
        originY: 0,
    },
    open: {
        height: "auto",
        borderColor: styles.color.success,
        opacity: 1,
        scale: 1,
        originy: 0,
    },
};

const menuVariants = {
    closed: {
        transition: { staggerChildren: 0, delayChildren: 0 },
    },
    open: {
        transition: { staggerChildren: 0.03, delayChildren: 0.06 },
    },
};

const selectVariants = {
    closed: {
        opacity: 0,
    },
    open: {
        opacity: 1,
    },
};

export {
    labelVariants,
    labelCenterVariants,
    inputVariants,
    iconVariants,
    dropdownVariants,
    menuVariants,
    selectVariants,
};
