import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

type Response = any;

export const useReadUserAllNotifications = (
    queryKeys: (string | number)[][]
) => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse<Response, any>, unknown, void, void>(
        () => mainApi.post("set_all_notification_as_read"),
        {
            onSuccess: () => {
                queryKeys.forEach((queryKey) => {
                    queryClient.invalidateQueries(queryKey);
                });
            },
        }
    );
};
