import { AnimatePresence, motion } from "framer-motion";

import { collapseVariants, indicatorVariants } from "./Collapse.animations";

import { CollapseProps } from "./Collapse.typings";

import styles from "./Collapse.module.css";

import arrowIcon from "./images/arrow-icon.svg";

export const Collapse = ({
    title,
    icon,
    children,
    isActive,
    isDisabled,
    onClick,
}: CollapseProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <button
                    className={styles.button}
                    type="button"
                    disabled={isDisabled}
                    onClick={onClick}
                >
                    <span className={styles.icon}>
                        <img src={icon} alt="" />
                    </span>
                    <span className={styles.title}>{title}</span>
                    <motion.span
                        className={styles.indicator}
                        initial="closed"
                        animate={isActive ? "open" : "closed"}
                        variants={indicatorVariants}
                        transition={{
                            type: "tween",
                            duration: 0.3,
                        }}
                    >
                        <img src={arrowIcon} alt="" />
                    </motion.span>
                </button>
            </div>
            <AnimatePresence mode="wait">
                {!isActive && (
                    <motion.div
                        className={styles.collapse}
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={collapseVariants}
                        transition={{
                            type: "tween",
                            duration: 0.3,
                        }}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};
