import { useNavigation } from "react-router-dom";

import { TasksCategoryListItem } from "../TasksCategoryListItem";

import { TaskCategoryResponse } from "../../typings";

import styles from "./TasksCategoryList.module.css";

type Props = {
    tasks: TaskCategoryResponse["subsections"];
    isFiltered?: boolean;
};

export const TasksCategoryList = ({ tasks, isFiltered }: Props) => {
    const { state } = useNavigation();
    const isLoadingState = state === "loading";

    return (
        <div className={styles.container}>
            {tasks.length > 0 ? (
                tasks.map((task) => {
                    const taskProps = {
                        title: task.name,
                        to: task.id,
                        progress: [
                            task.premium_exercises_done,
                            task.premium_exercises_total,
                        ] as [number, number],
                        isTheoryDisabled:
                            (!isFiltered && !task.has_standard_theory) ||
                            (isFiltered && !task.has_exam_theory),
                        isEqual: true,
                        isLoading: isLoadingState,
                    };

                    return isFiltered ? (
                        task.has_exam_exercise ? (
                            <div className={styles.column} key={task.id}>
                                <TasksCategoryListItem {...taskProps} />
                            </div>
                        ) : null
                    ) : (
                        <div className={styles.column} key={task.id}>
                            <TasksCategoryListItem {...taskProps} />
                        </div>
                    );
                })
            ) : (
                <div className={styles.column}>
                    <div className={styles.empty}>BRAK KATEGORII</div>
                </div>
            )}
        </div>
    );
};
