import React, { forwardRef } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { ConditionalWrapper } from "../../wrappers";

import { DefaultButtonProps } from "./DefaultButton.typings";

import styles from "./DefaultButton.module.css";

const colors = {
    disabled: "#879195",
    enabled: "#2AC3BB",
    error: "#FE5A5E",
    white: "#FFFFFF",
};

const Button = forwardRef(
    (
        forwardedProps: DefaultButtonProps,
        ref: React.ForwardedRef<HTMLButtonElement & HTMLAnchorElement>
    ) => {
        const {
            className,
            wrapper,
            wrapperType,
            to,
            state,
            children,
            isSubmit,
            isDisabled,
            onClick,
        } = forwardedProps;

        return (
            <ConditionalWrapper wrapper={wrapper} wrapperType={wrapperType}>
                {to && !isSubmit && !onClick ? (
                    <Link
                        ref={ref}
                        className={clsx(
                            styles.button,
                            className,
                            isDisabled && styles.disabled
                        )}
                        to={to}
                        state={state}
                    >
                        {children}
                    </Link>
                ) : (
                    <button
                        ref={ref}
                        className={clsx(
                            styles.button,
                            className,
                            isDisabled && styles.disabled
                        )}
                        type={isSubmit ? "submit" : "button"}
                        disabled={isDisabled}
                        onClick={onClick ? () => onClick() : undefined}
                    >
                        {children}
                    </button>
                )}
            </ConditionalWrapper>
        );
    }
);

export const DefaultButton = Object.assign(motion(Button), { color: colors });
