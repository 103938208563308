import { useState } from "react";
import clsx from "clsx";

import { DottedLoader } from "../../../../components/loaders";

import { NotificationButtonProps } from "./NotificationButton.typings";

import styles from "./NotificationButton.module.css";

export const NotificationButton = ({
    className,
    children,
    icon,
    size,
    isDisabled,
    isLoading,
    isRedirecting,
    onClick,
}: NotificationButtonProps) => {
    const [isClicked, setIsClicked] = useState(false);

    const handleOnClick = () => {
        setIsClicked(true);

        if (onClick) {
            onClick();
        }
    };

    return (
        <button
            className={clsx(styles.container, className)}
            type="button"
            style={{ width: size ? `${size}px` : undefined }}
            disabled={isDisabled || isLoading || isRedirecting}
            onClick={handleOnClick}
        >
            {(isLoading && isClicked) || (isRedirecting && isClicked) ? (
                <span className={styles.loading}>
                    <DottedLoader />
                </span>
            ) : (
                <>
                    {icon && <span className={styles.icon}>{icon}</span>}
                    {children}
                </>
            )}
        </button>
    );
};
