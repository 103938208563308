import { useState } from "react";
import { Link, useNavigation } from "react-router-dom";

import { DifficultyStatus } from "../../../tasks/components";
import { CircleLoader } from "../../../../components/loaders";

import { DifficultyLevels } from "../../../tasks/typings";

import styles from "./ProgressCategoryButton.module.css";
import { useMedia } from "../../../../hooks";

type Props = {
    children: string;
    to: string;
    level: DifficultyLevels["advanced"];
};

export const ProgressCategoryButton = ({ children, to, level }: Props) => {
    const { isDesktop } = useMedia();
    const { state } = useNavigation();

    const [isClicked, setIsClicked] = useState(false);

    return (
        <div className={styles.container}>
            <Link
                className={styles.button}
                to={to}
                onClick={() => setIsClicked(true)}
            >
                <span className={styles.text}>
                    {children}
                    {isClicked && state === "loading" && (
                        <span className={styles.loading}>
                            <CircleLoader
                                color={!isDesktop ? "white" : undefined}
                                size="24"
                            />
                        </span>
                    )}
                </span>
                <span className={styles.difficulty}>
                    <DifficultyStatus
                        level={level}
                        isAdvanced={true}
                        isHorizontal={true}
                        isLargerOnDesktop={true}
                    />
                </span>
            </Link>
        </div>
    );
};
