import { useParams } from "react-router-dom";

import { getAccountCookie } from "../../../../services";
import { TaskAccordion } from "../../components";

import { useTaskTheory } from "../../hooks";

import styles from "./TaskTheory.module.css";

export const TaskTheory = () => {
    const { userSchool } = getAccountCookie();

    const params = useParams();

    const taskId = params.taskId as string;

    const { data } = useTaskTheory(taskId, userSchool.class);

    return (
        <div className={styles.container}>
            <TaskAccordion content={data!.data.theories} />
        </div>
    );
};
