import { removeLastSlashes } from "./removeLastSlashes";

const removeLastPath = (string: string) => {
    const lastSlash = string.lastIndexOf("/");

    return string.slice(0, lastSlash);
};

export const getPreviousPath = (pathname: string, count?: number) => {
    if (count && count > 1) {
        let newPathname = removeLastSlashes(pathname);

        for (let i = 0; i < count; i++) {
            newPathname = removeLastPath(newPathname);
        }

        return newPathname.length > 0 ? newPathname : '/';
    } else {
        return removeLastPath(removeLastSlashes(pathname));
    }
};
