import { SubscriptionPackage } from "../SubscriptionPackage";

import { SubscriptionPackagesProps } from "./SubscriptionPackages.typings";

import styles from "./SubscriptionPackages.module.css";

export const SubscriptionPackages = ({
    title,
    subscriptions,
}: SubscriptionPackagesProps) => {
    return (
        <>
            {title && <h5 className={styles.title}>ABONAMENT</h5>}
            {subscriptions.map((subscription, index) => (
                <SubscriptionPackage
                    key={subscription.subscription}
                    active={
                        subscription.active_to !== "nieaktywny" ? (
                            subscription.subscription ===
                            "Rozwiązanie na zawołanie" ? (
                                <>
                                    {subscription.solutions_left} zadań
                                    <br />
                                    do {subscription.active_to}
                                </>
                            ) : (
                                subscription.active_to
                            )
                        ) : undefined
                    }
                    isBorderless={!title && index === 0}
                >
                    {subscription.subscription}
                </SubscriptionPackage>
            ))}
        </>
    );
};
