import { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";

import {
    getRemindPasswordCookie,
    removeRemindPasswordCookie,
} from "../../../../services";

import { useLoadingContext } from "../../../../contexts/LoadingContext";

import { useCodeResend, useRemindPasswordConfirm } from "../../hooks";
import { useMedia, useSubmitForm } from "../../../../hooks";

import { ProtectedRoute } from "../../../../components/utils";
import { AuthContainer } from "../../../../components/containers";
import { ArrowButton, DefaultButton } from "../../../../components/buttons";
import { MainHeading } from "../../../../components/headings";
import { ActionText, ErrorText } from "../../../../components/texts";
import { DefaultInput } from "../../../../components/inputs";

import { confirmationSchema } from "../../utils";

import styles from "./RemindConfirmation.module.css";

import pi from "../../../../assets/images/robots/pi-loading.svg";
import pi2 from "../../../../assets/images/robots/pi-error.svg";

const AnimatedForm = motion(Form);

type Props = {
    mutation: ReturnType<typeof useRemindPasswordConfirm>;
};

export const RemindPasswordConfirmation = ({ mutation }: Props) => {
    const remindPasswordCookie = getRemindPasswordCookie();

    const submitForm = useSubmitForm();
    const navigate = useNavigate();
    const { isDesktop } = useMedia();
    const { setLoadingActive } = useLoadingContext();
    const codeResend = useCodeResend();

    const isFormSubmitted = useRef(false);
    const [errorResponse, setErrorResponse] = useState<[boolean, string]>([
        false,
        "",
    ]);

    useEffect(() => {
        if (mutation.error && isFormSubmitted.current) {
            isFormSubmitted.current = false;

            setErrorResponse([
                true,
                mutation.error.response.data.messages.error,
            ]);

            setLoadingActive(false);
        }
    }, [mutation.error, setLoadingActive]);

    useEffect(() => {
        if (mutation.isSuccess && remindPasswordCookie) {
            setLoadingActive(false, () => {
                removeRemindPasswordCookie();

                navigate("/reset-password");

                mutation.reset();
            });
        }
    }, [mutation, remindPasswordCookie, setLoadingActive, navigate]);

    const handleBack = () => {
        removeRemindPasswordCookie();

        navigate("/remind-password");
    };

    const handleCodeResend = () => {
        codeResend.mutate(remindPasswordCookie!);

        navigate("/remind-password", {
            state: {
                isConfirmed: true,
            },
        });
    };

    if (!mutation.isSuccess && !remindPasswordCookie) {
        return <Navigate to="/remind-password" replace={true} />;
    }

    return (
        <ProtectedRoute redirectWhenLoggedIn={true} redirectPath="/dashboard">
            <ArrowButton wrapper={styles.back} onClick={handleBack}>
                Wstecz
            </ArrowButton>
            <AuthContainer
                position="center"
                image={errorResponse[0] ? pi2 : pi}
                imageSize={isDesktop ? 285 : 214}
                imagePosition="right"
                imageOffsetX={isDesktop ? 78 : undefined}
                imageOffsetY={isDesktop ? 15.5556 : undefined}
            >
                <MainHeading wrapper={styles.heading} elementType="h2">
                    Zresetuj swoje hasło
                </MainHeading>
                <Formik
                    initialValues={{
                        email: remindPasswordCookie,
                        code: "",
                    }}
                    validationSchema={confirmationSchema}
                    onSubmit={(data, actions) => {
                        isFormSubmitted.current = true;

                        setLoadingActive(true, () => {
                            submitForm(data);

                            actions.setSubmitting(false);
                        });
                    }}
                >
                    {(formik) => (
                        <AnimatedForm
                            initial={{
                                paddingTop: "15px",
                            }}
                            animate={{
                                paddingTop: errorResponse[1] ? "40px" : "15px",
                            }}
                            transition={{ type: "tween", duration: 0.3 }}
                        >
                            <ErrorText isError={!!errorResponse[1]}>
                                {errorResponse[1]}
                            </ErrorText>
                            <DefaultInput
                                label="E-mail"
                                name="email"
                                type="email"
                                isDisabled={true}
                            />
                            <DefaultInput
                                label="Kod potwierdzający"
                                name="code"
                                type="text"
                                maxLength={6}
                                isNumeric={true}
                                isMasked={true}
                                isError={!!errorResponse[1]}
                                onChange={() =>
                                    setErrorResponse((prevResponse) => [
                                        prevResponse[0],
                                        "",
                                    ])
                                }
                            />
                            <DefaultButton
                                wrapper={styles.formButton}
                                initial={{
                                    backgroundColor:
                                        DefaultButton.color.disabled,
                                }}
                                animate={{
                                    backgroundColor:
                                        !formik.dirty || !formik.isValid
                                            ? DefaultButton.color.disabled
                                            : DefaultButton.color.enabled,
                                }}
                                whileHover={{
                                    opacity: formik.isSubmitting ? 1 : 0.7,
                                }}
                                transition={{
                                    type: "tween",
                                    duration: 0.3,
                                }}
                                isSubmit={true}
                                isDisabled={!formik.isValid}
                            >
                                WYŚLIJ
                            </DefaultButton>
                        </AnimatedForm>
                    )}
                </Formik>
                <div className={styles.info}>
                    {errorResponse[0] ? (
                        <ActionText
                            className={styles.infoText}
                            messages={[
                                "Sprawdź dokładnie kod i wpisz go ponownie.",
                                "Twój kod mógł też stracić ważność.",
                            ]}
                            button="WYŚLIJ PONOWNIE"
                            callback={handleCodeResend}
                            isButtonLast={true}
                        />
                    ) : (
                        <ActionText
                            className={styles.infoText}
                            messages={["Nie mam żadnego kodu."]}
                            button="WYŚLIJ PONOWNIE"
                            callback={handleCodeResend}
                        />
                    )}
                </div>
            </AuthContainer>
        </ProtectedRoute>
    );
};
