import {
    AnswerChoice,
    AnswerFill,
    AnswerTruth,
    AnswerOpen,
    TaskContent,
} from "../../build-components";

import { AnswerType } from "../../../typings";

export const getAnswer = (answer: AnswerType, isLoading?: boolean) => {
    if (answer.type === "text") {
        return <TaskContent key={answer.id} content={answer.content} isInline={true} />;
    } else if (answer.type === "radiobutton") {
        return (
            <AnswerChoice
                key={answer.id}
                id={answer.id}
                options={answer.options}
                isMultiple={!answer.is_radio}
                isLoading={isLoading}
            />
        );
    } else if (answer.type === "true_or_false") {
        return (
            <AnswerTruth key={answer.id} id={answer.id} isLoading={isLoading} />
        );
    } else if (answer.type === "match") {
        return (
            <AnswerFill
                key={answer.id}
                id={answer.id}
                rows={answer.row_answers}
                answers={answer.all_answers}
                isLoading={isLoading}
            />
        );
    } else if (answer.type === "open") {
        return (
            <AnswerOpen key={answer.id} id={answer.id} isLoading={isLoading} />
        );
    }
};
