import clsx from "clsx";
import { useState } from "react";

import { DefaultButton } from "../../../../components/buttons";
import { DottedLoader } from "../../../../components/loaders";
import { useMedia } from "../../../../hooks";
import { useGetSolutionOnDemandList } from "../../hooks/queries";
import { AnswerState } from "../../typings";
import { Answer } from "../Answer";

import checkmarkImg from "../../../../assets/images/dashboard/answerOnDemand/checkmark.svg";
import uploadImg from "../../../../assets/images/dashboard/answerOnDemand/upload.svg";
import styles from "./AnswerRequests.module.css";

type Props = {
    vertical?: boolean;
};
export const AnswerRequests = ({ vertical = false }: Props) => {
    const { isDesktop } = useMedia();
    const [filter, setFilter] = useState<AnswerState>("sent");
    const [renderAmount, setRenderAmount] = useState<
        Record<AnswerState, number>
    >({
        sent: 10,
        solved: 10,
    });

    const { data, isLoading } = useGetSolutionOnDemandList();

    return (
        <div className={styles.answerRequests}>
            {!isDesktop && data && (
                <div className={styles.filters}>
                    <button
                        onClick={() => setFilter("sent")}
                        className={clsx(
                            styles.filterButton,
                            filter === "sent" && styles.active
                        )}
                        type="button"
                    >
                        <span className={styles.filterButtonInner}>
                            {data.data.in_progress.length > 0 && (
                                <span
                                    className={clsx(
                                        styles.filterAmount,
                                        filter === "sent" && styles.active
                                    )}
                                >
                                    {data.data.in_progress.length}
                                </span>
                            )}
                            <span className={styles.filterTitle}>
                                <img src={uploadImg} alt="" />
                                WYSŁANE
                            </span>
                        </span>
                    </button>
                    <button
                        onClick={() => setFilter("solved")}
                        className={clsx(
                            styles.filterButton,
                            filter === "solved" && styles.active
                        )}
                        type="button"
                    >
                        <span className={styles.filterButtonInner}>
                            {data.data.answered.length > 0 && (
                                <span
                                    className={clsx(
                                        styles.filterAmount,
                                        filter === "solved" && styles.active
                                    )}
                                >
                                    {data.data.answered.length}
                                </span>
                            )}
                            <span className={styles.filterTitle}>
                                <img src={checkmarkImg} alt="" />
                                ROZWIĄZANE
                            </span>
                        </span>
                    </button>
                </div>
            )}
            {isLoading && (
                <div className={styles.loader}>
                    <DottedLoader />
                </div>
            )}
            {data && (
                <div
                    className={clsx(
                        styles.answers,
                        vertical && styles.vertical
                    )}
                >
                    {(isDesktop || filter === "sent") && (
                        <div>
                            {isDesktop && (
                                <div className={styles.columnHeader}>
                                    <span className={styles.filterButtonInner}>
                                        <span className={styles.filterTitle}>
                                            <img src={uploadImg} alt="" />
                                            WYSŁANE
                                        </span>
                                    </span>
                                </div>
                            )}
                            {data.data.in_progress
                                .slice(0, renderAmount.sent)
                                .map((answer) => (
                                    <Answer
                                        key={answer.id}
                                        data={{
                                            id: answer.id,
                                            date: answer.created_at,
                                            read: true,
                                        }}
                                        state="sent"
                                    />
                                ))}
                            {data.data.in_progress.length === 0 && (
                                <div className={styles.empty}>
                                    tutaj pojawią się dodane zadania
                                </div>
                            )}
                            {renderAmount.sent <
                                data.data.in_progress.length && (
                                <DefaultButton
                                    layout
                                    className={styles.showMoreButton}
                                    onClick={() =>
                                        setRenderAmount((state) => ({
                                            ...state,
                                            sent: state.sent + 10,
                                        }))
                                    }
                                >
                                    Pokaż więcej
                                </DefaultButton>
                            )}
                        </div>
                    )}
                    {(isDesktop || filter === "solved") && (
                        <div className={styles.solved}>
                            {isDesktop && (
                                <div className={styles.columnHeader}>
                                    <span className={styles.filterButtonInner}>
                                        <span className={styles.filterTitle}>
                                            <img src={checkmarkImg} alt="" />
                                            ROZWIĄZANE
                                        </span>
                                    </span>
                                </div>
                            )}
                            {data.data.answered
                                .slice(0, renderAmount.solved)
                                .map((answer) => (
                                    <Answer
                                        key={answer.id}
                                        data={{
                                            id: answer.id,
                                            date: answer.created_at,
                                            read: answer.read === "1",
                                        }}
                                        state="solved"
                                    />
                                ))}
                            {data.data.answered.length === 0 && (
                                <div className={styles.empty}>
                                    tutaj pojawią się rozwiązane zadania
                                </div>
                            )}
                            {renderAmount.solved <
                                data.data.answered.length && (
                                <DefaultButton
                                    layout
                                    className={styles.showMoreButton}
                                    onClick={() =>
                                        setRenderAmount((state) => ({
                                            ...state,
                                            solved: state.solved + 10,
                                        }))
                                    }
                                >
                                    Pokaż więcej
                                </DefaultButton>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
