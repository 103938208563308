import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import { setInvitationCookie } from "../../../services";

import { useGetInvitationToken } from "../../../modules/auth/hooks";

import { CircleLoader } from "../../../components/loaders";

import styles from "./InvitationLoader.module.css";

type Props = {
    token: string;
    setIsError: React.Dispatch<React.SetStateAction<boolean>>;
};

const debounceFn = (fn: () => void, timeout = 3000) => {
    setTimeout(() => {
        fn();
    }, timeout);
};

export const InvitationLoader = ({ token, setIsError }: Props) => {
    const getInvitationToken = useGetInvitationToken(token);

    const navigate = useNavigate();

    const [invitationStatus, setInvitationStatus] = useState("");
    const isInvitationSuccess = invitationStatus === "success";
    const isInvitationError = invitationStatus === "error";

    useEffect(() => {
        const queryResult = getInvitationToken;
        const data = queryResult.data;
        const isError = queryResult.isError;

        if (data) {
            const email = data.data.email;
            const accountType = data.data.type;

            setInvitationCookie(email, accountType);

            debounceFn(() => setInvitationStatus("success"), 1000);

            debounceFn(() =>
                navigate(accountType.toLowerCase(), {
                    replace: true,
                })
            );
        }

        if (isError) {
            debounceFn(() => setInvitationStatus("error"), 1000);

            debounceFn(() => setIsError(true));
        }
    }, [getInvitationToken, navigate, setIsError]);

    return (
        <div className={styles.container}>
            <p className={styles.title}>Trwa wczytywanie zaproszenia</p>
            <div className={styles.tile}>
                <div className={styles.box}>
                    <CircleLoader />
                </div>
                <div
                    className={clsx(
                        styles.caption,
                        isInvitationError && styles.error
                    )}
                >
                    {isInvitationSuccess
                        ? "PRZENOSZENIE"
                        : isInvitationError
                        ? "BŁĄD"
                        : "WCZYTYWANIE"}
                </div>
            </div>
        </div>
    );
};
