import clsx from "clsx";

import { useMedia } from "../../../hooks";

import styles from "./ShapesContainer.module.css";

export const ShapesContainer = () => {
    const { isDesktop } = useMedia();

    return (
        <div className={clsx(styles.shapes, isDesktop && styles.isDesktop)}>
            <div className={clsx(styles.shape, styles.polygon)}></div>
            <div className={clsx(styles.shape, styles.ellipse)}></div>
        </div>
    );
};
