const setSlideTransform = (
    element: HTMLElement,
    position: number,
    isAnimated?: boolean
) => {
    element.setAttribute(
        "style",
        `transform: translateX(${position}%); ${
            isAnimated ? "transition: transform .3s ease;" : "transition: none;"
        }`
    );
};

const resetSlideTransform = (element: HTMLElement) => {
    if (element.getAttribute("style") !== "transform: translateX(0%);") {
        setSlideTransform(element, 0);
    }
};

const setSlideAction = (
    element: HTMLElement,
    style: string,
    position: number,
    enableAction: React.MutableRefObject<boolean>
) => {
    if (50 < position) {
        element.classList.add(style);
        enableAction.current = true;
    } else if (element.classList.contains(style)) {
        element.classList.remove(style);
    } else {
        enableAction.current = false;
    }
};

const disableActionClick = (
    ref: React.MutableRefObject<boolean>,
    position: number
) => {
    if (position >= 5 || position <= -5) {
        ref.current = true;
    }
};

export {
    setSlideTransform,
    resetSlideTransform,
    setSlideAction,
    disableActionClick,
};
