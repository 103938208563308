import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

import { useMedia } from "../../../hooks";

import { CircleLoader } from "../../loaders";
import { DefaultIcon } from "./icons";

import { taskChangeAnimations } from "../../../modules/tasks/utils";

import { TaskHeadingProps } from "./TaskHeading.typings";

import styles from "./TaskHeading.module.css";

export const TaskHeading = ({
    title,
    icon,
    tooltip,
    isEqual,
    isRoundingDisabled,
    isLoading,
    onClick,
}: TaskHeadingProps) => {
    const { isDesktop } = useMedia();

    const { state } = useNavigation();
    const { pathname } = useLocation();
    const prevPathname = useRef(pathname);

    const [isClicked, setIsClicked] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const isOtherPage = useRef(false);
    const isInnerLoading = state === "loading";

    const [containerRef, setContainerRef] = useState<HTMLHeadingElement | null>(
        null
    );

    const handleClick = () => {
        setIsHovered(false);
        setIsClicked(true);

        isOtherPage.current = true;

        if (onClick) {
            onClick();
        }
    };

    useEffect(() => {
        if (prevPathname.current !== pathname) {
            setIsClicked(false);
        }

        if (pathname.indexOf("/theory") === -1) {
            isOtherPage.current = false;
        }

        prevPathname.current = pathname;
    }, [pathname]);

    return (
        <motion.div
            className={clsx(
                styles.container,
                (icon || onClick) && styles.hasIcon,
                isRoundingDisabled && isDesktop && styles.roundingDisabled,
                isEqual && styles.equal
            )}
            {...(!isEqual && {
                initial: {
                    height: containerRef
                        ? containerRef.clientHeight + (isDesktop ? 20 : 19)
                        : undefined,
                },
                animate: {
                    height: containerRef
                        ? containerRef.clientHeight + (isDesktop ? 20 : 19)
                        : undefined,
                },
                transition: {
                    type: "tween",
                    duration: taskChangeAnimations.transition.duration,
                },
            })}
        >
            <AnimatePresence mode="wait" initial={false}>
                <motion.h3
                    ref={setContainerRef}
                    key={title}
                    className={styles.title}
                    {...taskChangeAnimations}
                >
                    {title}
                </motion.h3>
            </AnimatePresence>
            {isLoading && !isClicked && (
                <div className={styles.loading}>
                    <CircleLoader
                        color={isDesktop ? undefined : "white"}
                        size="24"
                    />
                </div>
            )}
            <AnimatePresence mode="wait" initial={false}>
                {(icon || onClick) && (
                    <motion.div
                        className={styles.icon}
                        {...(!isClicked &&
                            !isOtherPage.current && {
                                ...taskChangeAnimations,
                                animate: {
                                    ...taskChangeAnimations.animate,
                                    transition: {
                                        delay: taskChangeAnimations.transition
                                            .duration,
                                    },
                                },
                            })}
                    >
                        <button
                            className={styles.button}
                            type="button"
                            disabled={!onClick || isInnerLoading}
                            onClick={handleClick}
                            onMouseEnter={
                                isDesktop && tooltip
                                    ? () => setIsHovered(true)
                                    : undefined
                            }
                            onMouseLeave={
                                isDesktop && tooltip
                                    ? () => setIsHovered(false)
                                    : undefined
                            }
                        >
                            {isInnerLoading && isClicked ? (
                                <CircleLoader size="18" />
                            ) : typeof icon === "string" ? (
                                <img src={icon} alt="" />
                            ) : (
                                <DefaultIcon />
                            )}
                        </button>
                        {tooltip && (
                            <AnimatePresence mode="wait">
                                {isDesktop && isHovered && (
                                    <motion.span
                                        className={styles.tooltip}
                                        initial={{ y: 4, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        exit={{ y: 4, opacity: 0 }}
                                        transition={{
                                            type: "tween",
                                            duration: 0.2,
                                        }}
                                    >
                                        <span className={styles.tooltipBody}>
                                            {tooltip}
                                        </span>
                                        <span
                                            className={styles.tooltipArrow}
                                        ></span>
                                    </motion.span>
                                )}
                            </AnimatePresence>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </motion.div>
    );
};
