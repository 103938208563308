import * as yup from "yup";

import { SelectValue } from "../../../../components/inputs";
import { Source } from "../../typings";

export const validationSchema = yup.object({
    source: yup.object({
        label: yup.string().required(),
        value: yup.string().required(),
    }),
    title: yup
        .object({
            id: yup.string().required(" "),
            name: yup.string().required(" "),
        })
        .when("source", {
            is: (value: SelectValue<string, Source>) => value.value === "other",
            then: (schema) =>
                schema.shape({
                    id: yup.string().notRequired(),
                    name: yup.string().notRequired(),
                }),
            otherwise: (schema) =>
                schema.shape({
                    id: yup.string().required(),
                    name: yup.string().required(),
                }),
        }),
    pageNumber: yup.string().when("source", {
        is: (value: SelectValue<string, Source>) => value.value === "other",
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required(),
    }),
    taskNumber: yup.string().when("source", {
        is: (value: SelectValue<string, Source>) => value.value === "other",
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required(),
    }),
    file1: yup.mixed(),
    file2: yup.mixed(),
    notes: yup.string(),
});
