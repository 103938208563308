export const removeLastSlashes = (
    string: string,
    hasLastSlash: boolean = string.endsWith("/")
) => {
    while (hasLastSlash) {
        string = string.slice(0, -1);

        hasLastSlash = string.endsWith("/");
    }

    return string;
};
