import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

import { userSavedCardsQuery } from "../../utils";

type Mutation = {
    cardId: string;
};

export const useUnlinkCard = () => {
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse<any, any>, any, Mutation, unknown>(
        (data) =>
            mainApi.post("unlink_card", {
                id_card: data.cardId,
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(userSavedCardsQuery().queryKey);
            },
        }
    );
};
