import clsx from "clsx";

import { useMedia } from "../../../hooks";

import styles from "./AuthContainer.module.css";

type Props = {
    position?: "default" | "top" | "center";
    image?: string;
    imageSize?: number;
    imagePosition?: "center" | "right";
    imageOffsetX?: number;
    imageOffsetY?: number;
    children?: JSX.Element | JSX.Element[] | string;
};

type ImageProps = {
    image: string;
    imageSize: Props["imageSize"];
    imagePosition?: Props["imagePosition"];
    imageOffsetX?: Props["imageOffsetX"];
};

const Image = ({
    image,
    imageSize,
    imagePosition,
    imageOffsetX,
}: ImageProps) => {
    const { isDesktop } = useMedia();

    const offset = imageOffsetX ? imageOffsetX * -1 : undefined;

    return (
        <img
            className={clsx(
                styles.image,
                imagePosition === "center" && styles.center,
                imagePosition === "right" && styles.right
            )}
            style={{
                maxHeight: imageSize,
                marginLeft:
                    isDesktop && !imagePosition && offset ? offset : undefined,
                marginRight:
                    isDesktop &&
                    (imagePosition === "center" || imagePosition === "right") &&
                    offset
                        ? offset
                        : undefined,
                transform: isDesktop
                    ? imagePosition === "center" || imagePosition === "right"
                        ? "translateX(100%)"
                        : "translateX(-100%)"
                    : undefined,
            }}
            src={image}
            alt=""
        />
    );
};

export const AuthContainer = ({
    position = "default",
    image,
    imageSize,
    imagePosition,
    imageOffsetX,
    imageOffsetY,
    children,
}: Props) => {
    const { isDesktop } = useMedia();

    return (
        <div id="mainAppContainer" className={styles.container}>
            <div className={styles.flex}>
                <div
                    className={clsx(styles.main, isDesktop && styles.isDesktop)}
                >
                    <div
                        className={clsx(
                            styles.wrapper,
                            position === "default" && styles.default,
                            position === "top" && styles.top,
                            position === "center" && styles.center,
                            isDesktop && styles.isDesktop
                        )}
                    >
                        {children}
                    </div>
                </div>
                {image && imageSize && (
                    <div
                        className={clsx(
                            styles.footer,
                            isDesktop && styles.isDesktop
                        )}
                    >
                        <div
                            className={styles.wrapper}
                            style={{
                                height: imageSize,
                                marginBottom:
                                    isDesktop && imageOffsetY
                                        ? imageOffsetY + "vh"
                                        : undefined,
                            }}
                        >
                            <Image
                                image={image}
                                imageSize={imageSize}
                                imagePosition={imagePosition}
                                imageOffsetX={imageOffsetX}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
