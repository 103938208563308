import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

import { ReactComponent as Arrow } from "./images/arrow-icon.svg";

import {
    arrowVariants,
    collapseAnimations,
    transition,
} from "./CollapseDesktop.animations";

import { CollapseDesktopProps } from "./CollapseDesktop.typings";

import styles from "./CollapseDesktop.module.css";

const ArrowMotion = motion(Arrow);

export const CollapseDesktop = ({
    elements,
    isDisabled,
}: CollapseDesktopProps) => {
    const [isOpen, setIsOpen] = useState(isDisabled ? false : true);

    return (
        <div className={clsx(styles.container, isDisabled && styles.disabled)}>
            <div className={styles.main}>
                <div className={styles.row}>
                    {elements.map((element) => (
                        <div key={element.key} className={styles.column}>
                            <div className={styles.heading}>
                                <div className={styles.icon}>
                                    <img src={element.icon} alt="" />
                                </div>
                                {element.title}
                            </div>
                        </div>
                    ))}
                </div>
                <AnimatePresence mode="wait" initial={false}>
                    {isOpen && !isDisabled && (
                        <motion.div
                            className={styles.collapse}
                            {...collapseAnimations}
                        >
                            <div className={styles.row}>
                                {elements.map((element) => (
                                    <div
                                        key={element.key}
                                        className={styles.column}
                                    >
                                        {element.children}
                                    </div>
                                ))}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            <div className={styles.aside}>
                <button
                    className={styles.button}
                    type="button"
                    disabled={isDisabled}
                    onClick={
                        !isDisabled
                            ? () => setIsOpen((open) => !open)
                            : undefined
                    }
                >
                    <ArrowMotion
                        variants={arrowVariants}
                        initial="closed"
                        animate={isOpen ? "open" : "closed"}
                        transition={transition}
                    />
                </button>
            </div>
        </div>
    );
};
