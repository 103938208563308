import { motion } from "framer-motion";

const colors = {
    default: "#ffffff",
    primary: "#2eafbe",
};

const pathVariants = {
    inactive: {
        fill: colors.primary,
    },
    active: {
        fill: colors.default,
    },
};

type Props = {
    isActive?: boolean;
};

export const ExamIcon = ({ isActive }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="25"
        viewBox="0 0 30 25"
    >
        <motion.path
            id="Icon_material-school"
            data-name="Icon material-school"
            d="M6.955,18.639v5.556L16.5,29.5l9.545-5.306V18.639L16.5,23.944ZM16.5,4.5l-15,8.333,15,8.333,12.273-6.819v9.6H31.5V12.833Z"
            transform="translate(-1.5 -4.5)"
            fill={colors.primary}
            variants={pathVariants}
            initial={isActive ? "active" : "inactive"}
            animate={isActive ? "active" : "inactive"}
            transition={{
                type: "tween",
                duration: 0.3,
            }}
        />
    </svg>
);
