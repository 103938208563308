import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

type Error = {
    response: {
        data: {
            messages: {
                error: string;
            };
        };
    };
};

type Mutation = {
    [name: string]: FormDataEntryValue;
};

export const useDeleteAccount = () => {
    return useMutation<AxiosResponse<any, any>, Error, Mutation, unknown>(
        (data) =>
            mainApi.post("delete_account_with_code", {
                password: data.password,
                code: data.code,
            })
    );
};
