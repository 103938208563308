import { useEffect, useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { useAuthContext } from "../../../../contexts/AuthContext";

import {
    setAccountCookie,
    setRegisterCookie,
    setTokenCookie,
} from "../../../../services";
import { mainApi } from "../../../../api";

import { useCodeResend } from "./useCodeResend";

import { SchoolType } from "../../../../typings";

type Response = {
    user_id: number;
    token: string;
    is_active: boolean;
    subscription_paid: boolean;
    inactive: boolean;
    parent_confirmation_required: boolean;
    is_parent_approved: boolean;
    school: SchoolType | "";
    class: string | "";
    email: string;
    type: "Uczeń" | "Rodzic" | "Nauczyciel";
    parent_email: string;
    has_active_parents: boolean;
    front_app_video_url: string;
    has_watched_front_app_video: boolean;
};

type Error = {
    code?: "ERR_NETWORK";
    message?: "Network Error";
    name?: "AxiosError";
    response?: {
        data: {
            messages: {
                email?: string;
                error?: string;
            };
        };
        status: number;
    };
};

type Mutation = {
    [name: string]: FormDataEntryValue;
};

export const useLogin = () => {
    const codeResend = useCodeResend(true);
    const { setIsUserLogged, setIsStorePageDisplyed } = useAuthContext();

    const deviceToken = useRef("");
    const expoPushToken = window.sessionStorage.getItem("expoPushToken");

    useEffect(() => {
        if (expoPushToken) {
            deviceToken.current = expoPushToken;
        }
    }, [expoPushToken]);

    return useMutation<AxiosResponse<Response, any>, Error, Mutation, unknown>(
        (data) => {
            const expoPushToken = window.sessionStorage.getItem("expoPushToken");
            return mainApi.post("user-login", {
                email: data.email,
                password: data.password,
                device_token: expoPushToken,
            })
        }
        ,
        {
            onSuccess: (data) => {
                if (data.data.is_active) {
                    const accountType = data.data.type;
                    const isStudent = accountType === "Uczeń";

                    const accountData = {
                        userId: data.data.user_id.toString(),
                        accountType,
                        userEmail: data.data.email,
                        parentEmail: isStudent ? data.data.parent_email : "",
                        userSchool: isStudent
                            ? data.data.school && data.data.class
                                ? ([data.data.school, data.data.school === 'Podstawowa' ? '8_kl' : 'matura_podstawowa'] as [
                                    SchoolType,
                                    string
                                ])
                                : undefined
                            : undefined,
                        videoUrl: data.data.front_app_video_url,
                        hasActiveParents: data.data.has_active_parents,

                        isSubscriptionPaid:
                            data.data.subscription_paid.toString(),
                        isAccountDisabled: data.data.inactive.toString(),
                        isParentConfirmationRequired: isStudent
                            ? data.data.parent_confirmation_required.toString()
                            : "false",
                        isParentApproved: isStudent
                            ? data.data.parent_confirmation_required
                                ? data.data.is_parent_approved.toString()
                                : "true"
                            : "true",
                        isVideoWatched:
                            data.data.has_watched_front_app_video.toString(),
                    };

                    setAccountCookie(accountData);

                    setTokenCookie(data.data.token);

                    setIsUserLogged(true);

                    setIsStorePageDisplyed(false);
                } else {
                    const email = data.data.email;

                    setRegisterCookie(email);

                    codeResend.mutate(email);
                }
            },
        }
    );
};
