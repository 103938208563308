import Cookies from "js-cookie";

const key = "examFilter";

const setExamFilter = (isSet: boolean) => {
    if (isSet) {
        Cookies.set(key, `true`, {
            sameSite: "strict",
            expires: 30,
        });
    } else {
        removeExamFilter();
    }
};

const getExamFilter = () => (Cookies.get(key) === "true" ? true : false);

const removeExamFilter = () => Cookies.remove(key, { sameSite: "strict" });

export { setExamFilter, getExamFilter };
