import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

import { useStorageFlag } from "../../hooks";

import { IconButton } from "../../../../components/buttons";
import { DefaultModal } from "../../../../components/modals";

import { getPreviousPath } from "../../../../utils";

import { StudentListItemProps } from "./StudentListItem.typings";

import styles from "./StudentListItem.module.css";

export const StudentListItem = ({
    id,
    name,
    email,
    schoolClass,
    mutation,
    isDesktop,
    isPreview,
    isInvited,
    isUnconfirmed,
    isDashboard,
    isLargerOnDesktop,
}: StudentListItemProps) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { state } = useNavigation();

    const isMutated = useRef(mutation.isError ?? false);
    const [isError, setIsError] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);
    useStorageFlag(["popOpen", "modal"], isModalOpen);

    const [isProgressLoading, setIsProgressLoading] = useState(false);

    const handleAcceptMutation = () => {
        isMutated.current = true;

        mutation.mutate({
            action: "accept",
            email,
        });
    };

    const handleRemoveMutation = () => {
        isMutated.current = true;

        if (isUnconfirmed) {
            mutation.mutate({
                action: "decline",
                email,
            });
        } else {
            mutation.mutate({
                action: "remove",
                email,
            });
        }

        setIsModalOpen(false);
    };

    useEffect(() => {
        if (mutation.isError && isMutated.current) {
            isMutated.current = false;

            setIsError(true);
        }
    }, [mutation]);

    useEffect(() => {
        if (isError) {
            setTimeout(() => {
                setIsError(false);
            }, 1500);
        }
    }, [isError]);

    return (
        <>
            <div
                className={clsx(
                    styles.container,
                    isPreview && styles.preview,
                    isUnconfirmed && styles.unconfirmed,
                    isLargerOnDesktop && styles.large
                )}
            >
                <div className={styles.content}>
                    <p className={styles.name}>
                        {name && schoolClass && isDesktop ? (
                            <>
                                {name}{" "}
                                <span className={styles.classInline}>
                                    - klasa {schoolClass}
                                </span>
                            </>
                        ) : (
                            name ?? email
                        )}
                    </p>
                    <p className={styles.class}>
                        {isPreview ? (
                            schoolClass ? (
                                `(KLASA ${schoolClass})`
                            ) : (
                                ""
                            )
                        ) : schoolClass ? (
                            isDesktop ? (
                                <>
                                    {email}{" "}
                                    {isUnconfirmed ? (
                                        <>
                                            <br /> (niepotwierdzony)
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </>
                            ) : (
                                <>
                                    {email}
                                    <br />
                                    klasa {schoolClass}{" "}
                                    {isUnconfirmed ? "- niepotwierdzony" : ""}
                                </>
                            )
                        ) : isUnconfirmed ? (
                            "(niepotwierdzony)"
                        ) : (
                            ""
                        )}
                    </p>
                </div>
                <div className={styles.buttonGroup}>
                    {!isPreview && !isUnconfirmed && (
                        <div className={styles.button}>
                            <IconButton
                                type="chart"
                                isLoading={
                                    state === "loading" && isProgressLoading
                                }
                                onClick={() => {
                                    setIsProgressLoading(true);

                                    navigate(
                                        isDashboard
                                            ? `${pathname}/student-progress/${id}`
                                            : `${getPreviousPath(
                                                  pathname,
                                                  2
                                              )}/student-progress/${id}`
                                    );
                                }}
                            />
                        </div>
                    )}
                    {isUnconfirmed && isInvited && (
                        <div className={styles.button}>
                            <IconButton
                                type="confirm"
                                isDisabled={isError || mutation.isLoading}
                                onClick={handleAcceptMutation}
                            />
                        </div>
                    )}
                    <div className={styles.button}>
                        <IconButton
                            type="delete"
                            isDisabled={isError || mutation.isLoading}
                            onClick={() => setIsModalOpen(true)}
                        />
                    </div>
                </div>
                <AnimatePresence mode="wait">
                    {isError && (
                        <motion.div
                            className={styles.error}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{
                                type: "tween",
                                duration: 0.2,
                            }}
                        >
                            <p>Wystąpił nieoczekiwany błąd.</p>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
            <DefaultModal
                title="ODEPNIJ"
                actions={[
                    {
                        name: "TAK, ODEPNIJ",
                        isImportant: true,
                        onClick: handleRemoveMutation,
                    },
                    {
                        name: "NIE, WRÓĆ",
                        onClick: () => setIsModalOpen(false),
                    },
                ]}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            >
                <p>Czy na pewno chcesz odpiąć od swojego konta użytkownika:</p>
                <p>
                    {name ? (
                        <>
                            <strong>
                                {name} (uczeń)
                                <br />
                                {email}
                            </strong>
                        </>
                    ) : (
                        <>
                            <strong>{email} (uczeń)</strong>
                        </>
                    )}
                </p>
            </DefaultModal>
        </>
    );
};
