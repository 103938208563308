import { AnimatePresence, motion } from "framer-motion";

import { useResultContext } from "../../contexts/ResultContext";

import { AnswerSubmit, TaskSolution } from "../build-components";

import { getAnswer } from "./utils";
import { taskChangeAnimations } from "../../utils";

import { TaskFormProps } from "./TaskForm.typings";

export const TaskForm = ({ taskId, answers, isLoading }: TaskFormProps) => {
    const { results } = useResultContext();

    return (
        <>
            {answers.map((answer) => getAnswer(answer, isLoading))}
            {answers.filter((answer) => answer.type !== "text").length > 0 && (
                <AnimatePresence mode="wait" initial={false}>
                    {!results ? (
                        <motion.div key="submit" {...taskChangeAnimations}>
                            <AnswerSubmit taskId={taskId} isLoading={isLoading}>
                                ZATWIERDŹ
                            </AnswerSubmit>
                        </motion.div>
                    ) : (
                        <motion.div key="solution" {...taskChangeAnimations}>
                            <TaskSolution content={results.solution} />
                        </motion.div>
                    )}
                </AnimatePresence>
            )}
        </>
    );
};
