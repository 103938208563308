import { useEffect } from "react";
import {
    Navigate,
    useLocation,
    useNavigate,
    useNavigation,
    useParams,
} from "react-router-dom";

import { setTaskParams } from "../../services";

import { useRedirectContext } from "../../../../contexts/RedirectContext";

import { useAiSessionTheory } from "../../hooks";
import { useRedirectByPop } from "../../../../hooks";

import { TaskContainer } from "../../../../components/containers";
import { DashboardHeading, TaskHeading } from "../../../../components/headings";
import { NavigationBar, TaskContent } from "../../components";
import { DefaultButton } from "../../../../components/buttons";
import { ButtonLoader } from "../../../../components/utils";

import { taskSlug } from "../../utils";
import { getPreviousPath } from "../../../../utils";

import styles from "./AiSessionTheory.module.css";

type LocationType = {
    pathname: string;
    state: boolean | null;
};

export const AiSessionTheory = () => {
    const params = useParams();
    const { pathname, state }: LocationType = useLocation();
    const navigate = useNavigate();
    const { state: navigationState } = useNavigation();

    const { setRedirect } = useRedirectContext();

    const sectionId = params.sessionId as string;

    const { data } = useAiSessionTheory(sectionId);

    const handleRedirect = () => {
        if (data) {
            const taskId = data.data.session_exercise_id;

            setTaskParams({
                type: taskSlug.ai,
                taskId,
                additionalId: sectionId,
            });

            navigate(`/dashboard/student/task/${taskSlug.ai}/${taskId}`);
        }
    };

    const backwardPathname = getPreviousPath(pathname, 3);

    useRedirectByPop(backwardPathname);

    useEffect(() => {
        setRedirect(backwardPathname);
    }, [backwardPathname, setRedirect]);

    if (!params.sessionId || state !== true) {
        return <Navigate to={"/dashboard/student"} replace={true} />;
    }

    return (
        <TaskContainer>
            <DashboardHeading
                title="TEORIA W PIGUŁCE"
                to={backwardPathname}
                hasSmallSpacing={true}
            />
            <>
                {data && (
                    <>
                        <TaskHeading title={data.data.theory_name ?? 'Brak tytułu'} />
                        <div className={styles.container}>
                            <TaskContent content={data.data.theory_content} />
                            <DefaultButton
                                wrapper={styles.button}
                                onClick={handleRedirect}
                            >
                                <ButtonLoader
                                    condition={navigationState === "loading"}
                                    color="white"
                                >
                                    DALEJ
                                </ButtonLoader>
                            </DefaultButton>
                        </div>
                    </>
                )}
            </>
            <NavigationBar
                backward={{
                    children: "WRÓĆ",
                    to: backwardPathname,
                }}
            />
        </TaskContainer>
    );
};
