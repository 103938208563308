import { getNormalizedString } from "../../../../utils";

import { StudentsResponse } from "../../typings";

type Keys = (string | null | undefined)[];

const getStringFromKeys = (keys: Keys) => {
    let stringFromKeys = "";
    const filteredKeys = keys.filter((key) => key);

    filteredKeys.forEach((value, index) => {
        stringFromKeys += value;

        if (index !== keys.length - 1) {
            stringFromKeys += " ";
        }
    });

    return stringFromKeys.toLowerCase();
};

const getFilteredValue = (keys: Keys, value: string) =>
    getNormalizedString(getStringFromKeys(keys)).indexOf(
        getNormalizedString(value.toLowerCase())
    ) !== -1;

export const getFilteredStudents = (
    data: StudentsResponse[0],
    value: string
) => {
    return data.filter((element) =>
        getFilteredValue(
            [element.first_name, element.last_name, element.email],
            value
        )
    );
};
