import clsx from "clsx";
import { Formik, FormikConfig, FormikHelpers } from "formik";

import { DefaultInput } from "../../../../components/inputs";
import { DefaultModal } from "../../../../components/modals";
import {
    getNewNotification,
    validationSchema,
} from "../../pages/Notifications/utils";
import { NotificationType } from "../../typings";

import styles from "./NotificationsModal.module.css";

type Props = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    initialValues?: NotificationType;
    onSubmit: FormikConfig<NotificationType>["onSubmit"];
};
export const NotificationsModal = ({
    isOpen,
    setIsOpen,
    initialValues,
    onSubmit,
}: Props) => {
    const setDayOfWeek = (
        key: keyof NotificationType["daysOfWeek"],
        setValues: FormikHelpers<NotificationType>["setValues"]
    ) =>
        setValues((state) => ({
            ...state,
            daysOfWeek: {
                ...state.daysOfWeek,
                [key]: !state.daysOfWeek[key],
            },
        }));

    return (
        <Formik<NotificationType>
            initialValues={
                initialValues ??
                getNewNotification({
                    time: "18:00",
                    daysOfWeek: {
                        monday: false,
                        tuesday: false,
                        wednesday: false,
                        thursday: false,
                        friday: false,
                        saturday: false,
                        sunday: false,
                    },
                })
            }
            onSubmit={onSubmit}
            validateOnMount
            validationSchema={validationSchema}
        >
            {(formik) => (
                <DefaultModal
                    actions={[
                        {
                            name: "USTAW",
                            onClick: formik.submitForm,
                            isDisabled: !formik.isValid,
                        },
                    ]}
                    title=""
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    hasSmallSpacing
                >
                    <DefaultInput
                        isMarginless
                        name="time"
                        type="time"
                        label="Godzina powiadomienia"
                    />
                    <div className={styles.dayButtons}>
                        <button
                            onClick={() =>
                                setDayOfWeek("monday", formik.setValues)
                            }
                            className={clsx(
                                styles.dayButton,
                                formik.values.daysOfWeek.monday &&
                                    styles.selected
                            )}
                            type="button"
                        >
                            Pon
                        </button>
                        <button
                            onClick={() =>
                                setDayOfWeek("tuesday", formik.setValues)
                            }
                            className={clsx(
                                styles.dayButton,
                                formik.values.daysOfWeek.tuesday &&
                                    styles.selected
                            )}
                            type="button"
                        >
                            Wt
                        </button>
                        <button
                            onClick={() =>
                                setDayOfWeek("wednesday", formik.setValues)
                            }
                            className={clsx(
                                styles.dayButton,
                                formik.values.daysOfWeek.wednesday &&
                                    styles.selected
                            )}
                            type="button"
                        >
                            Śr
                        </button>
                        <button
                            onClick={() =>
                                setDayOfWeek("thursday", formik.setValues)
                            }
                            className={clsx(
                                styles.dayButton,
                                formik.values.daysOfWeek.thursday &&
                                    styles.selected
                            )}
                            type="button"
                        >
                            Czw
                        </button>
                        <button
                            onClick={() =>
                                setDayOfWeek("friday", formik.setValues)
                            }
                            className={clsx(
                                styles.dayButton,
                                formik.values.daysOfWeek.friday &&
                                    styles.selected
                            )}
                            type="button"
                        >
                            Pt
                        </button>
                        <button
                            onClick={() =>
                                setDayOfWeek("saturday", formik.setValues)
                            }
                            className={clsx(
                                styles.dayButton,
                                formik.values.daysOfWeek.saturday &&
                                    styles.selected
                            )}
                            type="button"
                        >
                            Sob
                        </button>
                        <button
                            onClick={() =>
                                setDayOfWeek("sunday", formik.setValues)
                            }
                            className={clsx(
                                styles.dayButton,
                                formik.values.daysOfWeek.sunday &&
                                    styles.selected
                            )}
                            type="button"
                        >
                            Niedz
                        </button>
                    </div>
                </DefaultModal>
            )}
        </Formik>
    );
};
