import { useState } from "react";

import {
    useTeacherStudents,
    useRelationByTeacher,
    useNavigationAccount,
} from "../../../hooks";

import { DashboardHeading } from "../../../../../components/headings";
import { AccountContainer } from "../../../../../components/containers";
import { InviteInput, NavigationAccount, Students } from "../../../components";

import { teacherStudentsQuery } from "../../../utils";

import styles from "./TeacherStudents.module.css";

export const TeacherStudents = () => {
    const navigation = useNavigationAccount("Uczniowie");

    const queryKey = teacherStudentsQuery().queryKey;
    const queryResult = useTeacherStudents();
    const mutation = useRelationByTeacher(queryKey);

    const [inviteValue, setInviteValue] = useState("");

    return (
        <>
            <DashboardHeading title="MOJE KONTO" subtitle="moi uczniowie" />
            <AccountContainer
                sidebarElement={<NavigationAccount elements={navigation} />}
            >
                <>
                    <Students
                        className={styles.container}
                        data={{ response: queryResult.data, key: "students" }}
                        emptyText={{
                            initial: "Brak uczniów do wyświetlenia",
                            search: "Nie znaleziono wyszukiwanego ucznia",
                        }}
                        mutation={mutation}
                    />
                    <div className={styles.bar}>
                        <InviteInput
                            className={styles.input}
                            title="DODAJ UCZNIA"
                            label="E-mail ucznia"
                            value={inviteValue}
                            mutation={mutation}
                            isBottom={true}
                            onChange={(value) => setInviteValue(value)}
                        />
                    </div>
                </>
            </AccountContainer>
        </>
    );
};
