import { AnimatePresence, motion } from "framer-motion";

import { useMedia } from "../../../../hooks";

import { discountAnimations } from "./StorePrice.animations";
import { StorePriceProps } from "./StorePrice.typings";

import styles from "./StorePrice.module.css";

import infinity from "../../../../assets/images/robots/infinity-watching.svg";

export const StorePrice = ({ current, discount }: StorePriceProps) => {
    const { isDesktop } = useMedia();

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <AnimatePresence mode="wait">
                    {discount && (
                        <motion.div
                            className={styles.discount}
                            {...discountAnimations}
                        >
                            rabat: {discount} zł
                        </motion.div>
                    )}
                </AnimatePresence>
                <div className={styles.price}>SUMA: {current} zł</div>
            </div>
            {isDesktop && (
                <div className={styles.robot}>
                    <img src={infinity} alt="" />
                </div>
            )}
        </div>
    );
};
