import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { childrenSubscriptionsQuery } from "../../utils";

import { SchoolType } from "../../../../typings";
import { SubscriptionType } from "../../typings";

type Response = {
    children: {
        child_email: string;
        user_id: string;
        first_name: string;
        last_name: string;
        school: SchoolType;
        class: string;
        subscriptions: SubscriptionType[];
    }[];
};

export const useChildrenSubscriptions = (isEnabled?: boolean) => {
    return useQuery<AxiosResponse<Response, any>>(
        childrenSubscriptionsQuery(isEnabled)
    );
};
