import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { romanize } from "romans";
import clsx from "clsx";

import { getAccountCookie } from "../../../../services";

import { SubscriptionPackages } from "../SubscriptionPackages";
import { MidgetButton } from "../MidgetButton";

import {
    collapseAnimations,
    getIconAnimations,
} from "./SubscriptionItem.animations";
import { SubscriptionItemProps } from "./SubscriptionItem.typings";

import styles from "./SubscriptionItem.module.css";

import { ReactComponent as ArrowIcon } from "./images/arrow-icon.svg";

export const SubscriptionItem = ({
    id,
    name,
    schoolClass,
    subscriptions,
    isAccordion,
    isOpen,
    isStudent,
    isUnderage,
}: SubscriptionItemProps) => {
    const { hasActiveParents } = getAccountCookie();

    const navigate = useNavigate();

    const [isActive, setIsActive] = useState(isOpen ?? false);

    return (
        <div className={styles.container}>
            <button
                className={clsx(styles.heading, isAccordion && styles.toggle)}
                type="button"
                onClick={
                    isAccordion
                        ? () => setIsActive((active) => !active)
                        : undefined
                }
            >
                <span className={styles.name}>{name}</span>
                <span className={styles.class}>
                    (KLASA {romanize(parseInt(schoolClass))})
                </span>
                {isAccordion && (
                    <motion.span
                        className={styles.icon}
                        {...getIconAnimations(isActive)}
                    >
                        <ArrowIcon />
                    </motion.span>
                )}
            </button>
            <AnimatePresence mode="wait" initial={false}>
                {((isAccordion && isActive) || !isAccordion) && (
                    <motion.div
                        className={styles.collapse}
                        {...collapseAnimations}
                    >
                        <div className={styles.body}>
                            <SubscriptionPackages
                                title="ABONAMENT"
                                subscriptions={subscriptions}
                            />
                            <div className={styles.buttons}>
                                {isStudent && hasActiveParents && (
                                    <div className={styles.button}>
                                        <MidgetButton
                                            onClick={() =>
                                                navigate(
                                                    "/dashboard/student/store"
                                                )
                                            }
                                        >
                                            POPROŚ O ABONAMENT
                                        </MidgetButton>
                                    </div>
                                )}
                                {!isUnderage && (
                                    <div className={styles.button}>
                                        <MidgetButton
                                            onClick={() =>
                                                navigate(
                                                    isStudent
                                                        ? "/dashboard/student/store"
                                                        : `/dashboard/parent/store/${id}`
                                                )
                                            }
                                        >
                                            DODAJ/PRZEDŁUŻ
                                        </MidgetButton>
                                    </div>
                                )}
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};
