type ReturnType = {
    NAZWA: string;
    GMI: string;
};

export const parseCityName = (name: string): ReturnType => {
    const bracketPosition = name.indexOf("(");

    const NAZWA = name.slice(0, bracketPosition - 1);
    const GMI = name.slice(bracketPosition + 1, name.length - 1);

    return {
        NAZWA,
        GMI,
    };
};
