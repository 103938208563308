import { useState, useEffect } from "react";
import {
    Navigate,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { useRedirectContext } from "../../../../contexts/RedirectContext";

import { useMedia, useRedirectByPop } from "../../../../hooks";
import { useAiNewSession, useAiSessionResult } from "../../hooks";

import {
    BackgroundContainer,
    TaskContainer,
} from "../../../../components/containers";
import { DashboardHeading } from "../../../../components/headings";
import { NavigationBar, TaskItem } from "../../components";
import { DefaultButton } from "../../../../components/buttons";
import { CircleLoader, DottedLoader } from "../../../../components/loaders";

import { createGridRepeat, getPreviousPath } from "../../../../utils";
import { getAiSessionDescription } from "../../utils";
import { studySettingsQuery } from "../../../competencyTest/utils";

import styles from "./AiSessionResult.module.css";

import infinity from "../../../../assets/images/robots/infinity-student.svg";

type LocationType = {
    pathname: string;
    state: boolean | null;
};

export const AiSessionResult = () => {
    const { isDesktop, isSmallerDesktop } = useMedia();

    const { setRedirect } = useRedirectContext();

    const queryClient = useQueryClient();
    const { pathname, state }: LocationType = useLocation();
    const params = useParams();
    const navigate = useNavigate();

    const { data: aiSessionResultData } = useAiSessionResult(
        params.sessionId as string
    );
    const { mutate: setAiNewSession } = useAiNewSession();

    const [isSessionLoading, setIsSessionLoading] = useState(false);

    const handleNewSession = () => {
        setIsSessionLoading(true);

        setAiNewSession(undefined, {
            onError: () => {
                setIsSessionLoading(false);
            },
        });
    };

    const prefetchStudySettings = async () => {
        await queryClient.prefetchQuery(studySettingsQuery());

        navigate(backwardPathname);
    };

    const backwardPathname = getPreviousPath(pathname, 3);

    useRedirectByPop(backwardPathname);

    useEffect(() => {
        setRedirect(backwardPathname);
    }, [backwardPathname, setRedirect]);

    if (!params.sessionId || state !== true) {
        return <Navigate to={"/dashboard/student"} replace={true} />;
    }

    return (
        <BackgroundContainer
            rightElement={{
                image: infinity,
                size: {
                    width: 224,
                    height: 467,
                },
                position: {
                    x: isSmallerDesktop ? -30 : -128,
                    y: -56,
                },
            }}
        >
            <TaskContainer
                footerElement={
                    !isDesktop ? (
                        <img className={styles.robot} src={infinity} alt="" />
                    ) : undefined
                }
            >
                <DashboardHeading
                    title="PODSUMOWANIE SESJI"
                    subtitle={
                        !isDesktop
                            ? aiSessionResultData
                                ? getAiSessionDescription(
                                      aiSessionResultData.data
                                          .percentage_result,
                                      isDesktop
                                  )
                                : "Trwa wczytywanie"
                            : undefined
                    }
                    to={backwardPathname}
                    hasBorder={true}
                    hasSmallSpacing={!isDesktop}
                />
                {aiSessionResultData?.data ? (
                    <>
                        {isDesktop && (
                            <h4 className={styles.title}>
                                {getAiSessionDescription(
                                    aiSessionResultData.data.percentage_result,
                                    isDesktop
                                )}
                            </h4>
                        )}
                        <div className={styles.tasks}>
                            <div
                                className={styles.grid}
                                style={{
                                    gridTemplateRows: createGridRepeat(
                                        aiSessionResultData.data.exercises
                                            .length > 0
                                            ? aiSessionResultData.data.exercises
                                                  .length
                                            : 1
                                    ),
                                }}
                            >
                                {aiSessionResultData.data.exercises.map(
                                    (exercise, index) => {
                                        const isSuccess =
                                            exercise.answered_right === "1";
                                        const isError =
                                            exercise.answered_right === "0";

                                        return (
                                            <div
                                                key={
                                                    exercise.id_exercise +
                                                    "_" +
                                                    index
                                                }
                                                className={styles.item}
                                            >
                                                <TaskItem
                                                    to={undefined}
                                                    level={exercise.difficulty}
                                                    isExam={true}
                                                    isSuccess={isSuccess}
                                                    isError={isError}
                                                >
                                                    {`Zadanie ${index + 1}`}
                                                </TaskItem>
                                            </div>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                        <div className={styles.buttons}>
                            <DefaultButton
                                wrapper={styles.button}
                                initial={{
                                    background: DefaultButton.color.white,
                                    color: DefaultButton.color.enabled,
                                    border: `1px solid ${DefaultButton.color.enabled}`,
                                }}
                                animate={{
                                    background: DefaultButton.color.white,
                                    color: DefaultButton.color.enabled,
                                    border: `1px solid ${DefaultButton.color.enabled}`,
                                }}
                                whileHover={{ opacity: 0.7 }}
                                isDisabled={isSessionLoading}
                                onClick={prefetchStudySettings}
                            >
                                STRONA GŁÓWNA
                            </DefaultButton>
                            <DefaultButton
                                wrapper={styles.button}
                                whileHover={{ opacity: 0.7 }}
                                isDisabled={isSessionLoading}
                                onClick={handleNewSession}
                            >
                                {isSessionLoading ? (
                                    <DottedLoader color="white" />
                                ) : (
                                    "KOLEJNA SESJA"
                                )}
                            </DefaultButton>
                        </div>
                    </>
                ) : (
                    <div className={styles.loading}>
                        <p>Trwa wczytywanie danych rozwiązanej sesji</p>
                        <CircleLoader />
                    </div>
                )}
                <NavigationBar
                    backward={{
                        children: "WRÓĆ",
                        onClick: prefetchStudySettings,
                    }}
                />
            </TaskContainer>
        </BackgroundContainer>
    );
};
