import {
    useState,
    useEffect,
    useCallback,
    createContext,
    useContext,
} from "react";

import { parseMathtypeAnswer } from "../utils";

import { AnswerType } from "../typings";

type Props = {
    answers: AnswerType[];
    children: React.ReactNode;
};

type ProvidedAnswersArguments = {
    parseMatch?: boolean;
};

type ContextType = {
    provideAnswer: (answer: ProvidedAnswerType) => void;
    removeProvidedAnswer: (id: string) => void;
    getProvidedAnswers: (
        options?: ProvidedAnswersArguments
    ) => Omit<ProvidedAnswerType, "type">[];
    getProvidedAnswer: (id: string) => any | undefined;
    removeProvidedAnswers: () => void;
    isAnswersValid: boolean;
};

type ProvidedAnswerType = {
    id: string;
    type: AnswerType["type"];
    value: any;
};

const AnswersContext = createContext<ContextType | null>(null);

export const AnswersProvider = ({ answers, children }: Props) => {
    const answersToProvide = answers.filter((answer) => answer.type !== "text");
    const [providedAnswers, setProvidedAnswers] = useState<
        ProvidedAnswerType[] | []
    >([]);
    const [isAnswersValid, setIsAnswersValid] = useState(false);

    const provideAnswer = useCallback(
        (answer: ProvidedAnswerType) =>
            setProvidedAnswers((providedAnswers) => {
                if (
                    providedAnswers.some(
                        (providedAnswer) => providedAnswer.id === answer.id
                    )
                ) {
                    const clearedAnswers = providedAnswers.filter(
                        (providedAnswer) => providedAnswer.id !== answer.id
                    );

                    return [...clearedAnswers, answer];
                } else {
                    if (providedAnswers.length > 0) {
                        return [...providedAnswers, answer];
                    } else {
                        return [answer];
                    }
                }
            }),
        []
    );

    const removeProvidedAnswer = useCallback(
        (id: string) =>
            setProvidedAnswers((providedAnswers) =>
                providedAnswers.filter(
                    (providedAnswer) => providedAnswer.id !== id
                )
            ),
        []
    );

    const removeProvidedAnswers = useCallback(() => setProvidedAnswers([]), []);

    const getProvidedAnswers = useCallback(
        (options?: ProvidedAnswersArguments) =>
            providedAnswers.map((providedAnswer) => ({
                id: providedAnswer.id,
                value:
                    options?.parseMatch && providedAnswer.type === "match"
                        ? providedAnswer.value.map(
                              (providedAnswerValue: {
                                  id: string;
                                  answer: { id: string; content: string };
                              }) => ({
                                  id: providedAnswerValue.id,
                                  answer: providedAnswerValue.answer.content,
                              })
                          )
                        : providedAnswer.type === "open"
                        ? parseMathtypeAnswer(providedAnswer.value)
                        : providedAnswer.value,
            })),
        [providedAnswers]
    );

    const getProvidedAnswer = useCallback(
        (id: string): any | undefined => {
            const answers = getProvidedAnswers();
            const answer = answers.find((answer) => id === answer.id);

            return answer ? answer.value : undefined;
        },
        [getProvidedAnswers]
    );

    useEffect(() => {
        if (providedAnswers.length === answersToProvide.length) {
            if (
                answersToProvide.some(
                    (answerToProvide) => answerToProvide.type === "match"
                )
            ) {
                let isValid = true;

                answersToProvide.forEach((answerToProvide) => {
                    if (answerToProvide.type === "match") {
                        providedAnswers.every((providedAnswer) => {
                            if (providedAnswer.id === answerToProvide.id) {
                                if (
                                    providedAnswer.value.length ===
                                    answerToProvide.row_answers.length
                                ) {
                                    return true;
                                } else {
                                    isValid = false;

                                    return false;
                                }
                            } else {
                                return true;
                            }
                        });
                    }
                });

                if (isValid) {
                    setIsAnswersValid(true);
                } else {
                    setIsAnswersValid(false);
                }
            } else {
                setIsAnswersValid(true);
            }
        } else if (isAnswersValid) {
            setIsAnswersValid(false);
        }
    }, [answersToProvide, providedAnswers, isAnswersValid]);

    return (
        <AnswersContext.Provider
            value={{
                provideAnswer,
                removeProvidedAnswer,
                getProvidedAnswer,
                getProvidedAnswers,
                removeProvidedAnswers,
                isAnswersValid,
            }}
        >
            {children}
        </AnswersContext.Provider>
    );
};

export const useAnswersContext = () =>
    useContext(AnswersContext) as ContextType;
