type Props = {
    isAlt?: boolean;
};

export const GoIcon = ({ isAlt }: Props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={isAlt ? "12" : "22"}
        height={isAlt ? "16" :"30"}
        viewBox="0 0 22 30"
    >
        <path
            id="Icon_material-directions-run"
            data-name="Icon material-directions-run"
            d="M18.819,7.8a2.791,2.791,0,1,0-2.733-2.791A2.77,2.77,0,0,0,18.819,7.8ZM13.9,27.2l1.366-6.14,2.87,2.791V32.22h2.733V21.755L18,18.964l.82-4.186a9.92,9.92,0,0,0,7.516,3.488V15.476a6.7,6.7,0,0,1-5.876-3.349L19.093,9.894A2.808,2.808,0,0,0,16.77,8.5c-.41,0-.683.14-1.093.14l-7.106,3.07v6.558H11.3V13.522l2.46-.977-2.186,11.3-6.7-1.4-.547,2.791L13.9,27.2Z"
            transform="translate(-4.335 -2.22)"
            fill={isAlt ? "#2eafbe" : "#fff"}
        />
    </svg>
);
