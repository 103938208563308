import { mainApi } from "../../../../api";

const key = "taskTheory";

export const taskTheoryQuery = (id?: string, userClass?: string) => ({
    queryKey: id ? [key, id] : [key],
    queryFn: async () =>
        mainApi.post(`get_exercise_theory/${id}`, {
            class: userClass,
        }),
});
