export const getTestSummaryDescription = (score: number) => {
    if (score < 20) {
        return "Twój wynik testu nie jest wysoki, ale systematyczna nauka pozwoli Ci nadrobić zaległości, stwórz swój plan w kolejnych krokach.";
    } else if (score < 39.99999999) {
        return "Masz dobre podstawy żeby iść dalej, opanuj pozostałą część materiału i zaplanuj naukę w kolejnych krokach.";
    } else if (score < 59.99999999) {
        return "Masz dobre podstawy aby iść dalej i być naprawdę dobrym z matmy, zaplanuj naukę w kolejnych krokach.";
    } else if (score < 79.99999999) {
        return "Dobra robota! Ruszaj dalej i zaplanuj naukę matematyki w kolejnych krokach.";
    } else {
        return "Świetnie Ci poszło, masz szansę na opanowanie całego materiału z matematyki, zaplanuj naukę w kolejnych krokach.";
    }
};
