import { CircleLoaderProps, GetSizeStyles } from "../CircleLoader.typings";

export const getSize = (
    size: CircleLoaderProps["size"],
    styles: GetSizeStyles
) => {
    switch (size) {
        case "18":
            return styles.size18;
        case "24":
            return styles.size24;
        default:
            return null;
    }
};
