import { CircleLoaderProps, GetColorStyles } from "../CircleLoader.typings";

export const getColor = (color: CircleLoaderProps['color'], styles:GetColorStyles) => {
    switch (color) {
        case "red":
            return styles.red;
        case "white":
            return styles.white;
        default:
            return null;
    }
};
