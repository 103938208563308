import {
    CoinIcon,
    KeyIcon,
    LibraIcon,
    PeopleIcon,
    PersonIcon,
    TrashIcon,
} from "../icons";

import { NavigationAccountIcons } from "../../../typings";

export const getIcon = (
    icon: NavigationAccountIcons,
    isActive?: boolean,
    isImportant?: boolean
) => {
    switch (icon) {
        case "person":
            return <PersonIcon isActive={isActive} isImportant={isImportant} />;
        case "people":
            return <PeopleIcon isActive={isActive} isImportant={isImportant} />;
        case "coin":
            return <CoinIcon isActive={isActive} isImportant={isImportant} />;
        case "libra":
            return <LibraIcon isActive={isActive} isImportant={isImportant} />;
        case "key":
            return <KeyIcon isActive={isActive} isImportant={isImportant} />;
        case "trash":
            return <TrashIcon isActive={isActive} isImportant={isImportant} />;
    }
};
