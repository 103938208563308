import { Link } from "react-router-dom";

import styles from "./NotificationSubscription.module.css";

import { ReactComponent as ExclamationIcon } from "./images/exclamation-icon.svg";

type Props = {
    children: React.ReactNode;
    isSecondLineDisabled?: boolean;
};

export const NotificationSubscription = ({
    children,
    isSecondLineDisabled,
}: Props) => {
    return (
        <div className={styles.container}>
            <div className={styles.icon}>
                <ExclamationIcon />
            </div>
            <div className={styles.content}>
                <p>{children}</p>
                {!isSecondLineDisabled && (
                    <p>
                        Aby uzyskać pełny dostęp przejdź do zakładki{" "}
                        <Link to={"/dashboard/student/account/subscription"}>
                            ABONAMENTY
                        </Link>
                        .
                    </p>
                )}
            </div>
        </div>
    );
};
