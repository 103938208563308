import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useLocation, useNavigate } from "react-router-dom";

import { mainApi } from "../../../../api";

import { setTaskParams } from "../../services";

import { getPreviousPath } from "../../../../utils";
import { taskSlug } from "../../utils";

type Response = {
    next_exercise_id: number;
    category_id: string;
    subcategory_id: string;
    class: string;
};

type Mutation = {
    taskId: string;
    categoryId: string;
    subcategoryId: string;
    classType: string;
};

export const useGetNextLearningProgressTask = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return useMutation<AxiosResponse<Response, any>, any, Mutation, unknown>(
        (data) =>
            mainApi.post(
                "user_subcategory_learning_progress_next_exercise_id",
                {
                    current_exercise_id: data.taskId,
                    category_id: data.categoryId,
                    subcategory_id: data.subcategoryId,
                    class: data.classType,
                }
            ),

        {
            onSuccess: (data) => {
                const taskId = data.data.next_exercise_id;
                const categoryId = data.data.category_id;
                const subcategoryId = data.data.subcategory_id;
                const classType = data.data.class;

                if (taskId !== 0) {
                    setTaskParams({
                        type: taskSlug.learning_progress,
                        taskId: taskId.toString(),
                        additionalId: `${categoryId}/${subcategoryId}/${classType}`,
                    });

                    navigate(
                        `${getPreviousPath(pathname, 3)}/task/${
                            taskSlug.learning_progress
                        }/${taskId}`
                    );
                } else {
                    navigate(
                        `${getPreviousPath(
                            pathname,
                            3
                        )}/learning-progress/${classType}/${categoryId}/${subcategoryId}`
                    );
                }
            },
        }
    );
};
