import { useUserSavedCards } from "../../hooks";

import {
    ObjectSelectInput,
    PaymentInput,
    SelectValue,
} from "../../../../components/inputs";
import { DefaultButton } from "../../../../components/buttons";
import { ButtonLoader } from "../../../../components/utils";

import {
    getCardNumberValue,
    getCardNumberResponse,
    getCardType,
    parseValidThru,
    getCommonResponse,
    getFormValueFromCardData,
    getDataFromSelectValue,
    setFormDataBySelect,
    getSelectValueFromData,
    parseCvcValue,
} from "./utils";

import styles from "./StorePaymentForm.module.css";

import { StorePaymentFormProps } from "./StorePayment.typings";

export const StorePaymentForm = ({
    data,
    setData,
    savedId,
    setSavedId,
    isValid,
    isLoading,
    isSubmitting,
    onSubmit,
    onCancel,
}: StorePaymentFormProps) => {
    const { data: cards } = useUserSavedCards();

    const cardType = getCardType(data.number.value);

    const handleSelectChange = (object: SelectValue<string, string>) => {
        if (object.value !== "") {
            const data = getDataFromSelectValue(object.value);

            setSavedId(data.id);

            setFormDataBySelect(setData, data);
        } else {
            setSavedId(undefined);

            setFormDataBySelect(setData, undefined);
        }
    };

    return (
        <div className={styles.container}>
            {cards && cards.data.length > 0 && (
                <div className={styles.select}>
                    <ObjectSelectInput
                        value={getSelectValueFromData(savedId, data)}
                        selectValues={[
                            {
                                label: "Nowa karta",
                                value: "",
                            },
                            ...cards.data.map((card) => ({
                                label: `**** **** **** ${card.card_last_digits}`,
                                value: getFormValueFromCardData(card),
                            })),
                        ]}
                        label="Wybierz kartę z listy"
                        onChange={handleSelectChange}
                    />
                </div>
            )}
            <PaymentInput
                value={data.number.value}
                label="Numer karty"
                max={19}
                response={data.number.response}
                isDisabled={isLoading || data.number.response === "saved"}
                isDimmed={data.number.response === "saved"}
                onChange={(value) => {
                    setData((data) => ({
                        ...data,
                        cvc: {
                            value: "",
                            response: "",
                        },
                        number: {
                            value: getCardNumberValue(value),
                            response: getCardNumberResponse(value, 16),
                        },
                    }));
                }}
            />
            <PaymentInput
                value={data.owner.value}
                label="Właściciel"
                response={data.owner.response}
                isDisabled={isLoading || data.owner.response === "saved"}
                isDimmed={data.owner.response === "saved"}
                onChange={(value) =>
                    setData((data) => ({
                        ...data,
                        owner: {
                            value,
                            response: getCommonResponse(value, 3),
                        },
                    }))
                }
            />
            <div className={styles.grid}>
                <PaymentInput
                    value={data.validThru.value}
                    label="MM/RR"
                    max={5}
                    response={data.validThru.response}
                    isDisabled={isLoading || data.cvc.response === "saved"}
                    isDimmed={data.cvc.response === "saved"}
                    onChange={(value) =>
                        setData((data) => ({
                            ...data,
                            validThru: {
                                value: parseValidThru(value),
                                response: getCommonResponse(
                                    parseValidThru(value),
                                    5
                                ),
                            },
                        }))
                    }
                />
                <PaymentInput
                    value={data.cvc.value}
                    label="CVC"
                    max={cardType ? cardType.code.size : undefined}
                    response={data.cvc.response}
                    isDisabled={
                        getCardNumberResponse(data.number.value, 16) !==
                            "success" ||
                        isLoading ||
                        data.cvc.response === "saved"
                    }
                    isDimmed={
                        getCardNumberResponse(data.number.value, 16) !==
                            "success" || data.cvc.response === "saved"
                    }
                    onChange={(value) =>
                        setData((data) => ({
                            ...data,
                            cvc: {
                                value: parseCvcValue(value),
                                response: getCommonResponse(
                                    parseCvcValue(value),
                                    cardType ? cardType.code.size : 0
                                ),
                            },
                        }))
                    }
                />
            </div>
            <DefaultButton
                initial={{
                    backgroundColor: DefaultButton.color.disabled,
                }}
                animate={{
                    backgroundColor: !isValid
                        ? DefaultButton.color.disabled
                        : DefaultButton.color.enabled,
                }}
                whileHover={{
                    opacity: 0.7,
                }}
                transition={{
                    type: "tween",
                    duration: 0.3,
                }}
                isDisabled={!isValid || isLoading}
                onClick={onSubmit}
            >
                <ButtonLoader condition={isSubmitting} color="white">
                    POTWIERDŹ
                </ButtonLoader>
            </DefaultButton>
            <DefaultButton
                wrapper={styles.cancelButton}
                isDisabled={isLoading}
                onClick={onCancel}
            >
                WRÓĆ
            </DefaultButton>
        </div>
    );
};
