import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CircleLoader } from "../../../../components/loaders";
import { getIsMobile } from "../../../../utils";
import { useReadUserNotification } from "../../hooks";
import { getPathFromUrl } from "../../utils";
import {
    EnvelopeIcon,
    ExclamationIcon,
    GoIcon,
    QuestionIcon,
} from "../icon-components";
import { NotificationSlideProps } from "./NotificationSlide.typings";
import {
    disableActionClick,
    resetSlideTransform,
    setSlideAction,
    setSlideTransform,
} from "./NotificationSlide.utils";

import styles from "./NotificationSlide.module.css";

const isMobile = getIsMobile();

export const NotificationSlide = ({
    id,
    type,
    body,
    url,
    createdAt,
    queryKeys,
    isLoading,
    isRedirecting,
    setIsOpen,
    setIsRedirecting,
}: NotificationSlideProps) => {
    const navigate = useNavigate();

    const readUserNotification = useReadUserNotification(queryKeys);

    const isCritical = type === "Krytyczne" ? true : false;
    const isRead =
        readUserNotification.isLoading || readUserNotification.isSuccess;
    const [isGoToExecuted, setIsGoToExecuted] = useState(false);

    const contentRef = useRef<HTMLElement>(null);
    const goToSlideRef = useRef<HTMLElement>(null);
    const markAsReadSlideRef = useRef<HTMLElement>(null);

    const isDragging = useRef(false);
    const isDropping = useRef(false);
    const isClickDisabled = useRef(false);
    const isActionEnabled = useRef(false);

    const dragPositionStart = useRef(0);
    const dragActionType = useRef("");

    const handleGoTo = useCallback(() => {
        if (url) {
            const pathFromUrl = getPathFromUrl(url);

            setIsGoToExecuted(true);

            if (pathFromUrl.isInsideApp) {
                navigate(pathFromUrl.url);

                setIsRedirecting(true);
            } else {
                window.open(pathFromUrl.url, "_blank");

                setIsOpen(false);
            }
        }

        readUserNotification.mutate({ id });
    }, [id, navigate, readUserNotification, setIsOpen, setIsRedirecting, url]);

    const handleDragStart = (
        event:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.TouchEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation();

        if (isDragging.current || isDropping.current) return;

        if (event.nativeEvent instanceof MouseEvent) {
            dragPositionStart.current = event.nativeEvent.pageX;
        } else {
            if (event.nativeEvent instanceof TouchEvent) {
                dragPositionStart.current = event.nativeEvent.touches[0].pageX;
            }
        }

        isDragging.current = true;
    };

    const handleDrag = (
        event: MouseEvent | React.TouchEvent<HTMLButtonElement>
    ) => {
        event.stopPropagation();

        if (!isDragging.current || isDropping.current) return;

        const contentElement = contentRef.current as HTMLElement;
        const goToSlideElement = goToSlideRef.current as HTMLElement;
        const markAsReadSlideElement =
            markAsReadSlideRef.current as HTMLElement;

        const dragWidth = contentElement.clientWidth;
        let dragPositionCurrent = 0;

        if (event instanceof MouseEvent) {
            const dragPosition = event.pageX - dragPositionStart.current;

            disableActionClick(isClickDisabled, dragPosition);

            dragPositionCurrent = +((dragPosition * 100) / dragWidth).toFixed(
                4
            );
        } else {
            if (event.nativeEvent instanceof TouchEvent) {
                const dragPosition =
                    event.touches[0].pageX - dragPositionStart.current;

                disableActionClick(isClickDisabled, dragPosition);

                dragPositionCurrent = +(
                    (dragPosition * 100) /
                    dragWidth
                ).toFixed(4);
            }
        }

        if (dragPositionCurrent >= 100) {
            dragPositionCurrent = 100;
        } else if (dragPositionCurrent <= -100) {
            dragPositionCurrent = -100;
        }

        setSlideTransform(contentElement, dragPositionCurrent);

        if (dragPositionCurrent > 0) {
            setSlideTransform(goToSlideElement, dragPositionCurrent);

            resetSlideTransform(markAsReadSlideElement);

            setSlideAction(
                goToSlideElement,
                styles.active,
                dragPositionCurrent,
                isActionEnabled
            );

            dragActionType.current = "goTo";
        } else {
            setSlideTransform(markAsReadSlideElement, dragPositionCurrent);

            resetSlideTransform(goToSlideElement);

            setSlideAction(
                markAsReadSlideElement,
                styles.active,
                dragPositionCurrent * -1,
                isActionEnabled
            );

            dragActionType.current = "markAsRead";
        }
    };

    const handleDragEnd = useCallback(
        (event: MouseEvent | React.TouchEvent<HTMLButtonElement>) => {
            event.stopPropagation();

            if (!isDragging.current || isDropping.current) return;

            const contentElement = contentRef.current as HTMLElement;
            const goToSlideElement = goToSlideRef.current as HTMLElement;
            const markAsReadSlideElement =
                markAsReadSlideRef.current as HTMLElement;

            isDragging.current = false;
            isDropping.current = true;

            if (dragActionType.current === "goTo") {
                if (isActionEnabled.current) {
                    setSlideTransform(contentElement, 100, true);
                    setSlideTransform(goToSlideElement, 100, true);
                } else {
                    setSlideTransform(contentElement, 0, true);
                    setSlideTransform(goToSlideElement, 0, true);
                }
            }

            if (dragActionType.current === "markAsRead") {
                if (isActionEnabled.current) {
                    setSlideTransform(contentElement, -100, true);
                    setSlideTransform(markAsReadSlideElement, -100, true);
                } else {
                    setSlideTransform(contentElement, 0, true);
                    setSlideTransform(markAsReadSlideElement, 0, true);
                }
            }

            setTimeout(() => {
                isDropping.current = false;

                if (isActionEnabled.current) {
                    if (dragActionType.current === "goTo") {
                        handleGoTo();
                    }

                    if (dragActionType.current === "markAsRead") {
                        readUserNotification.mutate({
                            id,
                        });
                    }
                }
            }, 300);
        },
        [readUserNotification, id, handleGoTo]
    );

    const handleClick = () => {
        if (isClickDisabled.current) return;

        handleGoTo();
    };

    useEffect(() => {
        if (!isMobile) {
            document.addEventListener("mousemove", handleDrag);
            document.addEventListener("mouseup", handleDragEnd);
        }

        return () => {
            if (!isMobile) {
                document.removeEventListener("mousemove", handleDrag);
                document.removeEventListener("mouseup", handleDragEnd);
            }
        };
    }, [handleDragEnd]);

    return (
        <button
            className={clsx(
                styles.button,
                isCritical && styles.critical,
                isRead && !isClickDisabled.current && styles.disabled
            )}
            type="button"
            disabled={isRead || isLoading || isRedirecting}
            onMouseDown={!isMobile ? handleDragStart : undefined}
            onTouchStart={isMobile ? handleDragStart : undefined}
            onTouchMove={isMobile ? handleDrag : undefined}
            onTouchEnd={isMobile ? handleDragEnd : undefined}
            onClick={handleClick}
        >
            <span className={styles.content} ref={contentRef}>
                <span className={styles.icon}>
                    {isCritical ? <ExclamationIcon /> : <QuestionIcon />}
                </span>
                <span className={styles.text}>{body}</span>
                <b className={styles.text}>{createdAt}</b>
            </span>
            <span
                className={clsx(styles.slide, styles.goTo)}
                ref={goToSlideRef}
            >
                <span className={styles.slideBody}>
                    <span className={styles.goToText}>
                        {(isLoading && isGoToExecuted) ||
                        (isRedirecting && isGoToExecuted)
                            ? "WCZYTYWANIE"
                            : "PRZEJDŹ DO"}
                    </span>
                    <span className={styles.goToIcon}>
                        {(isLoading && isGoToExecuted) ||
                        (isRedirecting && isGoToExecuted) ? (
                            <CircleLoader size="18" color="white" />
                        ) : (
                            <GoIcon />
                        )}
                    </span>
                </span>
            </span>
            <span
                className={clsx(styles.slide, styles.markAsRead)}
                ref={markAsReadSlideRef}
            >
                <span className={styles.slideBody}>
                    <span className={styles.markAsReadIcon}>
                        <EnvelopeIcon />
                    </span>
                    <span className={styles.markAsReadText}>
                        OZNACZ JAKO PRZECZYTANE
                    </span>
                </span>
            </span>
        </button>
    );
};
