import { getFillFields } from "./getFillFields";
import { setDropPosition } from "./setDropPosition";
import { setFixedPointerPosition } from "./setFixedPointerPosition";

export const getDropId = (
    dataName: string,
    event: MouseEvent | TouchEvent | PointerEvent
) => {
    const fields = getFillFields(dataName);
    const fieldsFoundToDrop: { field: HTMLElement; y: number }[] = [];

    fields.forEach((field) => {
        const mouseEvent = event as MouseEvent;

        const fieldElement = field as HTMLElement;
        const eventElement = event.target as HTMLElement;

        const elementHeight = 42;
        const elementWidth = fieldElement.offsetWidth;

        if (eventElement.nodeName === "#document") return;

        const fieldElementBounds = fieldElement.getBoundingClientRect();
        const eventElementBounds = eventElement.getBoundingClientRect();

        const pointerPosition = {
            x: mouseEvent.clientX - eventElementBounds.x,
            y: mouseEvent.clientY - eventElementBounds.y,
        };

        const fixedPointerPosition = {
            x: setFixedPointerPosition(pointerPosition, "x", elementWidth),
            y: setFixedPointerPosition(pointerPosition, "y", elementHeight),
        };

        const dropPosition = setDropPosition(
            mouseEvent,
            fieldElementBounds,
            fixedPointerPosition
        );

        if (
            elementWidth * 0.9 > dropPosition.x &&
            elementWidth * -0.9 < dropPosition.x &&
            elementHeight * 0.8 > dropPosition.y &&
            elementHeight * -0.8 < dropPosition.y
        ) {
            fieldsFoundToDrop.push({
                field: fieldElement,
                y:
                    dropPosition.y < 0
                        ? elementHeight - dropPosition.y * -1
                        : elementHeight - dropPosition.y,
            });

            if (fieldsFoundToDrop.length > 1) {
                fieldsFoundToDrop.sort((a, b) => (a.y > b.y ? b.y : a.y));
            }
        }
    });

    return fieldsFoundToDrop.length > 0
        ? fieldsFoundToDrop[0].field.getAttribute(`data-${dataName}`) as string
        : undefined;
};
