import { SubscriptionItem } from "../SubscriptionItem";

import { SubscriptionsProps } from "./Subscriptions.typings";

import styles from "./Subscriptions.module.css";

export const Subscriptions = ({
    subs,
    isStudent,
    isUnderage,
}: SubscriptionsProps) => {
    return subs && subs.length > 0 ? (
        <div className={styles.container}>
            {subs.map((sub) => (
                <SubscriptionItem
                    key={sub.id}
                    id={sub.id}
                    name={sub.name}
                    schoolClass={sub.schoolClass}
                    subscriptions={sub.subscriptions}
                    isAccordion={subs.length > 1}
                    isOpen={sub.isOpen}
                    isStudent={isStudent}
                    isUnderage={isUnderage}
                />
            ))}
        </div>
    ) : (
        <p>Brak dzieci</p>
    );
};
