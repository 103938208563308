import { createContext, useContext, useState, useCallback } from "react";
import { QueryClient } from "@tanstack/react-query";

import { AppLoading } from "../components/utils";

type LoadingContextType = {
    setLoadingActive: (
        active: boolean,
        callback?: () => void,
        query?: [QueryClient, (string | number | undefined)[]]
    ) => void;
};

const LoadingContext = createContext<LoadingContextType | null>(null);

type Props = { children: JSX.Element };

const duration = {
    container: 300,
    element: 500,
};

export const LoadingProvider = ({ children }: Props) => {
    const [isActive, setIsActive] = useState(false);

    const setLoadingActive = useCallback(
        (
            active: Parameters<LoadingContextType["setLoadingActive"]>[0],
            callback?: Parameters<LoadingContextType["setLoadingActive"]>[1],
            query?: Parameters<LoadingContextType["setLoadingActive"]>[2]
        ) => {
            if (active) {
                setIsActive(true);

                if (typeof callback === "function") {
                    setTimeout(() => {
                        callback();
                    }, duration.container);
                }
            } else {
                if (typeof callback === "function") {
                    callback();
                }

                setTimeout(() => {
                    setIsActive(false);

                    if (query) {
                        query[0].removeQueries(query[1]);
                    }
                }, duration.element * 2 + 1000);
            }
        },
        []
    );

    return (
        <LoadingContext.Provider value={{ setLoadingActive }}>
            {children}
            <AppLoading duration={duration} isActive={isActive} />
        </LoadingContext.Provider>
    );
};

export const useLoadingContext = () =>
    useContext(LoadingContext) as LoadingContextType;
