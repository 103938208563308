import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { userAdvancementDataQuery } from "../../utils";

type Response = {
    advancement: string;
    exercises: string;
    topics: string;
    study_goal: string;
};

export const useUserAdvancementData = () => {
    return useQuery<AxiosResponse<Response, any>>(userAdvancementDataQuery());
};
