import { useState } from "react";
import { useNavigation } from "react-router-dom";

import { useMedia } from "../../../../hooks";
import { useCompetencyTestContext } from "../../hooks";
import {
    useGetToKnow,
    useSetCompetencyTestAsDone,
} from "../../hooks/mutations";

import { DefaultButton } from "../../../../components/buttons";
import { MainHeading } from "../../../../components/headings";
import { StepperWithTitle } from "../../components";
import { ButtonLoader } from "../../../../components/utils";

import { colors } from "../../utils";

import styles from "./Step4.module.css";

export const Step4 = () => {
    const { isDesktop } = useMedia();
    const { state } = useNavigation();

    const { form } = useCompetencyTestContext();

    const getToKnowMutation = useGetToKnow();
    const setCompetencyTestAsDoneMutation = useSetCompetencyTestAsDone(true);

    const [loadingRoute, setLoadingRoute] = useState("");

    const getClass = () => {
        if (form.scope === "full") {
            if (form.exam?.value) {
                return form.exam?.value;
            }

            return "8_kl";
        }
        return form.class?.value;
    };

    return (
        <div className={styles.step4}>
            <MainHeading elementType="h2" className={styles.heading}>
                Daj się poznać
            </MainHeading>
            {!isDesktop && (
                <p>
                    Dzięki Twoim odpowiedziom nasza Sztuczna Inteligencja
                    opracuje optymalny tok nauczania, żeby Twoja nauka była
                    najskuteczniejsza.
                </p>
            )}
            {isDesktop && (
                <StepperWithTitle
                    steps={[colors.filled, colors.filled, colors.active]}
                />
            )}
            <p>
                Aby jeszcze lepiej dopasować plan nauki do Twoich potrzeb,
                przejdź test który sprawdzi dokładnie aktualny poziom Twojej
                znajomości materiału.
            </p>
            <div className={styles.buttons}>
                <DefaultButton
                    isDisabled={
                        state === "loading" ||
                        getToKnowMutation.isLoading ||
                        setCompetencyTestAsDoneMutation.isLoading
                    }
                    onClick={() => {
                        setLoadingRoute("accept");

                        getToKnowMutation.mutate(
                            {
                                scope: form.scope!,
                                class: getClass()!,
                                grade: form.grade?.label!,
                                opinion: form.opinion?.label!,
                                redirect: true,
                            },
                            {
                                onError: () => setLoadingRoute(""),
                            }
                        );
                    }}
                >
                    <ButtonLoader
                        condition={
                            (getToKnowMutation.isLoading ||
                                state === "loading") &&
                            loadingRoute === "accept"
                        }
                        color="white"
                    >
                        ROZPOCZNIJ TEST
                    </ButtonLoader>
                </DefaultButton>
                <DefaultButton
                    initial={false}
                    animate={{
                        backgroundColor: DefaultButton.color.disabled,
                    }}
                    isDisabled={
                        state === "loading" ||
                        getToKnowMutation.isLoading ||
                        setCompetencyTestAsDoneMutation.isLoading
                    }
                    onClick={() => {
                        setLoadingRoute("decline");

                        getToKnowMutation.mutate(
                            {
                                scope: form.scope!,
                                class: getClass()!,
                                grade: form.grade?.label!,
                                opinion: form.opinion?.label!,
                                redirect: false,
                            },
                            {
                                onSuccess: () =>
                                    setCompetencyTestAsDoneMutation.mutate(),
                                onError: () => setLoadingRoute(""),
                            }
                        );
                    }}
                >
                    <ButtonLoader
                        condition={
                            (getToKnowMutation.isLoading ||
                                setCompetencyTestAsDoneMutation.isLoading ||
                                state === "loading") &&
                            loadingRoute === "decline"
                        }
                        color="white"
                    >
                        NIE CHCĘ ROBIĆ TESTU
                    </ButtonLoader>
                </DefaultButton>
            </div>
        </div>
    );
};
