import { QueryClient } from "@tanstack/react-query";
import { LoaderFunctionArgs } from "react-router-dom";

import { getAccountCookie } from "../../../../services";

import { learningProgressQuery, parentChildrensQuery } from "../../utils";

export const studentProgressParentLoader =
    (queryClient: QueryClient) =>
    async ({ params }: LoaderFunctionArgs) => {
        const { accountType } = getAccountCookie();

        const childrenId = params.childrenId;

        if (accountType !== "parent" || !childrenId) {
            return undefined;
        }

        const query = learningProgressQuery("other", childrenId);
        const query2 = parentChildrensQuery();

        const data = queryClient.getQueryData(query.queryKey);
        const data2 = queryClient.getQueryData(query2.queryKey);

        return data && data2
            ? { data, data2 }
            : (async () => {
                  await queryClient.fetchQuery(query);
                  await queryClient.fetchQuery(query2);

                  return null;
              })();
    };
