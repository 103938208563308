import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { mainApi } from "../../../../api";

import { setTaskParams } from "../../services";

import { useNotify } from "../../../../hooks";

import { taskSlug } from "../../utils";
import { getPreviousPath } from "../../../../utils";
import { useAiSession } from "./useAiSession";

type Response = {
    session_id: number;
};

export const useAiNewSession = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { mutate: setAiSession } = useAiSession();

    const notify = useNotify({ type: "error", position: "bottom-center" });

    return useMutation<AxiosResponse<Response, any>, any, void, unknown>(
        () => mainApi.get("ai_new_session"),
        {
            onSuccess: () => {
                setAiSession();
            },
        }
    );
};
