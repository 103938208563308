import clsx from "clsx";

import { DashboardHeading } from "../../../../../components/headings";

import styles from "./DashboardTermsOfUse.module.css";

type Props = { containerPadding?: boolean };
export const DashboardTermsOfUse = ({ containerPadding = true }: Props) => {
    return (
        <div className={styles.root}>
            <div
                className={clsx(
                    styles.container,
                    containerPadding && styles.padding
                )}
            >
                <DashboardHeading title="Regulamin" />
                <ol>
                    <li aria-level={1}>
                        <span>
                            Właścicielem i administratorem strony smarttutor.pl
                            oraz aplikacji “Smart Tutor Nauka matematyki” jest
                            SMART TUTOR sp. z.o.o., ul. Zagrodnicza 8a, 61-654
                            Poznań, NIP: 9721342304, KRS:&nbsp;0001051164,
                            REGON:&nbsp;526070720.&nbsp;
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Korzystając z aplikacji Smart Tutor akceptujesz
                            niniejszy regulamin.
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Właściciel zastrzega sobie prawo do zmiany zapisów
                            niniejszego regulaminu.&nbsp;
                        </span>
                    </li>
                </ol>
                <p>
                    <b>Konto</b>
                </p>
                <ol>
                    <li aria-level={1}>
                        <span>
                            Z jednego konta może korzystać jeden użytkownik.
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            W tym samym momencie na jednym koncie można
                            korzystać tylko na jednym urządzeniu. Próba
                            zalogowania się na dwóch urządzeniach jednocześnie
                            może spowodować automatyczne wylogowanie.
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Możliwe jest założenie kont o różnych uprawnieniach,
                            tj. konto ucznia, konto rodzica, konto nauczyciela.
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Podczas rejestracji wymagane jest podanie adresu
                            email, który wykorzystywany będzie w celach
                            związanych z działaniem aplikacji oraz wysyłaniem
                            związanych z nią informacji.
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Konto użytkownika jest chronione hasłem, które
                            użytkownik ustala samodzielnie podczas rejestracji.
                            Użytkownik jest odpowiedzialny za zachowanie
                            poufności swojego hasła i nie udostępnianie go
                            osobom trzecim.
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            W przypadku utraty hasła do konta, istnieje
                            możliwość jego odzyskania poprzez formularz w
                            aplikacji i potwierdzenie adresu email w swojej
                            skrzynce.&nbsp;
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Użytkownik w każdym momencie może usunąć swoje konto
                            w aplikacji w zakładce “Moje konto”.
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Administrator zastrzega sobie prawo do usunięcia
                            konta użytkownika w przypadku braku aktywności
                            (logowania) w okresie 12 miesięcy od ostatniego
                            logowania.&nbsp;
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Dostęp do aplikacji przyznawany jest automatycznie
                            po wpisaniu kody ektywacyjnego przesłanego na podany
                            podczas rejestracji adres email.&nbsp;
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            W przypadku problemów z rejestracją prosimy o
                            kontakt pod adresem email admin@smarttutor.pl
                        </span>
                    </li>
                </ol>
                <p>
                    <b>Konto ucznia</b>
                </p>
                <ol>
                    <li aria-level={1}>
                        <span>
                            Uczeń w wieku powyżej 13 lat może samodzielne
                            zalożyć konto w aplikacji Smart Tutor podając imię i
                            nazwisko, swój adres email, hasło, rok urodzenia,
                            rodzaj szkoły, klasę, miejscowość.&nbsp;
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            W przypadku osób, które nie ukończyły 13 roku życia
                            podczas rejestracji wymagane jest podanie adresu
                            email rodzica/opiekuna prawnego. Rodzic następnie
                            musi wyrazić zgodę na korzystanie z aplikacji przez
                            ucznia poniżej 13 roku życia.&nbsp;
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Uczeń po rejestracji może przypisać do swojego
                            konta, konto rodzica lub nauczyciela.
                        </span>
                    </li>
                </ol>
                <p>
                    <b>Konto rodzica/nauczyciela</b>
                </p>
                <ol>
                    <li aria-level={1}>
                        <span>
                            Rodzic i nauczyciel może powiązać ze swoim kontem,
                            konto ucznia.&nbsp;
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Rodzic może usunąć powiązanie nauczyciela ze swoim
                            dzieckiem.
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Rodzic i nauczyciel może usunąć powiązanie ucznia ze
                            swoim kontem.
                        </span>
                    </li>
                </ol>
                <p>
                    <b>Płatności</b>
                </p>
                <ol>
                    <li aria-level={1}>
                        <span>
                            Aplikacja w wersji demo/free jest bezpłatna.&nbsp;
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Wersja premium Aplikacji oraz moduł Rozwiązanie na
                            zawołanie jest płatny.&nbsp;
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Cennik poszczególnych modułów podany jest w
                            aplikacji.&nbsp;
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Zakupu można dokonać w aplikacji Smart Tutor- Nauka
                            matematyki.
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Płatności w aplikacji realizowane są natychmiastowo,
                            więc Użytkownik dostęp do aplikacji otrzymuje w
                            ciągu kilku minut od złożenia zamówienia.&nbsp;
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Obsługę płatności elektronicznych prowadzi Krajowy
                            Integrator Płatności S.A., ul.PL. WŁADYSŁAWA ANDERSA
                            3, 61-894 POZNAŃ, NIP: 7773061579, KRS: 0000412357
                        </span>
                    </li>
                </ol>
                <ol>
                    <li aria-level={1}>
                        <span>
                            Dostępne metody płatności: BLIK, Szybkie przelewy,
                            Karty Płatnicze
                        </span>
                    </li>
                </ol>
                <p>
                    <b>Reklamacje</b>
                </p>
                <ol>
                    <li aria-level={1}>
                        <span>
                            Użytkownikowi przysługuje prawo złożenia reklamacji.
                            W przypadku chęci złożenia reklamacji prosimy o
                            kontakt na adres: kontakt@smarttutor.pl
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Właściciel w odpowiedzi na wiadomość maksymalnie w
                            przeciągu 14 dni ustosunkuje się do złożonej
                            reklamacji
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            W przypadku wystąpienia awarii, ograniczonego
                            dostępu do Aplikacji nie przewiduje się
                            rekompensaty.
                        </span>
                    </li>
                </ol>
                <p>
                    <b>Odstąpienie od umowy</b>
                </p>
                <ol>
                    <li aria-level={1}>
                        <span>
                            Użytkownikowi zawierającemu umowę na odległość lub
                            umowę poza lokalem przedsiębiorstwa przysługuje
                            prawo odstąpienia od umowy w terminie 14 dni bez
                            podania przyczyny.&nbsp;
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Odstąpienie od umowy może nastąpić poprzez złożenie
                            elektronicznego oświadczenia o odstąpieniu od umowy
                            poprzez wysłanie wiadomości na adres
                            kontakt@smarttutor.pl lub pisemnie na adres Mały
                            Inżynier, ul. Zagrodnicza 8a, 61-654 Poznań.
                        </span>
                    </li>
                </ol>
                <p>
                    <b>Zwrot płatności</b>
                </p>
                <ol>
                    <li aria-level={1}>
                        <span>
                            W przypadku gdy Właściciel zobowiązany będzie do
                            zwrotu płatności, zostanie ona zwrócona maksymalnie
                            w przeciągu 14 dni.&nbsp;
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Zwrot środków nastąpi w ten sam sposób w jaki
                            dokonana została płatność, chyba że Użytkownik
                            wyrazi zgodę na inną formę zwrotu
                            należności&nbsp;&nbsp;
                        </span>
                    </li>
                </ol>
                <p>
                    <b>Prawa autorskie</b>
                </p>
                <ol>
                    <li aria-level={1}>
                        <span>
                            Prawa autorskie do aplikacji, przygotowanych i
                            zamieszczonych materiałów należą do Właściciela, tj.
                            Mały Inżynier. Zabrania się kopiowania,
                            przetwarzania i udostępniania osobom trzecim bez
                            pisemnej zgody Właściciela.&nbsp;
                        </span>
                    </li>
                </ol>
                <p>
                    <b>Newsletter</b>
                </p>
                <ol>
                    <li aria-level={1}>
                        <span>
                            Usługa newslettera świadczona jest bezpłatnie przez
                            czas nieoznaczony.&nbsp;
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Zapisanie się do newslettera następuje w momencie
                            akceptacji regulaminu i rejestracji w
                            Aplikacji.&nbsp;
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Użytkownik w każdym momencie może wypowiedzieć umowę
                            o świadczenie usługi newslettera poprzez kliknięcie
                            w odpowiedni link w przesłanej wiadomości.
                        </span>
                    </li>
                    <li aria-level={1}>
                        <span>
                            Usługa świadczona jest drogą elektroniczną z
                            wykorzystaniem podanego podczas rejestracji adresu
                            email. Podany adres może zostać wykorzystany do
                            wysyłki informacji edukacyjnych, handlowych,
                            reklamowych w ramach aplikacji Smart Tutor.
                        </span>
                    </li>
                </ol>
            </div>
        </div>
    );
};
