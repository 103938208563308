import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { orderBannerQuery } from "../../utils";

type Response = {
    title1: string;
    title2: string;
    desc: string;
    img: string;
    img_mobile: string;
};

export const useOrderBanner = () => {
    return useQuery<AxiosResponse<Response, any>>(orderBannerQuery());
};
