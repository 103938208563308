import * as yup from "yup";

import { passwordCompareMessage } from "../variables";

export const registerOthersSchema = yup.object({
    email: yup.string().email("* nieprawidłowy adres e-mail").required(" "),
    password: yup
        .string()
        .min(8, " ")
        .minLowercase(1, " ")
        .minUppercase(1, " ")
        .minNumbers(1, " ")
        // .minSymbols(1, " ")
        .required(" "),
    password_confirm: yup
        .string()
        .min(8, " ")
        .minLowercase(1, passwordCompareMessage)
        .minUppercase(1, passwordCompareMessage)
        .minNumbers(1, passwordCompareMessage)
        // .minSymbols(1, passwordCompareMessage)
        .oneOf([yup.ref("password")], passwordCompareMessage)
        .required(" "),
    city: yup.object({
        id: yup.string().required(" "),
        name: yup.string().required(" "),
    }),
    first_name: yup.string().min(2, " ").required(" "),
    last_name: yup.string().min(2, " ").required(" "),
    agreement: yup
        .boolean()
        .oneOf([true], "* wyrażenie zgody jest niezbędne")
        .required(" "),
});
