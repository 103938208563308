import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { Form, Formik } from "formik";
import { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { DefaultButton } from "../../../../components/buttons";
import { DashboardHeading, WideHeading } from "../../../../components/headings";
import {
    DefaultInput,
    DefaultTextarea,
    FileInput,
    ObjectSelectInput,
    SearchInput,
} from "../../../../components/inputs";
import { DottedLoader } from "../../../../components/loaders";
import { DefaultModal } from "../../../../components/modals";
import { useMedia } from "../../../../hooks";
import { getPreviousPath } from "../../../../utils";
import { userSubscriptionsQuery } from "../../../dashboard/utils";
import { NavigationBar } from "../../../tasks/components";
import { AnswerRequests } from "../../components";
import { useAddSolutionOnDemand } from "../../hooks/mutations";
import { useGetShortDescription } from "../../hooks/useGetShortDescription";
import { useUserHasSubscription } from "../../hooks/useUserHasSubscrption";
import { useGetSchoolHasBegun } from "../../hooks/useGetSchoolHasBegun";
import { FormFields } from "../../typings";
import { sourceOptions } from "../../utils/selectOptions";
import { validationSchema } from "./utils";

import styles from "./AnswerOnDemandForm.module.css";

export const AnswerOnDemandForm = () => {
    const queryClient = useQueryClient();
    const { isDesktop } = useMedia();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const shortDescription = useGetShortDescription();
    const userHasSubscription = useUserHasSubscription();
    const schoolHasBegun = useGetSchoolHasBegun();

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [isOnSuccessModalActive, setIsOnSuccessModalActive] = useState(false);

    const addSolutionOnDemandMutation = useAddSolutionOnDemand();

    if (!userHasSubscription || !schoolHasBegun) {
        return <Navigate to={getPreviousPath(pathname)} />;
    }

    return (
        <div className={styles.answerOnDemandForm}>
            <DefaultModal
                isOpen={isErrorModalOpen}
                setIsOpen={setIsErrorModalOpen}
                actions={[
                    {
                        name: "Zamknij",
                        onClick: () => {
                            setIsErrorModalOpen(false);
                        },
                    },
                ]}
                title="Wystąpił błąd"
            >
                {addSolutionOnDemandMutation.error?.response &&
                    Object.values(
                        addSolutionOnDemandMutation.error.response.data.messages
                    ).map((message, index) => <p key={index}>{message}</p>)}
            </DefaultModal>
            <DefaultModal
                borderColor="primary"
                titleColor="primary"
                isOpen={isOnSuccessModalActive}
                setIsOpen={setIsOnSuccessModalActive}
                title="WYSŁANO"
                hasSmallSpacing
                actions={[
                    {
                        name: "DODAJ KOLEJNE",
                        onClick: () => setIsOnSuccessModalActive(false),
                    },
                    {
                        name: "ZAKOŃCZ",
                        onClick: () => {
                            setIsOnSuccessModalActive(false);
                            navigate(getPreviousPath(pathname));
                        },
                        white: true,
                    },
                ]}
            >
                <p>
                    Twoje zadanie zostało wysłane. Otrzymasz powiadomienie, gdy
                    je rozwiążemy.
                </p>
            </DefaultModal>

            <DashboardHeading
                title="ROZWIĄZANIE NA ZAWOŁANIE"
                hasBorder
                hasSmallSpacing
            />
            {isDesktop ? (
                <div className={styles.subtitle}>
                    wyślij nam swoje zadanie do rozwiązania
                </div>
            ) : (
                <WideHeading className={styles.heading}>
                    POPROŚ O ROZWIĄZANIE
                </WideHeading>
            )}

            <div className={styles.answerOnDemandSubscription}>
                {shortDescription}
            </div>

            <div className={styles.flex}>
                <Formik<FormFields>
                    initialValues={{
                        source: undefined,
                        title: { id: "", name: "" },
                        pageNumber: "",
                        taskNumber: "",
                        file1: undefined,
                        file2: undefined,
                        notes: "",
                    }}
                    onSubmit={(values, { resetForm }) => {
                        addSolutionOnDemandMutation.mutate(
                            {
                                source: values.source?.value!,
                                title: values.title.id,
                                page_number: values.pageNumber,
                                task_number: values.taskNumber,
                                file1: values.file1,
                                file2: values.file2,
                                notes: values.notes,
                            },
                            {
                                onSuccess: () => {
                                    queryClient.invalidateQueries(
                                        userSubscriptionsQuery().queryKey
                                    );
                                    setIsOnSuccessModalActive(true);
                                    resetForm();
                                },
                                onError: (error) => {
                                    if (error.response?.status === 400) {
                                        setIsErrorModalOpen(true);
                                    }
                                },
                            }
                        );
                    }}
                    validationSchema={validationSchema}
                    validateOnMount
                >
                    {({ values, setValues, isValid }) => {
                        const isSourcePresent = values.source?.value;
                        const isOtherSourcePresent =
                            values.source?.value === "other";

                        const isFormValid =
                            isValid && (!!values.file1 || !!values.file2);

                        return (
                            <Form className={styles.form}>
                                <div className={styles.textFields}>
                                    <ObjectSelectInput
                                        selectValues={sourceOptions}
                                        value={values.source}
                                        onChange={(value) =>
                                            setValues((state) => ({
                                                ...state,
                                                source: value,
                                            }))
                                        }
                                        label="Zadanie z..."
                                    />
                                    <SearchInput
                                        className={styles.searchInput}
                                        label="Tytuł"
                                        name="title"
                                        query={{
                                            key: ["book_search"],
                                            url: "/getSelectBooks",
                                            name: "title",
                                            nameFn: (value) => value.title,
                                        }}
                                        onChange={(value) =>
                                            setValues((state) => ({
                                                ...state,
                                                title: value,
                                            }))
                                        }
                                        isDisabled={
                                            !isSourcePresent ||
                                            isOtherSourcePresent
                                        }
                                        emptyErrorMessage="Jeśli nie znalazłeś książki, wybierz inne źródło w polu powyżej."
                                        minCharsForQueryToLaunch={0}
                                    />
                                    <div className={styles.selectFields}>
                                        <DefaultInput
                                            isMarginless
                                            type="text"
                                            name="pageNumber"
                                            label="Nr strony"
                                            isDisabled={
                                                !isSourcePresent ||
                                                isOtherSourcePresent
                                            }
                                        />
                                        <DefaultInput
                                            isMarginless
                                            type="text"
                                            name="taskNumber"
                                            label="Nr zadania"
                                            isDisabled={
                                                !isSourcePresent ||
                                                isOtherSourcePresent
                                            }
                                        />
                                    </div>
                                </div>

                                <div className={styles.fileFields}>
                                    <div
                                        className={clsx(
                                            styles.fileFieldsTitle,
                                            !isSourcePresent && styles.disabled
                                        )}
                                    >
                                        załącz zdjęcie zadania
                                    </div>
                                    <div className={styles.fileFieldsInner}>
                                        <FileInput
                                            file={values.file1}
                                            onChange={(e) =>
                                                setValues((state) => ({
                                                    ...state,
                                                    file1: e.target.files?.[0],
                                                }))
                                            }
                                            isDisabled={!isSourcePresent}
                                        />
                                        <FileInput
                                            file={values.file2}
                                            onChange={(e) =>
                                                setValues((state) => ({
                                                    ...state,
                                                    file2: e.target.files?.[0],
                                                }))
                                            }
                                            isDisabled={!isSourcePresent}
                                        />
                                    </div>
                                    <DefaultTextarea
                                        placeholder="Dodatkowe notatki, np. numer zadania."
                                        value={values.notes}
                                        onChange={(e) => {
                                            setValues((state) => ({
                                                ...state,
                                                notes: e.target.value,
                                            }));
                                        }}
                                        isDisabled={!isSourcePresent}
                                    />
                                    <DefaultButton
                                        isSubmit
                                        isDisabled={!isFormValid}
                                        initial={{
                                            backgroundColor:
                                                DefaultButton.color.disabled,
                                        }}
                                        animate={{
                                            backgroundColor: isFormValid
                                                ? DefaultButton.color.enabled
                                                : DefaultButton.color.disabled,
                                        }}
                                    >
                                        {addSolutionOnDemandMutation.isLoading ? (
                                            <DottedLoader color="white" />
                                        ) : (
                                            "PRZEŚLIJ DO ROZWIĄZANIA"
                                        )}
                                    </DefaultButton>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>

                {isDesktop && <AnswerRequests vertical />}

                <NavigationBar
                    backward={{
                        children: "WRÓĆ",
                    }}
                />
            </div>
        </div>
    );
};
