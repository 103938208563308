import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { learningProgressSubcategoryQuery } from "../../utils";

import { ClassType, LearningProgressAccountType } from "../../typings";
import { TaskLearningProgressProps } from "../../../tasks/components/TasksLearningProgress/TaskLearningProgress.typings";

type Response = {
    category_id: string;
    category_name: string;
    category_image: string;
    category_image_2: string;
    subcategory_id: string;
    subcategory_name: string;
    progress: {
        done_right: number;
        done_wrong: number;
        not_done: number;
        total: number;
    };
    exercises: TaskLearningProgressProps["tasks"];
    class: ClassType;
};

export const useLearningProgressSubcategory = <
    T extends LearningProgressAccountType
>(
    type: T,
    classType: string,
    subcategoryId: string,
    childrenId: T extends "other" ? string : undefined
) => {
    return useQuery<AxiosResponse<Response, any>>(
        learningProgressSubcategoryQuery(
            type,
            classType,
            subcategoryId,
            childrenId
        )
    );
};
