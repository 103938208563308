import * as yup from "yup";
import YupPassword from "yup-password";

YupPassword(yup);

export const accountDeleteSchema = yup.object({
    password: yup
        .string()
        .min(8, " ")
        .minLowercase(1, " ")
        .minUppercase(1, " ")
        .minNumbers(1, " ")
        // .minSymbols(1, " ")
        .required(" "),
    code: yup.string().min(9, " ").required(),
});
