import { FormikConfig } from "formik";
import { motion } from "framer-motion";
import { forwardRef, useState } from "react";

import { getStringifiedDaysOfWeek } from "../../pages/Notifications/utils";
import { NotificationType } from "../../typings";
import { NotificationsModal } from "../NotificationsModal";

import iconMinus from "../../../../assets/images/dashboard/competency-test/minus.svg";
import styles from "./Notification.module.css";

type Props = {
    notification: NotificationType;
    setNotifications: React.Dispatch<React.SetStateAction<NotificationType[]>>;
};

export const Notification = forwardRef<HTMLDivElement, Props>(
    function Notification({ notification, setNotifications }, ref) {
        const [isModalOpen, setIsModalOpen] = useState(false);

        const onSubmit: FormikConfig<NotificationType>["onSubmit"] = (
            values
        ) => {
            setNotifications((state) =>
                state.map((element) => {
                    if (element.id === notification.id) {
                        return values;
                    }
                    return element;
                })
            );

            setIsModalOpen(false);
        };

        const removeNotification = () => {
            setNotifications((state) =>
                state.filter((value) => value.id !== notification.id)
            );
        };

        return (
            <motion.div
                ref={ref}
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                    type: "tween",
                    duration: 0.3,
                }}
                className={styles.notificationOuter}
            >
                <NotificationsModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    initialValues={notification}
                    onSubmit={onSubmit}
                />
                <button
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                    type="button"
                    className={styles.notification}
                >
                    <div className={styles.notificationInner}>
                        <div className={styles.date}>
                            <div className={styles.time}>
                                {notification.time}
                            </div>
                        </div>
                        <div>
                            {getStringifiedDaysOfWeek(notification.daysOfWeek)}
                        </div>
                    </div>
                </button>
                <button
                    onClick={removeNotification}
                    className={styles.buttonRemove}
                    type="button"
                >
                    <img src={iconMinus} alt="-" />
                </button>
            </motion.div>
        );
    }
);
