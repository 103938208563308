import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { UseQueryResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import clsx from "clsx";

import { useReadUserNotification } from "../../hooks";

import {
    getPathFromUrl,
    userImportantNotificationQuery,
    userNotificationsQuery,
} from "../../utils";

import { ImportantNotificationResponse } from "../../typings";

import styles from "./NotificationAlert.module.css";

import iconQuestionmark from "../../../../assets/images/dashboard/alert/icon-questionmark.svg";
import iconExclamation from "../../../../assets/images/dashboard/alert/icon-exclamation.svg";

type Props = {
    result: UseQueryResult<
        AxiosResponse<ImportantNotificationResponse, any>,
        unknown
    >;
};

export const NotificationAlert = ({ result }: Props) => {
    const notification = result.data && result.data.data.notification;

    const navigate = useNavigate();

    const userNotificationsQueryKey = userNotificationsQuery().queryKey;
    const userImportantNotificationQueryKey =
        userImportantNotificationQuery().queryKey;
    const readUserNotification = useReadUserNotification([
        userNotificationsQueryKey,
        userImportantNotificationQueryKey,
    ]);

    const handleGoTo = () => {
        if (notification) {
            readUserNotification.mutate({ id: notification.id });

            if (notification.url) {
                const pathFromUrl = getPathFromUrl(notification.url);

                if (pathFromUrl.isInsideApp) {
                    navigate(pathFromUrl.url);
                } else {
                    window.open(pathFromUrl.url, "_blank");
                }
            }
        }
    };

    return (
        <>
            <AnimatePresence mode="wait" initial={false}>
                {notification && (
                    <motion.div
                        key={notification.id}
                        className={styles.container}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                            type: "tween",
                            duration: 0.2,
                        }}
                    >
                        <button
                            className={clsx(
                                styles.button,
                                notification.type === "Krytyczne" &&
                                    styles.important,
                                readUserNotification.isLoading &&
                                    styles.disabled
                            )}
                            type="button"
                            disabled={readUserNotification.isLoading}
                            onClick={handleGoTo}
                        >
                            <div className={styles.icon}>
                                {notification.type === "Krytyczne" ? (
                                    <img src={iconExclamation} alt="" />
                                ) : (
                                    <img src={iconQuestionmark} alt="" />
                                )}
                            </div>
                            <div className={styles.text}>
                                {notification.title}
                            </div>
                        </button>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};
