import { SchoolType } from "../../../typings";

export const getUserSchool = (userSchool?: string) => {
    const userSchoolArray = userSchool
        ? userSchool.split(",")
        : ["Podstawowa", "1"];

    return {
        name: userSchoolArray[0] as SchoolType,
        class: userSchoolArray[1],
    };
};
