import { QueryClient } from "@tanstack/react-query";

import { getAccountCookie } from "../../../../services";

import { fetchDelay } from "../../../../utils/fetchDelay";

import { tasksQuery } from "../queries";

export const tasksLoader = (queryClient: QueryClient) => async () => {
    const { accountType, userSchool } = getAccountCookie();

    if (accountType !== "student") {
        return undefined;
    }

    const query = tasksQuery(userSchool.class);

    return (
        queryClient.getQueryData(query.queryKey) ??
        fetchDelay(queryClient.fetchQuery(query), { min: 800, max: 1600 })
    );
};
