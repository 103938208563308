import { useState } from "react";
import { Link, useNavigation } from "react-router-dom";
import clsx from "clsx";

import { DifficultyStatus } from "../../../tasks/components";
import { CircleLoader } from "../../../../components/loaders";

import { DifficultyLevels } from "../../../tasks/typings";

import styles from "./ProgressButton.module.css";

type Props = {
    children: string;
    to: string;
    icon: string;
    level: DifficultyLevels["advanced"];
};

export const ProgressButton = ({ children, to, icon, level }: Props) => {
    const { state } = useNavigation();

    const [isClicked, setIsClicked] = useState(false);

    return (
        <div className={styles.container}>
            <Link
                className={clsx(
                    styles.button,
                    state === "loading" && styles.disabled
                )}
                to={to}
                onClick={() => setIsClicked(true)}
            >
                <span className={styles.icon}>
                    {isClicked && state === "loading" ? (
                        <CircleLoader color="white" size="24" />
                    ) : (
                        <img src={icon} alt="" />
                    )}
                </span>
                {children}
                <span className={styles.difficulty}>
                    <DifficultyStatus level={level} isAdvanced={true} />
                </span>
            </Link>
        </div>
    );
};
