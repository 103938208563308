import { useUserSubscriptions } from "../../dashboard/hooks/queries";

export const useGetShortDescription = () => {
    const userSubscriptionsQuery = useUserSubscriptions();

    if (!userSubscriptionsQuery.isSuccess) {
        return null;
    }

    const answerOnDemandSubscription =
        userSubscriptionsQuery.data.data.subscriptions.find(
            (subscription) =>
                subscription.subscription === "Rozwiązanie na zawołanie"
        );

    const isSubscriptionActive =
        answerOnDemandSubscription?.active_to !== "nieaktywny";

    const solutionsLeft = Number(
        answerOnDemandSubscription?.solutions_left ?? 0
    );
    
    if (!isSubscriptionActive) {
        return "nieaktywny";
    }

    if (solutionsLeft === 1) {
        return `pozostało Ci ${solutionsLeft} zadanie`;
    }

    if (solutionsLeft >= 2 && solutionsLeft <= 4) {
        return `pozostały Ci ${solutionsLeft} zadania`;
    }

    return `pozostało Ci ${solutionsLeft} zadań`;
};
