import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { useNotify } from "../../../../hooks";

import { mainApi } from "../../../../api";

import { StorePaymentData } from "../../typings";

export const usePlaceOrderRequest = () => {
    const notify = useNotify({ type: "error", position: "bottom-center" });

    return useMutation<AxiosResponse<any, any>, any, StorePaymentData, unknown>(
        (data) =>
            mainApi.post("place_order_request", {
                discount_code: data.discountCode,
                sub_8_kl_id: data.primarySchoolId,
                sub_matura_podstawowa_id: data.highSchoolBasicId,
                sub_matura_rozszerzona_id: data.highSchoolExtendedId,
                sub_rozw_na_zawolanie_id: data.solutionOnDemandId,
                final_amount: data.price,
            }),
        {
            onError: () =>
                notify("Wysyłanie prośby o płatność nie powiodło się"),
        }
    );
};
