import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getPreviousPath } from "../../../../utils";

import { TaskType } from "../../typings";

export const useRedirectToValidTask = (
    currentTask: {
        type: TaskType;
        id: string;
    },
    storedTask:
        | {
              type: TaskType;
              id: string;
          }
        | undefined,
    isChanged?: boolean
) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const isStoredTaskValid =
        storedTask &&
        storedTask.type === currentTask.type &&
        storedTask.id === currentTask.id
            ? true
            : false;
    const isTaskRedirected = useRef(isStoredTaskValid ? true : false);
    const isUpdatedByChange = useRef(false);

    useEffect(() => {
        if (
            (!isStoredTaskValid && storedTask && !isTaskRedirected.current) ||
            (!isStoredTaskValid &&
                storedTask &&
                isChanged &&
                !isUpdatedByChange.current)
        ) {
            isTaskRedirected.current = true;

            if (isChanged) {
                isUpdatedByChange.current = true;
            }

            navigate(
                `${getPreviousPath(pathname, 2)}/${storedTask!.type}/${
                    storedTask!.id
                }`,
                {
                    replace: true,
                }
            );
        }
    }, [isStoredTaskValid, navigate, pathname, storedTask, isChanged]);

    useEffect(() => {
        if (!isChanged && isUpdatedByChange.current) {
            isUpdatedByChange.current = false;
        }
    }, [isChanged]);
};
