type ValidationType =
    | "lowercase"
    | "uppercase"
    | "number"
    | "length"
    | "special"
    | undefined;

export const validateString = (type: ValidationType, value: string) => {
    switch (type) {
        case "lowercase":
            return /[a-z]/g.test(value);

        case "uppercase":
            return /[A-Z]/g.test(value);

        case "number":
            return /[0-9]/g.test(value);

        case "length":
            return value.length >= 8 ? true : false;

        case "special":
            return /[!@#$%^&*)(+=_\-|\\}\]{["':;?/>.<,]/g.test(value);

        default:
            return false;
    }
};
