import { useState, useCallback, createContext, useContext } from "react";
import {
    AnswerCheckType,
    AnswerType,
    ChoiceCheckType,
    FillCheckType,
    OpenCheckType,
    TruthCheckType,
} from "../typings";

const ResultContext = createContext<ContextType | null>(null);

type Props = {
    children: JSX.Element;
};

type ResultType = AnswerCheckType | undefined;

type GetResult<T extends Exclude<AnswerType["type"], "text">> =
    | (T extends "radiobutton"
          ? ChoiceCheckType
          : T extends "true_or_false"
          ? TruthCheckType
          : T extends "match"
          ? FillCheckType
          : T extends "open"
          ? OpenCheckType
          : any)
    | undefined;

type ContextType = {
    results: ResultType;
    setResults: React.Dispatch<React.SetStateAction<ResultType>>;
    getResult: <T extends Exclude<AnswerType["type"], "text">>(
        id: string,
        type: T
    ) => GetResult<T>;
    removeResults: () => void;
    isResultsLoading: boolean;
    setIsResultsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ResultProvider = ({ children }: Props) => {
    const [results, setResults] = useState<ResultType>(undefined);
    const [isResultsLoading, setIsResultsLoading] = useState(false);

    const getResult = useCallback(
        <T extends Exclude<AnswerType["type"], "text">>(
            id: string
        ): GetResult<T> => {
            if (results) {
                return results.answers.filter(
                    (answer) => answer.id === id
                )[0] as any;
            } else {
                return undefined;
            }
        },
        [results]
    );

    const removeResults = useCallback(() => setResults(undefined), []);

    return (
        <ResultContext.Provider
            value={{
                results,
                setResults,
                getResult,
                removeResults,
                isResultsLoading,
                setIsResultsLoading,
            }}
        >
            {children}
        </ResultContext.Provider>
    );
};

export const useResultContext = () => useContext(ResultContext) as ContextType;
