import clsx from "clsx";

import { ConditionalWrapper } from "../../wrappers";

import styles from "./MainHeading.module.css";

type Props = {
    className?: string;
    wrapper?: string;
    wrapperType?: React.ComponentProps<
        typeof ConditionalWrapper
    >["wrapperType"];
    elementType?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    children: JSX.Element | string;
};

export const MainHeading = ({
    className,
    wrapper,
    wrapperType = "div",
    elementType: ElementType = "h1",
    children,
}: Props) => {
    return (
        <ConditionalWrapper wrapper={wrapper} wrapperType={wrapperType}>
            <ElementType className={clsx(styles.title, className)}>
                {children}
            </ElementType>
        </ConditionalWrapper>
    );
};
