export const QuestionIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
    >
        <g id="Group_484" data-name="Group 484" transform="translate(-24 -148)">
            <g
                id="Ellipse_216"
                data-name="Ellipse 216"
                transform="translate(24 148)"
                fill="#fff"
                stroke="#2eafbe"
                strokeWidth="1"
            >
                <circle cx="16" cy="16" r="16" stroke="none" />
                <circle cx="16" cy="16" r="15.5" fill="none" />
            </g>
            <path
                id="Path_1998"
                data-name="Path 1998"
                d="M-5.778-16.473a7.082,7.082,0,0,1,.35-2.478,3.874,3.874,0,0,1,1.136-1.575A7.6,7.6,0,0,0-3.1-21.747a2.009,2.009,0,0,0,.409-1.22q0-1.624-1.38-1.624a1.274,1.274,0,0,0-1.03.473,2.029,2.029,0,0,0-.414,1.28H-9.1a4.363,4.363,0,0,1,1.338-3.349,5.289,5.289,0,0,1,3.694-1.2A5.492,5.492,0,0,1-.4-26.279,3.911,3.911,0,0,1,.9-23.129,3.709,3.709,0,0,1,.549-21.5,5.481,5.481,0,0,1-.577-20.01L-1.8-18.86a2.91,2.91,0,0,0-.722,1.038,4.16,4.16,0,0,0-.223,1.349ZM-6.214-13.2a1.678,1.678,0,0,1,.568-1.306,2.041,2.041,0,0,1,1.417-.511,2.041,2.041,0,0,1,1.417.511A1.678,1.678,0,0,1-2.243-13.2,1.678,1.678,0,0,1-2.811-11.9a2.041,2.041,0,0,1-1.417.511A2.041,2.041,0,0,1-5.646-11.9,1.678,1.678,0,0,1-6.214-13.2Z"
                transform="translate(44.101 183.407)"
                fill="#2eafbe"
            />
        </g>
    </svg>
);
