import { mainApi } from "../../../../api";

import { LearningProgressAccountType } from "../../typings";

const key = "learningProgressCategory";
const url = "user_category_learning_progress";

export const learningProgressCategoryQuery = (
    type: LearningProgressAccountType,
    classType?: string,
    categoryId?: string,
    childrenId?: string
) => ({
    queryKey:
        type === "student"
            ? classType && categoryId
                ? [key, classType, categoryId]
                : [key]
            : classType && categoryId && childrenId
            ? [key, classType, categoryId, childrenId]
            : [key],
    queryFn: async () =>
        mainApi.post(type === "student" ? url : `${url}/${childrenId}`, {
            class: classType,
            category_id: categoryId,
        }),
});
