import clsx from "clsx";

import { CircleLoader } from "../../loaders";

import styles from "./IconButton.module.css";

import iconDelete from "../../../assets/images/dashboard/button/icon-trash.svg";
import iconConfirm from "../../../assets/images/dashboard/button/icon-checkmark.svg";
import iconBars from "../../../assets/images/dashboard/button/icon-bars.svg";

type Props = {
    type?: "delete" | "confirm" | "chart";
    children?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
    onClick?: () => void;
};

const getIcon = (type: Props["type"]) => {
    switch (type) {
        case "delete":
            return iconDelete;
        case "confirm":
            return iconConfirm;
        case "chart":
            return iconBars;
        default:
            return undefined;
    }
};

export const IconButton = ({
    type,
    children,
    isDisabled,
    isLoading,
    onClick,
}: Props) => {
    return (
        <button
            className={clsx(
                styles.button,
                type === "delete" && styles.important,
                type === "confirm" && styles.blank
            )}
            disabled={isDisabled}
            type="button"
            onClick={onClick}
        >
            {isLoading ? (
                <CircleLoader size="18" color="white" />
            ) : (
                (children || type) && (
                    <img src={children ? children : getIcon(type)} alt="" />
                )
            )}
        </button>
    );
};
