import * as yup from "yup";

import { passwordCompareMessage } from "../variables";

export const registerStudentSchema = yup.object({
    email: yup.string().email("* nieprawidłowy adres e-mail").required(" "),
    password: yup
        .string()
        .min(8, " ")
        .minLowercase(1, " ")
        .minUppercase(1, " ")
        .minNumbers(1, " ")
        // .minSymbols(1, " ")
        .required(" "),
    password_confirm: yup
        .string()
        .min(8, " ")
        .minLowercase(1, passwordCompareMessage)
        .minUppercase(1, passwordCompareMessage)
        .minNumbers(1, passwordCompareMessage)
        // .minSymbols(1, passwordCompareMessage)
        .oneOf([yup.ref("password")], passwordCompareMessage)
        .required(" "),
    birthday: yup.string().required("* data urodzenia jest wymagana"),
    school: yup.string().required(" "),
    class: yup.string().required(" "),
    city: yup.object({
        id: yup.string().required(" "),
        name: yup.string().required(" "),
    }),
    parent_email: yup
        .string()
        .test(
            "isParentEmail",
            "* podany adres e-mail rodzica jest identyczny",
            (value, context) => {
                if (
                    value &&
                    context.parent.email === value &&
                    value.length > 0
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        )
        .when("birthday", {
            is: (date?: string) => {
                if (date) {
                    const dateString = date.split(".").reverse().join("-");
                    const dateSelectedTimestamp = new Date(
                        dateString
                    ).getTime();
                    const dateCurrentTimestamp = new Date().getTime();
                    const dateTarget = new Date(
                        dateSelectedTimestamp - dateCurrentTimestamp
                    );

                    return !(Math.abs(dateTarget.getUTCFullYear() - 1970) > 13);
                } else {
                    return false;
                }
            },
            then: yup
                .string()
                .email("* nieprawidłowy adres e-mail rodzica")
                .required(
                    "* masz mniej mniej niż 13 lat, podaj e-mail rodzica, aby poprosić o zgodę na korzystanie z aplikacji"
                ),
            otherwise: yup.string().email("* nieprawidłowy adres e-mail"),
        }),
    first_name: yup.string().min(2, " ").required(" "),
    last_name: yup.string().min(2, " ").required(" "),
    agreement: yup
        .boolean()
        .oneOf([true], "* wyrażenie zgody jest niezbędne")
        .required(" "),
});
