import { TaskHint } from "../TaskHint";
import { UtilsButton } from "../../UtilsButton";

import { TaskUtilsProps } from "./TaskUtils.typings";

import styles from "./TaskUtils.module.css";

import icon from "./icons/note-icon.svg";

export const TaskUtils = ({
    hint,
    isLoading,
    setIsNoteOpen,
}: TaskUtilsProps) => {
    const isHintAvailable = hint.contents.length > 0;
    const isHintSingle = hint.contents.length === 1;

    return (
        <div className={styles.container}>
            <div className={styles.item}>
                <UtilsButton
                    icon={icon}
                    isDisabled={isLoading}
                    onClick={() => setIsNoteOpen((isOpen) => !isOpen)}
                >
                    NOTES
                </UtilsButton>
            </div>
            {isHintAvailable && (
                <div className={styles.item}>
                    <TaskHint
                        hint={hint}
                        isLoading={isLoading}
                        isSingle={isHintSingle}
                    />
                </div>
            )}
        </div>
    );
};
