import { DefaultButton } from "../../../../components/buttons";
import { ButtonLoader } from "../../../../components/utils";

import styles from "./SettingsOption.module.css";

type Props = {
    title: string;
    subtitle: string;
    content?: string | { id: string; children: string }[];
    button: {
        children: string;
        onClick: () => void;
    };
    isDisabled?: boolean;
    isLoading?: boolean;
};

export const SettingsOption = ({
    title,
    subtitle,
    content,
    button,
    isDisabled,
    isLoading,
}: Props) => {
    return (
        <div className={styles.container}>
            <h4 className={styles.title}>{title}</h4>
            <div className={styles.content}>
                <p>{subtitle}</p>
                {Array.isArray(content) ? (
                    content.length > 0 ? (
                        content.map((element) => (
                            <p key={element.id} className={styles.highlight}>
                                {element.children}
                            </p>
                        ))
                    ) : (
                        <p className={styles.highlight}>Brak powiadomień</p>
                    )
                ) : (
                    <p className={styles.highlight}>{content}</p>
                )}
            </div>
            <DefaultButton
                wrapper={styles.button}
                isDisabled={isDisabled || isLoading}
                onClick={button.onClick}
            >
                <ButtonLoader condition={isLoading ?? false} color="white">
                    {button.children}
                </ButtonLoader>
            </DefaultButton>
        </div>
    );
};
