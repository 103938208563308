import React from "react";

import { getAccountCookie } from "../../../../../../services";

import { useParentChildrens } from "../../../../hooks";

type ParentsChildrenType = ReturnType<typeof useParentChildrens>["data"];

export const getDeleteContent = (
    type: ReturnType<typeof getAccountCookie>["accountType"],
    childrenData?: ParentsChildrenType
) => {
    const childrens = childrenData?.data.children;

    switch (type) {
        case "student":
            return (
                <p>
                    Usunięcie konta spowoduje bezpowrotne skasowanie wszystkich
                    Twoich postępów nauki.
                </p>
            );
        case "parent":
            return (
                <>
                    {childrens && childrens.length > 0 && (
                        <p>
                            Do Twojego konta są podpięci uczniowie (
                            {childrens.length}):
                            {childrens.map((children) => (
                                <React.Fragment key={children.email}>
                                    <br />
                                    <strong>{children.email}</strong>
                                </React.Fragment>
                            ))}
                        </p>
                    )}
                    <p>
                        Usunięcie Twojego konta nie spowoduje zablokowanie im
                        dostępu do aplikacji jednak nie będzie możliwe jego
                        przedłużenie po zakończeniu okresu abonamentowego. Nie
                        będzie też możliwe uzyskanie dostępu do postępów ich
                        nauki.
                    </p>
                </>
            );
        case "teacher":
            return (
                <p>
                    Usunięcie konta spowoduje, że stracisz dostęp do śledzenia
                    postępów nauki Twoich uczniów.
                </p>
            );
        default:
            return null;
    }
};
