import { useState, useEffect, forwardRef, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation, useNavigation } from "react-router-dom";
import clsx from "clsx";

import { useUserNotifications, useReadUserAllNotifications } from "../../hooks";

import { NotificationItem } from "../NotificationItem";
import { NotificationButton } from "../NotificationButton";
import { LettersIcon } from "../icon-components";

import {
    userImportantNotificationQuery,
    userNotificationsQuery,
} from "../../utils";

import { animationProps } from "./NotificationsDropdown.animations";

import styles from "./NotificationsDropdown.module.css";

type Props = {
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NotificationsDropdown = forwardRef(
    (props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
        const { setIsOpen } = props;

        const { pathname } = useLocation();
        const prevPathname = useRef(pathname);

        const { state } = useNavigation();
        const isLoadingState = state === "loading";

        const userNotifications = useUserNotifications();
        const notifications = userNotifications.data
            ? userNotifications.data.data.notifications
            : [];

        const userNotificationsQueryKey = userNotificationsQuery().queryKey;
        const userImportantNotificationQueryKey =
            userImportantNotificationQuery().queryKey;
        const { mutate, reset, isLoading, isSuccess } =
            useReadUserAllNotifications([
                userNotificationsQueryKey,
                userImportantNotificationQueryKey,
            ]);

        const [isRedirecting, setIsRedirecting] = useState(false);

        useEffect(() => {
            if (isSuccess) {
                setTimeout(() => {
                    reset();
                }, 1000);
            }
        }, [isSuccess, reset]);

        useEffect(() => {
            if (prevPathname.current !== pathname) {
                setIsOpen(false);

                setTimeout(() => {
                    setIsRedirecting(false);
                }, 500);
            }

            prevPathname.current = pathname;
        }, [pathname, setIsOpen]);

        return (
            <div className={styles.container} ref={ref}>
                <div className={styles.title}>POWIADOMIENIA</div>
                <hr className={styles.border} />
                <div className={styles.list}>
                    <AnimatePresence mode="sync" initial={false}>
                        {notifications.length > 0 ? (
                            notifications.map((notification) => (
                                <motion.div
                                    key={notification.id}
                                    {...animationProps}
                                >
                                    <NotificationItem
                                        key={notification.id}
                                        id={notification.id}
                                        type={notification.type}
                                        body={notification.title}
                                        url={notification.url}
                                        createdAt={notification.created_at}
                                        queryKeys={[
                                            userNotificationsQueryKey,
                                            userImportantNotificationQueryKey,
                                        ]}
                                        isDisabled={isLoading || isSuccess}
                                        isLoading={isLoadingState}
                                        isRedirecting={isRedirecting}
                                        setIsOpen={setIsOpen}
                                        setIsRedirecting={setIsRedirecting}
                                    />
                                </motion.div>
                            ))
                        ) : (
                            <motion.div
                                key="notifications_empty"
                                className={styles.empty}
                                {...animationProps}
                            >
                                Brak powiadomień
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                <hr className={clsx(styles.border, styles.bottom)} />
                <div className={styles.button}>
                    <NotificationButton
                        className={styles.readAll}
                        icon={<LettersIcon />}
                        isDisabled={notifications.length === 0}
                        isRedirecting={isRedirecting}
                        onClick={() => mutate()}
                    >
                        OZNACZ WSZYSTKIE JAKO PRZECZYTANE
                    </NotificationButton>
                </div>
            </div>
        );
    }
);
