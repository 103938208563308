import creditCardType from "credit-card-type";

import { SavedCardsResponse } from "../../../hooks/queries/useUserSavedCards";

import { CardFormData } from "../../../typings";

const getCardNumberValue = (value: string, isRaw?: boolean) => {
    const trimValue = value.replaceAll(" ", "");

    const isFirstCharZero = trimValue.charAt(0) === "0";
    const isNumbers = new RegExp("^[0-9]+$").test(trimValue);

    const parseValue = isFirstCharZero
        ? isNumbers
            ? trimValue
            : trimValue.replace(/[^0-9]/g, "")
        : parseInt(trimValue).toString();
    const baseValue = parseValue === "NaN" ? "" : parseValue;

    let formattedValue = "";

    for (let i = 0; i < baseValue.length; i++) {
        formattedValue += baseValue.charAt(i);

        if ((i + 1) % 4 === 0 && i + 1 !== baseValue.length) {
            formattedValue += " ";
        }
    }

    return isRaw ? baseValue : formattedValue === "NaN" ? "" : formattedValue;
};

const getCardType = (value: string) => {
    const types = creditCardType(value);

    return types.length > 0 ? types[0] : undefined;
};

const getCardNumberResponse = (value: string, max: number) => {
    max = max > 0 ? max : 1;

    if (getCardNumberValue(value, true).length >= max && getCardType(value)) {
        return "success";
    } else {
        if (getCardNumberValue(value, true).length !== 0) {
            return "error";
        } else {
            return "";
        }
    }
};

const parseValidThru = (value: string) => {
    const trimValue = value.replaceAll("/", "");

    const isFirstCharZero = trimValue.charAt(0) === "0";
    const isNumbers = new RegExp("^[0-9]+$").test(trimValue);

    const parseValue = isFirstCharZero
        ? isNumbers
            ? trimValue
            : trimValue.replace(/[^0-9]/g, "")
        : parseInt(trimValue).toString();
    const baseValue = parseValue === "NaN" ? "" : parseValue;

    let formattedValue = "";

    for (let i = 0; i < baseValue.length; i++) {
        if (i === 2) {
            formattedValue += "/";
        }

        formattedValue += baseValue.charAt(i);
    }

    return formattedValue === "NaN" ? "" : formattedValue;
};

const parseCvcValue = (value: string) => {
    const isFirstCharZero = value.charAt(0) === "0";
    const isNumbers = new RegExp("^[0-9]+$").test(value);

    const parseValue = isFirstCharZero
        ? isNumbers
            ? value
            : value.replace(/[^0-9]/g, "")
        : parseInt(value).toString();

    return parseValue === "NaN" ? "" : value;
};

const getCommonResponse = (value: string, min: number) =>
    min > 0
        ? value.length >= min
            ? "success"
            : value.length !== 0
            ? "error"
            : ""
        : "";

const getFormValueFromCardData = (data: SavedCardsResponse) =>
    `${data.id},**** **** **** ${
        data.card_last_digits
    },*** ********,${data.exp_date.substring(0, 2)}/${data.exp_date.substring(
        2,
        4
    )},***`;

const getDataFromSelectValue = (string: string) => {
    const dataArray = string.split(",");

    return {
        id: dataArray[0],
        number: dataArray[1],
        owner: dataArray[2],
        validThru: dataArray[3],
        cvc: dataArray[4],
    };
};

const setFormDataBySelect = (
    setData: React.Dispatch<React.SetStateAction<CardFormData>>,
    data: ReturnType<typeof getDataFromSelectValue> | undefined
) => {
    setData({
        number: {
            value: data ? data.number : "",
            response: data ? "saved" : "",
        },
        owner: {
            value: data ? data.owner : "",
            response: data ? "saved" : "",
        },
        validThru: {
            value: data ? data.validThru : "",
            response: data ? "saved" : "",
        },
        cvc: {
            value: data ? data.cvc : "",
            response: data ? "saved" : "",
        },
    });
};

const getSelectValueFromData = (
    savedId: string | undefined,
    data: CardFormData
) =>
    savedId
        ? {
              label: data.number.value,
              value: `${savedId},${data.number},${data.owner},${data.validThru},${data.cvc}`,
          }
        : {
              label: "Nowa karta",
              value: "",
          };

export {
    getCardNumberValue,
    getCardType,
    getCardNumberResponse,
    parseValidThru,
    parseCvcValue,
    getCommonResponse,
    getFormValueFromCardData,
    getDataFromSelectValue,
    setFormDataBySelect,
    getSelectValueFromData,
};
