import { useLayoutEffect } from "react";

import { useLocation } from "react-router-dom";
import { deromanize, romanize } from "romans";

import { DefaultButton } from "../../../../components/buttons";
import { DashboardHeading } from "../../../../components/headings";
import { ObjectSelectInput, Options } from "../../../../components/inputs";
import { useMedia } from "../../../../hooks";
import { getAccountCookie } from "../../../../services";
import { getClassList, getPreviousPath } from "../../../../utils";
import { SelectableCard } from "../../../dashboard/components";
import { useUserData } from "../../../dashboard/hooks";
import { StepperWithTitle } from "../../components";
import { useCompetencyTestContext } from "../../hooks";
import { colors } from "../../utils";

import styles from "./Step2.module.css";

export const Step2 = () => {
    const { isDesktop } = useMedia();

    const { pathname } = useLocation();

    const { form, setForm, backwardPathname } = useCompetencyTestContext();

    const getUserDataQuery = useUserData();
    const { userSchool } = getAccountCookie();

    const isFilled = () => {
        if (
            form.scope === "full" &&
            getUserDataQuery.data?.data.data.school !== "Podstawowa" &&
            !form.exam?.value
        ) {
            return false;
        }

        return !!form.scope && !!form.class;
    };

    const getClassOptions = (): Options | undefined =>
        getUserDataQuery.data &&
        getClassList(getUserDataQuery.data.data.data.school, true).map(
            (userClass) => ({
                label: `Poziom nauczania i zadania dla klasy ${userClass}`,
                value: deromanize(userClass).toString(),
            })
        );
    const getFullOptions = (): Options | undefined => [
        { label: "Matura podstawowa", value: "matura_podstawowa" },
        // Temporarily disabled
        // { label: "Matura rozszerzona", value: "matura_rozszerzona" },
    ];

    useLayoutEffect(() => {
        if (getUserDataQuery.data?.data.data.class && !form.class) {
            setForm((state) => ({
                ...state,
                class: {
                    label: `Poziom nauczania i zadania dla klasy ${
                        getUserDataQuery.data?.data.data.class &&
                        romanize(Number(getUserDataQuery.data.data.data.class))
                    }`,
                    value: getUserDataQuery.data.data.data.class,
                },
            }));
        }
    }, [form.class, getUserDataQuery.data, setForm]);

    return (
        <div className={styles.step2}>
            <DashboardHeading
                title="Daj się poznać"
                subtitle="cel nauki"
                to={backwardPathname}
                hasBorder={true}
            />
            {isDesktop && (
                <StepperWithTitle
                    steps={[
                        isFilled() ? colors.filled : colors.active,
                        colors.inactive,
                        colors.inactive,
                    ]}
                />
            )}

            <div className={styles.form}>
                <div className={styles.buttons}>
                    {/* TODO: teraz jest na sztywno - w oryginale, cardy są dwa, te poniżej */}

                    {userSchool.name === "Podstawowa" && (
                        <>
                            <SelectableCard
                                onClick={() =>
                                    setForm((state) => ({
                                        ...state,
                                        scope: "class",
                                    }))
                                }
                                isSelected={form.scope === "class"}
                            >
                                NAUKA CAŁEGO MATERIAŁU
                                <br /> Z KLASY{" "}
                                {form.class?.value &&
                                    romanize(Number(form.class.value))}
                            </SelectableCard>
                            <SelectableCard
                                onClick={() => {
                                    setForm((state) => ({
                                        ...state,
                                        scope: "full",
                                    }));
                                }}
                                isSelected={form.scope === "full"}
                            >
                                PRZYGOTOWANIE DO EGZAMINU{" "}
                                {getUserDataQuery.data?.data.data.school ===
                                "Podstawowa"
                                    ? "ÓSMOKLASISTY"
                                    : "MATURALNEGO"}
                            </SelectableCard>
                        </>
                    )}

                    {userSchool.name !== "Podstawowa" && (
                        <SelectableCard
                            onClick={() => {
                                setForm((state) => ({
                                    ...state,
                                    scope: "full",
                                }));
                            }}
                            isSelected={form.scope === "full"}
                        >
                            PRZYGOTOWANIE DO EGZAMINU MATURALNEGO
                        </SelectableCard>
                    )}
                </div>
                {form.scope === "class" && (
                    <ObjectSelectInput
                        className={styles.select}
                        selectValues={getClassOptions()}
                        value={form.class}
                        onChange={(value) =>
                            setForm((state) => ({ ...state, class: value }))
                        }
                        isLabelCenter
                    />
                )}
                {form.scope === "full" &&
                    getUserDataQuery.data?.data.data.school !==
                        "Podstawowa" && (
                        <ObjectSelectInput
                            className={styles.select}
                            selectValues={getFullOptions()}
                            value={form.exam}
                            onChange={(value) =>
                                setForm((state) => ({ ...state, exam: value }))
                            }
                            isLabelCenter
                        />
                    )}
            </div>
            <DefaultButton
                initial={false}
                animate={{
                    backgroundColor: isFilled()
                        ? DefaultButton.color.enabled
                        : DefaultButton.color.disabled,
                }}
                wrapper={styles.nextButton}
                to={`${getPreviousPath(pathname, 1)}/grade`}
                state={true}
                isDisabled={!isFilled()}
            >
                PRZEJDŹ DALEJ
            </DefaultButton>
        </div>
    );
};
