import { useUserSubscriptions } from "../../dashboard/hooks/queries";
import { SubscriptionType } from "../../dashboard/typings";

export const useUserHasSubscription = () => {
    const userSubscriptionsQuery = useUserSubscriptions();

    if (!userSubscriptionsQuery.data || !userSubscriptionsQuery.isSuccess) {
        return false;
    }

    const answerOnDemandSubscription =
        userSubscriptionsQuery.data.data.subscriptions.find(
            (subscription) =>
                subscription.subscription === "Rozwiązanie na zawołanie"
        ) as SubscriptionType;

    return (
        answerOnDemandSubscription.active_to !== "nieaktywny" &&
        parseInt(answerOnDemandSubscription.solutions_left as string) > 0
    );
};
