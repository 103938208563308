import clsx from "clsx";
import { useState } from "react";
import { Link } from "react-router-dom";

import { CircleLoader } from "../../../../components/loaders";

import academicCapImg from "../../../../assets/images/components/button/academic-cap.svg";
import styles from "./ExamTask.module.css";

type Props = {
    children: string;
    to: string;
    tasks_done: number;
    tasks_all: number;
    isLoading?: boolean;
};

export const ExamTask = ({ children, to, tasks_done, tasks_all, isLoading }: Props) => {
    const [isClicked, setIsClicked] = useState(false);

    // const progressStatus = getProgressStatus(progress);

    return (
        <Link
            className={clsx(styles.button, isLoading && styles.disabled)}
            to={to}
            onClick={() => setIsClicked(true)}
        >
            <span className={styles.icon}>
                {isLoading && isClicked ? (
                    <CircleLoader color="white" size="24" />
                ) : (
                    <img src={academicCapImg} alt="" />
                )}
            </span>
            {children}
            <span className={clsx(styles.progress)}>{tasks_done}/{tasks_all}</span>
        </Link>
    );
};
