export const ShieldIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="54"
        height="68"
        viewBox="0 0 54 68"
    >
        <defs>
            <linearGradient
                id="linear-gradient"
                x1="1"
                x2="0"
                y2="1"
                gradientUnits="objectBoundingBox"
            >
                <stop offset="0" stopColor="#2eafbe" />
                <stop offset="1" stopColor="#2ac3bb" />
            </linearGradient>
        </defs>
        <g
            id="Group_12958"
            data-name="Group 12958"
            transform="translate(-1103.359 -136.85)"
        >
            <path
                id="Icon_feather-shield"
                data-name="Icon feather-shield"
                d="M33,71S60,57.4,60,37V13.2L33,3,6,13.2V37C6,57.4,33,71,33,71Z"
                transform="translate(1097.359 133.85)"
                fill="url(#linear-gradient)"
            />
        </g>
    </svg>
);
