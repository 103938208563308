import { TaskFilterExamType } from "../../typings";

export const getShieldExamName = (filter: TaskFilterExamType) => {
    switch (filter) {
        case "8_kl":
            return "E8";
        case "matura_podstawowa":
            return "MP";
        case "matura_rozszerzona":
            return "MR";
    }
};
