export const TasksIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.148"
        height="14.803"
        viewBox="0 0 16.148 14.803"
    >
        <path
            id="Icon_open-task"
            data-name="Icon open-task"
            d="M0,0V14.8H14.13V7.211L12.111,9.326v3.362H2.019V2.115H9.265L11.284,0ZM14.13,0,8.074,6.344,6.056,4.229,4.037,6.344l4.037,4.229,8.074-8.459Z"
            fill="#2eafbe"
        />
    </svg>
);
