import clsx from "clsx";

import styles from "./StoreGrid.module.css";

type Props = {
    children: JSX.Element | JSX.Element[];
};

export const StoreGrid = ({ children }: Props) => {
    return (
        <div
            className={clsx(
                styles.container,
                !Array.isArray(children) && styles.center
            )}
        >
            {Array.isArray(children) ? (
                children.map((object, index) => (
                    <div key={index} className={styles.element}>
                        {object}
                    </div>
                ))
            ) : (
                <div className={styles.element}>{children}</div>
            )}
        </div>
    );
};
