import { Outlet, useNavigate, useOutlet } from "react-router-dom";

import { getAccountCookie } from "../../../../../services";

import { useMedia } from "../../../../../hooks";
import {
    useDeleteAccountSendCode,
    useNavigationAccount,
    useParentChildrens,
} from "../../../hooks";
import { useDeleteAccountContext } from "../../Dashboard";

import { DefaultButton } from "../../../../../components/buttons";
import { DashboardHeading } from "../../../../../components/headings";
import { AccountContainer } from "../../../../../components/containers";
import { AccountDeleteContent, NavigationAccount } from "../../../components";

import { getDeleteContent } from "./utils";

import styles from "./AccountDelete.module.css";

import pi from "../../../../../assets/images/robots/pi-tears.svg";

export const AccountDelete = () => {
    const { accountType } = getAccountCookie();
    const { isDesktop } = useMedia();

    const outlet = useOutlet();
    const navigate = useNavigate();

    const navigation = useNavigationAccount("Usunięcie konta");

    const parentsChildren = useParentChildrens(
        accountType === "parent" ? true : false
    );
    const deleteAccountCodeSend = useDeleteAccountSendCode();

    const [isDeleteAccountRequested, setIsDeleteAccountRequested] =
        useDeleteAccountContext();

    const handleOnClick = () => {
        if (!isDeleteAccountRequested) {
            deleteAccountCodeSend.mutate(null);

            setIsDeleteAccountRequested(true);
        }

        navigate("confirm");
    };

    return (
        <>
            <DashboardHeading title="MOJE KONTO" subtitle="usunięcie konta" />
            <AccountContainer
                sidebarElement={<NavigationAccount elements={navigation} />}
            >
                <div className={styles.container}>
                    <div>
                        {outlet ? (
                            <Outlet />
                        ) : (
                            <>
                                <AccountDeleteContent>
                                    {getDeleteContent(
                                        accountType,
                                        parentsChildren.data
                                    )}
                                </AccountDeleteContent>
                                <DefaultButton
                                    wrapper={styles.forwardButton}
                                    whileHover={{
                                        opacity: 0.7,
                                    }}
                                    onClick={handleOnClick}
                                >
                                    USUŃ KONTO
                                </DefaultButton>
                            </>
                        )}
                    </div>
                    {!isDesktop && (
                        <div className={styles.footer}>
                            <div className={styles.robot}>
                                <img src={pi} alt="" />
                            </div>
                        </div>
                    )}
                </div>
            </AccountContainer>
        </>
    );
};
