import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { mainApi } from "../../../api";

import { useMedia } from "../../../hooks";
import { useBanner } from "../../dashboard/hooks";

import { studentGetToKnowDataQuery } from "../utils/queries";
import { getPreviousPath } from "../../../utils";
import { userAdvancementDataQuery } from "../../dashboard/utils";

import { NotificationType } from "../typings";

export const useGetToKnow = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { refetch: refetchBanner } = useBanner(true);

    return useMutation(
        (data: {
            scope: "class" | "full";
            class: string;
            grade: string;
            opinion: string;
            redirect: boolean;
        }) =>
            mainApi.post("get_to_know", {
                scope: data.scope,
                class: data.class,
                grade: data.grade,
                opinion: data.opinion,
            }),
        {
            onSuccess: (_, variables) => {
                queryClient.invalidateQueries(
                    studentGetToKnowDataQuery().queryKey
                );
                queryClient.invalidateQueries(
                    userAdvancementDataQuery().queryKey
                );

                if (variables.redirect) {
                    refetchBanner();

                    navigate(`${getPreviousPath(pathname, 2)}/loading`, {
                        state: true,
                    });
                }
            },
        }
    );
};

export const useSubcategoriesPerWeek = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return useMutation(
        (data: { subcategoriesPerWeek: number }) =>
            mainApi.post("subcategories_per_week", data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    studentGetToKnowDataQuery().queryKey
                );

                navigate(`${getPreviousPath(pathname)}/notifications`);
            },
        }
    );
};

export const useStudySchedule = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (data: { times: NotificationType[] }) =>
            mainApi.post("study_schedule", data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    studentGetToKnowDataQuery().queryKey
                );
            },
        }
    );
};

export const useSetCompetencyTestAsDone = (isSkipped?: boolean) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const { refetch: refetchBanner } = useBanner(true);

    return useMutation(() => mainApi.post("set_competency_test_as_done"), {
        onSuccess: () => {
            queryClient.invalidateQueries(studentGetToKnowDataQuery().queryKey);
            queryClient.invalidateQueries(userAdvancementDataQuery().queryKey);

            refetchBanner();

            navigate(
                `${getPreviousPath(pathname, 3)}/competency-test/${
                    isSkipped ? "schedule" : "finished"
                }`,
                { state: true }
            );
        },
    });
};

export const useResetCompetencyTest = () => {
    const { isDesktop } = useMedia();

    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const { refetch: refetchBanner } = useBanner(true);

    return useMutation(() => mainApi.get("reset_competency_test"), {
        onSuccess: () => {
            queryClient.removeQueries(studentGetToKnowDataQuery().queryKey);
            queryClient.invalidateQueries(userAdvancementDataQuery().queryKey);

            refetchBanner();

            navigate(
                `/dashboard/student/competency-test/form/${
                    isDesktop ? "target" : ""
                }`,
                { state: true }
            );
        },
    });
};
