import clsx from "clsx";

import { getVisualIcon } from "./utils";

import styles from "./CounterBox.module.css";

type Props<T extends boolean> = {
    children: React.ReactNode;
    count: T extends true ? "0" | "1" | "2" : string | number;
    isVisual: T;
};

export const CounterBox = <T extends boolean>({
    children,
    count,
    isVisual,
}: Props<T>) => {
    return (
        <div className={clsx(styles.container, isVisual && styles.visual)}>
            <div className={styles.text}>{children}</div>
            <div className={styles.counter}>
                {isVisual
                    ? getVisualIcon(count as "0" | "1" | "2")
                    : typeof count === "number"
                    ? count.toString()
                    : count}
            </div>
        </div>
    );
};
