const colors = {
    success: "#2eafbe",
    error: "#fe5a5e",
    gray: "#E5E7EA",
    darkGray: "#879195",
    white: "#ffffff",
};

const labelActiveProps = {
    top: -16,
    fontSize: "13px",
};

const labelVariants = {
    idle: {
        top: 15,
        color: colors.darkGray,
        fontSize: "16px",
    },
    focused: {
        ...labelActiveProps,
        color: colors.darkGray,
    },
    filled: {
        ...labelActiveProps,
        color: colors.darkGray,
    },
    success: {
        ...labelActiveProps,
        color: colors.success,
    },
    error: {
        ...labelActiveProps,
        color: colors.error,
    },
};

const inputVariants = {
    idle: {
        background: colors.white,
        borderColor: colors.white,
    },
    success: {
        background: colors.white,
        borderColor: colors.success,
    },
    error: {
        background: colors.white,
        borderColor: colors.error,
    },
    disabled: {
        background: colors.gray,
        borderColor: colors.gray,
    },
};

const transition = {
    type: "tween",
    duration: 0.3,
};

export { labelVariants, inputVariants, transition };
