import { useEffect } from "react";
import { useLocation, useNavigation } from "react-router-dom";

import { useRedirectContext } from "../../../../contexts/RedirectContext";

import { useGetCompetencyTest } from "../../hooks/queries";

import { DefaultButton } from "../../../../components/buttons";
import { DashboardHeading } from "../../../../components/headings";
import { NavigationBar, TaskItem } from "../../../tasks/components";
import { ButtonLoader } from "../../../../components/utils";
import { RedirectLoading } from "../../components";

import { useMedia, useRedirectByPop } from "../../../../hooks";
import { createGridRepeat, getPreviousPath } from "../../../../utils";
import { getTestSummaryDescription } from "../../utils";

import { CompetencyTestLocation } from "../../typings";

import styles from "./CompetencyTestFinished.module.css";

export const CompetencyTestFinished = () => {
    const { isDesktop } = useMedia();
    const { pathname, state }: CompetencyTestLocation = useLocation();
    const { state: navigationState } = useNavigation();

    const { setRedirect } = useRedirectContext();

    const getCompetencyTest = useGetCompetencyTest();

    const allSuccessfulExercises =
        getCompetencyTest.data?.data.exercises.filter(
            (exercise) => exercise.already_tried && exercise.done_correctly
        ).length;

    const allExercises = getCompetencyTest.data?.data.exercises.length;

    const backwardPathname = getPreviousPath(pathname, 2);

    useRedirectByPop(backwardPathname);

    useEffect(() => {
        setRedirect(backwardPathname);
    }, [backwardPathname, setRedirect]);

    if (!state) {
        return <RedirectLoading />;
    }

    return (
        <div className={styles.examTasksFinished}>
            <DashboardHeading
                title="TEST KOMPETENCJI"
                subtitle={
                    <>
                        Twój wynik testu to:{" "}
                        <strong>
                            {typeof allSuccessfulExercises === "number" &&
                            !!allExercises
                                ? (
                                      (allSuccessfulExercises / allExercises) *
                                      100
                                  ).toFixed(2)
                                : "0"}
                            %
                        </strong>{" "}
                        poprawnie wykonanych zadań!
                    </>
                }
                to={backwardPathname}
                hasBorder={true}
            />
            {isDesktop &&
                typeof allSuccessfulExercises === "number" &&
                !!allExercises && (
                    <div className={styles.title}>
                        Twój wynik testu to:{" "}
                        <strong>
                            {(
                                (allSuccessfulExercises / allExercises) *
                                100
                            ).toFixed(2)}
                            %
                        </strong>{" "}
                        poprawnie wykonanych zadań!
                    </div>
                )}
            {typeof allSuccessfulExercises === "number" && !!allExercises && (
                <div className={styles.subtitle}>
                    {getTestSummaryDescription(
                        (allSuccessfulExercises / allExercises) * 100
                    )}
                </div>
            )}
            {getCompetencyTest.data && (
                <div
                    style={{
                        gridTemplateRows: createGridRepeat(
                            getCompetencyTest.data.data.exercises.length
                        ),
                    }}
                    className={styles.tasks}
                >
                    {getCompetencyTest.data.data.exercises.map(
                        (exercise, index) => {
                            const isSuccess =
                                exercise.already_tried &&
                                exercise.done_correctly;
                            const isError =
                                exercise.already_tried &&
                                !exercise.done_correctly;

                            return (
                                <TaskItem
                                    key={exercise.id}
                                    to={undefined}
                                    level={exercise.difficulty}
                                    isExam
                                    isSuccess={isSuccess}
                                    isError={isError}
                                >
                                    {`Zadanie ${index + 1}`}
                                </TaskItem>
                            );
                        }
                    )}
                </div>
            )}
            <DefaultButton
                wrapper={styles.button}
                to={`${getPreviousPath(pathname, 1)}/schedule`}
                state={true}
                isDisabled={navigationState === "loading"}
            >
                <ButtonLoader
                    condition={navigationState === "loading"}
                    color="white"
                >
                    PRZEJDŹ DALEJ
                </ButtonLoader>
            </DefaultButton>
            <NavigationBar
                backward={{
                    children: "WRÓĆ DO LISTY TEMATÓW",
                    to: backwardPathname,
                }}
            />
        </div>
    );
};
