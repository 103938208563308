import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { bannerQuery } from "../../utils";

import { HeaderType } from "../../typings";

type BannerResponse = {
    baner: HeaderType & { text_mobile: string; image_mobile: string };
};

export const useBanner = (isDisabled?: boolean) => {
    return useQuery<AxiosResponse<BannerResponse, any>>(
        bannerQuery(isDisabled)
    );
};
