export const getColor = (state: "default" | "important" | "active") => {
    switch (state) {
        case "default":
            return "#2eafbe";
        case "important":
            return "#fe5a5e";
        case "active":
            return "#ffffff";
    }
};
