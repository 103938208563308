import { Portal } from "react-portal";
import { AnimatePresence, motion } from "framer-motion";

import styles from "./ErrorModal.module.css";

type Props = {
    error?: any;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ErrorModal = ({ error, isOpen, setIsOpen }: Props) => {
    return (
        <Portal node={document && document.getElementById("modal-root")}>
            <AnimatePresence mode="wait">
                {isOpen && (
                    <motion.div
                        className={styles.modal}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ type: "tween", duration: 0.2 }}
                    >
                        <div className={styles.wrapper}>
                            <div className={styles.dialog}>
                                <motion.button
                                    id="modalCloseButton"
                                    className={styles.close}
                                    type="button"
                                    whileHover={{ opacity: 1 }}
                                    transition={{
                                        type: "tween",
                                        duration: 0.2,
                                    }}
                                    onClick={() => setIsOpen(false)}
                                ></motion.button>
                                <div className={styles.body}>
                                    <div className={styles.error}>
                                        <div className={styles.message}>
                                            {error.name}: {error.message}
                                        </div>
                                        <div className={styles.stack}>
                                            {error instanceof ReferenceError ||
                                            error instanceof TypeError ||
                                            error instanceof SyntaxError ||
                                            error instanceof EvalError
                                                ? error.stack
                                                : JSON.stringify(
                                                      error,
                                                      null,
                                                      4
                                                  )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </Portal>
    );
};
