import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigation } from "react-router-dom";
import clsx from "clsx";

import { useAuthContext } from "../../../../contexts/AuthContext";

import { getAccountCookie } from "../../../../services";

import { useStorageFlag } from "../../hooks";
import { useIsHomeDashboard } from "../../../../hooks";

import { NavbarButton } from "../NavbarButton";
import { NavbarBrand } from "../NavbarBrand";
import { DefaultModal } from "../../../../components/modals";

import { getOperatingSystem } from "../../../../utils";

import { getCollapseVariants } from "./Navbar.animations";

import styles from "./Navbar.module.css";

type Props = {
    children?: JSX.Element | JSX.Element[];
    isCollapsed?: boolean;
    setIsCollapsed?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Navbar = ({ children, isCollapsed, setIsCollapsed }: Props) => {
    const { accountType } = getAccountCookie();

    const { state } = useNavigation();
    const { logout } = useAuthContext();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const isHome = useIsHomeDashboard();
    useStorageFlag(["popOpen", "modal"], isModalOpen);
    const operatingSystem = getOperatingSystem();

    const isNavigationLoading = state === "loading";

    useEffect(() => {
        const dashboardContainerElement = document.querySelector(
            "#dashboardContainer"
        ) as Element;

        if (isCollapsed) {
            dashboardContainerElement.classList.add(styles.collapsed);
        } else {
            dashboardContainerElement.classList.remove(styles.collapsed);
        }

        return () => {
            if (isCollapsed) {
                dashboardContainerElement.classList.remove(styles.collapsed);
            }
        };
    }, [isCollapsed]);

    return (
        <>
            <div className={styles.container}>
                <motion.div
                    className={styles.collapse}
                    initial={isCollapsed ? "closed" : "open"}
                    animate={isCollapsed ? "closed" : "open"}
                    variants={getCollapseVariants(operatingSystem)}
                    transition={{ type: "tween", duration: 0.5 }}
                >
                    {children}
                </motion.div>
                <div className={styles.menu}>
                    <div className={styles.menuBlock}>
                        <NavbarButton
                            icon="user"
                            to="account"
                            isHidden={isCollapsed}
                            isDisabled={isCollapsed || isNavigationLoading}
                        >
                            MOJE KONTO
                        </NavbarButton>
                    </div>
                    <div className={clsx(styles.menuBlock, styles.center)}>
                        <NavbarBrand
                            accountType={accountType}
                            isAction={isCollapsed}
                            isDisabled={
                                !isCollapsed &&
                                (isHome || isCollapsed || isNavigationLoading)
                            }
                            onClick={
                                isCollapsed
                                    ? () => {
                                          if (setIsCollapsed) {
                                              setIsCollapsed(false);
                                          }
                                      }
                                    : undefined
                            }
                        />
                    </div>
                    <div className={clsx(styles.menuBlock, styles.right)}>
                        <NavbarButton
                            icon="logout"
                            isReversed={true}
                            isHidden={isCollapsed}
                            isDisabled={isCollapsed || isNavigationLoading}
                            onClick={() => setIsModalOpen(true)}
                        >
                            WYLOGUJ
                        </NavbarButton>
                    </div>
                </div>
            </div>
            <DefaultModal
                title="WYLOGUJ"
                actions={[
                    {
                        name: "TAK, WYLOGUJ",
                        isImportant: true,
                        onClick: logout,
                    },
                    {
                        name: "NIE, WRÓĆ",
                        onClick: () => setIsModalOpen(false),
                    },
                ]}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
            >
                <p>
                    Czy na pewno chcesz się
                    <br />
                    wylogować?
                </p>
            </DefaultModal>
        </>
    );
};
