import clsx from "clsx";
import { useState } from "react";

import magifyingGlassImg from "../../../assets/images/dashboard/answerOnDemand/magnifying-glass.svg";
import { DefaultModal } from "../../modals";
import styles from "./FileInput.module.css";

type Props = {
    file?: File;
    preview?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    isDisabled?: boolean;
    accept?: string;
};
export const FileInput = ({
    file,
    preview,
    onChange,
    isDisabled,
    accept = "image/jpeg",
}: Props) => {
    const [previewModal, setPreviewModal] = useState(false);
    const imgSrc = preview ?? (file && URL.createObjectURL(file));

    return (
        <div className={styles.root}>
            <label
                className={clsx(styles.label, isDisabled && styles.disabled)}
            >
                <div
                    className={clsx(
                        styles.backgroundLayer,
                        (file || preview) && styles.filePresent,
                        isDisabled && styles.disabled
                    )}
                />

                {imgSrc && <img src={imgSrc} alt={file?.name} />}
                <input type="file" accept={accept} onChange={onChange} />
            </label>
            <button
                type="button"
                className={styles.enlargeButton}
                disabled={!imgSrc}
                onClick={() => setPreviewModal(true)}
            >
                <img src={magifyingGlassImg} alt="Powiększ" />
            </button>
            <DefaultModal
                isOpen={previewModal}
                actions={[
                    { name: "Zamknij", onClick: () => setPreviewModal(false) },
                ]}
                setIsOpen={setPreviewModal}
                title="Podgląd"
                dialogClassname={styles.dialog}
            >
                {imgSrc && (
                    <img
                        className={styles.previewImg}
                        src={imgSrc}
                        alt={file?.name}
                    />
                )}
            </DefaultModal>
        </div>
    );
};
