import { BubbleIcons } from "../NavigationBubble.typings";

import { ReactComponent as BooksIcon } from "../images/books-icon.svg";
import { ReactComponent as SchoolIcon } from "../images/school-icon.svg";
import { ReactComponent as RocketIcon } from "../images/rocket-icon.svg";
import { ReactComponent as TestIcon } from "../images/test-icon.svg";
import { ReactComponent as BulbIcon } from "../images/bulb-icon.svg";

export const getIcon = (icon: BubbleIcons) => {
    switch (icon) {
        case "books":
            return <BooksIcon />;
        case "school":
            return <SchoolIcon />;
        case "rocket":
            return <RocketIcon />;
        case "test":
            return <TestIcon />;
        case "bulb":
            return <BulbIcon />;
    }
};
