import { QueryClient } from "@tanstack/react-query";
import { LoaderFunctionArgs } from "react-router-dom";

import { getAccountCookie } from "../../../../services";

import { aiSessionTheoryQuery } from "../queries";

export const aiSessionTheoryLoader =
    (queryClient: QueryClient) =>
    async ({ params }: LoaderFunctionArgs) => {
        const { accountType } = getAccountCookie();

        const sessionId = params.sessionId;

        if (accountType !== "student" || !sessionId) {
            return undefined;
        }

        const query = aiSessionTheoryQuery(sessionId);

        return (
            queryClient.getQueryData(query.queryKey) ??
            queryClient.fetchQuery(query)
        );
    };
