
const transition = {
    type: "tween",
    duration: 0.3,
};

const collapseAnimations = {
    initial: {
        height: 0,
    },
    animate: {
        height: "auto",
    },
    exit: {
        height: 0,
    },
    transition
};

const arrowVariants = {
    closed: {
        rotate: 0,
    },
    open: {
        rotate: -180,
    },
};

export { collapseAnimations, arrowVariants, transition };
