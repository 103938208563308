import { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { Formik, Form } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { useLoadingContext } from "../../../../../contexts/LoadingContext";

import { useDeleteAccount, useDeleteAccountSendCode } from "../../../hooks";
import { useSubmitForm } from "../../../../../hooks";

import { DefaultButton } from "../../../../../components/buttons";
import { DefaultInput } from "../../../../../components/inputs";
import { ActionText, ErrorText } from "../../../../../components/texts";
import { AccountDeleteContent } from "../../../components";

import { getPreviousPath } from "../../../../../utils";
import { accountDeleteSchema } from "../../../utils";

import styles from "./AccountDeleteConfirmation.module.css";

const AnimatedForm = motion(Form);

type Props = {
    mutation: ReturnType<typeof useDeleteAccount>;
};

export const AccountDeleteConfirmation = ({ mutation }: Props) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const deleteAccountCodeSend = useDeleteAccountSendCode();

    const { setLoadingActive } = useLoadingContext();
    const submitForm = useSubmitForm();

    const isFormSubmitted = useRef(false);
    const [errorResponse, setErrorResponse] = useState<[boolean, string]>([
        false,
        "",
    ]);

    useEffect(() => {
        if (mutation.error && isFormSubmitted.current) {
            isFormSubmitted.current = false;

            setErrorResponse([
                true,
                mutation.error.response.data.messages.error,
            ]);

            setLoadingActive(false);
        }
    }, [mutation.error, setLoadingActive]);

    useEffect(() => {
        if (mutation.isSuccess) {
            setLoadingActive(false, () => {
                navigate("/account-deleted", {
                    state: {
                        isDeleted: true,
                    },
                });

                mutation.reset();
            });
        }
    }, [mutation, setLoadingActive, navigate]);

    const handleCodeResend = () => {
        deleteAccountCodeSend.mutate(null);

        navigate(`${getPreviousPath(pathname)}/resend`, {
            state: {
                isResend: true,
            },
        });
    };

    return (
        <>
            <AccountDeleteContent>
                <p>
                    W celu usunięcia konta wpisz poniżej aktualne
                    <br />
                    hasło oraz kod potwierdzający, który właśnie
                    <br />
                    wysłaliśmy na Twój adres e-mail.
                </p>
            </AccountDeleteContent>
            <Formik
                initialValues={{
                    password: "",
                    code: "",
                }}
                validationSchema={accountDeleteSchema}
                onSubmit={(data, actions) => {
                    isFormSubmitted.current = true;

                    setLoadingActive(true, () => {
                        submitForm(data);

                        actions.setSubmitting(false);
                    });
                }}
            >
                {(formik) => (
                    <AnimatedForm
                        className={styles.form}
                        initial={{
                            paddingTop: "15px",
                        }}
                        animate={{
                            paddingTop: errorResponse[1] ? "30px" : "15px",
                        }}
                        transition={{ type: "tween", duration: 0.3 }}
                    >
                        <ErrorText isError={!!errorResponse[1]}>
                            {errorResponse[1]}
                        </ErrorText>
                        <DefaultInput
                            label="Hasło"
                            name="password"
                            type="password"
                            isError={!!errorResponse[1]}
                            onChange={() =>
                                setErrorResponse((prevResponse) => [
                                    prevResponse[0],
                                    "",
                                ])
                            }
                        />
                        <DefaultInput
                            label="Kod potwierdzający"
                            name="code"
                            type="text"
                            maxLength={9}
                            isNumeric={true}
                            isMasked={true}
                            isError={!!errorResponse[1]}
                            onChange={() =>
                                setErrorResponse((prevResponse) => [
                                    prevResponse[0],
                                    "",
                                ])
                            }
                        />
                        <DefaultButton
                            wrapper={styles.formButton}
                            initial={{
                                backgroundColor: DefaultButton.color.disabled,
                            }}
                            animate={{
                                backgroundColor:
                                    !formik.dirty || !formik.isValid
                                        ? DefaultButton.color.disabled
                                        : DefaultButton.color.error,
                            }}
                            whileHover={{ opacity: 0.7 }}
                            transition={{
                                type: "tween",
                                duration: 0.3,
                            }}
                            isSubmit={true}
                            isDisabled={!formik.dirty || !formik.isValid}
                        >
                            USUŃ KONTO
                        </DefaultButton>
                    </AnimatedForm>
                )}
            </Formik>
            <ActionText
                className={styles.infoText}
                messages={["Nie mam żadnego kodu."]}
                button="WYŚLIJ PONOWNIE"
                callback={handleCodeResend}
            />
        </>
    );
};
