import { useState, useRef, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import parse from "html-react-parser";
import clsx from "clsx";

import { UtilsButton } from "../../UtilsButton";

import { replaceOptions } from "../../../utils";

import { TaskHintProps } from "./TaskHint.typings";

import "swiper/css";
import styles from "./TaskHint.module.css";

import icon from "./images/bulb-icon.svg";

export const TaskHint = ({ hint, isLoading, isSingle }: TaskHintProps) => {
    const [isOpen, setIsOpen] = useState(false);
    
    const buttonRef = useRef<HTMLButtonElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const closeTooltip = (event: any) => {
        const target = event.target;
        let parent = target;
        let isFound = false;

        while (parent) {
            if (parent === tooltipRef.current || parent === buttonRef.current) {
                isFound = true;

                break;
            }

            parent = parent.parentElement;
        }

        if (!isFound) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen && isLoading) {
            setIsOpen(false);
        }
    }, [isOpen, isLoading]);

    useEffect(() => {
        document.addEventListener("click", closeTooltip);

        return () => {
            document.removeEventListener("click", closeTooltip);
        };
    }, []);

    return (
        <>
            <UtilsButton ref={buttonRef} icon={icon} isDisabled={isLoading} onClick={() => setIsOpen((open) => !open)}>PODPOWIEDŹ</UtilsButton>
            <AnimatePresence mode="wait">
                {isOpen && (
                    <motion.div
                        ref={tooltipRef}
                        className={styles.tooltip}
                        initial={{ y: -12, opacity: 0, scale: 0.8 }}
                        animate={{ y: 0, opacity: 1, scale: 1 }}
                        exit={{ y: -12, opacity: 0, scale: 0.8 }}
                        transition={{ type: "tween", duration: 0.2 }}
                    >
                        <div
                            className={clsx(
                                styles.container,
                                isSingle && styles.single
                            )}
                        >
                            <h4 className={styles.title}>{hint.title}</h4>
                            <button
                                className={styles.close}
                                type="button"
                                onClick={() => setIsOpen(false)}
                            ></button>
                            <Swiper
                                modules={[Navigation, Pagination]}
                                spaceBetween={20}
                                slidesPerView={1}
                                navigation={
                                    isSingle
                                        ? undefined
                                        : {
                                              prevEl: "#swiper-button-prev",
                                              nextEl: "#swiper-button-next",
                                          }
                                }
                                pagination={{
                                    el: "#swiper-pagination",
                                    bulletClass: styles.bullet,
                                    bulletActiveClass: styles.active,
                                    clickable: true,
                                }}
                                speed={300}
                                autoHeight={isSingle ? false : true}
                                loop={!isSingle}
                            >
                                {hint.contents.map((content) => (
                                    <SwiperSlide key={content.id}>
                                        <div className={styles.body}>
                                            {parse(
                                                content.content,
                                                replaceOptions(styles)
                                            )}
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            {!isSingle && (
                                <>
                                    <div
                                        id="swiper-pagination"
                                        className={styles.pagination}
                                    ></div>
                                    <div className={styles.arrows}>
                                        <button
                                            id="swiper-button-prev"
                                            className={clsx(
                                                styles.arrow,
                                                styles.prev
                                            )}
                                            type="button"
                                        ></button>
                                        <button
                                            id="swiper-button-next"
                                            className={clsx(
                                                styles.arrow,
                                                styles.next
                                            )}
                                            type="button"
                                        ></button>
                                    </div>
                                </>
                            )}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};
