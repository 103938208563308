const colors = {
    white: "#FFFFFF",
    black: "#000000",
    grayDark: "#879195",
    primary: "#2DB3BE",
    primaryLight: "#2ac3bb",
    success: "#15B745",
    error: "#FE5A5E",
};

const fieldVariants = {
    default: {
        borderColor: colors.white,
    },
    default_desktop: {
        borderColor: colors.grayDark,
    },
    highlight: {
        borderColor: colors.primary,
    },
    fill: {
        borderColor: colors.primary,
    },
    success: {
        borderColor: colors.success,
    },
    error: {
        borderColor: colors.error,
    },
};

const answerVariants = {
    default: {
        background: colors.white,
        color: colors.black,
        borderColor: colors.white,
        cursor: "grab",
    },
    default_desktop: {
        background: colors.white,
        color: colors.black,
        borderColor: colors.grayDark,
        cursor: "grab",
    },
    drag: {
        background: colors.primary,
        color: colors.white,
        borderColor: colors.primary,
        cursor: "grabbing",
    },
    drop: {
        background: colors.primaryLight,
        color: colors.white,
        borderColor: colors.primaryLight,
        cursor: "grab",
    },
};

const rowVariants = {
    default: {
        marginBottom: 10,
    },
    answered: {
        marginBottom: 5,
    },
};

const transition = {
    type: "tween",
    duration: 0.2,
};

const springTransition = {
    type: "spring",
    stiffness: 200,
    damping: 16,
};

const dragTransition = {
    bounceDamping: 30,
    bounceStiffness: 240,
};

export {
    fieldVariants,
    answerVariants,
    rowVariants,
    transition,
    springTransition,
    dragTransition,
};
