import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useRedirectContext } from "../../../../contexts/RedirectContext";

import { useMedia } from "../../../../hooks";
import { useStudentGetToKnowData, useStudySettings } from "../../hooks/queries";
import { useAiSession } from "../../../tasks/hooks";
import { useIsSubscriptionPaid } from "../../../dashboard/hooks/utils";
import { useUserSubscriptions } from "../../../dashboard/hooks/queries";

import {
    BackgroundContainer,
    TaskContainer,
} from "../../../../components/containers";
import { DashboardHeading } from "../../../../components/headings";
import { SettingsOption } from "../../components";
import { NavigationBar } from "../../../tasks/components";

import { getPreviousPath } from "../../../../utils";

import styles from "./StudySettings.module.css";

import infinityImg from "../../../../assets/images/robots/infinity-pleased.svg";

export const StudySettings = () => {
    const { isSmallerDesktop } = useMedia();

    const { pathname } = useLocation();
    const navigate = useNavigate();

    const { setRedirect } = useRedirectContext();

    const { data: userSubscriptionsData } = useUserSubscriptions();
    const { data: studentData } = useStudentGetToKnowData();
    const { data: studySettingsData } = useStudySettings();
    const { mutate: setAiSession } = useAiSession();

    const isSubscriptionPaid = useIsSubscriptionPaid(
        userSubscriptionsData?.data.subscriptions,
        studentData?.data
    );

    const [loadingRoute, setLoadingRoute] = useState("");

    const backwardPathname = getPreviousPath(pathname, 2);

    useEffect(() => {
        setRedirect(backwardPathname);
    }, [backwardPathname, setRedirect]);

    return (
        <BackgroundContainer
            leftElement={{
                image: infinityImg,
                position: { x: isSmallerDesktop ? 30 : 180, y: 180 },
                size: { height: 458, width: 250 },
            }}
        >
            <TaskContainer>
                <div className={styles.container}>
                    <DashboardHeading
                        title="PLANOWANIE NAUKI"
                        to={backwardPathname}
                        hasBorder={true}
                    />
                    <div className={styles.options}>
                        {studySettingsData && (
                            <>
                                <SettingsOption
                                    title="Cel nauki"
                                    subtitle="Twój cel nauki to:"
                                    content={studySettingsData.data.study_goal}
                                    button={{
                                        children: "ZMIEŃ CEL NAUKI",
                                        onClick: () =>
                                            navigate(
                                                "/dashboard/student/competency-test/form",
                                                {
                                                    state: true,
                                                }
                                            ),
                                    }}
                                    isDisabled={loadingRoute !== ""}
                                    isLoading={loadingRoute === "reset"}
                                />
                                <SettingsOption
                                    title="Powiadomienia"
                                    subtitle="Przypominamy Ci o nauce:"
                                    content={studySettingsData.data.study_schedule.map(
                                        (element) => ({
                                            id: element.id,
                                            children: `${element.day_of_the_week} ${element.time}`,
                                        })
                                    )}
                                    button={{
                                        children: "EDYTUJ POWIADOMIENIA",
                                        onClick: () => {
                                            setLoadingRoute("notifications");

                                            navigate(
                                                `${getPreviousPath(pathname)}/${
                                                    studentData?.data.data
                                                        .subjects_per_week !==
                                                    "0"
                                                        ? "notifications"
                                                        : "schedule"
                                                }`
                                            );
                                        },
                                    }}
                                    isDisabled={loadingRoute !== ""}
                                    isLoading={loadingRoute === "notifications"}
                                />
                            </>
                        )}
                        <SettingsOption
                            title="Ucz się dalej ze Smart Tutor"
                            subtitle="Kontynuuj swoją sesję nauczania"
                            button={{
                                children: "ROZPOCZNIJ SESJĘ",
                                onClick: () => {
                                    setLoadingRoute("ai-session");

                                    if (isSubscriptionPaid) {
                                        setAiSession();
                                    } else {
                                        navigate(
                                            `${getPreviousPath(
                                                pathname,
                                                2
                                            )}/restriction`,
                                            { state: true }
                                        );
                                    }
                                },
                            }}
                            isDisabled={loadingRoute !== ""}
                            isLoading={loadingRoute === "ai-session"}
                        />
                    </div>
                </div>
            </TaskContainer>
            <NavigationBar
                backward={{
                    children: "WRÓĆ",
                    to: backwardPathname,
                }}
            />
        </BackgroundContainer>
    );
};
