import { getAccountTypeFromUrl } from "./getAccountTypeFromUrl";

export const getPathFromUrl = (url: string) => {
    const accountType = getAccountTypeFromUrl(url);
    const dashboardUrlIndex = url.indexOf(`/dashboard/${accountType}/`);
    const isAppUrl = accountType
        ? dashboardUrlIndex !== -1
            ? true
            : false
        : false;

    const path = isAppUrl ? url.substring(dashboardUrlIndex, url.length) : url;

    return {
        url: path,
        isInsideApp: isAppUrl,
    };
};
