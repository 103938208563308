import { QueryClient } from "@tanstack/react-query";
import { LoaderFunctionArgs } from "react-router-dom";

import { getAccountCookie } from "../../../../services";

import { taskSubcategoryQuery } from "../queries";

export const taskSubcategoryLoader =
    (queryClient: QueryClient) =>
    async ({ params }: LoaderFunctionArgs) => {
        const { accountType, userSchool } = getAccountCookie();
        const subcategoryId = params.subcategoryId;

        if (accountType !== "student" || !subcategoryId) {
            return undefined;
        }

        const query = taskSubcategoryQuery(subcategoryId, userSchool.class);

        return (
            queryClient.getQueryData(query.queryKey) ??
            queryClient.fetchQuery(query)
        );
    };
