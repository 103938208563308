import { getColor } from "../utils";
import { IconProps } from "./Icons.typings";

export const LibraIcon = ({ isActive, isImportant }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="30"
        viewBox="0 0 38 30"
    >
        <path
            id="Icon_map-lawyer"
            data-name="Icon map-lawyer"
            d="M.224,20.924a4.9,4.9,0,0,0,9.732,0Zm26.514,10a.774.774,0,0,1-.221.545.755.755,0,0,1-.537.227H11.8a.768.768,0,0,1,0-1.536H25.979A.761.761,0,0,1,26.738,30.925ZM11.124,32.09H26.656v2.221H11.124Zm16.92-11.166a4.9,4.9,0,0,0,9.729,0Zm9.461-1.546-3.985-5.556h2.13a.957.957,0,0,0,0-1.914H24.366a5.607,5.607,0,0,0-4.282-3.641v-3a.949.949,0,0,0-.946-.959l-.088.01-.088-.01a.937.937,0,0,0-.669.28.96.96,0,0,0-.275.678v3a5.6,5.6,0,0,0-4.28,3.641H2.45a.957.957,0,0,0,0,1.914H4.483L.5,19.377H0V20.4H10.182V19.377h-.5L5.7,13.822h9.248A4.1,4.1,0,0,1,15,13.188a.914.914,0,0,0,.055-.295,4.066,4.066,0,0,1,2.96-3.026V20.405h-.04a11.681,11.681,0,0,1-4.707,9.363H24.833a11.7,11.7,0,0,1-4.711-9.363h-.036V9.868a4.065,4.065,0,0,1,2.959,3.026.983.983,0,0,0,.057.295,4.316,4.316,0,0,1,.049.634H32.3l-3.985,5.556h-.5V20.4H38V19.378Zm-32.82,0H1.5l3.181-4.435v4.435Zm.814,0V14.943L8.68,19.378H5.5Zm27,0H29.322L32.5,14.943Zm.81,0V14.943L36.5,19.378ZM6.035,10.571a.944.944,0,1,1-.942-.959.95.95,0,0,1,.942.959Zm27.819-.051a.945.945,0,0,1-.944.953.932.932,0,0,1-.668-.277,1,1,0,0,1,.185-1.536.935.935,0,0,1,.966,0A.959.959,0,0,1,33.854,10.52Z"
            transform="translate(0 -4.311)"
            fill={getColor(
                isActive ? "active" : isImportant ? "important" : "default"
            )}
            opacity="1"
        />
    </svg>
);
