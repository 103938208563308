import { useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { parseCityName } from "../../../../utils";
import { userDataQuery } from "../../utils";

type Mutation = {
    [name: string]: FormDataEntryValue;
};

const parseUserData = (data: Mutation) => {
    const object: { [name: string]: {} | string } = {};

    Object.entries(data).forEach(
        ([key, value]: [string, FormDataEntryValue]) => {
            if (key === "cityId" || key === "cityName") {
                object["city"] = {
                    ...(typeof object["city"] === "object" && object["city"]),
                    ...(key === "cityId"
                        ? { id: value }
                        : parseCityName(value as string)),
                };
            } else if (key !== "agreement" && key !== "is_children") {
                if (key === "birthday") {
                    object[key] = (value as string).replaceAll(".", "-");
                } else {
                    object[key] = value;
                }
            }
        }
    );

    return object;
};

export const useOptimisticUserData = () => {
    const queryClient = useQueryClient();
    const queryKey = userDataQuery().queryKey;

    const updateOptimisticUserData = (data: any) => {
        const currentQuery =
            queryClient.getQueryData<AxiosResponse<any, any>>(queryKey);

        if (currentQuery) {
            const newQuery = {
                ...currentQuery,
                data: {
                    ...currentQuery.data,
                    data: {
                        ...parseUserData(data),
                    },
                },
            };

            queryClient.setQueryData(queryKey, newQuery);
        }
    };

    return updateOptimisticUserData;
};
