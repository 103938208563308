import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

import { getAccountCookie } from "../../../../services";

const ri = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1) + min);

type Mutation = string | void;

export const useAddNotify = () => {
    const { userId } = getAccountCookie();

    return useMutation<AxiosResponse<any, any>, any, Mutation, unknown>(
        (data) =>
            mainApi.post("test_add_notification", {
                title: ri(0, 10000),
                desc: ri(6969, 99999),
                type: "Ważne",
                user_id: userId,
                url: data,
            })
    );
};
