import { useState, useRef, useEffect } from "react";
import { Navigate, Outlet, useOutlet, useSearchParams } from "react-router-dom";

import {
    getInvitationCookie,
    getRegisterCookie,
    removeInvitationCookie,
} from "../../../../services";

import { useRegister } from "../../hooks";
import { useMedia } from "../../../../hooks";

import { ProtectedRoute } from "../../../../components/utils";
import { AuthContainer } from "../../../../components/containers";
import { MainHeading } from "../../../../components/headings";
import { MainLogo } from "../../../../components/logos";
import { ActionText } from "../../../../components/texts";
import { AccountType } from "../../components";

import styles from "./Register.module.css";

import infinity from "../../../../assets/images/robots/infinity-questionmark.svg";
import { InvitationLoader } from "../../../../components/loaders";

type Props = {
    mutation: ReturnType<typeof useRegister>;
};

export const Register = ({ mutation }: Props) => {
    const registerCookie = getRegisterCookie();
    const invitationCookie = getInvitationCookie();

    const outlet = useOutlet();
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token");

    const { isDesktop } = useMedia();

    const [isInvitationError, setIsInvitationError] = useState(false);
    const isRedirectAvailable = useRef(false);

    useEffect(() => {
        if (token && isInvitationError) {
            removeInvitationCookie();

            setSearchParams({});
        }
    }, [token, isInvitationError, setSearchParams]);

    if (registerCookie && !isRedirectAvailable.current && !mutation.isSuccess) {
        isRedirectAvailable.current = true;

        return <Navigate to="confirm" replace={true} />;
    }

    if (invitationCookie && !token && !isRedirectAvailable.current) {
        isRedirectAvailable.current = true;

        return (
            <Navigate to={invitationCookie[1].toLowerCase()} replace={true} />
        );
    }

    return outlet ? (
        <ProtectedRoute redirectWhenLoggedIn={true} redirectPath="/dashboard">
            <Outlet />
        </ProtectedRoute>
    ) : (
        <ProtectedRoute redirectWhenLoggedIn={true} redirectPath="/dashboard">
            <AuthContainer
                image={infinity}
                imageSize={isDesktop ? 415 : 240}
                imageOffsetX={-24}
                imageOffsetY={4.4444}
            >
                <MainLogo isCenter={true} />
                <MainHeading wrapper={styles.heading}>REJESTRACJA</MainHeading>
                {!token || isInvitationError ? (
                    <>
                        <AccountType />
                        <ActionText
                            messages={["Mam już konto."]}
                            button="ZALOGUJ"
                            to="/"
                        />
                    </>
                ) : (
                    <InvitationLoader
                        token={token}
                        setIsError={setIsInvitationError}
                    />
                )}
            </AuthContainer>
        </ProtectedRoute>
    );
};
