import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { mainApi } from "../../../api";
import { FormFields, Source } from "../typings";
import { getSolutionOnDemandListQuery } from "../utils/queries";

export const useAddSolutionOnDemand = () => {
    const queryClient = useQueryClient();

    return useMutation<
        AxiosResponse,
        AxiosError<{ messages: Record<keyof FormFields, string> }>,
        {
            source: Source;
            title?: string;
            page_number?: string;
            task_number?: string;
            file1?: File;
            file2?: File;
            notes?: string;
        }
    >((data) => mainApi.post("add_solution_on_demand", data), {
        onSuccess: () =>
            queryClient.invalidateQueries(
                getSolutionOnDemandListQuery().queryKey
            ),
    });
};

export const useSetSolutionOnDemandAsRead = () => {
    const queryClient = useQueryClient();

    return useMutation<
        AxiosResponse,
        AxiosError,
        {
            id: string;
        }
    >((data) => mainApi.post(`set_solution_on_demand_as_read/${data.id}`), {
        onSuccess: () =>
            queryClient.invalidateQueries(
                getSolutionOnDemandListQuery().queryKey
            ),
    });
};
