import { CircleLoader } from "../../../../components/loaders";
import { useUserSavedCards } from "../../hooks";

import { CardItem } from "../CardItem";

import styles from "./Cards.module.css";

export const Cards = () => {
    const { data: userSavedCards } = useUserSavedCards();

    return (
        <>
            <div className={styles.container}>
                <div className={styles.title}>KARTY</div>
                <div className={styles.list}>
                    {userSavedCards ? (
                        userSavedCards.data.length > 0 ? (
                            userSavedCards.data.map((card) => (
                                <CardItem
                                    key={card.id}
                                    id={card.id}
                                    digits={`**** **** **** ${card.card_last_digits}`}
                                />
                            ))
                        ) : (
                            <p className={styles.empty}>Brak podpiętych kart</p>
                        )
                    ) : (
                        <div className={styles.loading}>
                            <CircleLoader size="24" />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
