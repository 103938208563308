import { useNavigation } from "react-router-dom";

import { StatusBar } from "../StatusBar";
import { TaskForm } from "../TaskForm";
import { TaskContent, TaskUtils } from "../build-components";

import { BuildProps } from "./TaskBuild.typings";

import styles from "./TaskBuild.module.css";

export const TaskBuild = <B extends boolean = false>({
    taskId,
    content,
    answers,
    hint,
    sheets,
    level,
    count,
    isExamFilter,
    setIsNoteOpen,
}: BuildProps<B>) => {
    const { state } = useNavigation();
    const isLoadingState = state === "loading";

    return (
        <div className={styles.container}>
            <StatusBar
                sheets={sheets}
                level={level}
                count={count}
                isExamFilter={isExamFilter}
                isLargerOnDesktop={true}
            />
            <TaskContent content={content} />
            <TaskUtils
                hint={hint}
                isLoading={isLoadingState}
                setIsNoteOpen={setIsNoteOpen}
            />
            <TaskForm
                taskId={taskId}
                answers={answers}
                isLoading={isLoadingState}
            />
        </div>
    );
};
