import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { aiSessionTheoryQuery } from "../../utils";

type Response = {
    session_exercise_id: string;
    theory_content: string;
    theory_id: string;
    theory_name: string;
};

export const useAiSessionTheory = (sessionId: string) => {
    return useQuery<AxiosResponse<Response, any>>(
        aiSessionTheoryQuery(sessionId)
    );
};
