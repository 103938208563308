import { useEffect, useState } from "react";
import {
    Navigate,
    Outlet,
    useLocation,
    useNavigate,
    useOutlet,
} from "react-router-dom";

import { getAccountCookie } from "../../../../../services";

import { useIsHomeDashboard, useMedia } from "../../../../../hooks";
import { useDeleteAccountContext } from "../../Dashboard";

import { useClearTaskParams } from "../../../../tasks/hooks";
import {
    useChildrenParents,
    useStorageFlag,
    useUserImportantNotification,
} from "../../../hooks";
import { useUserAdvancementData } from "../../../hooks/queries/useUserAdvancementData";

import { DashboardContainer } from "../../../../../components/containers";
import { WideHeading } from "../../../../../components/headings";
import { VideoModal } from "../../../../../components/modals";
import {
    Brand,
    HeaderWrapper,
    HomeWrapper,
    Navbar,
    NavbarDesktop,
    NavigationBubbles,
    NotificationAlert,
    Notifications,
    NotificationsBackdrop,
    NotificationsDesktop,
    NotificationSubscription,
} from "../../../components";

import { getUserAdvancement } from "../../../utils";

import { useAuthContext } from "../../../../../contexts/AuthContext";
import styles from "./DashboardStudent.module.css";

export const DashboardStudent = () => {
    const {
        userId,
        accountType,
        isSubscriptionPaid,
        isAccountDisabled,
        isVideoWatched,
    } = getAccountCookie();
    const { isDesktop } = useMedia();

    const outlet = useOutlet();
    const { pathname } = useLocation();

    const deleteAccountContext = useDeleteAccountContext();
    const userImportantNotification = useUserImportantNotification();
    const userAdvancementData = useUserAdvancementData();

    useChildrenParents();

    const isHome = useIsHomeDashboard();
    const isAiSessionFinished = pathname.indexOf("ai-session/finished") !== -1;

    const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
    useStorageFlag(["popOpen", "notifications"], isNotificationsOpen);

    useClearTaskParams();

    const [isVideoModalOpen, setIsVideoModalOpen] = useState(!isVideoWatched);

    const navigate = useNavigate();

    const { isStorePageDisplayed } = useAuthContext();

    useEffect(() => {
        if (!isSubscriptionPaid && !isStorePageDisplayed) {
            navigate("/dashboard/student/store");
        }
    }, []);

    if (isAccountDisabled) {
        return <Navigate to={`/dashboard/disabled`} replace={true} />;
    }

    if (accountType !== "student") {
        return <Navigate to={`/dashboard/${accountType}`} replace={true} />;
    }

    return isAiSessionFinished ? (
        <Outlet />
    ) : (
        <>
            <DashboardContainer
                outsideElement={
                    isDesktop ? (
                        <>
                            <NavbarDesktop>
                                <NotificationsDesktop
                                    isOpen={isNotificationsOpen}
                                    setIsOpen={setIsNotificationsOpen}
                                />
                            </NavbarDesktop>
                            {isHome && (
                                <WideHeading className={styles.heading}>
                                    PANEL UCZNIA
                                </WideHeading>
                            )}
                        </>
                    ) : (
                        <Navbar
                            isCollapsed={isNotificationsOpen}
                            setIsCollapsed={setIsNotificationsOpen}
                        >
                            <Notifications
                                isOpen={isNotificationsOpen}
                                setIsOpen={setIsNotificationsOpen}
                            />
                        </Navbar>
                    )
                }
            >
                {outlet ? (
                    <Outlet context={deleteAccountContext} />
                ) : (
                    <>
                        {!isDesktop && (
                            <Brand
                                onClick={() => setIsNotificationsOpen(true)}
                            />
                        )}
                        {!isSubscriptionPaid && userId !== "2304" && (
                            <NotificationSubscription>
                                Korzystasz obecnie z darmowej wersji, masz
                                dostęp jedynie do 5% materiałów.
                            </NotificationSubscription>
                        )}
                        <NotificationAlert result={userImportantNotification} />
                        <HeaderWrapper />
                        <HomeWrapper>
                            <NavigationBubbles
                                elements={[
                                    {
                                        children: isDesktop ? (
                                            "Baza zadań"
                                        ) : (
                                            <>
                                                Baza
                                                <br />
                                                zadań
                                            </>
                                        ),
                                        icon: "books",
                                        to: "tasks",
                                    },
                                    {
                                        children: isDesktop ? (
                                            "Zadania egzaminacyjne"
                                        ) : (
                                            <>
                                                Zadania
                                                <br />
                                                egzaminacyjne
                                            </>
                                        ),
                                        icon: "school",
                                        to: "exam-tasks",
                                    },
                                    {
                                        type: "stats",
                                        children: "Postępy nauki",
                                        icon: "rocket",
                                        to: "learning-progress",
                                        stats: [
                                            {
                                                name: "Twój cel nauki",
                                                progress: undefined,
                                                studyGoal:
                                                    userAdvancementData.data
                                                        ? userAdvancementData
                                                              .data.data
                                                              .study_goal !== ""
                                                            ? userAdvancementData
                                                                  .data.data
                                                                  .study_goal
                                                            : "Brak wybranego celu nauki"
                                                        : "Brak wybranego celu nauki",
                                            },
                                            {
                                                name: "Liczba zadań",
                                                progress: getUserAdvancement(
                                                    userAdvancementData.data
                                                        ?.data.exercises
                                                ),
                                                studyGoal: undefined,
                                            },
                                            {
                                                name: "Twój postęp nauki",
                                                progress: getUserAdvancement(
                                                    userAdvancementData.data
                                                        ?.data.advancement
                                                ),
                                                studyGoal: undefined,
                                            },
                                        ],
                                        isFetching:
                                            userAdvancementData.isLoading,
                                        isError: userAdvancementData.isError,
                                    },
                                    {
                                        children: isDesktop ? (
                                            "Planowanie nauki"
                                        ) : (
                                            <>
                                                Planowanie
                                                <br />
                                                nauki
                                            </>
                                        ),
                                        icon: "test",
                                        to: isSubscriptionPaid
                                            ? "competency-test"
                                            : "restriction",
                                        state: !isSubscriptionPaid,
                                    },
                                    {
                                        children: isDesktop ? (
                                            "Rozwiązanie na zawołanie"
                                        ) : (
                                            <>
                                                Rozwiązanie
                                                <br />
                                                na zawołanie
                                            </>
                                        ),
                                        icon: "bulb",
                                        to: "answer-on-demand",
                                        isDisabled: true,
                                    },
                                ]}
                            />
                        </HomeWrapper>
                    </>
                )}
            </DashboardContainer>
            {!isDesktop && (
                <NotificationsBackdrop isOpen={isNotificationsOpen} />
            )}
            <VideoModal
                isOpen={isVideoModalOpen}
                setIsOpen={setIsVideoModalOpen}
            />
        </>
    );
};
