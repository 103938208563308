import axios from "axios";

import { buildFormData, getTokenCookie } from "../services";

export const mainApi = axios.create({
    baseURL: process.env.REACT_APP_API_BASEURL,
    headers: {
        "Content-Type": "multipart/form-data",
    },
    transformRequest: [(data) => buildFormData(data)],
});

mainApi.interceptors.request.use(
    (config) => {
        if (getTokenCookie()) {
            config.headers!.Authorization = `Bearer ${getTokenCookie()}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);
