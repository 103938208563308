import { useState } from "react";
import {
    Navigate,
    useLocation,
    useNavigate,
    useNavigation,
} from "react-router-dom";

import { useUserSubscriptions } from "../../dashboard/hooks/queries";
import { useStudentGetToKnowData } from "../../competencyTest/hooks/queries";
import { useIsSubscriptionPaid } from "../../dashboard/hooks/utils";

import { useMedia } from "../../../hooks";

import { BackgroundContainer } from "../../../components/containers";
import { DashboardHeading } from "../../../components/headings";
import { NotificationSubscription } from "../../dashboard/components";
import { DefaultButton } from "../../../components/buttons";
import { ButtonLoader } from "../../../components/utils";

import { getPreviousPath } from "../../../utils";

import styles from "./FreeRestriction.module.css";

import pi from "../../../assets/images/robots/pi-confused.svg";

type LocationType = {
    pathname: string;
    state: boolean | null;
};

export const FreeRestriction = () => {
    const { isDesktop, isSmallerDesktop } = useMedia();

    const { pathname, state }: LocationType = useLocation();
    const navigate = useNavigate();
    const { state: navigationState } = useNavigation();

    const { data: userSubscriptionsData } = useUserSubscriptions();
    const { data: studentData } = useStudentGetToKnowData();

    const isSubscriptionPaid = useIsSubscriptionPaid(
        userSubscriptionsData?.data.subscriptions,
        studentData?.data
    );

    const [loadingRoute, setLoadingRoute] = useState("");

    if (isSubscriptionPaid) {
        return <Navigate to="/dashboard/student" replace={true} />;
    }

    if (!state) {
        return <Navigate to={getPreviousPath(pathname)} replace={true} />;
    }

    return (
        <BackgroundContainer
            rightElement={{
                image: pi,
                size: {
                    width: 328,
                    height: 246,
                },
                position: {
                    x: isSmallerDesktop ? -60 : -140,
                    y: -30,
                },
            }}
        >
            <div className={styles.container}>
                <DashboardHeading title="BRAK ABONAMENTU" />
                <div className={styles.main}>
                    <NotificationSubscription>
                        Korzystasz obecnie z darmowej wersji, masz dostęp
                        jedynie do 5% materiałów.
                    </NotificationSubscription>
                    <h3 className={styles.title}>
                        Ta funkcja jest niedostępna
                        <br />w darmowej wersji Smart Tutor
                    </h3>
                    <div className={styles.buttons}>
                        <DefaultButton
                            wrapper={styles.button}
                            whileHover={{
                                opacity: 0.7,
                            }}
                            transition={{
                                type: "tween",
                                duration: 0.3,
                            }}
                            isDisabled={loadingRoute !== ""}
                            onClick={() => {
                                setLoadingRoute("store");

                                navigate(`${getPreviousPath(pathname)}/store`);
                            }}
                        >
                            <ButtonLoader
                                condition={
                                    navigationState === "loading" &&
                                    loadingRoute === "store"
                                }
                                color="white"
                            >
                                WYKUP ABONAMENT
                            </ButtonLoader>
                        </DefaultButton>
                        <DefaultButton
                            wrapper={styles.button}
                            initial={{
                                backgroundColor: DefaultButton.color.white,
                                color: DefaultButton.color.enabled,
                                border: `1px solid ${DefaultButton.color.enabled}`,
                            }}
                            animate={{
                                backgroundColor: DefaultButton.color.white,
                                color: DefaultButton.color.enabled,
                                border: `1px solid ${DefaultButton.color.enabled}`,
                            }}
                            whileHover={{
                                opacity: 0.7,
                            }}
                            transition={{
                                type: "tween",
                                duration: 0.3,
                            }}
                            isDisabled={loadingRoute !== ""}
                            onClick={() => {
                                setLoadingRoute("dashboard");
                                navigate(getPreviousPath(pathname));
                            }}
                        >
                            <ButtonLoader
                                condition={
                                    navigationState === "loading" &&
                                    loadingRoute === "dashboard"
                                }
                            >
                                STRONA GŁÓWNA
                            </ButtonLoader>
                        </DefaultButton>
                    </div>
                </div>
                {!isDesktop && (
                    <div className={styles.robot}>
                        <img src={pi} alt="" />
                    </div>
                )}
            </div>
        </BackgroundContainer>
    );
};
