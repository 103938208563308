import { mainApi } from "../../../../api";

const key = "task";

export const taskQuery = (id: { taskId: string; examId?: string }) => ({
    queryKey: id.examId ? [key, id.taskId, id.examId] : [key, id.taskId],
    queryFn: async () =>
        mainApi.post(
            `get_exercise/${id.taskId}`,
            id.examId && {
                exam: id.examId,
            }
        ),
    enabled: false
});
