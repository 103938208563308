import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";

import { mainApi } from "../../../../api";

import { setTaskParams } from "../../services";

import { getPreviousPath } from "../../../../utils";
import { taskSlug } from "../../utils";

type Response = {
    next_exercise_id: string | false;
    exam_id: string;
};

type Mutation = {
    taskId: string;
    examId: string;
};

export const useGetNextExamTask = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return useMutation<AxiosResponse<Response, any>, any, Mutation, unknown>(
        (data) =>
            mainApi.post("get_next_exam_exercise_id", {
                id_exam: data.examId,
                id_exercise: data.taskId,
            }),
        {
            onSuccess: (data) => {
                const taskId = data.data.next_exercise_id;
                const examId = data.data.exam_id;

                if (taskId) {
                    setTaskParams({
                        type: taskSlug.exercise,
                        taskId,
                        additionalId: examId,
                    });

                    navigate(`${getPreviousPath(pathname)}/${taskId}`);
                } else {
                    navigate(
                        `${getPreviousPath(pathname, 3)}/exam-tasks/${examId}`
                    );
                }
            },
        }
    );
};
