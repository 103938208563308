import { Navigate, useLocation } from "react-router-dom";

import { useAuthContext } from "../../../../contexts/AuthContext";

import { useMedia } from "../../../../hooks";

import { AuthContainer } from "../../../../components/containers";
import { MainHeading } from "../../../../components/headings";
import { AuthText } from "../../../../components/texts";
import { DefaultButton } from "../../../../components/buttons";
import { DisableBack } from "../../../../components/utils";

import styles from "./RegisterConfirmed.module.css";

import pi from "../../../../assets/images/robots/pi-success.svg";

type StateType = {
    state: { isConfirmed?: boolean } | null;
};

export const RegisterConfirmed = () => {
    const { isDesktop } = useMedia();

    const { state }: StateType = useLocation();
    const { setIsUserLogged } = useAuthContext();

    if (!state?.isConfirmed) {
        return <Navigate to="/register" replace={true} />;
    }

    return (
        <>
            <AuthContainer
                position="center"
                image={pi}
                imageSize={isDesktop ? 284 : 214}
                imagePosition="right"
                imageOffsetX={isDesktop ? 41 : undefined}
                imageOffsetY={isDesktop ? 15.5556 : undefined}
            >
                <MainHeading wrapper={styles.heading} elementType="h2">
                    Rejestracja potwierdzona
                </MainHeading>
                <AuthText>
                    <p>
                        Możesz już zacząć swoją przygodę
                        <br />
                        ze <strong>Smart Tutor</strong>!
                    </p>
                </AuthText>
                <DefaultButton
                    wrapper={styles.forwardButton}
                    whileHover={{ opacity: 0.7 }}
                    onClick={() => setIsUserLogged(true)}
                >
                    PRZEJDŹ DO PANELU
                </DefaultButton>
            </AuthContainer>
            <DisableBack />
        </>
    );
};
