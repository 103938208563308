import { Navigate, useLocation } from "react-router-dom";

import { getAccountCookie } from "../../../services";

import { useAuthContext } from "../../../contexts/AuthContext";

import { useMedia } from "../../../hooks";

import { AuthContainer } from "../../../components/containers";
import { MainHeading } from "../../../components/headings";
import { AuthText } from "../../../components/texts";
import { DefaultButton } from "../../../components/buttons";

import styles from "./SessionExpired.module.css";

import pi from "../../../assets/images/robots/pi-sad.svg";

type LocationType = {
    state: { sessionExpired: boolean } | null;
};

export const SessionExpired = () => {
    const { accountType } = getAccountCookie();
    const { isDesktop } = useMedia();

    const { state }: LocationType = useLocation();

    const { logout } = useAuthContext();

    if (!state?.sessionExpired) {
        return <Navigate to={`/dashboard/${accountType}`} replace={true} />;
    }

    return (
        <AuthContainer
            position="center"
            image={pi}
            imageSize={201}
            imagePosition="right"
            imageOffsetX={isDesktop ? 41 : undefined}
            imageOffsetY={isDesktop ? 15.5556 : undefined}
        >
            <MainHeading wrapper={styles.heading} elementType="h2">
                WYLOGOWANO
            </MainHeading>
            <AuthText>
                <p>
                    Twoja sesja wygasła lub zalogowałeś/aś się
                    <br />
                    na innym urządzeniu.
                </p>
                <p>Jeśli to nie Ty - zmień hasło do swojego konta!</p>
            </AuthText>
            <DefaultButton
                wrapper={styles.forwardButton}
                onClick={() => logout(true)}
            >
                ZALOGUJ SIĘ PONOWNIE
            </DefaultButton>
        </AuthContainer>
    );
};
