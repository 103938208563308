import { useState } from "react";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

import { DifficultyStatus } from "../DifficultyStatus";
import { ExamBadge } from "../ExamBadge";
import { CheckmarkIcon, XMarkIcon } from "../icon-components";
import { CircleLoader } from "../../../../components/loaders";

import { buttonVariants, contentVariants } from "./TaskItem.animations";

import { TaskItemProps } from "./TaskItem.typings";

import styles from "./TaskItem.module.css";

const AnimatedLink = motion(Link);

export const TaskItem = ({
    children,
    to,
    level,
    isExam,
    isSuccess,
    isError,
    isLoading,
    onClick,
}: TaskItemProps) => {
    const [isClicked, setIsClicked] = useState(false);

    const handleOnClick = () => {
        setIsClicked(true);

        if (onClick) {
            onClick();
        }
    };

    return (
        <AnimatedLink
            className={clsx(
                styles.button,
                (!to || isLoading) && styles.disabled
            )}
            to={to ?? ""}
            variants={buttonVariants}
            initial={isSuccess ? "success" : isError ? "error" : "default"}
            animate={isSuccess ? "success" : isError ? "error" : "default"}
            transition={{ type: "tween", duration: 0.3 }}
            onClick={to ? handleOnClick : undefined}
        >
            {isExam && (
                <span className={styles.badge}>
                    <ExamBadge
                        status={
                            isSuccess
                                ? "success"
                                : isError
                                ? "error"
                                : undefined
                        }
                        hasBorder={true}
                    />
                </span>
            )}
            <AnimatePresence initial={false}>
                {(isSuccess || isError) && (
                    <motion.span
                        key={
                            isSuccess
                                ? "is-success"
                                : isError
                                ? "is-error"
                                : "default-state"
                        }
                        className={styles.icon}
                        initial={{
                            opacity: 0,
                            scale: 0.5,
                        }}
                        animate={{
                            opacity: 1,
                            scale: 1,
                        }}
                        exit={{
                            opacity: 0,
                            scale: 0.5,
                        }}
                        transition={{ type: "tween", duration: 0.3 }}
                    >
                        {isSuccess ? (
                            <CheckmarkIcon isSuccess={true} />
                        ) : (
                            isError && <XMarkIcon />
                        )}
                    </motion.span>
                )}
            </AnimatePresence>
            <motion.span
                className={styles.content}
                variants={contentVariants}
                initial={isSuccess || isError ? "finished" : "default"}
                animate={isSuccess || isError ? "finished" : "default"}
                transition={{ type: "tween", duration: 0.3 }}
            >
                {children}
            </motion.span>
            {isLoading && isClicked ? (
                <span className={styles.loading}>
                    <CircleLoader size="24" />
                </span>
            ) : (
                <span className={styles.difficulty}>
                    <DifficultyStatus level={level} />
                </span>
            )}
        </AnimatedLink>
    );
};
