export const ExclamationIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
    >
        <g id="Group_483" data-name="Group 483" transform="translate(-24 -148)">
            <g
                id="Ellipse_216"
                data-name="Ellipse 216"
                transform="translate(24 148)"
                fill="#fff"
                stroke="#fe5a5e"
                strokeWidth="1"
            >
                <circle cx="16" cy="16" r="16" stroke="none" />
                <circle cx="16" cy="16" r="15.5" fill="none" />
            </g>
            <path
                id="Path_98"
                data-name="Path 98"
                d="M.413-16.188H-2.722l-.494-10.828H.907Zm-3.744,3.374a1.651,1.651,0,0,1,.614-1.322,2.3,2.3,0,0,1,1.533-.517,2.3,2.3,0,0,1,1.533.517,1.651,1.651,0,0,1,.614,1.322A1.651,1.651,0,0,1,.35-11.492a2.3,2.3,0,0,1-1.533.517,2.3,2.3,0,0,1-1.533-.517A1.651,1.651,0,0,1-3.331-12.814Z"
                transform="translate(41.184 182.995)"
                fill="#fe5a5e"
            />
        </g>
    </svg>
);
