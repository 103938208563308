import { getColor } from "../utils";
import { IconProps } from "./Icons.typings";

export const TrashIcon = ({ isActive, isImportant }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
    >
        <path
            id="Icon_awesome-trash"
            data-name="Icon awesome-trash"
            d="M28.929,1.875H20.893l-.629-1.1A1.644,1.644,0,0,0,18.824,0H11.17A1.624,1.624,0,0,0,9.737.779l-.629,1.1H1.071A1.011,1.011,0,0,0,0,2.812V4.687a1.011,1.011,0,0,0,1.071.938H28.929A1.011,1.011,0,0,0,30,4.687V2.812A1.011,1.011,0,0,0,28.929,1.875ZM3.563,27.363A3.066,3.066,0,0,0,6.77,30H23.23a3.066,3.066,0,0,0,3.208-2.636L27.857,7.5H2.143Z"
            fill={getColor(
                isActive ? "active" : isImportant ? "important" : "default"
            )}
            opacity="1"
        />
    </svg>
);
