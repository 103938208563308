import styles from "./AppDownload.module.css";

import googlePlay from "./images/google-play.png";
import appStore from "./images/app-store.png";

export const AppDownload = () => {
    return (
        <div className={styles.container}>
            <div className={styles.item}>
                <a
                    className={styles.button}
                    href="https://android.smarttutor.pl"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={googlePlay} alt="Google Play" />
                </a>
            </div>
            <div className={styles.item}>
                <a
                    className={styles.button}
                    href="https://ios.smarttutor.pl"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={appStore} alt="App Store" />
                </a>
            </div>
        </div>
    );
};
